import * as React from 'react';

import { ButtonModel } from '@notino/react-styleguide';
import { AddToCartSettingsQuery } from '@notino/shared/definitions/types';

import { RequestState } from '@containers/App/model';

import {
  useAddToCartActions,
  useAddToCartButtonContext,
  useAddToCartSendState,
} from '../../context';
import messages from '../../messages';

import { BuyButtonWithDefaults } from './BuyButtonWithDefaults';
import { useHandleAddToCart } from './hooks/useHandleAddToCart';
import { LegacyAddToCartButton } from './LegacyAddToCartButton';

export interface IBuyButtonProps {
  isSpecialProduct: boolean;
  isOnSpecialModal?: boolean;
  Settings: AddToCartSettingsQuery['Settings'];
  productId: string;
  productCode?: string;
  buttonType: 'button' | 'submit';
  showSpecialModal?: () => void;
}

export const BuyButton: React.FC<IBuyButtonProps> = React.memo(
  ({
    isSpecialProduct,
    isOnSpecialModal,
    buttonType,
    productId,
    showSpecialModal,
  }) => {
    const {
      props: {
        buttonStyle,
        buttonSize,
        buttonElementClassName,
        buttonElementId,
        wrapable,
        buttonText,
        withLegacyAddToCart,
      },
    } = useAddToCartButtonContext();

    const sendState = useAddToCartSendState();
    const { setMouseOverBuyButton } = useAddToCartActions();

    const { handleAddToCartEvent: handleClick } = useHandleAddToCart({
      isOnSpecialModal,
    });

    // Real buy button is the original button in case of non-professional (or returned, damaged) product
    // or the one on professional modal
    const isRealBuyButton = !isSpecialProduct || isOnSpecialModal;

    const BuyButtonElement = (
      <BuyButtonWithDefaults
        buttonStyle={
          isOnSpecialModal ? ButtonModel.Styles.primary : buttonStyle
        }
        buttonSize={isOnSpecialModal ? ButtonModel.Sizes.small : buttonSize}
        buttonElementId={buttonElementId}
        buttonElementClassName={buttonElementClassName}
        wrapable={wrapable}
        buttonText={
          [RequestState.FAILED, RequestState.RETRYING].includes(sendState)
            ? messages.addToCartTryAgain
            : buttonText
        }
        sendState={isRealBuyButton ? sendState : null}
        onMouseOver={setMouseOverBuyButton}
        onClick={isRealBuyButton ? handleClick : showSpecialModal}
        buttonType={buttonType}
      />
    );

    if (withLegacyAddToCart) {
      return (
        <LegacyAddToCartButton productId={productId}>
          {BuyButtonElement}
        </LegacyAddToCartButton>
      );
    }

    return BuyButtonElement;
  }
);
