import * as React from 'react';

import { isDiorBrand } from '../../utils';

import DiorLogo from './Logos/Dior.jpg';

const IMAGE_ALT = 'Brand logo';
export const getLogoForBrand = (brandId: string) => {
  if (isDiorBrand(brandId)) {
    return <img src={DiorLogo} alt={IMAGE_ALT} />;
  }
  return null;
};
