import * as React from 'react';

import { useQuery } from '@apollo/client';

import { getImageAttributes } from '@notino/react-web-utils';
import { GetImageSettingsQuery } from '@notino/shared/definitions/types';

import getImageSettingsQuery from './queries/imageSettings.graphql';

export const useGetImageAttributes = () => {
  const { data: { Settings } = {} } = useQuery<GetImageSettingsQuery>(
    getImageSettingsQuery
  );

  return React.useCallback(
    (src: string, sourceKey: string, alt: string = '') => {
      return getImageAttributes(
        {
          src,
          alt,
        },
        Settings?.imageDomainPath,
        sourceKey
      );
    },
    [Settings?.imageDomainPath]
  );
};

export const useImageAttributes = (
  src: string,
  sourceKey: string,
  alt: string = ''
) => {
  const getImageAttributesFn = useGetImageAttributes();

  return getImageAttributesFn(src, sourceKey, alt);
};
