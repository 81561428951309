import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonModel } from '@notino/react-styleguide';

import { AddToCartCoreComponent } from '@sharedComponents/AddToCartButton/AddToCartCoreComponentButton';
import { AddToCartButtonProvider } from '@sharedComponents/AddToCartButton/context';
import { MaxProductsInCartModal } from '@sharedComponents/AddToCartButton/MaxProductsInCartModal';
import messages from '@sharedComponents/AddToCartButton/messages';
import { IAddToCartButtonProps } from '@sharedComponents/AddToCartButton/model';
import { NotificationModalOrDrawerContent } from '@sharedComponents/AddToCartButton/NotificationModal';

import { ButtonWrapper } from './styled';

type CommonProps = Pick<
  IAddToCartButtonProps,
  | 'showAddToCartModal'
  | 'getModalAfterAddToCart'
  | 'buttonSize'
  | 'buttonWrapper'
  | 'buttonStyle'
  | 'count'
  | 'wrapable'
  | 'disableRedirect'
>;

type UpsellingAddToCartButtonProps = Omit<
  IAddToCartButtonProps,
  keyof CommonProps
>;

const getModalAfterAddToCart: CommonProps['getModalAfterAddToCart'] = (
  modalProps
) => {
  if (
    modalProps.type === 'common' &&
    modalProps.notification?.modalType === 'common'
  ) {
    return [
      <NotificationModalOrDrawerContent
        key="notification"
        statusMessages={modalProps.notification.messages}
        messagesValues={modalProps.notification.values}
      />,
      {
        center: false,
        withFocusTrap: true,
        header: (
          <FormattedMessage {...messages[modalProps.notification.header]} />
        ),
      },
    ];
  }
  if (modalProps.type === 'maxProductsInCart') {
    return [
      <MaxProductsInCartModal key="maxProductsInCart" />,
      {
        withFocusTrap: true,
      },
    ];
  }
};

export const UpsellingAddToCartButton: React.FC<UpsellingAddToCartButtonProps> =
  React.memo((props) => {
    const commonProps: CommonProps = {
      showAddToCartModal: false,
      buttonWrapper: ButtonWrapper,
      buttonStyle: ButtonModel.Styles.secondary,
      buttonSize: ButtonModel.Sizes.xSmall,
      count: 1,
      disableRedirect: true,
      wrapable: true,
      getModalAfterAddToCart,
    };

    return (
      <AddToCartButtonProvider {...props} {...commonProps}>
        <AddToCartCoreComponent {...props} {...commonProps} />
      </AddToCartButtonProvider>
    );
  });
