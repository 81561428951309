import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackEngravingChecked = (checked: boolean, timing: number) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      name: 'engraving',
      action: checked ? 'select_engraving' : 'deselect_engraving',
      interaction: 'checkbox',
      mode: checked ? 'on' : 'off',
      timing,
      type: 'engraving',
    },
    _clear: true,
  });
};
