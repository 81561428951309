import * as React from 'react';

import { useTrackingContext } from '@context/tracking/TrackingContext';

import { trackEngravingChecked } from '../../Engraving/tracking';
import { trackTryItFirstChanged } from '../../TryItFirst/tracking';

import { Action, ActionTypes, IActions } from './model';

export const useActions = (dispatch: React.Dispatch<Action>): IActions => {
  const { getTimeFromInit } = useTrackingContext();

  return React.useMemo(
    () => ({
      changeEngravingInitials: (value: string) => {
        dispatch({ type: ActionTypes.CHANGE_ENGRAVING_INITIALS, value });
        trackEngravingChecked(value.length > 0, getTimeFromInit());
      },
      toggleTryItFirst: (value: boolean) => {
        dispatch({ type: ActionTypes.TOGGLE_TRY_IT_FIRST, value });
        trackTryItFirstChanged(value, getTimeFromInit());
      },
      clear: () => {
        dispatch({ type: ActionTypes.CLEAR });
      },
    }),
    [dispatch, getTimeFromInit]
  );
};
