import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ModalContext, ProductLabel } from '@notino/react-styleguide';
import { GetUpsellingDataQuery } from '@notino/shared/definitions/types';

import { useWithSpaRedirect } from '@components/ClientRedirect/useHandleClientRedirect';
import PriceLabel from '@components/PriceLabel';
import { useTagAttributes } from '@components/Universals/ProductItem/useTagAttributes';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import {
  useGetMasterAddToCart,
  ProductEventWither,
  TrackedProduct,
} from '@helpers/googleTagManager';
import { useAddToCardCloudTracking } from '@hooks/useAddToCardCloudTracking';
import { ATTRIBUTION_TOKEN_QUERY_PARAM } from '@utils/constants';
import { appendQueryParam } from '@utils/url';

import { GTM_LIST_LABEL } from '../../constants';

import {
  Annotation,
  ProductTileWrapper,
  Image,
  ImageWrapper,
  BrandTitle,
  Price,
  PriceWrapper,
  CurrencyWrapper,
  DescriptionSectionWrapper,
  ProductTileAnchor,
  ProductName,
  StyledSpecialSalesIcon,
  DualPriceWrapper,
  DualCurrencyWrapper,
  TagsWrapper,
} from './styled';
import { UpsellingAddToCartButton } from './UpsellingAddToCartButton';

export interface ProductTitleOwnProps {
  position: number;
  productById: GetUpsellingDataQuery['vpProductByIds'][number];
  trackVisibleItemsGTM: (product: TrackedProduct) => void;
  attributionToken?: string;
}

export const ProductTile: React.FC<ProductTitleOwnProps> = React.memo(
  ({ productById, position, attributionToken, trackVisibleItemsGTM }) => {
    const {
      imageUrl,
      brand,
      name,
      url,
      price,
      annotation,
      id,
      productCode,
      attributes,
      masterId,
      catalogMasterId,
      canBuy,
      stockAvailability,
    } = productById;

    const [ref, inView] = useInView();
    const { hideModal } = ModalContext.useModalContext();
    const {
      getLeftSidePrice,
      getRightSidePrice,
      rightSideCurrency,
      leftSideCurrency,
      isDualPriceForCroatia,
    } = useIsDualPriceForCroatia();
    const addToCardCloudTracking = useAddToCardCloudTracking();

    const trackSelectItem = () => {
      dispatchTrackingEvent({
        event: 'select_item',
        product: [
          ProductEventWither()
            .withVpProduct({
              ...productById,
              reviewOverview: null,
            })
            .withAdditionalData({
              list_position: position,
              list_name: GTM_LIST_LABEL,
            })
            .build(),
        ],
        _clear: true,
      });
    };

    const trackAddToCart = useGetMasterAddToCart(GTM_LIST_LABEL);

    const onProductAdded = async () => {
      await trackAddToCart(productById, catalogMasterId.toString(), position);
      addToCardCloudTracking({ quantity: 1, productCode });
    };

    React.useEffect(() => {
      if (inView) {
        trackVisibleItemsGTM(
          ProductEventWither()
            .withVpProduct({
              ...productById,
              reviewOverview: null,
            })
            .withAdditionalData({
              list_position: position,
              id: productById.id,
            })
            .build()
        );
      }
    }, [inView, productById, position, trackVisibleItemsGTM]);

    const tagsAttrs = useTagAttributes(attributes);

    const href = attributionToken
      ? appendQueryParam(url, ATTRIBUTION_TOKEN_QUERY_PARAM, attributionToken)
      : url;

    const withSpaRedirect = useWithSpaRedirect({ ...productById, url: href });

    const onClick = withSpaRedirect(() => {
      hideModal();
      trackSelectItem();
    });

    return (
      <ProductTileWrapper
        data-testid="upselling-product"
        id={`upsellingProduct-${id}`}
        ref={ref}
      >
        <ProductTileAnchor href={href} onClick={onClick}>
          <ImageWrapper>
            <Image
              src={imageUrl}
              alt={`${brand?.name} ${name}`}
              sourceKey="list"
            />

            <TagsWrapper>
              {tagsAttrs.map((attr) => (
                <ProductLabel key={attr.label} labelStyle={attr.style}>
                  {attr.label}
                </ProductLabel>
              ))}
            </TagsWrapper>

            <StyledSpecialSalesIcon
              attributes={attributes}
              stockAvailabilityCode={stockAvailability.code}
            />
          </ImageWrapper>

          <DescriptionSectionWrapper>
            <BrandTitle>{brand.name}</BrandTitle>
            <ProductName>{name}</ProductName>
            <Annotation>{annotation}</Annotation>
          </DescriptionSectionWrapper>

          <Price>
            {isDualPriceForCroatia ? (
              <>
                <PriceLabel
                  price={{ value: getLeftSidePrice(price.value) }}
                  wrapperComponent={DualPriceWrapper}
                  currencyComponent={DualCurrencyWrapper}
                  currencySymbolOverride={leftSideCurrency}
                />
                <span> / </span>
                <PriceLabel
                  price={{ value: getRightSidePrice(price.value) }}
                  wrapperComponent={DualPriceWrapper}
                  currencyComponent={DualCurrencyWrapper}
                  currencySymbolOverride={rightSideCurrency}
                />
              </>
            ) : (
              <PriceLabel
                price={price}
                wrapperComponent={PriceWrapper}
                currencyComponent={CurrencyWrapper}
              />
            )}
          </Price>
        </ProductTileAnchor>

        {canBuy && (
          <UpsellingAddToCartButton
            buttonWrapperProps={{ 'data-code': productById.productCode }}
            product={{
              id,
              attributes,
              productCode,
              name,
              variantName: annotation,
              masterId,
            }}
            onProductAdded={onProductAdded}
          />
        )}
      </ProductTileWrapper>
    );
  }
);
