import * as React from 'react';
import { useIntl } from 'react-intl';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { messages } from './messages';
import { Container, Icon, SectionTitle } from './styled';
import TrimenSvg from './Trimen.svg';

export interface IAboutBrandProps {
  documentation: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['documentation'];
  isFranceShop: boolean;
}

export const Other: React.FC<IAboutBrandProps> = ({
  documentation,
  isFranceShop,
}) => {
  const { formatMessage } = useIntl();

  const { specialStorage, importantNotice, manufacturerImporter } =
    documentation;

  return (
    <Container id="pdOthersTab">
      {isFranceShop && <Icon src={TrimenSvg} />}

      {specialStorage && (
        <>
          <SectionTitle>{formatMessage(messages.specialStorage)}</SectionTitle>
          <div dangerouslySetInnerHTML={{ __html: specialStorage }} />
        </>
      )}
      {importantNotice && (
        <>
          <SectionTitle>{formatMessage(messages.importantNotice)}</SectionTitle>
          <div dangerouslySetInnerHTML={{ __html: importantNotice }} />
        </>
      )}
      {manufacturerImporter && (
        <>
          <SectionTitle>
            {formatMessage(messages.manufacturerImporter)}
          </SectionTitle>
          <div dangerouslySetInnerHTML={{ __html: manufacturerImporter }} />
        </>
      )}
    </Container>
  );
};
