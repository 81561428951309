export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type ActiveWatchdogsResponse = {
  __typename?: 'ActiveWatchdogsResponse';
  cursor: Maybe<Scalars['Int']>;
  moreAvailable: Maybe<Scalars['Boolean']>;
  watchdogs: Maybe<Array<Maybe<ActiveWatchdogsResponse_Watchdog>>>;
};

export type ActiveWatchdogsResponse_Watchdog = {
  __typename?: 'ActiveWatchdogsResponse_Watchdog';
  catalogId: Maybe<Scalars['Int']>;
  registeredEmails: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ActualProduct = {
  __typename?: 'ActualProduct';
  id: Scalars['String'];
};

export type AddGiftToCart = {
  __typename?: 'AddGiftToCart';
  itemId: Scalars['Int'];
};

export type AddProductResponse = {
  __typename?: 'AddProductResponse';
  productAddResponse: Maybe<CartProducts>;
  serviceAddResponse: Maybe<Array<Maybe<CartServiceAddResponse>>>;
};

export type AddProductsResponse = {
  __typename?: 'AddProductsResponse';
  productAddResponse: Maybe<Array<Maybe<CartProducts>>>;
  serviceAddResponse: Maybe<Array<Maybe<CartServiceAddResponse>>>;
};

export type AddToCartTryItFirstResult = {
  __typename?: 'AddToCartTryItFirstResult';
  status: Maybe<Array<CartProductStatus>>;
};

export type AdminPanelInfo = {
  __typename?: 'AdminPanelInfo';
  approvalStatus: Maybe<RolloutStatusType>;
  availableShops: Maybe<Array<AvailableShop>>;
  commodityId: Scalars['String'];
  displayType: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
};

export type AlternateSite = {
  __typename?: 'AlternateSite';
  culture: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
};

export type AppConfig = {
  __typename?: 'AppConfig';
  captcha: CaptchaConfig;
  /** @deprecated Use captcha field instead */
  recaptchaSiteKey: Scalars['String'];
  shops: Maybe<Array<Shop>>;
  supportedLocales: Maybe<Array<Scalars['String']>>;
  supportedShops: Maybe<Array<Scalars['String']>>;
};

export type Article = {
  __typename?: 'Article';
  author: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ArticlesQueryResponse = {
  __typename?: 'ArticlesQueryResponse';
  articles: Array<BlogArticle>;
  totalCount: Scalars['Int'];
};

export type ArticlesQueryVariables = {
  brandId?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<Scalars['Int']>>;
  kinds?: InputMaybe<Array<Scalars['Int']>>;
  masterProductCode?: InputMaybe<Scalars['String']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  subCategories?: InputMaybe<Array<Scalars['Int']>>;
  types?: InputMaybe<Array<Scalars['Int']>>;
  /** @deprecated Always set to true now */
  useNewEndpoint?: InputMaybe<Scalars['Boolean']>;
};

export enum AuthorType {
  Motivated = 'Motivated',
  Unknown = 'Unknown',
  Verified = 'Verified',
}

export type Availability = {
  __typename?: 'Availability';
  availablePickUpStores: Scalars['Int'];
  maxOrderQuantity: Scalars['Int'];
  state: AvailabilityState;
  stockAvailability: StockAvailability;
};

export enum AvailabilityState {
  CanBeBought = 'CanBeBought',
  Disabled = 'Disabled',
  ShowWatchdog = 'ShowWatchdog',
}

export type AvailableShop = {
  __typename?: 'AvailableShop';
  languageTag: Scalars['String'];
  productUrl: Scalars['String'];
  shopId: Scalars['String'];
};

export type BazaarvoiceIntegrationType = {
  __typename?: 'BazaarvoiceIntegrationType';
  environment: Scalars['String'];
  locale: Scalars['String'];
  siteId: Scalars['String'];
};

export type Benefit = {
  __typename?: 'Benefit';
  iconId: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type BlogArticle = {
  __typename?: 'BlogArticle';
  hreflangId: Maybe<Scalars['String']>;
  image: Maybe<BlogArticleImage>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type BlogArticleImage = {
  __typename?: 'BlogArticleImage';
  alt: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  description: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logoUrl: Maybe<Scalars['String']>;
  manufacturer: Maybe<Scalars['String']>;
  market: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type BrandItem = {
  __typename?: 'BrandItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  link: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Campaign = {
  __typename?: 'Campaign';
  campaignId: Maybe<Scalars['String']>;
  detailPageText: Maybe<Scalars['String']>;
  displayOnDetailPage: Maybe<Scalars['Boolean']>;
  giftImageUrl: Maybe<Scalars['String']>;
  giftType: Maybe<GiftType>;
  id: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
};

export type CampaignConfig = {
  __typename?: 'CampaignConfig';
  allowed: Maybe<EGiftPackagingAllowedType>;
  groupName: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Float']>;
};

export type CancelWatchdogsResponse = {
  __typename?: 'CancelWatchdogsResponse';
  cancelledWatchdogCount: Maybe<Scalars['Int']>;
};

export type CaptchaConfig = {
  __typename?: 'CaptchaConfig';
  turnstileSiteKey: Maybe<Scalars['String']>;
};

export type CartAddedService = {
  __typename?: 'CartAddedService';
  productId: Maybe<Scalars['String']>;
  type: Maybe<CartServiceType>;
  value: Maybe<Scalars['String']>;
};

export type CartContent = {
  __typename?: 'CartContent';
  cartId: Scalars['String'];
  delivery: Maybe<CartContentProduct>;
  discounts: Array<DiscountDto>;
  payment: Maybe<CartContentProduct>;
  pricesSummary: PricesSummaryDto;
  products: Maybe<Array<Maybe<CartContentProduct>>>;
  serviceItems: ServiceItemsDto;
  vouchers: Array<VoucherDto>;
};

export type CartContentProduct = {
  __typename?: 'CartContentProduct';
  action: Scalars['Boolean'];
  autoInserted: Scalars['Boolean'];
  brandId: Scalars['Int'];
  categoryId: Scalars['Int'];
  count: Scalars['Int'];
  deleted: Scalars['Boolean'];
  discount: Scalars['Boolean'];
  engraving: Maybe<CustomServiceDto>;
  freeProduct: Scalars['Boolean'];
  giftCampaignId: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  isNiche: Scalars['Boolean'];
  isService: Scalars['Boolean'];
  isVirtualService: Scalars['Boolean'];
  itemId: Scalars['Int'];
  priceSumWithVat: Scalars['Int'];
  priceWithVat: Scalars['Int'];
  productCode: Scalars['String'];
  productElisId: Scalars['Int'];
  productId: Scalars['Int'];
  tryItFirstId: Scalars['Int'];
  upselling: Scalars['Boolean'];
  usedProduct: Scalars['Boolean'];
  vat: Scalars['Int'];
};

export type CartItemDto = {
  __typename?: 'CartItemDto';
  action: Maybe<Scalars['Boolean']>;
  autoInserted: Maybe<Scalars['Boolean']>;
  count: Maybe<Scalars['Int']>;
  customServices: Maybe<CartItemDto_CustomServices>;
  deleleted: Maybe<Scalars['Boolean']>;
  discount: Maybe<Scalars['Boolean']>;
  freeProduct: Maybe<Scalars['Boolean']>;
  giftCampaignId: Maybe<Scalars['Int']>;
  ident: Maybe<Scalars['String']>;
  isService: Maybe<Scalars['Boolean']>;
  itemId: Maybe<Scalars['Int']>;
  priceSumWithVat: Maybe<Scalars['Float']>;
  priceWithVat: Maybe<Scalars['Float']>;
  productCode: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['Int']>;
  upselling: Maybe<Scalars['Boolean']>;
  usedProduct: Maybe<Scalars['Boolean']>;
  vat: Maybe<Scalars['Float']>;
};

export type CartItemDto_CustomServices = {
  __typename?: 'CartItemDto_customServices';
  /** default field */
  success: Maybe<Scalars['String']>;
};

export type CartProduct = {
  __typename?: 'CartProduct';
  comparisonPrice: Maybe<Scalars['Boolean']>;
  count: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  isUpselling: Maybe<Scalars['Boolean']>;
  product: Maybe<VpProduct>;
  status: Array<Maybe<CartProductStatus>>;
};

export enum CartProductStatus {
  Default = 'Default',
  Error = 'Error',
  NoCreateCart = 'NoCreateCart',
  OutOfPriceLimit = 'OutOfPriceLimit',
  ReducedCount = 'ReducedCount',
  ShowSoldOutWarning = 'ShowSoldOutWarning',
  Sold = 'Sold',
  Success = 'Success',
  UseProductInCart = 'UseProductInCart',
  VoucherOnline = 'VoucherOnline',
}

export type CartProducts = {
  __typename?: 'CartProducts';
  cart: CartContent;
  itemId: Scalars['Int'];
  messages: Array<Scalars['String']>;
  productId: Scalars['Int'];
  productIncrease: Scalars['Int'];
};

export type CartServiceAddResponse = {
  __typename?: 'CartServiceAddResponse';
  message: Maybe<Scalars['String']>;
  service: Maybe<CartAddedService>;
  status: Array<Maybe<CartProductStatus>>;
};

export type CartServiceInput = {
  count?: InputMaybe<Scalars['Int']>;
  productId: Scalars['String'];
  type: CartServiceType;
  value: Scalars['String'];
};

export enum CartServiceType {
  Engraving = 'Engraving',
}

export enum CartStatusType {
  Default = 'Default',
  Error = 'Error',
  InternalError = 'InternalError',
  NotFound = 'NotFound',
  Success = 'Success',
  Warning = 'Warning',
}

export type CatalogProduct = {
  __typename?: 'CatalogProduct';
  alternateSites: Maybe<Array<Maybe<AlternateSite>>>;
  annotation: Maybe<Scalars['String']>;
  brand: Maybe<Brand>;
  breadcrumb: Maybe<Array<Breadcrumb>>;
  campaigns: Maybe<Array<Maybe<Campaign>>>;
  catalogMasterId: Scalars['ID'];
  categorization: Categorization;
  category: Maybe<Array<Maybe<Scalars['String']>>>;
  collection: Maybe<Scalars['String']>;
  config: ProductConfig;
  deliveries: Array<ProductDelivery>;
  description: Maybe<Scalars['String']>;
  features: ProductDetailFeatures;
  gender: Maybe<Gender>;
  isForbiddenToDisplay: Maybe<Scalars['Boolean']>;
  isPerfume: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  masterProductCode: Scalars['String'];
  primaryCategories: Maybe<PrimaryCategoryObject>;
  related: Array<Scalars['Int']>;
  reviewOverview: Maybe<ReviewOverview>;
  reviewSummary: Maybe<ReviewSummary>;
  subCategory: Maybe<Array<Maybe<Scalars['String']>>>;
  subName: Maybe<Scalars['String']>;
  type: Maybe<Array<Maybe<Scalars['String']>>>;
  url: Scalars['String'];
  variantDisplayType: Maybe<VariantDisplayType>;
  variants: Array<CatalogVariant>;
  webMasterId: Scalars['ID'];
};

export type CatalogProductReviewOverviewArgs = {
  ignoreExternalRating?: InputMaybe<Scalars['Boolean']>;
};

export type CatalogProductVariantCoreInfo = {
  availability: Maybe<Availability>;
  catalogId: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  productCode: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  webId: Maybe<Scalars['ID']>;
  wishlisted: Maybe<Scalars['Boolean']>;
};

export type CatalogVariant = CatalogProductVariantCoreInfo & {
  __typename?: 'CatalogVariant';
  additionalInfo: Scalars['String'];
  additionalText: Maybe<Scalars['String']>;
  annotation: Maybe<Scalars['String']>;
  attributes: Maybe<Scalars['JSON']>;
  availability: Availability;
  brand: Maybe<Brand>;
  campaignConfig: Maybe<CampaignConfig>;
  catalogId: Scalars['ID'];
  characteristics: Array<Characteristic>;
  colors: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionOverride: Maybe<Scalars['String']>;
  documentation: Maybe<ProductDocumentation>;
  eanCode: Scalars['String'];
  features: Maybe<VariantFeatures>;
  gender: Maybe<Gender>;
  media: Maybe<VariantMedia>;
  metaData: Seo;
  name: Maybe<Scalars['String']>;
  navigationValues: Array<Scalars['Int']>;
  orderCode: Maybe<Scalars['String']>;
  originalPrice: Maybe<OriginalPrice>;
  parameters: Maybe<Parameters>;
  price: Price;
  primaryCategories: Maybe<PrimaryCategoryObject>;
  productCode: Scalars['String'];
  recentMinPrice: Maybe<RecentMinPrice>;
  stockAvailability: StockInfo;
  unitPrice: Maybe<UnitPriceDetail>;
  url: Maybe<Scalars['String']>;
  variantName: Maybe<Scalars['String']>;
  webId: Scalars['ID'];
  wishlisted: Maybe<Scalars['Boolean']>;
};

export type Categorization = {
  __typename?: 'Categorization';
  categories: Maybe<Array<CategorizationItem>>;
  kinds: Maybe<Array<CategorizationItem>>;
  subCategories: Maybe<Array<CategorizationItem>>;
  types: Maybe<Array<CategorizationItem>>;
};

export type CategorizationItem = {
  __typename?: 'CategorizationItem';
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

export type CategorizationKind = {
  name: Scalars['String'];
};

export type Characteristic = {
  __typename?: 'Characteristic';
  help: Maybe<Scalars['String']>;
  /** @deprecated Not supported by server. */
  link: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  values: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ClickAndCollectStore = {
  __typename?: 'ClickAndCollectStore';
  code: Scalars['String'];
  description: Scalars['String'];
  earliestPickup: Scalars['String'];
  isOverloaded: Maybe<Scalars['Boolean']>;
  location: Location;
  name: Scalars['String'];
  stockCount: StockAvailability;
};

export type ClientCache = {
  __typename?: 'ClientCache';
  hasWebcam: Maybe<Scalars['Boolean']>;
};

export type ClientHeaders = {
  __typename?: 'ClientHeaders';
  abTest: Maybe<Scalars['String']>;
  assetsPath: Scalars['String'];
  authorization: Scalars['String'];
  featureFlags: FeatureFlags;
  features: Scalars['String'];
  gatewayUrl: Scalars['String'];
  groupId: Scalars['String'];
  hrkDefaultCurrency: HrkDefaultCurrency;
  lastVisitedProducts: Maybe<Array<Scalars['String']>>;
  modifaceDomain: Maybe<Scalars['String']>;
  mounts: Array<Scalars['String']>;
  originUrl: Maybe<Scalars['String']>;
  role: Scalars['String'];
  sentryDsn: Scalars['String'];
  sentryEnvironment: Scalars['String'];
  sentryTracingRate: Scalars['Float'];
  shadefinderAppId: Maybe<Scalars['String']>;
  shopId: Scalars['String'];
  skinAnalyzer: SkinAnalyzer;
  userEmail: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userName: Maybe<Scalars['String']>;
  xAbTest: Maybe<Scalars['String']>;
  xLangTag: Maybe<Scalars['String']>;
  xUserToken: Maybe<Scalars['String']>;
};

export type ClientProductSsr = {
  __typename?: 'ClientProductSsr';
  id: Maybe<Scalars['String']>;
  pathname: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type ClientWatchdog = {
  __typename?: 'ClientWatchdog';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['String'];
  success: Scalars['String'];
};

export type ClientWatchdogs = {
  __typename?: 'ClientWatchdogs';
  list: Array<Maybe<ClientWatchdog>>;
};

export type Cloudflare = {
  __typename?: 'Cloudflare';
  baseUrl: Scalars['String'];
  enabled: Scalars['Boolean'];
  quality: Scalars['Int'];
};

export type CompositionTables = {
  __typename?: 'CompositionTables';
  nutritionValues: Maybe<Array<NutritionValue>>;
  vitaminsAndMinerals: Maybe<Array<VitaminsAndMinerals>>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  symbol: Maybe<Scalars['String']>;
};

export type CustomServiceDto = {
  __typename?: 'CustomServiceDto';
  cartItemId: Scalars['Int'];
  cartServiceItem: Scalars['Int'];
  priceWithVat: Scalars['Int'];
  value: Scalars['String'];
};

export type CustomerInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DefaultCurrency = {
  __typename?: 'DefaultCurrency';
  code: Scalars['String'];
  currencyId: Maybe<Scalars['Int']>;
  symbol: Maybe<Scalars['String']>;
};

export type Delivery = {
  __typename?: 'Delivery';
  date: Maybe<Scalars['String']>;
  dateFrom: Maybe<Scalars['String']>;
  dateTo: Maybe<Scalars['String']>;
  disallowedReason: Maybe<Scalars['String']>;
  isAirTransport: Maybe<Scalars['Boolean']>;
  isPersonal: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
};

export enum DeliveryDisallowedReason {
  AirTransportDisallowed = 'AirTransportDisallowed',
}

export enum DeliveryType {
  AirTransport = 'AirTransport',
  Personal = 'Personal',
  Transport = 'Transport',
}

export type DesiredShade = {
  __typename?: 'DesiredShade';
  darker: Maybe<RecommendedProduct>;
  ideal: Maybe<RecommendedProduct>;
  lighter: Maybe<RecommendedProduct>;
};

export type DiscountDto = {
  __typename?: 'DiscountDto';
  priority: Scalars['Int'];
  type: EDiscountType;
  value: Scalars['Int'];
};

export enum EDiscountType {
  AbsoluteWithVat = 'AbsoluteWithVat',
  AbsoluteWithoutVat = 'AbsoluteWithoutVat',
  Percentage = 'Percentage',
}

export enum EGiftPackagingAllowedType {
  Brand = 'Brand',
  None = 'None',
  Product = 'Product',
}

export enum EStatus {
  Default = 'Default',
  Error = 'Error',
  Fail = 'Fail',
  NoCreateCart = 'NoCreateCart',
  NotFoundProduct = 'NotFoundProduct',
  OutOfLimit = 'OutOfLimit',
  OutOfPriceLimit = 'OutOfPriceLimit',
  ReducedCount = 'ReducedCount',
  Sold = 'Sold',
  Success = 'Success',
  UseProductInCart = 'UseProductInCart',
  VoucherAndProductNotAllowed = 'VoucherAndProductNotAllowed',
  Warning = 'Warning',
}

export type Engraving = {
  __typename?: 'Engraving';
  allowed: Maybe<Scalars['Boolean']>;
  characterCount: Maybe<Scalars['Int']>;
  config: Maybe<EngravingConfig>;
  image: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['Int']>;
};

export type EngravingConfig = {
  __typename?: 'EngravingConfig';
  isActive: Maybe<Scalars['Boolean']>;
  isAvailable: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['Int']>;
  orderedCount: Maybe<Scalars['Int']>;
  price: Maybe<Scalars['Float']>;
  productId: Maybe<Scalars['String']>;
  servicesType: Maybe<Scalars['String']>;
};

export type EventCalendar = {
  __typename?: 'EventCalendar';
  activeFrom: Maybe<Scalars['String']>;
  activeTo: Maybe<Scalars['String']>;
  backgroundImageUrl: Maybe<Scalars['String']>;
  calendarsCollectionId: Maybe<Scalars['Int']>;
  foregroundImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  items: Maybe<Array<Maybe<EventCalendarDay>>>;
  shopId: Maybe<Scalars['String']>;
  type: Maybe<EventCalendarType>;
};

export type EventCalendarDay = {
  __typename?: 'EventCalendarDay';
  /** @deprecated Use 'brands' instead */
  brand: Maybe<Brand>;
  brands: Maybe<Array<Maybe<Brand>>>;
  buttonText: Maybe<Scalars['String']>;
  date: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  discount: Maybe<Scalars['Boolean']>;
  filterId: Maybe<Array<Maybe<Scalars['Int']>>>;
  filterType: Maybe<Scalars['String']>;
  freeDelivery: Maybe<Scalars['Boolean']>;
  giftAvailable: Maybe<Scalars['Boolean']>;
  largeImage: Maybe<Scalars['String']>;
  smallImage: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  todayItem: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
};

export enum EventCalendarType {
  Advent = 'Advent',
  FlashSale = 'FlashSale',
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  bazaarvoice_cookie_consent_mapping: Maybe<Scalars['Boolean']>;
  bazaarvoice_integration_pd: Maybe<BazaarvoiceIntegrationType>;
  bazaarvoice_pd_components: Maybe<Scalars['Boolean']>;
  discovery_box_self_choice_enabled: Maybe<Scalars['Boolean']>;
  dual_currency_price: Maybe<Scalars['Boolean']>;
  gateway_router_proxy_enabled: Maybe<Scalars['Boolean']>;
  google_retail_api_user_events: Maybe<Scalars['Boolean']>;
  klarna_nushop_id: Maybe<Scalars['String']>;
  livestream_on_pd_enabled: Maybe<Scalars['Boolean']>;
  new_vto_button: Maybe<Scalars['Boolean']>;
  pd_360_photos: Maybe<Scalars['Boolean']>;
  pd_color_picker_drawer: Maybe<Scalars['Boolean']>;
  pd_display_rich_content_as_first_tab_brand_ids: Maybe<
    Array<Scalars['String']>
  >;
  pd_has_all_reviews_page: Maybe<Scalars['Boolean']>;
  pd_set_immediate_enabled: Maybe<Scalars['Boolean']>;
  pd_skin_analyzer_variant: Maybe<PdSkinAnalyzerVariant>;
  pd_upselling_product_ids_for_testing: Maybe<Array<Scalars['String']>>;
  reco_product_detail: Maybe<Scalars['Boolean']>;
  reco_product_detail_add_to_cart: Maybe<Scalars['Boolean']>;
  shade_finder_try_it_first_product_master_ids: Maybe<Array<Scalars['String']>>;
  spa_redirect_wishlist_to_pd: Maybe<Scalars['Boolean']>;
  strict_sales_display_type: Maybe<StrictSalesDisplayTypeFeatureFlag>;
  voucher_variant: Maybe<VoucherVariantFeatureFlag>;
};

export type FeatureSwitches = {
  __typename?: 'FeatureSwitches';
  addToCartResendDelay: Maybe<Scalars['String']>;
  addToCartWarningCount: Maybe<Scalars['String']>;
  hideReviewsABTest: Maybe<Scalars['String']>;
  modifaceDesktopEnabled: Maybe<Scalars['String']>;
  modifaceMobileEnabled: Maybe<Scalars['String']>;
  reviewsOnServerIds: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Filter = {
  __typename?: 'Filter';
  key: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export type FilterInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type Flag = {
  __typename?: 'Flag';
  countryCode: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type Footer = {
  __typename?: 'Footer';
  benefits: Maybe<Array<Maybe<Benefit>>>;
  copyrightText: Maybe<Scalars['String']>;
  flags: Maybe<Array<Maybe<Flag>>>;
  footerText: Maybe<FooterText>;
  linksSections: Maybe<Array<Maybe<LinkSection>>>;
  partners: Maybe<Array<Maybe<Partner>>>;
  payments: Maybe<Array<Maybe<Payment>>>;
  places: Maybe<Array<Maybe<Place>>>;
  quickContact: Maybe<QuickContact>;
  socialIcons: Maybe<Array<Maybe<SocialIcon>>>;
};

export type FooterText = {
  __typename?: 'FooterText';
  homePage: Maybe<HomePageFooter>;
};

export type Freetext = {
  __typename?: 'Freetext';
  content: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type GdprSettings = {
  __typename?: 'GdprSettings';
  securityAndPrivacyUrl: Maybe<Scalars['String']>;
  termsAndConditionsUrl: Maybe<Scalars['String']>;
};

export enum Gender {
  Man = 'man',
  Unisex = 'unisex',
  Woman = 'woman',
}

export type GenericModifaceEffect = {
  __typename?: 'GenericModifaceEffect';
  category: Maybe<Scalars['String']>;
  shades: Maybe<Scalars['JSON']>;
  upc: Maybe<Scalars['String']>;
};

export type GetLivestreamResponse = {
  __typename?: 'GetLivestreamResponse';
  shows: Maybe<Array<Livestream>>;
};

export type GetLivestreamVariables = {
  productCode: Scalars['String'];
};

export type GiftAddDtoInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  count: Scalars['Int'];
  productId: Scalars['Int'];
};

export type GiftModel = {
  __typename?: 'GiftModel';
  code: Scalars['String'];
  count: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  idAction: Maybe<Scalars['Int']>;
  productId: Maybe<Scalars['Int']>;
};

export type GiftPackagingConfig = {
  __typename?: 'GiftPackagingConfig';
  allowed: Maybe<EGiftPackagingAllowedType>;
  price: Maybe<Scalars['Float']>;
};

export enum GiftType {
  Basic = 'BASIC',
  Gwp = 'GWP',
  Sample = 'SAMPLE',
  Special = 'SPECIAL',
  TryItFirst = 'TRY_IT_FIRST',
}

export type HairModifaceEffects = {
  __typename?: 'HairModifaceEffects';
  category: Maybe<Scalars['String']>;
  shades: Maybe<Array<Maybe<ModifaceHairShade>>>;
  upc: Maybe<Scalars['String']>;
};

export type HomePageFooter = {
  __typename?: 'HomePageFooter';
  leftSideHtml: Maybe<Scalars['String']>;
  rightSideHtml: Maybe<Scalars['String']>;
};

export type HrkDefaultCurrency = {
  __typename?: 'HrkDefaultCurrency';
  code: Scalars['String'];
  currencyId: Scalars['Int'];
  exchangeRates: Maybe<HrkExchangeRates>;
  symbol: Scalars['String'];
};

export type HrkExchangeRates = {
  __typename?: 'HrkExchangeRates';
  EUR: Maybe<Scalars['Float']>;
  HRK: Maybe<Scalars['Float']>;
};

export type Image = {
  __typename?: 'Image';
  alt: Maybe<Scalars['String']>;
  src: Maybe<Scalars['String']>;
};

export type ImagesByCategory = {
  __typename?: 'ImagesByCategory';
  mainImage: Image;
  others: Maybe<Array<Maybe<Image>>>;
  previewImage: Maybe<Image>;
  shadeImage: Maybe<Image>;
};

export type InactiveWatchdogsResponse = {
  __typename?: 'InactiveWatchdogsResponse';
  cursor: Maybe<Scalars['Int']>;
  moreAvailable: Maybe<Scalars['Boolean']>;
  watchdogs: Maybe<Array<Maybe<InctiveWatchdogsResponse_Watchdog>>>;
};

export type InctiveWatchdogsResponse_Watchdog = {
  __typename?: 'InctiveWatchdogsResponse_Watchdog';
  catalogId: Maybe<Scalars['Int']>;
};

export type InstructionDocument = {
  __typename?: 'InstructionDocument';
  type: InstructionDocumentType;
  url: Scalars['String'];
};

export enum InstructionDocumentType {
  InstructionsForUse = 'InstructionsForUse',
  PackagingInformation = 'PackagingInformation',
}

export type ItemInCart = {
  __typename?: 'ItemInCart';
  cartItemId: Scalars['Int'];
  count: Maybe<Scalars['Int']>;
  price: Maybe<Scalars['Int']>;
  productId: Maybe<Scalars['Int']>;
  warning: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemResponse = {
  __typename?: 'ItemResponse';
  itemId: Maybe<Scalars['Int']>;
};

export type Language = {
  __typename?: 'Language';
  langId: Maybe<Scalars['Int']>;
  priceDecimalPlaces: Maybe<Scalars['Int']>;
  priceFormat: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type LanguagedMessage = {
  __typename?: 'LanguagedMessage';
  acceptLanguage: Scalars['String'];
  messages: Maybe<Scalars['JSON']>;
};

export type LegalInformation = {
  __typename?: 'LegalInformation';
  compositionTables: Maybe<CompositionTables>;
  document: Maybe<Scalars['String']>;
  foodComposition: Maybe<Scalars['String']>;
  importantNotice: Maybe<Scalars['String']>;
  ingredients: Maybe<Scalars['String']>;
  instructionDocument: Maybe<InstructionDocument>;
  instructions: Maybe<Scalars['String']>;
  isSensitiveHairProduct: Maybe<Scalars['Boolean']>;
  manufacturerImporter: Maybe<Scalars['String']>;
  nutritionValues: Maybe<Scalars['String']>;
  preparationUsage: Maybe<Scalars['String']>;
  specialStorage: Maybe<Scalars['String']>;
  video: Maybe<Scalars['String']>;
  warnings: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  targetUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type LinkSection = {
  __typename?: 'LinkSection';
  links: Maybe<Array<Maybe<Link>>>;
  sectionType: Maybe<SectionType>;
};

export type Livestream = {
  __typename?: 'Livestream';
  deeplink: Scalars['String'];
  description: Scalars['String'];
  isLive: Scalars['Boolean'];
  previewImageUrl: Scalars['String'];
  publishedDate: Maybe<Scalars['String']>;
  scheduledDate: Scalars['String'];
  scheduledStart: Maybe<Scalars['String']>;
  showId: Scalars['String'];
  title: Scalars['String'];
  videoLength: Scalars['String'];
};

export type Locale = {
  __typename?: 'Locale';
  currency: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  priceDecimalPlaces: Maybe<Scalars['Int']>;
  priceFormat: Maybe<Scalars['String']>;
  tag: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export type LoggedInUserReview = {
  __typename?: 'LoggedInUserReview';
  digests: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  ipAddress: Maybe<Scalars['String']>;
  masterProductCode: Scalars['String'];
  name: Scalars['String'];
  ratings: Maybe<Array<ReviewRating>>;
  score: Scalars['Int'];
  source: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
};

export type MasterProductListItem = {
  __typename?: 'MasterProductListItem';
  annotation: Maybe<Scalars['String']>;
  brandName: Maybe<Scalars['String']>;
  collection: Maybe<Scalars['String']>;
  masterId: Maybe<Scalars['Int']>;
  masterProductCode: Maybe<Scalars['String']>;
  reviewOverview: Maybe<ReviewOverview>;
  /** @deprecated Use reviewOverview. */
  reviewSummary: Maybe<ReviewSummary>;
  subName: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  variants: Array<Maybe<ProductListItem>>;
};

export type ModifaceEffect = {
  __typename?: 'ModifaceEffect';
  category: Maybe<Scalars['String']>;
  shades: Maybe<Array<Maybe<ModifaceEffectShade>>>;
  upc: Maybe<Scalars['String']>;
};

export type ModifaceEffectShade = {
  __typename?: 'ModifaceEffectShade';
  color_a: Maybe<Scalars['Int']>;
  color_b: Maybe<Scalars['Int']>;
  color_g: Maybe<Scalars['Int']>;
  color_r: Maybe<Scalars['Int']>;
  contrastBoost: Maybe<Scalars['Float']>;
  gamma: Maybe<Scalars['Float']>;
  glossDetail: Maybe<Scalars['Float']>;
  intensity: Maybe<Scalars['Float']>;
  placement: Maybe<Scalars['String']>;
  skinClearing: Maybe<Scalars['Float']>;
  skinGlow: Maybe<Scalars['Float']>;
  sparkle_a: Maybe<Scalars['Float']>;
  sparkle_b: Maybe<Scalars['Int']>;
  sparkle_g: Maybe<Scalars['Int']>;
  sparkle_r: Maybe<Scalars['Int']>;
  wetness: Maybe<Scalars['Float']>;
};

export type ModifaceHairShade = {
  __typename?: 'ModifaceHairShade';
  blendGradientBottomOffset: Maybe<Scalars['Int']>;
  blendGradientTopOffset: Maybe<Scalars['Int']>;
  effect: Maybe<Scalars['Int']>;
  enableRootRecoloring: Maybe<Scalars['Boolean']>;
  family: Maybe<Scalars['String']>;
  histogram_b: Maybe<Scalars['String']>;
  histogram_c: Maybe<Scalars['String']>;
  histogram_g: Maybe<Scalars['String']>;
  histogram_r: Maybe<Scalars['String']>;
  intensity: Maybe<Scalars['Float']>;
  renderingVersion: Maybe<Scalars['Int']>;
  shazamColorFamily: Maybe<Scalars['String']>;
  shine: Maybe<Scalars['Float']>;
  totalpixels: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddGiftToCart: Maybe<ItemResponse>;
  AddProductToCart: AddProductResponse;
  AddProductsToCart: AddProductsResponse;
  DeleteGiftFromCart: Maybe<Scalars['String']>;
  DeleteProductFromCart: Maybe<ShoppingCartModel>;
  TryItFirstAddToCart: AddToCartTryItFirstResult;
  WishlistAddProduct: Maybe<Wishlist>;
  WishlistAddProductByOwner: Maybe<Wishlist>;
  WishlistCopyWishlist: Maybe<WishlistId>;
  WishlistMergeWishlists: Maybe<Wishlist>;
  WishlistRemoveProduct: Maybe<Wishlist>;
  WishlistRemoveProductByOwner: Maybe<Wishlist>;
  addProductToClickAndCollect: Maybe<ShoppingCartModel>;
  addReview: Maybe<ReviewResponse>;
  cancelWatchdogs: Maybe<CancelWatchdogsResponse>;
  createWatchdog: WatchDog;
  dummy: Maybe<Scalars['String']>;
  handleVote: Maybe<Review>;
  samplingCreateOrder: SamplingOrderResponse;
  saveShadefinderUserData: Maybe<SaveShadefinderUserDataResult>;
  /** @deprecated Use createWatchdog. Send catalogId instead of productId */
  sendWatchdog: WatchDog;
  setActualProduct: Maybe<ActualProduct>;
  storeWatchdog: Maybe<Array<Maybe<ClientWatchdog>>>;
  tnbCreateNewToken: Scalars['String'];
  tnbCreateOrder: TnbOrderResponse;
  tnbMakeOrder: Maybe<TnbMakeOrderResponse>;
  tnbSmsValidation: Maybe<Scalars['ID']>;
};

export type MutationAddGiftToCartArgs = {
  gift: GiftAddDtoInput;
};

export type MutationAddProductToCartArgs = {
  product: ProductToCartInput;
  services?: InputMaybe<Array<InputMaybe<CartServiceInput>>>;
};

export type MutationAddProductsToCartArgs = {
  products: Array<ProductToCartInput>;
  services?: InputMaybe<Array<InputMaybe<CartServiceInput>>>;
};

export type MutationDeleteGiftFromCartArgs = {
  itemId: Scalars['Int'];
};

export type MutationDeleteProductFromCartArgs = {
  itemId: Scalars['Int'];
};

export type MutationTryItFirstAddToCartArgs = {
  productCode: Scalars['String'];
  productId: Scalars['String'];
  tryItFirstId: Scalars['String'];
};

export type MutationWishlistAddProductArgs = {
  product: Scalars['String'];
};

export type MutationWishlistAddProductByOwnerArgs = {
  owner: Scalars['String'];
  product: Scalars['String'];
};

export type MutationWishlistCopyWishlistArgs = {
  anonymousId?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

export type MutationWishlistMergeWishlistsArgs = {
  newOwner: Scalars['String'];
  owner: Scalars['String'];
};

export type MutationWishlistRemoveProductArgs = {
  product: Scalars['String'];
};

export type MutationWishlistRemoveProductByOwnerArgs = {
  owner: Scalars['String'];
  product: Scalars['String'];
};

export type MutationAddProductToClickAndCollectArgs = {
  productId: Scalars['Int'];
  warehouseCode: Scalars['String'];
};

export type MutationAddReviewArgs = {
  captchaToken?: InputMaybe<Scalars['String']>;
  digests?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  masterProductCode: Scalars['String'];
  name: Scalars['String'];
  ratings?: InputMaybe<Array<InputMaybe<Rating>>>;
  score: Scalars['Int'];
  source: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};

export type MutationCancelWatchdogsArgs = {
  catalogId: Scalars['Int'];
  emails: Array<Scalars['String']>;
};

export type MutationCreateWatchdogArgs = {
  catalogId: Scalars['Int'];
  email: Scalars['String'];
};

export type MutationHandleVoteArgs = {
  action: VoteAction;
  id: Scalars['String'];
  type: VoteType;
};

export type MutationSamplingCreateOrderArgs = {
  campaignId: Scalars['Int'];
  customer: CustomerInput;
};

export type MutationSaveShadefinderUserDataArgs = {
  userData: ShadefinderUserDataInput;
};

export type MutationSendWatchdogArgs = {
  email: Scalars['String'];
  productId: Scalars['String'];
};

export type MutationSetActualProductArgs = {
  id: Scalars['String'];
};

export type MutationStoreWatchdogArgs = {
  errors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MutationTnbCreateNewTokenArgs = {
  productCode: Scalars['String'];
};

export type MutationTnbCreateOrderArgs = {
  order?: InputMaybe<TnbOrderInput>;
};

export type MutationTnbMakeOrderArgs = {
  order: TnbMakeOrderInput;
};

export type MutationTnbSmsValidationArgs = {
  validationData: TnbSmsValidationInput;
};

export type NutritionValue = {
  __typename?: 'NutritionValue';
  amount: Maybe<Scalars['Float']>;
  amountSign: Maybe<Scalars['String']>;
  measureUnit: Maybe<Scalars['String']>;
  nutritionValue: Maybe<Scalars['String']>;
  variantAmount: Maybe<Scalars['Float']>;
};

export type OriginalPrice = {
  __typename?: 'OriginalPrice';
  currency: Maybe<Scalars['String']>;
  tax: Maybe<Scalars['Float']>;
  type: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Float']>;
};

export type Pager = {
  __typename?: 'Pager';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  remaining: Scalars['Int'];
};

export type PagerInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type Parameters = {
  __typename?: 'Parameters';
  amount: Maybe<Scalars['Float']>;
  package: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  externalCode: Maybe<Scalars['String']>;
  imagePath: Maybe<Scalars['String']>;
  linkUrl: Maybe<Scalars['String']>;
  onlyOnHomepage: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<PartnerType>;
};

export enum PartnerType {
  External = 'External',
  Internal = 'Internal',
}

export enum PathTemplate {
  Error = 'Error',
  HomePage = 'HomePage',
  NotFound = 'NotFound',
  ProductDetailSimple = 'ProductDetailSimple',
  ReviewsPage = 'ReviewsPage',
  SpecialPageComponent = 'SpecialPageComponent',
  Tryandbuy = 'Tryandbuy',
  Wishlist = 'Wishlist',
}

export type PathView = {
  __typename?: 'PathView';
  catalogId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  path: Scalars['String'];
  productId: Maybe<Scalars['String']>;
  type: PathTemplate;
};

export type Payment = {
  __typename?: 'Payment';
  imageUrl: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export enum PdSkinAnalyzerVariant {
  Off = 'off',
  Variant1 = 'variant1',
  Variant2 = 'variant2',
}

export type Place = {
  __typename?: 'Place';
  name: Maybe<Scalars['String']>;
  stores: Maybe<Array<Maybe<Store>>>;
};

export type Price = {
  __typename?: 'Price';
  currency: Maybe<Scalars['String']>;
  tax: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

export type PriceLevel = {
  __typename?: 'PriceLevel';
  levels: Maybe<Array<PriceLevelItem>>;
};

export type PriceLevelItem = {
  __typename?: 'PriceLevelItem';
  value: Scalars['Int'];
};

export type PricesSummaryDto = {
  __typename?: 'PricesSummaryDto';
  discountWithoutDelivery: Scalars['Int'];
  priceDelivery: Scalars['Int'];
  priceItemSummary: Scalars['Int'];
  pricePayment: Scalars['Int'];
  priceProductsSummary: Scalars['Int'];
  vat: Scalars['Int'];
};

export type PrimaryCategories = {
  __typename?: 'PrimaryCategories';
  category: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
  subCategory: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type PrimaryCategoryObject = {
  __typename?: 'PrimaryCategoryObject';
  category: Maybe<CategorizationItem>;
  kind: Maybe<CategorizationItem>;
  subCategory: Maybe<CategorizationItem>;
  type: Maybe<CategorizationItem>;
};

export type Product = {
  __typename?: 'Product';
  alternateSites: Maybe<Array<Maybe<AlternateSite>>>;
  annotation: Maybe<Scalars['String']>;
  availableSets: Maybe<ProductAvailableSets>;
  /** @deprecated Use brandInfo. */
  brand: Maybe<Scalars['String']>;
  /** @deprecated Use brandInfo. */
  brandId: Maybe<Scalars['Int']>;
  brandInfo: Maybe<Brand>;
  /** @deprecated Use brandInfo. */
  brandRef: Maybe<Brand>;
  breadcrumb: Maybe<Array<Maybe<Breadcrumb>>>;
  campaigns: Maybe<Array<Maybe<Campaign>>>;
  categorization: Categorization;
  category: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use characteristics of specific variant. */
  characteristics: Maybe<Array<Maybe<Characteristic>>>;
  code: Maybe<Scalars['String']>;
  collection: Maybe<Scalars['String']>;
  composition: Maybe<Array<Maybe<Characteristic>>>;
  config: ProductConfig;
  deliveries: Maybe<Array<Maybe<Delivery>>>;
  description: Maybe<Scalars['String']>;
  hasDifferentColors: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isForbiddenToDisplay: Maybe<Scalars['Boolean']>;
  isPerfume: Maybe<Scalars['Boolean']>;
  isShadeFinderAvailable: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  manufacturer: Maybe<Scalars['String']>;
  marketType: Maybe<Scalars['String']>;
  orderUnit: Maybe<Scalars['String']>;
  primaryCategories: PrimaryCategories;
  /** @deprecated Use reviewOverview. This return always null. */
  rating: Maybe<ProductRating>;
  related: Maybe<Array<Maybe<Product>>>;
  /** @deprecated Use 'getArticles' instead. */
  relatedArticles: Maybe<Array<Maybe<Article>>>;
  reviewOverview: Maybe<ReviewOverview>;
  /** @deprecated Use reviewOverview instead. ReviewOverview is more effective and faster to resolve. */
  reviewSummary: Maybe<ReviewSummary>;
  seo: Maybe<Seo>;
  subCategory: Maybe<Array<Maybe<Scalars['String']>>>;
  subName: Maybe<Scalars['String']>;
  type: Maybe<Array<Maybe<Scalars['String']>>>;
  unit: Maybe<Scalars['String']>;
  /** @deprecated Use perAmount directly inside unitPrice. */
  unitAmount: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  variantDisplayType: Maybe<VariantDisplayType>;
  variants: Maybe<Array<Maybe<Variant>>>;
};

export type ProductAvailableSet = {
  __typename?: 'ProductAvailableSet';
  catalogId: Maybe<Scalars['Int']>;
  type: Maybe<ProductAvailableSetType>;
  webId: Maybe<Scalars['Int']>;
};

export enum ProductAvailableSetType {
  EconomyPack = 'EconomyPack',
  GiftSet = 'GiftSet',
  TravelSet = 'TravelSet',
  Unknown = 'Unknown',
}

export type ProductAvailableSets = {
  __typename?: 'ProductAvailableSets';
  sets: Maybe<Array<Maybe<ProductAvailableSet>>>;
};

export type ProductConfig = {
  __typename?: 'ProductConfig';
  showAddToCartButton: Maybe<Scalars['Boolean']>;
  showAddToCartModal: Maybe<Scalars['Boolean']>;
};

export type ProductDelivery = {
  __typename?: 'ProductDelivery';
  date: Scalars['String'];
  dateFrom: Maybe<Scalars['String']>;
  dateTo: Maybe<Scalars['String']>;
  delivery: DeliveryType;
  disallowedReason: Maybe<DeliveryDisallowedReason>;
  name: Scalars['String'];
};

export type ProductDetailFeatures = {
  __typename?: 'ProductDetailFeatures';
  availableSets: Maybe<ProductAvailableSets>;
  composition: Maybe<Array<Characteristic>>;
  isShadeFinderAvailable: Maybe<Scalars['Boolean']>;
  selfChoiceBox: Maybe<SelfChoiceBox>;
};

export type ProductDocumentation = {
  __typename?: 'ProductDocumentation';
  composition: Maybe<CompositionTables>;
  document: Maybe<InstructionDocument>;
  foodComposition: Maybe<Scalars['String']>;
  importantNotice: Maybe<Scalars['String']>;
  ingredients: Maybe<Scalars['String']>;
  instructions: Maybe<Scalars['String']>;
  manufacturerImporter: Maybe<Scalars['String']>;
  preparationUsage: Maybe<Scalars['String']>;
  specialStorage: Maybe<Scalars['String']>;
  video: Maybe<Video>;
  warnings: Maybe<Scalars['String']>;
};

export enum ProductIdType {
  Catalog = 'Catalog',
  Web = 'Web',
}

export type ProductIdentifiers = {
  __typename?: 'ProductIdentifiers';
  catalogId: Maybe<Scalars['Int']>;
  catalogMasterId: Maybe<Scalars['Int']>;
  webMasterId: Maybe<Scalars['Int']>;
};

export type ProductListItem = {
  __typename?: 'ProductListItem';
  annotation: Maybe<Scalars['String']>;
  attributes: Maybe<Scalars['JSON']>;
  canBuy: Scalars['Boolean'];
  id: Maybe<Scalars['ID']>;
  images: ImagesByCategory;
  name: Maybe<Scalars['String']>;
  parameters: Maybe<Parameters>;
  price: Maybe<Price>;
  productCode: Maybe<Scalars['String']>;
  showWatchdog: Scalars['Boolean'];
  stockAvailability: Maybe<StockInfo>;
  wishlisted: Maybe<Scalars['Boolean']>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  currencyCode: Maybe<Scalars['String']>;
  originalPrice: Maybe<Scalars['Float']>;
  price: Maybe<Scalars['Float']>;
  productId: Maybe<Scalars['String']>;
  unitPrice: Maybe<Scalars['Float']>;
};

export type ProductRating = {
  __typename?: 'ProductRating';
  score: Maybe<Scalars['Float']>;
  votes: Maybe<Scalars['Int']>;
};

export type ProductRecommendation = {
  __typename?: 'ProductRecommendation';
  recommendationAttributes: Maybe<RecommendationAttribute>;
  recommendedProducts: Maybe<Array<Maybe<RecommendationProduct>>>;
};

export type ProductToCartInput = {
  count: Scalars['Int'];
  isComparisonPrice?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};

export type ProductVariantCoreInfo = {
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  productCode: Maybe<Scalars['String']>;
  stockAvailability: Maybe<StockInfo>;
  url: Maybe<Scalars['String']>;
  wishlisted: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  AdminPanelInfo: Maybe<AdminPanelInfo>;
  AppConfig: AppConfig;
  CartServicesIncluded: Maybe<ServicesIncluded>;
  ClientCache: Maybe<ClientCache>;
  Footer: Maybe<Footer>;
  GetShoppingCart: Maybe<ShoppingCartModel>;
  ProductSsrData: Maybe<ClientProductSsr>;
  RequestHeaders: Maybe<ClientHeaders>;
  RichContentAvailable: RichContentAvailable;
  RichContentByCatalogMasterId: RichContent;
  RichContentData: RichContentAvailability;
  RoutineByProductId: RoutineAvailability;
  RoutineItem: Maybe<VpProduct>;
  Settings: Settings;
  /** @deprecated Use WishlistByLoggedUserOrOwner */
  WishlistByLoggedUser: Maybe<Wishlist>;
  WishlistByLoggedUserOrOwner: Maybe<Wishlist>;
  /** @deprecated Use WishlistByLoggedUserOrOwner */
  WishlistByOwner: Maybe<Wishlist>;
  activeWatchdogs: Maybe<ActiveWatchdogsResponse>;
  brand: Maybe<Brand>;
  brands: Maybe<Array<Maybe<BrandItem>>>;
  checkUserNewsletterStatus: Scalars['Boolean'];
  collectStoresByProductId: Maybe<Array<ClickAndCollectStore>>;
  currencies: Maybe<Array<Maybe<Currency>>>;
  currentCurrency: Currency;
  currentLocale: Locale;
  dummy: Maybe<Scalars['String']>;
  /** @deprecated Use 'eventCalendars' instead */
  eventCalendarByYear: Array<Maybe<EventCalendarDay>>;
  eventCalendars: Maybe<Array<Maybe<EventCalendar>>>;
  externalReviewLink: Maybe<Scalars['String']>;
  freetext: Maybe<Freetext>;
  getArticles: Maybe<ArticlesQueryResponse>;
  getLivestreams: Maybe<GetLivestreamResponse>;
  getProductRecommendations: Maybe<ProductRecommendation>;
  /** @deprecated Use getProductRecommendations instead */
  getRecommendedProducts: Array<Scalars['String']>;
  getShadefinder: Maybe<ShadefinderResponse>;
  getUpsellingProductRecommendations: Maybe<ProductRecommendation>;
  getUpsellingProductRecommendationsForMultiple: Maybe<ProductRecommendation>;
  /** @deprecated Use getUpsellingProductRecommendations instead */
  getUpsellingProducts: Array<Scalars['String']>;
  /** @deprecated Use getUpsellingProductRecommendationsForMultiple instead */
  getUpsellingProductsForMultiple: Array<Scalars['String']>;
  inactiveWatchdogs: Maybe<InactiveWatchdogsResponse>;
  isValidEmail: ValidEmail;
  languagedMessages: LanguagedMessage;
  languagedMessagesByPrefixes: LanguagedMessage;
  localeByShopId: Maybe<Locale>;
  locales: Maybe<Array<Maybe<Locale>>>;
  loggedInUserReview: Maybe<LoggedInUserReview>;
  modifaceHairLicenceByHostName: Scalars['String'];
  modifaceLicenceByHostName: Scalars['String'];
  product: Product;
  productBySCode: Maybe<VpProduct>;
  productDetailByCatalogMasterId: Maybe<CatalogProduct>;
  /** @deprecated Use productDetailByCatalogMasterId instead */
  productDetailByMasterId: Maybe<Array<Maybe<Product>>>;
  productDetailByStore: Maybe<StoreProduct>;
  productIdBySCode: Maybe<Scalars['Int']>;
  productIdsByWebId: Maybe<ProductIdentifiers>;
  productListItemByMasterIds: Array<Maybe<MasterProductListItem>>;
  productMasterIdByVariantId: Maybe<Scalars['String']>;
  /** @deprecated Use productDetailByCatalogMasterId instead */
  productsById: Maybe<Array<Maybe<Product>>>;
  productsPriceById: Maybe<Array<Maybe<ProductPrice>>>;
  productsPriceByIdAndShop: Maybe<Array<Maybe<ProductPrice>>>;
  resolveUrl: PathView;
  reviews: Array<Review>;
  reviewsSummary: ReviewSummary;
  reviewsSummaryByCategorizationKinds: ReviewSummary;
  samplingIsValidEmail: ValidEmail;
  shopSettings: Maybe<Shop>;
  tnbCampaign: Maybe<TnbCampaign>;
  tnbProductRecom: Array<Maybe<Scalars['String']>>;
  tnbProducts: Array<Maybe<TnBProduct>>;
  tnbSettings: TnbSettings;
  tnbToken: TnBToken;
  user: Maybe<User>;
  userProductsInfo: Maybe<Array<Maybe<UserProductInfo>>>;
  /** @deprecated Use userProductsInfo. Comparison price is determined by Product Id instead of Master Id as before. */
  userProductsInfoByMasterIds: Maybe<Array<Maybe<UserProductInfo>>>;
  validateText: ValidateTextResult;
  vpProductById: Maybe<VpProduct>;
  vpProductByIds: Array<Maybe<VpProduct>>;
  /** @deprecated Use watchdogState. Send catalogId instead of productId */
  watchdogActive: WatchdogActive;
  watchdogRegistered: WatchdogRegistered;
  watchdogState: WatchdogActive;
  watchdogs: Maybe<ClientWatchdogs>;
};

export type QueryAdminPanelInfoArgs = {
  productUrl: Scalars['String'];
};

export type QueryCartServicesIncludedArgs = {
  productsIds: Scalars['String'];
};

export type QueryRichContentAvailableArgs = {
  rcKey: Scalars['String'];
  versionKey?: InputMaybe<Scalars['String']>;
};

export type QueryRichContentByCatalogMasterIdArgs = {
  catalogMasterId: Scalars['String'];
  versionKey?: InputMaybe<Scalars['String']>;
};

export type QueryRichContentDataArgs = {
  rcKey: Scalars['String'];
  versionKey?: InputMaybe<Scalars['String']>;
};

export type QueryRoutineByProductIdArgs = {
  productId?: InputMaybe<Scalars['String']>;
};

export type QueryRoutineItemArgs = {
  productId?: InputMaybe<Scalars['String']>;
};

export type QueryWishlistByLoggedUserOrOwnerArgs = {
  owner?: InputMaybe<Scalars['String']>;
};

export type QueryWishlistByOwnerArgs = {
  owner: Scalars['String'];
};

export type QueryActiveWatchdogsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryBrandArgs = {
  id: Scalars['Int'];
};

export type QueryCollectStoresByProductIdArgs = {
  productId: Scalars['Int'];
};

export type QueryEventCalendarByYearArgs = {
  type: Scalars['String'];
  year: Scalars['String'];
};

export type QueryEventCalendarsArgs = {
  type?: InputMaybe<EventCalendarType>;
};

export type QueryExternalReviewLinkArgs = {
  catalogId: Scalars['String'];
};

export type QueryFreetextArgs = {
  id: Scalars['String'];
};

export type QueryGetArticlesArgs = {
  variables: ArticlesQueryVariables;
};

export type QueryGetLivestreamsArgs = {
  variables: GetLivestreamVariables;
};

export type QueryGetProductRecommendationsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  productCode: Scalars['String'];
};

export type QueryGetRecommendedProductsArgs = {
  productCode: Scalars['String'];
};

export type QueryGetShadefinderArgs = {
  productId: Scalars['String'];
};

export type QueryGetUpsellingProductRecommendationsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  productCode: Scalars['String'];
};

export type QueryGetUpsellingProductRecommendationsForMultipleArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  productCodes: Array<Scalars['String']>;
};

export type QueryGetUpsellingProductsArgs = {
  productCode: Scalars['String'];
};

export type QueryGetUpsellingProductsForMultipleArgs = {
  productCodes: Array<Scalars['String']>;
};

export type QueryInactiveWatchdogsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryIsValidEmailArgs = {
  email: Scalars['String'];
};

export type QueryLanguagedMessagesByPrefixesArgs = {
  prefixes?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoggedInUserReviewArgs = {
  masterProductCode: Scalars['String'];
};

export type QueryModifaceHairLicenceByHostNameArgs = {
  hostName: Scalars['String'];
};

export type QueryModifaceLicenceByHostNameArgs = {
  hostName: Scalars['String'];
};

export type QueryProductArgs = {
  productUrl: Scalars['String'];
};

export type QueryProductBySCodeArgs = {
  sCode: Scalars['String'];
};

export type QueryProductDetailByCatalogMasterIdArgs = {
  catalogMasterId: Scalars['String'];
};

export type QueryProductDetailByMasterIdArgs = {
  masterId: Scalars['String'];
};

export type QueryProductDetailByStoreArgs = {
  masterId: Scalars['String'];
  storeCode: Scalars['String'];
};

export type QueryProductIdBySCodeArgs = {
  sCode: Scalars['String'];
};

export type QueryProductIdsByWebIdArgs = {
  webId: Scalars['String'];
};

export type QueryProductListItemByMasterIdsArgs = {
  masterIds: Array<Scalars['String']>;
};

export type QueryProductMasterIdByVariantIdArgs = {
  productId: Scalars['String'];
};

export type QueryProductsByIdArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryProductsPriceByIdArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryProductsPriceByIdAndShopArgs = {
  ids: Array<Scalars['String']>;
  shop?: InputMaybe<Scalars['String']>;
};

export type QueryResolveUrlArgs = {
  url: Scalars['String'];
};

export type QueryReviewsArgs = {
  code: Scalars['String'];
  hideTranslated?: InputMaybe<Scalars['Boolean']>;
  orderBy: ReviewOrderBy;
  orderDesc: Scalars['Boolean'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryReviewsSummaryArgs = {
  masterProductCode: Scalars['String'];
  ratingFloorType: RatingFloortype;
};

export type QueryReviewsSummaryByCategorizationKindsArgs = {
  kinds: Array<CategorizationKind>;
  masterProductCode: Scalars['String'];
};

export type QuerySamplingIsValidEmailArgs = {
  campaignId: Scalars['String'];
  email: Scalars['String'];
};

export type QueryTnbCampaignArgs = {
  campaignId: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
};

export type QueryTnbProductRecomArgs = {
  samples: Array<InputMaybe<Scalars['String']>>;
};

export type QueryTnbTokenArgs = {
  tokenId?: InputMaybe<Scalars['String']>;
};

export type QueryUserProductsInfoArgs = {
  productIds: Array<Scalars['String']>;
};

export type QueryUserProductsInfoByMasterIdsArgs = {
  masterIds: Array<Scalars['String']>;
};

export type QueryValidateTextArgs = {
  text: Scalars['String'];
};

export type QueryVpProductByIdArgs = {
  id: Scalars['String'];
};

export type QueryVpProductByIdsArgs = {
  idType?: InputMaybe<ProductIdType>;
  ids: Array<Scalars['String']>;
};

export type QueryWatchdogActiveArgs = {
  productId: Scalars['String'];
};

export type QueryWatchdogRegisteredArgs = {
  catalogIds: Array<Scalars['Int']>;
};

export type QueryWatchdogStateArgs = {
  catalogId: Scalars['Int'];
};

export type QuickContact = {
  __typename?: 'QuickContact';
  contactInformationUrl: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumbers: Maybe<Array<Maybe<Scalars['String']>>>;
  workingHours: Maybe<Scalars['String']>;
};

export type RcContentInput = {
  isApproved?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rich?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['String']>;
};

export type Rating = {
  id?: InputMaybe<Scalars['Int']>;
  ratingType?: InputMaybe<Scalars['String']>;
  score: Scalars['Int'];
};

export enum RatingFloortype {
  Cosmetics = 'Cosmetics',
  Health = 'Health',
  Parfums = 'Parfums',
}

export type RecentMinPrice = {
  __typename?: 'RecentMinPrice';
  currency: Maybe<Scalars['String']>;
  isActionEligible: Maybe<Scalars['Boolean']>;
  tax: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

export type RecommendationAttribute = {
  __typename?: 'RecommendationAttribute';
  attributionToken: Maybe<Scalars['String']>;
};

export type RecommendationProduct = {
  __typename?: 'RecommendationProduct';
  productCode: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['String']>;
};

export type RecommendedPrice = {
  __typename?: 'RecommendedPrice';
  currency: Scalars['String'];
  isConverted: Scalars['Boolean'];
  tax: Scalars['Float'];
  value: Scalars['Float'];
};

export type RecommendedProduct = {
  __typename?: 'RecommendedProduct';
  available: Scalars['Boolean'];
  mainImage: Scalars['String'];
  productId: Scalars['Int'];
  swatchImage: Maybe<Scalars['String']>;
  variantName: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  alreadyDisliked: Scalars['Boolean'];
  alreadyLiked: Scalars['Boolean'];
  authorType: AuthorType;
  createdDate: Scalars['String'];
  dislike: Scalars['Int'];
  id: Scalars['Int'];
  isAnonymized: Scalars['Boolean'];
  like: Scalars['Int'];
  originalShopId: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  text: Maybe<Scalars['String']>;
  translatedFrom: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export enum ReviewOrderBy {
  DateTime = 'DateTime',
  Favorite = 'Favorite',
  Rating = 'Rating',
}

export type ReviewOverview = {
  __typename?: 'ReviewOverview';
  count: Scalars['Int'];
  rating: Scalars['Float'];
  textReviewsCount: Scalars['Int'];
  translatedReviewsCount: Scalars['Int'];
};

export type ReviewRating = {
  __typename?: 'ReviewRating';
  id: Scalars['Int'];
  ratingType: Scalars['String'];
  score: Scalars['Int'];
};

export type ReviewResponse = {
  __typename?: 'ReviewResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type ReviewSummary = {
  __typename?: 'ReviewSummary';
  averageRating: Scalars['Float'];
  ratings: Array<ReviewRating>;
  score: Scalars['Int'];
  totalVotes: Scalars['Int'];
  votes: Maybe<Scalars['JSON']>;
};

export enum ReviewVoteType {
  Dislike = 'dislike',
  Like = 'like',
}

export type RichContent = {
  __typename?: 'RichContent';
  content: Maybe<Scalars['JSON']>;
  isAvailable: Scalars['Boolean'];
};

export type RichContentAvailability = {
  __typename?: 'RichContentAvailability';
  available: Scalars['Boolean'];
  richContentData: Maybe<RichContentData>;
};

export type RichContentAvailable = {
  __typename?: 'RichContentAvailable';
  available: Scalars['Boolean'];
};

export type RichContentData = {
  __typename?: 'RichContentData';
  data: Maybe<Scalars['JSON']>;
};

export enum Roles {
  Admin = 'Admin',
  Anonymous = 'Anonymous',
  Customer = 'Customer',
  Superuser = 'Superuser',
}

export enum RolloutStatusType {
  Approved = 'approved',
  NotSet = 'notSet',
  Rejected = 'rejected',
}

export type Route = {
  __typename?: 'Route';
  component: Maybe<Scalars['String']>;
  exact: Maybe<Scalars['Boolean']>;
  path: Maybe<Scalars['String']>;
  rolesOnly: Maybe<Array<Maybe<Roles>>>;
};

export type Routine = {
  __typename?: 'Routine';
  created: Maybe<Scalars['String']>;
  header: Maybe<RoutineHeader>;
  id: Scalars['ID'];
  isActive: Maybe<Scalars['Boolean']>;
  items: Maybe<Array<Maybe<RoutineItem>>>;
  ownerName: Maybe<Scalars['String']>;
  shops: Maybe<Array<Maybe<Scalars['String']>>>;
  updated: Maybe<Scalars['String']>;
};

export type RoutineAsset = {
  __typename?: 'RoutineAsset';
  alt: Maybe<Scalars['String']>;
  src: Maybe<Scalars['String']>;
  type: Maybe<RoutineAssetEnum>;
};

export enum RoutineAssetEnum {
  Image = 'Image',
  Video = 'Video',
}

export type RoutineAvailability = {
  __typename?: 'RoutineAvailability';
  available: Scalars['Boolean'];
  routine: Maybe<Routine>;
};

export type RoutineHeader = {
  __typename?: 'RoutineHeader';
  asset: Maybe<RoutineAsset>;
  description: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type RoutineItem = {
  __typename?: 'RoutineItem';
  description: Maybe<Scalars['String']>;
  isBinding: Maybe<Scalars['Boolean']>;
  isTemplate: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Int']>;
  product: Maybe<VpProduct>;
  productCode: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
};

export type SamplingCustomer = {
  __typename?: 'SamplingCustomer';
  address: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type SamplingOrderResponse = {
  __typename?: 'SamplingOrderResponse';
  campaignId: Maybe<Scalars['String']>;
  customer: Maybe<SamplingCustomer>;
  id: Maybe<Scalars['String']>;
  shopId: Maybe<Scalars['String']>;
};

export type SaveShadefinderUserDataResult = {
  __typename?: 'SaveShadefinderUserDataResult';
  success: Maybe<Scalars['Boolean']>;
};

export enum SectionType {
  Other = 'Other',
  UpcomingHolidays = 'UpcomingHolidays',
  WhyShopAtNotino = 'WhyShopAtNotino',
}

export type SelfChoiceBox = {
  __typename?: 'SelfChoiceBox';
  sampleProductId: Maybe<Scalars['Int']>;
};

export type Seo = {
  __typename?: 'Seo';
  description: Maybe<Scalars['String']>;
  keywords: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ServiceDto = {
  __typename?: 'ServiceDto';
  cartItemId: Maybe<Scalars['Int']>;
  cartServiceItem: Maybe<Scalars['Int']>;
  groupName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  ident: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['Int']>;
  serviceName: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type ServiceItemsDto = {
  __typename?: 'ServiceItemsDto';
  ecoPackaging: Maybe<CartContentProduct>;
  fundRaising: Maybe<CartContentProduct>;
  giftPackagings: Array<CartContentProduct>;
  insurance: Maybe<CartContentProduct>;
};

export type ServicesIncluded = {
  __typename?: 'ServicesIncluded';
  engravingShow: Scalars['Boolean'];
  tryFirstShow: Scalars['Boolean'];
};

export type Settings = {
  __typename?: 'Settings';
  cloudflareSettings: Maybe<Cloudflare>;
  deliveriesDisplayMode: Maybe<Scalars['String']>;
  featureSwitches: Maybe<FeatureSwitches>;
  imageDomainPath: Scalars['String'];
  isAddToCartButtonAllowed: Maybe<Scalars['String']>;
  isAddToCartModalAllowed: Maybe<Scalars['String']>;
  isEngravingAllowed: Maybe<Scalars['String']>;
  isNewShoppingCartEndpointEnabled: Maybe<Scalars['Boolean']>;
  isRoutineAllowed: Maybe<Scalars['String']>;
  isWishlistAllowed: Maybe<Scalars['Boolean']>;
  /** @deprecated Configurable fields are no longer necessary due to url-resolver. */
  routes: Array<Maybe<Route>>;
  showUnitPrices: Maybe<Scalars['Boolean']>;
};

export type Shade = {
  __typename?: 'Shade';
  bright: Maybe<Undertones>;
  dark: Maybe<Undertones>;
  medium: Maybe<Undertones>;
  mediumDark: Maybe<Undertones>;
  ultraBright: Maybe<Undertones>;
};

export type ShadefinderRecommendationInput = {
  makeupScale: Scalars['String'];
  userSelectedVariant: Scalars['Boolean'];
  variantId?: InputMaybe<Scalars['Int']>;
};

export type ShadefinderResponse = {
  __typename?: 'ShadefinderResponse';
  available: Scalars['Boolean'];
  shades: Shade;
};

export type ShadefinderUserDataInput = {
  productId: Scalars['Int'];
  recommendation?: InputMaybe<
    Array<InputMaybe<ShadefinderRecommendationInput>>
  >;
  skinTone?: InputMaybe<Scalars['String']>;
  skinUnderTone?: InputMaybe<Scalars['String']>;
};

export type Shop = {
  __typename?: 'Shop';
  countryCode: Scalars['String'];
  defaultCurrency: DefaultCurrency;
  defaultLanguage: Language;
  gdprSettings: GdprSettings;
  shopId: Scalars['String'];
  websiteId: Scalars['Int'];
};

export type ShoppingCartModel = {
  __typename?: 'ShoppingCartModel';
  boxes: Maybe<ShoppingCartModel_Boxes>;
  cartId: Maybe<Scalars['String']>;
  delivery: Maybe<CartItemDto>;
  insurance: Maybe<CartItemDto>;
  items: Maybe<Array<Maybe<CartItemDto>>>;
  payment: Maybe<CartItemDto>;
  services: Maybe<Array<Maybe<ServiceDto>>>;
};

export type ShoppingCartModel_Boxes = {
  __typename?: 'ShoppingCartModel_boxes';
  /** default field */
  success: Maybe<Scalars['String']>;
};

export type SkinAnalyzer = {
  __typename?: 'SkinAnalyzer';
  apiUrl: Scalars['String'];
  env: Scalars['String'];
};

export type SocialIcon = {
  __typename?: 'SocialIcon';
  link: Maybe<Scalars['String']>;
  name: Maybe<SocialIconName>;
};

export enum SocialIconName {
  Blog = 'Blog',
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  Twitter = 'Twitter',
  YouTube = 'YouTube',
}

export type Sorter = {
  __typename?: 'Sorter';
  desc: Maybe<Scalars['Boolean']>;
  sortBy: Maybe<Scalars['String']>;
};

export type SorterInput = {
  desc: Scalars['Boolean'];
  sortBy: Scalars['String'];
};

export enum StockAvailability {
  Last1 = 'Last1',
  Last2 = 'Last2',
  Last3 = 'Last3',
  Last4 = 'Last4',
  Last5 = 'Last5',
  MoreThan5 = 'MoreThan5',
  MoreThan20 = 'MoreThan20',
  OutOfStock = 'OutOfStock',
}

export type StockInfo = {
  __typename?: 'StockInfo';
  code: Maybe<Scalars['String']>;
  count: Maybe<Scalars['Int']>;
};

export type Store = {
  __typename?: 'Store';
  name: Maybe<Scalars['String']>;
  urlPath: Maybe<Scalars['String']>;
};

export type StoreProduct = {
  __typename?: 'StoreProduct';
  annotation: Maybe<Scalars['String']>;
  brandInfo: Maybe<Brand>;
  breadcrumb: Maybe<Array<Maybe<Breadcrumb>>>;
  categorization: Categorization;
  code: Maybe<Scalars['String']>;
  collection: Maybe<Scalars['String']>;
  composition: Maybe<Array<Maybe<Characteristic>>>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: Maybe<Scalars['String']>;
  masterCode: Maybe<Scalars['String']>;
  related: Maybe<Array<Maybe<Scalars['Int']>>>;
  reviewOverview: Maybe<ReviewOverview>;
  subName: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
  variantDisplayType: Maybe<VariantDisplayType>;
  variants: Maybe<Array<Maybe<Variant>>>;
};

export enum StrictSalesDisplayTypeFeatureFlag {
  Default = 'default',
  ShowBottomPriceAndTopPriceWhenVoucher = 'showBottomPriceAndTopPriceWhenVoucher',
  ShowBottomPriceWhenVoucher = 'showBottomPriceWhenVoucher',
}

export type Subscription = {
  __typename?: 'Subscription';
  dummy: Maybe<Scalars['String']>;
};

export type TableManipulatorInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  pager: PagerInput;
  sorter?: InputMaybe<SorterInput>;
};

export type TnBProduct = {
  __typename?: 'TnBProduct';
  accord: Maybe<Scalars['String']>;
  brand: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  group: Maybe<Array<Maybe<Scalars['String']>>>;
  img: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type TnBToken = {
  __typename?: 'TnBToken';
  id: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['String']>;
  product: Maybe<TnBTokenProduct>;
  status: Maybe<Scalars['String']>;
};

export type TnBTokenProduct = {
  __typename?: 'TnBTokenProduct';
  orderCode: Maybe<Scalars['String']>;
};

export type TnbCampaign = {
  __typename?: 'TnbCampaign';
  closeAfterItemSelect: Maybe<Scalars['Boolean']>;
  displayMarketingConsent: Maybe<Scalars['Boolean']>;
  enableMultiOpenPicker: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['Int']>;
  isActive: Maybe<Scalars['Boolean']>;
  isFirstPickerOpened: Maybe<Scalars['Boolean']>;
  isMarketingConsentRequired: Maybe<Scalars['Boolean']>;
  isSmsValidationOn: Maybe<Scalars['Boolean']>;
  isTresholdReached: Maybe<Scalars['Boolean']>;
  items: Maybe<Array<Maybe<TnbItem>>>;
  minOrderCount: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  pickerItemComponentType: Maybe<Scalars['Int']>;
  sampleCount: Maybe<Scalars['Int']>;
  sendConfirmationEmail: Maybe<Scalars['Boolean']>;
  shopId: Maybe<Scalars['String']>;
  thankYouImage: Maybe<Scalars['String']>;
};

export type TnbItem = {
  __typename?: 'TnbItem';
  additionalInfo: Maybe<Scalars['String']>;
  additionalText: Maybe<Scalars['String']>;
  brandName: Maybe<Scalars['String']>;
  customDisplayText: Maybe<Scalars['String']>;
  customImageUrl: Maybe<Scalars['String']>;
  displayOrder: Maybe<Scalars['Int']>;
  images: Maybe<Array<Maybe<TnbItemImage>>>;
  name: Maybe<Scalars['String']>;
  orderCode: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['Int']>;
  useCustomImage: Maybe<Scalars['Boolean']>;
  useCustomText: Maybe<Scalars['Boolean']>;
  variantName: Maybe<Scalars['String']>;
};

export type TnbItemImage = {
  __typename?: 'TnbItemImage';
  alt: Maybe<Scalars['String']>;
  src: Maybe<Scalars['String']>;
};

export type TnbMakeOrderResponse = {
  __typename?: 'TnbMakeOrderResponse';
  emailIsUsed: Maybe<Scalars['Boolean']>;
  error: Maybe<Scalars['String']>;
  phoneIsUsed: Maybe<Scalars['Boolean']>;
  success: Maybe<Scalars['Boolean']>;
};

export type Undertones = {
  __typename?: 'Undertones';
  cold: Maybe<DesiredShade>;
  neutral: Maybe<DesiredShade>;
  warm: Maybe<DesiredShade>;
};

export type UnitPrice = {
  __typename?: 'UnitPrice';
  perAmount: Maybe<Scalars['Int']>;
  unit: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Float']>;
};

export type UnitPriceDetail = {
  __typename?: 'UnitPriceDetail';
  currency: Maybe<Scalars['String']>;
  perAmount: Maybe<Scalars['Int']>;
  tax: Maybe<Scalars['Float']>;
  unit: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  cartId: Maybe<Scalars['String']>;
  comparisonIds: Maybe<Scalars['String']>;
  consent: Array<Scalars['String']>;
  grd: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  info: Maybe<UserInfo>;
  role: Maybe<Roles>;
  sid: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  lastName: Maybe<Scalars['String']>;
  lastName2: Maybe<Scalars['String']>;
};

export type UserProductInfo = {
  __typename?: 'UserProductInfo';
  isComparison: Maybe<Scalars['Boolean']>;
  productId: Maybe<Scalars['String']>;
};

export type ValidEmail = {
  __typename?: 'ValidEmail';
  isValidEmail: Scalars['Boolean'];
};

export type ValidateTextResult = {
  __typename?: 'ValidateTextResult';
  forbiddenWords: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Maybe<Scalars['String']>;
};

export type Variant = ProductVariantCoreInfo & {
  __typename?: 'Variant';
  additionalInfo: Maybe<Scalars['String']>;
  additionalText: Maybe<Scalars['String']>;
  annotation: Maybe<Scalars['String']>;
  attributes: Maybe<Scalars['JSON']>;
  availablePickUpStores: Maybe<Scalars['Int']>;
  campaignConfig: Maybe<CampaignConfig>;
  canBuy: Maybe<Scalars['Boolean']>;
  characteristics: Maybe<Array<Maybe<Characteristic>>>;
  color: Maybe<Scalars['String']>;
  colors: Maybe<Array<Maybe<Scalars['String']>>>;
  descriptionOverride: Maybe<Scalars['String']>;
  eanCode: Maybe<Scalars['String']>;
  engraving: Maybe<Engraving>;
  gender: Maybe<Scalars['String']>;
  /** @deprecated Use campaignConfig. */
  giftPackagingConfig: Maybe<GiftPackagingConfig>;
  hairModifaceEffects: Maybe<Array<Maybe<HairModifaceEffects>>>;
  id: Maybe<Scalars['ID']>;
  images: Maybe<Array<Maybe<Image>>>;
  imagesByCategory: Maybe<ImagesByCategory>;
  legalInformation: Maybe<LegalInformation>;
  maxOrderQty: Maybe<Scalars['Int']>;
  /** @deprecated Use modifaceData which contains more data for modiface */
  modifaceEffects: Maybe<Array<Maybe<ModifaceEffect>>>;
  modifaceMakeUpData: Maybe<Array<Maybe<Scalars['JSON']>>>;
  name: Maybe<Scalars['String']>;
  navigationValues: Maybe<Array<Maybe<Scalars['Int']>>>;
  nextDeliveryDate: Maybe<Scalars['String']>;
  orderCode: Maybe<Scalars['String']>;
  originalPrice: Maybe<OriginalPrice>;
  parameters: Maybe<Parameters>;
  price: Maybe<Price>;
  primaryCategories: PrimaryCategories;
  productCode: Maybe<Scalars['String']>;
  recentMinPrice: Maybe<RecentMinPrice>;
  seo: Maybe<Seo>;
  showWatchdog: Maybe<Scalars['Boolean']>;
  stockAvailability: Maybe<StockInfo>;
  type: Maybe<Scalars['String']>;
  unitPrice: Maybe<UnitPriceDetail>;
  url: Maybe<Scalars['String']>;
  variantName: Maybe<Scalars['String']>;
  videos: Maybe<Array<Maybe<Scalars['String']>>>;
  wishlisted: Maybe<Scalars['Boolean']>;
};

export enum VariantDisplayType {
  ColorPicker = 'ColorPicker',
  Default = 'Default',
  Select = 'Select',
  ShadesDrawer = 'ShadesDrawer',
  Thumbnail = 'Thumbnail',
  Tiles = 'Tiles',
}

export type VariantFeatures = {
  __typename?: 'VariantFeatures';
  engraving: Maybe<Engraving>;
  hairVirtualTryOnModel: Maybe<HairModifaceEffects>;
  makeUpVirtualTryOnEffects: Maybe<ModifaceEffect>;
  selfChoiceBox: Maybe<SelfChoiceBox>;
  versionedModifaceEffects: Maybe<Array<Maybe<VersionedModifaceEffects>>>;
};

export type VariantMedia = {
  __typename?: 'VariantMedia';
  mainImage: Maybe<Image>;
  others: Array<Image>;
  previewImage: Maybe<Image>;
  shadeImage: Maybe<Image>;
  videos: Array<Video>;
};

export type VersionedModifaceEffects = {
  __typename?: 'VersionedModifaceEffects';
  apiVersion: Maybe<Scalars['String']>;
  effects: Maybe<Array<Maybe<GenericModifaceEffect>>>;
  type: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['String'];
  type: VideoType;
};

export enum VideoType {
  Vimeo = 'Vimeo',
  Youtube = 'Youtube',
}

export type VitaminsAndMinerals = {
  __typename?: 'VitaminsAndMinerals';
  amount: Maybe<Scalars['Float']>;
  amountSign: Maybe<Scalars['String']>;
  measureUnit: Maybe<Scalars['String']>;
  nutritionValue: Maybe<Scalars['String']>;
  onePortionAmount: Maybe<Scalars['Float']>;
  referenceValue: Maybe<Scalars['Float']>;
  referenceValueOnePercent: Maybe<Scalars['Float']>;
  referenceValuePercent: Maybe<Scalars['Float']>;
};

export enum VoteAction {
  Add = 'add',
  Remove = 'remove',
}

export enum VoteType {
  Dislike = 'dislike',
  Like = 'like',
}

export type VoucherDto = {
  __typename?: 'VoucherDto';
  deleted: Scalars['Boolean'];
  isDeliveryVoucher: Scalars['Boolean'];
  isPercentVoucher: Scalars['Boolean'];
  value: Scalars['Int'];
};

export enum VoucherVariantFeatureFlag {
  ContrastBackground = 'contrastBackground',
  ContrastBackgroundWithLabel = 'contrastBackgroundWithLabel',
  Default = 'default',
}

export type VpProduct = ProductVariantCoreInfo & {
  __typename?: 'VpProduct';
  annotation: Maybe<Scalars['String']>;
  attributes: Maybe<Scalars['JSON']>;
  brand: Maybe<Brand>;
  canBuy: Scalars['Boolean'];
  catalogId: Maybe<Scalars['Int']>;
  catalogMasterId: Maybe<Scalars['Int']>;
  config: ProductConfig;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  imageUrl: Maybe<Scalars['String']>;
  masterId: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  orderUnit: Maybe<Scalars['String']>;
  price: Maybe<Price>;
  primaryCategories: PrimaryCategories;
  productCode: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['Int']>;
  recommendedPrice: Maybe<RecommendedPrice>;
  reviewOverview: Maybe<ReviewOverview>;
  /** @deprecated Use reviewOverview. */
  reviewSummary: Maybe<ReviewSummary>;
  showWatchdog: Scalars['Boolean'];
  stockAvailability: Maybe<StockInfo>;
  subName: Maybe<Scalars['String']>;
  unitPrice: Maybe<UnitPrice>;
  url: Maybe<Scalars['String']>;
  wishlisted: Maybe<Scalars['Boolean']>;
};

export type WatchDog = {
  __typename?: 'WatchDog';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type WatchdogActive = {
  __typename?: 'WatchdogActive';
  watchdogActive: Scalars['Boolean'];
};

export type Wishlist = {
  __typename?: 'Wishlist';
  id: Scalars['ID'];
  owner: Scalars['String'];
  products: Array<Maybe<Scalars['String']>>;
};

export type WishlistId = {
  __typename?: 'WishlistId';
  id: Scalars['ID'];
};

export type TnbMakeOrderInput = {
  acceptedNewsletterOffer?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  productIds: Array<Scalars['Int']>;
  sendConfirmationEmail?: InputMaybe<Scalars['Boolean']>;
  smsCode?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type TnbOrderInput = {
  customer: CustomerInput;
  samples: Array<InputMaybe<Scalars['String']>>;
  token: Scalars['String'];
};

export type TnbOrderResponse = {
  __typename?: 'tnbOrderResponse';
  orderNumber: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TnbSettings = {
  __typename?: 'tnbSettings';
  defaultGender: Scalars['String'];
  defaultGroup: Scalars['String'];
  phoneRegex: Scalars['String'];
  shopId: Scalars['String'];
  zipRegex: Scalars['String'];
};

export type TnbSmsValidationInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type WatchdogRegistered = {
  __typename?: 'watchdogRegistered';
  registeredEmails: Maybe<Scalars['JSON']>;
};

export type GetImageSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetImageSettingsQuery = {
  __typename?: 'Query';
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
};

export type UpdatePathnameQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePathnameQuery = {
  __typename?: 'Query';
  ProductSsrData: {
    __typename?: 'ClientProductSsr';
    pathname: string | null;
    productId: string | null;
    type: string | null;
  } | null;
};

export type UpdateResolveUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type UpdateResolveUrlQuery = {
  __typename?: 'Query';
  resolveUrl: {
    __typename: 'PathView';
    id: string;
    path: string;
    type: PathTemplate;
    productId: string | null;
    catalogId: string | null;
  };
};

export type GetLivestreamsQueryVariables = Exact<{
  variables: GetLivestreamVariables;
}>;

export type GetLivestreamsQuery = {
  __typename?: 'Query';
  getLivestreams: {
    __typename?: 'GetLivestreamResponse';
    shows: Array<{
      __typename?: 'Livestream';
      showId: string;
      title: string;
      description: string;
      previewImageUrl: string;
      videoLength: string;
      isLive: boolean;
      scheduledStart: string | null;
      scheduledDate: string;
      publishedDate: string | null;
      deeplink: string;
    }> | null;
  } | null;
};

export type GetPriceConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetPriceConfigQuery = {
  __typename?: 'Query';
  currentLocale: {
    __typename?: 'Locale';
    id: number | null;
    tag: string | null;
    currency: string | null;
    priceDecimalPlaces: number | null;
    priceFormat: string | null;
  };
  currentCurrency: {
    __typename?: 'Currency';
    id: number | null;
    code: string | null;
    symbol: string | null;
  };
};

export type GetProductsByIdBatchedQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetProductsByIdBatchedQuery = {
  __typename?: 'Query';
  vpProductByIds: Array<{
    __typename?: 'VpProduct';
    id: string | null;
    masterId: number | null;
    catalogMasterId: number | null;
    catalogId: number | null;
    url: string | null;
    imageUrl: string | null;
    name: string | null;
    subName: string | null;
    annotation: string | null;
    productCode: string | null;
    wishlisted: boolean | null;
    attributes: any | null;
    showWatchdog: boolean;
    canBuy: boolean;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    } | null;
    config: {
      __typename?: 'ProductConfig';
      showAddToCartModal: boolean | null;
      showAddToCartButton: boolean | null;
    };
    brand: {
      __typename?: 'Brand';
      name: string;
      url: string | null;
      id: string;
    } | null;
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    } | null;
    unitPrice: {
      __typename?: 'UnitPrice';
      value: number | null;
      unit: string | null;
      perAmount: number | null;
    } | null;
    reviewOverview: { __typename?: 'ReviewOverview'; rating: number } | null;
    primaryCategories: {
      __typename?: 'PrimaryCategories';
      category: string | null;
      subCategory: string | null;
      kind: string | null;
      type: string | null;
    };
  } | null>;
};

export type FragmentVpProductByIdBodyModifiedFragment = {
  __typename?: 'VpProduct';
  id: string | null;
  masterId: number | null;
  catalogMasterId: number | null;
  catalogId: number | null;
  url: string | null;
  imageUrl: string | null;
  name: string | null;
  subName: string | null;
  annotation: string | null;
  productCode: string | null;
  wishlisted: boolean | null;
  attributes: any | null;
  showWatchdog: boolean;
  canBuy: boolean;
  price: {
    __typename?: 'Price';
    value: number | null;
    currency: string | null;
    tax: number | null;
  } | null;
  config: {
    __typename?: 'ProductConfig';
    showAddToCartModal: boolean | null;
    showAddToCartButton: boolean | null;
  };
  brand: {
    __typename?: 'Brand';
    name: string;
    url: string | null;
    id: string;
  } | null;
  stockAvailability: {
    __typename?: 'StockInfo';
    code: string | null;
    count: number | null;
  } | null;
  unitPrice: {
    __typename?: 'UnitPrice';
    value: number | null;
    unit: string | null;
    perAmount: number | null;
  } | null;
  reviewOverview: { __typename?: 'ReviewOverview'; rating: number } | null;
  primaryCategories: {
    __typename?: 'PrimaryCategories';
    category: string | null;
    subCategory: string | null;
    kind: string | null;
    type: string | null;
  };
};

export type GetItemDataByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetItemDataByIdQuery = {
  __typename?: 'Query';
  vpProductById: {
    __typename?: 'VpProduct';
    id: string | null;
    masterId: number | null;
    catalogMasterId: number | null;
    catalogId: number | null;
    url: string | null;
    imageUrl: string | null;
    name: string | null;
    subName: string | null;
    annotation: string | null;
    productCode: string | null;
    wishlisted: boolean | null;
    attributes: any | null;
    showWatchdog: boolean;
    canBuy: boolean;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    } | null;
    config: {
      __typename?: 'ProductConfig';
      showAddToCartModal: boolean | null;
      showAddToCartButton: boolean | null;
    };
    brand: {
      __typename?: 'Brand';
      name: string;
      url: string | null;
      id: string;
    } | null;
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    } | null;
    unitPrice: {
      __typename?: 'UnitPrice';
      value: number | null;
      unit: string | null;
      perAmount: number | null;
    } | null;
    reviewOverview: { __typename?: 'ReviewOverview'; rating: number } | null;
    primaryCategories: {
      __typename?: 'PrimaryCategories';
      category: string | null;
      subCategory: string | null;
      kind: string | null;
      type: string | null;
    };
  } | null;
};

export type AddToWishlistMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type AddToWishlistMutation = {
  __typename?: 'Mutation';
  WishlistAddProduct: {
    __typename?: 'Wishlist';
    id: string;
    products: Array<string | null>;
  } | null;
};

export type IsWishlistEnabledQueryVariables = Exact<{ [key: string]: never }>;

export type IsWishlistEnabledQuery = {
  __typename?: 'Query';
  Settings: { __typename?: 'Settings'; isWishlistAllowed: boolean | null };
};

export type RemoveFromWishlistMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type RemoveFromWishlistMutation = {
  __typename?: 'Mutation';
  WishlistRemoveProduct: {
    __typename?: 'Wishlist';
    id: string;
    products: Array<string | null>;
  } | null;
};

export type GetProductPathNameQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductPathNameQuery = {
  __typename?: 'Query';
  ProductSsrData: {
    __typename?: 'ClientProductSsr';
    pathname: string | null;
  } | null;
};

export type GetProductWebViewQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type GetProductWebViewQuery = {
  __typename?: 'Query';
  resolveUrl: {
    __typename: 'PathView';
    id: string;
    path: string;
    type: PathTemplate;
    productId: string | null;
    catalogId: string | null;
  };
};

export type AdminPanelInfoBodyFragment = {
  __typename?: 'AdminPanelInfo';
  approvalStatus: RolloutStatusType | null;
  message: string | null;
  displayType: string | null;
  availableShops: Array<{
    __typename?: 'AvailableShop';
    productUrl: string;
    shopId: string;
    languageTag: string;
  }> | null;
};

export type GetAdminInfoQueryVariables = Exact<{
  productUrl: Scalars['String'];
}>;

export type GetAdminInfoQuery = {
  __typename?: 'Query';
  AppConfig: {
    __typename?: 'AppConfig';
    supportedLocales: Array<string> | null;
  };
  AdminPanelInfo: {
    __typename?: 'AdminPanelInfo';
    approvalStatus: RolloutStatusType | null;
    message: string | null;
    displayType: string | null;
    availableShops: Array<{
      __typename?: 'AvailableShop';
      productUrl: string;
      shopId: string;
      languageTag: string;
    }> | null;
  } | null;
};

export type GetRequestHeadersShopIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRequestHeadersShopIdQuery = {
  __typename?: 'Query';
  RequestHeaders: { __typename?: 'ClientHeaders'; shopId: string } | null;
};

export type GetEventCalendarByYearQueryVariables = Exact<{
  type: Scalars['String'];
  year: Scalars['String'];
}>;

export type GetEventCalendarByYearQuery = {
  __typename?: 'Query';
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
  eventCalendarByYear: Array<{
    __typename?: 'EventCalendarDay';
    date: string | null;
    url: string | null;
    title: string | null;
    description: string | null;
    giftAvailable: boolean | null;
    smallImage: string | null;
    largeImage: string | null;
    filterType: string | null;
    filterId: Array<number | null> | null;
    buttonText: string | null;
    todayItem: boolean | null;
    brand: {
      __typename?: 'Brand';
      id: string;
      name: string;
      logoUrl: string | null;
      url: string | null;
    } | null;
    brands: Array<{
      __typename?: 'Brand';
      id: string;
      name: string;
      logoUrl: string | null;
      url: string | null;
    } | null> | null;
  } | null>;
  user: { __typename?: 'User'; id: string | null; role: Roles | null } | null;
};

export type GetLocalesDataQueryVariables = Exact<{
  prefixes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetLocalesDataQuery = {
  __typename?: 'Query';
  currentLocale: {
    __typename?: 'Locale';
    id: number | null;
    tag: string | null;
    currency: string | null;
    priceDecimalPlaces: number | null;
    priceFormat: string | null;
  };
  currentCurrency: {
    __typename?: 'Currency';
    id: number | null;
    code: string | null;
    symbol: string | null;
  };
  languagedMessagesByPrefixes: {
    __typename?: 'LanguagedMessage';
    acceptLanguage: string;
    messages: any | null;
  };
};

export type GetBlogArticlesQueryVariables = Exact<{
  variables: ArticlesQueryVariables;
}>;

export type GetBlogArticlesQuery = {
  __typename?: 'Query';
  getArticles: {
    __typename?: 'ArticlesQueryResponse';
    articles: Array<{
      __typename?: 'BlogArticle';
      title: string;
      url: string;
      hreflangId: string | null;
      image: {
        __typename?: 'BlogArticleImage';
        url: string | null;
        alt: string | null;
      } | null;
    }>;
  } | null;
};

export type GetModifaceDomainQueryVariables = Exact<{ [key: string]: never }>;

export type GetModifaceDomainQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename?: 'ClientHeaders';
    modifaceDomain: string | null;
  } | null;
};

export type ModifaceHairLicenceByHostNameQueryVariables = Exact<{
  hostName: Scalars['String'];
}>;

export type ModifaceHairLicenceByHostNameQuery = {
  __typename?: 'Query';
  modifaceHairLicenceByHostName: string;
};

export type ModifaceLicenceByHostNameQueryVariables = Exact<{
  hostName: Scalars['String'];
}>;

export type ModifaceLicenceByHostNameQuery = {
  __typename?: 'Query';
  modifaceLicenceByHostName: string;
};

export type GetProductImageQueryVariables = Exact<{
  webId: Scalars['String'];
}>;

export type GetProductImageQuery = {
  __typename?: 'Query';
  vpProductById: {
    __typename?: 'VpProduct';
    id: string | null;
    imageUrl: string | null;
  } | null;
};

export type AddToClickAndCollectMutationVariables = Exact<{
  warehouseCode: Scalars['String'];
  productId: Scalars['Int'];
}>;

export type AddToClickAndCollectMutation = {
  __typename?: 'Mutation';
  addProductToClickAndCollect: {
    __typename?: 'ShoppingCartModel';
    cartId: string | null;
  } | null;
};

export type GetStoresQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;

export type GetStoresQuery = {
  __typename?: 'Query';
  collectStoresByProductId: Array<{
    __typename?: 'ClickAndCollectStore';
    name: string;
    code: string;
    description: string;
    stockCount: StockAvailability;
    earliestPickup: string;
    isOverloaded: boolean | null;
  }> | null;
};

export type SaveShadefinderUserDataMutationVariables = Exact<{
  userData: ShadefinderUserDataInput;
}>;

export type SaveShadefinderUserDataMutation = {
  __typename?: 'Mutation';
  saveShadefinderUserData: {
    __typename?: 'SaveShadefinderUserDataResult';
    success: boolean | null;
  } | null;
};

export type GetShadefinderAppIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetShadefinderAppIdQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename?: 'ClientHeaders';
    shadefinderAppId: string | null;
  } | null;
};

export type GetShadefinderQueryQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type GetShadefinderQueryQuery = {
  __typename?: 'Query';
  getShadefinder: {
    __typename?: 'ShadefinderResponse';
    available: boolean;
    shades: {
      __typename?: 'Shade';
      ultraBright: {
        __typename?: 'Undertones';
        cold: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        neutral: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        warm: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
      } | null;
      bright: {
        __typename?: 'Undertones';
        cold: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        neutral: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        warm: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
      } | null;
      medium: {
        __typename?: 'Undertones';
        cold: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        neutral: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        warm: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
      } | null;
      mediumDark: {
        __typename?: 'Undertones';
        cold: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        neutral: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        warm: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
      } | null;
      dark: {
        __typename?: 'Undertones';
        cold: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        neutral: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
        warm: {
          __typename?: 'DesiredShade';
          darker: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          ideal: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
          lighter: {
            __typename?: 'RecommendedProduct';
            available: boolean;
            productId: number;
            mainImage: string;
            swatchImage: string | null;
            variantName: string;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type UndertoneFragmentFragment = {
  __typename?: 'Undertones';
  cold: {
    __typename?: 'DesiredShade';
    darker: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    ideal: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    lighter: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
  } | null;
  neutral: {
    __typename?: 'DesiredShade';
    darker: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    ideal: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    lighter: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
  } | null;
  warm: {
    __typename?: 'DesiredShade';
    darker: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    ideal: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
    lighter: {
      __typename?: 'RecommendedProduct';
      available: boolean;
      productId: number;
      mainImage: string;
      swatchImage: string | null;
      variantName: string;
    } | null;
  } | null;
};

export type GetShopIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetShopIdQuery = {
  __typename?: 'Query';
  RequestHeaders: { __typename?: 'ClientHeaders'; shopId: string } | null;
};

export type AddToCartTryFirstMutationVariables = Exact<{
  productCode: Scalars['String'];
  productId: Scalars['String'];
  tryItFirstId: Scalars['String'];
}>;

export type AddToCartTryFirstMutation = {
  __typename?: 'Mutation';
  TryItFirstAddToCart: {
    __typename?: 'AddToCartTryItFirstResult';
    status: Array<CartProductStatus> | null;
  };
};

export type ProductInfoQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type ProductInfoQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    collection: string | null;
    annotation: string | null;
    variants: Array<{
      __typename?: 'CatalogVariant';
      attributes: any | null;
      media: {
        __typename?: 'VariantMedia';
        mainImage: {
          __typename?: 'Image';
          alt: string | null;
          src: string | null;
        } | null;
      } | null;
    }>;
    brand: { __typename?: 'Brand'; name: string } | null;
  } | null;
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
};

export type GetUserNameQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserNameQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename?: 'ClientHeaders';
    userName: string | null;
  } | null;
};

export type AddReviewMutationVariables = Exact<{
  text?: InputMaybe<Scalars['String']>;
  ratings?: InputMaybe<Array<InputMaybe<Rating>> | InputMaybe<Rating>>;
  score: Scalars['Int'];
  name: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  source: Scalars['String'];
  digests?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  captchaToken?: InputMaybe<Scalars['String']>;
}>;

export type AddReviewMutation = {
  __typename?: 'Mutation';
  addReview: { __typename?: 'ReviewResponse'; success: boolean | null } | null;
};

export type HandleVoteMutationVariables = Exact<{
  id: Scalars['String'];
  type: VoteType;
  action: VoteAction;
}>;

export type HandleVoteMutation = {
  __typename?: 'Mutation';
  handleVote: {
    __typename?: 'Review';
    id: number;
    like: number;
    dislike: number;
    alreadyLiked: boolean;
    alreadyDisliked: boolean;
  } | null;
};

export type GetRichContentByCatalogMasterIdQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
  versionKey?: InputMaybe<Scalars['String']>;
}>;

export type GetRichContentByCatalogMasterIdQuery = {
  __typename?: 'Query';
  RichContentByCatalogMasterId: {
    __typename?: 'RichContent';
    isAvailable: boolean;
    content: any | null;
  };
};

export type GetExternalReviewLinkQueryVariables = Exact<{
  catalogId: Scalars['String'];
}>;

export type GetExternalReviewLinkQuery = {
  __typename?: 'Query';
  externalReviewLink: string | null;
};

export type HasWebcamQueryVariables = Exact<{ [key: string]: never }>;

export type HasWebcamQuery = {
  __typename?: 'Query';
  ClientCache: { __typename?: 'ClientCache'; hasWebcam: boolean | null } | null;
};

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSettingsQuery = {
  __typename?: 'Query';
  Settings: {
    __typename?: 'Settings';
    isNewShoppingCartEndpointEnabled: boolean | null;
    showUnitPrices: boolean | null;
  };
};

export type GetCatalogProductViewQueryVariables = Exact<{
  masterId: Scalars['String'];
  catalogMasterId: Scalars['String'];
}>;

export type GetCatalogProductViewQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    webMasterId: string;
    catalogMasterId: string;
    masterProductCode: string;
    description: string | null;
    annotation: string | null;
    subName: string | null;
    collection: string | null;
    url: string;
    gender: Gender | null;
    kind: string | null;
    category: Array<string | null> | null;
    subCategory: Array<string | null> | null;
    type: Array<string | null> | null;
    isPerfume: boolean | null;
    related: Array<number>;
    isForbiddenToDisplay: boolean | null;
    variantDisplayType: VariantDisplayType | null;
    reviewOverview: {
      __typename?: 'ReviewOverview';
      count: number;
      rating: number;
      textReviewsCount: number;
      translatedReviewsCount: number;
    } | null;
    brand: {
      __typename?: 'Brand';
      id: string;
      name: string;
      url: string | null;
      description: string | null;
      market: string | null;
      manufacturer: string | null;
    } | null;
    features: {
      __typename?: 'ProductDetailFeatures';
      isShadeFinderAvailable: boolean | null;
      composition: Array<{
        __typename?: 'Characteristic';
        help: string | null;
        name: string | null;
        values: Array<string | null> | null;
      }> | null;
      availableSets: {
        __typename?: 'ProductAvailableSets';
        sets: Array<{
          __typename?: 'ProductAvailableSet';
          webId: number | null;
          type: ProductAvailableSetType | null;
        } | null> | null;
      } | null;
      selfChoiceBox: {
        __typename?: 'SelfChoiceBox';
        sampleProductId: number | null;
      } | null;
    };
    variants: Array<{
      __typename?: 'CatalogVariant';
      webId: string;
      catalogId: string;
      name: string | null;
      variantName: string | null;
      orderCode: string | null;
      productCode: string;
      eanCode: string;
      annotation: string | null;
      additionalInfo: string;
      additionalText: string | null;
      gender: Gender | null;
      url: string | null;
      attributes: any | null;
      navigationValues: Array<number>;
      descriptionOverride: string | null;
      colors: Array<string | null> | null;
      wishlisted: boolean | null;
      features: {
        __typename?: 'VariantFeatures';
        selfChoiceBox: {
          __typename?: 'SelfChoiceBox';
          sampleProductId: number | null;
        } | null;
        engraving: {
          __typename?: 'Engraving';
          allowed: boolean | null;
          characterCount: number | null;
          image: string | null;
          config: {
            __typename?: 'EngravingConfig';
            isAvailable: boolean | null;
            price: number | null;
          } | null;
        } | null;
        versionedModifaceEffects: Array<{
          __typename?: 'VersionedModifaceEffects';
          apiVersion: string | null;
          type: string | null;
          effects: Array<{
            __typename?: 'GenericModifaceEffect';
            upc: string | null;
            category: string | null;
            shades: any | null;
          } | null> | null;
        } | null> | null;
      } | null;
      metaData: {
        __typename?: 'Seo';
        title: string | null;
        description: string | null;
        keywords: string | null;
      };
      parameters: {
        __typename?: 'Parameters';
        amount: number | null;
        package: string | null;
        unit: string | null;
      } | null;
      price: {
        __typename?: 'Price';
        value: number | null;
        currency: string | null;
        tax: number | null;
      };
      originalPrice: {
        __typename?: 'OriginalPrice';
        value: number | null;
        currency: string | null;
        tax: number | null;
        type: string | null;
      } | null;
      unitPrice: {
        __typename?: 'UnitPriceDetail';
        value: number | null;
        currency: string | null;
        tax: number | null;
        unit: string | null;
        perAmount: number | null;
      } | null;
      recentMinPrice: {
        __typename?: 'RecentMinPrice';
        value: number | null;
        currency: string | null;
        tax: number | null;
        isActionEligible: boolean | null;
      } | null;
      media: {
        __typename?: 'VariantMedia';
        mainImage: {
          __typename?: 'Image';
          src: string | null;
          alt: string | null;
        } | null;
        previewImage: {
          __typename?: 'Image';
          src: string | null;
          alt: string | null;
        } | null;
        shadeImage: {
          __typename?: 'Image';
          src: string | null;
          alt: string | null;
        } | null;
        others: Array<{
          __typename?: 'Image';
          src: string | null;
          alt: string | null;
        }>;
        videos: Array<{ __typename?: 'Video'; id: string; type: VideoType }>;
      } | null;
      primaryCategories: {
        __typename?: 'PrimaryCategoryObject';
        category: {
          __typename?: 'CategorizationItem';
          id: number;
          name: string | null;
        } | null;
        kind: {
          __typename?: 'CategorizationItem';
          id: number;
          name: string | null;
        } | null;
        subCategory: {
          __typename?: 'CategorizationItem';
          id: number;
          name: string | null;
        } | null;
        type: {
          __typename?: 'CategorizationItem';
          id: number;
          name: string | null;
        } | null;
      } | null;
      brand: {
        __typename?: 'Brand';
        id: string;
        name: string;
        description: string | null;
        logoUrl: string | null;
        url: string | null;
        market: string | null;
        manufacturer: string | null;
      } | null;
      characteristics: Array<{
        __typename?: 'Characteristic';
        name: string | null;
        help: string | null;
        values: Array<string | null> | null;
      }>;
      availability: {
        __typename?: 'Availability';
        state: AvailabilityState;
        maxOrderQuantity: number;
        stockAvailability: StockAvailability;
        availablePickUpStores: number;
      };
      stockAvailability: {
        __typename?: 'StockInfo';
        code: string | null;
        count: number | null;
      };
      campaignConfig: {
        __typename?: 'CampaignConfig';
        allowed: EGiftPackagingAllowedType | null;
        price: number | null;
        groupName: string | null;
      } | null;
      documentation: {
        __typename?: 'ProductDocumentation';
        instructions: string | null;
        warnings: string | null;
        ingredients: string | null;
        foodComposition: string | null;
        specialStorage: string | null;
        manufacturerImporter: string | null;
        importantNotice: string | null;
        preparationUsage: string | null;
        video: { __typename?: 'Video'; id: string; type: VideoType } | null;
        composition: {
          __typename?: 'CompositionTables';
          nutritionValues: Array<{
            __typename?: 'NutritionValue';
            nutritionValue: string | null;
            amountSign: string | null;
            amount: number | null;
            measureUnit: string | null;
            variantAmount: number | null;
          }> | null;
          vitaminsAndMinerals: Array<{
            __typename?: 'VitaminsAndMinerals';
            nutritionValue: string | null;
            amountSign: string | null;
            amount: number | null;
            measureUnit: string | null;
            onePortionAmount: number | null;
            referenceValue: number | null;
            referenceValueOnePercent: number | null;
            referenceValuePercent: number | null;
          }> | null;
        } | null;
        document: {
          __typename?: 'InstructionDocument';
          url: string;
          type: InstructionDocumentType;
        } | null;
      } | null;
    }>;
    deliveries: Array<{
      __typename?: 'ProductDelivery';
      date: string;
      dateFrom: string | null;
      dateTo: string | null;
      delivery: DeliveryType;
      disallowedReason: DeliveryDisallowedReason | null;
      name: string;
    }>;
    categorization: {
      __typename?: 'Categorization';
      kinds: Array<{ __typename?: 'CategorizationItem'; id: number }> | null;
      categories: Array<{
        __typename?: 'CategorizationItem';
        id: number;
      }> | null;
      types: Array<{ __typename?: 'CategorizationItem'; id: number }> | null;
      subCategories: Array<{
        __typename?: 'CategorizationItem';
        id: number;
      }> | null;
    };
    breadcrumb: Array<{
      __typename?: 'Breadcrumb';
      name: string;
      link: string | null;
    }> | null;
    alternateSites: Array<{
      __typename?: 'AlternateSite';
      link: string | null;
      culture: string | null;
    } | null> | null;
    config: {
      __typename?: 'ProductConfig';
      showAddToCartButton: boolean | null;
      showAddToCartModal: boolean | null;
    };
    primaryCategories: {
      __typename?: 'PrimaryCategoryObject';
      category: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      kind: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      subCategory: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      type: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
    } | null;
    campaigns: Array<{
      __typename?: 'Campaign';
      campaignId: string | null;
      detailPageText: string | null;
      displayOnDetailPage: boolean | null;
      giftImageUrl: string | null;
      giftType: GiftType | null;
      productId: string | null;
      name: string | null;
      imageUrl: string | null;
      text: string | null;
    } | null> | null;
  } | null;
  Settings: {
    __typename?: 'Settings';
    imageDomainPath: string;
    deliveriesDisplayMode: string | null;
    showUnitPrices: boolean | null;
    isWishlistAllowed: boolean | null;
    isNewShoppingCartEndpointEnabled: boolean | null;
    featureSwitches: {
      __typename?: 'FeatureSwitches';
      reviewsOnServerIds: string | null;
      addToCartResendDelay: string | null;
      addToCartWarningCount: string | null;
    } | null;
  };
  shopSettings: { __typename?: 'Shop'; countryCode: string } | null;
  userProductsInfo: Array<{
    __typename?: 'UserProductInfo';
    productId: string | null;
    isComparison: boolean | null;
  } | null> | null;
  currentLocale: {
    __typename?: 'Locale';
    id: number | null;
    tag: string | null;
    currency: string | null;
    priceDecimalPlaces: number | null;
    priceFormat: string | null;
  };
  currentCurrency: {
    __typename?: 'Currency';
    id: number | null;
    code: string | null;
    symbol: string | null;
  };
  user: {
    __typename?: 'User';
    id: string | null;
    role: Roles | null;
    cartId: string | null;
    consent: Array<string>;
    comparisonIds: string | null;
    sid: string | null;
  } | null;
};

export type GetRecommendedProductsQueryVariables = Exact<{
  productCode: Scalars['String'];
  brandId?: InputMaybe<Scalars['Int']>;
}>;

export type GetRecommendedProductsQuery = {
  __typename?: 'Query';
  databreakersRecommendedProducts: {
    __typename?: 'ProductRecommendation';
    recommendationAttributes: {
      __typename?: 'RecommendationAttribute';
      attributionToken: string | null;
    } | null;
    recommendedProducts: Array<{
      __typename?: 'RecommendationProduct';
      productId: string | null;
    } | null> | null;
  } | null;
};

export type CatalogProductDetailViewFragmentFragment = {
  __typename?: 'CatalogProduct';
  webMasterId: string;
  catalogMasterId: string;
  masterProductCode: string;
  description: string | null;
  annotation: string | null;
  subName: string | null;
  collection: string | null;
  url: string;
  gender: Gender | null;
  kind: string | null;
  category: Array<string | null> | null;
  subCategory: Array<string | null> | null;
  type: Array<string | null> | null;
  isPerfume: boolean | null;
  related: Array<number>;
  isForbiddenToDisplay: boolean | null;
  variantDisplayType: VariantDisplayType | null;
  brand: {
    __typename?: 'Brand';
    id: string;
    name: string;
    url: string | null;
    description: string | null;
    market: string | null;
    manufacturer: string | null;
  } | null;
  features: {
    __typename?: 'ProductDetailFeatures';
    isShadeFinderAvailable: boolean | null;
    composition: Array<{
      __typename?: 'Characteristic';
      help: string | null;
      name: string | null;
      values: Array<string | null> | null;
    }> | null;
    availableSets: {
      __typename?: 'ProductAvailableSets';
      sets: Array<{
        __typename?: 'ProductAvailableSet';
        webId: number | null;
        type: ProductAvailableSetType | null;
      } | null> | null;
    } | null;
    selfChoiceBox: {
      __typename?: 'SelfChoiceBox';
      sampleProductId: number | null;
    } | null;
  };
  variants: Array<{
    __typename?: 'CatalogVariant';
    webId: string;
    catalogId: string;
    name: string | null;
    variantName: string | null;
    orderCode: string | null;
    productCode: string;
    eanCode: string;
    annotation: string | null;
    additionalInfo: string;
    additionalText: string | null;
    gender: Gender | null;
    url: string | null;
    attributes: any | null;
    navigationValues: Array<number>;
    descriptionOverride: string | null;
    colors: Array<string | null> | null;
    wishlisted: boolean | null;
    features: {
      __typename?: 'VariantFeatures';
      selfChoiceBox: {
        __typename?: 'SelfChoiceBox';
        sampleProductId: number | null;
      } | null;
      engraving: {
        __typename?: 'Engraving';
        allowed: boolean | null;
        characterCount: number | null;
        image: string | null;
        config: {
          __typename?: 'EngravingConfig';
          isAvailable: boolean | null;
          price: number | null;
        } | null;
      } | null;
      versionedModifaceEffects: Array<{
        __typename?: 'VersionedModifaceEffects';
        apiVersion: string | null;
        type: string | null;
        effects: Array<{
          __typename?: 'GenericModifaceEffect';
          upc: string | null;
          category: string | null;
          shades: any | null;
        } | null> | null;
      } | null> | null;
    } | null;
    metaData: {
      __typename?: 'Seo';
      title: string | null;
      description: string | null;
      keywords: string | null;
    };
    parameters: {
      __typename?: 'Parameters';
      amount: number | null;
      package: string | null;
      unit: string | null;
    } | null;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    };
    originalPrice: {
      __typename?: 'OriginalPrice';
      value: number | null;
      currency: string | null;
      tax: number | null;
      type: string | null;
    } | null;
    unitPrice: {
      __typename?: 'UnitPriceDetail';
      value: number | null;
      currency: string | null;
      tax: number | null;
      unit: string | null;
      perAmount: number | null;
    } | null;
    recentMinPrice: {
      __typename?: 'RecentMinPrice';
      value: number | null;
      currency: string | null;
      tax: number | null;
      isActionEligible: boolean | null;
    } | null;
    media: {
      __typename?: 'VariantMedia';
      mainImage: {
        __typename?: 'Image';
        src: string | null;
        alt: string | null;
      } | null;
      previewImage: {
        __typename?: 'Image';
        src: string | null;
        alt: string | null;
      } | null;
      shadeImage: {
        __typename?: 'Image';
        src: string | null;
        alt: string | null;
      } | null;
      others: Array<{
        __typename?: 'Image';
        src: string | null;
        alt: string | null;
      }>;
      videos: Array<{ __typename?: 'Video'; id: string; type: VideoType }>;
    } | null;
    primaryCategories: {
      __typename?: 'PrimaryCategoryObject';
      category: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      kind: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      subCategory: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
      type: {
        __typename?: 'CategorizationItem';
        id: number;
        name: string | null;
      } | null;
    } | null;
    brand: {
      __typename?: 'Brand';
      id: string;
      name: string;
      description: string | null;
      logoUrl: string | null;
      url: string | null;
      market: string | null;
      manufacturer: string | null;
    } | null;
    characteristics: Array<{
      __typename?: 'Characteristic';
      name: string | null;
      help: string | null;
      values: Array<string | null> | null;
    }>;
    availability: {
      __typename?: 'Availability';
      state: AvailabilityState;
      maxOrderQuantity: number;
      stockAvailability: StockAvailability;
      availablePickUpStores: number;
    };
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    };
    campaignConfig: {
      __typename?: 'CampaignConfig';
      allowed: EGiftPackagingAllowedType | null;
      price: number | null;
      groupName: string | null;
    } | null;
    documentation: {
      __typename?: 'ProductDocumentation';
      instructions: string | null;
      warnings: string | null;
      ingredients: string | null;
      foodComposition: string | null;
      specialStorage: string | null;
      manufacturerImporter: string | null;
      importantNotice: string | null;
      preparationUsage: string | null;
      video: { __typename?: 'Video'; id: string; type: VideoType } | null;
      composition: {
        __typename?: 'CompositionTables';
        nutritionValues: Array<{
          __typename?: 'NutritionValue';
          nutritionValue: string | null;
          amountSign: string | null;
          amount: number | null;
          measureUnit: string | null;
          variantAmount: number | null;
        }> | null;
        vitaminsAndMinerals: Array<{
          __typename?: 'VitaminsAndMinerals';
          nutritionValue: string | null;
          amountSign: string | null;
          amount: number | null;
          measureUnit: string | null;
          onePortionAmount: number | null;
          referenceValue: number | null;
          referenceValueOnePercent: number | null;
          referenceValuePercent: number | null;
        }> | null;
      } | null;
      document: {
        __typename?: 'InstructionDocument';
        url: string;
        type: InstructionDocumentType;
      } | null;
    } | null;
  }>;
  deliveries: Array<{
    __typename?: 'ProductDelivery';
    date: string;
    dateFrom: string | null;
    dateTo: string | null;
    delivery: DeliveryType;
    disallowedReason: DeliveryDisallowedReason | null;
    name: string;
  }>;
  categorization: {
    __typename?: 'Categorization';
    kinds: Array<{ __typename?: 'CategorizationItem'; id: number }> | null;
    categories: Array<{ __typename?: 'CategorizationItem'; id: number }> | null;
    types: Array<{ __typename?: 'CategorizationItem'; id: number }> | null;
    subCategories: Array<{
      __typename?: 'CategorizationItem';
      id: number;
    }> | null;
  };
  breadcrumb: Array<{
    __typename?: 'Breadcrumb';
    name: string;
    link: string | null;
  }> | null;
  alternateSites: Array<{
    __typename?: 'AlternateSite';
    link: string | null;
    culture: string | null;
  } | null> | null;
  config: {
    __typename?: 'ProductConfig';
    showAddToCartButton: boolean | null;
    showAddToCartModal: boolean | null;
  };
  primaryCategories: {
    __typename?: 'PrimaryCategoryObject';
    category: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    kind: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    subCategory: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    type: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
  } | null;
  campaigns: Array<{
    __typename?: 'Campaign';
    campaignId: string | null;
    detailPageText: string | null;
    displayOnDetailPage: boolean | null;
    giftImageUrl: string | null;
    giftType: GiftType | null;
    productId: string | null;
    name: string | null;
    imageUrl: string | null;
    text: string | null;
  } | null> | null;
};

export type CatalogVariantFragmentFragment = {
  __typename?: 'CatalogVariant';
  webId: string;
  catalogId: string;
  name: string | null;
  variantName: string | null;
  orderCode: string | null;
  productCode: string;
  eanCode: string;
  annotation: string | null;
  additionalInfo: string;
  additionalText: string | null;
  gender: Gender | null;
  url: string | null;
  attributes: any | null;
  navigationValues: Array<number>;
  descriptionOverride: string | null;
  colors: Array<string | null> | null;
  wishlisted: boolean | null;
  features: {
    __typename?: 'VariantFeatures';
    selfChoiceBox: {
      __typename?: 'SelfChoiceBox';
      sampleProductId: number | null;
    } | null;
    engraving: {
      __typename?: 'Engraving';
      allowed: boolean | null;
      characterCount: number | null;
      image: string | null;
      config: {
        __typename?: 'EngravingConfig';
        isAvailable: boolean | null;
        price: number | null;
      } | null;
    } | null;
    versionedModifaceEffects: Array<{
      __typename?: 'VersionedModifaceEffects';
      apiVersion: string | null;
      type: string | null;
      effects: Array<{
        __typename?: 'GenericModifaceEffect';
        upc: string | null;
        category: string | null;
        shades: any | null;
      } | null> | null;
    } | null> | null;
  } | null;
  metaData: {
    __typename?: 'Seo';
    title: string | null;
    description: string | null;
    keywords: string | null;
  };
  parameters: {
    __typename?: 'Parameters';
    amount: number | null;
    package: string | null;
    unit: string | null;
  } | null;
  price: {
    __typename?: 'Price';
    value: number | null;
    currency: string | null;
    tax: number | null;
  };
  originalPrice: {
    __typename?: 'OriginalPrice';
    value: number | null;
    currency: string | null;
    tax: number | null;
    type: string | null;
  } | null;
  unitPrice: {
    __typename?: 'UnitPriceDetail';
    value: number | null;
    currency: string | null;
    tax: number | null;
    unit: string | null;
    perAmount: number | null;
  } | null;
  recentMinPrice: {
    __typename?: 'RecentMinPrice';
    value: number | null;
    currency: string | null;
    tax: number | null;
    isActionEligible: boolean | null;
  } | null;
  media: {
    __typename?: 'VariantMedia';
    mainImage: {
      __typename?: 'Image';
      src: string | null;
      alt: string | null;
    } | null;
    previewImage: {
      __typename?: 'Image';
      src: string | null;
      alt: string | null;
    } | null;
    shadeImage: {
      __typename?: 'Image';
      src: string | null;
      alt: string | null;
    } | null;
    others: Array<{
      __typename?: 'Image';
      src: string | null;
      alt: string | null;
    }>;
    videos: Array<{ __typename?: 'Video'; id: string; type: VideoType }>;
  } | null;
  primaryCategories: {
    __typename?: 'PrimaryCategoryObject';
    category: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    kind: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    subCategory: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
    type: {
      __typename?: 'CategorizationItem';
      id: number;
      name: string | null;
    } | null;
  } | null;
  brand: {
    __typename?: 'Brand';
    id: string;
    name: string;
    description: string | null;
    logoUrl: string | null;
    url: string | null;
    market: string | null;
    manufacturer: string | null;
  } | null;
  characteristics: Array<{
    __typename?: 'Characteristic';
    name: string | null;
    help: string | null;
    values: Array<string | null> | null;
  }>;
  availability: {
    __typename?: 'Availability';
    state: AvailabilityState;
    maxOrderQuantity: number;
    stockAvailability: StockAvailability;
    availablePickUpStores: number;
  };
  stockAvailability: {
    __typename?: 'StockInfo';
    code: string | null;
    count: number | null;
  };
  campaignConfig: {
    __typename?: 'CampaignConfig';
    allowed: EGiftPackagingAllowedType | null;
    price: number | null;
    groupName: string | null;
  } | null;
  documentation: {
    __typename?: 'ProductDocumentation';
    instructions: string | null;
    warnings: string | null;
    ingredients: string | null;
    foodComposition: string | null;
    specialStorage: string | null;
    manufacturerImporter: string | null;
    importantNotice: string | null;
    preparationUsage: string | null;
    video: { __typename?: 'Video'; id: string; type: VideoType } | null;
    composition: {
      __typename?: 'CompositionTables';
      nutritionValues: Array<{
        __typename?: 'NutritionValue';
        nutritionValue: string | null;
        amountSign: string | null;
        amount: number | null;
        measureUnit: string | null;
        variantAmount: number | null;
      }> | null;
      vitaminsAndMinerals: Array<{
        __typename?: 'VitaminsAndMinerals';
        nutritionValue: string | null;
        amountSign: string | null;
        amount: number | null;
        measureUnit: string | null;
        onePortionAmount: number | null;
        referenceValue: number | null;
        referenceValueOnePercent: number | null;
        referenceValuePercent: number | null;
      }> | null;
    } | null;
    document: {
      __typename?: 'InstructionDocument';
      url: string;
      type: InstructionDocumentType;
    } | null;
  } | null;
};

export type GetCaptchaSiteKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCaptchaSiteKeyQuery = {
  __typename?: 'Query';
  AppConfig: {
    __typename?: 'AppConfig';
    captcha: { __typename?: 'CaptchaConfig'; turnstileSiteKey: string | null };
  };
};

export type GetLoadingProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetLoadingProductQuery = {
  __typename?: 'Query';
  vpProductById: {
    __typename?: 'VpProduct';
    id: string | null;
    masterId: number | null;
    catalogMasterId: number | null;
    catalogId: number | null;
    url: string | null;
    imageUrl: string | null;
    name: string | null;
    subName: string | null;
    annotation: string | null;
    productCode: string | null;
    wishlisted: boolean | null;
    attributes: any | null;
    showWatchdog: boolean;
    canBuy: boolean;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    } | null;
    config: {
      __typename?: 'ProductConfig';
      showAddToCartModal: boolean | null;
      showAddToCartButton: boolean | null;
    };
    brand: {
      __typename?: 'Brand';
      name: string;
      url: string | null;
      id: string;
    } | null;
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    } | null;
    unitPrice: {
      __typename?: 'UnitPrice';
      value: number | null;
      unit: string | null;
      perAmount: number | null;
    } | null;
    reviewOverview: { __typename?: 'ReviewOverview'; rating: number } | null;
    primaryCategories: {
      __typename?: 'PrimaryCategories';
      category: string | null;
      subCategory: string | null;
      kind: string | null;
      type: string | null;
    };
  } | null;
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
};

export type GetReviewOverviewQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type GetReviewOverviewQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    webMasterId: string;
    reviewOverview: {
      __typename?: 'ReviewOverview';
      count: number;
      rating: number;
    } | null;
  } | null;
};

export type GetSectionsAvailabilityQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type GetSectionsAvailabilityQuery = {
  __typename?: 'Query';
  RoutineByProductId: {
    __typename?: 'RoutineAvailability';
    available: boolean;
    routine: {
      __typename?: 'Routine';
      id: string;
      header: {
        __typename?: 'RoutineHeader';
        title: string | null;
        description: string | null;
        asset: {
          __typename?: 'RoutineAsset';
          type: RoutineAssetEnum | null;
          alt: string | null;
          src: string | null;
        } | null;
      } | null;
      items: Array<{
        __typename?: 'RoutineItem';
        description: string | null;
        productId: number;
      } | null> | null;
    } | null;
  };
};

export type GetServicesIncludedQueryVariables = Exact<{
  productsIds: Scalars['String'];
}>;

export type GetServicesIncludedQuery = {
  __typename?: 'Query';
  CartServicesIncluded: {
    __typename?: 'ServicesIncluded';
    engravingShow: boolean;
    tryFirstShow: boolean;
  } | null;
};

export type GetUserEmailClientQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserEmailClientQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename?: 'ClientHeaders';
    userEmail: string | null;
  } | null;
};

export type CheckEmailSamplingQueryVariables = Exact<{
  email: Scalars['String'];
  campaignId: Scalars['String'];
}>;

export type CheckEmailSamplingQuery = {
  __typename?: 'Query';
  samplingIsValidEmail: { __typename?: 'ValidEmail'; isValidEmail: boolean };
};

export type CreateSamplingOrderMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  customer: CustomerInput;
}>;

export type CreateSamplingOrderMutation = {
  __typename?: 'Mutation';
  samplingCreateOrder: {
    __typename?: 'SamplingOrderResponse';
    id: string | null;
    campaignId: string | null;
    customer: {
      __typename?: 'SamplingCustomer';
      name: string | null;
      email: string | null;
      address: string | null;
      city: string | null;
      zip: string | null;
    } | null;
  };
};

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckEmailQuery = {
  __typename?: 'Query';
  isValidEmail: { __typename?: 'ValidEmail'; isValidEmail: boolean };
};

export type FreetextQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type FreetextQuery = {
  __typename?: 'Query';
  freetext: {
    __typename?: 'Freetext';
    title: string;
    type: string;
    content: string;
  } | null;
};

export type GetTnbProducsQueryVariables = Exact<{
  tokenId?: InputMaybe<Scalars['String']>;
}>;

export type GetTnbProducsQuery = {
  __typename?: 'Query';
  tnbProducts: Array<{
    __typename?: 'TnBProduct';
    code: string | null;
    brand: string | null;
    name: string | null;
    type: string | null;
    gender: string;
    group: Array<string | null> | null;
    url: string | null;
    img: string | null;
    accord: string | null;
  } | null>;
  tnbToken: {
    __typename?: 'TnBToken';
    id: string | null;
    orderId: string | null;
    status: string | null;
    product: {
      __typename?: 'TnBTokenProduct';
      orderCode: string | null;
    } | null;
  };
  tnbSettings: {
    __typename?: 'tnbSettings';
    defaultGender: string;
    defaultGroup: string;
    phoneRegex: string;
    zipRegex: string;
  };
};

export type TnbProductRecomQueryVariables = Exact<{
  samples: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type TnbProductRecomQuery = {
  __typename?: 'Query';
  tnbProductRecom: Array<string | null>;
};

export type SendOrderMutationVariables = Exact<{
  order?: InputMaybe<TnbOrderInput>;
}>;

export type SendOrderMutation = {
  __typename?: 'Mutation';
  tnbCreateOrder: {
    __typename?: 'tnbOrderResponse';
    success: boolean;
    orderNumber: string | null;
  };
};

export type GetTnbProducsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetTnbProducsListQuery = {
  __typename?: 'Query';
  tnbProducts: Array<{
    __typename?: 'TnBProduct';
    code: string | null;
    brand: string | null;
    name: string | null;
    type: string | null;
    gender: string;
    group: Array<string | null> | null;
    url: string | null;
    img: string | null;
    accord: string | null;
  } | null>;
};

export type GetUserEmailQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserEmailQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    info: { __typename?: 'UserInfo'; email: string | null } | null;
  } | null;
};

export type GetTnbCampaignItemsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;

export type GetTnbCampaignItemsQuery = {
  __typename?: 'Query';
  tnbCampaign: {
    __typename?: 'TnbCampaign';
    pickerItemComponentType: number | null;
    items: Array<{
      __typename?: 'TnbItem';
      productId: number | null;
      displayOrder: number | null;
      orderCode: string | null;
      useCustomImage: boolean | null;
      customImageUrl: string | null;
      useCustomText: boolean | null;
      customDisplayText: string | null;
      brandName: string | null;
      name: string | null;
      variantName: string | null;
      additionalInfo: string | null;
      additionalText: string | null;
      images: Array<{
        __typename?: 'TnbItemImage';
        alt: string | null;
        src: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetTnbCampaignQueryVariables = Exact<{
  campaignId: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
}>;

export type GetTnbCampaignQuery = {
  __typename?: 'Query';
  tnbCampaign: {
    __typename?: 'TnbCampaign';
    id: number | null;
    name: string | null;
    isActive: boolean | null;
    isTresholdReached: boolean | null;
    sampleCount: number | null;
    thankYouImage: string | null;
    minOrderCount: number | null;
    enableMultiOpenPicker: boolean | null;
    closeAfterItemSelect: boolean | null;
    pickerItemComponentType: number | null;
    isFirstPickerOpened: boolean | null;
    isMarketingConsentRequired: boolean | null;
    sendConfirmationEmail: boolean | null;
    displayMarketingConsent: boolean | null;
    isSmsValidationOn: boolean | null;
    shopId: string | null;
    items: Array<{
      __typename?: 'TnbItem';
      productId: number | null;
      displayOrder: number | null;
      orderCode: string | null;
      useCustomImage: boolean | null;
      customImageUrl: string | null;
      useCustomText: boolean | null;
      customDisplayText: string | null;
      brandName: string | null;
      name: string | null;
      variantName: string | null;
      additionalInfo: string | null;
      additionalText: string | null;
      images: Array<{
        __typename?: 'TnbItemImage';
        alt: string | null;
        src: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
};

export type TnbMakeOrderMutationVariables = Exact<{
  order: TnbMakeOrderInput;
}>;

export type TnbMakeOrderMutation = {
  __typename?: 'Mutation';
  tnbMakeOrder: {
    __typename?: 'TnbMakeOrderResponse';
    success: boolean | null;
    error: string | null;
    emailIsUsed: boolean | null;
    phoneIsUsed: boolean | null;
  } | null;
};

export type TnbSmsValidationMutationVariables = Exact<{
  validationData: TnbSmsValidationInput;
}>;

export type TnbSmsValidationMutation = {
  __typename?: 'Mutation';
  tnbSmsValidation: string | null;
};

export type CopyWishlistMutationVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
  anonymousId?: InputMaybe<Scalars['String']>;
}>;

export type CopyWishlistMutation = {
  __typename?: 'Mutation';
  WishlistCopyWishlist: { __typename?: 'WishlistId'; id: string } | null;
};

export type WatchdogRegisteredQueryVariables = Exact<{
  catalogIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type WatchdogRegisteredQuery = {
  __typename?: 'Query';
  watchdogRegistered: {
    __typename?: 'watchdogRegistered';
    registeredEmails: any | null;
  };
};

export type GetWishlistByLoggedUserOrOwnerQueryVariables = Exact<{
  owner?: InputMaybe<Scalars['String']>;
}>;

export type GetWishlistByLoggedUserOrOwnerQuery = {
  __typename?: 'Query';
  WishlistByLoggedUserOrOwner: {
    __typename?: 'Wishlist';
    products: Array<string | null>;
    id: string;
  } | null;
  user: { __typename?: 'User'; id: string | null } | null;
};

export type AppFeatureSwitchesFragment = {
  __typename?: 'FeatureSwitches';
  reviewsOnServerIds: string | null;
  addToCartResendDelay: string | null;
  addToCartWarningCount: string | null;
};

export type GetCurrencyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrencyQuery = {
  __typename?: 'Query';
  currentCurrency: {
    __typename?: 'Currency';
    id: number | null;
    code: string | null;
  };
};

export type GetDefaultDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultDataQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename: 'ClientHeaders';
    role: string;
    userId: string;
    userEmail: string | null;
    userName: string | null;
    abTest: string | null;
    shopId: string;
    xUserToken: string | null;
    xLangTag: string | null;
    gatewayUrl: string;
    assetsPath: string;
    sentryDsn: string;
    sentryEnvironment: string;
    sentryTracingRate: number;
    originUrl: string | null;
    mounts: Array<string>;
    features: string;
  } | null;
  ProductSsrData: {
    __typename: 'ClientProductSsr';
    id: string | null;
    pathname: string | null;
    productId: string | null;
    type: string | null;
  } | null;
};

export type GetMasterProductInfoQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type GetMasterProductInfoQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    webMasterId: string;
    annotation: string | null;
    collection: string | null;
    kind: string | null;
    category: Array<string | null> | null;
    subCategory: Array<string | null> | null;
    type: Array<string | null> | null;
    brand: {
      __typename?: 'Brand';
      id: string;
      name: string;
      description: string | null;
      url: string | null;
      manufacturer: string | null;
      market: string | null;
    } | null;
  } | null;
};

export type GetRequestHeadersQueryVariables = Exact<{ [key: string]: never }>;

export type GetRequestHeadersQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename: 'ClientHeaders';
    shopId: string;
    xUserToken: string | null;
    xLangTag: string | null;
    gatewayUrl: string;
    userId: string;
    userName: string | null;
    userEmail: string | null;
    assetsPath: string;
    abTest: string | null;
    sentryDsn: string;
    sentryEnvironment: string;
    sentryTracingRate: number;
    originUrl: string | null;
    mounts: Array<string>;
    role: string;
    features: string;
    skinAnalyzer: { __typename?: 'SkinAnalyzer'; apiUrl: string; env: string };
    hrkDefaultCurrency: {
      __typename?: 'HrkDefaultCurrency';
      code: string;
      exchangeRates: {
        __typename?: 'HrkExchangeRates';
        EUR: number | null;
        HRK: number | null;
      } | null;
    };
    featureFlags: {
      __typename: 'FeatureFlags';
      gateway_router_proxy_enabled: boolean | null;
      spa_redirect_wishlist_to_pd: boolean | null;
      dual_currency_price: boolean | null;
      shade_finder_try_it_first_product_master_ids: Array<string> | null;
      pd_set_immediate_enabled: boolean | null;
      klarna_nushop_id: string | null;
      google_retail_api_user_events: boolean | null;
      bazaarvoice_pd_components: boolean | null;
      bazaarvoice_cookie_consent_mapping: boolean | null;
      reco_product_detail_add_to_cart: boolean | null;
      reco_product_detail: boolean | null;
      pd_has_all_reviews_page: boolean | null;
      livestream_on_pd_enabled: boolean | null;
      pd_display_rich_content_as_first_tab_brand_ids: Array<string> | null;
      pd_color_picker_drawer: boolean | null;
      voucher_variant: VoucherVariantFeatureFlag | null;
      strict_sales_display_type: StrictSalesDisplayTypeFeatureFlag | null;
      discovery_box_self_choice_enabled: boolean | null;
      pd_skin_analyzer_variant: PdSkinAnalyzerVariant | null;
      pd_360_photos: boolean | null;
      new_vto_button: boolean | null;
      pd_upselling_product_ids_for_testing: Array<string> | null;
      bazaarvoice_integration_pd: {
        __typename?: 'BazaarvoiceIntegrationType';
        siteId: string;
        locale: string;
        environment: string;
      } | null;
    };
  } | null;
};

export type GetShowUnitPricesQueryVariables = Exact<{ [key: string]: never }>;

export type GetShowUnitPricesQuery = {
  __typename?: 'Query';
  Settings: { __typename?: 'Settings'; showUnitPrices: boolean | null };
};

export type LoggedInUserReviewQueryVariables = Exact<{
  masterProductCode: Scalars['String'];
}>;

export type LoggedInUserReviewQuery = {
  __typename?: 'Query';
  loggedInUserReview: {
    __typename?: 'LoggedInUserReview';
    id: number;
    name: string;
    text: string | null;
    digests: Array<string> | null;
    score: number;
    source: string | null;
    ratings: Array<{
      __typename?: 'ReviewRating';
      id: number;
      ratingType: string;
      score: number;
    }> | null;
  } | null;
};

export type GetReviewSummaryQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type GetReviewSummaryQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    webMasterId: string;
    reviewSummary: {
      __typename?: 'ReviewSummary';
      averageRating: number;
      totalVotes: number;
      votes: any | null;
      ratings: Array<{
        __typename?: 'ReviewRating';
        id: number;
        score: number;
        ratingType: string;
      }>;
    } | null;
    reviewOverview: {
      __typename?: 'ReviewOverview';
      textReviewsCount: number;
    } | null;
  } | null;
};

export type GetReviewsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderDesc: Scalars['Boolean'];
  orderBy: ReviewOrderBy;
  code: Scalars['String'];
  hideTranslated?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetReviewsQuery = {
  __typename?: 'Query';
  reviews: Array<{
    __typename?: 'Review';
    id: number;
    text: string | null;
    userName: string;
    score: number;
    createdDate: string;
    like: number;
    dislike: number;
    alreadyLiked: boolean;
    alreadyDisliked: boolean;
    translatedFrom: string | null;
    authorType: AuthorType;
    originalShopId: string | null;
    isAnonymized: boolean;
  }>;
};

export type GetFullUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetFullUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string | null;
    cartId: string | null;
    comparisonIds: string | null;
    role: Roles | null;
    consent: Array<string>;
    sid: string | null;
    info: { __typename?: 'UserInfo'; email: string | null } | null;
  } | null;
};

export type GetGdprPathsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGdprPathsQuery = {
  __typename?: 'Query';
  shopSettings: {
    __typename?: 'Shop';
    gdprSettings: {
      __typename?: 'GdprSettings';
      termsAndConditionsUrl: string | null;
      securityAndPrivacyUrl: string | null;
    };
  } | null;
};

export type FragmentUpsellingProductFragment = {
  __typename?: 'VpProduct';
  id: string | null;
  catalogMasterId: number | null;
  url: string | null;
  imageUrl: string | null;
  name: string | null;
  annotation: string | null;
  productCode: string | null;
  wishlisted: boolean | null;
  attributes: any | null;
  showWatchdog: boolean;
  canBuy: boolean;
  masterId: number | null;
  price: {
    __typename?: 'Price';
    value: number | null;
    currency: string | null;
    tax: number | null;
  } | null;
  config: {
    __typename?: 'ProductConfig';
    showAddToCartModal: boolean | null;
    showAddToCartButton: boolean | null;
  };
  brand: {
    __typename?: 'Brand';
    name: string;
    id: string;
    url: string | null;
  } | null;
  stockAvailability: {
    __typename?: 'StockInfo';
    code: string | null;
    count: number | null;
  } | null;
  primaryCategories: {
    __typename?: 'PrimaryCategories';
    type: string | null;
    kind: string | null;
    subCategory: string | null;
    category: string | null;
  };
};

export type GetUpsellingDataQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetUpsellingDataQuery = {
  __typename?: 'Query';
  vpProductByIds: Array<{
    __typename?: 'VpProduct';
    id: string | null;
    catalogMasterId: number | null;
    url: string | null;
    imageUrl: string | null;
    name: string | null;
    annotation: string | null;
    productCode: string | null;
    wishlisted: boolean | null;
    attributes: any | null;
    showWatchdog: boolean;
    canBuy: boolean;
    masterId: number | null;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    } | null;
    config: {
      __typename?: 'ProductConfig';
      showAddToCartModal: boolean | null;
      showAddToCartButton: boolean | null;
    };
    brand: {
      __typename?: 'Brand';
      name: string;
      id: string;
      url: string | null;
    } | null;
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    } | null;
    primaryCategories: {
      __typename?: 'PrimaryCategories';
      type: string | null;
      kind: string | null;
      subCategory: string | null;
      category: string | null;
    };
  } | null>;
};

export type UpsellingProductsQueryVariables = Exact<{
  productCodes: Array<Scalars['String']> | Scalars['String'];
  brandId?: InputMaybe<Scalars['Int']>;
}>;

export type UpsellingProductsQuery = {
  __typename?: 'Query';
  getUpsellingProductRecommendationsForMultiple: {
    __typename?: 'ProductRecommendation';
    recommendationAttributes: {
      __typename?: 'RecommendationAttribute';
      attributionToken: string | null;
    } | null;
    recommendedProducts: Array<{
      __typename?: 'RecommendationProduct';
      productId: string | null;
    } | null> | null;
  } | null;
};

export type GetProductByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetProductByIdsQuery = {
  __typename?: 'Query';
  vpProductByIds: Array<{
    __typename?: 'VpProduct';
    id: string | null;
    url: string | null;
    imageUrl: string | null;
    name: string | null;
    annotation: string | null;
    productCode: string | null;
    orderUnit: string | null;
    price: {
      __typename?: 'Price';
      value: number | null;
      currency: string | null;
      tax: number | null;
    } | null;
    brand: { __typename?: 'Brand'; name: string } | null;
    stockAvailability: {
      __typename?: 'StockInfo';
      code: string | null;
      count: number | null;
    } | null;
  } | null>;
  currentCurrency: {
    __typename?: 'Currency';
    id: number | null;
    code: string | null;
    symbol: string | null;
  };
};

export type AddProductsToCartMutationVariables = Exact<{
  products: Array<ProductToCartInput> | ProductToCartInput;
  services?: InputMaybe<Array<CartServiceInput> | CartServiceInput>;
}>;

export type AddProductsToCartMutation = {
  __typename?: 'Mutation';
  AddProductsToCart: {
    __typename?: 'AddProductsResponse';
    productAddResponse: Array<{
      __typename?: 'CartProducts';
      productId: number;
      itemId: number;
      messages: Array<string>;
      productIncrease: number;
      cart: {
        __typename?: 'CartContent';
        cartId: string;
        products: Array<{
          __typename?: 'CartContentProduct';
          count: number;
          itemId: number;
          productId: number;
        } | null> | null;
      };
    } | null> | null;
    serviceAddResponse: Array<{
      __typename?: 'CartServiceAddResponse';
      status: Array<CartProductStatus | null>;
      service: {
        __typename?: 'CartAddedService';
        type: CartServiceType | null;
      } | null;
    } | null> | null;
  };
};

export type AddToCartSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AddToCartSettingsQuery = {
  __typename?: 'Query';
  Settings: {
    __typename?: 'Settings';
    isNewShoppingCartEndpointEnabled: boolean | null;
    featureSwitches: {
      __typename?: 'FeatureSwitches';
      addToCartResendDelay: string | null;
      addToCartWarningCount: string | null;
    } | null;
  };
};

export type GetUserQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  userProductsInfo: Array<{
    __typename?: 'UserProductInfo';
    productId: string | null;
    isComparison: boolean | null;
  } | null> | null;
};

export type GetRequestInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetRequestInfoQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename?: 'ClientHeaders';
    xUserToken: string | null;
    gatewayUrl: string;
  } | null;
};

export type CreateWatchdogMutationVariables = Exact<{
  catalogId: Scalars['Int'];
  email: Scalars['String'];
}>;

export type CreateWatchdogMutation = {
  __typename?: 'Mutation';
  createWatchdog: {
    __typename?: 'WatchDog';
    success: boolean;
    errors: Array<string> | null;
  };
};

export type WatchdogStateQueryVariables = Exact<{
  catalogId: Scalars['Int'];
}>;

export type WatchdogStateQuery = {
  __typename?: 'Query';
  watchdogState: { __typename?: 'WatchdogActive'; watchdogActive: boolean };
};

export type TestCampaignsQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type TestCampaignsQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename: 'CatalogProduct';
    campaigns: Array<{
      __typename: 'Campaign';
      campaignId: string | null;
      name: string | null;
      imageUrl: string | null;
      text: string | null;
      detailPageText: string | null;
      displayOnDetailPage: boolean | null;
      giftImageUrl: string | null;
      giftType: GiftType | null;
      productId: string | null;
    } | null> | null;
  } | null;
};

export type TestProductDetailByMasterIdQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type TestProductDetailByMasterIdQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename: 'CatalogProduct';
    webMasterId: string;
    url: string;
    collection: string | null;
    annotation: string | null;
    subName: string | null;
    masterProductCode: string;
    kind: string | null;
    category: Array<string | null> | null;
    subCategory: Array<string | null> | null;
    type: Array<string | null> | null;
    description: string | null;
    related: Array<number>;
    isPerfume: boolean | null;
    isForbiddenToDisplay: boolean | null;
    variantDisplayType: VariantDisplayType | null;
    brand: {
      __typename: 'Brand';
      id: string;
      name: string;
      description: string | null;
      url: string | null;
      market: string | null;
      manufacturer: string | null;
    } | null;
    breadcrumb: Array<{
      __typename: 'Breadcrumb';
      name: string;
      link: string | null;
    }> | null;
    campaigns: Array<{
      __typename: 'Campaign';
      id: string | null;
      campaignId: string | null;
      name: string | null;
      imageUrl: string | null;
      text: string | null;
      detailPageText: string | null;
      displayOnDetailPage: boolean | null;
      giftImageUrl: string | null;
      giftType: GiftType | null;
      productId: string | null;
    } | null> | null;
    alternateSites: Array<{
      __typename: 'AlternateSite';
      link: string | null;
      culture: string | null;
    } | null> | null;
    deliveries: Array<{
      __typename: 'ProductDelivery';
      name: string;
      date: string;
      delivery: DeliveryType;
      disallowedReason: DeliveryDisallowedReason | null;
    }>;
    reviewOverview: {
      __typename: 'ReviewOverview';
      count: number;
      textReviewsCount: number;
      translatedReviewsCount: number;
      rating: number;
    } | null;
    variants: Array<{
      __typename?: 'CatalogVariant';
      webId: string;
      features: {
        __typename?: 'VariantFeatures';
        engraving: { __typename?: 'Engraving'; allowed: boolean | null } | null;
      } | null;
    }>;
  } | null;
};

export type TestSendWatchDogMutationVariables = Exact<{
  catalogId: Scalars['Int'];
  email: Scalars['String'];
}>;

export type TestSendWatchDogMutation = {
  __typename?: 'Mutation';
  createWatchdog: {
    __typename?: 'WatchDog';
    success: boolean;
    errors: Array<string> | null;
  };
};

export type GetDefaultQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultQueryQuery = {
  __typename?: 'Query';
  RequestHeaders: {
    __typename: 'ClientHeaders';
    groupId: string;
    role: string;
    userId: string;
    userEmail: string | null;
    userName: string | null;
    abTest: string | null;
    shopId: string;
    xUserToken: string | null;
    xLangTag: string | null;
    gatewayUrl: string;
    assetsPath: string;
    sentryDsn: string;
    sentryEnvironment: string;
    modifaceDomain: string | null;
    shadefinderAppId: string | null;
    sentryTracingRate: number;
    originUrl: string | null;
    mounts: Array<string>;
    features: string;
    lastVisitedProducts: Array<string> | null;
    skinAnalyzer: { __typename?: 'SkinAnalyzer'; apiUrl: string; env: string };
    hrkDefaultCurrency: {
      __typename?: 'HrkDefaultCurrency';
      code: string;
      exchangeRates: {
        __typename?: 'HrkExchangeRates';
        EUR: number | null;
        HRK: number | null;
      } | null;
    };
    featureFlags: {
      __typename: 'FeatureFlags';
      gateway_router_proxy_enabled: boolean | null;
      spa_redirect_wishlist_to_pd: boolean | null;
      dual_currency_price: boolean | null;
      google_retail_api_user_events: boolean | null;
      shade_finder_try_it_first_product_master_ids: Array<string> | null;
      pd_set_immediate_enabled: boolean | null;
      klarna_nushop_id: string | null;
      bazaarvoice_pd_components: boolean | null;
      bazaarvoice_cookie_consent_mapping: boolean | null;
      reco_product_detail_add_to_cart: boolean | null;
      reco_product_detail: boolean | null;
      pd_has_all_reviews_page: boolean | null;
      livestream_on_pd_enabled: boolean | null;
      pd_display_rich_content_as_first_tab_brand_ids: Array<string> | null;
      pd_color_picker_drawer: boolean | null;
      voucher_variant: VoucherVariantFeatureFlag | null;
      strict_sales_display_type: StrictSalesDisplayTypeFeatureFlag | null;
      discovery_box_self_choice_enabled: boolean | null;
      pd_skin_analyzer_variant: PdSkinAnalyzerVariant | null;
      pd_360_photos: boolean | null;
      new_vto_button: boolean | null;
      pd_upselling_product_ids_for_testing: Array<string> | null;
      bazaarvoice_integration_pd: {
        __typename: 'BazaarvoiceIntegrationType';
        environment: string;
        locale: string;
        siteId: string;
      } | null;
    };
  } | null;
  ProductSsrData: {
    __typename: 'ClientProductSsr';
    id: string | null;
    pathname: string | null;
    productId: string | null;
    type: string | null;
  } | null;
};

export type GetProductInfoQueryVariables = Exact<{
  catalogMasterId: Scalars['String'];
}>;

export type GetProductInfoQuery = {
  __typename?: 'Query';
  productDetailByCatalogMasterId: {
    __typename?: 'CatalogProduct';
    variants: Array<{
      __typename?: 'CatalogVariant';
      webId: string;
      attributes: any | null;
      media: {
        __typename?: 'VariantMedia';
        mainImage: {
          __typename?: 'Image';
          alt: string | null;
          src: string | null;
        } | null;
      } | null;
      metaData: {
        __typename?: 'Seo';
        title: string | null;
        description: string | null;
        keywords: string | null;
      };
      stockAvailability: {
        __typename?: 'StockInfo';
        count: number | null;
        code: string | null;
      };
    }>;
  } | null;
  Settings: { __typename?: 'Settings'; imageDomainPath: string };
};
