import * as React from 'react';

import { useFunctionInBulk } from '@notino/react-web-utils';
import { Product } from '@notino/web-tracking/dist/types/package/ga4/events/products';

import { dispatchTrackingEvent } from '@context/tracking/utils';

interface ITrackDynamicListArgs {
  delay?: number;
  interaction?: string;
  listName: string;
}

export type TrackedProduct = Product & { list_position: number; id: string };

export const useTrackDynamicList = ({
  delay = 500,
  interaction = 'scroll',
  ...rest
}: ITrackDynamicListArgs) => {
  const trackProductsImpression = React.useCallback(
    (products: TrackedProduct[], params: ITrackDynamicListArgs) => {
      dispatchTrackingEvent({
        event: 'view_item_list',
        product_lists: {
          products,
          interaction,
          sorting_type: 'automatic',
          list_id: undefined,
          list_name: params.listName,
        },
        _clear: true,
      });
    },
    [interaction]
  );

  return useFunctionInBulk<
    TrackedProduct,
    // @ts-ignore
    Omit<ITrackDynamicListArgs, 'delay'>
  >(trackProductsImpression, delay, rest);
};
