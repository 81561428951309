import * as React from 'react';

import { AvailabilityState } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { useOpenWatchdogModal } from '@sharedComponents/Watchdog/useOpenWatchdogModal';

import { IWatchdogProps } from './model';
import { ButtonWrapper } from './styled';
import { useWatchdogActive } from './useWatchdogActive';

export const Watchdog = ({
  variant,
  id,
  children,
  hasRegisteredWatchdog,
}: IWatchdogProps) => {
  const { watchdogActive } = useWatchdogActive({
    variant,
    hasRegisteredWatchdog,
  });

  const openWatchdogModal = useOpenWatchdogModal({ variant });

  if (
    'showWatchdog' in variant
      ? !variant.showWatchdog
      : variant.availability.state !== AvailabilityState.ShowWatchdog
  ) {
    return null;
  }

  const handleShowModalOrDrawer: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    openWatchdogModal();
  };

  const childrenContent =
    typeof children === 'function' ? children({ watchdogActive }) : children;

  return (
    <ErrorBoundary>
      <div id={id}>
        <ButtonWrapper onClick={handleShowModalOrDrawer}>
          {childrenContent}
        </ButtonWrapper>
      </div>
    </ErrorBoundary>
  );
};
