import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ErrorBoundary from '@components/ErrorBoundary';
import { TabContent } from '@sharedComponents/TabSwitcher';

import messages from '../../messages';
import { useIsRichContentFirstTab } from '../hooks/useIsRichContentFirstTab';
import { TabIds } from '../model';
import { ShowOnDesktop, ShowOnMobile, TitleWrapper } from '../styled';

import { RichContentArea } from './RichContentArea/loadable';
import { TAB_IDENTIFIERS, TRACKING_ATTRIBUTES } from './tracking';

interface RichTabContentProps {
  productFullName: string;
  customVersionKey: string;
  isReorderedRichContentVariant: boolean;
  tabOrder?: number;
}

export const RichTabContent: React.FC<RichTabContentProps> = ({
  productFullName,
  customVersionKey,
  isReorderedRichContentVariant,
  tabOrder,
}) => {
  const isRichContentFirstTab = useIsRichContentFirstTab();

  return (
    <TabContent
      lazyLoad={!isRichContentFirstTab}
      tabIdentifier={TAB_IDENTIFIERS.richContent}
      trackingLabel={TRACKING_ATTRIBUTES.richContent}
      tabDividerMobile={isReorderedRichContentVariant}
      tabId={TabIds.RichContent}
      tabOrder={tabOrder}
      title={
        <TitleWrapper>
          <ShowOnDesktop>
            <FormattedMessage
              {...messages.rcHeader}
              values={{
                name: productFullName,
              }}
            />
          </ShowOnDesktop>

          <ShowOnMobile>
            <FormattedMessage {...messages.fixedHeaderRichContent} />
          </ShowOnMobile>
        </TitleWrapper>
      }
    >
      <ErrorBoundary>
        <RichContentArea versionKey={customVersionKey} name={productFullName} />
      </ErrorBoundary>
    </TabContent>
  );
};
