import * as React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import {
  CollapsibleItemsContainer,
  IconRegularGift,
  IconSolidInfo,
  ReactFCC,
  TooltipModel,
} from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';
import { ICampaign } from '@containers/ProductListing/model';

import messages from '../../../../messages';

import { GiftBaseItem } from './GiftBaseItem';
import {
  Title,
  GiftCount,
  CampaignsStyled,
  CampaignsWrapper,
  CollapsibleItemsContainerWrapper,
  Container,
  IconTextWrapper,
  GiftIconWrapper,
  TooltipContent,
} from './styled';
import { useGiftsTracking } from './tracking';

interface IGiftBaseProps {
  containerId: string;
  titleMessage: MessageDescriptor;
  imageMessage: MessageDescriptor;
  shouldShowPickUpInformation?: boolean;
  campaigns: ICampaign[];
}

export const GiftBase: ReactFCC<IGiftBaseProps> = React.memo(
  ({
    imageMessage,
    containerId,
    titleMessage,
    campaigns,
    shouldShowPickUpInformation,
  }) => {
    const { formatMessage } = useIntl();
    const { trackToggleGifts, trackScrollGifts } = useGiftsTracking();

    const giftsLength = campaigns.length;

    const campaignsComponent = React.useMemo(() => {
      return campaigns.map(
        ({ text, detailPageText, giftImageUrl, ...rest }) => (
          <GiftBaseItem
            key={rest.campaignId}
            descriptionText={detailPageText || text}
            imageUrl={giftImageUrl}
            imageMessage={imageMessage}
          />
        )
      );
    }, [campaigns, imageMessage]);

    return (
      <Container id={containerId} data-testid="gift-base">
        <IconTextWrapper>
          <GiftIconWrapper>
            <IconRegularGift
              color="icon.highlight"
              width="24px"
              height="24px"
            />
          </GiftIconWrapper>

          <Title>
            <FormattedMessage {...titleMessage} />
          </Title>

          {shouldShowPickUpInformation && (
            <PortalTooltip
              position={TooltipModel.Position.topCenter}
              content={
                <PortalTooltip.Content>
                  <TooltipContent>
                    <FormattedMessage
                      {...messages.giftTooltipPickUpInformation}
                    />
                  </TooltipContent>
                </PortalTooltip.Content>
              }
            >
              <IconSolidInfo color="icon.disabled" height="16px" width="16px" />
            </PortalTooltip>
          )}
          {giftsLength > 1 ? <GiftCount>({giftsLength})</GiftCount> : null}
        </IconTextWrapper>

        <CampaignsWrapper>
          <CollapsibleItemsContainerWrapper>
            <CollapsibleItemsContainer
              onToggle={trackToggleGifts}
              collapseTextButton={formatMessage(messages.showLessGifts)}
              expandTextButton={formatMessage(messages.showMoreGifts)}
              itemsToShow={2}
            >
              {campaignsComponent}
            </CollapsibleItemsContainer>
          </CollapsibleItemsContainerWrapper>

          <CampaignsStyled
            isOneCampaign={campaigns.length === 1}
            onScroll={trackScrollGifts}
          >
            {campaignsComponent}
          </CampaignsStyled>
        </CampaignsWrapper>
      </Container>
    );
  }
);
