import { useIntl } from 'react-intl';

import { StockAvailability } from '@notino/shared/definitions/types';

import { messages } from '../../messages';

export const useStockCount = (stockCount: string): string => {
  const { formatMessage } = useIntl();
  switch (stockCount) {
    case StockAvailability.Last1:
      return formatMessage(messages.lessThan5, { count: 1 });
    case StockAvailability.Last2:
      return formatMessage(messages.lessThan5, { count: 2 });
    case StockAvailability.Last3:
      return formatMessage(messages.lessThan5, { count: 3 });
    case StockAvailability.Last4:
      return formatMessage(messages.lessThan5, { count: 4 });
    case StockAvailability.Last5:
      return formatMessage(messages.lessThan5, { count: 5 });
    case StockAvailability.MoreThan20:
      return formatMessage(messages.moreThan20, {});
    case StockAvailability.MoreThan5:
      return formatMessage(messages.moreThan5, {});
  }
};
