import { CDN_DOMAIN } from '@utils/constants';

import { imageErrorHandler } from './imageHandlers';

export const resolveEngravingImageAttribute = (code: string) => {
  const imagePath = `${CDN_DOMAIN}/images/gallery/engraving/`;
  const imageSrc = `${imagePath}${code}.jpg`;
  const imageRetinaSrc = `${imagePath}${code}@2k.jpg`;

  return {
    src: imageRetinaSrc,
    srcSet: `${imageSrc} 1x, ${imageRetinaSrc} 2x`,
    onError: imageErrorHandler(require('../img/engraving.png')),
  } as const;
};
