import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, ButtonModel } from '@notino/react-styleguide';

import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { isDesktop } from '@utils/helpers';

import discoBoxImage from './discoBoxImage.webp';
import { messages } from './messages';
import {
  ContentStyledImage,
  ContentText,
  ContentWrapper,
  Title,
} from './styled';
import { trackModalClose } from './tracking';

type Props = {
  sampleProductId: number;
};

export const Content = ({ sampleProductId }: Props) => {
  const { getTimeFromInit } = useTrackingContext();

  React.useEffect(
    () => () => {
      // track only for modal, drawer close tracking is elsewhere
      if (isDesktop()) {
        trackModalClose(getTimeFromInit());
      }
    },
    [getTimeFromInit]
  );

  const trackClick = () => {
    dispatchTrackingEvent({
      event: 'element_click',
      _clear: true,
      element: {
        timing: getTimeFromInit(),
        interaction: 'click',
        name: 'discovery_box_pdp_enter',
        type: 'discovery_box_pdp',
        action: 'discovery_box_pdp_enter',
      },
    });
  };

  return (
    <ContentWrapper>
      {isDesktop() && (
        <Title>
          <FormattedMessage {...messages.modalTitle} />
        </Title>
      )}

      <ContentText>
        <FormattedMessage {...messages.modalText} />
      </ContentText>

      <ContentStyledImage src={discoBoxImage} alt="discovery box" />

      <a
        href={`/discovery-box/?add-sample-product-id=${sampleProductId}`}
        onClick={trackClick}
      >
        <Button buttonStyle={ButtonModel.Styles.primary}>
          <FormattedMessage {...messages.modalCta} />
        </Button>
      </a>
    </ContentWrapper>
  );
};
