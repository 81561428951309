import { useQuery } from '@apollo/client';

import { GetRequestInfoQuery } from '@notino/shared/definitions/types';

import getRequestInfoQuery from '../requestInfo.graphql';

interface IUseRequestHeaders {
  xUserToken?: string | null;
  gatewayUrl?: string;
}
export const useRequestHeaders = (): IUseRequestHeaders => {
  const { data: requestInfoData } =
    useQuery<GetRequestInfoQuery>(getRequestInfoQuery);
  return requestInfoData?.RequestHeaders || {};
};
