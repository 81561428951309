import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';

export const trackModifaceOpen = ({
  timing,
  product,
}: Omit<
  TrackingAttributes<{
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  }>,
  'variant'
>) => {
  dispatchTrackingEvent({
    event: 'modiface_open',
    modiface: {
      timing,
      products: [ProductEventWither().withProduct(product).forceBuild()],
      action: 'modiface_opened',
      interaction: 'click',
    },
    _clear: true,
  });
};

export const trackModifaceLoaded = ({
  timing,
  product,
}: Omit<
  TrackingAttributes<{
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  }>,
  'variant'
>) => {
  dispatchTrackingEvent({
    event: 'modiface_loaded',
    modiface: {
      timing,
      products: [ProductEventWither().withProduct(product).forceBuild()],
      action: 'modiface_loaded',
      interaction: 'automatic',
    },
    _clear: true,
  });
};

export const trackModifaceFailed = ({
  timing,
  product,
  error,
}: Omit<
  TrackingAttributes<{
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
    error: string;
  }>,
  'variant'
>) => {
  dispatchTrackingEvent({
    event: 'modiface_error',
    modiface: {
      timing,
      products: [ProductEventWither().withProduct(product).forceBuild()],
      action: 'modiface_error',
      interaction: 'automatic',
      type: 'load',
      error_message: error,
    },
    _clear: true,
  });
};

export const trackModifaceSelectClick = ({
  timing,
  product,
  selectedVariant,
  viewedVariants,
}: Omit<
  TrackingAttributes<{
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
    selectedVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
    viewedVariants: number;
  }>,
  'variant'
>) => {
  dispatchTrackingEvent({
    event: 'modiface_select_product',
    modiface: {
      timing,
      products: [
        ProductEventWither()
          .withProduct(product)
          .withVariant(selectedVariant)
          .build(),
      ],
      action: 'modiface_product_selected',
      interaction: 'click',
      viewed_variants: viewedVariants,
    },
    _clear: true,
  });
};

export const trackModifaceClosed = ({
  timing,
  product,
  selectedVariant,
  viewedVariants,
}: Omit<
  TrackingAttributes<{
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
    selectedVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
    viewedVariants: number;
  }>,
  'variant'
>) => {
  dispatchTrackingEvent({
    event: 'modiface_close',
    modiface: {
      timing,
      products: [
        ProductEventWither()
          .withProduct(product)
          .withVariant(selectedVariant)
          .build(),
      ],
      interaction: 'close_x',
      action: 'modiface_closed',
      viewed_variants: viewedVariants,
    },
    _clear: true,
  });
};
