import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chooseStore: {
    id: 'pd.clickandcollect.choose.store',
    defaultMessage: 'Vyberte pobočku',
  },
  pickHere: {
    id: 'pd.clickandcollect.pick.here',
    defaultMessage: 'Vyzvednout zde',
  },
  availableFrom: {
    id: 'pd.clickandcollect.available.from',
    defaultMessage: '{date} od {time}',
  },
  today: {
    id: 'pd.clickandcollect.today',
    defaultMessage: 'dnes',
  },
  tomorrow: {
    id: 'pd.clickandcollect.tomorrow',
    defaultMessage: 'zítra',
  },
  lessThan5: {
    id: 'pd.clickandcollect.lessThan5',
    defaultMessage: '{count} ks',
  },
  moreThan5: {
    id: 'pd.clickandcollect.moreThan5',
    defaultMessage: 'Více než 5 ks',
  },
  moreThan20: {
    id: 'pd.clickandcollect.moreThan20',
    defaultMessage: 'Více než 20 ks',
  },
  pickUpGiftInformation: {
    id: 'pd.clickandcollect.pickUp.gift.information',
    defaultMessage:
      'Na objednávky prostřednictvím služby Vyzvednout ihned se nevztahují akce dárků zdarma.',
  },
  overloaded: {
    id: 'pd.clickandcollect.overloaded',
    defaultMessage: 'Pobočka je aktuálně pro vychystání zboží přezížena',
  },
});
