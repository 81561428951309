import * as React from 'react';

import { ModalContext, ReactFCC } from '@notino/react-styleguide';

import BottomSheet from './BottomSheet';
import { DrawerCloseType } from './useCloseType';

type DrawerContent = React.ReactNode;

type ToggleDrawerOptions = Omit<
  React.ComponentProps<typeof BottomSheet>,
  'open' | 'children'
> & {
  closeType?: DrawerCloseType;
};

export type ToggleDrawerFn = (
  content?: DrawerContent,
  options?: ToggleDrawerOptions
) => void;

interface DrawerContext {
  toggleDrawer: ToggleDrawerFn;
  hideDrawer: (closeType?: DrawerCloseType) => void;
  isDrawerOpen: boolean;
}

export const DrawerContext = React.createContext<DrawerContext | undefined>(
  undefined
);

export const DrawerProvider: ReactFCC = ({ children }) => {
  const { isShown: isModalShown } = ModalContext.useModalContext();
  const innerHeight = React.useRef<number>(10000);

  const [drawerContent, setDrawerContent] =
    React.useState<DrawerContent | null>(null);
  const [options, setOptions] = React.useState<ToggleDrawerOptions | null>(
    null
  );

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = React.useCallback(
    (content?: DrawerContent, toggleOptions?: ToggleDrawerOptions) => {
      if (!content) {
        options?.onDismiss?.(toggleOptions?.closeType || 'automatic');
        setIsDrawerOpen(false);
        return;
      }

      setDrawerContent(content);
      setIsDrawerOpen(true);
      setOptions(toggleOptions);
      innerHeight.current = window.innerHeight * 0.97;
    },
    [options]
  );

  const hideDrawer = React.useCallback(
    (closeType?: DrawerCloseType) => {
      toggleDrawer(undefined, { closeType });
    },
    [toggleDrawer]
  );

  React.useEffect(() => {
    if (isModalShown) {
      hideDrawer();
    }
  }, [isModalShown, hideDrawer]);

  return (
    <DrawerContext.Provider value={{ toggleDrawer, hideDrawer, isDrawerOpen }}>
      {children}

      {!isModalShown && (
        <BottomSheet
          {...options}
          maxHeight={innerHeight.current}
          open={isDrawerOpen}
          onDismiss={(closeType) => toggleDrawer(undefined, { closeType })}
        >
          <div data-testid="mobile-drawer">{drawerContent}</div>
        </BottomSheet>
      )}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = React.useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
