import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconSolidWarning, ModalContext } from '@notino/react-styleguide';

import messages from '../messages';

import {
  ModalContent,
  SystemOverloadedMessage,
  PleaseTryAgainMessage,
} from './styled';

export const useSystemOverloadedModal = () => {
  const { toggleModal } = ModalContext.useModalContext();

  return React.useCallback(() => {
    toggleModal(
      <ModalContent>
        <IconSolidWarning height="40px" width="40px" color="icon.secondary" />
        <SystemOverloadedMessage>
          <FormattedMessage {...messages.systemOverloaded} />
        </SystemOverloadedMessage>
        <PleaseTryAgainMessage>
          <FormattedMessage {...messages.pleaseTryAgain} />
        </PleaseTryAgainMessage>
      </ModalContent>,
      {
        noBorders: true,
      }
    );
  }, [toggleModal]);
};
