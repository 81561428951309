import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackSelectOpen = (timing: number) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      type: 'Select',
      timing,
      action: 'open',
      name: 'open_variants_select',
    },
    _clear: true,
  });
};
