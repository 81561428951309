import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalModel } from '@notino/react-styleguide';

import { AddToCartModal } from '@sharedComponents/AddToCartButton/AddToCartModal';
import { StyledHeader } from '@sharedComponents/AddToCartButton/AddToCartModal/styled';
import messages from '@sharedComponents/AddToCartButton/messages';
import { IAddToCartButtonProps } from '@sharedComponents/AddToCartButton/model';
import { NotificationModalOrDrawerContent } from '@sharedComponents/AddToCartButton/NotificationModal';

import { MaxProductsInCartModal } from './MaxProductsInCartModal';

export const getModalAfterAddToCart: IAddToCartButtonProps['getModalAfterAddToCart'] =
  (props) => {
    if (props.type === 'common' && props.notification?.modalType === 'common') {
      return [
        <NotificationModalOrDrawerContent
          key="notification"
          statusMessages={props.notification.messages}
          messagesValues={props.notification.values}
        />,
        {
          center: false,
          withFocusTrap: true,
          header: (
            <StyledHeader>
              <FormattedMessage {...messages[props.notification.header]} />
            </StyledHeader>
          ),
        },
      ];
    } else if (props.type === 'upselling') {
      return [
        <AddToCartModal
          key="upselling"
          productCodes={props.products.map((product) => product.productCode)}
          remainingFreeDelivery={0}
          closingModal={props.onClosingModal}
        />,
        {
          center: false,
          withFocusTrap: true,
          header: (
            <StyledHeader>
              <FormattedMessage {...messages.addedToCart} />
            </StyledHeader>
          ),
          subHeader: props.subHeader,
          onClose: props.onClose,
          size: ModalModel.Sizes.large,
        },
      ];
    } else if (props.type === 'maxProductsInCart') {
      return [
        <MaxProductsInCartModal key="maxProductsInCart" />,
        {
          withFocusTrap: true,
        },
      ];
    }
  };
