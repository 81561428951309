import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ButtonModel,
  IconRegularDelivery,
  ModalContext,
} from '@notino/react-styleguide';

import PriceLabel from '@components/PriceLabel';
import ProgressBar from '@components/ProgressBar';
import { CART_URL } from '@utils/constants';

import messages from '../messages';

import { IAddToCartModalProps } from './model';
import {
  StyledButton,
  DeliveryFree,
  DeliveryFreeRow,
  PriceWrapper,
  CurrencyWrapper,
  ButtonsWrapper,
} from './styled';
import { UpsellingProducts } from './UpsellingProducts';

export const AddToCartModal: React.FC<IAddToCartModalProps> = ({
  productCodes,
  remainingFreeDelivery,
  closingModal,
}) => {
  const { hideModal } = ModalContext.useModalContext();

  const onContinueClick = () => {
    closingModal();
    hideModal();
  };

  return (
    <ButtonsWrapper data-testid="add-to-cart-modal">
      <StyledButton
        id="upsellingContinueWithShopping"
        buttonStyle={ButtonModel.Styles.secondary}
        onClick={onContinueClick}
        data-testid="upsellingContinueWithShopping"
      >
        <FormattedMessage {...messages.continueWithShopping} />
      </StyledButton>
      <a href={CART_URL} data-cypress="goToShoppingCart">
        <StyledButton
          data-testid="goToShoppingCart"
          buttonStyle={ButtonModel.Styles.primary}
        >
          <FormattedMessage {...messages.goToCart} />
        </StyledButton>
      </a>
      {/* This funcionality is not used at the moment, consider removal in the future */}
      {remainingFreeDelivery > 0 && (
        <DeliveryFree>
          <DeliveryFreeRow>
            <IconRegularDelivery
              color="icon.primary"
              width="18px"
              height="18px"
            />
            <FormattedMessage
              {...messages.priceMissingForFreeDelivery}
              values={{
                price: (
                  <strong>
                    <PriceLabel
                      price={{
                        value: remainingFreeDelivery,
                      }}
                      wrapperComponent={PriceWrapper}
                      currencyComponent={CurrencyWrapper}
                    />
                  </strong>
                ),
              }}
            />
          </DeliveryFreeRow>
          <ProgressBar
            name="freeDelivery"
            max={1000}
            value={700}
            color="common.green50"
            height={4}
            animationDuration={0.8}
            animationDelay={200}
          />
        </DeliveryFree>
      )}

      {productCodes && productCodes.length > 0 ? (
        <UpsellingProducts productCodes={productCodes} />
      ) : null}
    </ButtonsWrapper>
  );
};
