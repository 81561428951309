import * as React from 'react';

import {
  GiftType,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

const EMPTY_ARR = [];
export const useTryItFirstCampaigns = (
  campaigns: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['campaigns'] = EMPTY_ARR
) => {
  return React.useMemo(() => {
    return campaigns.filter(
      (campaign) => campaign && campaign.giftType === GiftType.TryItFirst
    );
  }, [campaigns]);
};
