import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { IconSolidPlayCircle } from '@notino/react-styleguide';

import ErrorBoundary from '@components/ErrorBoundary';
import { RectanglePlaceholder } from '@components/Placeholders';
import { IImageProperties } from '@helpers/utils';

import { Border, Div, Img, TopMostDiv, VideoIcon } from './styled';

export interface IImageProps extends IImageProperties {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseOver?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  isSelected: boolean;
}

export const Image: React.FC<IImageProps> = ({
  isVideo,
  onClick,
  onMouseOver,
  ...imageAttributes
}) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <ErrorBoundary>
      <TopMostDiv>
        {imageAttributes.isSelected && <Border />}

        <Div ref={ref} onMouseOver={onMouseOver} onClick={onClick}>
          {inView ? (
            <Img data-testid="slider-image" {...imageAttributes} />
          ) : (
            <RectanglePlaceholder size="8.75rem" />
          )}
          {isVideo && (
            <VideoIcon>
              <IconSolidPlayCircle
                width="24px"
                height="24px"
                color="icon.primary"
              />
            </VideoIcon>
          )}
        </Div>
      </TopMostDiv>
    </ErrorBoundary>
  );
};
