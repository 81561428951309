import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMutation } from '@apollo/client';

import { ModalContext, Toaster } from '@notino/react-styleguide';
import {
  CreateWatchdogMutation,
  CreateWatchdogMutationVariables,
} from '@notino/shared/definitions/types';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { useUser } from '@queries/useUser';
import { ToastLink } from '@sharedComponents/Watchdog/styled';
import { trackWatchdogSent } from '@sharedComponents/Watchdog/tracking';
import { isDesktop } from '@utils/helpers';

import {
  WatchdogComm,
  IWatchdogState,
  IWatchdogFields,
  IWatchdogProps,
} from './model';
import createWatchdogMutation from './queries/createWatchdog.graphql';
import { WatchdogForm } from './WatchdogForm';
import { watchdogMessages } from './watchdogMessages';

type Props = { onSuccess: () => void } & Pick<IWatchdogProps, 'variant'>;

export const WatchdogContent: React.FC<Props> = ({ variant, onSuccess }) => {
  const { formatMessage } = useIntl();
  const { isLoggedIn } = useUser();
  const { hideDrawer } = useDrawer();
  const { hideModal } = ModalContext.useModalContext();
  const [state, setState] = React.useState<IWatchdogState>({
    errors: [],
    status: WatchdogComm.IDLE,
    submitAttempts: 0,
  });

  const [saveWatchdog] = useMutation<
    CreateWatchdogMutation,
    CreateWatchdogMutationVariables
  >(createWatchdogMutation, {
    onError: () => {
      setState((prevState) => ({
        ...prevState,
        status: WatchdogComm.ERROR,
      }));
    },
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      const { errors, success } = data.createWatchdog;
      if (errors || !success) {
        setState((prevState) => ({
          ...prevState,
          errors: errors || [],
          status: WatchdogComm.ERROR,
        }));
        return;
      }

      onSuccess();

      setState((prevState) => ({
        ...prevState,
        status: WatchdogComm.IDLE,
      }));

      hideDrawer();
      hideModal();

      setTimeout(
        () =>
          Toaster.info({
            content: (
              <FormattedMessage
                {...(isLoggedIn
                  ? watchdogMessages.watchdogSentNotification
                  : watchdogMessages.watchdogSentNotificationAnonymous)}
                values={{
                  a: (chunks) => (
                    <ToastLink
                      rel="noopener noreferrer"
                      href="/mynotino/watchdog"
                    >
                      {chunks}
                    </ToastLink>
                  ),
                }}
              />
            ),
            icon: 'IconRegularCheckmark',
          }),
        isDesktop() ? 0 : 1350 // on mobile wait for drawer to close
      );
    },
  });

  const handleSubmit = React.useCallback(
    async (values: IWatchdogFields) => {
      setState((prevState) => ({
        ...prevState,
        status: WatchdogComm.SENDING,
        submitAttempts: prevState.submitAttempts + 1,
      }));

      try {
        await saveWatchdog({
          variables: {
            email: values.watchdogEmail,
            catalogId: Number(variant.catalogId),
          },
        });
        trackWatchdogSent(values.watchdogEmail, variant);
      } catch (_) {
        setState((prevState) => ({
          ...prevState,
          status: WatchdogComm.ERROR,
        }));
      }
    },
    [saveWatchdog, variant]
  );

  const errorMessage =
    state.status === WatchdogComm.ERROR
      ? formatMessage(watchdogMessages.watchdogSubmitError)
      : undefined;

  return (
    <WatchdogForm
      handleSubmit={handleSubmit}
      errorMessage={
        state.errors.length === 0 ? errorMessage : state.errors.join(' ')
      }
      submitAttempts={state.submitAttempts}
      status={state.status}
      variantUrl={variant.url}
    />
  );
};
