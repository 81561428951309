import * as React from 'react';
import { PropsWithChildren } from 'react';

export const TabOrderProvider = ({ children }: PropsWithChildren) => {
  const childrenWithTabOrderProp = React.Children.map(
    children,
    (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          tabOrder: index,
        });
      }
      return child;
    }
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childrenWithTabOrderProp}</>;
};
