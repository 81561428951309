import { useIntl } from 'react-intl';

import { ClientOnlyAttributeTypes } from '@components/Tags/model';
import { resolveDamageAttribute } from '@components/Tags/utils';
import messages from '@sharedComponents/AddToCartButton/messages';
import { IAddToCartButtonProps } from '@sharedComponents/AddToCartButton/model';

type UseMessagesForSpecialModalOrDrawer = {
  isSpecial: boolean;
  header: string | null;
  description: string | null;
};

export const useMessagesForSpecialModalOrDrawer = (
  product: IAddToCartButtonProps['product']
): UseMessagesForSpecialModalOrDrawer => {
  const { formatMessage } = useIntl();

  const isDamagedOrReturned = product?.attributes?.Damage
    ? resolveDamageAttribute(product.attributes.Damage)
    : null;

  const isProfessionalProduct = Boolean(
    product?.attributes?.IsForProfessionals
  );
  const isDamaged = isDamagedOrReturned === ClientOnlyAttributeTypes.Damaged;
  const isReturned = isDamagedOrReturned === ClientOnlyAttributeTypes.Returned;

  if (isProfessionalProduct && isDamaged) {
    return {
      isSpecial: true,
      header: formatMessage(messages.damagedProductForProfessionalChoosed),
      description: formatMessage(
        messages.damagedProductForProfessionalAcknowledgement
      ),
    };
  } else if (isProfessionalProduct && isReturned) {
    return {
      isSpecial: true,
      header: formatMessage(messages.returnedProductForProfessionalChoosed),
      description: formatMessage(
        messages.returnedProductForProfessionalAcknowledgement
      ),
    };
  } else if (isProfessionalProduct) {
    return {
      isSpecial: true,
      header: formatMessage(messages.productForProfessionalChoosed),
      description: formatMessage(
        messages.productForProfessionalAcknowledgement
      ),
    };
  } else if (isDamaged) {
    return {
      isSpecial: true,
      header: formatMessage(messages.damagedProductChoosed),
      description: formatMessage(messages.damagedProductAcknowledgement),
    };
  } else if (isReturned) {
    return {
      isSpecial: true,
      header: formatMessage(messages.returnedProductChoosed),
      description: formatMessage(messages.returnedProductAcknowledgement),
    };
  }

  return {
    isSpecial: false,
    description: null,
    header: null,
  };
};
