import { useIntl } from 'react-intl';

import { isToday, isTomorrow } from '../../../../utils/dateUtils';
import { messages } from '../../messages';

import { getEarliestPickupDate } from './utils';

export const usePickUpTime = (earliestPickup: string): string => {
  const { formatTime, formatDate, formatMessage } = useIntl();

  const earliestPickupDate = getEarliestPickupDate(new Date(earliestPickup));

  const time = formatTime(earliestPickupDate, {
    hour: 'numeric',
    minute: 'numeric',
  });

  let dateName: string;
  if (isToday(earliestPickupDate)) {
    dateName = formatMessage(messages.today);
  } else if (isTomorrow(earliestPickupDate)) {
    dateName = formatMessage(messages.tomorrow);
  } else {
    dateName = formatDate(earliestPickupDate, {
      weekday: 'long',
    });
  }

  return formatMessage(messages.availableFrom, {
    date: dateName,
    time,
  });
};
