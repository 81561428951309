import * as Cookie from 'js-cookie';

const LAST_VISITED_PRODUCTS_COOKIE_KEY = 'lastProds';
const LAST_VISITED_PRODUCTS_COOKIE_DIVIDER = '-';
const MAX_COOKIE_ITEMS_COUNT = 16;
const YEAR_DAYS_COUNT = 365;

const getLastVisitedProducts = () => {
  try {
    return Cookie.get(LAST_VISITED_PRODUCTS_COOKIE_KEY);
  } catch (e) {
    return '';
  }
};

export const loadProductsFromCookieOldestFirst = (): string[] => {
  const lastVisitedCookie = getLastVisitedProducts();
  const lastVisitedProductIdsCookie = lastVisitedCookie?.split(
    LAST_VISITED_PRODUCTS_COOKIE_DIVIDER
  );

  if (lastVisitedProductIdsCookie?.length) {
    return lastVisitedProductIdsCookie;
  }

  return [];
};

export const appendProductToCookie = (productId: string) => {
  const productsWithoutCurrentProduct =
    loadProductsFromCookieOldestFirst().filter((id) => id !== productId);
  const productIds = [...productsWithoutCurrentProduct, productId].slice(
    -1 * MAX_COOKIE_ITEMS_COUNT
  );

  removeOldParfumsCookie();

  Cookie.set(
    LAST_VISITED_PRODUCTS_COOKIE_KEY,
    productIds.join(LAST_VISITED_PRODUCTS_COOKIE_DIVIDER),
    { expires: YEAR_DAYS_COUNT }
  );
};

const removeOldParfumsCookie = () => {
  // eg. www.notino.cz
  const hostName = window.location.hostname;
  const allSubDomainsHostName = hostName.replace(/^(www\.)/, '.');

  Cookie.remove(LAST_VISITED_PRODUCTS_COOKIE_KEY, {
    domain: allSubDomainsHostName,
  });
};
