import * as React from 'react';

import styled from 'styled-components';

import {
  IBasicStyledProps,
  SemanticColorKey,
  getSemanticColor,
  theme,
} from '@notino/react-styleguide';

import { registerKeyboardListeners } from '@helpers/accessibility/registerKeyboardListeners';
import { arrayFromNumber } from '@helpers/utils';

interface IProgressProps extends IBasicStyledProps {
  heightInPx?: number;
}

const Progress = styled.div<IProgressProps>`
  height: ${(props: IProgressProps) =>
    props.heightInPx ? props.heightInPx : 8}px;
  background: ${(props: IProgressProps) =>
    props.theme.color.background.tertiary};
  position: relative;
`;

interface IBarProps extends IProgressProps {
  lineWidth: number;
  color?: SemanticColorKey;
  animationDuration?: number;
}
const Bar = styled.div<IBarProps>`
  width: ${(props) => `${props.lineWidth}%`};
  height: ${(props) => (props.heightInPx ? props.heightInPx : 8)}px;
  background: ${(props) =>
    props.color
      ? getSemanticColor(props.color)
      : props.theme.color.background.inverse};
  transition: ${(props) =>
      props.animationDuration ? props.animationDuration : 0.2}s
    ease;
`;

const Segments = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 0;
`;

interface ISegmentProps {
  selectable: boolean;
}
const Segment = styled.div<ISegmentProps>`
  width: 20%;
  height: 100%;
  cursor: ${(props) => (props.selectable ? 'pointer' : 'auto')};

  &:not(:last-child) {
    border-right: 1px solid ${theme.color.common.white};
  }
`;

interface IProgressBarProps {
  max: number;
  value: number;
  segments?: boolean;
  selectable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: any;
  color?: SemanticColorKey;
  height?: number;
  animationDuration?: number;
  animationDelay?: number;
  name: string;
  label?: string;
}

const ProgressBar = ({
  selectable,
  onSelect,
  segments,
  color,
  height,
  name,
  value,
  max,
  animationDuration,
  label,
}: IProgressBarProps) => {
  const calcWidth = (segment: number): number => {
    let percent = selectable ? segment * 20 : (value / max) * 100;

    if (segments) {
      percent = (percent > 0 ? Math.round(percent / 20) : 0) * 20;
    }

    if (percent > 100) {
      percent = 100;
    }

    return percent;
  };

  const [selectedSegment, setSelectedSegment] = React.useState(value);
  const [width, setWidth] = React.useState(calcWidth(value));

  const onSegmentClick = (segment) => {
    if (selectable) {
      onSelect(segment);
      setSelectedSegment(segment);
      setWidth(calcWidth(segment));
    }
  };

  const onSegmentHover = (segment) => {
    if (selectable) {
      setWidth(calcWidth(segment));
    }
  };

  const onProgressLeave = () => {
    if (selectable) {
      setWidth(calcWidth(selectedSegment));
    }
  };

  return (
    <Progress
      heightInPx={height}
      onMouseLeave={onProgressLeave}
      aria-label={label}
    >
      {segments && (
        <Segments>
          {arrayFromNumber(5).map((i) => {
            const handleSelect = () => onSegmentClick(i);
            return (
              <Segment
                key={i}
                aria-label={`${i}`}
                data-cypress={`${name}_segment_${i}`}
                selectable={selectable}
                tabIndex={selectable ? 0 : -1}
                onClick={handleSelect}
                onMouseEnter={() => onSegmentHover(i)}
                {...registerKeyboardListeners({
                  Enter: handleSelect,
                  Space: handleSelect,
                })}
              />
            );
          })}
        </Segments>
      )}
      <Bar
        data-cypress={`${name}_progress_bar`}
        color={color}
        lineWidth={width}
        heightInPx={height}
        animationDuration={animationDuration}
      />
    </Progress>
  );
};

export default ProgressBar;
