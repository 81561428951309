import { PostAddToCartAction } from '@sharedComponents/AddToCartButton/model';
import { CART_URL } from '@utils/constants';

const CART_OVERVIEW_UPDATE_EVENT = 'header:cart:overview:update';

export const updateHeaderCartOverview = ({ cartId, cartSettings }) => {
  if (window?.fragments?.isNewHeader) {
    document.dispatchEvent(new CustomEvent(CART_OVERVIEW_UPDATE_EVENT));
    if (cartSettings === PostAddToCartAction.REDIRECT) {
      window.location.href = CART_URL;
    }
  } else if (typeof window.getAjaxCartToHeader === 'function') {
    window.getAjaxCartToHeader(cartId, () => {
      if (cartSettings === PostAddToCartAction.REDIRECT) {
        window.location.href = CART_URL;
      }
    });
  }
};
