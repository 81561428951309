import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconSolidInfo } from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { TabWrapper } from '@components';
import ErrorBoundary from '@components/ErrorBoundary';
import { useLivestreams } from '@components/LivestreamBanner/hooks/useLivestreams';
import { useBvNotinoUserRedirect } from '@containers/ProductDetailContainer/ProductDetail/hooks/useBvNotinoUserRedirect';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useApolloClientHeaders } from '@hooks/useApolloClientHeaders';
import { TabContent } from '@sharedComponents/TabSwitcher';
import { TabOrderProvider } from '@sharedComponents/TabSwitcher/TabOrderProvider';
import { assignRefs } from '@utils/assignRefs';
import { useIsDesktop } from '@utils/helpers';

import messages from '../../messages';
import { useIsRichContentFirstTab } from '../hooks/useIsRichContentFirstTab';
import { useOpenBVModal } from '../hooks/useOpenBVModal';
import { useTabsConfig } from '../hooks/useTabsConfig';
import { TabIds } from '../model';
import {
  TitleWrapper,
  TextNormal,
  DescriptionTitle,
  ProductTitleWrapper,
  ReviewSummaryCount,
  CompositionTitle,
} from '../styled';

import { AboutBrand } from './AboutBrand';
import { AboutProduct } from './AboutProduct';
import { Composition } from './Composition';
import { ContentSwitcher } from './ContentSwitcher';
import { Other } from './Other';
import LivestreamBanners from './ProductLivestreams';
import { Reviews } from './Reviews';
import { messages as reviewMessages } from './Reviews/messages';
import { CollectReviewsLink, CollectReviewsWrapper } from './Reviews/styled';
import { useTrackReviewListView } from './Reviews/useTrackReviewListView';
import { useRichContentData } from './RichContentArea/queries/useRichContentData';
import { RichTabContent } from './RichTabContent';
import { TRACKING_ATTRIBUTES, TAB_IDENTIFIERS } from './tracking';
import { useHandleReviewDigest } from './useHandleReviewDigest';
import { getProductFullName } from './utils';

interface IProductTabContentProps {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  customVersionKey: string;
  reviewsOnServerIds: string[];
}

export const ProductTabContent = ({
  product,
  variant,
  customVersionKey,
  reviewsOnServerIds,
}: IProductTabContentProps) => {
  const { data: richContent } = useRichContentData({
    versionKey: customVersionKey,
  });

  const { bazaarvoice_pd_components, livestream_on_pd_enabled } =
    useFeatureFlags();

  const { formatMessage } = useIntl();

  const bvReviewsContainer = React.useRef<HTMLDivElement | null>(null);
  const isFranceShop = useApolloClientHeaders().shopId === 'notino.fr';

  const isMobile = !useIsDesktop();

  const { reviewWrapperRef } = useTrackReviewListView();

  const shouldRenderRichContentTab =
    !!richContent?.RichContentByCatalogMasterId?.isAvailable;

  const isReorderedRichContentVariant = useIsRichContentFirstTab();

  useOpenBVModal(bvReviewsContainer);
  useBvNotinoUserRedirect(bvReviewsContainer, variant.catalogId);

  const { shouldRenderDescriptionTab, shouldRenderLivestreamsTab } =
    useTabsConfig();

  useHandleReviewDigest({ masterId: product.webMasterId });

  const { data: livestreamData } = useLivestreams({
    enabled: livestream_on_pd_enabled && isMobile,
    productCode: product.masterProductCode,
  });

  /**
   * Characteristics title can be rendered by DescriptionTitle if no description is provided.
   * Otherwise, it is rendered by CharacteristicsTitle on the desktop or inside AboutProduct on mobile.
   */
  const shouldCharsTitleBeRendered =
    Boolean(product.description) && variant.characteristics.length > 0;

  const shouldRenderAboutBrand = Boolean(product.brand?.description);

  const documentation = variant.documentation;
  const shouldRenderOthersTab = Boolean(
    documentation?.specialStorage ||
      documentation?.manufacturerImporter ||
      documentation?.importantNotice ||
      isFranceShop
  );

  const shouldRenderComposition =
    Boolean(documentation?.ingredients) ||
    Boolean(documentation?.foodComposition);

  const productFullName = getProductFullName(product);
  const productReviewCount = product?.reviewOverview?.textReviewsCount ?? 0;

  const richContentTab = shouldRenderRichContentTab && (
    <RichTabContent
      customVersionKey={customVersionKey}
      productFullName={productFullName}
      isReorderedRichContentVariant={isReorderedRichContentVariant}
    />
  );

  return (
    <>
      <ErrorBoundary>
        <ContentSwitcher
          product={product}
          variant={variant}
          isRichContentAvailable={shouldRenderRichContentTab}
          productReviewCount={productReviewCount}
          shouldRenderComposition={shouldRenderComposition}
          shouldRenderOthersTab={shouldRenderOthersTab}
        />
      </ErrorBoundary>

      <TabWrapper>
        <div id="tabAnchor">
          <TabOrderProvider>
            {isReorderedRichContentVariant && richContentTab}

            {shouldRenderDescriptionTab && (
              <TabContent
                tabId={TabIds.Description}
                trackingLabel={TRACKING_ATTRIBUTES.description}
                tabDividerMobile={
                  !isReorderedRichContentVariant || !shouldRenderRichContentTab
                }
                tabIdentifier={TAB_IDENTIFIERS.description}
                title={
                  <ProductTitleWrapper>
                    <DescriptionTitle fullWidth={!shouldCharsTitleBeRendered}>
                      <FormattedMessage
                        {...messages[
                          `${
                            product.description
                              ? 'prodDescription'
                              : `composition${
                                  product.isPerfume ? 'Perfume' : 'Cosmetics'
                                }`
                          }`
                        ]}
                        values={{
                          name: <TextNormal>{productFullName}</TextNormal>,
                        }}
                      />
                    </DescriptionTitle>
                  </ProductTitleWrapper>
                }
              >
                <ErrorBoundary>
                  <AboutProduct
                    description={
                      variant.descriptionOverride || product.description
                    }
                    pictograms={variant.attributes?.Pictograms}
                    characteristics={variant.characteristics}
                    composition={product.features.composition || []}
                    isPerfume={product.isPerfume}
                    documentation={variant.documentation}
                    isForSensitiveHair={variant.attributes?.IsForSensitiveHair}
                  />
                </ErrorBoundary>
              </TabContent>
            )}
            {shouldRenderComposition && (
              <TabContent
                tabDividerMobile={false}
                tabId={TabIds.Composition}
                trackingLabel={TRACKING_ATTRIBUTES.composition}
                tabIdentifier={TAB_IDENTIFIERS.composition}
                dataTestId={TAB_IDENTIFIERS.composition}
                title={
                  <TitleWrapper>
                    <CompositionTitle>
                      <FormattedMessage {...messages.compositionTab} />{' '}
                      <TextNormal>{productFullName}</TextNormal>
                    </CompositionTitle>
                  </TitleWrapper>
                }
              >
                <ErrorBoundary>
                  <Composition
                    html={`${variant.documentation.ingredients || ''}
                    ${variant.documentation.foodComposition || ''}`}
                    tablesData={variant.documentation.composition}
                  />
                </ErrorBoundary>
              </TabContent>
            )}

            {shouldRenderOthersTab && (
              <TabContent
                tabId={TabIds.Other}
                trackingLabel={TRACKING_ATTRIBUTES.other}
                tabIdentifier={TAB_IDENTIFIERS.other}
                tabDividerMobile={false}
                title={
                  <TitleWrapper>
                    <FormattedMessage {...messages.other} />
                  </TitleWrapper>
                }
              >
                <ErrorBoundary>
                  <Other
                    documentation={variant.documentation}
                    isFranceShop={isFranceShop}
                  />
                </ErrorBoundary>
              </TabContent>
            )}

            {shouldRenderAboutBrand && (
              <TabContent
                tabId={TabIds.AboutBrand}
                trackingLabel={TRACKING_ATTRIBUTES.brand}
                tabIdentifier={TAB_IDENTIFIERS.brand}
                tabDividerMobile={false}
                title={
                  <TitleWrapper>
                    <FormattedMessage
                      {...messages.aboutBrand}
                      values={{
                        brandName: (
                          <TextNormal>{product.brand.name}</TextNormal>
                        ),
                      }}
                    />
                  </TitleWrapper>
                }
              >
                <ErrorBoundary>
                  <AboutBrand description={product.brand.description} />
                </ErrorBoundary>
              </TabContent>
            )}

            {!isReorderedRichContentVariant && richContentTab}

            <TabContent
              tabId={TabIds.Reviews}
              tabDividerMobile={false}
              tabIdentifier={TAB_IDENTIFIERS.reviews}
              trackingLabel={TRACKING_ATTRIBUTES.reviews}
              titleHiddenOnDesktop={bazaarvoice_pd_components}
              title={
                <TitleWrapper id="pdReviewsScroll">
                  <FormattedMessage
                    {...messages.reviewHeader}
                    values={{
                      name: (
                        <>
                          <TextNormal>{productFullName}</TextNormal>

                          <ReviewSummaryCount id="pdReviewsScroll_reviewCount">
                            {productReviewCount > 0 &&
                            !bazaarvoice_pd_components
                              ? productReviewCount
                              : ''}
                          </ReviewSummaryCount>
                        </>
                      ),
                    }}
                  />
                </TitleWrapper>
              }
            >
              <ErrorBoundary>
                <CollectReviewsWrapper
                  bazaarvoice_integration_pd={bazaarvoice_pd_components}
                >
                  <IconSolidInfo color="icon.disabled" height="0.9rem" />

                  <CollectReviewsLink
                    href={formatMessage(reviewMessages.collectingReviewsLink)}
                  >
                    {formatMessage(reviewMessages.collectingReviewsText)}
                  </CollectReviewsLink>
                </CollectReviewsWrapper>

                {bazaarvoice_pd_components ? (
                  <div
                    ref={assignRefs(bvReviewsContainer, reviewWrapperRef)}
                    data-bv-show="reviews"
                    data-bv-product-id={product.masterProductCode}
                    id="BVReviewsScroll"
                  />
                ) : (
                  <Reviews
                    wrapperRef={reviewWrapperRef}
                    reviewsTabId={TabIds.Reviews}
                    renderReviewsOnServer={reviewsOnServerIds?.includes(
                      product.webMasterId
                    )}
                    productCode={product.masterProductCode}
                    masterId={product.webMasterId}
                    variant={variant}
                  />
                )}
              </ErrorBoundary>
            </TabContent>

            {shouldRenderLivestreamsTab && (
              <TabContent
                tabId={TabIds.Livestreams}
                alwaysOpen={true}
                tabDividerMobile={false}
                tabIdentifier={TAB_IDENTIFIERS.livestreamsTab}
                title={
                  <TitleWrapper>
                    <FormattedMessage {...messages.fixedHeaderLivestreams} />
                  </TitleWrapper>
                }
              >
                <ErrorBoundary>
                  <LivestreamBanners
                    livestreams={livestreamData?.getLivestreams?.shows}
                  />
                </ErrorBoundary>
              </TabContent>
            )}
          </TabOrderProvider>
        </div>
      </TabWrapper>
    </>
  );
};
