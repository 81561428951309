import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import {
  InstructionDocumentType,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import messages from './messages';
import {
  InstructionDescription,
  InstructionsWrapper,
  PdfWrapper,
} from './styled';

interface IInstructionsProps {
  instructions: string;
  instructionDocument: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['documentation']['document'];
}

const messageMap: Record<InstructionDocumentType, MessageDescriptor> = {
  InstructionsForUse: messages.instructionsForUse,
  PackagingInformation: messages.packagingInformation,
};

export const Instructions: React.FC<IInstructionsProps> = ({
  instructions,
  instructionDocument,
}) => {
  const instructionMessage = messageMap[instructionDocument?.type];

  return (
    <InstructionsWrapper>
      {instructions && (
        <InstructionDescription
          dangerouslySetInnerHTML={{
            __html: instructions,
          }}
        />
      )}

      {instructionMessage && (
        <PdfWrapper>
          <InstructionDescription data-testid="new-pdf-instructions">
            <p>
              <FormattedMessage
                {...instructionMessage}
                values={{
                  a: (parts) => (
                    <a
                      rel="nofollow noreferrer"
                      target="_blank"
                      href={instructionDocument.url}
                    >
                      {parts}
                    </a>
                  ),
                }}
              />
            </p>
          </InstructionDescription>
        </PdfWrapper>
      )}
    </InstructionsWrapper>
  );
};
