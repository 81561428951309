export interface ILivestream {
  showId: string;
  title: string;
  description: string;
  previewImageUrl: string;
  videoLength: string;
  isLive: boolean;
  scheduledStart?: string;
  scheduledDate: string;
  publishedDate?: string;
  deeplink: string;
}

export enum EShowComponentLayout {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  SINGLE = 'single',
}
