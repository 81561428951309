import { MutableRefObject } from 'react';

type Ref<T> = MutableRefObject<T> | ((instance: T | null | undefined) => void);

export const assignRefs =
  <T>(...refs: Array<Ref<T | null | undefined>>) =>
  (node: T | null | undefined) =>
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else {
        ref.current = node;
      }
    });
