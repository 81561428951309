import styled from 'styled-components';

import { breakpoints, Button, theme } from '@notino/react-styleguide';

import { CurrencyStyled } from '@components/PriceLabel/components/styled';

export const SubHeaderWrapper = styled.div`
  line-height: 1.25rem;
`;

export const SubHeader = styled.div`
  margin-bottom: 1rem;
`;

export const Notes = styled.div`
  color: ${theme.color.text.disabled};
  font-size: 0.8rem;
`;

export const StyledButton = styled(Button)`
  width: 100% !important;
  margin-top: 0.625rem;

  @media (min-width: ${breakpoints.sm}) {
    width: auto !important;
    margin-right: 1.875rem;
    margin-top: 0;
  }
`;

export const DeliveryFree = styled.div`
  margin-top: 3.125rem;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 18.75rem;
  }
`;
export const DeliveryFreeRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5625rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;

export const PriceWrapper = styled.span``;

export const CurrencyWrapper = styled(CurrencyStyled)`
  font-size: inherit;
  font-weight: inherit;
`;

export const StyledHeader = styled.div``;

export const ButtonsWrapper = styled.div`
  text-align: left;
`;
