import { getAttributeToken } from '@containers/ProductDetailContainer/ProductDetail/hooks/useGetAttributeToken';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { useUser } from '@queries/useUser';

interface UseAddToCardCloudTrackingArgs {
  quantity: number;
  productCode: string;
}

export const useAddToCardCloudTracking = () => {
  const { google_retail_api_user_events } = useFeatureFlags();
  const {
    user: { sid, cartId },
  } = useUser();

  return ({ quantity, productCode }: UseAddToCardCloudTrackingArgs) => {
    const attributionToken = getAttributeToken();

    if (google_retail_api_user_events) {
      dispatchTrackingEvent({
        event: 'cloud_retail',
        cloud_retail: {
          eventType: 'add-to-cart',
          visitorId: sid,
          eventTime: new Date().toISOString(),
          productDetails: [
            {
              product: {
                id: productCode,
              },
              quantity,
            },
          ],
          cartId,
          ...(attributionToken && { attributionToken }),
        },
      });
    }
  };
};
