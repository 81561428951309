import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  IconSolidPlane,
  IconRegularStore,
  IconRegularDelivery,
} from '@notino/react-styleguide';
import {
  DeliveryType,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import {
  DeliveryDisallowedReasons,
  IDelivery,
} from '@containers/ProductListing/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { HorizontalChevron } from '@sharedComponents/Icons/HorizontalChevron';

import messages from '../../../messages';

import { DeliveryLine } from './DeliveryLine';
import { messages as deliveryMessages } from './messages';
import {
  ChevronIconWrapper,
  Container,
  DeliveryDescription,
  DeliveryInfo,
  DeliveryList,
  FlexRow,
  FlexRowWithMargin,
  Select,
  SelectWrapper,
  StyledHr,
  TopDeliveryInfo,
} from './styled';
import { TimeText } from './TimeText';
import {
  groupPossibleDeliveries,
  hasOneDeliveryTypeWithTwoOptions,
} from './utils';

export enum DeliveriesDisplayMode {
  Static = 'Static',
  Expandable = 'Expandable',
}

export interface IDeliveryTimeProps {
  deliveries: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['deliveries'];
  displayMode?: DeliveriesDisplayMode;
}

const disallowedReasonMessageKeyMapping = {
  [DeliveryDisallowedReasons.AirTransportDisallowed]:
    messages.deliveryDisallowed,
};

const getIconByDelivery = (delivery: IDelivery): JSX.Element => {
  switch (delivery.delivery) {
    case DeliveryType.Personal:
      return <IconRegularStore />;
    case DeliveryType.AirTransport:
      return <IconSolidPlane />;
    default:
      return <IconRegularDelivery />;
  }
};

export const DeliveryTime: React.FC<IDeliveryTimeProps> = React.memo(
  ({ deliveries, displayMode }) => {
    const { formatMessage } = useIntl();
    const { getTimeFromInit } = useTrackingContext();
    const isStatic = displayMode === DeliveriesDisplayMode.Static;

    const [showAllDeliveriesMode, setShowAllDeliveriesMode] =
      React.useState(isStatic);

    const handleDeliveryListClick = (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();

      dispatchTrackingEvent({
        event: 'element_click',
        element: {
          name: 'delivery_options',
          type: 'product_detail',
          mode: !showAllDeliveriesMode ? 'on' : 'off',
          timing: getTimeFromInit(),
          interaction: 'click',
          action: 'click_on_delivery',
          promo_labels: undefined,
        },
        _clear: true,
      });

      setShowAllDeliveriesMode((old) => !old);
    };

    const getDeliveryDescription = (delivery: IDelivery) => {
      if (delivery.disallowedReason) {
        const messageDescriptor =
          disallowedReasonMessageKeyMapping[delivery.disallowedReason];

        if (messageDescriptor) {
          return <FormattedMessage {...messageDescriptor} />;
        }
      }

      return (
        <TimeText
          date={delivery.date}
          from={delivery.dateFrom}
          to={delivery.dateTo}
        />
      );
    };

    const groupedDeliveries = React.useMemo(
      () => groupPossibleDeliveries(deliveries),
      [deliveries]
    );

    const showAllDeliveries =
      showAllDeliveriesMode ||
      hasOneDeliveryTypeWithTwoOptions(groupedDeliveries);

    return (
      <ErrorBoundary>
        <Container data-testid="pd-delivery-time" id="pdDeliveryTime">
          <SelectWrapper>
            <Select>
              <DeliveryList role="list" onClick={handleDeliveryListClick}>
                {(!showAllDeliveries && (
                  <FlexRow
                    key={0}
                    role="listitem"
                    data-testid="pd-delivery-list-item"
                  >
                    {groupedDeliveries.map(
                      // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
                      ({ deliveries, getTopDeliveryName }, index) =>
                        deliveries.length > 0 && (
                          <TopDeliveryInfo
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            <DeliveryLine
                              showIcon={true}
                              icon={getIconByDelivery(deliveries[0])}
                              isInactive={
                                deliveries[0].disallowedReason !== null
                              }
                              name={getTopDeliveryName(
                                deliveries[0],
                                formatMessage
                              )}
                              description={getDeliveryDescription(
                                deliveries[0]
                              )}
                            />
                          </TopDeliveryInfo>
                        )
                    )}
                  </FlexRow>
                )) || (
                  <>
                    {/* eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow */}
                    {groupedDeliveries.map(({ deliveries }) =>
                      deliveries.map((delivery, index) => (
                        <FlexRowWithMargin
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          role="listitem"
                          data-testid="pd-delivery-list-item"
                        >
                          <DeliveryInfo>
                            <DeliveryLine
                              showIcon={index === 0}
                              icon={getIconByDelivery(delivery)}
                              isInactive={delivery.disallowedReason !== null}
                              name={delivery.name}
                              description={getDeliveryDescription(delivery)}
                            />
                          </DeliveryInfo>
                        </FlexRowWithMargin>
                      ))
                    )}
                  </>
                )}
              </DeliveryList>

              {!isStatic && (
                <ChevronIconWrapper
                  onClick={handleDeliveryListClick}
                  aria-expanded={showAllDeliveries}
                  aria-label={formatMessage(deliveryMessages.deliveryInfo)}
                >
                  <HorizontalChevron
                    isOpened={showAllDeliveriesMode}
                    width="16px"
                    height="16px"
                    color="icon.secondary"
                  />
                </ChevronIconWrapper>
              )}
            </Select>
          </SelectWrapper>

          {showAllDeliveriesMode && (
            <>
              <StyledHr />
              <DeliveryDescription>
                <FormattedMessage {...messages.deliveryDescription} />
              </DeliveryDescription>
            </>
          )}
        </Container>
      </ErrorBoundary>
    );
  }
);
