import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import {
  ELivestreamTrackingInteraction,
  trackLivestreamView,
  trackOnClick as externalTrackOnClick,
} from '@containers/ProductDetailContainer/ProductDetail/livestreamTracking';

export interface IUseLivestreamTrackingProps {
  showId: string;
  itemIndex: number;
  viewInteraction: ELivestreamTrackingInteraction;
}

export const useMobileLivestreamTracking = ({
  showId,
  itemIndex,
  viewInteraction,
}: IUseLivestreamTrackingProps) => {
  const [livestreamRef, livestreamInView] = useInView({
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (livestreamInView) {
      trackLivestreamView({
        showId,
        itemIndex,
        interaction: viewInteraction,
      });
    }
  }, [livestreamInView, itemIndex, showId, viewInteraction]);

  const trackOnClick = React.useCallback(
    (interaction: ELivestreamTrackingInteraction) => {
      externalTrackOnClick({ showId, itemIndex, interaction });
    },
    [itemIndex, showId]
  );

  return { livestreamRef, trackOnClick };
};
