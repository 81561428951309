import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { CommonContainer, CommonMarginWrapper } from '../../styled';

export const Container = styled(CommonContainer)`
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 2rem;
  }
`;

export const MarginWrapper = styled(CommonMarginWrapper)``;

export const Description = styled.div`
  display: block;

  p {
    margin: 0 0 1.3em;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  width: 100%;
  color: ${theme.color.text.secondary};
  ${theme.typography.bodyRegular};

  @media (min-width: ${breakpoints.lg}) {
    ${theme.typography.bodyLarge}
  }
`;
