import * as History from 'history';

import { masterUrl } from '@notino/shared/client-utils';

export interface IRCVersionKeys {
  rcKey: string;
  customRcKey: string;
  customVersionKey: string;
}

export const getRcVersionKeys = (
  location: History.Location
): IRCVersionKeys => {
  let customRcKey = null;
  let customVersionKey = null;
  if (location.search.length > 0) {
    const query = new URLSearchParams(location.search);
    if (query.has('rcKey')) {
      // prefer to show rc with rcKey param
      customRcKey = query.get('rcKey');
    }
    if (query.has('versionKey')) {
      // prefer to show selected rich content version
      customVersionKey = query.get('versionKey');
    }
  }

  const rcKey =
    customRcKey ||
    masterUrl(location.pathname, {
      trimLeadingSlash: true,
      trimTrailingSlash: true,
    });

  return {
    rcKey,
    customVersionKey,
    customRcKey,
  };
};
