import { ITheme } from '@notino/react-styleguide';

import { getRenderableAttributes } from '@components/Tags/utils';
import { AttributeType } from '@containers/ProductListing/model';

export const variantInfo = (variant: {
  additionalInfo: string;
  variantName: string;
  name: string;
}) =>
  variant.additionalInfo?.trim() || variant.variantName?.trim() || variant.name;

/**
 * TODO move outside this file to model (typings) or settings resp.
 */

export interface IImageProperties {
  src: string;
  alt: string;
  srcSet?: string;
  width?: string;
  height?: string;
  isVideo?: boolean;
}

/**
 * Creates an array with size of given number
 */
export const arrayFromNumber = (size: number) => {
  const array: number[] = [];
  for (let i = 1; i <= size; i++) {
    array.push(i);
  }
  return array;
};

export const hexToDec = (hexStr: string, position: number) =>
  parseInt(hexStr.substr(position, 2), 16);

export const hexToRgba = (hex: string, opacity: number) => {
  let c: string = hex.substring(1);
  if (c.length === 3) {
    // eg. fff
    c = [c[0], c[0], c[1], c[1], c[2], c[2]].join('');
  }
  return `rgba(${hexToDec(c, 0)}, ${hexToDec(c, 2)}, ${hexToDec(
    c,
    4
  )}, ${opacity})`;
};

export const dataLabelsProp = (attributes: AttributeType) => ({
  'data-pd-variant-labels': Object.keys(
    getRenderableAttributes(attributes)
  ).join(','),
});

export const isEmpty = (text: string) =>
  text.replace(/<[^>]+>/g, '').trim().length === 0;

export const linearGradientFromColors = (colors: string[], theme: ITheme) => {
  if (colors.length === 0) {
    return theme.color.background.disabled;
  }

  let direction = 'to right';
  const ratio = 100 / colors.length;

  if (ratio === 50) {
    direction = 'to bottom right';
  }

  return `${direction}, ${colors
    .map(
      (c, index) =>
        `${c.toLowerCase()} ${ratio * index}%, ${c.toLowerCase()} ${
          ratio * (index + 1)
        }%`
    )
    .join(',')}`;
};

export const isAbTestEnabled = (
  abTestHeader: string | null,
  abTestNumber: string | string[],
  abTestEnabledValue: string = '1'
) => {
  if (abTestHeader) {
    const abTests = parseAbTestHeader(abTestHeader);

    const foundAbTest = abTests.find((test) => {
      if (Array.isArray(abTestNumber)) {
        return abTestNumber.includes(test.number);
      }
      return test.number === abTestNumber;
    });

    return foundAbTest?.value === abTestEnabledValue;
  }

  return false;
};

const parseAbTestHeader = (abTestHeader: string) => {
  const abTests = abTestHeader.split(',');

  return abTests
    .map((test) => test.split('='))
    .filter((pair) => pair.length === 2)
    .map((pair) => ({
      number: pair[0],
      value: pair[1],
    }));
};
