import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { IProduct } from '@containers/ProductListing/model';

import messages from '../../messages';
import { ProductsSection } from '../ProductsSection';

import { LIST_LABEL } from './constants';
import { useLastVisitedItems } from './useLastVisitedItems';

interface ILastVisitedProductsProps {
  productId: IProduct['id'];
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number][];
}

export const LastVisitedProducts: React.FC<ILastVisitedProductsProps> = (
  props
) => {
  const items = useLastVisitedItems(props.productId);

  if (!items || !items.length || items.length < 3) {
    return null;
  }

  return (
    <ProductsSection
      productIds={items}
      titleMessage={messages.lastVisited}
      titleId="pdLastVisitedProducts"
      containerId="pd-last-visited-products-header"
      listLabel={LIST_LABEL}
      trackProductClick={true}
      trackProductImpression={true}
    />
  );
};
