import styled from 'styled-components';

import { ProductLabel, Link, theme } from '@notino/react-styleguide';

export const ReviewMotivationProductLabel = styled(ProductLabel)<{
  isVerified: boolean;
}>`
  font-size: 0.75rem;
  text-transform: none;
  padding: 0.25rem 0.375rem;

  ${(props) => props.isVerified && `color: ${theme.color.text.positive};`}
`;

export const TooltipContent = styled.div`
  max-width: max-content;
  width: 15rem;
  padding: 0.5rem;
`;

export const MoreInfoLink = styled(Link)`
  margin-left: 0.25rem;
  text-decoration: underline;
`;
