import * as React from 'react';

import { ICurrency } from '@containers/App/model';

export const VoucherDiscountContext = React.createContext<{
  currency: ICurrency;
  isUnderSelectBox?: boolean;
  isArrowHidden?: boolean;
  hasContrastBg: boolean;
}>(null!);

export const useVoucherDiscountContext = () =>
  React.useContext(VoucherDiscountContext);
