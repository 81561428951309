import { defineMessages } from 'react-intl';

export const watchdogMessages = defineMessages({
  watchdogSubmitError: {
    id: 'product.watchdog.submit.error',
    defaultMessage: 'Chyba při odeslání formuláře.',
  },
  watchdogText: {
    id: 'product.watchdog.text',
    defaultMessage: `
      Rádi Vás budeme informovat e-mailem, až zboží naskladníme.
      Samozřejmě je jen na Vás, jestli nabídku využijete.
    `,
  },
  watchdogSentHeader: {
    id: 'product.watchdog.sent.header',
    defaultMessage: 'Váš e-mail byl uložen.',
  },
  watchdogSentNotification: {
    id: 'product.watchdog.toast',
    defaultMessage:
      'O naskladnění vás budeme informovat e-mailem. Přehled upozornění najdete ve svém <a>zákaznickém účtu</a>.',
  },
  watchdogSentNotificationAnonymous: {
    id: 'product.watchdog.toast.anonymous',
    defaultMessage: 'O naskladnění vás budeme informovat e-mailem.',
  },
  watchdogHeader: {
    id: 'product.watchdog.header',
    defaultMessage: 'Hlídání dostupnosti zboží',
  },
  watchdogEmailLabel: {
    id: 'product.watchdog.email.label',
    defaultMessage: 'Vepište svůj e-mail',
  },
  watchdogSendButton: {
    id: 'product.watchdog.send.button',
    defaultMessage: 'Odeslat',
  },
  watchdogTryAgainButton: {
    id: 'product.watchdog.tryAgain.button',
    defaultMessage: 'Zkusit znovu',
  },
  watchdogGDPRinfo: {
    id: 'product.watchdog.gdpr.info',
    defaultMessage:
      'Odesláním souhlasíte se zpracováním emailu za účelem hlídání dostupnosti tohoto zboží společností Notino s.r.o., se sídlem v Brně, Londýnské náměstí 881/6, PSČ 639 00, IČO 27609057 do odeslání emailu, maximálně 1 roku. Údaje mohou být předány a zpracovány sesterskými společnostmi. Máte právo svůj souhlas kdykoliv odvolat, požadovat od správce přístup k osobním údajům, jejich opravu, výmaz, přenositelnost, popřípadě omezení zpracování. Více informací v {link}.​',
  },
  watchAvailability: {
    id: 'product.watchdog.availibility',
    defaultMessage: 'Hlídat dostupnost',
  },
  watchdogActive: {
    id: 'product.watchdog.active',
    defaultMessage: 'Hlídáme pro vás',
  },
  logIn: {
    id: 'product.watchdog.login',
    defaultMessage: 'Přihlásit se',
  },
  skipLogin: {
    id: 'product.watchdog.skipLogin',
    defaultMessage: 'Pokračovat bez přihlášení',
  },
});
