import * as React from 'react';

import { useStore } from 'zustand';

import { ModalContext, ReactFCC } from '@notino/react-styleguide';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { noop } from '@utils/noop';

import { IAddToCartButtonProps } from '../model';

import { IAddToCartButtonContext } from './model';
import { createAddToCartStore } from './store';
import { useAddToCartMutation } from './useAddToCartMutation';

const AddToCartButtonContext = React.createContext<IAddToCartButtonContext>(
  {} as IAddToCartButtonContext
);

export const AddToCartButtonProvider: ReactFCC<IAddToCartButtonProps> = ({
  children,
  ...props
}) => {
  const { toggleDrawer } = useDrawer();

  const storeRef = React.useRef(
    createAddToCartStore({
      getModalAfterAddToCart: props.getModalAfterAddToCart,
      toggleModal: ModalContext.useModalContext().toggleModal,
      toggleDrawer,
    })
  );

  const propsWithDefaults: IAddToCartButtonContext['props'] = {
    ...props,
    onProductAdded: props.onProductAdded ?? noop,
    onProductAddFailed: props.onProductAddFailed ?? noop,
    onClosingModal: props.onClosingModal ?? noop,
    disableRedirect: Boolean(props.disableRedirect),
    withLegacyAddToCart: Boolean(props.withLegacyAddToCart),
    services: props.services ?? [],
  };

  const apollo = useAddToCartMutation();

  if (!props.product) {
    return null;
  }

  return (
    <AddToCartButtonContext.Provider
      value={{ props: propsWithDefaults, apollo, store: storeRef.current }}
    >
      {children}
    </AddToCartButtonContext.Provider>
  );
};

export const useAddToCartButtonContext = (): IAddToCartButtonContext =>
  React.useContext(AddToCartButtonContext);

export const useIsMouseOverBuyButton = () =>
  useStore(
    useAddToCartButtonContext().store,
    (store) => store.mouseOverBuyButton
  );

export const useAddToCartSendState = () =>
  useStore(useAddToCartButtonContext().store, (store) => store.sendState);

export const useAddToCartSubmitAttempts = () =>
  useStore(useAddToCartButtonContext().store, (store) => store.submitAttempts);

export const useAddToCartActions = () =>
  useStore(useAddToCartButtonContext().store, (store) => store.actions);
