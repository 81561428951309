import { useEffect } from 'react';

export function useAdjustStickyElementWidth(
  containerRef: React.MutableRefObject<HTMLDivElement>,
  stickyElRef: React.MutableRefObject<HTMLDivElement>
) {
  useEffect(() => {
    const containerEl = containerRef.current;
    const stickyEl = stickyElRef.current;
    if (!containerEl || !stickyEl) {
      return;
    }

    // NOTE: I need to set the width explicitly because of position fixed on stickyEl
    const setInnerContainerWidth = () => {
      stickyEl.style.width = `${containerEl.clientWidth}px`;
    };
    setInnerContainerWidth();
    window.addEventListener('resize', setInnerContainerWidth);
    return () => {
      window.removeEventListener('resize', setInnerContainerWidth);
    };
  }, [stickyElRef, containerRef]);
}
