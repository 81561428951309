import * as React from 'react';

interface IKlarnaProps {
  price: number;
  priceDecimalPlaces: number;
  locale: string;
}

export const Klarna: React.FC<IKlarnaProps> = ({
  price,
  locale,
  priceDecimalPlaces,
}) => {
  // The amount of money in micro units ($120.00 = 12000), used for amount based credit promotions.
  const priceInMicroUnits = price * Math.pow(10, priceDecimalPlaces);
  const priceWithoutDecimal = Math.trunc(Math.round(priceInMicroUnits));

  React.useEffect(
    function refreshOnChange() {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
    },
    [price]
  );

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<klarna-placement
                    data-key="credit-promotion-badge"
                    data-locale="${locale}"
                    data-purchase-amount="${priceWithoutDecimal}">
                 </klarna-placement>`,
      }}
    />
  );
};
