import styled from 'styled-components';

import {
  IconRegularChevronRight,
  breakpoints,
  theme,
} from '@notino/react-styleguide';

export const Chevron = styled(IconRegularChevronRight)`
  color: ${theme.color.icon.tertiary};
  height: 1.25rem;
  width: 1.25rem;
`;

export const Wrapper = styled.div`
  padding: 0.75rem;
  margin-top: 0.5rem;
  background: ${theme.color.background.secondary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background: ${theme.color.background.secondaryHover};
  }
`;

export const GetSamplesText = styled.div`
  color: ${theme.color.text.secondary};
  flex: 1;
  ${theme.typography.labelRegular400}
  b {
    font-weight: 500;
  }
`;

export const StyledImage = styled.img`
  height: 3rem;
  width: 3rem;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

export const ContentStyledImage = styled(StyledImage)`
  height: 7.5rem;
  width: 7.5rem;

  @media (min-width: ${breakpoints.md}) {
    order: 1;
  }
`;

export const Title = styled.div`
  ${theme.typography.labelLarge}
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    ${theme.typography.titleLarge}
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    order: 2;
  }
`;

export const ContentWrapper = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }

  img {
    aspect-ratio: 678/360; // needed for BottomSheet to calculate its height if image loading is slow
    margin-top: 1.5rem;
  }

  a {
    text-decoration: none;

    @media (min-width: ${breakpoints.md}) {
      order: 4;
    }

    button {
      padding-inline: 2rem;
      display: block;
      margin: 1.5rem auto 0;
      width: 100%;
      max-width: 35rem;
      @media (min-width: ${breakpoints.md}) {
        width: auto;
      }
    }
  }
`;

export const ContentText = styled.div`
  color: ${theme.color.text.secondary};
  ${theme.typography.labelRegular400}
  line-height: 1.5rem;
  text-align: center;
  max-width: 35rem;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    order: 3;
  }
`;
