import styled from 'styled-components';

export const ReviewsFetch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3.125rem 0;
  font-size: 0.875rem;
  svg {
    margin-right: 0.5rem;
  }
  a {
    text-decoration: underline;
    text-transform: lowercase;
    cursor: pointer;
  }
`;
