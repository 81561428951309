import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';

import { IConditionalVoucherDiscount } from '..';

import { ConditionTooltipContent, Link } from './styled';

interface IConditionsTooltipProps {
  targets: IConditionalVoucherDiscount['targets'];
}
export const ConditionsTooltip: ReactFCC<IConditionsTooltipProps> = ({
  children,
  targets,
}) => {
  const content = targets.map((target, idx) => (
    <React.Fragment key={target.url}>
      <Link href={target.url}>{target.name}</Link>
      {idx < targets.length - 1 && ', '}
    </React.Fragment>
  ));
  return (
    <PortalTooltip
      interactive={true}
      content={
        <PortalTooltip.Content>
          <ConditionTooltipContent>{content}</ConditionTooltipContent>
        </PortalTooltip.Content>
      }
    >
      <u>{children}</u>
    </PortalTooltip>
  );
};
