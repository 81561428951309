import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { snakeCase } from 'lodash';

import {
  ButtonModel,
  IconSolidInfo,
  RadioButton,
  Spinner,
  Text,
} from '@notino/react-styleguide';
import {
  GetCatalogProductViewQuery,
  StockAvailability,
} from '@notino/shared/definitions/types';
import { CartType } from '@notino/web-tracking';

import { useModifaceVariants } from '@containers/ProductDetailContainer/ProductDetail/hooks/useModifaceVariants';
import { usePriceLogic } from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic';
import {
  MIN_PRICE_PROMO_LABEL,
  RRP_PRICE_PROMO_LABEL,
} from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic/tracking';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';

import { getRenderableDiscoBoxSampleId } from '../../DiscoveryBoxSelfChoice';
import { getGiftCampaigns } from '../../Gift';

import { Store } from './components/Store';
import { useTrackModalOrDrawerShow } from './hooks/useTrackModalShow';
import { messages } from './messages';
import {
  ContentWrapper,
  StoreWrapper,
  CenterWrapper,
  StyledActionButtonWithConfirmation,
  ActionButtonWithConfirmationWrapper,
  GiftInformationBox,
} from './styled';
import { useClickAndCollect } from './useClickAndCollect';

interface IClickAndCollectContentProps {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  selectedVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
}

export const ClickAndCollectContent = ({
  selectedVariant,
  product,
}: IClickAndCollectContentProps) => {
  const [selectedStore, setSelectedStore] = React.useState('');
  const { rrpShown, minimalPriceShown } = usePriceLogic(selectedVariant);
  const flags = useFeatureFlags();

  const { giftCampaigns } = getGiftCampaigns({
    product,
    variant: selectedVariant,
  });

  const shouldShowGiftInformationBox = giftCampaigns?.length > 0;

  const {
    tryItFirstAvailable,
    engravingAvailable,
    product: { variants },
  } = useProductDetailContext();
  const modifaceVariants = useModifaceVariants(variants);

  const {
    stores,
    storesLoading,
    handleAddToClickAndCollect,
    addToClickAndCollectData: {
      loading: addToCnCLoading,
      error: addToCnCError,
    },
  } = useClickAndCollect(Number(selectedVariant.webId));

  useTrackModalOrDrawerShow();

  if (storesLoading) {
    return (
      <CenterWrapper tabIndex={0}>
        <Spinner color="icon.primary" size={32} />
      </CenterWrapper>
    );
  }

  const onRadioButtonClick = (storeId: string) => () => {
    setSelectedStore(storeId);
  };

  const handleSubmit = async () => {
    dispatchTrackingEvent({
      event: 'add_to_cart',
      product: ProductEventWither()
        .withProduct(product)
        .withVariant(selectedVariant)
        .withServices({
          modifaceVariants,
          tryItFirstAvailable,
          engravingAvailable,
          discoveryBoxAvailable: Boolean(
            getRenderableDiscoBoxSampleId(product, selectedVariant, flags)
          ),
        })
        .withAdditionalData({
          quantity: 1,
          cart_type: snakeCase(CartType.clickAndCollect),
        })
        .withAdditionalPromoLabels([
          rrpShown && RRP_PRICE_PROMO_LABEL,
          minimalPriceShown && MIN_PRICE_PROMO_LABEL,
        ])
        .build(),
      _clear: true,
    });

    await handleAddToClickAndCollect(selectedStore);
  };

  return (
    <>
      <ContentWrapper
        shouldShowGiftInformationBox={shouldShowGiftInformationBox}
      >
        {stores?.collectStoresByProductId?.map((store) => {
          const outOfStock = store.stockCount === StockAvailability.OutOfStock;
          if (outOfStock) {
            return null;
          }

          return (
            <StoreWrapper key={store.code}>
              <RadioButton
                onChange={onRadioButtonClick(store.code)}
                position={4}
                disabled={store.isOverloaded}
                checked={selectedStore === store.code}
                labelDataTestId="collectStoreItem"
              >
                <Store data={store} />
              </RadioButton>
            </StoreWrapper>
          );
        })}
      </ContentWrapper>

      <ActionButtonWithConfirmationWrapper>
        {shouldShowGiftInformationBox && (
          <GiftInformationBox>
            <IconSolidInfo color="icon.secondary" height="16px" width="16px" />
            <Text typography="labelSmall400">
              <FormattedMessage {...messages.pickUpGiftInformation} />
            </Text>
          </GiftInformationBox>
        )}

        <StyledActionButtonWithConfirmation
          buttonStyle={ButtonModel.Styles.primary}
          fullWidth={true}
          onClick={handleSubmit}
          loading={addToCnCLoading}
          error={!!addToCnCError}
          data-testid="pickHereButton"
          disabled={!selectedStore}
        >
          <FormattedMessage {...messages.pickHere} />
        </StyledActionButtonWithConfirmation>
      </ActionButtonWithConfirmationWrapper>
    </>
  );
};
