import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ProgressBar from '@components/ProgressBar';
import { IRating } from '@containers/ProductDetailContainer/model';

import messages from '../../../../../messages';
import { StyledCol, RatingRow } from '../styled';

import { AttributesReviews, ParamProgressWrapper, ParamValue } from './styled';

interface IAttributesSummaryProps {
  ratings: IRating[];
  showAttributesReviews: boolean;
}

export const AttributesSummary: React.FC<IAttributesSummaryProps> = ({
  ratings,
  showAttributesReviews,
}) => {
  return (
    <StyledCol xs={12} md={4}>
      <AttributesReviews
        data-cypress="reviews-attributes"
        visible={showAttributesReviews}
      >
        {ratings.map((rating, ratingKey) => (
          <RatingRow key={rating.id} isFirst={ratingKey === 0}>
            <ParamProgressWrapper>
              <ProgressBar
                name={String(rating.id)}
                max={5}
                value={rating.score}
                segments={true}
              />
            </ParamProgressWrapper>
            <ParamValue>
              <FormattedMessage
                {...messages[`ratingType${rating.ratingType}`]}
              />
            </ParamValue>
          </RatingRow>
        ))}
      </AttributesReviews>
    </StyledCol>
  );
};
