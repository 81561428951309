const validations = {
  picker: {
    required: true,
  },
  name: {
    required: true,
  },
  email: {
    required: true,
    regex: {
      pattern:
        "^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-z0-9](-?[a-z0-9])*(\\.[a-z](-?[a-z0-9])*)+$",
      modifier: 'i',
    },
  },
  address: {
    required: true,
  },
  city: {
    required: true,
  },
};

export default validations;
