import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { useQuery } from '@apollo/client';
import { map } from 'lodash';

import { IArticle } from '@notino/react-styleguide';
import {
  GetBlogArticlesQuery,
  GetBlogArticlesQueryVariables,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import messages from '@containers/ProductDetailContainer/messages';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import loadable from '@loadable/component';

import { SectionContainer } from '../SectionContainer';

import { Margin } from './index.styles';
import getBlogArticlesQuery from './queries/getBlogArticles.graphql';

const LoadableArticlesSlider = loadable(
  () => import('./LoadableArticlesSlider')
);

type Props = Pick<
  GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  'categorization'
> & {
  brandId: string | number;
  masterProductCode: string;
  allProductCodes: string[];
};

export const BlogArticles = ({
  categorization: { kinds, categories, subCategories, types },
  brandId,
  masterProductCode,
  allProductCodes,
}: Props) => {
  const { getTimeFromInit } = useTrackingContext();

  const [ref, isInView] = useInView({
    triggerOnce: true,
    rootMargin: '50px',
  });

  const { data, error } = useQuery<
    GetBlogArticlesQuery,
    GetBlogArticlesQueryVariables
  >(getBlogArticlesQuery, {
    skip: !isInView,
    variables: {
      variables: {
        kinds: map(kinds, 'id'),
        types: map(types, 'id'),
        categories: map(categories, 'id'),
        subCategories: map(subCategories, 'id'),
        brandId: brandId ? Number(brandId) : null,
        masterProductCode: masterProductCode,
        productCodes: allProductCodes,
      },
    },
  });

  const trackClick = (article: IArticle) => {
    dispatchTrackingEvent({
      event: 'article_click',
      _clear: true,
      blog: {
        timing: getTimeFromInit(),
        name: 'blog_tile',
        action: 'click_on_blog_tile',
        interaction: 'click',
        type: 'blog_tile',
        link_path: article.url,
        unified_link: articles.find((x) => x.url === article.url)?.hreflangId,
        location: 'product_detail',
        position: `${articles.map((a) => a.url).indexOf(article.url) + 1}`,
      },
    });
  };

  const articles = data?.getArticles?.articles || [];

  return (
    <div ref={ref}>
      {!error && articles.length > 0 && (
        <SectionContainer
          titleMessage={messages.getInspired}
          titleId="pdBlogArticles"
          containerId="pd-blog-articles-header"
          withDivider={false}
        >
          <Margin>
            <LoadableArticlesSlider
              articles={articles.slice(0, 3).map((a) => ({
                ...a,
                image: { alt: a.image.alt, src: a.image.url },
              }))}
              onClick={(_, article) => trackClick(article)}
            />
          </Margin>
        </SectionContainer>
      )}
    </div>
  );
};
