import * as React from 'react';

import { ModalModel, ModalContext } from '@notino/react-styleguide';
import { AvailabilityState } from '@notino/shared/definitions/types';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { WatchdogContent } from '@sharedComponents/Watchdog/WatchdogContent';
import { isDesktop } from '@utils/helpers';

import { IWatchdogProps } from './model';
import { useWatchdogActive } from './useWatchdogActive';

export const useOpenWatchdogModal = ({
  variant,
}: Pick<IWatchdogProps, 'variant'>) => {
  const { toggleModal } = ModalContext.useModalContext();
  const { toggleDrawer } = useDrawer();

  const { setWatchdogActiveCache } = useWatchdogActive({
    variant,
  });

  const canShowWatchdog =
    'showWatchdog' in variant
      ? variant.showWatchdog
      : variant.availability.state === AvailabilityState.ShowWatchdog;

  return React.useCallback(() => {
    if (!canShowWatchdog) {
      return;
    }

    if (isDesktop()) {
      toggleModal(
        <WatchdogContent
          variant={variant}
          onSuccess={setWatchdogActiveCache}
        />,
        {
          type: ModalModel.Types.default,
          withFocusTrap: true,
          positionBottomOnMobile: true,
          noBorders: true,
        }
      );
      return;
    }

    toggleDrawer(
      <WatchdogContent variant={variant} onSuccess={setWatchdogActiveCache} />
    );
  }, [
    setWatchdogActiveCache,
    toggleDrawer,
    toggleModal,
    variant,
    canShowWatchdog,
  ]);
};
