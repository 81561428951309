import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import { TitleCSS } from '../styled';

export const Title = styled.div`
  ${TitleCSS}
`;

interface IContainerProps {
  withDivider: boolean;
}

export const Container = styled.section<IContainerProps>`
  box-sizing: border-box;
  display: flex;
  @media (min-width: ${breakpoints.md}) {
    margin: 0;
    border-top: 0;
  }
`;

export const MarginWrapper = styled.div`
  height: inherit;
  margin: 1rem 0;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    margin: 0;
  }
`;
