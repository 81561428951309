import * as React from 'react';

import { IconSolidHeart, IconRegularHeart } from '@notino/react-styleguide';

import { StyledPulsatingHeart } from './styled';

interface IPulsatingHeartProps {
  wishlisted: boolean;
  loading: boolean;
  width?: string;
  height?: string;
}

export const PulsatingHeart: React.FC<IPulsatingHeartProps> = ({
  wishlisted,
  loading,
}) => {
  return (
    <StyledPulsatingHeart data-testid="wishlist" animate={loading}>
      {wishlisted || loading ? (
        <IconSolidHeart width="20px" height="20px" color="icon.highlight" />
      ) : (
        <IconRegularHeart width="20px" height="20px" color="icon.primary" />
      )}
    </StyledPulsatingHeart>
  );
};
