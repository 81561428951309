import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';

import { IStyledVariantProps } from '../../../../model';
import { VariantCommon } from '../../variantsStyled';

type VariantProps = {
  displayInRow: boolean;
} & IStyledVariantProps;

export const Variant = styled(VariantCommon)<VariantProps>`
  ${({ isSelected, displayInRow }) =>
    css`
      border: 2px solid
        ${isSelected ? theme.color.common.gray30 : theme.color.border.divider};
      padding: 0.625rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin: 0 0 0.25rem;
      width: 6.5rem;
      height: 7.5rem;

      @media (min-width: ${breakpoints.lg}) {
        flex-direction: ${displayInRow ? 'row' : 'column'};
        width: auto;
        margin: 0;
      }
    `}
`;

interface IImgWrapperProps {
  isOnStock: boolean;
  isSelected: boolean;
}

export const ImgWrapper = styled.span<IImgWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.color.background.primary};

  img {
    opacity: ${(props) => (props.isSelected || props.isOnStock ? 1 : 0.5)};
    overflow: hidden;
    height: 4rem;
    width: 4rem;
    object-fit: contain;
  }
`;

interface IPriceLabelWrapperProps {
  isOnStock: boolean;
  isSelected: boolean;
}

export const PriceLabelWrapper = styled.div<IPriceLabelWrapperProps>`
  text-align: center;

  ${({ isSelected }) =>
    css`
      ${isSelected
        ? theme.typography.labelRegular
        : theme.typography.labelRegular400}
      color: ${isSelected
        ? theme.color.text.primary
        : theme.color.text.secondary};
    `}
`;

// has to be used because of isVideo prop warning, as we attempt to pass to dom element isVideo prop
export const Img = styled(CdnImage)``;

export const VariantList = styled.ul<{
  itemsCount: number;
}>`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ${({ itemsCount }) =>
    css`
      display: flex;
      gap: 0.5rem;
      margin: 0;

      @media (min-width: ${breakpoints.lg}) {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(${itemsCount >= 3 ? 3 : 2}, 1fr);
        overflow-x: auto;
        margin: 0;
      }
    `}
`;

export const VariantListItem = styled.li`
  list-style-type: none;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  margin: 0;

  &:before {
    display: none;
  }
`;

export const PricePlaceholder = styled.div`
  height: 1rem;
  width: 3rem;
`;
