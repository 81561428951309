import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ImagePlaceholder } from '@notino/react-styleguide';

import { useTrackingContext } from '@context/tracking/TrackingContext';
import { IImageProperties } from '@helpers/utils';
import { useIsMounted } from '@hooks/useIsMounted';
import { useOnTap } from '@hooks/useOnTap';
import { assignRefs } from '@utils/assignRefs';

import { Image360 } from '../Image360';
import { trackProductImageTap } from '../tracking';

import { SlideWrapper, ImgWrap } from './styled';

interface Props {
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
  imgProps: IImageProperties;
  shouldShow360Photo?: boolean;
}

export const Slide: React.FC<React.PropsWithChildren<Props>> = ({
  imgProps,
  children,
  isFirstSlide = false,
  isLastSlide = false,
  shouldShow360Photo,
}) => {
  const [isSquare, setIsSquare] = React.useState(false);
  const isMounted = useIsMounted();
  const [inViewRef, inView] = useInView({ triggerOnce: true });

  const { getTimeFromInit } = useTrackingContext();
  const onTapRef = useOnTap<HTMLDivElement>(() =>
    trackProductImageTap({ timing: getTimeFromInit() })
  );

  const handleImageLoad = (elem: HTMLImageElement) => {
    setIsSquare(
      elem.naturalWidth === elem.naturalHeight &&
        !isFirstSlide &&
        !imgProps.src.includes('-o/')
    );
  };

  const checkIfImageLoadedBeforeJavascript = (instance: HTMLImageElement) => {
    if (instance?.complete) {
      handleImageLoad(instance);
    }
  };

  return (
    <SlideWrapper
      ref={assignRefs(onTapRef, inViewRef)}
      isSquare={isSquare}
      showDivider={!isMounted && !isLastSlide}
    >
      {!inView && (
        <ImgWrap>
          <img
            {...imgProps}
            draggable={false}
            loading={isFirstSlide ? 'eager' : 'lazy'}
            ref={checkIfImageLoadedBeforeJavascript}
            onLoad={(e) => handleImageLoad(e.currentTarget)}
          />
        </ImgWrap>
      )}

      {inView &&
        (shouldShow360Photo ? (
          <Image360 />
        ) : (
          <ImagePlaceholder
            image={imgProps}
            onLoad={handleImageLoad}
            hidePlaceholder={isFirstSlide}
          />
        ))}

      {children}
    </SlideWrapper>
  );
};
