import styled, { css } from 'styled-components';

const ContentWrapper = styled.div<{ isWider?: boolean }>`
  max-width: 64.6875rem;
  margin: 0 auto;
  padding: 0;
  width: 100%;

  ${({ isWider }) =>
    isWider &&
    css`
      max-width: 80rem;
      @media (min-width: 105rem) {
        max-width: 100rem;
      }
    `}
`;

export default ContentWrapper;
