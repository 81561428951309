import * as React from 'react';

import { TooltipModel } from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';
import { ICharacteristic } from '@containers/ProductListing/model';

import {
  CharacteristicName,
  CharacteristicRow,
  CharacteristicValue,
  TooltipContentWrapper,
} from './styled';

interface IProps {
  item: ICharacteristic;
  fullWidth: boolean;
}

export const Characteristic = ({
  item: { name, help, values },
  fullWidth,
}: IProps) => {
  return (
    <CharacteristicRow fullWidth={fullWidth}>
      <CharacteristicName isTooltip={!!help}>
        {help ? (
          <PortalTooltip
            position={TooltipModel.Position.topRight}
            content={
              <PortalTooltip.Content>
                <TooltipContentWrapper>{help}</TooltipContentWrapper>
              </PortalTooltip.Content>
            }
          >
            {name}
          </PortalTooltip>
        ) : (
          name
        )}
      </CharacteristicName>

      <CharacteristicValue>{values.join(', ')}</CharacteristicValue>
    </CharacteristicRow>
  );
};
