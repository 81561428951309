import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

interface IArrowWrapperProps {
  isLeft: boolean;
  arrowMarginFromTop?: string;
}

const leftArrowCSS = css`
  left: 0.09375em;
`;

const rightArrowCSS = css<IArrowWrapperProps>`
  right: 0.09375em;
`;

export const ArrowWrapper = styled.div<IArrowWrapperProps>`
  position: absolute;
  top: ${({ arrowMarginFromTop = '50%' }) => arrowMarginFromTop};
  display: none;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  z-index: 2;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background-color: ${theme.color.background.primary};
  width: 3.25em;
  height: 3.25em;
  border-radius: 50%;

  ${({ isLeft }) => (isLeft ? leftArrowCSS : rightArrowCSS)}

  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }

  svg {
    margin: auto;
  }

  &:hover {
    background-color: #f7f7f7;
  }
`;
