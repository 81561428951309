import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { dispatchTrackingEvent } from '@context/tracking/utils';

import { useCurrentVariant } from '../ProductDetail/hooks/useCurrentVariant';

import { messages } from './messages';
import {
  ForbiddenProductScreenWrapper,
  Collection,
  Brand,
  ForbiddenInfoWrapper,
  TitleWrapper,
  ViewPort,
} from './styled';

interface IForbiddenProductScreenProps {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
}

export const ForbiddenProductScreen: React.FC<IForbiddenProductScreenProps> = ({
  product,
}) => {
  const variant = useCurrentVariant(product.variants);

  React.useEffect(() => {
    dispatchTrackingEvent({
      event: 'page_view',
      page: {
        path: window.location.pathname,
        title: variant.metaData.title,
        type: 'product',
        unified_name: `product-${variant.productCode}`,
        subpage: undefined,
      },
      _clear: true,
    });

    dispatchTrackingEvent({
      event: 'view_item_failed',
      products: [{ product_code: variant.productCode }],
      error: {
        status: 'Forbidden',
      },
      _clear: true,
    });
  }, [variant]);

  return (
    <ViewPort>
      <ForbiddenProductScreenWrapper>
        <TitleWrapper>
          <Brand>{product.brand.name}</Brand>
          <Collection>{product.collection}</Collection>
        </TitleWrapper>
        <ForbiddenInfoWrapper>
          <FormattedMessage {...messages.forbiddenDescription} />
        </ForbiddenInfoWrapper>
      </ForbiddenProductScreenWrapper>
    </ViewPort>
  );
};
