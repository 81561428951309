export function hasImageShadow(src: string) {
  return src.includes('-o/');
}

export type ImageProps = {
  hasShadow: boolean;
  fullHeight: boolean;
};

export function getImageProps(img: HTMLImageElement, idx: number): ImageProps {
  const aspectRatio = img.naturalWidth / img.naturalHeight;

  const hasShadow = hasImageShadow(img.src);
  const canBeSized = idx !== 0 && !hasShadow;
  const isMoodImage = aspectRatio === 1;

  return {
    fullHeight: isMoodImage && canBeSized,
    hasShadow,
  };
}
