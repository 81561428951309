import * as React from 'react';

import { Video, VideoType } from '@notino/shared/definitions/types';

import {
  InstructionsWrapper,
  VideoWrapper,
  Video as VideoStyled,
} from './styled';

interface IInstructionsProps {
  video: Video;
}

const videoUrlMap: Record<VideoType, (id: string) => string> = {
  Youtube: (id) => `https://www.youtube-nocookie.com/embed/${id}`,
  Vimeo: (id) => `https://player.vimeo.com/video/${id}?dnt=1`,
};

export const VideoInstructions: React.FC<IInstructionsProps> = ({ video }) => {
  return (
    <InstructionsWrapper>
      {video && (
        <VideoWrapper>
          <VideoStyled
            src={videoUrlMap[video.type](video.id)}
            allow="encrypted-media"
            allowFullScreen={true}
          />
        </VideoWrapper>
      )}
    </InstructionsWrapper>
  );
};
