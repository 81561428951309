import { MutableRefObject } from 'react';

export type ScrollStage =
  | 'container-top'
  | 'container-bottom'
  | 'translated-scroll-up'
  | 'translated-scroll-down'
  | 'fixed-scroll-down'
  | 'fixed-scroll-up'
  | 'sticky-top';

const createAssignStyles =
  (stickyEl: HTMLElement) =>
  ({
    position,
    translateY,
    top,
    bottom,
  }: {
    position?: string;
    translateY?: number;
    top?: number;
    bottom?: number;
  }) => {
    stickyEl.style.position = position || 'relative';
    stickyEl.style.transform = translateY
      ? `translate3d(0px, ${translateY}px, 0px)`
      : '';
    stickyEl.style.top = top || top === 0 ? `${top}px` : '';
    stickyEl.style.bottom = bottom || bottom === 0 ? `${bottom}px` : '';
  };

export const getScrollStageStylesMap = (
  stickyElRef: MutableRefObject<HTMLElement>,
  containerElRef: MutableRefObject<HTMLElement>
): Record<ScrollStage, () => void> => {
  const stickyEl = stickyElRef.current;
  const containerEl = containerElRef.current;
  const assign = createAssignStyles(stickyEl);
  const distanceFromPageTop =
    window.pageYOffset + containerEl.getBoundingClientRect().top;

  return {
    'sticky-top': () => assign({ position: 'sticky', top: 0 }),
    'container-top': () => assign({ top: 0 }),
    'container-bottom': () => assign({ position: 'absolute', bottom: 0 }),
    'fixed-scroll-up': () => assign({ position: 'fixed', top: 0 }),
    'fixed-scroll-down': () => {
      assign({
        position: 'fixed',
        top: stickyEl.getBoundingClientRect().top,
      });
    },
    'translated-scroll-up': () =>
      assign({
        translateY:
          window.scrollY +
          stickyEl.getBoundingClientRect().top -
          distanceFromPageTop,
      }),
    'translated-scroll-down': () =>
      assign({ translateY: window.scrollY - distanceFromPageTop }),
  };
};
