import { dispatchTrackingEvent } from '@context/tracking/utils';

interface ITrackTab {
  timing: number;
  trackingLabel: string;
  mode: string;
}

export const trackTab = ({ timing, trackingLabel, mode }: ITrackTab) => {
  return dispatchTrackingEvent({
    event: 'subpage_view',
    subpage: {
      type: 'tab',
      interaction: 'click',
      action: 'click_on_tab',
      name: trackingLabel,
      timing,
      mode,
    },
    _clear: true,
  });
};
