export enum EAvailableHeaders {
  shopId = 'x-shopid',
  groupId = 'x-groupid',
  role = 'x-role',
  userId = 'x-userid',
  cartId = 'x-cartid',
  userEmail = 'X-User-Email',
  acceptLanguage = 'accept-language',
  authorization = 'authorization',
  comparisonids = 'x-comparisonids',
  gatewayUrl = 'x-gatewayurl',
  uuid = 'request-id',
  grd = 'x-grd',
  features = 'x-features',
  xUser = 'x-user',
  xRouter = 'x-router',
  xLangTag = 'x-langtag',
  xAbTest = 'x-abtest',
  xLayout = 'x-layout',
  xOriginRouterUrl = 'x-original-router-url',
  xFragmentType = 'x-fragment-type',
  xSharedLibs = 'x-shared-libs',
  cacheControl = 'Cache-Control',
  xTraceId = 'x-trace-id',
  xTurnstileToken = 'x-turnstile-token',
}
