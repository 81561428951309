import * as React from 'react';

import { IOrderBy } from '@containers/ProductDetailContainer/model';

import { Action, ActionTypes, IActions } from './model';

export const useActions = (dispatch: React.Dispatch<Action>): IActions => {
  return React.useMemo(
    () => ({
      changeOrder: (order: IOrderBy) => {
        dispatch({ type: ActionTypes.CHANGE_ORDER, value: order });
      },
      incNextPage: () => {
        dispatch({ type: ActionTypes.INC_NEXT_PAGE });
      },
      toggleHideTranslatedReviews: () => {
        dispatch({ type: ActionTypes.TOGGLE_HIDE_TRANSLATED_REVIEWS });
      },
    }),
    [dispatch]
  );
};
