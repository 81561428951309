import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  brandsNCategoriesN: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.brands.N.categories.N',
    defaultMessage: 'Platí na <these>tyto</these> značky a kategorie',
  },
  brandsNCategories0: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.brands.N.categories.0',
    defaultMessage: 'Platí na tyto <brands>značky</brands>',
  },
  brands0CategoriesN: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.brands.0.categories.N',
    defaultMessage: 'Platí na tyto <categories>kategorie</categories>',
  },
  brands1Categories0: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.brands.1.categories.0',
    defaultMessage: 'Platí na značku {brand}',
  },
  brands0Categories1: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.brands.0.categories.1',
    defaultMessage: 'Platí na kategorii {category}',
  },
  selectedProducts: {
    id: 'pd.multiTierVoucherDiscount.appliesTo.selectedProducts',
    defaultMessage: 'Platí na vybrané produkty',
  },
});
