import styled, { css } from 'styled-components';

import { theme } from '@notino/react-styleguide';

export const Arrow = styled.div<{ position: 'left' | 'right' }>`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  bottom: 0;
  z-index: 2;
  width: 2.75rem;
  height: 2.75rem;
  background: ${theme.color.background.primary};
  transform: translateY(-50%);
  border-radius: 3rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: 0.2s ease-in-out;

  &:hover {
    background: ${theme.color.background.disabled};
  }

  ${({ position }) =>
    position === 'left' &&
    css`
      left: 0.5rem;
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      right: 0.5rem;
    `}
`;
export const Fade = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 5rem;
  background: ${({
    position,
  }) => `linear-gradient(to ${position}, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
`};

  ${({ position }) =>
    position === 'left' &&
    css`
      left: -1px;
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      right: -1px;
    `}
`;
