import * as React from 'react';

import { AddToCartSettingsQuery } from '@notino/shared/definitions/types';

import { BuyButton } from '../sharedComponents/BuyButton';

import {
  DescriptionWrapper,
  ContentWrapper,
  ModalOrDrawerButtonWrapper,
} from './styled';

interface IProductForProcessionalModalOrDrawerProps {
  description: JSX.Element | string;
  settings: AddToCartSettingsQuery['Settings'];
  productId: string;
  productCode: string;
}

export const SpecialAddToCartModalOrDrawer: React.FC<
  IProductForProcessionalModalOrDrawerProps
> = ({ description, settings, productId, productCode }) => {
  return (
    <ContentWrapper data-testid="special-add-to-cart-modal">
      <DescriptionWrapper>{description}</DescriptionWrapper>
      <ModalOrDrawerButtonWrapper>
        <BuyButton
          productId={productId}
          productCode={productCode}
          isSpecialProduct={true}
          isOnSpecialModal={true}
          Settings={settings}
          buttonType="button"
        />
      </ModalOrDrawerButtonWrapper>
    </ContentWrapper>
  );
};
