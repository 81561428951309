import * as React from 'react';

import { useQuery, useApolloClient } from '@apollo/client';

import { HasWebcamQuery } from '@notino/shared/definitions/types';

import { hasDeviceWebcam } from '../ModiFaceModal/utils';

import hasWebcamQuery from './hasWebcamQuery.graphql';

const DETECT_WEBCAM_TIMEOUT = 500;

export const useHasWebcam = () => {
  const client = useApolloClient();
  const {
    data = { ClientCache: { hasWebcam: undefined, __typename: 'ClientCache' } },
  } = useQuery<HasWebcamQuery>(hasWebcamQuery, {
    fetchPolicy: 'cache-only',
    ssr: false,
  });

  const detectWebcamAndTrackProductDetailView = React.useCallback(
    (trackerFunc: (HasWebcam: boolean) => void) => {
      const hasWebcamQueryData = client.cache.readQuery<HasWebcamQuery>({
        query: hasWebcamQuery,
      });

      if (typeof hasWebcamQueryData?.ClientCache?.hasWebcam === 'boolean') {
        trackerFunc(hasWebcamQueryData?.ClientCache?.hasWebcam);
        return;
      }

      let tracked = false;
      // save timeout that fires trackerFunction
      setTimeout(() => {
        if (!tracked) {
          tracked = true;
          trackerFunc(false);
        }
      }, DETECT_WEBCAM_TIMEOUT);

      hasDeviceWebcam((hasDeviceWebcamera: boolean) => {
        if (!tracked) {
          tracked = true;
          trackerFunc(hasDeviceWebcamera);
        }

        client.writeQuery<HasWebcamQuery>({
          query: hasWebcamQuery,
          data: {
            ClientCache: {
              hasWebcam: hasDeviceWebcamera,
              __typename: 'ClientCache',
            },
          },
        });
      });
    },
    [client]
  );

  return [
    data?.ClientCache?.hasWebcam,
    detectWebcamAndTrackProductDetailView,
  ] as const;
};
