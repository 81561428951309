import * as React from 'react';

import ErrorBoundary from '@components/ErrorBoundary';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { BazaarvoiceRatings } from './BazaarvoiceRatings';
import { IHeaderProps } from './model';
import { ProductRating } from './ProductRating';
import {
  StyledHeader,
  H1,
  Span,
  Description,
  SubName,
  BrandExtraLine,
  ProductNameWrapper,
} from './styled';
import { trackBrandClick, withSpaceAfterWord } from './utils';

export const Header = ({
  desc,
  collection,
  subName,
  brandInfo,
  catalogId,
  productCode,
  children,
  reviewsId,
  hideReviews = false,
}: React.PropsWithChildren<IHeaderProps>) => {
  const { bazaarvoice_pd_components } = useFeatureFlags();
  const { getTimeFromInit } = useTrackingContext();

  const handleTrackClick = () =>
    trackBrandClick({
      timing: getTimeFromInit(),
      name: brandInfo.name,
    });

  const showOwnRatings = !hideReviews && !bazaarvoice_pd_components;
  const showBazaarvoiceRatings = !hideReviews && bazaarvoice_pd_components;

  return (
    <ErrorBoundary>
      <StyledHeader id="pdHeader">
        <H1 data-testid="pd-header-title">
          <ProductNameWrapper>
            {brandInfo && (
              <BrandExtraLine onClick={handleTrackClick} href={brandInfo.url}>
                {brandInfo.name}
              </BrandExtraLine>
            )}

            <Span>{withSpaceAfterWord(collection)}</Span>
          </ProductNameWrapper>

          {subName && <SubName>{withSpaceAfterWord(subName)}</SubName>}

          <Description>{desc}</Description>
        </H1>

        {showOwnRatings && (
          <ProductRating reviewsId={reviewsId} catalogMasterId={catalogId} />
        )}

        {showBazaarvoiceRatings && (
          <BazaarvoiceRatings productCode={productCode} />
        )}

        {children}
      </StyledHeader>
    </ErrorBoundary>
  );
};
