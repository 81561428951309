import { snakeCase } from 'lodash';

import { transformAttributesToTags } from '@components/Tags/utils';
import { transformProductIcons } from '@components/Universals/ProductItem/utils';
import { IConditionalFreeDelivery } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/ConditionalFreeDelivery/model';
import { IConditionalVoucherDiscount } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/VoucherDiscount/ConditionalVoucherDiscount';
import { IVoucherDiscount } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/VoucherDiscount/VoucherDiscount';
import { noop } from '@utils/noop';

import { IVariantShared } from './model';

export const getPromoLabels = (attributes: IVariantShared['attributes']) => {
  const tags = transformAttributesToTags(attributes)
    .map(snakeCase)
    .filter(Boolean);

  const productIcons = transformProductIcons(attributes, noop)
    .map(({ icon }) => snakeCase(icon))
    .filter(Boolean);

  const promoLabels = [...tags, ...productIcons];

  if (attributes.ConditionalFreeDelivery) {
    const { type, targets } =
      attributes.ConditionalFreeDelivery as IConditionalFreeDelivery;

    if (type === 'Piece' && targets.some((target) => target.type === 'Brand')) {
      promoLabels.push('free_del_qty');
    }

    if (type === 'Price') {
      promoLabels.push('free_del_min');
    }
  }

  if (attributes.VoucherDiscount) {
    const { displayType } = attributes.VoucherDiscount as IVoucherDiscount;
    if (displayType === 'Percentage') {
      promoLabels.push('unk_cpn_rel');
    }

    if (displayType === 'DiscountedPrice') {
      promoLabels.push('unk_cpn_abs');
    }
  }

  if (attributes.ConditionalVoucherDiscount) {
    const { conditionType, discountType } =
      attributes.ConditionalVoucherDiscount as IConditionalVoucherDiscount;

    const isPriceCondition = conditionType === 'Price';
    const isPieceCondition = conditionType === 'Piece';
    const isAbsoluteDiscount = discountType === 'Absolute';
    const isPercentageDiscount = discountType === 'Percentage';

    if (isPriceCondition && isAbsoluteDiscount) {
      promoLabels.push('cpn_abs_min');
    }

    if (isPriceCondition && isPercentageDiscount) {
      promoLabels.push('cpn_rel_min');
    }

    if (isPieceCondition && isPercentageDiscount) {
      promoLabels.push('cpn_rel_qty');
    }

    if (isPieceCondition && isAbsoluteDiscount) {
      promoLabels.push('cpn_abs_qty');
    }
  }

  return promoLabels;
};
