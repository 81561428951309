import React from 'react';

export const registerKeyboardListeners = (
  keyboardEventListeners: Partial<
    Record<string, (e: React.KeyboardEvent) => void>
  >,
  disableListeners?: boolean
): { onKeyUp?: React.KeyboardEventHandler } => {
  if (disableListeners) {
    return {};
  }

  return {
    onKeyUp: (event) => {
      // space key doesn't have 'key' attribute
      const eventName = event.keyCode === 32 ? 'Space' : event.key;
      keyboardEventListeners[eventName]?.(event);
    },
  };
};
