import * as React from 'react';
import { useLocation } from 'react-router';

import { useQuery } from '@apollo/client';

import { ReactFCC } from '@notino/react-styleguide';
import {
  GetCatalogProductViewQuery,
  GetCatalogProductViewQueryVariables,
} from '@notino/shared/definitions/types';

import PageNotFound, { PageLoadFailed } from '@components/NotFound';
import { Loading } from '@containers/ProductDetailContainer/loading';
import { selectVariant } from '@containers/ProductDetailContainer/utils';

import getProductView from '../../containers/ProductDetailContainer/queries/catalogProductView.graphql';

export interface IProductViewProviderProps {
  catalogId: number;
  productId: number;
  ssrEnabled: boolean;
}

interface IProductViewContext {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  data: GetCatalogProductViewQuery;
}

export const ProductViewContext =
  React.createContext<IProductViewContext | null>(null);

export const ProductViewProvider: ReactFCC<IProductViewProviderProps> = ({
  catalogId,
  productId,
  ssrEnabled,
  children,
}) => {
  const location = useLocation();

  const { data, loading, error } = useQuery<
    GetCatalogProductViewQuery,
    GetCatalogProductViewQueryVariables
  >(getProductView, {
    ssr: ssrEnabled,
    variables: {
      masterId: String(productId),
      catalogMasterId: String(catalogId),
    },

    errorPolicy: 'all',
  });

  if (!data?.productDetailByCatalogMasterId) {
    if (loading) {
      return <Loading productId={String(productId)} />;
    }
    if (error) {
      return <PageLoadFailed reason="unable to load PD data" />;
    }

    return <PageNotFound reason="Product not found" />;
  }

  const { productDetailByCatalogMasterId } = data;
  let product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  try {
    // on ssr errors are not propagated (due to apollo bug) we need to check data
    product = productDetailByCatalogMasterId;
  } catch (e) {
    return <PageLoadFailed reason="unable to load PD data" />;
  }

  if (!product) {
    return <PageNotFound reason="Product not found" />;
  }

  const { variants } = product;
  const variant = selectVariant(variants, location.pathname);

  const value = {
    data,
    variant,
    product,
  };

  return (
    <ProductViewContext.Provider value={value}>
      {children}
    </ProductViewContext.Provider>
  );
};

export const useProductView = () => {
  const ctx = React.useContext(ProductViewContext);
  if (!ctx) {
    throw new Error('useProductView must be used inside ProductViewProvider.');
  }

  return ctx;
};
