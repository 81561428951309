import styled from 'styled-components';

import { Heading, breakpoints } from '@notino/react-styleguide';

export const ForbiddenProductScreenWrapper = styled(Heading.H1)`
  margin: auto;
  display: block;
  min-height: 21.875rem;
`;

export const TitleWrapper = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
  }
`;

export const Brand = styled.span`
  font-weight: 300;
`;

export const Collection = styled.span`
  font-size: 1.125rem;
  font-weight: bold;
  margin-left: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
  }
`;

export const ForbiddenInfoWrapper = styled.div`
  max-width: 50rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: normal;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
    display: block;
    margin: auto;
    font-size: 1rem;
  }
`;
export const ViewPort = styled.main`
  margin-top: 3.5rem;
  padding-bottom: 3.125rem;
`;
