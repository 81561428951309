import * as React from 'react';

import { useQuery } from '@apollo/client';

import { Ratings } from '@notino/react-styleguide';
import {
  GetReviewOverviewQuery,
  GetReviewOverviewQueryVariables,
} from '@notino/shared/definitions/types';

import { useTrackingContext } from '@context/tracking/TrackingContext';
import { TabLink } from '@sharedComponents/TabSwitcher';

import getReviewOverviewQuery from '../../queries/reviewOverview.graphql';
import { TabIds } from '../model';

import { RatingWrapper, ReviewsAnchor } from './styled';
import { trackRatingClick } from './utils';

interface IPlaceholderProps {
  catalogMasterId: string;
  reviewsId?: string;
}

export const ProductRating = ({
  catalogMasterId,
  reviewsId = 'productBaseInfo',
}: IPlaceholderProps) => {
  const { getTimeFromInit } = useTrackingContext();
  const { loading, error, data } = useQuery<
    GetReviewOverviewQuery,
    GetReviewOverviewQueryVariables
  >(getReviewOverviewQuery, {
    ssr: true,
    errorPolicy: 'all',
    variables: {
      catalogMasterId,
    },
  });

  const reviewOverview = data?.productDetailByCatalogMasterId?.reviewOverview;

  const isReviewOverviewLoaded = !loading && !error && reviewOverview;

  const rating = isReviewOverviewLoaded ? reviewOverview.rating : 4.0;
  const totalVotes = isReviewOverviewLoaded ? reviewOverview.count : 1;

  return (
    <TabLink tabId={TabIds.Reviews}>
      {({ setActiveTab }) => {
        const handleReviewClick = () => {
          trackRatingClick({ timing: getTimeFromInit() });
          setActiveTab();
        };

        return (
          <ReviewsAnchor
            href="#pdReviewsScroll"
            onClick={handleReviewClick}
            title={rating.toString()}
          >
            <RatingWrapper>
              <Ratings
                ratingId={reviewsId}
                rating={rating}
                size={16}
                numReviews={totalVotes}
                foreground="text.highlight"
                animationDuration={0.5}
                microdata={false}
              />
            </RatingWrapper>
          </ReviewsAnchor>
        );
      }}
    </TabLink>
  );
};
