export interface ILookObject {
  category: string;

  colorA?: number;
  colorR?: number;
  colorG?: number;
  colorB?: number;

  intensity?: number;

  sparkleA?: number;
  sparkleR?: number;
  sparkleG?: number;
  sparkleB?: number;

  gamma?: number;
  glossDetail?: number;
  wetness?: number;

  // Foundation Only
  skinGlow?: number;
  skinClearing?: number;
  contrastBoost?: number;
  placement: string;
}

export enum EModifaceApiDataType {
  HAIR = 'Hair',
  MAKE_UP = 'Makeup',
}
