import { css } from 'styled-components';

import {
  styled,
  PictogramRegularDermo as SgRecomendedByDermatologistsIcon,
  breakpoints,
  theme,
  PictogramRegularPowerOfBeauty,
  PictogramRegularSummerBlackFriday,
  Link,
} from '@notino/react-styleguide';

const dermoIconCss = css`
  width: 3.5rem;
  height: 3.5rem;

  ${theme.typography.labelSmall400};
  font-weight: 500;

  @media (min-width: ${breakpoints.md}) {
    width: 4rem;
    height: 4rem;
  }
`;

export const RecomendedByDermatologistsIcon = styled(
  SgRecomendedByDermatologistsIcon
)`
  ${dermoIconCss}
`;

export const StyledPowerOfBeautyIcon = styled(PictogramRegularPowerOfBeauty)`
  width: 3.8rem;
  height: 2.5rem;

  @media (min-width: ${breakpoints.md}) {
    width: 4.5rem;
    height: 3rem;
  }
`;
export const StyledSummerBlackFridayIcon = styled(
  PictogramRegularSummerBlackFriday
)`
  min-width: 3.75rem !important;
  height: 3.125rem;

  line-height: 0.9125rem;
  font-size: 0.725rem;

  @media (min-width: ${breakpoints.md}) {
    min-width: 4.063rem !important;
    height: 3.75rem;
  }
`;

export const DermoLink = styled(Link)`
  cursor: pointer;
  width: fit-content;
`;
