import * as React from 'react';

import { CatalogProductDetailViewFragmentFragment } from '@notino/shared/definitions/types';

import { usePriceConfig } from '@components/PriceLabel/usePriceConfig';

export const useSsrProductUrl = (
  product: CatalogProductDetailViewFragmentFragment
) => {
  const { data: { currentLocale } = {} } = usePriceConfig();

  const xDefault = React.useMemo(() => {
    const findByCulture = (culture: string) =>
      product.alternateSites.find((site) => site.culture === culture);
    return (
      findByCulture('en-GB') ||
      findByCulture('cs-CZ') ||
      product.alternateSites[0]
    );
  }, [product.alternateSites]);

  const url = React.useMemo(
    () =>
      (
        product.alternateSites.find(
          (site) => site.culture === currentLocale?.tag
        ) || xDefault
      )?.link,
    [product, currentLocale, xDefault]
  );

  return { url, xDefault } as const;
};
