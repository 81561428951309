import * as React from 'react';

import { VariantDisplayType } from '@notino/shared/definitions/types';

import { IVariantsProps as ICommonVariantsProps } from '../../../model';

import { VariantsColorPickers } from './VariantsColorPickers';
import { VariantsThumbnail } from './VariantsThumbnail';
import { VariantsTile } from './VariantsTile';

interface IVariantsProps extends ICommonVariantsProps {
  variantsLen: number;
  displayType: VariantDisplayType;
}

export const Variants: React.FC<IVariantsProps> = React.memo(
  ({ variantsLen, displayType, ...rest }) => {
    if (variantsLen > 1) {
      switch (displayType) {
        case VariantDisplayType.Thumbnail:
          return <VariantsThumbnail {...rest} />;
        case VariantDisplayType.ColorPicker:
          return <VariantsColorPickers {...rest} />;
        case VariantDisplayType.Tiles:
        default:
          return <VariantsTile {...rest} />;
      }
    }

    if (variantsLen === 1) {
      if (displayType === VariantDisplayType.ColorPicker) {
        return <VariantsColorPickers {...rest} />;
      }
      return null; // hide variant tile if there is only one variant
    }

    return <VariantsTile {...rest} />;
  }
);
