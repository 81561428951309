import styled, { css } from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const SliderWrapper = styled.div`
  display: block;
  position: relative;
  height: 100%;
`;

interface IArrowWrapperProps {
  isLeft: boolean;
}

const leftArrowCSS = css`
  left: 0;
  @media (min-width: ${breakpoints.lg}) {
    left: -1.5625rem;
  }
`;

const rightArrowCSS = css`
  right: 0;
  @media (min-width: ${breakpoints.lg}) {
    right: -1.5625rem;
  }
`;

export const ArrowWrapper = styled.div<IArrowWrapperProps>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background-color: rgb(255, 255, 255);
  z-index: 2;

  ${({ isLeft }) => (isLeft ? leftArrowCSS : rightArrowCSS)}
`;
