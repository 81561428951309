import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pickShade: {
    id: 'pd.variant.drawer.pick.shade',
    defaultMessage: 'Zvolte si odstín',
  },
  shadeList: {
    id: 'pd.variant.drawer.shadeList',
    defaultMessage: 'Výpis odstínů',
  },
});
