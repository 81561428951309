import {
  CartProductStatus,
  AddProductsToCartMutation,
} from '@notino/shared/definitions/types';

import { withTryToInvokeTrackingEvent } from '@utils/exponea-logging';

import messages from './messages';
import { AddToCartProductErrors, ProductInfo } from './model';

export const servicesPrefix = 'services';

export const parseStatuses = (
  serviceAddResponse: AddProductsToCartMutation['AddProductsToCart']['serviceAddResponse'],
  productIncrease: number,
  requestedCount: number
) => {
  const result: (keyof typeof messages)[] = [];

  if (requestedCount !== productIncrease) {
    result.push('productsAddReducedCount');
  }

  if (serviceAddResponse) {
    serviceAddResponse.forEach((svcResponse) => {
      svcResponse.status.forEach((status) => {
        const statusMessageKey =
          `${servicesPrefix}${svcResponse.service.type}${status}` as keyof typeof messages;
        if (
          status !== CartProductStatus.Default &&
          !result.includes(statusMessageKey)
        ) {
          result.push(statusMessageKey);
        }
      });
    });
  }

  return result;
};

export type CartNotificationMessage =
  | {
      modalType: 'common';
      header: keyof typeof messages;
      messages: (keyof typeof messages)[];
      values?: Record<string, string>;
    }
  | {
      modalType: 'maxProductsInCart';
    };

export const generateNotificationForProduct = (
  fatalErrors: string[],
  product: ProductInfo
): CartNotificationMessage => {
  if (fatalErrors.includes(AddToCartProductErrors.SC_CART_PRICE_OUT_OF_LIMIT)) {
    return {
      modalType: 'common',
      header: 'cartLimitExceededHeader',
      messages: ['cartLimitExceededExplanation', 'cartLimitExceededAdvice'],
    };
  }

  if (fatalErrors.includes(AddToCartProductErrors.SC_SOLD_PRODUCT)) {
    return {
      modalType: 'common',
      header: 'cartSoldoutHeader',
      messages: ['cartSoldoutExplanation'],
      values: { productName: `${product.name} ${product.variantName}` },
    };
  }

  if (
    fatalErrors.includes(AddToCartProductErrors.SC_ONLINE_VOUCHER_AND_PRODUCT)
  ) {
    return {
      modalType: 'common',
      header: 'cartOnlyVoucherHeader',
      messages: ['cartOnlyVoucherExplanation'],
    };
  }

  if (
    fatalErrors.includes(AddToCartProductErrors.SC_MAX_PRODUCTS_IN_CART_ERROR)
  ) {
    return {
      modalType: 'maxProductsInCart',
    };
  }

  if (fatalErrors.length > 0) {
    return {
      modalType: 'common',
      header: 'productsAddError',
      messages: [],
    };
  }

  return null;
};

export const runTrackingEventCartUpdate = withTryToInvokeTrackingEvent(
  'CartUpdate',
  (payload: Parameters<typeof window.trackingEvents.CartUpdate>[0]) => {
    void window.trackingEvents.CartUpdate(payload);
  }
);
