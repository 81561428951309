export const imageErrorHandler =
  (fallbackSrc) =>
  ({ currentTarget: img }) => {
    /**
     * Iterates over sources from srcset and tries to find a source that is not failing.
     * If all fail, sets fallback image.
     */

    const otherSrcs = img.srcset
      .split(', ')
      .filter((src) => !src.match(img.currentSrc));

    if (otherSrcs.length) {
      img.srcset = otherSrcs.join(', ');
    } else {
      img.src = fallbackSrc;
      img.srcset = '';
    }
  };
