import { useQuery } from '@apollo/client';

import {
  AvailabilityState,
  CatalogVariantFragmentFragment,
  WatchdogStateQuery,
  WatchdogStateQueryVariables,
} from '@notino/shared/definitions/types';

import { IVariant } from '@containers/ProductListing/model';
import { useUser } from '@queries/useUser';

import watchdogActiveQuery from './queries/watchdogActive.graphql';

type UseWatchdogActiveParams = {
  variant:
    | Pick<IVariant, 'showWatchdog' | 'catalogId'>
    | Pick<CatalogVariantFragmentFragment, 'availability' | 'catalogId'>;
  cacheOnly?: boolean;
  hasRegisteredWatchdog?: boolean;
};

export const useWatchdogActive = ({
  variant,
  cacheOnly = true,
  hasRegisteredWatchdog,
}: UseWatchdogActiveParams) => {
  const { isLoggedIn } = useUser();

  const showWatchdog =
    'showWatchdog' in variant
      ? variant.showWatchdog
      : variant.availability.state === AvailabilityState.ShowWatchdog;

  const canHaveActiveWatchdog = isLoggedIn && showWatchdog;

  const { data, updateQuery } = useQuery<
    WatchdogStateQuery,
    WatchdogStateQueryVariables
  >(watchdogActiveQuery, {
    variables: { catalogId: Number(variant.catalogId) },
    skip: !canHaveActiveWatchdog,
    ssr: false,
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-first',
  });

  const setWatchdogActiveCache = () => {
    updateQuery((prev) => ({
      ...prev,
      watchdogState: {
        ...prev?.watchdogState,
        watchdogActive: true,
      },
    }));
  };

  return {
    watchdogActive:
      data?.watchdogState?.watchdogActive || hasRegisteredWatchdog,
    setWatchdogActiveCache,
  };
};
