import * as React from 'react';
import { useIntl } from 'react-intl';

import { Breadcrumb as BreadcrumbSG } from '@notino/react-styleguide';

import { useTrackingContext } from '@context/tracking/TrackingContext';

import messages from '../../messages';

import { BreadcrumbsJsonLd } from './BreadcrumbsJsonLd';
import { IBreadcrumb } from './model';
import { BreadcrumbWrapper } from './styled';
import { trackBreadcrumbClick } from './utils';

interface IBreadcrumbProps {
  items: IBreadcrumb[];
  alignToRight?: boolean;
  renderJsonLd?: boolean;
}

export const Breadcrumb = ({
  items,
  alignToRight = true,
  renderJsonLd = true,
}: IBreadcrumbProps) => {
  const { getTimeFromInit, getVariant } = useTrackingContext();
  const { formatMessage } = useIntl();

  const handleTrackClick = ({ index, link, name }) => {
    trackBreadcrumbClick({
      index,
      link,
      name,
      timing: getTimeFromInit(),
      variant: getVariant(),
      text: items.map((i) => i.name),
    });
  };

  return (
    items?.length > 0 && (
      <BreadcrumbWrapper>
        <BreadcrumbSG
          data-testid="breadcrumb-wrapper"
          onItemClick={handleTrackClick}
          alignToRight={alignToRight && items.length > 4}
          items={items}
          homeLinkTitle={formatMessage(messages.mainPage)}
        />
        {renderJsonLd && <BreadcrumbsJsonLd items={items} />}
      </BreadcrumbWrapper>
    )
  );
};
