import * as React from 'react';

import { noop } from '@utils/noop';

import { PlaceholderIcon } from './PlaceholderIcon';
import { GiftImg, ImageWrapper, PlaceholderIconWrapper } from './styled';

interface IGiftImageThumbProps {
  imageUrl: string;
  formattedImageMessage: string;
  sourceKey: string;
  touch?: boolean;
  onClick?: () => void;
}

export const GiftImageThumb: React.FC<IGiftImageThumbProps> = ({
  imageUrl,
  formattedImageMessage,
  sourceKey,
  onClick = noop,
}) => {
  return (
    <ImageWrapper
      data-testid="gift-image-wrapper"
      onClick={imageUrl && onClick}
    >
      {imageUrl ? (
        <GiftImg
          src={imageUrl}
          alt={formattedImageMessage}
          sourceKey={sourceKey}
        />
      ) : (
        <PlaceholderIconWrapper>
          <PlaceholderIcon />
        </PlaceholderIconWrapper>
      )}
    </ImageWrapper>
  );
};
