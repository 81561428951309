import * as React from 'react';

import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const useTrackModalOrDrawerShow = () => {
  const { getTimeFromInit } = useTrackingContext();

  React.useEffect(() => {
    dispatchTrackingEvent({
      event: 'subpage_view',
      subpage: {
        name: 'click_and_collect_modal',
        interaction: 'automatic',
        timing: getTimeFromInit(),
        action: 'click_and_collect_opened',
        type: 'click_and_collect',
      },
      _clear: true,
    });
  }, [getTimeFromInit]);
};
