import * as React from 'react';
import { FormattedDate } from 'react-intl';

import { LivestreamCard as ExternalLivestreamCard } from '@notino/react-styleguide';

import {
  ILivestream,
  EShowComponentLayout,
} from '@components/LivestreamBanner/model';
import { getBannerTheme } from '@components/LivestreamBanner/utils/getBannerTheme';
import { getShowElementId } from '@components/LivestreamBanner/utils/getShowElementId';
import { ELivestreamTrackingInteraction } from '@containers/ProductDetailContainer/ProductDetail/livestreamTracking';
import { useMobileLivestreamTracking } from '@containers/ProductDetailContainer/ProductDetail/ProductTabContent/ProductLivestreams/hooks/useLivestreamTracking';

interface IInternalLivestreamCardProps extends ILivestream {
  index: number;
}

export const LivestreamCard = ({
  showId,
  previewImageUrl,
  description,
  isLive,
  scheduledDate,
  title,
  videoLength,
  publishedDate,
  scheduledStart,
  index,
}: IInternalLivestreamCardProps) => {
  const { backgroundColor, fontColor } = getBannerTheme(isLive, scheduledDate);

  const { livestreamRef, trackOnClick } = useMobileLivestreamTracking({
    showId,
    itemIndex: index,
    viewInteraction:
      index < 2
        ? //First two shows are viewed in stardard way, to others you must slide
          ELivestreamTrackingInteraction.AUTOMATIC
        : ELivestreamTrackingInteraction.SLIDE,
  });

  return (
    <div ref={livestreamRef}>
      <ExternalLivestreamCard
        key={showId}
        onClick={() => trackOnClick(ELivestreamTrackingInteraction.IMAGE)}
        showId={getShowElementId(showId, EShowComponentLayout.MOBILE)}
        title={title}
        description={description}
        previewImageUrl={previewImageUrl}
        isLive={isLive}
        showDescription={true}
        scheduledStart={scheduledStart}
        videoLength={videoLength}
        scheduledStartIndicatorBackgroundColor={backgroundColor}
        scheduledStartIndicatorColor={fontColor}
        publishedDate={
          <FormattedDate
            value={publishedDate}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        }
        customThemeConfig={{
          container: {
            width: '15rem',
            height: '26.25rem',
            lineClamp: 9,
          },
        }}
      />
    </div>
  );
};
