import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { assignRefs } from '@utils/assignRefs';
import { useIsDesktop } from '@utils/helpers';

import {
  BottomSensor,
  Sticky,
  TopSensor,
  Container,
  ChildrenTopSensor,
  ChildrenBottomSensor,
} from './styled';
import { useAdjustStickyElementWidth } from './useAdjustStickyElementWidth';
import { useInViewRef } from './useInViewRef';
import { useScrollDirectionRef } from './useScrollDirectionRef';
import { ScrollStage, getScrollStageStylesMap } from './utils';

type Props = PropsWithChildren<{
  galleryWrapperRef: React.MutableRefObject<HTMLDivElement>;
}>;

const Component = ({ children, galleryWrapperRef }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const stickyElRef = useRef<HTMLDivElement>(null);
  const scrollDir = useScrollDirectionRef();
  const scrollStageRef = useRef<ScrollStage>('container-top');

  const [topRef, topInView] = useInViewRef();
  const [bottomRef, bottomInView] = useInViewRef();
  const [childrenTopRef, childrenTopInView] = useInViewRef();
  const [childrenBottomRef, childrenBottomInView] = useInViewRef();
  const [containerInViewRef, containerInView] = useInViewRef({
    initialInView: true,
  });

  useAdjustStickyElementWidth(containerRef, stickyElRef);

  const getAssignScrollStageStyles = useCallback(
    () => getScrollStageStylesMap(stickyElRef, containerRef),
    []
  );

  useEffect(() => {
    const containerEl = containerRef.current;
    const stickyEl = stickyElRef.current;
    const galleryWrapperEl = galleryWrapperRef.current;
    if (!containerEl || !stickyEl || !galleryWrapperEl) {
      return;
    }

    const handleScroll = () => {
      let scrollStage = scrollStageRef.current;

      //we need this variable to avoid bug with a faster scroll https://notino.tpondemand.com/entity/385583-new-pd-quickly-scroll-tst
      const containerDistanceFromPageTop =
        window.scrollY + containerEl.getBoundingClientRect().top;

      const wholeSidebarFitsInViewport =
        stickyEl.getBoundingClientRect().height <= window.innerHeight;
      const sidebarIsTallerThanContainer =
        stickyEl.getBoundingClientRect().height >=
        galleryWrapperEl.getBoundingClientRect().height;

      const isFixedStage =
        scrollStage === 'fixed-scroll-up' ||
        scrollStage === 'fixed-scroll-down';

      if (
        wholeSidebarFitsInViewport ||
        sidebarIsTallerThanContainer ||
        !containerInView.current
      ) {
        scrollStage = 'sticky-top';
      } else if (
        topInView.current ||
        window.scrollY < containerDistanceFromPageTop
      ) {
        scrollStage = 'container-top';
      } else if (bottomInView.current) {
        scrollStage = 'container-bottom';
      } else if (scrollDir.current === 'down' && childrenBottomInView.current) {
        scrollStage = 'fixed-scroll-down';
      } else if (
        scrollDir.current === 'down' &&
        scrollStage === 'fixed-scroll-down' &&
        !childrenBottomInView.current
      ) {
        scrollStage = 'translated-scroll-up';
      } else if (scrollDir.current === 'up' && childrenTopInView.current) {
        scrollStage = 'fixed-scroll-up';
      } else if (scrollDir.current === 'up' && isFixedStage) {
        scrollStage = 'translated-scroll-up';
      } else if (scrollDir.current === 'down' && isFixedStage) {
        scrollStage = 'translated-scroll-down';
      }

      if (scrollStageRef.current !== scrollStage) {
        scrollStageRef.current = scrollStage;
        getAssignScrollStageStyles()[scrollStage]();
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    resizeObserver.observe(stickyEl);

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.disconnect();
    };
  }, [
    topInView,
    bottomInView,
    childrenBottomInView,
    childrenTopInView,
    scrollDir,
    galleryWrapperRef,
    containerInView,
    getAssignScrollStageStyles,
  ]);

  useEffect(() => {
    const containerEl = containerRef.current;
    const galleryWrapperEl = galleryWrapperRef.current;

    if (!containerEl || !galleryWrapperEl) {
      return;
    }

    const galleryWrapperHeight = galleryWrapperEl.offsetHeight;
    const containerHeight = containerEl.offsetHeight;

    const handle = () => {
      let scrollStage = scrollStageRef.current;

      // NOTE: case when stickyEl is absolutely positioned at bottom:0px and galleryWrapper gets BIGGER
      // e.g. loading more reviews
      if (scrollStage === 'container-bottom') {
        const isNotScrolledToBottom =
          containerEl.getBoundingClientRect().bottom - window.innerHeight > 0;

        if (isNotScrolledToBottom) {
          scrollStage = 'fixed-scroll-up';
        }
      }

      // NOTE: case when stickyEl is in fixed position and galleryWrapper gets SMALLER
      // https://notino.tpondemand.com/entity/425474-rozhozeny-scroll-na-novem-pd
      if (
        (scrollStageRef.current === 'fixed-scroll-up' ||
          scrollStageRef.current === 'fixed-scroll-down') &&
        galleryWrapperHeight <= containerHeight
      ) {
        getAssignScrollStageStyles()['sticky-top']();
      }

      if (scrollStageRef.current !== scrollStage) {
        scrollStageRef.current = scrollStage;
        getAssignScrollStageStyles()[scrollStage]();
      }
    };

    const resizeObserver = new ResizeObserver(handle);
    resizeObserver.observe(galleryWrapperEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [getAssignScrollStageStyles, containerRef, galleryWrapperRef]);

  return (
    <Container ref={assignRefs(containerInViewRef, containerRef)}>
      <TopSensor ref={topRef} />

      <Sticky data-testid="sticky-side-bar" ref={stickyElRef}>
        <ChildrenTopSensor ref={childrenTopRef} />

        {children}

        <ChildrenBottomSensor ref={childrenBottomRef} />
      </Sticky>

      <BottomSensor ref={bottomRef} />
    </Container>
  );
};

export const StickySidebar = (props: Props) => {
  const isDesktop = useIsDesktop();

  if (isDesktop)
    return (
      <Component galleryWrapperRef={props.galleryWrapperRef}>
        {props.children}
      </Component>
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>;
};
