import styled from 'styled-components';

// Note: this cannot be <p> because of bug of unknown reason https://notino.tpondemand.com/entity/272849-uncorrect-displaying-of-dulezita-upozorneni-from
export const InstructionsWrapper = styled.div``;

export const VideoWrapper = styled.div`
  position: relative;
  margin-top: 0.75rem;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
export const PdfWrapper = styled.div`
  margin-top: 0.75rem;
`;

export const InstructionDescription = styled.div`
  margin-bottom: 1rem;
`;
