import { snakeCase } from 'lodash';

import { ProductTileModel } from '@notino/react-styleguide';

import { dispatchTrackingEvent } from '@context/tracking/utils';

const tagAttributeToNameMap: Record<
  ProductTileModel.ProductLabelAttributes,
  string
> = {
  Action: 'V akci/růžový štítek s procenty',
  FreeDelivery: 'Doprava zdarma/náklaďák',
  Clearance: 'Výprodej/černý štítek s procentem',
  New: 'Novinka/hvězdička',
  Gift: null,
  Damaged: null, // not tracked
  Returned: null, // not tracked
};

export const trackTagClick = (
  attr: ProductTileModel.ProductLabelAttributes,
  timing: number
) => {
  const name = tagAttributeToNameMap[attr];
  if (!name) {
    return;
  }

  dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      action: 'click_on_element',
      name,
      type: 'variant_tile_label',
      promo_labels: snakeCase(attr),
      interaction: 'click',
      timing,
    },
  });
};
