import * as React from 'react';

import {
  IconRegularChevronLeft,
  IconRegularChevronRight,
  ReactFCC,
} from '@notino/react-styleguide';

import { ArrowWrapper } from './styled';

interface IArrowProps {
  direction: 'left' | 'right';
  arrowMarginFromTop?: string;
  className?: string;
  onClick?: () => void;
}

export const Arrow: ReactFCC<IArrowProps> = ({
  direction,
  onClick,
  className,
  arrowMarginFromTop,
}) => {
  const isLeft = direction === 'left';

  return (
    <ArrowWrapper
      className={className}
      onClick={onClick}
      isLeft={isLeft}
      arrowMarginFromTop={arrowMarginFromTop}
      role="button"
    >
      {isLeft ? (
        <IconRegularChevronLeft
          color="icon.primary"
          width="20px"
          height="20px"
        />
      ) : (
        <IconRegularChevronRight
          color="icon.primary"
          width="20px"
          height="20px"
        />
      )}
    </ArrowWrapper>
  );
};
