import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonModel } from '@notino/react-styleguide';
import { GetItemDataByIdQuery } from '@notino/shared/definitions/types';

import { AddToCartButton } from '@sharedComponents/AddToCartButton/AddToCartButton';
import { Watchdog } from '@sharedComponents/Watchdog';
import { watchdogMessages } from '@sharedComponents/Watchdog/watchdogMessages';
import { noop } from '@utils/noop';

import { ButtonWrapper, StyledButtonWatchdog } from '../styled';

interface IBuyButtonProps {
  buttonElementClassName: string;
  product: GetItemDataByIdQuery['vpProductById'];
  showAddToCartModal: boolean;
  disableRedirect: boolean;
  hasRegisteredWatchdog?: boolean;
  onProductAdded: (
    product: GetItemDataByIdQuery['vpProductById']
  ) => void | Promise<void>;
  onProductAddFailed?: (
    message: string,
    product: GetItemDataByIdQuery['vpProductById']
  ) => void | Promise<void>;
  onClosingCartModal?: () => void;
}

export const BuyButton: React.FC<IBuyButtonProps> = ({
  buttonElementClassName,
  product,
  showAddToCartModal,
  disableRedirect,
  onProductAdded,
  onClosingCartModal,
  onProductAddFailed = noop,
  hasRegisteredWatchdog,
}) => {
  return (
    <>
      {product.canBuy && (
        <AddToCartButton
          buttonElementClassName={buttonElementClassName}
          buttonWrapper={ButtonWrapper}
          buttonStyle={ButtonModel.Styles.secondary}
          buttonSize={ButtonModel.Sizes.xSmall}
          product={{
            ...product,
            variantName: product.annotation,
          }}
          count={1}
          showAddToCartModal={showAddToCartModal}
          wrapable={true}
          disableRedirect={disableRedirect}
          onProductAdded={() => onProductAdded(product)}
          onProductAddFailed={(message) => onProductAddFailed(message, product)}
          onClosingModal={onClosingCartModal}
        />
      )}

      <Watchdog
        hasRegisteredWatchdog={hasRegisteredWatchdog}
        variant={{ ...product, catalogId: String(product.catalogId) }}
        id="productItemWatchdog"
      >
        {({ watchdogActive }) => (
          <StyledButtonWatchdog
            buttonStyle={ButtonModel.Styles.secondary}
            buttonSize={ButtonModel.Sizes.xSmall}
          >
            <FormattedMessage
              {...(watchdogActive
                ? watchdogMessages.watchdogActive
                : watchdogMessages.watchAvailability)}
            />
          </StyledButtonWatchdog>
        )}
      </Watchdog>
    </>
  );
};
