import * as React from 'react';

import { useQuery } from '@apollo/client';

import { GetSectionsAvailabilityQuery } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { Routine } from '@components/Universals/Routine/loadable';
import getSectionsAvailabilityQuery from '@containers/ProductDetailContainer/queries/sectionsAvailability.graphql';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { RelatedProducts } from '../RelatedProducts';
import { Clear } from '../styled';

interface IRoutineOrRelatedProducts {
  productId: string;
  productCode: string;
  relatedProducts: string[];
}

export const RoutineOrRelatedProducts: React.FC<IRoutineOrRelatedProducts> =
  React.memo(({ productId, relatedProducts, productCode }) => {
    const {
      data: sectionAvailability,
      loading,
      error,
    } = useQuery<GetSectionsAvailabilityQuery>(getSectionsAvailabilityQuery, {
      variables: {
        productId,
      },
      ssr: false,
    });

    const { reco_product_detail } = useFeatureFlags();

    const displayRoutine =
      !error &&
      sectionAvailability?.RoutineByProductId?.available &&
      sectionAvailability?.RoutineByProductId?.routine;

    const displayRelatedProducts = relatedProducts?.length > 0;

    if (
      loading ||
      (!displayRoutine && !displayRelatedProducts && !reco_product_detail)
    ) {
      return null;
    }

    return displayRoutine ? (
      <ErrorBoundary
        fallback={() => (
          <RelatedProducts
            currentProductCode={productCode}
            items={relatedProducts}
            shouldRenderProductsFromDatabreakers={reco_product_detail}
          />
        )}
      >
        <Routine productId={productId} />
        <Clear />
      </ErrorBoundary>
    ) : (
      <RelatedProducts
        currentProductCode={productCode}
        items={relatedProducts}
        shouldRenderProductsFromDatabreakers={reco_product_detail}
      />
    );
  });
