import { useIntl } from 'react-intl';

import { ProductTileModel } from '@notino/react-styleguide';

import { messages } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/VoucherDiscount/VoucherDiscountInfo/messages';
import { useApolloClientHeaders } from '@hooks/useApolloClientHeaders';

interface Attributes {
  BlackFridayOfferDiscount?: boolean;
  blackFriday?: boolean;
  christmasOffer?: boolean;
  VoucherDiscount?: {
    productDetailOnly?: boolean;
    theme: string | null;
    tags: string[];
  };
}

export function transformProductIcons(
  attributes: Attributes,
  formatMessage,
  isFranceShop?: boolean
): ProductTileModel.IProductIcon[] {
  return Object.keys(attributes)
    .map((key) => {
      if (key === 'LorealLabel') {
        return { icon: ProductTileModel.SpecialSales.lorealLabel };
      } else if (key === 'christmasOffer') {
        return { icon: ProductTileModel.SpecialSales.christmasOffer };
      } else if (key === 'VoucherDiscount') {
        const voucherDiscount = attributes.VoucherDiscount;

        if (!voucherDiscount) {
          return;
        }

        if (voucherDiscount.tags?.includes('summerblackfriday')) {
          return {
            icon: ProductTileModel.SpecialSales.summerBlackFriday,
            label: formatMessage(messages.sbfLabel),
            title: formatMessage(messages.sbfLabel),
            highlightedWordIndex: isFranceShop ? 2 : 0,
          };
        } else if (voucherDiscount.tags?.includes('blackfriday')) {
          return {
            icon: ProductTileModel.SpecialSales.blackFriday,
            label: formatMessage(messages.blackFridayLabel),
          };
        }
      } else if (key === 'Dermo') {
        return {
          icon: ProductTileModel.SpecialSales.recommendedByDermatologists,
        };
      }
      return null;
    })
    .filter(Boolean);
}

export const useGetProductIcons = () => {
  const { formatMessage } = useIntl();
  const isFranceShop = useApolloClientHeaders().shopId === 'notino.fr';

  return (
    attributes: Record<string, unknown>
  ): ProductTileModel.IProductIcon[] => {
    if (!attributes) return [];

    return transformProductIcons(attributes, formatMessage, isFranceShop);
  };
};
