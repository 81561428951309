import { PropsWithChildren } from 'react';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import styled, { css } from 'styled-components';

import { useArrows } from '@hooks/useArrows';

const Wrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  padding-bottom: 0.5rem;

  //Firefox
  scrollbar-color: #d9d9d9 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    height: 0.25rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: #d9d9d9;
  }
`;

const Fade = styled.div<{ leftFade: boolean; rightFade: boolean }>`
  max-width: 100%;
  width: fit-content;
  position: relative;

  ${({ leftFade }) =>
    leftFade &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 80px;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
      }
    `}

  ${({ rightFade }) =>
    rightFade &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        width: 80px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
      }
    `}
`;

export const FadeWrapper = ({ children }: PropsWithChildren) => {
  const [inViewRef, isInView] = useInView(); // NOTE: fix for when in mobile view, useArrows is not recalculated on tab open
  const { containerRef, showLeftArrow, showRightArrow } = useArrows([isInView]);

  const handleRef = (node: HTMLDivElement) => {
    containerRef.current = node;
    inViewRef(node);
  };

  return (
    <Fade leftFade={showLeftArrow} rightFade={showRightArrow}>
      <Wrapper ref={handleRef}>{children}</Wrapper>
    </Fade>
  );
};
