import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  referenceValue: {
    id: 'pd.composition.referenceValueAbbriviation',
    defaultMessage: '*RHP',
  },
  onePortion: {
    id: 'pd.composition.onePortion',
    defaultMessage: '1 dávka',
  },
  referenceValueOnePercent: {
    id: 'pd.composition.referenceValueOnePercent',
    defaultMessage: '% RHP v 1 dávce',
  },
  dailyAmount: {
    id: 'pd.composition.dailyAmount',
    defaultMessage: 'Denní dávka',
  },
  referenceValuePercent: {
    id: 'pd.composition.referenceValuePercent',
    defaultMessage: '% RHP',
  },
  referenceValueAnnotation: {
    id: 'pd.composition.referenceValueAnnotation',
    defaultMessage: '* RHP – referenční hodnota příjmu',
  },
  vitaminsAndMinerals: {
    id: 'pd.composition.vitaminsAndMinerals',
    defaultMessage: 'Vitamíny a minerální látky:',
  },
  number: {
    id: 'pd.composition.number',
    defaultMessage: 'Počet',
  },
  inDailyDose: {
    id: 'pd.composition.inDailyDose',
    defaultMessage: 'v denní dávce:',
  },
});
