import { defineMessages } from 'react-intl';
export default defineMessages({
  inWishlist: {
    id: 'product.item.in.wishlist',
    defaultMessage: 'Máte v oblíbených',
  },
  addToWishlist: {
    id: 'product.item.add.to.wishlist',
    defaultMessage: 'Přidat do oblíbených',
  },
});
