import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ButtonModel,
  IconSolidInfo,
  InputError,
} from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';

import { messages } from './engravingMessages';
import { IEngravingModalProps } from './EngravingModal';
import {
  StyledButton,
  InputErrorWrapper,
  EngravingImageDrawer,
  H3Drawer,
  DrawerInput,
  ImageMaxTextDrawer,
  ImageTextDrawer,
  EngravingDrawerWrapper,
  EngravingDrawerBody,
} from './styled';
import { useEngravingContent } from './useEngravingContent';
import { resolveEngravingImageAttribute } from './utils';

interface IEngravingDrawerProps extends IEngravingModalProps {
  onDrawerClose: () => void;
}

export const EngravingDrawer: React.FC<IEngravingDrawerProps> = ({
  imageAlt,
  image,
  maxChars,
  code,
  onDrawerClose,
  changeEngravingInitials,
  engravingInitials,
}) => {
  const { handleInitialsChange, handleSave, initials, invalid } =
    useEngravingContent({
      onContentClose: onDrawerClose,
      engravingInitials,
      changeEngravingInitials,
    });

  return (
    <EngravingDrawerWrapper>
      <EngravingImageDrawer>
        {image ? (
          <CdnImage src={image} alt={imageAlt} sourceKey="engraving" />
        ) : (
          <img {...resolveEngravingImageAttribute(code)} alt={imageAlt} />
        )}
      </EngravingImageDrawer>
      <EngravingDrawerBody>
        <H3Drawer>
          <FormattedMessage {...messages.engravingYourText} />
        </H3Drawer>
        <form>
          <DrawerInput
            type="text"
            placeholder="LOVE"
            maxLength={maxChars}
            onChange={handleInitialsChange}
            value={initials}
            invalid={invalid}
          />

          {invalid && (
            <InputErrorWrapper>
              <InputError>
                <FormattedMessage {...messages.engravingInvalidCharacters} />
              </InputError>
            </InputErrorWrapper>
          )}

          <ImageMaxTextDrawer>
            <FormattedMessage
              {...messages.engravingMaxCharactersv2}
              values={{ maxChars }}
            />
          </ImageMaxTextDrawer>
        </form>
        <ImageTextDrawer>
          <IconSolidInfo color="icon.disabled" height="16px" width="16px" />
          <FormattedMessage {...messages.engravingPreview} />
        </ImageTextDrawer>

        <StyledButton
          onClick={handleSave}
          fullWidth={true}
          type="submit"
          buttonStyle={ButtonModel.Styles.primary}
        >
          <FormattedMessage {...messages.addEngraving} />
        </StyledButton>
      </EngravingDrawerBody>
    </EngravingDrawerWrapper>
  );
};
