import { CatalogVariantFragmentFragment as IVariantFragment } from '@notino/shared/definitions/types';

import variantFragment from '@containers/ProductDetailContainer/queries/fragCatalogVariant.graphql';

import { ICallbackParameters } from '../model';

export const updateVariantCache = ({
  wishlisted,
  cache,
  productId,
}: ICallbackParameters & { productId: string | number }) => {
  cache.updateFragment<IVariantFragment>(
    {
      fragment: variantFragment,
      id: `CatalogVariant:${productId}`,
    },
    (prev) => ({
      ...prev,
      wishlisted,
    })
  );
};
