import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { useAddToCartButtonContext } from '@sharedComponents/AddToCartButton/context';

import { useRequestHeaders } from '../hooks/useRequestHeaders';

interface ILegacyAddToCartButtonProps {
  productId: string;
}

function trimEndSlash(str: string) {
  return str.replace(/\/(\s+)?$/, '');
}

export const LegacyAddToCartButton: ReactFCC<ILegacyAddToCartButtonProps> = ({
  children,
  productId,
}) => {
  const { xUserToken, gatewayUrl } = useRequestHeaders();
  const {
    props: { count },
  } = useAddToCartButtonContext();

  return (
    <form
      action={`${trimEndSlash(gatewayUrl)}/add-to-cart`}
      target="_self"
      method="post"
    >
      <input type="hidden" name="productId" value={productId} />
      <input type="hidden" name="count" value={count} />
      <input type="hidden" name="xUserToken" value={xUserToken} />
      {children}
    </form>
  );
};
