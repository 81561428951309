import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { ICampaign } from '@containers/ProductListing/model';

import messages from '../../../messages';
import { useProductDetailContext } from '../../ProductDetailContext';
import { isDiorBrand } from '../../utils';
import { GiftBase } from '../ProductGifts/GiftBase/GiftBase';
import { isInfantFormula } from '../utils/isInfantFormula';

export const Gift = () => {
  const { product, currentVariant } = useProductDetailContext();
  const { giftCampaigns } = getGiftCampaigns({
    product,
    variant: currentVariant,
  });

  if (giftCampaigns.length === 0) {
    return null;
  }

  const titleMessage =
    giftCampaigns.length > 1 ? messages.giftsForYou : messages.giftForYou;

  const shouldShowPickUpInformation =
    currentVariant.availability.availablePickUpStores > 0;

  return (
    <ErrorBoundary>
      <GiftBase
        containerId="pdGift"
        titleMessage={titleMessage}
        imageMessage={messages.giftForYou}
        campaigns={giftCampaigns}
        shouldShowPickUpInformation={shouldShowPickUpInformation}
      />
    </ErrorBoundary>
  );
};

export const getGiftCampaigns = ({
  product: { campaigns, brand },
  variant,
}: {
  product: Pick<
    GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
    'campaigns' | 'brand'
  >;
  variant: Pick<
    GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
    'navigationValues' | 'webId'
  >;
}): {
  giftCampaigns: ICampaign[];
  trackingAttrs: {
    moreGifts: boolean;
    giftAvailable: boolean;
  };
} => {
  const EMPTY = {
    giftCampaigns: [],
    trackingAttrs: {
      giftAvailable: false,
      moreGifts: false,
    },
  };

  if (
    isInfantFormula(variant) ||
    !Array.isArray(campaigns) ||
    campaigns.length === 0
  ) {
    return EMPTY;
  }

  const filteredCampaigns = campaigns.filter(
    (campaign) =>
      campaign.displayOnDetailPage &&
      variant.webId === campaign.productId &&
      (campaign.detailPageText || campaign.text)
  );

  if (filteredCampaigns.length === 0) {
    return EMPTY;
  }

  const giftCampaigns = isDiorBrand(brand?.id)
    ? [filteredCampaigns[0]]
    : filteredCampaigns;

  return {
    giftCampaigns,
    trackingAttrs: {
      moreGifts: giftCampaigns.length > 2,
      giftAvailable: giftCampaigns.length > 0,
    },
  };
};
