import * as React from 'react';

import { IBasicStyledProps } from '@notino/react-styleguide';

import { StyledChristmasIcon } from './styled';
import ChristmasIconSVG from './svg/christmas-bulb.svg';

export const ChristmasIcon: React.FC<IBasicStyledProps> = (props) => (
  <StyledChristmasIcon {...props} src={ChristmasIconSVG} />
);
