import * as React from 'react';

import { findIndex } from 'lodash';

import { ProductSliderPlaceholder } from '@notino/react-styleguide';
import { GetProductsByIdBatchedQuery } from '@notino/shared/definitions/types';

import { ProductSlider } from '@components/ProductSlider';
import { useGetProductItemProps } from '@components/Universals/ProductItem/useGetProductItemProps';
import { IProduct } from '@containers/ProductListing/model';
import {
  useTrackDynamicList,
  ProductEventWither,
} from '@helpers/googleTagManager';

import { BULK_CALL_TIMEOUT } from './constants';
import { Wrapper } from './styled';

export interface IProductSectionContentProps {
  listLabel?: string;
  trackProductClick?: boolean;
  trackProductImpression?: boolean;
  data: GetProductsByIdBatchedQuery;
  itemsPerSlide: number;
  attributionToken?: string;
}

export const ProductsSectionContent: React.FC<IProductSectionContentProps> =
  React.memo(
    ({
      listLabel = '',
      trackProductClick = false,
      trackProductImpression = false,
      data,
      itemsPerSlide,
      attributionToken,
    }) => {
      const trackVisibleItems = useTrackDynamicList({
        delay: BULK_CALL_TIMEOUT,
        listName: listLabel,
      });

      const onNewItemShow = (id: IProduct['id']) => {
        if (trackProductImpression && listLabel) {
          const index = findIndex(data.vpProductByIds, { id });
          if (index !== -1) {
            const product = data.vpProductByIds[index];
            trackVisibleItems(
              ProductEventWither()
                .withVpProduct(product)
                .withAdditionalData({
                  list_position: index + 1,
                  id: product.id,
                })
                .build()
            );
          }
        }
      };

      const getProductItemProps = useGetProductItemProps({
        noPriceIfUnavailable: true,
        useMasterUrl: true,
        inSectionOfType: 'slider',
        GTMEventLabel: listLabel,
        sendGTM: trackProductClick,
        wishlistActionLocation: 'list',
      });

      const products = data.vpProductByIds
        .map((product, index) =>
          getProductItemProps({
            product,
            position: index + 1,
            attributionToken,
          })
        )
        .filter(Boolean);

      return (
        <Wrapper>
          <ProductSlider
            itemsPerSlide={itemsPerSlide}
            onItemShow={({ product }) => {
              onNewItemShow(product.id);
            }}
            products={products}
            fallback={
              <ProductSliderPlaceholder
                placeholderCount={itemsPerSlide}
                rows={4}
              />
            }
          />
        </Wrapper>
      );
    }
  );
