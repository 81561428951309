import * as React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { useMutation } from '@apollo/client';

import { Ratings } from '@notino/react-styleguide';
import {
  VoteType,
  VoteAction,
  GetReviewsQuery,
  HandleVoteMutation,
  HandleVoteMutationVariables,
} from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { PortalTooltip } from '@components/PortalTooltip';
import { RequestState } from '@containers/App/model';
import { useApolloClientHeaders } from '@hooks/useApolloClientHeaders';

import messages from '../../../../../messages';
import handleVoteMutationQuery from '../../queries/handleVote.graphql';
import { canBeVoted } from '../utils';

import { ReviewMotivation } from './ReviewMotivation';
import {
  WasItUsefulWrapper,
  WasItUseful,
  Separator,
  Votes,
  ReviewDetails,
  ReviewItem,
  ReviewDate,
  Name,
  ReviewSource,
  ReviewTooltipContent,
  RatingWrapper,
} from './styled';
import { Vote } from './Vote';

export interface ISingleReviewProps {
  item: GetReviewsQuery['reviews'][number];
  index: number;
}

export const SingleReviewData: React.FC<ISingleReviewProps> = ({
  item,
  index,
}) => {
  const { formatMessage } = useIntl();
  const { shopId } = useApolloClientHeaders();

  const [
    { [VoteType.Like]: likeLoading, [VoteType.Dislike]: dislikeLoading },
    setState,
  ] = React.useState({
    [VoteType.Like]: RequestState.DEFAULT,
    [VoteType.Dislike]: RequestState.DEFAULT,
  });

  const [handleVoteMutation] = useMutation<
    HandleVoteMutation,
    HandleVoteMutationVariables
  >(handleVoteMutationQuery);

  const handleVote = async (type: VoteType) => {
    const unvote =
      (item.alreadyLiked && type === VoteType.Like) ||
      (item.alreadyDisliked && type === VoteType.Dislike);

    const otherType = type === VoteType.Like ? VoteType.Dislike : VoteType.Like;

    setState((old) => ({
      ...old,
      [type]: RequestState.IN_PROGRESS,
      [otherType]: RequestState.DEFAULT,
    }));

    try {
      await handleVoteMutation({
        variables: {
          id: item.id.toString(),
          type,
          action: unvote ? VoteAction.Remove : VoteAction.Add,
        },
      });

      setState((old) => ({
        ...old,
        [type]: RequestState.DONE,
      }));
    } catch (e) {
      setState((old) => ({
        ...old,
        [type]: RequestState.FAILED,
      }));
    }
  };

  const like = async () => {
    if (
      canBeVoted(item, VoteType.Like) &&
      likeLoading !== RequestState.IN_PROGRESS
    ) {
      await handleVote(VoteType.Like);
    }
  };

  const dislike = async () => {
    if (
      canBeVoted(item, VoteType.Dislike) &&
      dislikeLoading !== RequestState.IN_PROGRESS
    ) {
      await handleVote(VoteType.Dislike);
    }
  };

  const getUserNameOrCommentInfo = () => {
    if (item.isAnonymized) {
      const message =
        messages[
          item.originalShopId ? 'anonymizedSharedComment' : 'anonymizedComment'
        ];

      return (
        <FormattedMessage
          {...message}
          values={{
            originalShopId: item.originalShopId ?? shopId,
          }}
        />
      );
    }

    if (item.originalShopId) {
      return (
        <FormattedMessage
          {...messages.sharedComment}
          values={{
            userName: item.userName,
            originalShopId: item.originalShopId,
          }}
        />
      );
    }

    return item.userName;
  };

  return (
    <ReviewItem>
      {item.text}
      <ReviewDetails>
        <RatingWrapper>
          <Ratings
            ratingId={`reviewItem${index}`}
            size={14}
            rating={item.score}
            background="background.disabled"
            foreground="text.highlight"
          />
        </RatingWrapper>

        <Name data-testid="user-name-or-comment-info">
          {getUserNameOrCommentInfo()}
        </Name>

        {item.translatedFrom && (
          <PortalTooltip
            backgroundColor="inverse"
            content={
              <PortalTooltip.Content>
                <ReviewTooltipContent>
                  {formatMessage(messages.automaticlyTranslatedReview, {
                    shopId: item.translatedFrom,
                  })}
                </ReviewTooltipContent>
              </PortalTooltip.Content>
            }
          >
            <ReviewSource>
              <FormattedMessage
                {...messages.reviewSource}
                values={{ shopId: item.translatedFrom }}
              />
            </ReviewSource>
          </PortalTooltip>
        )}

        <ReviewDate>
          <FormattedDate
            value={item.createdDate}
            day="numeric"
            month="long"
            year="numeric"
          />
        </ReviewDate>

        <WasItUsefulWrapper>
          <WasItUseful id={`was-review-useful-${item.id}`}>
            <FormattedMessage {...messages.reviewWasItUsefull} />
          </WasItUseful>

          <Votes data-cypress="reviews-voting">
            <Vote
              describedBy={`was-review-useful-${item.id}`}
              message={messages.reviewUsefullYes}
              onClick={like}
              state={likeLoading}
              voted={item.alreadyLiked}
            >
              {item.like}
            </Vote>

            <Separator>|</Separator>

            <Vote
              describedBy={`was-review-useful-${item.id}`}
              message={messages.reviewUsefullNo}
              onClick={dislike}
              state={dislikeLoading}
              voted={item.alreadyDisliked}
            >
              {item.dislike}
            </Vote>
          </Votes>
        </WasItUsefulWrapper>

        <ErrorBoundary>
          <ReviewMotivation authorType={item.authorType} />
        </ErrorBoundary>
      </ReviewDetails>
    </ReviewItem>
  );
};
