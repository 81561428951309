export interface IColorVariant {
  colors: string[];
  webId: string | number;
}

export interface IModiFaceVariant {
  [key: string]: unknown;
}

export interface IModifaceEffectVariants {
  [key: string]: IModiFaceVariant[];
}

export enum EMessage {
  allowWebcam = 'allowWebcam',
  webcamError = 'webcamError',
  genericError = 'genericError',
  faceNotDetected = 'faceNotDetected',
  fileSizeTooBig = 'fileSizeTooBig',
  unsupportedFormat = 'unsupportedFormat',
  photoDimensionsTooSmall = 'photoDimensionsTooSmall',
  noMessage = '',
}

export enum ModeType {
  NONE,
  CAMERA,
  PHOTO,
}
