const shim = (cb) => {
  return setTimeout(function () {
    const start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
};

const isSupported = typeof requestIdleCallback !== 'undefined';

// very optimistic shim
const requestIdleCallbackShim = isSupported ? requestIdleCallback : shim;
const cancelIdleCallbackShim = isSupported ? cancelIdleCallback : clearTimeout;

export {
  requestIdleCallbackShim as requestIdleCallback,
  cancelIdleCallbackShim as cancelIdleCallback,
};
