import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const DescriptionWrapper = styled.div`
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  ${theme.typography.bodyRegular}
  color:${theme.color.text.secondary};
  margin-top: -0.75rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 1.5rem 1.25rem 1.25rem;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

export const ModalOrDrawerButtonWrapper = styled.div`
  & > button,
  form button {
    height: 2.75rem;
    padding: 0 0.5625rem;
    text-transform: none;
    font-weight: normal;
  }
  width: 100%;
  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;
