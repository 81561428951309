import * as React from 'react';

import { useMutation, useQuery } from '@apollo/client';

import {
  AddToClickAndCollectMutation,
  AddToClickAndCollectMutationVariables,
  GetStoresQuery,
} from '@notino/shared/definitions/types';

import { useSettings } from '@containers/ProductDetailContainer/hooks/useSettings';

import addToClickAndCollectMutation from './mutations/addToClickAndCollect.graphql';
import getStoresQuery from './queries/stores.graphql';
import { redirectToClickAndCollectCart } from './utils';

export const useClickAndCollect = (variantId: number) => {
  const { Settings: { isNewShoppingCartEndpointEnabled: isNewEndpoint } = {} } =
    useSettings();

  const { data: stores, loading: storesLoading } = useQuery<GetStoresQuery>(
    getStoresQuery,
    {
      variables: { productId: variantId },
    }
  );

  const [addToClickAndCollectCart, addToClickAndCollectData] = useMutation<
    AddToClickAndCollectMutation,
    AddToClickAndCollectMutationVariables
  >(addToClickAndCollectMutation, {
    onCompleted: (response) => {
      redirectToClickAndCollectCart(
        response.addProductToClickAndCollect.cartId,
        isNewEndpoint
      );
    },
  });

  const handleAddToClickAndCollect = React.useCallback(
    async (warehouseCode: string) => {
      await addToClickAndCollectCart({
        variables: {
          productId: variantId,
          warehouseCode: warehouseCode,
        },
      });
    },
    [addToClickAndCollectCart, variantId]
  );

  return React.useMemo(
    () => ({
      stores,
      storesLoading,
      handleAddToClickAndCollect,
      addToClickAndCollectData,
    }),
    [
      stores,
      storesLoading,
      handleAddToClickAndCollect,
      addToClickAndCollectData,
    ]
  );
};
