import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackModalClose = (timing: number) => {
  dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      timing,
      interaction: 'click',
      name: 'discovery_box_pdp_close',
      type: 'discovery_box_pdp',
      action: 'discovery_box_pdp_close',
    },
  });
};
