import { useEffect, useRef } from 'react';

export const useScrollDirectionRef = () => {
  const scrollDir = useRef<'up' | 'down' | null>(null);

  useEffect(() => {
    let lastScrollPosition = window.pageYOffset;

    const handleScrollDir = () => {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > lastScrollPosition) {
        scrollDir.current = 'down';
      } else if (currentScrollPosition < lastScrollPosition) {
        scrollDir.current = 'up';
      } else {
        scrollDir.current = null;
      }
      lastScrollPosition = currentScrollPosition;
    };

    document.addEventListener('scroll', handleScrollDir);
    return () => {
      document.removeEventListener('scroll', handleScrollDir);
    };
  }, []);

  return scrollDir;
};
