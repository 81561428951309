import * as React from 'react';

export const DRAWER_CLOSE_BUTTON_AREA_CLASS = 'drawer-close-button-area';

export type DrawerCloseType = 'swipe' | 'backdrop' | 'button' | 'automatic';

export const useCloseType = (open: boolean) => {
  const lastHeaderSwipe = React.useRef<{
    swipeStartPx: number;
    swipeEndPx: number;
    swipeEndTime: number;
  }>({ swipeStartPx: 0, swipeEndPx: 0, swipeEndTime: 0 });

  const backdropClickTime = React.useRef(0);
  const closeButtonClickTime = React.useRef(0);

  React.useEffect(() => {
    if (!open) {
      return;
    }

    const pointerDown = (e: PointerEvent) => {
      lastHeaderSwipe.current = {
        swipeStartPx: e.clientY,
        swipeEndTime: 0,
        swipeEndPx: 0,
      };
    };
    const pointerUp = (e: PointerEvent) => {
      lastHeaderSwipe.current.swipeEndPx = e.clientY;
      lastHeaderSwipe.current.swipeEndTime = new Date().getTime();
    };
    const backdropClick = () => {
      backdropClickTime.current = new Date().getTime();
    };
    const buttonClick = () => {
      closeButtonClickTime.current = new Date().getTime();
    };

    let header: HTMLDivElement = null;
    let backdrop: HTMLDivElement = null;
    let closeButton: HTMLDivElement = null;

    setTimeout(() => {
      header = document.querySelector('[data-rsbs-header]');
      header?.addEventListener('pointerdown', pointerDown);
      header?.addEventListener('pointerup', pointerUp);

      backdrop = document.querySelector('[data-rsbs-backdrop]');
      backdrop?.addEventListener('pointerup', backdropClick);

      closeButton = document.querySelector(
        `.${DRAWER_CLOSE_BUTTON_AREA_CLASS}`
      );
      closeButton?.addEventListener('pointerup', buttonClick);
    });

    return () => {
      header?.removeEventListener('pointerdown', pointerDown);
      header?.removeEventListener('pointerup', pointerUp);
      backdrop?.removeEventListener('pointerup', backdropClick);
      closeButton?.removeEventListener('pointerup', buttonClick);
    };
  }, [open]);

  const getCloseType = (): DrawerCloseType => {
    const now = new Date().getTime();
    const { swipeStartPx, swipeEndPx, swipeEndTime } = lastHeaderSwipe.current;
    const swipeDistance = swipeEndPx - swipeStartPx;

    if (swipeDistance > 10 && now - swipeEndTime < 100) {
      return 'swipe';
    }
    if (now - backdropClickTime.current < 100) {
      return 'backdrop';
    }
    if (now - closeButtonClickTime.current < 100) {
      return 'button';
    }
    return 'automatic';
  };

  return { getCloseType };
};
