import styled from 'styled-components';

import { ProductSliderPlaceholder } from '@notino/react-styleguide';

export const StyledListPlaceholder = styled(ProductSliderPlaceholder)<{
  containerProps: Record<string, string>;
}>`
  overflow-x: auto;
  margin: 1.5rem 0 4rem;
`;
