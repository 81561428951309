import * as React from 'react';

import { StyledRectangle } from './styled';

export interface IRectangleProps {
  size?: number | string;
  widthCss?: number | string;
  heightCss?: number | string;
}

const RectanglePlaceholder: React.FC<IRectangleProps> = (props) => (
  <StyledRectangle {...props} />
);

export default RectanglePlaceholder;
