import { dispatchTrackingEvent } from '@context/tracking/utils';
import { isDesktop } from '@utils/helpers';

import { EPictograms } from './model';

export const trackPictograms = (
  timing: number,
  pictogramTitle: EPictograms
) => {
  return dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      timing,
      interaction: isDesktop() ? 'hover' : 'click',
      type: 'pictogram',
      name: pictogramTitle,
      action: isDesktop() ? 'hover_on_pictogram' : 'click_on_pictogram',
    },
  });
};

export const trackOnClickMoreInfoTooltip = (
  timing: number,
  pictogramTitle: EPictograms
) => {
  return dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      timing,
      interaction: 'click',
      type: 'tooltip',
      name: `pictogram_${pictogramTitle}`,
      action: 'click_on_element',
    },
  });
};
