import {
  CatalogVariantFragmentFragment,
  StrictSalesDisplayTypeFeatureFlag,
} from '@notino/shared/definitions/types';

import { IPrice } from '@containers/ProductListing/model';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { useResolvedVoucherDiscount } from '../../ProductBaseInfo/VoucherDiscount/useResolvedVoucherDiscount';

import {
  useGetBetterOfferText,
  useGetOriginalPriceTooltip,
  useGetYouSaveText,
  useRecentPriceTooltip,
} from './utils';

// https://gitlab.notino.com/web/nushopweb/-/blob/master/docs/diagrams/price-logic.md
interface IReturnValue {
  discountText?: string;
  topPrice?: {
    value: IPrice;
    isCrossed: boolean;
    tooltip: JSX.Element;
  };
  bottomRecentPrice?: { value: IPrice; tooltip: JSX.Element };

  /** Indicates whether converted/recommended/maxHistorical price is shown. */
  rrpShown: boolean;
  minimalPriceShown: boolean;
}

export type UsePriceLogicParameters = Pick<
  CatalogVariantFragmentFragment,
  'attributes' | 'price' | 'originalPrice' | 'recentMinPrice'
>;

export const usePriceLogic = ({
  attributes,
  price,
  originalPrice,
  recentMinPrice,
}: UsePriceLogicParameters): IReturnValue => {
  const getYouSaveText = useGetYouSaveText();
  const getBetterOfferText = useGetBetterOfferText();
  const recentPriceTooltip = useRecentPriceTooltip();
  const getOriginalPriceTooltip = useGetOriginalPriceTooltip();
  const { strict_sales_display_type } = useFeatureFlags();

  const isActionOrClearance: boolean =
    attributes?.Action || attributes?.Clearance;

  const hasVoucher = !!useResolvedVoucherDiscount({ attributes });

  if (isActionOrClearance) {
    if (recentMinPrice?.isActionEligible) {
      return {
        minimalPriceShown: true,
        rrpShown: false,
        discountText: getYouSaveText(price.value, recentMinPrice.value),
        topPrice: {
          value: recentMinPrice,
          isCrossed: true,
          tooltip: recentPriceTooltip,
        },
      };
    }

    if (recentMinPrice) {
      return {
        rrpShown: false,
        minimalPriceShown: true,
        bottomRecentPrice: {
          value: recentMinPrice,
          tooltip: recentPriceTooltip,
        },
      };
    }

    return {
      rrpShown: false,
      minimalPriceShown: false,
    };
  }

  if (hasVoucher) {
    if (
      strict_sales_display_type ===
      StrictSalesDisplayTypeFeatureFlag.ShowBottomPriceWhenVoucher
    ) {
      return recentMinPrice
        ? {
            minimalPriceShown: true,
            rrpShown: false,
            bottomRecentPrice: {
              value: recentMinPrice,
              tooltip: recentPriceTooltip,
            },
          }
        : { minimalPriceShown: false, rrpShown: false };
    }

    if (
      strict_sales_display_type ===
      StrictSalesDisplayTypeFeatureFlag.ShowBottomPriceAndTopPriceWhenVoucher
    ) {
      if (recentMinPrice) {
        return originalPrice?.value
          ? {
              minimalPriceShown: true,
              rrpShown: true,
              bottomRecentPrice: {
                value: recentMinPrice,
                tooltip: recentPriceTooltip,
              },
              topPrice: {
                value: originalPrice,
                isCrossed: false,
                tooltip: getOriginalPriceTooltip(originalPrice.type),
              },
              discountText: getBetterOfferText(
                price.value,
                originalPrice.value
              ),
            }
          : {
              minimalPriceShown: true,
              rrpShown: false,
              bottomRecentPrice: {
                value: recentMinPrice,
                tooltip: recentPriceTooltip,
              },
            };
      }

      return originalPrice?.value
        ? {
            minimalPriceShown: false,
            rrpShown: true,
            topPrice: {
              value: originalPrice,
              isCrossed: false,
              tooltip: getOriginalPriceTooltip(originalPrice.type),
            },
            discountText: getBetterOfferText(price.value, originalPrice.value),
          }
        : {
            minimalPriceShown: false,
            rrpShown: false,
          };
    }
  }

  return originalPrice?.value
    ? {
        rrpShown: true,
        minimalPriceShown: false,
        topPrice: {
          value: originalPrice,
          isCrossed: false,
          tooltip: getOriginalPriceTooltip(originalPrice.type),
        },
        discountText: getBetterOfferText(price.value, originalPrice.value),
      }
    : { minimalPriceShown: false, rrpShown: false };
};
