import { Validator } from '@containers/App/model';

import { IRegexValidatorFactory, IValidation } from './model';

const composeValidators = (validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required: Validator = (value) => (value ? undefined : 'Required');

const maxLengthFactory = (maxLength: number, error = 'MaxLength') => {
  return (value) => {
    return value?.length > maxLength ? error : undefined;
  };
};

const regexFactory: IRegexValidatorFactory = (
  pattern,
  modifier,
  error = 'Invalid'
) => {
  const regex = new RegExp(pattern, modifier);
  return (value) => {
    const testValue = value ? value : '';
    return regex.test(testValue) ? undefined : error;
  };
};

const mapValidator = (validation: IValidation): Validator => {
  const validators = [];
  if (validation.required) {
    validators.push(required);
  }
  if (validation.maxLength) {
    validators.push(maxLengthFactory(validation.maxLength));
  }
  if (validation.regex) {
    validators.push(
      regexFactory(
        validation.regex.pattern,
        validation.regex.modifier,
        validation.regex.errMsg
      )
    );
  }
  if (validators.length === 1) {
    return validators[0];
  }
  return composeValidators(validators);
};

export const validatorFactory = <T extends Record<string, IValidation>>(
  validations: T
): Record<keyof T, Validator> =>
  Object.keys(validations).reduce((result, key) => {
    result[key as keyof T] = mapValidator(validations[key]);
    return result;
  }, {} as Record<keyof T, Validator>);
