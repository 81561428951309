import { DependencyList, useEffect, useRef } from 'react';

interface InViewOptions {
  threshold?: number | number[];
  rootMargin?: string;
  initialInView?: boolean;
  deps?: DependencyList;
}

export const useInViewRef = (
  options: InViewOptions = {}
): [
  elementRef: React.RefObject<HTMLDivElement>,
  inView: React.MutableRefObject<boolean>
] => {
  const { initialInView = false, deps = [] } = options;

  const inView = useRef(initialInView);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      inView.current = entry.isIntersecting;
    });
    observer.disconnect();

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [ref, inView];
};
