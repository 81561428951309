import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFormatPrice } from '@components/PriceLabel/useFormattedPrice';

import { IConditionalVoucherDiscount } from '../ConditionalVoucherDiscount';
import { useVoucherDiscountContext } from '../context';

import { messages } from './messages';
import { TierDiscountHighlight, TierRow } from './styled';

type Props = {
  discount: IConditionalVoucherDiscount;
};

export const Tiers = ({ discount }: Props) => {
  const { hasContrastBg } = useVoucherDiscountContext();

  return (
    <div>
      {discount.discountConditions.map((condition, i) => (
        <TierRow key={i} hasContrastBg={hasContrastBg}>
          <Condition
            condition={condition}
            discountType={discount.discountType}
            conditionType={discount.conditionType}
          />
        </TierRow>
      ))}
    </div>
  );
};

const Condition = ({
  condition,
  conditionType,
  discountType,
}: {
  condition: IConditionalVoucherDiscount['discountConditions'][number];
  discountType: IConditionalVoucherDiscount['discountType'];
  conditionType: IConditionalVoucherDiscount['conditionType'];
}) => {
  const formatPrice = useFormatPrice();
  const { hasContrastBg } = useVoucherDiscountContext();

  const commonValues = {
    b: (chunks) => (
      <TierDiscountHighlight hasContrastBg={hasContrastBg}>
        {chunks}
      </TierDiscountHighlight>
    ),
  };

  if (discountType === 'Percentage') {
    return conditionType === 'Price' ? (
      <FormattedMessage
        {...messages.percentageDiscountFromPrice}
        values={{
          percentage: condition.percentageDiscount,
          price: formatPrice({ value: condition.conditionMin })
            .formattedPriceWithCurrency,
          ...commonValues,
        }}
      />
    ) : (
      <FormattedMessage
        {...messages.percentageDiscountFromPieces}
        values={{
          percentage: condition.percentageDiscount,
          pieces: condition.conditionMin,
          ...commonValues,
        }}
      />
    );
  }

  return conditionType === 'Price' ? (
    <FormattedMessage
      {...messages.absoluteDiscountFromPrice}
      values={{
        priceAmount: formatPrice({ value: condition.discountAmount })
          .formattedPriceWithCurrency,
        price: formatPrice({ value: condition.conditionMin })
          .formattedPriceWithCurrency,
        ...commonValues,
      }}
    />
  ) : (
    <FormattedMessage
      {...messages.absoluteDiscountFromPieces}
      values={{
        priceAmount: formatPrice({ value: condition.discountAmount })
          .formattedPriceWithCurrency,
        pieces: condition.conditionMin,
        ...commonValues,
      }}
    />
  );
};
