import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { useQuery, useApolloClient } from '@apollo/client';
import NodeCache from 'node-cache';

import { ReactFCC } from '@notino/react-styleguide';
import {
  GetLocalesDataQuery,
  GetLocalesDataQueryVariables,
} from '@notino/shared/definitions/types';
import { type Logger } from '@notino/shared/logging';

import { useApolloClientHeaders } from '@hooks/useApolloClientHeaders';
import * as Sentry from '@sentry/browser';

import { IntlProviderOverride } from './IntlProviderOverride';
import getLocalesQuery from './queries/localesData.graphql';
import { usePrefixes } from './usePrefixes';

interface ILanguageProviderProps {
  originUrl: string;
  cache?: NodeCache;
  rendererLogger?: Logger;
}

const LOCALES_CACHE_TIME_SECONDS = 600;

export const LanguageProvider: ReactFCC<ILanguageProviderProps> = ({
  children,
  originUrl,
  cache,
  rendererLogger,
}) => {
  const prefixes = usePrefixes(originUrl);
  const { shopId } = useApolloClientHeaders();
  const apollo = useApolloClient();

  const cacheKey = `localesQuery:${shopId}:${prefixes?.join('_')}`;
  const cachedData = cache?.get<GetLocalesDataQuery>(cacheKey);

  const queryVariables: GetLocalesDataQueryVariables = { prefixes };

  if (cachedData) {
    apollo.writeQuery({
      query: getLocalesQuery,
      variables: queryVariables,
      data: cachedData,
    });
  }

  const { data, error, previousData } = useQuery<
    GetLocalesDataQuery,
    GetLocalesDataQueryVariables
  >(getLocalesQuery, {
    variables: queryVariables,
    skip: prefixes === null,
    fetchPolicy: 'cache-first',
  });

  const languageData = data || previousData;

  if (!languageData?.languagedMessagesByPrefixes || error) {
    return null;
  }

  if (!cachedData) {
    try {
      cache?.set(cacheKey, languageData, LOCALES_CACHE_TIME_SECONDS);
    } catch (err) {
      rendererLogger?.warn('Locales cache max keys limit was reached.');
    }
  }

  const locale = languageData?.languagedMessagesByPrefixes?.acceptLanguage;
  const messages = languageData?.languagedMessagesByPrefixes?.messages;

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages}
      onError={Sentry.captureException}
    >
      <IntlProviderOverride>
        {React.Children.only(children)}
      </IntlProviderOverride>
    </IntlProvider>
  );
};
