import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { IStyledVariantProps } from '../../../../model';
import { VariantCommon } from '../../variantsStyled';

type VariantProps = IStyledVariantProps & {
  isDualPriceForCroatia: boolean;
};

const getNewBorderStyleDesign = (isSelected: boolean, isOnStock: boolean) => {
  const borderWidth = isSelected ? '2px' : '1px';
  const borderColor = isSelected
    ? theme.color.border.selected
    : isOnStock
    ? theme.color.border.divider
    : theme.color.common.gray90;

  return `${borderWidth} solid ${borderColor}`;
};

export const Variant = styled(VariantCommon)<VariantProps>`
  ${({ isSelected, isOnStock, isDualPriceForCroatia }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: ${isOnStock && isDualPriceForCroatia
        ? 'center'
        : 'space-between'};
      margin: 0 0 0.25rem;
      border: ${getNewBorderStyleDesign(isSelected, isOnStock)};
      height: 3.5rem;
      width: 6.5rem;
      padding: 0.625rem 0.5rem;
      @media (min-width: ${breakpoints.lg}) {
        width: auto;
        margin: 0;
      }
    `}
`;

export const PriceLabelWrapper = styled.div`
  ${theme.typography.labelSmall400}
  color:${theme.color.text.secondary}
`;

export const Unavailable = styled.p`
  ${theme.typography.labelSmall400}
  color:${theme.color.text.tertiary}
`;
