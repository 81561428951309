import * as React from 'react';
import { PropsWithChildren } from 'react';

import { GetRequestHeadersQuery } from '@notino/shared/definitions/types';

export type FeatureFlags = Omit<
  GetRequestHeadersQuery['RequestHeaders']['featureFlags'],
  '__typename'
>;
const StatsigContext = React.createContext<FeatureFlags>(null);

export const FeatureFlagsProvider = ({
  children,
  featureFlags,
}: PropsWithChildren<{ featureFlags: FeatureFlags }>) => (
  <StatsigContext.Provider value={featureFlags}>
    {children}
  </StatsigContext.Provider>
);

export const useFeatureFlags = (): FeatureFlags => {
  const ctx = React.useContext(StatsigContext);
  if (!ctx) {
    throw new Error(
      'useFeatureFlags must be used inside FeatureFlagsProvider.'
    );
  }
  return ctx;
};
