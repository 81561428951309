import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoggedInUserReviewQuery } from '@notino/shared/definitions/types';

import { IReviewSummary } from '@containers/ProductDetailContainer/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';

import messages from '../../../../messages';

import { AttributesSummary } from './AttributesSummary';
import { ReviewHeader } from './ReviewHeader';
import { useReviewModalOrDrawer } from './ReviewModal/useReviewModalOrDrawer';
import { StarsSummary } from './StarsSummary';
import { ShowLink, StyledRow, StyledCol } from './styled';

interface IReviewsSummaryProps {
  reviewSummary: IReviewSummary;
  productCode: string;
  isAllReviewsPage?: boolean;
  masterId: string;
  catalogMasterId: string;
  loggedInUserReview?: LoggedInUserReviewQuery['loggedInUserReview'];
  refetchLoggedInUserReview: () => Promise<void>;
}

export const ReviewsSummary: React.FC<IReviewsSummaryProps> = React.memo(
  ({
    reviewSummary,
    productCode,
    masterId,
    catalogMasterId,
    isAllReviewsPage,
    loggedInUserReview,
    refetchLoggedInUserReview,
  }) => {
    const { getTimeFromInit } = useTrackingContext();

    const [showAttributesReviews, toggleAttributesReviews] = React.useReducer(
      (prev) => !prev,
      false
    );

    const openReviewModalOrDrawer = useReviewModalOrDrawer({
      ratings: reviewSummary.ratings,
      productCode,
      masterId,
      catalogMasterId,
      isAllReviewsPage,
      loggedInUserReview,
      refetchLoggedInUserReview,
    });

    const handleAddNewReview = () => {
      dispatchTrackingEvent({
        event: 'element_click',
        element: {
          action: 'wt_add_review',
          interaction: 'click',
          name: 'review',
          type: isAllReviewsPage ? 'review' : 'product_detail',
          mode: 'open',
          promo_labels: undefined,
          timing: getTimeFromInit(),
        },
        _clear: true,
      });

      openReviewModalOrDrawer();
    };

    if (!reviewSummary) {
      return null;
    }

    const hasReviews = reviewSummary.totalVotes > 0;

    return (
      <StyledRow>
        <ReviewHeader
          hasReviews={hasReviews}
          reviewSummary={reviewSummary}
          onWriteMessageClick={handleAddNewReview}
          isAllReviewsPage={isAllReviewsPage}
          userHasWrittenReview={!!loggedInUserReview}
        />
        {hasReviews && (
          <>
            <StarsSummary reviewSummary={reviewSummary} />
            <StyledCol md={false}>
              <ShowLink onClick={toggleAttributesReviews}>
                {showAttributesReviews ? (
                  <FormattedMessage {...messages.hideParametersReviews} />
                ) : (
                  <FormattedMessage {...messages.showParametersReviews} />
                )}
              </ShowLink>
            </StyledCol>
            <AttributesSummary
              showAttributesReviews={showAttributesReviews}
              ratings={reviewSummary.ratings}
            />
          </>
        )}
      </StyledRow>
    );
  }
);
