import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonModel, ModalContext } from '@notino/react-styleguide';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';

import { messages } from './messages';
import { StyledBag, StyledButton, Subtitle, Title, Wrapper } from './styled';

export const MaxProductsInCartModal = () => {
  const { hideModal } = ModalContext.useModalContext();
  const { hideDrawer } = useDrawer();

  return (
    <Wrapper data-testid="maxProductsInCartModal">
      <StyledBag />

      <Title>
        <FormattedMessage {...messages.maxProductsErrorHeader} />
      </Title>

      <Subtitle>
        <FormattedMessage {...messages.maxProductsErrorAdvice} />
      </Subtitle>

      <StyledButton
        buttonStyle={ButtonModel.Styles.primary}
        onClick={() => {
          hideDrawer();
          hideModal();
        }}
      >
        <FormattedMessage {...messages.ok} />
      </StyledButton>
    </Wrapper>
  );
};
