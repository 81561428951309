import { MutationFunctionOptions } from '@apollo/client';
import { ExecutionResult } from 'graphql';

import {
  AddProductsToCartMutationVariables,
  AddProductsToCartMutation,
} from '@notino/shared/definitions/types';

import {
  IAddToCartButtonProps,
  ProductWithCount,
} from '@sharedComponents/AddToCartButton/model';

export const addToCart = async ({
  addToCartMutation,
  products,
  services,
}: AddToCartParams): Promise<AddToCartReturn> => {
  const variables: AddProductsToCartMutationVariables = {
    products: products.map((product) => ({
      count: product.count,
      productId: Number(product.id),
    })),
    services: services && services.length > 0 ? services : [],
  };

  try {
    const response = await addToCartMutation({ variables });
    if (response?.data?.AddProductsToCart) {
      return {
        response: response.data.AddProductsToCart,
        success: true,
        error: undefined,
      };
    }
    return {
      success: false,
      error: response.errors[0]?.message,
    };
  } catch (err) {
    return {
      success: false,
      error: JSON.stringify(err),
    };
  }
};

type AddToCartParams = {
  products: ProductWithCount[];
  services: IAddToCartButtonProps['services'];
  addToCartMutation: (
    options?: MutationFunctionOptions<
      AddProductsToCartMutation,
      AddProductsToCartMutationVariables
    >
  ) => Promise<ExecutionResult<AddProductsToCartMutation>>;
};

type AddToCartReturn =
  | {
      error: undefined;
      success: true;
      response: AddProductsToCartMutation['AddProductsToCart'];
    }
  | {
      success: false;
      error: string;
    };
