import { useProductDetailContext } from '../../ProductDetailContext';

import { IConditionalVoucherDiscount } from './ConditionalVoucherDiscount';
import { IVoucherDiscount } from './VoucherDiscount';

export const useResolvedVoucherDiscount = ({
  attributes: _attributes,
}: {
  attributes?: unknown;
} = {}): IVoucherDiscount | IConditionalVoucherDiscount | null => {
  const { currentVariant } = useProductDetailContext();

  const attributes = _attributes ?? currentVariant.attributes;

  if (attributes.VoucherDiscount) {
    return attributes.VoucherDiscount;
  }

  const conditionalVoucherDiscount = attributes.ConditionalVoucherDiscount as
    | IConditionalVoucherDiscount
    | undefined;

  if (conditionalVoucherDiscount?.discountConditions.length) {
    return conditionalVoucherDiscount;
  }

  return null;
};
