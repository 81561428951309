import * as React from 'react';

import { IVariantsProps } from '../../../../model';
import { Container } from '../../variantsStyled';
import { useVariantTransition } from '../useVariantTransition';

import { isPriceSame } from './isPriceSame';
import { VariantList } from './styled';
import { VariantThumbnail } from './VariantThumbnail';

export const VariantsThumbnail: React.FC<IVariantsProps> = ({
  locale,
  selectedId: _selectedId,
  variants,
  currency,
}) => {
  const samePrice = isPriceSame(variants);
  const variantsCount = variants.length;

  const { handleClick, selectedId } = useVariantTransition(_selectedId);

  return (
    <Container data-testid="pd-variants-thumbnail" id="pdVariantsThumbnail">
      <VariantList itemsCount={variantsCount}>
        {variants.map((item) => (
          <VariantThumbnail
            variantsCount={variantsCount}
            item={item}
            locale={locale}
            unit={item.parameters?.unit}
            isSelected={item.webId === selectedId}
            currency={currency}
            key={item.webId}
            displayPrice={!samePrice}
            onClick={handleClick}
          />
        ))}
      </VariantList>
    </Container>
  );
};
