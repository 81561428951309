import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const Margin = styled.div`
  margin-bottom: -2rem;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: -1rem;
    margin-bottom: 4rem;
  }
`;
