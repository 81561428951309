import * as React from 'react';

import { withConsumers } from '../../context/contextConfig';

import {
  TabSwitcherContext,
  ITabSwitcherProviderState,
} from './context/TabSwitcherContext';
import { ITabLinkProps } from './model';
import { LinkWrapper } from './styled';

const TabLinkComponent: React.FC<ITabLinkProps & ITabSwitcherProviderState> = ({
  toggleActiveTab,
  tabId,
  activeTabId,
  children,
}) => {
  const setActiveTabHandler = () => {
    toggleActiveTab(tabId, true);
  };

  const isActive = activeTabId === tabId;

  /* eslint-disable react/jsx-no-useless-fragment */
  return typeof children === 'function' ? (
    <>
      {children({
        setActiveTab: setActiveTabHandler,
        isActive,
      })}
    </>
  ) : (
    <LinkWrapper onClick={setActiveTabHandler}>{children}</LinkWrapper>
  );
};

export const TabLink = withConsumers<ITabLinkProps, ITabSwitcherProviderState>(
  TabSwitcherContext.Consumer
)(TabLinkComponent);
