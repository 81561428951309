import styled, { css } from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import { ICurrencyProps } from './Currency';
import LineHeight from './LineHeight';
import { IWrapperProps } from './Wrapper';

export const CurrencyStyled = styled.span<ICurrencyProps>`
  font-size: 1.5rem;
  font-weight: normal;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }

  ${({ isSpaced, isPrefix }) => {
    if (isSpaced)
      return isPrefix
        ? css`
            &:after {
              content: ' ';
            }
          `
        : css`
            &:before {
              content: ' ';
            }
          `;
  }}
`;

export const Wrapper = styled.div.attrs<{ 'data-testid'?: string }>({
  'data-testid': 'pd-price-wrapper',
})<IWrapperProps>`
  font-weight: bold;
  font-size: 1.87rem;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  line-height: ${LineHeight.NORMAL};
  white-space: nowrap;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2.6rem;
    line-height: ${(props) => props.lineHeight || LineHeight.NORMAL};
  }
`;
