import { dispatchTrackingEvent } from '@context/tracking/utils';

export interface ITrackingItem {
  showId: string;
  itemIndex: number;
  interaction: ELivestreamTrackingInteraction;
}

export enum ELivestreamTrackingInteraction {
  IMAGE = 'image',
  AUTOMATIC = 'automatic',
  SLIDE = 'slide',
  BUTTON = 'button',
}

export const trackLivestreamView = (props: ITrackingItem) => {
  dispatchTrackingEvent({
    event: 'view_promotion',
    promotions: [mapToPromotion(props)],
    _clear: true,
  });
};

export const trackOnClick = ({
  showId,
  itemIndex,
  interaction,
}: ITrackingItem) => {
  dispatchTrackingEvent({
    event: 'select_promotion',
    promotions: [mapToPromotion({ showId, itemIndex, interaction })],
    _clear: true,
  });
};

export const mapToPromotion = ({
  showId,
  itemIndex,
  interaction,
}: ITrackingItem) => {
  return {
    id: showId,
    code: 'livestream',
    name: '',
    location: 'livestream_pdp',
    position: itemIndex + 1,
    creative: '',
    destination_url: '',
    interaction: interaction,
  };
};
