import {
  defaultDataIdFromObject,
  InMemoryCacheConfig,
  StoreObject,
} from '@apollo/client/cache';

import { GetReviewsQuery } from '@notino/shared/definitions/types';

const dataIdFromObject = <
  K extends StoreObject & { path?: string; webMasterId?: string; webId: string }
>(
  object: Readonly<K>
) => {
  switch (object.__typename) {
    case 'CatalogProduct':
      return `${object.__typename}:${object.webMasterId}`;
    case 'CatalogVariant':
      return `${object.__typename}:${object.webId}`;
    case 'User': {
      if (object.id) {
        return defaultDataIdFromObject(object);
      }
      return `${object.__typename}:__LOGGED_USER__`;
    }
    case 'Settings':
      return `${object.__typename}:0`;
    case 'FeatureSwitches':
      return `${object.__typename}:0`;
    case 'PathView':
      return `${object.__typename}:${decodeURI(object.path)}`;
    default:
      return defaultDataIdFromObject(object); // fall back to default handling
  }
};

export function getApolloCacheSettings(): InMemoryCacheConfig {
  return {
    dataIdFromObject,
    typePolicies: {
      RequestHeaders: {
        merge: true,
      },
      Settings: {
        merge: true,
      },
      CatalogProduct: {
        fields: {
          reviewOverview: {
            merge: true,
          },
        },
      },
      ReviewRating: {
        keyFields: false,
      },
      SeoMetaData: {
        fields: {
          keywords: {
            merge: true,
          },
          description: {
            merge: true,
          },
          title: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          RequestHeaders: {
            merge: true,
          },
          reviews: {
            keyArgs: ['code', 'orderDesc', 'orderBy', 'hideTranslated'],
            merge(
              existing: GetReviewsQuery['reviews'] = [],
              incoming: GetReviewsQuery['reviews'] = []
            ) {
              return [...existing, ...incoming];
            },
          },
          resolveUrl: {
            merge: true,
          },
          vpProductById: (_, { args, toReference }) => {
            return toReference({
              __typename: 'VpProduct',
              id: args.id,
            });
          },
        },
      },
    },
  };
}
