import { defineMessages } from 'react-intl';
export default defineMessages({
  addToCartTryAgain: {
    id: 'universals.add.to.cart.tryAgain',
    defaultMessage: 'Zkusit znovu',
  },
  addedToCart: {
    id: 'universals.added.to.cart',
    defaultMessage: 'Přidáno do košíku',
  },
  continueWithShopping: {
    id: 'universals.continue.with.shopping',
    defaultMessage: 'Pokračovat v nákupu',
  },
  goToCart: {
    id: 'universals.go.to.cart',
    defaultMessage: 'Přejít do košíku',
  },
  priceMissingForFreeDelivery: {
    id: 'universals.amount.missing.for.free.delivery',
    defaultMessage: '{price} do dopravy zdarma',
  },
  customersAlsoBuy: {
    id: 'universals.customers.also.buy',
    defaultMessage: 'Zákazníci také koupili',
  },
  productsAddOutOfPriceLimit: {
    id: 'universals.products.add.OutOfPriceLimit',
    defaultMessage:
      'Přidáním zboží do košíku jste překročili povolený limit objednávky.',
  },
  productsAddVoucherOnline: {
    id: 'universals.products.add.VoucherOnline',
    defaultMessage: 'V košíku je online poukázka.',
  },
  productsAddSold: {
    id: 'universals.products.add.Sold',
    defaultMessage: 'Zboží je vyprodáno.',
  },
  productsAddUseProductInCart: {
    id: 'universals.products.add.UseProductInCart',
    defaultMessage:
      'Toto zboží se speciální slevou již nelze vložit do košíku.',
  },
  productsAddNoCreateCart: {
    id: 'universals.products.add.NoCreateCart',
    defaultMessage: 'Zboží se nepodařilo vložit do košíku.',
  },
  productsAddReducedCount: {
    id: 'universals.products.add.ReducedCount',
    defaultMessage: 'Množství zboží vloženého do košíku bylo upraveno.',
  },
  productsAddError: {
    id: 'universals.products.add.Error',
    defaultMessage: 'Při přidání produktu do košíku se vyskytla chyba.',
  },
  servicesEngravingDefault: {
    id: 'universals.services.Engraving.Default',
    defaultMessage: 'Gravírování bylo úspěšně přidáno ke zboží.',
  },
  servicesEngravingSuccess: {
    id: 'universals.services.Engraving.Success',
    defaultMessage: 'Gravírování bylo úspěšně přidáno ke zboží.',
  },
  servicesEngravingNotFound: {
    id: 'universals.services.Engraving.NotFound',
    defaultMessage: 'Při přidání gravírování ke zboží se vyskytla chyba.',
  },
  servicesEngravingError: {
    id: 'universals.services.Engraving.Error',
    defaultMessage: 'Při přidání gravírování ke zboží se vyskytla chyba.',
  },
  productForProfessionalChoosed: {
    id: 'universals.product.for.professional.choosed',
    defaultMessage: 'Vybrali jste profesionální produkt',
  },
  returnedProductChoosed: {
    id: 'universals.product.returned.choosed',
    defaultMessage: 'Vybrali jste rozbalený produkt',
  },
  damagedProductChoosed: {
    id: 'universals.product.damaged.choosed',
    defaultMessage: 'Vybrali jste produkt s poškozeným obalem',
  },
  damagedProductForProfessionalChoosed: {
    id: 'universals.product.damaged.for.professional.choosed',
    defaultMessage: 'Vybrali jste profesionální produkt s poškozeným obalem',
  },
  returnedProductForProfessionalChoosed: {
    id: 'universals.product.returned.for.professional.choosed',
    defaultMessage: 'Vybrali jste profesionální rozbalený produkt',
  },
  productForProfessionalAcknowledgement: {
    id: 'universals.product.for.professional.acknowledgement',
    defaultMessage:
      'Přidáním do košíku berete na vědomí, že tento produkt je určený výhradně pro profesionální použití.',
  },
  returnedProductAcknowledgement: {
    id: 'universals.product.returned.acknowledgement',
    defaultMessage: 'Může být lehce použitý nebo bez původního obalu.',
  },
  damagedProductAcknowledgement: {
    id: 'universals.product.damaged.acknowledgement',
    defaultMessage: 'Produkt je nový se 100% obsahem, má pouze poškozený obal.',
  },
  damagedProductForProfessionalAcknowledgement: {
    id: 'universals.product.damaged.for.professional.acknowledgement',
    defaultMessage:
      'Produkt je nový se 100% obsahem, má však poškozený obal. Přidáním do košíku berete také na vědomí, že tento produkt je určen výhradně pro profesionální použití.',
  },
  returnedProductForProfessionalAcknowledgement: {
    id: 'universals.product.returned.for.professional.acknowledgement',
    defaultMessage:
      'Produkt může být lehce použitý nebo bez původního obalu. Přidáním do košíku berete také na vědomí, že tento produkt je určen výhradně pro profesionální použití. ',
  },
  productForProfessionalConfirm: {
    id: 'universals.product.for.professional.confirm',
    defaultMessage: 'Přidat do košíku',
  },
  cartLimitExceededHeader: {
    id: 'universals.add.to.cart.limit.exceeded.header',
    defaultMessage: 'Vaše objednávka přesáhla maximální hodnotu.',
  },
  cartLimitExceededExplanation: {
    id: 'universals.add.to.cart.limit.exceeded.explanation',
    defaultMessage:
      'Zboží odesíláme z České republiky a z důvodu celního řízení může být v maximální výši <b>4250 UAH</b>.',
  },
  cartLimitExceededAdvice: {
    id: 'universals.add.to.cart.limit.exceeded.advice',
    defaultMessage: 'Pro další nákup si prosím vytvořte novou objednávku.',
  },
  cartSoldoutHeader: {
    id: 'universals.add.to.cart.soldout.header',
    defaultMessage: 'Vyprodaný produkt',
  },
  cartSoldoutExplanation: {
    id: 'universals.add.to.cart.soldout.explanation',
    defaultMessage:
      'Je nám líto, ale bohužel někdo jiný koupil produkt <b>{productName}</b> rychleji než Vy. Prosím vyberte si jiný produkt anebo zkuste naše hlídání dostupnosti mailem.',
  },
  cartOnlyVoucherHeader: {
    id: 'universals.add.to.cart.only.voucher.header',
    defaultMessage: 'Elektronická poukázka',
  },
  cartOnlyVoucherExplanation: {
    id: 'universals.add.to.cart.only.voucher.explanation',
    defaultMessage:
      'Elektronickou poukázku lze zakoupit pouze samostatně bez dalších produktů.',
  },
  systemOverloaded: {
    id: 'universals.add.to.cart.systemOverloaded',
    defaultMessage: 'Systém je nyní přetížený',
  },
  pleaseTryAgain: {
    id: 'universals.add.to.cart.pleaseTryAgain',
    defaultMessage: 'Zkuste to prosím za několik minut. Děkujeme.',
  },
});
