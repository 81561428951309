import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import messages from '@containers/ProductDetailContainer/messages';
import { roundToMax2Decimals } from '@utils/number';

import { FadeWrapper } from './FadeWrapper';
import { Text, Wrapper, SectionTitle, TableWrapper, TopMargin } from './styled';
import { VitaminsTable } from './VitaminsTable';

interface CompositionProps {
  html: string;
  tablesData?: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['documentation']['composition'];
}

export const Composition = ({ html, tablesData }: CompositionProps) => {
  return (
    <Wrapper data-testid="pd-composition-wrapper" id="pd-composition-wrapper">
      <SectionTitle>
        <FormattedMessage {...messages.fullComposition} />
      </SectionTitle>

      <Text
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />

      {tablesData?.nutritionValues?.length > 0 && (
        <TopMargin>
          <FadeWrapper>
            <TableWrapper data-testid="nutrition-table">
              <table>
                {tablesData.nutritionValues.map((row) => (
                  <tr key={row.nutritionValue}>
                    <td>{row.nutritionValue}</td>
                    <td>
                      {roundToMax2Decimals(row.amount)} {row.measureUnit}
                    </td>
                    <td>
                      {roundToMax2Decimals(row.variantAmount)} {row.measureUnit}
                    </td>
                  </tr>
                ))}
              </table>
            </TableWrapper>
          </FadeWrapper>
        </TopMargin>
      )}

      {!!tablesData?.vitaminsAndMinerals?.length && (
        <TopMargin>
          <VitaminsTable
            vitaminsAndMinerals={tablesData?.vitaminsAndMinerals}
          />
        </TopMargin>
      )}
    </Wrapper>
  );
};
