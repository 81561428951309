import type { PartialDeep } from 'type-fest';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import {
  AvailabilityState,
  GetCatalogProductViewQuery,
  GetProductsByIdBatchedQuery,
} from '@notino/shared/definitions/types';
import {
  AdditionalServicesAvailability,
  getVariantPriceWithoutTax,
} from '@notino/web-tracking';
import { Product } from '@notino/web-tracking/dist/types/package/ga4/events/products';

import { getGiftCampaigns } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/Gift';
import { getModifaceAvailability } from '@containers/ProductDetailContainer/ProductDetail/utils';

import { IVariantAddToCart } from './model';
import { getPromoLabels } from './utils';

const getAvailability = (
  variant: Pick<
    IVariantAddToCart,
    'showWatchdog' | 'stockAvailability' | 'availability'
  >
): Product['availability'] => {
  if (
    variant.showWatchdog ||
    variant.availability?.state === AvailabilityState.ShowWatchdog
  ) {
    return 'watchdog';
  }
  return (
    variant.stockAvailability?.code?.toLowerCase() !==
    IStock.outOfStock.toLowerCase()
  );
};

interface IProductEventWither<
  ProductEventType extends Record<string, unknown>
> {
  withProduct: (
    product: GetCatalogProductViewQuery['productDetailByCatalogMasterId']
  ) => IProductEventWither<
    ProductEventType &
      Pick<
        Product,
        | 'collection'
        | 'rating'
        | 'brand_name'
        | 'brand_id'
        | 'manufacturer_name'
        | 'primary_category'
        | 'primary_subcategory'
        | 'primary_type'
        | 'kind'
        | 'group'
      >
  >;
  withPartialProduct: (
    product: Partial<
      GetCatalogProductViewQuery['productDetailByCatalogMasterId']
    >
  ) => IProductEventWither<
    ProductEventType &
      Pick<
        Product,
        | 'collection'
        | 'rating'
        | 'brand_name'
        | 'brand_id'
        | 'manufacturer_name'
        | 'primary_category'
        | 'primary_subcategory'
        | 'primary_type'
        | 'kind'
        | 'group'
      >
  >;
  withVpProduct: (
    product: Pick<
      GetProductsByIdBatchedQuery['vpProductByIds'][number],
      | 'brand'
      | 'productCode'
      | 'name'
      | 'wishlisted'
      | 'attributes'
      | 'primaryCategories'
    > &
      IVariantAddToCart
  ) => IProductEventWither<
    ProductEventType &
      Pick<
        Product,
        | 'rating'
        | 'brand_name'
        | 'primary_category'
        | 'primary_subcategory'
        | 'primary_type'
        | 'kind'
        | 'group'
        | 'product_code'
        | 'product_name'
        | 'favorite'
        | 'promo_labels'
        | 'availability'
        | 'price'
        | 'full_price'
      >
  >;
  withVariant: (
    variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
  ) => IProductEventWither<
    ProductEventType &
      Pick<
        Product,
        | 'product_code'
        | 'product_name'
        | 'favorite'
        | 'product_gender'
        | 'promo_labels'
        | 'availability'
        | 'price'
        | 'full_price'
      >
  >;
  withPartialVariant: (
    variant: PartialDeep<
      GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
    >
  ) => IProductEventWither<
    ProductEventType &
      Pick<
        Product,
        | 'product_code'
        | 'product_name'
        | 'favorite'
        | 'product_gender'
        | 'promo_labels'
        | 'availability'
        | 'price'
        | 'full_price'
      >
  >;
  withServices: (services?: {
    tryItFirstAvailable: AdditionalServicesAvailability;
    engravingAvailable: AdditionalServicesAvailability;
    discoveryBoxAvailable: boolean;
    skinAnalyzer?: boolean;
    modifaceVariants: Record<string, unknown>;
  }) => IProductEventWither<ProductEventType & Pick<Product, 'services'>>;
  withAdditionalData: <T extends Partial<Product>>(
    additionalData: T
  ) => IProductEventWither<ProductEventType & T>;
  withAdditionalPromoLabels: (
    promoLabels: string[]
  ) => IProductEventWither<ProductEventType>;
  build: () => ProductEventType;
  forceBuild: () => Product;
}

const getServices = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  {
    tryItFirstAvailable,
    engravingAvailable,
    discoveryBoxAvailable,
    skinAnalyzer,
    modifaceVariants = {},
  }: Parameters<IProductEventWither<Record<string, unknown>>['withServices']>[0]
) => {
  const services = [];

  const {
    trackingAttrs: { giftAvailable, moreGifts },
  } = getGiftCampaigns({ product, variant });

  if (engravingAvailable === AdditionalServicesAvailability.available) {
    services.push('engraving');
  }
  if (tryItFirstAvailable === AdditionalServicesAvailability.available) {
    services.push('try_it_first');
  }
  if (modifaceVariants?.[variant?.webId]) {
    services.push(`modiface_${getModifaceAvailability(true)}`);
  }
  if (variant?.availability.availablePickUpStores > 0) {
    services.push('click_and_collect');
  }
  if (giftAvailable) {
    services.push('gift_available');
  }
  if (moreGifts) {
    services.push('more_gifts');
  }
  if (product.features?.availableSets?.sets?.length > 0) {
    services.push('gift_sets');
  }
  if (product.features?.isShadeFinderAvailable) {
    services.push('shadefinder_available');
  }
  if (discoveryBoxAvailable) {
    services.push('discovery_box');
  }
  if (skinAnalyzer) {
    services.push('skin_anl');
  }
  return services;
};

export const ProductEventWither = <
  ProductEventType extends Record<string, unknown>
>(): IProductEventWither<ProductEventType> => {
  let event: ProductEventType = {} as ProductEventType;
  let variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number] =
    null;
  let product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'] =
    null;

  return {
    withProduct(p) {
      if (!p) {
        return this;
      }
      product = p;
      event = {
        ...event,
        collection: product.collection,
        rating: String(product.reviewOverview?.rating),
        brand_name: product.brand?.name,
        brand_id: product.brand?.id,
        manufacturer_name: product.brand?.manufacturer,
        primary_category: product.primaryCategories?.category?.name,
        primary_subcategory: product.primaryCategories?.subCategory?.name,
        primary_type: product.primaryCategories?.type?.name,
        kind: product.primaryCategories?.kind?.name,
        group: undefined, // not returned from api yet,
      };
      return this;
    },
    withVariant(v) {
      if (!v) {
        return this;
      }
      variant = v;
      event = {
        ...event,
        product_code: variant.productCode,
        product_name: `${variant.name} ${
          variant.variantName || product.annotation
        } ${variant.additionalInfo}`.trim(),
        favorite: variant.wishlisted,
        product_gender: variant.gender,
        promo_labels: getPromoLabels(variant.attributes),
        availability: getAvailability(variant),
        price: variant ? getVariantPriceWithoutTax(variant.price) : undefined,
        full_price: variant.price?.value,
      };
      return this;
    },
    withPartialProduct(p) {
      return this.withProduct(p);
    },
    withVpProduct(p) {
      if (!p) {
        return this;
      }
      event = {
        ...event,
        rating: p.reviewOverview?.rating,
        brand_name: p.brand?.name,
        primary_category: p.primaryCategories?.category,
        primary_subcategory: p.primaryCategories?.subCategory,
        primary_type: p.primaryCategories?.type,
        kind: p.primaryCategories?.kind,
        group: undefined, // not returned from api yet,
        product_code: p.productCode,
        product_name: `${p.name} ${p.annotation}`.trim(),
        favorite: p.wishlisted,
        promo_labels: getPromoLabels(p.attributes),
        availability: getAvailability(p),
        price: p ? getVariantPriceWithoutTax(p.price) : undefined,
        full_price: p.price?.value,
      };
      return this;
    },
    withPartialVariant(v) {
      return this.withVariant(
        v as GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
      );
    },
    withServices(services) {
      event = {
        ...event,
        services: getServices(product, variant, services),
      };
      return this;
    },
    withAdditionalData(additionalData) {
      event = {
        ...event,
        ...additionalData,
      };
      return this;
    },
    withAdditionalPromoLabels(promoLabels) {
      event = {
        ...event,
        promo_labels: [
          ...(event.promo_labels as string[]),
          ...promoLabels.filter(Boolean),
        ],
      };
      return this;
    },
    build() {
      return event;
    },
    forceBuild() {
      return event as unknown as Product;
    },
  };
};
