import { useQuery } from '@apollo/client';

import { IsWishlistEnabledQuery } from '@notino/shared/definitions/types';

import isWishlistEnabledQuery from '../queries/isWishlistEnabled.graphql';

export const useIsWishlistEnabled = () => {
  const { data, loading, error } = useQuery<IsWishlistEnabledQuery>(
    isWishlistEnabledQuery
  );

  return !loading && !error && data?.Settings?.isWishlistAllowed;
};
