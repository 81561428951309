import styled from 'styled-components';

export const MainWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

export const BannerCarouselWrapper = styled.div``;

export const SliderWrapper = styled.div`
  position: relative;
`;
