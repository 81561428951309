import { ApolloError, useQuery } from '@apollo/client';

import {
  GetLivestreamsQueryVariables,
  GetLivestreamsQuery,
} from '@notino/shared/definitions/types';

import getLivestreamsQuery from '../queries/getLivestreams.graphql';

export const useLivestreams = ({
  enabled,
  productCode,
}: {
  enabled: boolean;
  productCode: string;
}): { data: GetLivestreamsQuery; error: ApolloError } => {
  const { data, error } = useQuery<
    GetLivestreamsQuery,
    GetLivestreamsQueryVariables
  >(getLivestreamsQuery, {
    skip: !enabled,
    ssr: false,
    variables: {
      variables: {
        productCode: productCode,
      },
    },
  });

  return { data, error };
};
