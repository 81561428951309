import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const ReviewsSentSuccesModalWrapper = styled.div`
  margin: 3rem;
  text-align: center;
  padding: 1.25rem;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

export const ReviewSentText = styled.div`
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const ReviewSentThanksText = styled.div`
  font-size: 0.875rem;
  color: ${theme.color.text.secondary};
`;
