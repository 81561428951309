import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoggedInUserReviewQuery } from 'packages/shared/definitions/types';

import {
  ModalModel,
  ModalContext,
  IModalProps,
  useBreakpointValue,
} from '@notino/react-styleguide';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import messages from '@containers/ProductDetailContainer/messages';
import {
  ReviewSourceTypes,
  IRating,
} from '@containers/ProductDetailContainer/model';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useUser } from '@queries/useUser';

import { shouldOpenReviewModal } from '../../utils/shouldOpenReviewModal';

import { ReviewsForm } from './ReviewsFormModalBody/loadable';
import { ReviewsSentSuccessModalBody } from './ReviewsSentSuccesModal';

interface IUseReviewModalOrDrawerParameters {
  ratings: IRating[];
  productCode: string;
  masterId: string;
  catalogMasterId: string;
  isAllReviewsPage?: boolean;
  loggedInUserReview?: LoggedInUserReviewQuery['loggedInUserReview'];
  refetchLoggedInUserReview: () => Promise<void>;
}

const MODAL_OPTIONS: IModalProps = {
  size: ModalModel.Sizes.default,
  type: ModalModel.Types.default,
  header: null,
  center: false,
  noBorders: true,
  withFocusTrap: true,
};

export const useReviewModalOrDrawer = ({
  ratings,
  productCode,
  masterId,
  catalogMasterId,
  isAllReviewsPage,
  loggedInUserReview,
  refetchLoggedInUserReview,
}: IUseReviewModalOrDrawerParameters) => {
  const { toggleModal, isShown } = ModalContext.useModalContext();
  const [success, setSuccess] = React.useState(false);
  const isDesktop = useBreakpointValue({ md: true });
  const { toggleDrawer, isDrawerOpen } = useDrawer();
  const { isLoggedIn } = useUser();
  const { product } = useProductDetailContext();

  const handleSuccess = React.useCallback(async () => {
    setSuccess(true);
    if (isLoggedIn) {
      await refetchLoggedInUserReview();
    }
  }, [refetchLoggedInUserReview, isLoggedIn]);

  React.useEffect(() => {
    // reset success when modal closed so user can edit review when opened again
    if ((!isShown || !isDrawerOpen) && isLoggedIn) {
      setSuccess(false);
    }
  }, [isShown, isLoggedIn, isDrawerOpen]);

  const DRAWER_OPTIONS = React.useMemo(() => {
    return {
      header: (
        <FormattedMessage
          {...messages.reviewHeader}
          values={{ name: `${product?.brand?.name} ${product?.collection}` }}
        />
      ),
    };
  }, [product?.brand?.name, product?.collection]);

  const getReviewsFormComponent = React.useCallback(
    (source: ReviewSourceTypes) => {
      return (
        <ReviewsForm
          catalogMasterId={catalogMasterId}
          masterId={masterId}
          ratings={ratings}
          source={source}
          productCode={productCode}
          onSuccess={handleSuccess}
          loggedInUserReview={loggedInUserReview}
          isAllReviewsPage={isAllReviewsPage}
        />
      );
    },
    [
      catalogMasterId,
      handleSuccess,
      loggedInUserReview,
      masterId,
      productCode,
      ratings,
      isAllReviewsPage,
    ]
  );

  React.useEffect(() => {
    const reviewSource = shouldOpenReviewModal();

    const modalOrDrawerContent = (
      <div tabIndex={0}>{getReviewsFormComponent(reviewSource)}</div>
    );

    if (reviewSource) {
      isDesktop
        ? toggleModal(modalOrDrawerContent, MODAL_OPTIONS)
        : toggleDrawer(modalOrDrawerContent, DRAWER_OPTIONS);
    }
  }, [
    masterId,
    catalogMasterId,
    productCode,
    ratings,
    toggleModal,
    handleSuccess,
    loggedInUserReview,
    isDesktop,
    toggleDrawer,
    getReviewsFormComponent,
    DRAWER_OPTIONS,
  ]);

  return React.useCallback(() => {
    const modalOrDrawerContent = (
      <div tabIndex={0}>
        {success ? (
          <ReviewsSentSuccessModalBody />
        ) : (
          getReviewsFormComponent(ReviewSourceTypes.DIRECT)
        )}
      </div>
    );

    isDesktop
      ? toggleModal(modalOrDrawerContent, MODAL_OPTIONS)
      : toggleDrawer(modalOrDrawerContent, DRAWER_OPTIONS);
  }, [
    success,
    getReviewsFormComponent,
    isDesktop,
    toggleModal,
    toggleDrawer,
    DRAWER_OPTIONS,
  ]);
};
