import * as React from 'react';

import { AddToCartButtonProvider } from '@sharedComponents/AddToCartButton/context';
import { getModalAfterAddToCart } from '@sharedComponents/AddToCartButton/getModalAfterAddToCart';
import { IAddToCartButtonProps } from '@sharedComponents/AddToCartButton/model';

import { AddToCartCoreComponent } from './AddToCartCoreComponentButton';

export const AddToCartButton: React.FC<IAddToCartButtonProps> = React.memo(
  (props) => {
    return (
      <AddToCartButtonProvider
        {...props}
        getModalAfterAddToCart={getModalAfterAddToCart}
      >
        <AddToCartCoreComponent
          {...props}
          getModalAfterAddToCart={getModalAfterAddToCart}
        />
      </AddToCartButtonProvider>
    );
  }
);
