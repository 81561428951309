import * as uuid from 'uuid';

import { EShop, Roles } from '@containers/App/model';
import { EAvailableHeaders } from '@headersHelper';

export interface IRequestHeaders {
  __typename?: string;
  shopId?: EShop;
  groupId?: string;
  role?: Roles;
  userId?: string;
  authorization?: string;
  xUserToken?: string;
  userEmail?: string;
  userName?: string;
  xLangTag?: string;
  gatewayUrl?: string;
  assetsPath?: string;
  originUrl?: string;
  features?: string;
  abTest?: string;
  language?: string;
}

class LocalRequestHeadersSingleton {
  private _localHeaders: IRequestHeaders;
  private _layoutSetting: Map<string, string>;

  public constructor() {
    this._localHeaders = {};
    this._layoutSetting = new Map<string, string>();
  }

  public get localHeaders(): IRequestHeaders {
    return this._localHeaders;
  }

  public set localHeaders(value: IRequestHeaders) {
    this._localHeaders = {
      ...value,
      language: value.shopId.slice('notino.'.length),
    };
  }

  public updateLSHeader(lsKey: keyof IRequestHeaders, newValue: string) {
    this.localHeaders = { ...this.localHeaders, [lsKey]: newValue };
  }

  public getHeaders(isGatewayProxy: boolean) {
    if (isGatewayProxy) {
      return {
        [EAvailableHeaders.xTraceId]: uuid(),
      };
    }
    return {
      [EAvailableHeaders.userEmail]: this.localHeaders.userEmail,
      [EAvailableHeaders.shopId]: this.localHeaders.shopId,
      [EAvailableHeaders.xLangTag]: this.localHeaders.xLangTag,
      ...(this.localHeaders.xUserToken && {
        [EAvailableHeaders.xUser]: this.localHeaders.xUserToken,
      }),
      ...(this.localHeaders.features && {
        [EAvailableHeaders.features]: this.localHeaders.features,
      }),
      ...(this.localHeaders.abTest && {
        [EAvailableHeaders.xAbTest]: this.localHeaders.abTest,
      }),
      [EAvailableHeaders.xTraceId]: uuid(),
    };
  }
  public getLayoutSetting(key: string) {
    return this._layoutSetting.get(key);
  }
  public setLayoutSetting(key: string, layout: string) {
    this._layoutSetting.set(key, layout);
  }
}

export const LocalRequestHeaders = new LocalRequestHeadersSingleton();
