import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonModel } from '@notino/react-styleguide';

import { IReviewSummary } from '@containers/ProductDetailContainer/model';

import messages from '../../../../../messages';
import { StyledCol } from '../styled';

import {
  Header,
  NoReviewText,
  OverallValue,
  StyledButton,
  StyledRatings,
} from './styled';

interface IHeaderProps {
  hasReviews: boolean;
  isAllReviewsPage?: boolean;
  reviewSummary: IReviewSummary;
  userHasWrittenReview: boolean;
  onWriteMessageClick: () => void;
}

export const ReviewHeader = ({
  hasReviews,
  reviewSummary,
  onWriteMessageClick,
  isAllReviewsPage,
  userHasWrittenReview,
}: IHeaderProps) => {
  const headerColProps = hasReviews ? { md: 3 } : {};

  const buttonStyle = isAllReviewsPage
    ? ButtonModel.Styles.secondary
    : ButtonModel.Styles.primary;

  return (
    <StyledCol xs={12} {...headerColProps}>
      <Header data-cypress="reviews-averageRating">
        {hasReviews && (
          <OverallValue>{reviewSummary.averageRating.toFixed(1)}</OverallValue>
        )}

        <StyledRatings
          ratingId="overall"
          rating={hasReviews ? reviewSummary.averageRating : 0}
          size={20}
          background="background.disabled"
          foreground="text.highlight"
        />
      </Header>

      {!hasReviews && (
        <NoReviewText>
          <FormattedMessage {...messages.noReview} />
        </NoReviewText>
      )}

      <StyledButton
        data-cypress="reviews-addNewReview"
        buttonStyle={buttonStyle}
        onClick={onWriteMessageClick}
      >
        <FormattedMessage
          {...(userHasWrittenReview
            ? messages.editReview
            : messages.writeReview)}
        />
      </StyledButton>
    </StyledCol>
  );
};
