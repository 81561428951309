import {
  GetCatalogProductViewQuery,
  StockAvailability,
} from './definitions/types';

const productPattern = new RegExp('^p-\\d+$');

interface IMasterUrlOptions {
  trimLeadingSlash: boolean;
  trimTrailingSlash: boolean;
}

const trimUrlSlash = (url: string, options: IMasterUrlOptions) => {
  const { trimLeadingSlash, trimTrailingSlash } = options || {};

  if (
    url.startsWith('/') &&
    url.endsWith('/') &&
    trimLeadingSlash &&
    trimTrailingSlash
  ) {
    return url.slice(1, -1);
  } else if (url.startsWith('/') && trimLeadingSlash) {
    return url.slice(1);
  } else if (url.endsWith('/') && trimTrailingSlash) {
    return url.slice(0, -1);
  }

  return url;
};

export const removeUrlSearchParams = (url: string) => url.split('?')[0];

export const masterUrl = (url: string, options?: IMasterUrlOptions): string =>
  trimUrlSlash(url, options)
    .split('/')
    .filter((string) => !productPattern.test(string))
    .join('/');

export const variantIdFromUrl = (url: string): string => {
  return url
    .split('/')
    .filter((string) => productPattern.test(string))
    .join('')
    .replace(/p-/g, '');
};

export const variantUrlFromUrl = (url: string): string => {
  return url
    .split('/')
    .filter((string) => productPattern.test(string))
    .join('');
};

type Variant = Pick<
  GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  'attributes' | 'stockAvailability'
>;

const isAvailable = (variant: Variant) =>
  variant.stockAvailability.code.toLowerCase() !==
  StockAvailability.OutOfStock.toLowerCase();

const isMaster = (variant: Variant) =>
  variant.attributes && variant.attributes.Master;

const isNotSample = (variant: Variant) =>
  variant.attributes ? !variant.attributes.Sample : true;

const isNotDamaged = (variant: { attributes: Variant['attributes'] }) =>
  variant.attributes ? !variant.attributes.Damage : true;

const isSale = (variant: Variant) =>
  variant.attributes && variant.attributes.Action;

export const pickBestVariant = <T extends Variant>(variants: T[]): T => {
  const sale = variants.find(isSale);
  if (sale && isAvailable(sale)) {
    return sale;
  }

  const master = variants.find(isMaster);
  if (!master || !isAvailable(master)) {
    const noSamples = variants.filter(isNotSample);
    return (
      noSamples.filter(isNotDamaged).find(isAvailable) ||
      noSamples.find(isAvailable) ||
      master ||
      variants[0]
    );
  }
  return master;
};
