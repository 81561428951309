import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';
import { IWatchdogProps } from '@sharedComponents/Watchdog/model';

export const trackWatchdogSent = (
  email: string,
  product: IWatchdogProps['variant']
) => {
  dispatchTrackingEvent({
    event: 'watchdog_submit',
    watchdog: {
      action: 'watchdog_submitted',
      interaction: 'button',
      type: 'modal',
      products: [
        ProductEventWither()
          .withPartialProduct(product)
          .withPartialVariant(product)
          .build(),
      ],
      email,
      emailHashed: undefined,
    },
    _clear: true,
  });
};

export const trackWatchdogClick = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
) => {
  dispatchTrackingEvent({
    event: 'watchdog_click',
    watchdog: {
      action: 'wt_add_watchdog',
      interaction: 'button',
      type: 'Drawer',
      products: [
        ProductEventWither().withProduct(product).withVariant(variant).build(),
      ],
    },
    _clear: true,
  });
};
