import {
  breakpoints,
  Button,
  styled,
  ProductTile,
  theme,
} from '@notino/react-styleguide';

export const StyledProductTile = styled(ProductTile)`
  margin-top: 1rem;
`;

export const VoucherCode = styled.strong`
  color: ${theme.color.text.primary};
`;
export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  & > button,
  form button {
    text-transform: none !important;
    font-weight: normal !important;
    width: auto !important;
  }
`;

export const StyledButtonWatchdog = styled(Button)`
  margin-top: 1rem;
  line-height: 1.4;
  padding: 0.4375rem 1rem;
  text-transform: none;
  font-weight: normal;
  white-space: pre-wrap;
  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

export const CouponIcon = styled.img`
  width: 2.5rem;
  height: 0.5rem;
  z-index: 2;
`;
