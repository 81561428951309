/**
 * @return [tomorrow, date after tomorrow]
 */
const getNextTwoDays = (): [Date, Date] => {
  const now = new Date();
  const tomorrowTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0
  );
  const dayAfterTomorrowTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 2,
    0,
    0,
    0
  );
  return [tomorrowTime, dayAfterTomorrowTime];
};

export const isTomorrow = (date: Date) => {
  const timeToCompare = new Date(date).getTime();
  const [tomorrow, dayAfterTomorrow] = getNextTwoDays();

  return (
    timeToCompare >= tomorrow.getTime() &&
    timeToCompare < dayAfterTomorrow.getTime()
  );
};

export const isToday = (date: Date) => {
  const timeToCompare = new Date(date).getTime();
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const [tomorrow] = getNextTwoDays();

  return (
    timeToCompare >= startOfToday.getTime() &&
    timeToCompare < tomorrow.getTime()
  );
};
