import { PostAddToCartAction } from '../../../model';

export const getSettings = (
  showAddToCartModal,
  disableRedirect
): PostAddToCartAction => {
  const isDocument = typeof document !== 'undefined';

  if (
    (isDocument &&
      document.body.className.includes('exponea-addtocart-modal')) ||
    showAddToCartModal
  ) {
    return PostAddToCartAction.SHOW_MODAL;
  }

  if (
    (isDocument &&
      document.body.className.includes('exponea-addtocart-redirect')) ||
    !disableRedirect
  ) {
    return PostAddToCartAction.REDIRECT;
  }

  return PostAddToCartAction.ONLY_ADD;
};
