import styled, { css } from 'styled-components';

import {
  breakpoints,
  ColorCircle,
  IBasicStyledProps,
  theme,
} from '@notino/react-styleguide';

import { COLOR_CIRCLE_SIZE } from '../../ShadesDrawer/constants';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
`;

export const VariantsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

interface IVariantProps extends IBasicStyledProps {
  isSelected: boolean;
  isOnStock: boolean;
  touch: boolean;
}

export const Variant = styled.div<IVariantProps>`
  position: relative;

  ${({ isSelected }) => css`
    border: 2px solid
      ${isSelected ? theme.color.common.gray30 : theme.color.common.white};
  `}

  cursor: pointer;
  margin: 0;
  display: ${(props: IVariantProps) => (props.touch ? 'flex' : 'none')};
  border-radius: 4rem;
  @media (hover: hover) {
    display: ${(props: IVariantProps) => (props.touch ? 'none' : 'flex')};
  }
`;

export const StyledColorCircle = styled(ColorCircle)<{
  bordered: boolean;
}>`
  border-radius: 4rem;
  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid ${theme.color.common.gray30} !important;
    `}

  @media (min-width: ${breakpoints.md}) {
    width: ${COLOR_CIRCLE_SIZE}rem;
    height: ${COLOR_CIRCLE_SIZE}rem;
  }
`;
