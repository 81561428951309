import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ActionButton, ButtonModel } from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { withProviders } from '@context/contextConfig';
import { useLoggedInUserReviewFetch } from '@queries/useReviews/useLoggedInUserReviewFetch';
import { useReviewsFetch } from '@queries/useReviews/useReviewsFetch';
import { useReviewsSummaryFetch } from '@queries/useReviews/useReviewsSummaryFetch';
import { useTabSwitcher } from '@sharedComponents/TabSwitcher/context/TabSwitcherContext';

import { useProductDetailContext } from '../../ProductDetailContext';

import { PAGE_SIZE } from './constants';
import { LoadingWrapper } from './Loading/LoadingWrapper';
import { ReviewsSkeleton } from './Loading/ReviewsSkeleton';
import { ReviewSummarySkeleton } from './Loading/ReviewSummarySkeleton';
import { messages } from './messages';
import { ReviewsItems } from './ReviewItems';
import { ReviewItemsProvider } from './ReviewItems/context';
import { SortBox } from './ReviewItems/styled';
import { ReviewsSummary } from './ReviewsSummary';
import { CenterWrapper, ErrorMessage, Wrapper } from './styled';
import { shouldOpenReviewModal } from './utils/shouldOpenReviewModal';

interface IReviewsProps {
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  productCode: string;
  masterId: string;
  reviewsTabId: number;
  renderReviewsOnServer: boolean;
  wrapperRef?: React.Ref<HTMLDivElement>;
}

const ReviewsComponent: React.FC<IReviewsProps> = React.memo(
  ({
    productCode,
    masterId,
    reviewsTabId,
    renderReviewsOnServer,
    wrapperRef,
  }) => {
    const {
      product: { catalogMasterId },
    } = useProductDetailContext();

    const reviewModalOpen = shouldOpenReviewModal();

    const { activeTabId } = useTabSwitcher();
    const isActive = activeTabId === reviewsTabId || Boolean(reviewModalOpen);

    const skip = renderReviewsOnServer ? false : !isActive;
    const ssr = renderReviewsOnServer ? true : isActive;

    const {
      data: reviewsSummaryData,
      loading: reviewsSummaryLoading,
      error: reviewsSummaryError,
      refetch: reviewsSummaryRefetch,
    } = useReviewsSummaryFetch(catalogMasterId, { skip, ssr });

    const {
      data: loggedInUserReviewData,
      loading: loggedInUserReviewLoading,
      refetch: refetchLoggedInUserReview,
    } = useLoggedInUserReviewFetch(productCode, {
      ssr,
      skip,
    });

    const { data, error, loading, refetch, fetchMore } = useReviewsFetch(
      PAGE_SIZE,
      productCode,
      { skip, ssr }
    );

    if (!isActive && !renderReviewsOnServer) {
      return null;
    }

    const reviewOverview =
      reviewsSummaryData?.productDetailByCatalogMasterId?.reviewOverview;
    const reviewSummary =
      reviewsSummaryData?.productDetailByCatalogMasterId?.reviewSummary;

    const textReviewsCount = reviewOverview?.textReviewsCount ?? 0;

    const refetchSummary = async () => {
      await reviewsSummaryRefetch();
    };

    const refetchReviews = async () => {
      await refetch();
    };

    return (
      <Wrapper ref={wrapperRef} id="pdReviews">
        <LoadingWrapper
          isLoading={reviewsSummaryLoading || loggedInUserReviewLoading}
          isError={!reviewSummary || !!reviewsSummaryError}
          skeleton={<ReviewSummarySkeleton />}
          error={
            <CenterWrapper>
              <ErrorMessage>
                <FormattedMessage {...messages.reviewsSummaryFailed} />
              </ErrorMessage>

              <ActionButton
                onClick={refetchSummary}
                buttonSize={ButtonModel.Sizes.xSmall}
              >
                <FormattedMessage {...messages.tryAgain} />
              </ActionButton>
            </CenterWrapper>
          }
        >
          <ReviewsSummary
            reviewSummary={reviewSummary}
            productCode={productCode}
            masterId={masterId}
            catalogMasterId={catalogMasterId}
            refetchLoggedInUserReview={async () => {
              await refetchLoggedInUserReview({
                masterProductCode: productCode,
              });
            }}
            loggedInUserReview={loggedInUserReviewData?.loggedInUserReview}
          />
        </LoadingWrapper>

        <LoadingWrapper
          isLoading={loading}
          isError={!data || !!error}
          skeleton={<ReviewsSkeleton />}
          error={
            <>
              <SortBox />
              <CenterWrapper>
                <ErrorMessage>
                  <FormattedMessage {...messages.reviewsFailed} />
                </ErrorMessage>
                <ActionButton
                  onClick={refetchReviews}
                  buttonSize={ButtonModel.Sizes.xSmall}
                >
                  <FormattedMessage {...messages.tryAgain} />
                </ActionButton>
              </CenterWrapper>
            </>
          }
        >
          <ReviewsItems
            productCode={productCode}
            reviews={data?.reviews || []}
            reviewsLoading={loading}
            reviewsError={error}
            refetch={refetch}
            fetchMore={fetchMore}
            textReviewsCount={textReviewsCount}
          />
        </LoadingWrapper>
      </Wrapper>
    );
  }
);

export const Reviews =
  withProviders<IReviewsProps>(ReviewItemsProvider)(ReviewsComponent);
