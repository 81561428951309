import * as React from 'react';

import { IImageProperties } from '@helpers/utils';

import { MainImg, BufferImage } from './styled';
import { getImageProps, hasImageShadow, ImageProps } from './utils';

interface IMainImageProps {
  imageAttrs: IImageProperties;
  onClick: React.MouseEventHandler<HTMLImageElement>;
  index: number;
}

export const MainImage: React.FC<IMainImageProps> = (props) => {
  const { imageAttrs, onClick, index } = props;

  const [imgProps, setImageProps] = React.useState<
    ImageProps & IMainImageProps['imageAttrs']
  >(() => ({
    hasShadow: hasImageShadow(imageAttrs.src),
    fullHeight: false,
    ...props.imageAttrs,
  }));

  const handleImageLoad: React.ReactEventHandler<HTMLImageElement> = (
    event
  ) => {
    setImageProps({
      ...getImageProps(event.currentTarget, index),
      ...props.imageAttrs,
    });
  };

  return (
    <>
      <MainImg
        {...imgProps}
        onClick={onClick}
        loading="eager"
        id="pd-image-main"
      />

      {/* Preload image and calculate its size before we render it so there is no flickering*/}
      <BufferImage
        loading="eager"
        {...imageAttrs}
        alt=""
        onLoad={handleImageLoad}
      />
    </>
  );
};
