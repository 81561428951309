import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackBrandClick = ({
  timing,
  name,
}: Omit<TrackingAttributes<{ name: string }>, 'variant'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      timing,
      name,
      action: 'click_on_element',
      interaction: 'click',
      type: 'brand',
    },
    _clear: true,
  });
};

export const trackRatingClick = ({
  timing,
}: Omit<TrackingAttributes, 'variant'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      timing,
      name: 'stars',
      action: 'click_on_element',
      interaction: 'click',
      type: 'product_detail',
    },
    _clear: true,
  });
};

export const withSpaceAfterWord = (word: string) => `${word} `;
