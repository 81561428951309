import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useApolloClient } from '@apollo/client';

import { usePriceLogic } from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic';
import {
  MIN_PRICE_PROMO_LABEL,
  RRP_PRICE_PROMO_LABEL,
} from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic/tracking';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';

import messages from '../messages';
import { PulsatingHeart } from '../PulsatingHeart';
import { useWishlistActions } from '../useWishlistActions';

import { Wrapper, StyledAddToWishlist } from './styled';
import { updateVariantCache } from './updateVariantCache';

interface IWishlistProductDetailIconProps {
  wishlisted: boolean;
  productId: string | number;
}

export const WishlistProductDetailIcon: React.FC<
  IWishlistProductDetailIconProps
> = ({ wishlisted, productId }) => {
  const { currentVariant } = useProductDetailContext();
  const { minimalPriceShown, rrpShown } = usePriceLogic(currentVariant);
  const { cache } = useApolloClient();

  const updateCallback = React.useCallback(
    (_, addedToWishlist: boolean) =>
      updateVariantCache({ wishlisted: addedToWishlist, productId, cache }),
    [cache, productId]
  );

  const { handleWishlistClick, loading } = useWishlistActions({
    productId: productId.toString(),
    wishlisted: wishlisted,
    onCompleted: updateCallback,
  });

  return (
    <Wrapper>
      <StyledAddToWishlist
        onClick={() =>
          handleWishlistClick('product_detail', [
            rrpShown && RRP_PRICE_PROMO_LABEL,
            minimalPriceShown && MIN_PRICE_PROMO_LABEL,
          ])
        }
      >
        <PulsatingHeart wishlisted={wishlisted} loading={loading} />
        <FormattedMessage
          {...messages[`${wishlisted ? 'inWishlist' : 'addToWishlist'}`]}
        />
      </StyledAddToWishlist>
    </Wrapper>
  );
};
