import {
  GetCatalogProductViewQuery,
  PdSkinAnalyzerVariant,
} from '@notino/shared/definitions/types';

import { BrandsId } from '@containers/ProductDetailContainer/ProductDetail/model';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

const ALLOWED_CATEGORY_ID = 3645;
const ALLOWED_BRAND_IDS = [BrandsId.Estee, BrandsId.Roche];

export const useSkinAnalyzerVisibility = () => {
  const { product } = useProductDetailContext();
  const { pd_skin_analyzer_variant } = useFeatureFlags();
  return getSkinAnalyzerVisibility({ product, pd_skin_analyzer_variant });
};

export const getSkinAnalyzerVisibility = ({
  product,
  pd_skin_analyzer_variant,
}: {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  pd_skin_analyzer_variant: PdSkinAnalyzerVariant;
}) => {
  const shouldShowSkinAnalyzer =
    !!product &&
    ALLOWED_BRAND_IDS.includes(product.brand.id as BrandsId) &&
    Number(product.categorization?.categories?.[0]?.id) === ALLOWED_CATEGORY_ID;

  const isVariant1 =
    pd_skin_analyzer_variant === PdSkinAnalyzerVariant.Variant1;
  const isVariant2 =
    pd_skin_analyzer_variant === PdSkinAnalyzerVariant.Variant2;

  return { shouldShowSkinAnalyzer, isVariant1, isVariant2 };
};
