import { useQuery } from '@apollo/client';

import {
  GetRichContentByCatalogMasterIdQuery,
  GetRichContentByCatalogMasterIdQueryVariables,
} from '@notino/shared/definitions/types';

import { useIsRichContentFirstTab } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsRichContentFirstTab';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';

import getRichContentByCatalogMasterIdQuery from './richContentByCatalogMasterId.graphql';

type Params = { versionKey: string | undefined };

export const useRichContentData = ({ versionKey }: Params) => {
  const { product } = useProductDetailContext();
  const isRichContentFirstTab = useIsRichContentFirstTab();

  const response = useQuery<
    GetRichContentByCatalogMasterIdQuery,
    GetRichContentByCatalogMasterIdQueryVariables
  >(getRichContentByCatalogMasterIdQuery, {
    variables: { catalogMasterId: product.catalogMasterId, versionKey },
    ssr: isRichContentFirstTab,
  });

  return response;
};
