import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { getModifaceVariants, getModifaceHairVariants } from '../utils';

export const useModifaceVariants = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants']
) => {
  return React.useMemo(() => getModifaceVariants(variants), [variants]);
};

export const useModiface = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants']
) => {
  return React.useMemo(
    () => ({
      makeup: getModifaceVariants(variants),
      hair: getModifaceHairVariants(variants),
    }),
    [variants]
  );
};
