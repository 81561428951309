import { useIntl } from 'react-intl';

import { ProductTileModel } from '@notino/react-styleguide';

import messages from '@components/Tags/messages';
import { ILabelAttribute, labelAttributes } from '@components/Tags/model';
import { transformAttributesToTags } from '@components/Tags/utils';

interface UseGetTagAttributesReturns extends ILabelAttribute {
  attribute: ProductTileModel.ProductLabelAttributes;
  label: string;
}

export function useGetTagAttributes() {
  const { formatMessage } = useIntl();

  return (
    attributes: Record<string, unknown>,
    options: {
      multiple?: boolean;
      customFilter?: (
        tagAttributes: ProductTileModel.ProductLabelAttributes
      ) => boolean;
    } = {}
  ): null | UseGetTagAttributesReturns[] => {
    const { multiple = false, customFilter } = options;

    if (!attributes) {
      return null;
    }

    let renderableAttributes: ProductTileModel.ProductLabelAttributes[] =
      transformAttributesToTags(attributes);

    if (customFilter) {
      renderableAttributes = renderableAttributes.filter(customFilter);
    }

    const attrs = renderableAttributes.slice(0, multiple ? Infinity : 1);

    return attrs
      .filter((label) => label !== ProductTileModel.ProductLabelAttributes.Gift)
      .map((label) => ({
        attribute: label,
        label: formatMessage(messages[`attribute${label as string}`]),
        ...labelAttributes[label],
      }));
  };
}

export function useTagAttributes(
  attributes: Record<string, unknown>,
  options: {
    multiple?: boolean;
    customFilter?: (
      tagAttributes: ProductTileModel.ProductLabelAttributes
    ) => boolean;
  } = {}
) {
  const getTagAttributes = useGetTagAttributes();
  return getTagAttributes(attributes, options);
}
