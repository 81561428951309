import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  reviewSent: {
    id: 'pd.reviews.review.sent',
    defaultMessage: 'Hodnocení odesláno',
  },
  reviewSentThanks: {
    id: 'pd.reviews.review.sent.thanks',
    defaultMessage:
      'Děkujeme, že pomáháte ostatním zákazníkům s lepším výběrem',
  },
});
