import { snakeCase } from 'lodash';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';
import { CartType } from '@notino/web-tracking';

import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';

export const ENGRAVING_CODE = 'ENGXXXU_SENG01';

export const engravingPrefix = (text: string) => {
  return `Engraving - ${text}`;
};

export const trackEngravingAddToCart = (
  productInfo: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  quantity,
  additionalPromoLabels: string[]
) => {
  dispatchTrackingEvent({
    event: 'add_to_cart',
    product: ProductEventWither()
      .withProduct(productInfo)
      .withVariant(variant)
      .withAdditionalData({
        // use engraving product code instead of product code
        // more info in comments: https://notino.tpondemand.com/entity/132907-nushopweb-pd-trackovani-pridavani-gravirovani-do
        product_code: ENGRAVING_CODE,
        price: variant.features.engraving.config.price,
        brand_name: engravingPrefix(productInfo.brand.name),
        ...(variant.name && {
          name: engravingPrefix(variant.name),
        }),
        cart_type: snakeCase(CartType.cart),
        quantity,
      })
      .withAdditionalPromoLabels(additionalPromoLabels)
      .build(),
    _clear: true,
  });
};
