import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { IOpenedProps } from '../../../../model';

import { OpenedVariants } from './styled';

interface IExpandedProps extends IOpenedProps {
  isExpanded: boolean;
  onOuterClick: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export const ExpandedVariants: ReactFCC<IExpandedProps> = ({
  isExpanded,
  onOuterClick,
  children,
}) => {
  const ref = React.useRef(null);

  const handleClickOutside = React.useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target) && isExpanded) {
        onOuterClick(e);
      }
    },
    [onOuterClick, isExpanded]
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('touchstart', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('touchstart', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <OpenedVariants ref={ref} isExpanded={isExpanded}>
      {children}
    </OpenedVariants>
  );
};
