import { defineMessages } from 'react-intl';

export default defineMessages({
  instructionsForUse: {
    id: 'pd.about.instructionsForUse',
    defaultMessage: 'Návod k použití naleznete <a>ZDE</a>',
  },
  packagingInformation: {
    id: 'pd.about.packagingInformation',
    defaultMessage: 'Příbalovou informaci naleznete <a>ZDE</a>',
  },
});
