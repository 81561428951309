import { Offer, AggregateRating } from 'schema-dts';

import {
  AvailabilityState,
  GetCatalogProductViewQuery,
  StockAvailability,
} from '@notino/shared/definitions/types';

import { IVoucherDiscount } from '../ProductBaseInfo/VoucherDiscount/VoucherDiscount';

const SOURCE_KEY = 'order_2k';

const getImageAddress = (src: string, imageDomainPath: string) =>
  `${imageDomainPath}${SOURCE_KEY}/${src}`;

export const getMainImageSrc = (
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  imageDomainPath: string
): string | null =>
  variant.media && variant.media.mainImage
    ? getImageAddress(variant.media.mainImage.src, imageDomainPath)
    : null;

export const getImagesSrc = (
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  imageDomainPath: string
): string[] => {
  const result = [];

  if (variant.media) {
    const mainImageSrc = getMainImageSrc(variant, imageDomainPath);
    if (mainImageSrc) {
      result.push(mainImageSrc);
    }
    if (variant.media.others) {
      variant.media.others.forEach((image) =>
        result.push(getImageAddress(image.src, imageDomainPath))
      );
    }
  }

  return result;
};

const getItemAvailability = (
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
) => {
  if (
    variant.availability.stockAvailability === StockAvailability.OutOfStock &&
    variant.availability.state === AvailabilityState.ShowWatchdog
  ) {
    return 'https://schema.org/LimitedAvailability';
  }
  if (variant.availability.stockAvailability === StockAvailability.OutOfStock) {
    return 'https://schema.org/OutOfStock';
  }
  return 'https://schema.org/InStock';
};

type GetVariantOfferReturn = {
  itemCountry: string;
} & Offer;

export const getVariantOffer = (
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  imageDomainPath: string,
  itemCountry: string
): GetVariantOfferReturn => {
  const variantMainImageSrc = getMainImageSrc(variant, imageDomainPath);
  const discount: IVoucherDiscount = variant.attributes.VoucherDiscount;
  const price = discount ? discount.discountedPrice : variant.price.value;

  return {
    '@type': 'Offer',
    name: `${product.brand.name} ${variant.name} ${
      variant.additionalInfo || ''
    }`,
    availability: getItemAvailability(variant),
    ...(variant.price && {
      price,
      priceCurrency: variant.price.currency,
    }),
    itemCountry,
    sku: variant.productCode,
    url: variant.url,
    ...(variantMainImageSrc && {
      image: variantMainImageSrc,
    }),
  };
};

export const getAggregateRating = (
  reviewOverview: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['reviewOverview']
): AggregateRating => ({
  '@type': 'AggregateRating',
  bestRating: '5',
  worstRating: '1',
  ratingValue: reviewOverview.rating,
  ratingCount: reviewOverview.count,
});
