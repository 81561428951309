import * as React from 'react';

import { LeftIconWrapper } from '../styled';

import { DeliveryDate, DeliveryInfoWrapper, Name } from './styled';

interface IDeliveryLineProps {
  showIcon: boolean;
  icon: JSX.Element;
  name: string;
  description: JSX.Element | string;
  isInactive: boolean;
}

export const DeliveryLine: React.FC<IDeliveryLineProps> = ({
  showIcon,
  icon,
  name,
  description,
  isInactive,
}) => {
  return (
    <>
      <LeftIconWrapper>
        {showIcon &&
          React.cloneElement(icon, {
            color: isInactive ? 'icon.disabled' : 'icon.primary',
            width: '20px',
            height: '20px',
          })}
      </LeftIconWrapper>

      <DeliveryInfoWrapper>
        <Name isInactive={isInactive}>{name}</Name>

        <DeliveryDate isInactive={isInactive}>{description}</DeliveryDate>
      </DeliveryInfoWrapper>
    </>
  );
};
