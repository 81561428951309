import * as React from 'react';

import { IStore } from '../../model';

import { StoreStockAvailabilityWrapper } from './styled';
import { usePickUpTime } from './usePickupTime';
import { useStockCount } from './useStockCount';

export const StoreStockAvailability = ({
  stockCount,
  earliestPickup,
}: IStore): JSX.Element => {
  const stockCountMessage = useStockCount(stockCount);
  const pickupTime = usePickUpTime(earliestPickup);
  return (
    <StoreStockAvailabilityWrapper>
      <span data-testid="stockCount">{stockCountMessage}</span> |{' '}
      <span data-testid="pickupTime">{pickupTime}</span>
    </StoreStockAvailabilityWrapper>
  );
};
