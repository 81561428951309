import styled, { css } from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import theme from '@themes/default';

export const VoucherDiscountInfoWrapper = styled.div<{
  showArrow: boolean;
  isUnderSelectBox?: boolean;
  hasContrastBg: boolean;
}>`
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0.75rem;
  border-radius: 2px;
  align-items: center;

  background-color: ${({ hasContrastBg }) =>
    hasContrastBg
      ? theme.color.background.highlight
      : theme.color.common.magenta95};

  margin: ${(props) =>
    props.isUnderSelectBox ? '0 0 0.75rem 0' : '0.75rem 0 0.75rem 0'};

  @media (min-width: ${breakpoints.md}) {
    margin: 1rem 0 0.75rem 0;
  }

  ${({ showArrow, hasContrastBg }) =>
    showArrow &&
    css`
      &::after {
        content: ' ';
        height: 1rem;
        width: 1rem;
        background-color: ${hasContrastBg
          ? theme.color.background.highlight
          : theme.color.common.magenta95};
        border-radius: 2px;
        position: absolute;
        top: -5px;
        right: 1.3rem;
        transform: rotate(45deg);
      }
    `};
`;

export const DiscountInfoDescription = styled.div`
  text-align: left;
  flex: 1;
`;

export const DiscountAmountWrapper = styled.span<{
  isStressed: boolean;
  hasContrastBg: boolean;
  color?: string;
}>`
  color: ${({ hasContrastBg, color = theme.color.text.highlight }) =>
    hasContrastBg ? theme.color.text.inverse : color};
  margin-right: 5px;

  ${theme.typography.labelRegular}

  ${({ isStressed }) =>
    isStressed
      ? css`
          line-height: 1.5rem;
          font-size: 1.25rem;
          font-weight: 500;
        `
      : css`
          font-size: 1.12rem;
          line-height: 1.3rem;
        `}
`;

export const CodeWrapper = styled.span<{
  hasContrastBg: boolean;
}>`
  text-transform: uppercase;
  color: ${({ hasContrastBg }) =>
    hasContrastBg ? theme.color.text.inverse : theme.color.text.primary};

  ${theme.typography.labelRegular}
`;

export const TermsWrapper = styled.div`
  font-size: 0.75rem;
  color: ${theme.color.text.tertiary};
  font-weight: normal;

  @media (min-width: ${breakpoints.sm}) {
    align-items: center;
  }
`;

export const TextWrapper = styled.div<{
  isStressed: boolean;
  hasContrastBg: boolean;
}>`
  margin-bottom: 0.125rem;
  color: ${({ hasContrastBg }) =>
    hasContrastBg ? theme.color.text.inverse : theme.color.text.secondary};
  display: flex;
  flex-wrap: wrap;

  ${theme.typography.labelRegular400}

  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const VoucherDiscountIconWrapper = styled.div`
  margin: 0 0 0 2rem;

  @media (min-width: ${breakpoints.sm}) {
    margin: 0 0 0 1rem;
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 0 0 0 2.5rem;
  }

  z-index: 1;
  > div {
    font-size: 0.9rem;
    font-weight: 500;
    width: 3rem;
    height: 3rem;
    > span {
      margin: 0;
    }
  }
`;

export const InfoIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  margin-left: 4px;
  position: relative;
  top: 2px;

  @media (min-width: ${breakpoints.sm}) {
    margin-top: 0;
  }
`;

export const InstructionTextWrapper = styled.span<{
  hasContrastBg: boolean;
}>`
  padding-right: 0.3rem;

  ${({ hasContrastBg }) =>
    css`
      ${theme.typography.labelSmall400}
      color: ${hasContrastBg
        ? theme.color.text.inverse
        : theme.color.text.secondary};
    `}
`;
