import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useQuery } from '@apollo/client';

import {
  GetExternalReviewLinkQuery,
  GetExternalReviewLinkQueryVariables,
} from '@notino/shared/definitions/types';

import { TabIds } from '@containers/ProductDetailContainer/ProductDetail/model';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useUser } from '@queries/useUser';
import { useTabSwitcher } from '@sharedComponents/TabSwitcher/context/TabSwitcherContext';

import externalReviewLinkQuery from './externalReviewLink.graphql';

export const useBvNotinoUserRedirect = (
  containerRef: React.MutableRefObject<HTMLDivElement>,
  catalogId: string
) => {
  const { user } = useUser();
  const location = useLocation();
  const { bazaarvoice_pd_components } = useFeatureFlags();
  const { activeTabId } = useTabSwitcher();

  const isNotinoEmail = user.info?.email?.endsWith('@notino.com');
  const urlContainsBvData = new URLSearchParams(location.search).has('bvdata');

  const shouldRedirect =
    bazaarvoice_pd_components &&
    isNotinoEmail &&
    !urlContainsBvData &&
    activeTabId === TabIds.Reviews;

  const { data, loading } = useQuery<
    GetExternalReviewLinkQuery,
    GetExternalReviewLinkQueryVariables
  >(externalReviewLinkQuery, {
    variables: { catalogId },
    skip: !shouldRedirect,
    ssr: false,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    const currentRef = containerRef.current;

    const handleReplaceAddReviewClickHandler = () => {
      const redirectWithBvDataOrMakeButtonDisabled = (e: Event) => {
        if (data?.externalReviewLink && !loading) {
          e.stopPropagation();
          window.location.assign(data.externalReviewLink);
        }
      };

      const setOpacityAndClickHandler = (el: HTMLElement) => {
        el.style.setProperty('opacity', loading ? '0' : '1', 'important');
        el.addEventListener('click', redirectWithBvDataOrMakeButtonDisabled);
      };

      const firstReviewButton = currentRef.querySelector('#first-to-write');
      const moreToWriteButton = currentRef.querySelector('.bv-write-review');

      if (firstReviewButton) {
        setOpacityAndClickHandler(firstReviewButton as HTMLElement);
      }
      if (moreToWriteButton) {
        setOpacityAndClickHandler(moreToWriteButton as HTMLElement);
      }
    };

    if (currentRef) {
      handleReplaceAddReviewClickHandler();
      const observer = new MutationObserver(handleReplaceAddReviewClickHandler);
      observer.observe(currentRef, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, [
    containerRef,
    user.info?.email,
    bazaarvoice_pd_components,
    shouldRedirect,
    data?.externalReviewLink,
    loading,
  ]);
};
