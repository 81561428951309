import * as Sentry from '@sentry/browser';
import { Integration, Event, EventProcessor } from '@sentry/types';
import {
  SENTRY_IGNORE_ERROR_RULES,
  SENTRY_IGNORE_URL_RULES,
} from '@utils/sentry/constants';

const eventProcessor: EventProcessor = (event: Event) => {
  const scripts = document.getElementsByTagName('script');

  const defineScript = Array.from(scripts).find((script) => {
    return script.getAttribute('src')?.includes('define.js');
  });

  const areAsyncScripts = Array.from(scripts).some((script) => {
    const scriptDataFragments = script.getAttribute('data-fragments');

    return script.async && scriptDataFragments;
  });

  const version = defineScript
    ? defineScript.src.match(/(\d+\.\d+\.\d+)/)[0]
    : null;

  event.extra = {
    ...(event.extra || {}),
    DefineVersionFunction: version,
    AsyncSharedLibraries: areAsyncScripts,
  };

  return event;
};

const sentryConfig = ({
  dsn,
  environment,
  tracingRate,
}): Sentry.BrowserOptions => {
  const isLocalhost = environment === 'localhost';
  return {
    dsn,
    enabled: true,
    debug: isLocalhost,
    environment,
    release: `nushopweb@${process.env.npm_package_version}`,
    ignoreErrors: SENTRY_IGNORE_ERROR_RULES,
    denyUrls: SENTRY_IGNORE_URL_RULES,
    // this seems to be the right way of doing it
    // https://github.com/getsentry/sentry-javascript/issues/1652
    integrations: (integrations) => {
      const enrichedErrorReporting: Integration = {
        name: 'EnrichedErrorReporting',
        setupOnce: (callback) => callback(eventProcessor),
      };
      // remove globalhandlers so sentry doesn't contain errors we can't solve
      return [
        ...integrations.filter((i) => i.name !== 'GlobalHandlers'),
        enrichedErrorReporting,
      ];
    },
    beforeSend: (event, hint) => {
      if (isLocalhost) {
        // eslint-disable-next-line no-console
        console.error(
          'Sentry error',
          hint.originalException || hint.syntheticException
        );
        return null; // this drops the event and nothing will be sent to sentry
      }
      return event;
    },
    attachStacktrace: true,
    // Each transaction has a 20% chance of being sent to Sentry
    // For canary, we want to send all errors
    tracesSampleRate: tracingRate,
  };
};

export const initSentry = ({
  dsn,
  environment,
  tracingRate,
  shopId,
}: {
  dsn: string;
  environment: string;
  tracingRate: number;
  shopId: string;
}) => {
  Sentry.init(
    sentryConfig({
      dsn,
      environment,
      tracingRate,
    })
  );
  Sentry.setTag('shopId', shopId);

  if (window.notinoUser) {
    Sentry.setUser({
      id: window.notinoUser.uid,
      role: window.notinoUser.role,
    });
  }
};
