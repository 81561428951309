import * as React from 'react';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import PriceLabel from '@components/PriceLabel';
import { Tags } from '@components/Tags';
import { ILocale } from '@containers/App/model';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { VariantDisplayTypes } from '@containers/ProductListing/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { variantInfo, dataLabelsProp } from '@helpers/utils';

import { trackVariantClick } from '../../tracking';
import { HandleTransitionClick } from '../../Variants/useVariantTransition';

import {
  Row,
  ImgWrapper,
  Price,
  PriceBesideBelow,
  PriceWrapper,
  PriceSymbol,
  Name,
  Img,
  DualPriceWrapper,
  Slash,
} from './styled';
import { VariantDiscount } from './VariantDiscount';

interface IVariantItemProps {
  variant: CatalogVariantFragmentFragment;
  locale: ILocale;
  isSelected: boolean;
  onClick?: HandleTransitionClick;
}

export const VariantItem: React.FC<IVariantItemProps> = ({
  variant,
  locale,
  isSelected,
  onClick,
}) => {
  const { getTimeFromInit } = useTrackingContext();
  const {
    isDualPriceForCroatia,
    getRightSidePrice,
    getLeftSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
  } = useIsDualPriceForCroatia();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(variant, e);
    trackVariantClick(getTimeFromInit(), VariantDisplayTypes.Select);
  };

  const inStock = variant.stockAvailability.code !== IStock.outOfStock;

  return (
    <Row
      to={variant.url}
      replace={true}
      isSelected={isSelected}
      notInStock={!inStock}
      id={`pd-variant-${variant.webId}`}
      data-testid={`pd-variant-${variant.webId}`}
      onClick={handleClick}
      {...dataLabelsProp(variant.attributes)}
    >
      <ImgWrapper>
        <Img {...variant.media.mainImage} sourceKey="thumb" />
      </ImgWrapper>

      <PriceBesideBelow>
        <Name>
          <Tags
            small={true}
            multiple={true}
            attributes={variant.attributes}
            stockAvailability={variant.stockAvailability.code}
          />
          <span className="pd-variant-label">{variantInfo(variant)}</span>
        </Name>

        {inStock && (
          <PriceWrapper>
            <DualPriceWrapper>
              {isDualPriceForCroatia && (
                <>
                  <Price isSelected={isSelected}>
                    {getLeftSideFormattedPrice(variant.price.value)}
                    <PriceSymbol>{leftSideCurrency}</PriceSymbol>
                  </Price>
                  <Slash>/</Slash>
                </>
              )}

              <PriceLabel
                price={
                  isDualPriceForCroatia
                    ? {
                        value: getRightSidePrice(variant.price.value),
                      }
                    : variant.price
                }
                wrapperComponent={(props) => (
                  <Price isSelected={isSelected} {...props} />
                )}
                currencyComponent={PriceSymbol}
                currencySymbolOverride={
                  isDualPriceForCroatia ? rightSideCurrency : undefined
                }
              />
            </DualPriceWrapper>

            <VariantDiscount variant={variant} locale={locale} />
          </PriceWrapper>
        )}
      </PriceBesideBelow>
    </Row>
  );
};
