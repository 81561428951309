import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackTryItFirstChanged = (checked: boolean, timing: number) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      mode: checked ? 'on' : 'off',
      interaction: 'checkbox',
      action: checked ? 'select_try_it_first' : 'deselect_try_it_first',
      name: 'try_it_first',
      type: 'try_it_first',
      timing,
    },
    _clear: true,
  });
};
