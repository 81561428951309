import { AvailabilityState } from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useProductView } from '@context/product-view';

const REVIEWS_MIN_COUNT = 2;
const RATING_MIN_COUNT = 3;

export const useShouldShowReviewPage = (
  textReviewCount: number,
  ratingSummary: number
) => {
  const { pd_has_all_reviews_page } = useFeatureFlags();
  const { variant } = useProductView();

  const isNotDisabled =
    variant.availability.state !== AvailabilityState.Disabled;

  return (
    textReviewCount >= REVIEWS_MIN_COUNT &&
    ratingSummary >= RATING_MIN_COUNT &&
    pd_has_all_reviews_page &&
    isNotDisabled
  );
};
