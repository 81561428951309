import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const Background = styled.a`
  display: inline;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;

  position: relative;
  z-index: 1;

  img {
    height: 1.5625rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    img {
      height: 3.125rem;
    }
    margin-top: 1.25rem;
    margin-bottom: -1.25rem;
  }
`;
