import * as React from 'react';

import { IconRegularClose } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';

import ErrorBoundary from '@components/ErrorBoundary';
import PriceLabel from '@components/PriceLabel';
import { IVariantsProps } from '@containers/ProductDetailContainer/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { variantInfo, dataLabelsProp } from '@helpers/utils';
import { HorizontalChevron } from '@sharedComponents/Icons/HorizontalChevron';

import { useIsDualPriceForCroatia } from '../../hooks/useIsDualPriceForCroatia';
import { VARIANT_CHEVRON_SIZE } from '../ShadesDrawer/constants';
import { useVariantTransition } from '../Variants/useVariantTransition';
import { useHasStressOnVoucherDiscount } from '../VoucherDiscount/useHasStressOnVoucherDiscount';

import { ExpandedVariants } from './ExpandedVariants';
import {
  SectionWrapper,
  Wrapper,
  Select,
  SelectedVariantName,
  Text,
  CloseIcon as CloseIconWrapper,
  CloseWrapper,
  ChevronIconWrapper,
  PriceAndName,
  Prices,
  PriceWrapper,
  CurrencyWrapper,
  DualPriceWrapper,
  DualPrice,
  DualPriceSplitter,
  SmallerPriceWrapper,
  SmallerCurrencyWrapper,
} from './styled';
import { TagDiscount } from './TagDiscount';
import { trackSelectOpen } from './tracking';
import { VariantItem } from './VariantItem';

export interface IVariantsInSelectboxProps extends IVariantsProps {
  renderTagDiscount: boolean;
}

export const VariantsInSelectbox = ({
  variants,
  selectedId: _selectedId,
  locale,
  renderTagDiscount,
}: IVariantsInSelectboxProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { getTimeFromInit } = useTrackingContext();
  const {
    isDualPriceForCroatia,
    getLeftSideFormattedPrice,
    getRightSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
  } = useIsDualPriceForCroatia();

  const { handleClick, selectedId } = useVariantTransition(_selectedId);

  const handleExpand = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isExpanded) {
      trackSelectOpen(getTimeFromInit());
    }
    setIsExpanded(!isExpanded);
  };

  React.useEffect(() => {
    setIsExpanded(false);
  }, [variants, selectedId]);

  const selectedVariant = variants.find(
    (variant) => variant.webId === selectedId
  );
  const text = variantInfo(selectedVariant);
  const inStock = selectedVariant.stockAvailability.code !== IStock.outOfStock;
  const isSmall = useHasStressOnVoucherDiscount();

  return (
    <ErrorBoundary>
      <SectionWrapper
        data-testid="pd-variants-select-box"
        id="pdVariantsSelectbox"
      >
        {renderTagDiscount && <TagDiscount variant={selectedVariant} />}
        <Wrapper>
          <Select
            onClick={handleExpand}
            className="pd-variant-selected"
            aria-live="assertive"
            tabIndex={0}
            isExpanded={isExpanded}
            {...dataLabelsProp(selectedVariant.attributes)}
          >
            <PriceAndName>
              {inStock && !isDualPriceForCroatia && (
                <Prices>
                  <PriceLabel
                    id="pd-price"
                    price={selectedVariant.price}
                    wrapperComponent={
                      isSmall ? SmallerPriceWrapper : PriceWrapper
                    }
                    currencyComponent={
                      isSmall ? SmallerCurrencyWrapper : CurrencyWrapper
                    }
                  />
                </Prices>
              )}

              {inStock && isDualPriceForCroatia && (
                <Prices>
                  <DualPriceWrapper data-testid="pd-price" id="pd-dual-price">
                    <DualPrice isSmall={isSmall}>
                      {getLeftSideFormattedPrice(selectedVariant.price.value)}{' '}
                      <span>{leftSideCurrency}</span>
                    </DualPrice>
                    <DualPriceSplitter isSmall={isSmall}>/</DualPriceSplitter>
                    <DualPrice isSmall={isSmall}>
                      {getRightSideFormattedPrice(selectedVariant.price.value)}{' '}
                      <span>{rightSideCurrency}</span>
                    </DualPrice>
                  </DualPriceWrapper>
                </Prices>
              )}

              <SelectedVariantName>
                <Text title={text}>{text}</Text>
              </SelectedVariantName>
            </PriceAndName>
            <ChevronIconWrapper type="button">
              <HorizontalChevron
                isOpened={isExpanded}
                width={VARIANT_CHEVRON_SIZE}
                height={VARIANT_CHEVRON_SIZE}
                color="icon.secondary"
              />
            </ChevronIconWrapper>
          </Select>

          {isExpanded && (
            <ExpandedVariants
              isExpanded={isExpanded}
              onOuterClick={handleExpand}
            >
              <CloseWrapper>
                <CloseIconWrapper onClick={handleExpand}>
                  <IconRegularClose
                    color="icon.tertiary"
                    width="1.25rem"
                    height="1.25rem"
                  />
                </CloseIconWrapper>
              </CloseWrapper>
              {variants.map((variant) => (
                <VariantItem
                  variant={variant}
                  locale={locale}
                  isSelected={variant.webId === selectedId}
                  key={variant.webId}
                  onClick={handleClick}
                />
              ))}
            </ExpandedVariants>
          )}
        </Wrapper>
      </SectionWrapper>
    </ErrorBoundary>
  );
};
