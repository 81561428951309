/*
 * ProductBaseInfo messages
 */
import { defineMessages } from 'react-intl';
export default defineMessages({
  attributeNew: {
    id: 'attribute.new',
    defaultMessage: 'Nové',
  },
  attributeAction: {
    id: 'attribute.action',
    defaultMessage: 'Akce',
  },
  attributeClearance: {
    id: 'attribute.clearance',
    defaultMessage: 'Výprodej',
  },
  attributeFreeDelivery: {
    id: 'attribute.freedelivery',
    defaultMessage: 'Doprava zdarma',
  },
  attributeDamage: {
    id: 'attribute.damage',
    defaultMessage: 'Speciální sleva',
  },
  attributeDamaged: {
    id: 'attribute.damaged',
    defaultMessage: 'Poškozený obal',
  },
  attributeReturned: {
    id: 'attribute.returned',
    defaultMessage: 'Vráceno',
  },
});
