import { defineMessages } from 'react-intl';

import { EPictograms } from './model';

export const pictogramTitles = defineMessages({
  [EPictograms.BIO]: {
    id: 'pd.about.pictogram.IsBio',
    defaultMessage: 'Bio',
  },
  [EPictograms.NATURAL]: {
    id: 'pd.about.pictogram.IsNatural',
    defaultMessage: 'Přírodní',
  },
  [EPictograms.SPF]: {
    id: 'pd.about.pictogram.WithSpf',
    defaultMessage: 'SPF',
  },
  [EPictograms.SUSTAINABLE]: {
    id: 'pd.about.pictogram.IsSustainable',
    defaultMessage: 'Udržitelné',
  },
  [EPictograms.TRAVEL_PACKING]: {
    id: 'pd.about.pictogram.IsTravelPacking',
    defaultMessage: 'Cestovní balení',
  },
  [EPictograms.VEGAN]: {
    id: 'pd.about.pictogram.IsVegan',
    defaultMessage: 'Vegan',
  },
  [EPictograms.WITHOUT_ALCOHOL]: {
    id: 'pd.about.pictogram.WithoutAlcohol',
    defaultMessage: 'Bez alkoholu',
  },
  [EPictograms.WITHOUT_ALUMINIUM]: {
    id: 'pd.about.pictogram.WithoutAluminium',
    defaultMessage: 'Bez hliníku',
  },
  [EPictograms.WITHOUT_PERFUME]: {
    id: 'pd.about.pictogram.WithoutPerfume',
    defaultMessage: 'Bez parfemace',
  },
  [EPictograms.WITHOUT_SILICONE]: {
    id: 'pd.about.pictogram.WithoutSilicone',
    defaultMessage: 'Bez silikonů',
  },
  [EPictograms.WITHOUT_SULFATE]: {
    id: 'pd.about.pictogram.WithoutSulfate',
    defaultMessage: 'Bez sulfátů',
  },
});

export const pictogramTooltip = defineMessages({
  [EPictograms.BIO]: {
    id: 'pd.about.pictogram.IsBio.tooltip',
    defaultMessage: 'Produkt má certifikaci o bio kvalitě.',
  },
  [EPictograms.NATURAL]: {
    id: 'pd.about.pictogram.IsNatural.tooltip',
    defaultMessage: 'Obsahuje alespoň 85 % přírodních složek.',
  },
  [EPictograms.SPF]: {
    id: 'pd.about.pictogram.WithSpf.tooltip',
    defaultMessage: 'Produkt chrání před ultrafialovým zářením.',
  },
  [EPictograms.SUSTAINABLE]: {
    id: 'pd.about.pictogram.IsSustainable.tooltip',
    defaultMessage: 'Produkt je šetrný k životnímu prostředí.',
  },
  [EPictograms.TRAVEL_PACKING]: {
    id: 'pd.about.pictogram.IsTravelPacking.tooltip',
    defaultMessage: 'Praktická velikost vhodná na cesty.',
  },
  [EPictograms.VEGAN]: {
    id: 'pd.about.pictogram.IsVegan.tooltip',
    defaultMessage: 'Neobsahuje žádné ingredience živočišného původu.',
  },
  [EPictograms.WITHOUT_ALCOHOL]: {
    id: 'pd.about.pictogram.WithoutAlcohol.tooltip',
    defaultMessage: 'Ve složení nenajdete alkohol.',
  },
  [EPictograms.WITHOUT_ALUMINIUM]: {
    id: 'pd.about.pictogram.WithoutAluminium.tooltip',
    defaultMessage: 'Neobsahuje žádné deriváty hliníku.',
  },
  [EPictograms.WITHOUT_PERFUME]: {
    id: 'pd.about.pictogram.WithoutPerfume.tooltip',
    defaultMessage: 'Produkt je zcela bez vůně.',
  },
  [EPictograms.WITHOUT_SILICONE]: {
    id: 'pd.about.pictogram.WithoutSilicone.tooltip',
    defaultMessage: 'Neobsahuje vůbec žádné silikony.',
  },
  [EPictograms.WITHOUT_SULFATE]: {
    id: 'pd.about.pictogram.WithoutSulfate.tooltip',
    defaultMessage: 'Neobsahuje sulfáty.',
  },
});

export const pictogramMoreInfoLinkText = {
  id: 'pd.about.pictogram.link.text',
  defaultMessage: 'Více informací',
};

export const pictogramMoreInfoPathname = {
  id: 'pd.about.pictogram.link.pathname',
  defaultMessage: 'null',
};
