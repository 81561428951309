import styled, { css } from 'styled-components';

import {
  breakpoints,
  ActionButtonWithConfirmation,
  theme,
} from '@notino/react-styleguide';

const BuyButtonWrapableCSS = css`
  white-space: normal;
  line-height: 1.4;
`;

export const StyledBuyButton = styled(ActionButtonWithConfirmation)<{
  wrapable?: boolean;
}>`
  text-transform: none !important;
  height: ${({ wrapable }) => (wrapable ? 'fit-content' : '2rem')};
  ${({ wrapable }) => (wrapable ? BuyButtonWrapableCSS : '')};

  @media (min-width: ${breakpoints.xs}) {
    width: 100% !important;
    text-align: center;
  }

  ${theme.typography.labelRegular}
  span {
    font-weight: 500 !important;
  }
`;
