import { ComponentType, PropsWithChildren } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ButtonModel, IModalProps } from '@notino/react-styleguide';
import { CartServiceInput } from '@notino/shared/definitions/types';

import {
  IModal,
  IAddToCartResult,
} from '@sharedComponents/AddToCartButton/context/model';

export interface IWrap {
  wrapable?: boolean;
}

export interface ProductInfo {
  id: string;
  attributes?: ProductAttributes;
  productCode: string;
  name: string;
  variantName: string;
  masterId: string | number;
}

interface ProductAttributes {
  IsForProfessionals?: boolean;
  Damage?: {
    volumeInPercent: number;
  };
}

export interface IAddToCartButtonProps extends IWrap {
  product: ProductInfo;
  count: number;
  services?: CartServiceInput[];
  buttonWrapper?: ComponentType<PropsWithChildren>;
  buttonWrapperProps?: Record<string, unknown>;
  showAddToCartModal: boolean;
  disableRedirect?: boolean;
  onClosingModal?: () => void;
  buttonElementId?: string;
  buttonElementClassName?: string;
  buttonStyle?: ButtonModel.Styles;
  buttonSize?: ButtonModel.Sizes;
  buttonText?: MessageDescriptor;
  onProductAdded?: (products: ProductWithCount[]) => void | Promise<void>;
  withLegacyAddToCart?: boolean;
  onProductAddFailed?: (
    message: string,
    products: ProductWithCount[]
  ) => void | Promise<void>;
  getModalAfterAddToCart?: (
    props: IModal & IAddToCartResult & { products: ProductWithCount[] }
  ) => [JSX.Element, IModalProps];
}

export enum PostAddToCartAction {
  REDIRECT,
  SHOW_MODAL,
  ONLY_ADD,
}

export enum AddToCartProductErrors {
  SC_ECO_PACKAGING_ADD_ERROR = 'SC_ECO_PACKAGING_ADD_ERROR',
  SC_INSURANCE_ADD_ERROR = 'SC_INSURANCE_ADD_ERROR',
  SC_CART_PRICE_OUT_OF_LIMIT = 'SC_CART_PRICE_OUT_OF_LIMIT',
  SC_SOLD_PRODUCT = 'SC_SOLD_PRODUCT',
  SC_ONLINE_VOUCHER_AND_PRODUCT = 'SC_ONLINE_VOUCHER_AND_PRODUCT',
  SC_MAX_PRODUCTS_IN_CART_ERROR = 'SC_MAX_PRODUCTS_IN_CART_ERROR',
}

export type ProductWithCount = IAddToCartButtonProps['product'] & {
  count: number;
};
