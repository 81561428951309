import * as React from 'react';
import { useIntl } from 'react-intl';
import { JsonLd } from 'react-schemaorg';

import { BreadcrumbList } from 'schema-dts';

import messages from '@containers/ProductDetailContainer/messages';

import { IBreadcrumb } from './model';
import { prepareItemListElement } from './utils';

interface IBreadcrumbsJsonLdProps {
  items: IBreadcrumb[];
}

export const BreadcrumbsJsonLd = ({ items }: IBreadcrumbsJsonLdProps) => {
  const { formatMessage } = useIntl();

  return (
    <JsonLd<BreadcrumbList>
      item={{
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: prepareItemListElement([
          { link: '/', name: formatMessage(messages.mainPage) },
          ...items,
        ]),
      }}
    />
  );
};
