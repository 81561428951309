import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  discountAmount: {
    id: 'pd.black.friday.discount.highlighted',
    defaultMessage: 'Sleva 20% navíc ',
  },
  code: {
    id: 'pd.black.friday.code',
    defaultMessage: 'SUMMER',
  },
  label: {
    id: 'pd.black.friday.label',
    defaultMessage: 'Black Friday',
  },
  blackFridayLabel: {
    id: 'pd.black.friday.original.label',
    defaultMessage: 'Black Friday',
  },
  sbfLabel: {
    id: 'pd.summer.black.friday.label',
    defaultMessage: 'Summer Black Friday',
  },
});
