import { useQuery } from '@apollo/client';

import { GetSettingsQuery } from '@notino/shared/definitions/types';

import getSettingsQuery from './queries/getSettings.graphql';

const DEFAULT: GetSettingsQuery = {
  Settings: {
    showUnitPrices: null,
    __typename: 'Settings',
    isNewShoppingCartEndpointEnabled: null,
  },
};

/**
 * Feel free to extend this method to get more values if needed in future
 */
export const useSettings = (): GetSettingsQuery | Record<string, never> => {
  const { data, error, loading } = useQuery<GetSettingsQuery>(getSettingsQuery);

  if (error || loading || !data) {
    return DEFAULT;
  }

  return data;
};
