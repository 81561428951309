import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { ButtonModel } from '@notino/react-styleguide';

import { RequestState } from '@containers/App/model';
import { noop } from '@utils/noop';

import { messages } from './messages';
import { StyledBuyButton } from './styled';

interface IBuyButtonWithDefaultsProps {
  buttonStyle?: ButtonModel.Styles;
  buttonSize?: ButtonModel.Sizes;
  buttonElementId?: string;
  buttonElementClassName?: string;
  buttonType?: 'button' | 'submit';
  wrapable: boolean;
  buttonText?: MessageDescriptor;
  sendState?: RequestState;
  onMouseOver?: () => void;
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
}

const ERROR_STATE_ARRAY = [RequestState.FAILED, RequestState.RETRYING];
const LOADING_STATE_ARRAY = [RequestState.IN_PROGRESS, RequestState.RETRYING];

export const BuyButtonWithDefaults: React.FC<IBuyButtonWithDefaultsProps> =
  React.memo(
    ({
      buttonElementId,
      buttonElementClassName,
      wrapable,
      sendState = null,
      buttonStyle = ButtonModel.Styles.primary,
      buttonText = messages.addToCart,
      buttonSize = ButtonModel.Sizes.medium,
      buttonType = 'button',
      onMouseOver = noop,
      onClick = noop,
    }) => {
      return (
        <StyledBuyButton
          onMouseOver={onMouseOver}
          buttonStyle={buttonStyle}
          buttonSize={buttonSize}
          type={buttonType}
          onClick={onClick}
          loading={LOADING_STATE_ARRAY.includes(sendState)}
          error={ERROR_STATE_ARRAY.includes(sendState)}
          id={buttonElementId}
          className={buttonElementClassName}
          wrapable={wrapable}
          data-testid={buttonElementId}
        >
          <FormattedMessage {...buttonText} />
        </StyledBuyButton>
      );
    }
  );
