import * as React from 'react';
import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import {
  GiftType,
  GetServicesIncludedQuery,
  GetCatalogProductViewQuery,
  AvailabilityState,
} from '@notino/shared/definitions/types';

import getServicesIncludedQuery from '@containers/ProductDetailContainer/queries/servicesIncluded.graphql';

import { getEngravingAvailability, getTryItFirstAvailability } from '../utils';

export const useCartServices = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
) => {
  const productsIdsURI = React.useMemo(
    () => product.variants.map(({ webId }) => `productIds=${webId}`).join('&'),
    [product.variants]
  );

  const additionalServices = useQuery<GetServicesIncludedQuery>(
    getServicesIncludedQuery,
    {
      skip: variant.availability.state !== AvailabilityState.CanBeBought,
      ssr: false,
      variables: {
        productsIds: productsIdsURI,
      },
    }
  );

  const engravingAvailable = React.useMemo(
    () =>
      additionalServices &&
      additionalServices.data?.CartServicesIncluded &&
      getEngravingAvailability(
        additionalServices.data.CartServicesIncluded?.engravingShow,
        variant.features.engraving,
        additionalServices.error
      ),
    [additionalServices, variant.features.engraving]
  );

  const tryItFirstCampaigns = React.useMemo(() => {
    const { campaigns = [] } = product;
    return campaigns.filter(
      (campaign) => campaign && campaign.giftType === GiftType.TryItFirst
    );
  }, [product]);

  const tryItFirstAvailable = React.useMemo(
    () =>
      additionalServices &&
      additionalServices.data?.CartServicesIncluded &&
      getTryItFirstAvailability(
        additionalServices.data.CartServicesIncluded?.tryFirstShow,
        tryItFirstCampaigns,
        variant,
        additionalServices.error
      ),
    [additionalServices, variant, tryItFirstCampaigns]
  );

  return useMemo(
    () => ({
      additionalServices,
      engravingAvailable,
      tryItFirstAvailable,
    }),
    [additionalServices, engravingAvailable, tryItFirstAvailable]
  );
};
