import * as React from 'react';

import { arrayFromNumber } from '@helpers/utils';

import { SortBox } from '../ReviewItems/styled';

import { ReviewsWrapper, SkeletonElement, Row } from './styled';

export const ReviewsSkeleton: React.FC = () => {
  return (
    <>
      <SortBox />
      {arrayFromNumber(5).map((i) => (
        <ReviewsWrapper key={i} width={100}>
          <SkeletonElement marginY={0.5} height={1} />
          <Row>
            <SkeletonElement width={4} height={0.75} />
            <SkeletonElement width={4} height={0.75} marginX={0.75} />
            <SkeletonElement width={10} height={0.75} marginX={0.75} />
            <SkeletonElement width={3} height={0.75} />
          </Row>
        </ReviewsWrapper>
      ))}
    </>
  );
};
