import * as Sentry from '@sentry/browser';

interface MetaInformation {
  keywords: string;
  description: string;
  title: string;
}

export const replaceMetaInformation = ({
  keywords,
  description,
  title,
}: MetaInformation) => {
  try {
    const metaCollection =
      typeof document !== 'undefined' && document.getElementsByTagName('meta');
    if (metaCollection?.length > 0) {
      // eslint-disable-next-line
     const keywordsElement = metaCollection['keywords'];
      if (keywordsElement) {
        keywordsElement.content = keywords;
      }
      // eslint-disable-next-line
     const descriptionElement = metaCollection['description'];
      if (descriptionElement) {
        descriptionElement.content = description;
      }

      document.title = title;
    }
  } catch (e) {
    Sentry.captureMessage('Could not replace Meta Information', e);
  }
};
