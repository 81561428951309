import { createGlobalStyle, breakpoints, css } from '@notino/react-styleguide';

import theme from '@themes/default';

const listCss = css`
  ul {
    list-style-type: none;
  }
  li {
    position: relative;
    padding-left: 1.2rem;
    margin-bottom: 0;

    &:before {
      margin-top: 0;
      content: '';
      display: inline-block;
      height: 1em;
      width: 1.2em;
      background: url("data:image/svg+xml;utf8,<svg fill='${theme.color.icon.highlight.replace(
          '#',
          '@23'
        )}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 64'> <polygon points='2.96 7.96 0.13 10.79 21.34 32 0.13 53.21 2.96 56.04 27 32 2.96 7.96'/></svg>")
        50% 50% no-repeat;
      position: absolute;
      top: 0.3em;
      left: 0;
    }
  }
`;

const newListCss = css`
  p,
  ul,
  .expandable-info {
    ${theme.typography.bodyRegular};

    @media (min-width: ${breakpoints.md}) {
      ${theme.typography.bodyLarge}
    }
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${listCss}
`;

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
  }

  html {
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fontBaseSize}px;
    scroll-behavior: smooth;
  }

  .app * {
    box-sizing: border-box;
  }

  .app:not(.accesibility) * {
    outline: none !important;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .no-js .placeholder {
    display: none !important;
  }

  a {
    color: ${theme.color.text.secondary};
    transition: color 0.3s;
    &:hover {
      color: ${theme.color.text.primary};
    }
  }

  #pd-description-text {
    p {
      &:first-child {
        strong {
          margin-bottom: 0;
        }
      }

      strong {
        margin-bottom: 0.25rem;
      }
    }
  }
  #pd-description-wrapper,
  #pdOthersTab {
    a {
      color: ${theme.color.text.primary};
    }

    p:not(:first-child) {
      strong {
        margin-top: 2.5rem;
      }
    }

    strong {
      display: inline-block;

      margin-bottom: 0.75rem;

      ${theme.typography.titleSmall};

      @media (min-width: ${breakpoints.lg}) {
        ${theme.typography.titleMedium}
      }
    }

    ${newListCss};
  }

  #pd-composition-wrapper,
  #pdOthersTab {
    ${newListCss}
  }
`;
