import * as React from 'react';

import {
  IconRegularChevronLeft,
  IconRegularChevronRight,
} from '@notino/react-styleguide';

import { IImageProperties } from '@helpers/utils';
import { useArrows } from '@hooks/useArrows';
import { Fade, Arrow } from '@hooks/useArrows/styled';

import { useProductDetailContext } from '../../ProductDetailContext';

import { Image } from './Image';
import { ImageWrapper, ScrollWrapper, Wrapper } from './ScrollThumbs.styles';

interface IProps {
  imageIndex: number;
  handleImageChange: (index: number) => void;
  handleImageClick?: (index: number) => React.MouseEventHandler<HTMLElement>;
  imagesAttrs: IImageProperties[];
  dataTestId?: string;
  delayInteraction?: boolean;
}

export const ScrollThumbs = ({
  imageIndex,
  handleImageChange,
  handleImageClick,
  imagesAttrs,
  dataTestId,
  delayInteraction,
}: IProps) => {
  const { currentVariant } = useProductDetailContext();
  const { containerRef, scrollTo, showLeftArrow, showRightArrow } = useArrows([
    currentVariant.webId,
  ]);

  const canInteractRef = React.useRef(!delayInteraction);
  React.useEffect(() => {
    const id = setTimeout(() => (canInteractRef.current = true), 1000);
    return () => clearTimeout(id);
  }, []);

  return (
    <Wrapper data-testid={dataTestId}>
      {showLeftArrow && (
        <>
          <Arrow position="left" onClick={() => scrollTo('left')} role="button">
            <IconRegularChevronLeft
              color="icon.primary"
              height="1.25rem"
              width="1.25rem"
            />
          </Arrow>
          <Fade position="left" />
        </>
      )}

      <ScrollWrapper ref={containerRef}>
        {imagesAttrs.map((imageAttr, index) => (
          <ImageWrapper key={imageAttr.src}>
            <Image
              {...imageAttr}
              isSelected={index === imageIndex}
              onClick={handleImageClick?.(index)}
              onMouseOver={() =>
                canInteractRef.current && handleImageChange(index)
              }
            />
          </ImageWrapper>
        ))}
      </ScrollWrapper>

      {showRightArrow && (
        <>
          <Arrow
            position="right"
            onClick={() => scrollTo('right')}
            role="button"
          >
            <IconRegularChevronRight
              color="icon.primary"
              height="1.25rem"
              width="1.25rem"
            />
          </Arrow>
          <Fade position="right" />
        </>
      )}
    </Wrapper>
  );
};
