import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  engravingYourText: {
    id: 'engraving.enter.your.text',
    defaultMessage: 'Zadejte Váš text',
  },
  engravingMaxCharactersv2: {
    id: 'engraving.enter.max.characters.v2',
    defaultMessage: 'max. znaků: {maxChars}',
  },
  engravingInvalidCharacters: {
    id: 'engraving.invalid.characters',
    defaultMessage: 'Je možné použít pouze znaky [a-ž, A-Ž, 0-9]',
  },
  engravingSave: {
    id: 'engraving.save',
    defaultMessage: 'OK',
  },
  addEngraving: {
    id: 'engraving.add',
    defaultMessage: 'Přidat gravírování',
  },
  engravingPreview: {
    id: 'engraving.preview',
    defaultMessage: 'Foto produktu s gravírovaným textem je pouze ilustrační.',
  },
  engravingCheckboxLabel: {
    id: 'engraving.checkbox.label',
    defaultMessage: 'Vygravírovat vlastní text za {price}',
  },
  engravingCheckboxLabelDesc: {
    id: 'engraving.checkbox.label.description.with.link',
    defaultMessage:
      'Nechte si na produkt laserem vyrýt vlastní text a vytvořte tak dárek od srdce. Dali jsme pro vás dohromady <a>tipy</a> na ta nejhezčí věnování.',
  },
  engravingSpecialPageHref: {
    id: 'engraving.special.page.href',
    defaultMessage: 'https://www.notino.cz/gravirovani/',
  },
  engravingEdit: {
    id: 'engraving.edit',
    defaultMessage: 'upravit',
  },
});
