import { GetProductsByIdBatchedQuery } from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability } from '@notino/web-tracking';
import { Product } from '@notino/web-tracking/dist/types/package/ga4/events/products';

import { getRenderableDiscoBoxSampleId } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/DiscoveryBoxSelfChoice';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { FeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';

export type WishlistActionLocation = 'product_detail' | 'wishlist' | 'list';

interface HandleTrackWishlistOperationParams {
  addedToWishlist: boolean;
  shouldShowSkinAnalyzer: boolean;
  vpProduct?: GetProductsByIdBatchedQuery['vpProductByIds'][number];
  product?: ReturnType<typeof useProductDetailContext>['product'];
  variant?: ReturnType<typeof useProductDetailContext>['currentVariant'];
  timing: number;
  actionLocation: WishlistActionLocation;
  additionalPromoLabels?: string[];
  additionalGtm?: Partial<Product>;
  flags: FeatureFlags;
  engravingAvailable: AdditionalServicesAvailability;
  tryItFirstAvailable: AdditionalServicesAvailability;
  modifaceVariants: Record<string, unknown> | null;
}

export const handleTrackWishlistOperation = ({
  addedToWishlist: wishlisted,
  vpProduct,
  product,
  variant,
  timing,
  actionLocation,
  additionalGtm = {},
  additionalPromoLabels = [],
  flags,
  engravingAvailable,
  tryItFirstAvailable,
  modifaceVariants,
  shouldShowSkinAnalyzer,
}: HandleTrackWishlistOperationParams) => {
  const additionalData: Partial<Product> = Object.entries(additionalGtm).reduce(
    (acc, [key, value]) =>
      value === undefined ? acc : { ...acc, [key]: value },
    {}
  );

  const wither = ProductEventWither()
    .withVpProduct(vpProduct ? { ...vpProduct, wishlisted } : undefined)
    .withProduct(product)
    .withVariant(variant ? { ...variant, wishlisted } : undefined)
    .withAdditionalPromoLabels(additionalPromoLabels)
    .withAdditionalData(additionalData);

  if (product && variant) {
    wither.withServices({
      engravingAvailable,
      tryItFirstAvailable,
      modifaceVariants,
      skinAnalyzer: shouldShowSkinAnalyzer,
      discoveryBoxAvailable: Boolean(
        getRenderableDiscoBoxSampleId(product, variant, flags)
      ),
    });
  }
  const gaProduct = wither.build();

  if (wishlisted) {
    dispatchTrackingEvent({
      event: 'add_to_wishlist',
      wishlist: {
        action: 'product_added_to_wishlist',
        interaction: 'click_add',
        type: actionLocation,
        timing,
        products: [gaProduct],
      },
      _clear: true,
    });
  } else {
    dispatchTrackingEvent({
      event: 'remove_from_wishlist',
      wishlist: {
        action: 'product_removed_from_wishlist',
        interaction: 'click_remove',
        type: actionLocation,
        timing,
        products: [gaProduct],
      },
      _clear: true,
    });
  }
};
