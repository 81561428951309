import { ApolloError } from '@apollo/client';

import {
  GetCatalogProductViewQuery,
  CatalogVariantFragmentFragment,
} from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability } from '@notino/web-tracking';

import { IEngraving } from './../../ProductListing/model';
import { BrandsId } from './model';
import {
  MAKEUP_API_VERSION,
  HAIR_API_VERSION,
} from './ModiFaceModal/constants';
import { IModifaceEffectVariants } from './ModiFaceModal/model';
import { EModifaceApiDataType } from './ModiFaceModal/Visualizer/model';

export const getModifaceEffectsForVariant = (
  modifaceMakeUpData: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['features']['versionedModifaceEffects'][number]['effects'][number]
) => {
  return modifaceMakeUpData.shades.map((shade) => {
    return {
      ...shade,
      category: modifaceMakeUpData.category,
    };
  });
};

export const getModifaceVariants = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants']
): IModifaceEffectVariants => {
  return variants.reduce(
    (result, { webId, features: { versionedModifaceEffects } }) => {
      const modifaceEffect = versionedModifaceEffects?.find(
        (f) =>
          f.apiVersion === MAKEUP_API_VERSION &&
          f.type === EModifaceApiDataType.MAKE_UP
      )?.effects[0];

      if (!modifaceEffect) {
        return result;
      }

      // If it has modiface effect
      return {
        ...result,
        [webId]: getModifaceEffectsForVariant(modifaceEffect),
      };
    },
    <IModifaceEffectVariants>{}
  );
};

export const getModifaceAvailability = (
  hasModiface: boolean
): AdditionalServicesAvailability =>
  hasModiface
    ? AdditionalServicesAvailability.available
    : AdditionalServicesAvailability.notAvailable;

export const getTryItFirstAvailability = (
  cartTryItFirstShow: boolean,
  tryItFirstCampaigns: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['campaigns'],
  selectedVariant: CatalogVariantFragmentFragment,
  additionalServicesError: ApolloError
) => {
  if (additionalServicesError) {
    return AdditionalServicesAvailability.timeout;
  }

  if (!cartTryItFirstShow) {
    return AdditionalServicesAvailability.hidden;
  }

  if (
    tryItFirstCampaigns.some(
      (tryItFirstCampaign) =>
        tryItFirstCampaign.productId === selectedVariant.webId
    )
  ) {
    return AdditionalServicesAvailability.available;
  }

  return AdditionalServicesAvailability.notAvailable;
};

export const getEngravingAvailability = (
  cartEngravingShow: boolean,
  engraving: IEngraving,
  additionalServicesError: ApolloError
): AdditionalServicesAvailability => {
  if (additionalServicesError) {
    return AdditionalServicesAvailability.timeout;
  }

  if (engraving?.allowed) {
    return cartEngravingShow && engraving.config.isAvailable
      ? AdditionalServicesAvailability.available
      : AdditionalServicesAvailability.hidden;
  }

  return AdditionalServicesAvailability.notAvailable;
};

export const getVariantDescription = (variant, product): string =>
  variant.attributes && variant.attributes.Master
    ? product.annotation
    : variant.variantName;

export const getModifaceHairEffectsForVariant = (
  effect: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['features']['versionedModifaceEffects'][number]['effects'][number]
) => {
  return effect.shades.map((shade) => ({
    ...shade,
    category: effect.category,
  }));
};

export const getModifaceHairVariants = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants']
): IModifaceEffectVariants => {
  return variants.reduce(
    (result, { webId, features: { versionedModifaceEffects } }) => {
      const modifaceEffect = versionedModifaceEffects?.find(
        (f) =>
          f.apiVersion === HAIR_API_VERSION &&
          f.type === EModifaceApiDataType.HAIR
      )?.effects[0];

      if (!modifaceEffect) {
        return result;
      }

      return {
        ...result,
        [webId]: getModifaceHairEffectsForVariant(modifaceEffect),
      };
    },
    {} as IModifaceEffectVariants
  );
};

export const isDiorBrand = (brandId: string) => brandId === BrandsId.DIOR;
