import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

interface IVariantNameProps {
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
}

export const VariantName: React.FC<IVariantNameProps> = ({ variant }) => {
  const { parameters, additionalInfo } = variant;

  if (additionalInfo) {
    return <span>{additionalInfo}</span>;
  }

  if (parameters?.package) {
    return (
      <span>
        {parameters.package} {parameters?.unit}
      </span>
    );
  }

  return null;
};
