import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  IconRegularCameraActive,
  IconRegularTouch,
} from '@notino/react-styleguide';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import messages from '../../../messages';

import { ModiFaceWrapper, NewVtoButton, TextWrapper } from './styled';

interface IModiFaceOverlayProps {
  onClick: (e: React.MouseEvent) => void;
  visible?: boolean;
}

export const ModiFaceOverlay: React.FC<IModiFaceOverlayProps> = ({
  onClick,
  visible = true,
}) => {
  const { new_vto_button } = useFeatureFlags();

  if (new_vto_button) {
    return (
      <NewVtoButton onClick={onClick} visible={visible}>
        <IconRegularCameraActive
          color="icon.inverse"
          width="24px"
          height="24px"
        />
        <FormattedMessage {...messages.tryItOnYourselfNew} />
      </NewVtoButton>
    );
  }

  return (
    <ModiFaceWrapper onClick={onClick}>
      <IconRegularTouch color="icon.inverse" width="32px" height="37px" />
      <TextWrapper>
        <FormattedMessage {...messages.tryItOnYourself} />
      </TextWrapper>
    </ModiFaceWrapper>
  );
};
