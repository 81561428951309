import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ReactFCC } from '@notino/react-styleguide';

export const RenderInView: ReactFCC = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '100px' });

  return <div ref={ref}>{inView ? children : null}</div>;
};
