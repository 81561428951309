import * as React from 'react';

import * as Sentry from '@sentry/browser';

import { Tag } from './components';

interface IErrorBoundaryState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const defaultFallback = () => <Tag />;

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback?: () => JSX.Element }>,
  IErrorBoundaryState
> {
  public state = { error: null };

  public componentDidCatch(error) {
    if (typeof window !== 'undefined') {
      this.setState({ error });
      Sentry.captureException(error);
    }
  }

  public render() {
    if (this.state.error) {
      const { fallback = defaultFallback } = this.props;
      return fallback();
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
