import * as React from 'react';

export const TunnelForSpecialPageComponent: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: any;
  mountId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (props: { props: any }) => any;
}> = ({ components, mountId, render }) => {
  if (mountId) {
    const component = components.find(({ id }) => id.toString() === mountId);
    // client rendering
    return <div>{render({ props: component })}</div>;
  }
  /**
   * rendering on server
   * warning: this is the twilight zone, be careful
   * note: when there is a change made to App.tsx, the changes have to be reflected here as well so the SSR content is the same
   */
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {components.map((component) => (
        <div id={component.id} key={component.id} data-nushop-component={true}>
          <div data-reactroot="">{render({ props: component })}</div>
        </div>
      ))}
    </>
  );
};
