import { defineMessages } from 'react-intl';

export default defineMessages({
  reviewsName: {
    id: 'reviews.input.name',
    defaultMessage: 'Jméno',
  },
  reviewsEmail: {
    id: 'reviews.input.email',
    defaultMessage: 'E-mail',
  },
  reviewsEmailErrorRequired: {
    id: 'reviews.input.email.error.required',
    defaultMessage: 'Prosím zadejte e-mail',
  },
  reviewsEmailErrorInvalid: {
    id: 'reviews.input.email.error.invalid',
    defaultMessage: 'Prosím zadejte platný e-mail',
  },
  reviewsEmailErrorTaken: {
    id: 'reviews.input.email.error.taken',
    defaultMessage: 'Tento e-mail už byl použit',
  },
  reviewsEmailErrorFailed: {
    id: 'reviews.input.email.error.failed',
    defaultMessage: 'Chyba při kontrole e-mailu',
  },
  reviewsText: {
    id: 'reviews.input.text',
    defaultMessage: 'Vaše recenze',
  },
  reviewsTextErrorRequired: {
    id: 'reviews.input.text.error.required',
    defaultMessage: 'Prosím zadejte vaši recenzi',
  },
  reviewsTextErrorForbiddenWords: {
    id: 'reviews.input.text.error.forbiddenWords',
    defaultMessage: 'Vaše recenze obsahuje nevhodná slova: {forbiddenWords}',
  },
  reviewsTextErrorMaxLength: {
    id: 'reviews.input.text.error.maxLength',
    defaultMessage: 'Prosím zadejte kratší text recenze',
  },
  watchdogEmail: {
    id: 'watchdog.input.email',
    defaultMessage: 'Vepište svůj e-mail',
  },
  watchdogEmailErrorRequired: {
    id: 'watchdog.input.email.error.required',
    defaultMessage: 'Prosím zadejte e-mail',
  },
  watchdogEmailErrorInvalid: {
    id: 'watchdog.input.email.error.invalid',
    defaultMessage: 'Prosím zadejte platný e-mail',
  },
  watchdogEmailErrorTaken: {
    id: 'watchdog.input.email.error.taken',
    defaultMessage: 'Tento e-mail už byl použit',
  },
  watchdogEmailErrorFailed: {
    id: 'watchdog.input.email.error.failed',
    defaultMessage: 'Chyba při kontrole e-mailu',
  },
  name: {
    id: 'tnb.input.name',
    defaultMessage: 'Jméno a příjmení',
  },
  nameHelp: {
    id: 'tnb.input.name.help',
    defaultMessage: 'Jméno a příjmení musí být stejné jako na vaší schránce',
  },
  nameErrorRequired: {
    id: 'tnb.input.name.error.required',
    defaultMessage: 'Prosím zadejte vaše jméno a příjmení',
  },
  phone: {
    id: 'tnb.input.phone',
    defaultMessage: 'Telefon/mobil',
  },
  phoneErrorRequired: {
    id: 'tnb.input.phone.error.required',
    defaultMessage: 'Prosím zadejte telefonní číslo',
  },
  phoneErrorInvalid: {
    id: 'tnb.input.phone.error.invalid',
    defaultMessage: 'Prosím zadejte platné tel. číslo',
  },
  email: {
    id: 'tnb.input.email',
    defaultMessage: 'E-mail',
  },
  emailErrorRequired: {
    id: 'tnb.input.email.error.required',
    defaultMessage: 'Prosím zadejte e-mail',
  },
  emailErrorInvalid: {
    id: 'tnb.input.email.error.invalid',
    defaultMessage: 'Prosím zadejte platný e-mail',
  },
  emailErrorTaken: {
    id: 'tnb.input.email.error.taken',
    defaultMessage: 'Tento e-mail už byl použit',
  },
  emailErrorFailed: {
    id: 'tnb.input.email.error.failed',
    defaultMessage: 'Chyba při kontrole e-mailu',
  },
  address: {
    id: 'tnb.input.address',
    defaultMessage: 'Ulice a číslo popisné',
  },
  addressErrorRequired: {
    id: 'tnb.input.address.error.required',
    defaultMessage: 'Prosím zadejte ulici a číslo',
  },
  city: {
    id: 'tnb.input.city',
    defaultMessage: 'Město nebo obec',
  },
  cityErrorRequired: {
    id: 'tnb.input.city.error.required',
    defaultMessage: 'Prosím zadejte město nebo obec',
  },
  zip: {
    id: 'tnb.input.zip',
    defaultMessage: 'PSČ',
  },
  zipErrorRequired: {
    id: 'tnb.input.zip.error.required',
    defaultMessage: 'Prosím zadejte PSČ',
  },
  zipErrorInvalid: {
    id: 'tnb.input.zip.error.invalid',
    defaultMessage: 'Prosím zadejte platné PSČ',
  },
});
