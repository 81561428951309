import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const Container = styled.div`
  padding: 1rem 0;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.25rem;

  @media screen and (min-width: ${breakpoints.sm}) {
    padding-right: 0.5rem;
  }
`;

export const CampaignsWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    overflow: visible;
  }
`;

export const CollapsibleItemsContainerWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      text-decoration-line: underline;
      width: fit-content;
    }
  }
`;

export const GiftBaseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid ${theme.color.border.divider};
  border-radius: 0.25rem;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    border: none;
    gap: 0.75rem;
  }
`;

export const CampaignsStyled = styled.div<{ isOneCampaign: boolean }>`
  display: flex;
  gap: 1rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  div {
    width: ${({ isOneCampaign }) => (isOneCampaign ? '100%' : '18rem')};
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;

    div {
      width: 100%;
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

export const Title = styled.div`
  color: ${theme.color.text.highlight};

  ${theme.typography.labelRegular}
`;

export const Description = styled.div`
  padding: 1rem;
  padding-bottom: 1.5rem;

  a {
    color: ${theme.color.text.primary};
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }

  ${theme.typography.bodyRegular}

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    border: none;
    padding: 0rem;
  }
`;

export const GiftCount = styled.span`
  color: ${theme.color.text.tertiary};
  margin-left: 0.25rem;
`;

export const GiftIconWrapper = styled.div`
  padding-right: 0.25rem;
`;

export const TooltipContent = styled.div`
  width: 14rem;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    min-width: 16rem;
  }
`;
