import * as React from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

const DisplayNone = styled.div`
  display: none;
`;

function ClientPortal({ children }) {
  const el = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    const currEl = el.current;
    const portal = document.getElementById('nushop-portal-root');

    if (!portal) {
      console.warn(
        'To use portal, please add it on this page: <div id="nushop-portal-root"/>'
      );
      return;
    }

    portal.appendChild(currEl);
    return () => {
      portal.removeChild(currEl);
    };
  }, [el]);

  return createPortal(children, el.current);
}

function Portal({ children }) {
  const [isHydrated, setIsHydrated] = React.useState(false);

  React.useEffect(() => {
    setIsHydrated(true);
  }, []);

  return isHydrated ? (
    <ClientPortal>{children}</ClientPortal>
  ) : (
    <DisplayNone>{children}</DisplayNone>
  );
}

export { Portal };
