import * as React from 'react';

import { useApolloClient } from '@apollo/client';
import { snakeCase } from 'lodash';

import {
  GetMasterProductInfoQuery,
  GetMasterProductInfoQueryVariables,
} from '@notino/shared/definitions/types';
import { CartType } from '@notino/web-tracking';

import { dispatchTrackingEvent } from '@context/tracking/utils';
import getMasterProductInfoQuery from '@queries/masterProductInfo.graphql';

import { ProductEventWither } from './prepareProductInfo';
import { IVariantAddToCart } from './prepareProductInfo/model';

export const useGetMasterAddToCart = (listName?: string) => {
  const client = useApolloClient();

  return React.useCallback(
    async (
      product: IVariantAddToCart,
      catalogMasterId: string,
      position: number
    ) => {
      const { data } = await client.query<
        GetMasterProductInfoQuery,
        GetMasterProductInfoQueryVariables
      >({
        query: getMasterProductInfoQuery,
        variables: { catalogMasterId },
      });

      const masterProduct = data.productDetailByCatalogMasterId;

      if (data) {
        dispatchTrackingEvent({
          event: 'add_to_cart',
          product: ProductEventWither()
            .withPartialProduct({
              ...masterProduct,
              brand: masterProduct.brand,
              __typename: 'CatalogProduct',
            })
            .withVpProduct(product)
            .withAdditionalData({
              cart_type: snakeCase(CartType.cart),
              quantity: 1,
              list_position: position,
              ...(listName && { list_name: listName }),
            })
            .build(),
          _clear: true,
        });
      }
    },
    [client, listName]
  );
};
