import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';

import { GTM_PAGE_TYPE } from './constants';

export const callExponeaRecommendation = ({
  recommendationId,
  callback,
  productCode,
}) => {
  const options = {
    // Required parameters:
    recommendationId,
    // Specifies upper limit of the recommendations to return. Defaults to 10.
    size: 10,
    callback,
    // If true, fills the recommendations with random items until size is reached. This is utilized when models cannot recommend enough items.
    fillWithRandom: true,
    // Optional parameters:
    // Adds additional constrains to catalog when retrieving recommended items. Example: [{'property': 'gender', 'constraint': { 'type': 'string', 'operator': 'equals', 'operands': [{'type': 'constant', 'value': 'male'}]}}]
    catalogFilter: [],
    // Returns only specified attributes from catalog items. If empty or not set, returns everything.
    catalogAttributesWhitelist: ['product_number'],
    // CAUTION! Property is named product_id in exponea documentation but in real it is web productCode
    // {[product_id]: [weight]}, where the value weight determines the preference strength for the given product (bigger number = higher preference). For example: {"123": 1, "234": 2, "345": 4}
    items: { [productCode]: 1 },
  };

  if (
    window.exponea &&
    typeof window.exponea.getRecommendation === 'function'
  ) {
    window.exponea.getRecommendation(options);
  }
};

export const trackUpsellingOpen = ({
  timing,
}: Omit<TrackingAttributes, 'variant'>) => {
  dispatchTrackingEvent({
    event: 'subpage_view',
    subpage: {
      timing,
      type: GTM_PAGE_TYPE,
      action: 'open_cross_selling',
      interaction: 'automatic',
      name: 'cross_selling',
    },
    _clear: true,
  });
};

export const notifyCypressUpsellingDisabled = (
  shouldRenderDatabreakers: boolean
) => {
  // we let cypress know that upselling is disabled
  if (window.Cypress && !shouldRenderDatabreakers) {
    window.isUpsellingActive = false;
  }
};
