import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useCartServices } from './hooks/useCartServices';
import { useCurrentVariant } from './hooks/useCurrentVariant';
import { useModiface } from './hooks/useModifaceVariants';

interface ProductDetailContextValue extends ReturnType<typeof useCartServices> {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  currentVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  customVersionKey?: string;
  modiface: ReturnType<typeof useModiface>;
}

interface Props {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  customVersionKey?: string;
}

const Context = React.createContext<ProductDetailContextValue>(null!);

export const useProductDetailContext = (throwError = true) => {
  const value = React.useContext(Context);
  if (!value && throwError) {
    throw Error(
      'useProductDetailContext must be within ProductDetailContextProvider.'
    );
  }

  return value;
};

export const ProductDetailContextProvider = ({
  children,
  product,
  customVersionKey,
}: React.PropsWithChildren<Props>) => {
  const currentVariant = useCurrentVariant(product.variants);
  const cartServices = useCartServices(product, currentVariant);
  const modiface = useModiface(product.variants);

  const value: ProductDetailContextValue = React.useMemo(
    () => ({
      product,
      currentVariant,
      ...cartServices,
      customVersionKey,
      modiface,
    }),
    [product, currentVariant, cartServices, customVersionKey, modiface]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
