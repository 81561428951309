import * as React from 'react';
import { PropsWithChildren } from 'react';

import { IStyledComponent } from 'styled-components';

import { ITheme, TooltipModel } from '@notino/react-styleguide';

import { Portal } from './Portal';
import {
  Content,
  Placeholder,
  RelativeWrapper,
  TooltipWrapper,
  Wrapper,
} from './styled';

type Props = PropsWithChildren<{
  content: React.ReactNode;
  position?: TooltipModel.Position;
  backgroundColor?: BackgroundColor;
  interactive?: boolean;
  isOpen?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  onShow?: () => void;
  onHide?: () => void;
}>;

export type BackgroundColor = keyof ITheme['color']['background'];

type CompoundComponent = React.FC<Props> & {
  Content: IStyledComponent<
    'web',
    {
      shouldWrapText?: boolean;
      children?: React.ReactNode;
    }
  >;
};

export const PortalTooltip: CompoundComponent = ({
  children,
  content,
  onShow,
  onHide,
  disabled,
  interactive = false,
  isOpen: isOpenProp,
  backgroundColor,
  dataTestId,
  position: initialPosition = TooltipModel.Position.topCenter,
}) => {
  const [position, setPosition] = React.useState(initialPosition);
  const [isOpen, setIsOpen] = React.useState(false);
  const [placeholderStyle, setPlaceHolderStyle] =
    React.useState<React.CSSProperties>({});

  const wrapperRef = React.useRef<HTMLDivElement>();
  const tooltipWrapperRef = React.useRef<HTMLDivElement>();
  const distanceFromArrowToWindowEdge = React.useRef(Number.MAX_VALUE);

  const handleSetStyles = React.useCallback((el: HTMLElement) => {
    const { left, top, width } = el.getBoundingClientRect();
    setPlaceHolderStyle({
      top: top + window.pageYOffset,
      left: left + width / 2,
    });
  }, []);

  const handleShowTooltip: React.MouseEventHandler = (e) => {
    if (disabled) {
      return;
    }
    const el = e.currentTarget as HTMLElement;
    handleSetStyles(el);
    setIsOpen(true);
    onShow?.();
  };

  const handleHideTooltip = () => {
    if (disabled) {
      return;
    }
    setIsOpen(false);
    onHide?.();
  };

  React.useEffect(() => {
    const { left, right, width } = wrapperRef.current.getBoundingClientRect();
    const gutter = 4;
    // since arrow is in the middle of wrapped component
    // I add 'width/2' to each computation
    distanceFromArrowToWindowEdge.current = Math.min(
      left + width / 2 - gutter,
      window.innerWidth - right + width / 2 - gutter
    );
  });

  React.useEffect(() => {
    const scrollEvent = () => {
      setIsOpen((prev) => {
        if (prev) {
          onHide?.();
        }
        return false;
      });
    };

    window.addEventListener('scroll', scrollEvent);

    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, [onHide]);

  React.useEffect(() => {
    handleSetStyles(wrapperRef.current);
  }, [handleSetStyles]);

  const positionRef = React.useRef(position);
  React.useEffect(() => {
    positionRef.current = position;
  });

  // adjust position if tooltip gets off screen
  React.useEffect(() => {
    const el = tooltipWrapperRef.current;
    const { left, width } = el.getBoundingClientRect();

    const overflowsFromRight = left + width > window.innerWidth;
    if (left < 0 && overflowsFromRight) {
      return;
    }

    // using positionRef instead of position to prevent infinite update loop
    if (positionRef.current.startsWith('top')) {
      if (left < 0) {
        setPosition(TooltipModel.Position.topLeft);
      }
      if (overflowsFromRight) {
        setPosition(TooltipModel.Position.topRight);
      }
    } else {
      if (left < 0) {
        setPosition(TooltipModel.Position.bottomLeft);
      }
      if (overflowsFromRight) {
        setPosition(TooltipModel.Position.bottomRight);
      }
    }
  }, [placeholderStyle]);

  const isVisible = typeof isOpenProp !== 'undefined' ? isOpenProp : isOpen;

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      onClick={handleShowTooltip}
    >
      {children}

      <Portal>
        <Placeholder style={placeholderStyle} data-testid={dataTestId}>
          <RelativeWrapper
            backgroundColor={backgroundColor}
            position={position}
            visible={isVisible}
          >
            <TooltipWrapper
              ref={tooltipWrapperRef}
              position={position}
              visible={isVisible}
              interactive={interactive}
              backgroundColor={backgroundColor}
              distanceFromArrowToWindowEdge={
                distanceFromArrowToWindowEdge.current
              }
            >
              {content}
            </TooltipWrapper>
          </RelativeWrapper>
        </Placeholder>
      </Portal>
    </Wrapper>
  );
};

PortalTooltip.Content = Content;
