import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { dispatchTrackingEvent } from '@context/tracking/utils';

export const useTrackReviewListView = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      dispatchTrackingEvent({ event: 'review_list_view' });
    }
  }, [inView]);

  return { reviewWrapperRef: ref };
};
