import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Col, IconSolidWarning } from '@notino/react-styleguide';

import messages from '../../../../../messages';

import { ReviewsFetch } from './styled';

interface IFailedBlockProps {
  loadReviews: () => void;
}

export const FetchBlockFailed: React.FC<IFailedBlockProps> = ({
  loadReviews,
}) => (
  <Col xs={12}>
    <ReviewsFetch>
      <IconSolidWarning color="icon.tertiary" width="20px" height="20px" />
      <div>
        <FormattedMessage {...messages.fetchFailed} />,{' '}
        <a onClick={loadReviews}>
          <FormattedMessage {...messages.retry} />
        </a>
      </div>
    </ReviewsFetch>
  </Col>
);
