import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ILivestream } from '@components/LivestreamBanner/model';
import {
  trackLivestreamView,
  ELivestreamTrackingInteraction,
} from '@containers/ProductDetailContainer/ProductDetail/livestreamTracking';

export const useLiveStreamTracking = (livestreams: ILivestream[]) => {
  const viewTrackedItemsIndexes = React.useRef<number[]>([]);

  const [livestreamCarouselRef, livestreamCarouselInView] = useInView({
    triggerOnce: true,
  });

  const firstShowId = livestreams[0].showId;

  React.useEffect(() => {
    if (
      livestreamCarouselInView &&
      !viewTrackedItemsIndexes.current.includes(0)
    ) {
      trackLivestreamView({
        showId: firstShowId,
        itemIndex: 0,
        interaction: ELivestreamTrackingInteraction.AUTOMATIC,
      });

      viewTrackedItemsIndexes.current.push(0);
    }
  }, [livestreamCarouselInView, firstShowId]);

  const trackOnSlide = React.useCallback(
    (index: number) => {
      const livestream = livestreams[index];

      if (!viewTrackedItemsIndexes.current.includes(index)) {
        trackLivestreamView({
          showId: livestream.showId,
          itemIndex: index,
          interaction: ELivestreamTrackingInteraction.BUTTON,
        });

        viewTrackedItemsIndexes.current.push(index);
      }
    },
    [livestreams]
  );

  return {
    livestreamCarouselRef,
    trackOnSlide,
  };
};
