import styled, { css, keyframes } from 'styled-components';

import {
  breakpoints,
  IBasicStyledProps,
  theme,
} from '@notino/react-styleguide';

import { RequestState } from '@containers/App/model';

export const WasItUsefulWrapper = styled.div`
  display: flex;
`;

export const WasItUseful = styled.span`
  display: inline-flex;
  align-items: center;
  padding-right: 0.75rem;
`;

export const Vote = styled.a`
  padding-right: 0.3125rem;
  text-decoration: underline;
  user-select: none;
`;

export const Separator = styled.span`
  color: ${theme.color.text.disabled};
  margin-right: 0.375rem;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 1rem;
  left: 0;
  top: 0;
  transform: translateY(50%);
  padding-right: 0.625rem;
  z-index: 81;
`;

export const TooltipBox = styled.div`
  width: 1rem;
  height: 1rem;
`;

export const ErrorMsg = styled.div`
  color: ${theme.color.text.negative};
`;

export const Votes = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`;

const voting = keyframes`
  50% { color: ${theme.color.text.secondary}; }
`;

const votingSuccess = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const votingFailed = keyframes`
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
`;

const VotingInProgress = css`
  animation: ${voting} 1s ease infinite;
`;

const VotingSuccess = css`
  transition: all 1s ease;
  animation: ${votingSuccess} 0.8s ease;
`;

const VotingFailed = css`
  transition: all 1s ease;
  animation: ${votingFailed} 0.8s ease;
`;

export interface IVoteWrapperProps extends IBasicStyledProps {
  state: RequestState;
  voted: boolean;
}

export const VoteWrapper = styled.button<IVoteWrapperProps>`
  display: flex;
  align-items: center;
  margin-right: 0.375rem;
  position: relative;
  cursor: pointer;
  user-select: none;

  background: none;
  border: none;
  font-size: 0.75rem;
  color: ${theme.color.text.secondary};

  font-weight: ${(props) =>
    props.voted && props.state !== RequestState.IN_PROGRESS ? 700 : 300};

  @media only screen and (hover: hover) {
    &:hover a {
      text-decoration: none;
    }
  }

  a {
    margin-right: 0.125rem;
    transition: all 0.4s ease;

    ${(props: IVoteWrapperProps) =>
      props.state === RequestState.DONE ? VotingSuccess : ''};
    ${(props: IVoteWrapperProps) =>
      props.state === RequestState.FAILED ? VotingFailed : ''};
    ${(props: IVoteWrapperProps) =>
      props.state === RequestState.IN_PROGRESS ? VotingInProgress : ''};
  }
`;

export const ReviewItem = styled.li`
  margin-top: 2.5rem;
  ${theme.typography.bodyRegular}
  @media (min-width: ${breakpoints.lg}) {
    ${theme.typography.bodyLarge}
  }
`;

export const ReviewDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  color: ${theme.color.text.primary};
  ${theme.typography.labelRegular}
  margin-top: 0.75rem;

  > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export const Name = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`;

export const ReviewDate = styled.div``;

export const CheckboxWrapper = styled.div`
  margin: 1rem 0;
`;

export const ReviewSource = styled.div`
  color: ${theme.color.text.tertiary};
  border-bottom: 1px ${theme.color.border.default} dashed;
  line-height: 1.5;
  cursor: pointer;
`;

export const ReviewTooltipContent = styled.div`
  color: ${theme.color.text.inverse};
  white-space: wrap;

  @media (min-width: ${breakpoints.md}) {
    white-space: nowrap;
  }
`;

export const RatingWrapper = styled.div`
  div {
    line-height: initial !important;
  }
  svg {
    vertical-align: initial !important;
  }
`;
