import * as React from 'react';
import { useIntl } from 'react-intl';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { IVariantsProps } from '../../../../model';
import ShadesDrawerButton from '../../ShadesDrawer';
import { useVariantTransition } from '../useVariantTransition';

import { ColorPicker } from './ColorPicker';
import { messages } from './messages';
import { Container, VariantsList } from './styled';

const allowedCategories = ['Makeup', 'Hair'];

export const VariantsColorPickers: React.FC<IVariantsProps> = ({
  selectedId: _selectedId,
  variants,
  selectedVariant,
}) => {
  const { formatMessage } = useIntl();
  const { pd_color_picker_drawer } = useFeatureFlags();
  const { handleClick, selectedId } = useVariantTransition(_selectedId);

  const selectedCategoryName =
    selectedVariant.primaryCategories?.category?.name;

  const isAllowedCategory =
    selectedCategoryName &&
    allowedCategories.some((category) =>
      selectedCategoryName.includes(category)
    );

  const shouldShowShadeListButton =
    isAllowedCategory && pd_color_picker_drawer && variants.length > 1;

  return (
    <Container
      data-testid="pd-variants-color-picker"
      id="pdVariantsColorPicker"
    >
      <VariantsList>
        {variants.map((item) => (
          <ColorPicker
            item={item}
            isSelected={item.webId === selectedId}
            key={item.webId}
            onClick={handleClick}
          />
        ))}
      </VariantsList>

      {shouldShowShadeListButton && (
        <ShadesDrawerButton
          selectedId={selectedId}
          variants={variants}
          shadeDrawerTitle={formatMessage(messages.pickShade)}
          onClick={handleClick}
        >
          {formatMessage(messages.shadeList)}
        </ShadesDrawerButton>
      )}
    </Container>
  );
};
