import { IStock } from '@notino/shared/definitions/custom-definitions';
import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import { getRenderableAttributes } from '@components/Tags/utils';

import { usePriceLogic } from '../../hooks/usePriceLogic';
import { useResolvedVoucherDiscount } from '../VoucherDiscount/useResolvedVoucherDiscount';

export const useShouldRenderTagDiscount = (
  selectedVariant: CatalogVariantFragmentFragment
): boolean => {
  const attributes = getRenderableAttributes(selectedVariant.attributes);

  const hasAttributes = Object.keys(attributes).length > 0;

  const hasVoucherDiscount = !!useResolvedVoucherDiscount();

  const { topPrice } = usePriceLogic(selectedVariant);

  const hasDiscount =
    topPrice && selectedVariant.stockAvailability.code !== IStock.outOfStock;

  if (hasVoucherDiscount && !hasAttributes && !hasDiscount) {
    return false;
  }

  return hasAttributes || hasDiscount;
};
