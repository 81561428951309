import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '@notino/react-styleguide';
import { AdditionalServicesAvailability } from '@notino/web-tracking';

import { useEngravingTryItFirstContext } from '../context/EngravingTryItFirst';

import { Container, Description, DisabledTextWrapper } from './styled';
import { messages } from './tryItFirstMessages';

interface ITryItFirstProps {
  availability?: AdditionalServicesAvailability;
}

export const TryItFirst = ({ availability }: ITryItFirstProps) => {
  const {
    state: { withTryItFirst, withEngraving },
    actions: { toggleTryItFirst },
  } = useEngravingTryItFirstContext();

  const isTryItFirstDisabled =
    availability !== AdditionalServicesAvailability.available || withEngraving;

  const toggle = () => {
    toggleTryItFirst(!withTryItFirst);
  };

  return (
    <Container data-testid="pd-try-it-first" id="pdTryItFirst">
      <DisabledTextWrapper disabled={isTryItFirstDisabled}>
        <Checkbox
          disabled={isTryItFirstDisabled}
          checked={withTryItFirst}
          onChange={toggle}
          position={0}
        >
          <FormattedMessage {...messages.tryItFirst} />
        </Checkbox>
        <Description>
          <FormattedMessage {...messages.tryItFirstDescription} />
          <a href="/try-it-first" target="_blank" rel="noopener">
            <FormattedMessage {...messages.tryItFirstAbout} />
          </a>
        </Description>
      </DisabledTextWrapper>
    </Container>
  );
};

TryItFirst.displayName = 'TryItFirst';
