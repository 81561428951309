import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tryItFirst: {
    id: 'try.it.first',
    defaultMessage: 'Nejdřív vyzkoušejte, pak rozbalujte',
  },
  tryItFirstDescription: {
    id: 'try.it.first.description',
    defaultMessage:
      'Pošleme vám i vzorek produktu a až poté se rozhodnete, zda si velké balení necháte.',
  },
  tryItFirstAbout: {
    id: 'try.it.first.about',
    defaultMessage: 'Více o službě Try it First',
  },
});
