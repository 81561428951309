import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { TabOrderProvider } from '@sharedComponents/TabSwitcher/TabOrderProvider';

import messages from '../../../messages';
import { useIsRichContentFirstTab } from '../../hooks/useIsRichContentFirstTab';
import { TabIds } from '../../model';
import { TRACKING_ATTRIBUTES, TAB_IDENTIFIERS } from '../tracking';
import { getDescriptionIndex } from '../utils';

import { ContentTabLink } from './ContentTabLink';
import {
  ContentSwitcherContainer,
  ContentSwitcherWrapper,
  TabList,
  Container,
  ReviewSummaryCount,
} from './styled';

interface IContentSwitcherProps {
  isRichContentAvailable: boolean;
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  productReviewCount: number;
  shouldRenderComposition: boolean;
  shouldRenderOthersTab: boolean;
}

export const ContentSwitcher: React.FC<IContentSwitcherProps> = ({
  product,
  variant,
  isRichContentAvailable,
  productReviewCount,
  shouldRenderComposition,
  shouldRenderOthersTab,
}) => {
  const { bazaarvoice_pd_components } = useFeatureFlags();
  const descriptionIndex = getDescriptionIndex({
    description: product.description,
    composition: product.features.composition,
    variant,
  });

  const isRichContentFirstTab = useIsRichContentFirstTab();

  const { brand, description } = product;

  const shouldRenderAboutBrand = Boolean(brand?.description);

  const richContentTabLink = isRichContentAvailable && (
    <ContentTabLink
      tabId={TabIds.RichContent}
      ariaControls={TAB_IDENTIFIERS.richContent}
      trackingLabel={TRACKING_ATTRIBUTES.richContent}
      id="tabRichContent"
      dataTestId="tabRichContent"
    >
      <FormattedMessage {...messages.fixedHeaderRichContent} />
    </ContentTabLink>
  );

  return (
    <ContentSwitcherContainer>
      <ContentSwitcherWrapper>
        <Container>
          <Row>
            <Col>
              <TabList data-testid="tablist" role="tablist">
                <TabOrderProvider>
                  {isRichContentFirstTab && richContentTabLink}

                  {descriptionIndex && (
                    <ContentTabLink
                      tabId={TabIds.Description}
                      ariaControls={TAB_IDENTIFIERS.description}
                      trackingLabel={TRACKING_ATTRIBUTES.description}
                      id={description ? 'tabDescription' : 'tabComposition'}
                      dataTestId="tabDescription"
                    >
                      <FormattedMessage
                        {...messages[descriptionIndex]}
                        values={{ name: '' }}
                      />
                    </ContentTabLink>
                  )}

                  {shouldRenderComposition && (
                    <ContentTabLink
                      tabId={TabIds.Composition}
                      ariaControls={TAB_IDENTIFIERS.composition}
                      trackingLabel={TRACKING_ATTRIBUTES.composition}
                      id="tabComposition"
                      dataTestId="tabComposition"
                    >
                      <FormattedMessage {...messages.compositionTab} />
                    </ContentTabLink>
                  )}

                  {shouldRenderOthersTab && (
                    <ContentTabLink
                      tabId={TabIds.Other}
                      ariaControls={TAB_IDENTIFIERS.other}
                      trackingLabel={TRACKING_ATTRIBUTES.other}
                      id="tabOther"
                      dataTestId="tabOther"
                    >
                      <FormattedMessage {...messages.other} />
                    </ContentTabLink>
                  )}

                  {shouldRenderAboutBrand && (
                    <ContentTabLink
                      tabId={TabIds.AboutBrand}
                      ariaControls={TAB_IDENTIFIERS.brand}
                      trackingLabel={TRACKING_ATTRIBUTES.brand}
                      id="tabBrand"
                      dataTestId="tabBrand"
                    >
                      <FormattedMessage
                        {...messages.aboutBrand}
                        values={{ brandName: '' }}
                      />
                    </ContentTabLink>
                  )}

                  {!isRichContentFirstTab && richContentTabLink}

                  <ContentTabLink
                    tabId={TabIds.Reviews}
                    ariaControls={TAB_IDENTIFIERS.reviews}
                    id="tabReviews"
                    dataTestId="tabReviews"
                    trackingLabel={TRACKING_ATTRIBUTES.reviews}
                  >
                    <FormattedMessage
                      {...messages.reviewHeader}
                      values={{
                        name: (
                          <ReviewSummaryCount id="tabReviews_reviewCount">
                            {productReviewCount > 0 &&
                            !bazaarvoice_pd_components
                              ? productReviewCount
                              : ''}
                          </ReviewSummaryCount>
                        ),
                      }}
                    />
                  </ContentTabLink>
                </TabOrderProvider>
              </TabList>
            </Col>
          </Row>
        </Container>
      </ContentSwitcherWrapper>
    </ContentSwitcherContainer>
  );
};
