import * as React from 'react';
import { useIntl } from 'react-intl';

import { PrimitiveType } from 'intl-messageformat';

import {
  Input as SgInput,
  InputGroup as SgInputGroup,
} from '@notino/react-styleguide';

import messages from './messages';

export interface IInputGroupProps {
  required?: boolean;
  help?: boolean;

  meta: {
    error: string | { key: string; values: Record<string, PrimitiveType> };
    touched: boolean;
  };

  input: {
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    name: string;
    value: string;
    type?: string;
    inputMode?: string;
  };
}

export const InputGroup: React.FC<IInputGroupProps> = ({
  required,
  meta: { error, touched },
  input: { onBlur, onChange, onFocus, name, value, type },
  help,
}) => {
  const { formatMessage } = useIntl();

  const id = `input_${name}`;
  const inputType = type || 'text';

  const formatError = () => {
    if (typeof error === 'string') {
      return formatMessage(messages[`${name}Error${error}`]);
    }
    return formatMessage(
      messages[`${name}Error${String(error.key)}`],
      error.values
    );
  };

  return (
    <SgInputGroup
      required={required}
      layout="vertical"
      label={formatMessage(messages[name])}
      error={touched && error && formatError()}
      help={help && formatMessage(messages[`${name}Help`])}
    >
      <SgInput
        id={id}
        type={inputType}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        invalid={touched && !!error}
        value={value}
      />
    </SgInputGroup>
  );
};
