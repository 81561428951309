import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface IHttpContext {
  status?: number;
  url?: string;
  reason?: string;
}

const HttpContext = React.createContext<IHttpContext>({} as IHttpContext);

const HttpStatus = ({ status, reason, children }) => {
  const context = React.useContext(HttpContext);
  context.status = status;
  context.reason = reason;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export interface RedirectProps {
  to: string;
  push?: boolean;
}

const Redirect = ({ to, push }: RedirectProps) => {
  const ctx = React.useContext(HttpContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(to, {
      replace: !push,
    });
  }, [navigate, push, to]);

  if (ctx) {
    // TODO: This might not work properly with suspense, figure out how to prevent adding
    //  a new item for renders that aren't "committed"
    ctx.url = to;
  }

  return null;
};

export { HttpContext, HttpStatus, Redirect };
