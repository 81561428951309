import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  brands1Categories0FromPrice: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.brands.1.categories.0',
    defaultMessage: 'Platí od {price} na značku {brand}',
  },
  brands1Categories0FromPieces: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.brands.1.categories.0',
    defaultMessage: 'Platí od {pieces} ks na značku {brand}',
  },
  brandsNCategories0FromPrice: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.brands.N.categories.0',
    defaultMessage: 'Platí od {price} na tyto <brands>značky</brands>',
  },
  brandsNCategories0FromPieces: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.brands.N.categories.0',
    defaultMessage: 'Platí od {pieces} ks na tyto <brands>značky</brands>',
  },
  brands0Categories1FromPrice: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.brands.0.categories.1',
    defaultMessage: 'Platí od {price} na kategorii {category}',
  },
  brands0Categories1FromPieces: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.brands.0.categories.1',
    defaultMessage: 'Platí od {pieces} ks na kategorii {category}',
  },
  brands0CategoriesNFromPrice: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.brands.0.categories.N',
    defaultMessage:
      'Platí od {price} na tyto <categories>kategorie</categories>',
  },
  brands0CategoriesNFromPieces: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.brands.0.categories.N',
    defaultMessage:
      'Platí od {pieces} ks na tyto <categories>kategorie</categories>',
  },
  brandsNCategoriesNFromPrice: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.brands.N.categories.N',
    defaultMessage:
      'Platí od {price} na <these>tyto</these> značky a kategorie',
  },
  brandsNCategoriesNFromPieces: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.brands.N.categories.N',
    defaultMessage:
      'Platí od {pieces} ks na <these>tyto</these> značky a kategorie',
  },
  fromPriceSelectedProducts: {
    id: 'pd.conditionalVoucherDiscount.fromPrice.selectedProducts',
    defaultMessage: 'Platí od {price} na vybrané produkty',
  },
  fromPiecesSelectedProducts: {
    id: 'pd.conditionalVoucherDiscount.fromPieces.selectedProducts',
    defaultMessage: 'Platí od {pieces} ks na vybrané produkty',
  },
});
