import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductLabelModel } from '@notino/react-styleguide';
import { StockAvailability } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import PriceLabel from '@components/PriceLabel';
import { Tags } from '@components/Tags';
import { getRenderableAttributes } from '@components/Tags/utils';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { VariantDisplayTypes } from '@containers/ProductListing/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dataLabelsProp } from '@helpers/utils';

import { IVariantProps } from '../../../../model';
import { trackVariantClick } from '../../tracking';
import {
  VariantListItem,
  VariantLink,
  PriceLabelCurrency,
  Name,
} from '../../variantsStyled';

import { messages } from './messages';
import { Variant, PriceLabelWrapper, Unavailable } from './styled';
import { trackTagClick } from './tracking';

export const VariantTile: React.FC<IVariantProps> = ({
  item,
  isSelected,
  unit,
  onClick,
}) => {
  const { getTimeFromInit } = useTrackingContext();
  const { isDualPriceForCroatia } = useIsDualPriceForCroatia();
  const isOnStock =
    item.availability.stockAvailability !== StockAvailability.OutOfStock;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(item, e);
    const attributes = Object.keys(getRenderableAttributes(item.attributes));
    trackVariantClick(
      getTimeFromInit(),
      VariantDisplayTypes.Tiles,
      isOnStock ? attributes?.[0] : undefined
    );
  };

  return (
    <VariantListItem minWidth="0px">
      <ErrorBoundary>
        <VariantLink
          onClick={handleClick}
          to={item.url}
          replace={true}
          id={`pd-variant-${item.webId}`}
          data-testid={`pd-variant-${item.webId}`}
          className={isSelected ? 'pd-variant-selected' : ''}
          {...dataLabelsProp(item.attributes)}
        >
          <Variant
            isOnStock={isOnStock}
            isDualPriceForCroatia={isDualPriceForCroatia}
            isSelected={isSelected}
          >
            <Tags
              onClick={(attr) => trackTagClick(attr, getTimeFromInit())}
              type={ProductLabelModel.Types.corner}
              small={true}
              attributes={item.attributes}
              stockAvailability={item.stockAvailability.code}
            />

            <Name isOnStock={isOnStock} className="pd-variant-label">
              {`${item.parameters?.package || ''}\u00A0${unit || ''}`}
            </Name>

            {isOnStock && (
              <PriceLabel
                dataTestId="price-variant"
                price={item.price}
                wrapperComponent={(additionalProps) => (
                  <PriceLabelWrapper {...additionalProps} />
                )}
                currencyComponent={PriceLabelCurrency}
              />
            )}

            {!isOnStock && (
              <Unavailable>
                <FormattedMessage {...messages.unavailable} />
              </Unavailable>
            )}
          </Variant>
        </VariantLink>
      </ErrorBoundary>
    </VariantListItem>
  );
};
