import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  discountAmountPercentage: {
    id: 'pd.multiTierVoucherDiscount.highlighted.percentage',
    defaultMessage: 'Sleva az {amount}%',
  },
  discountAmountAbsolute: {
    id: 'pd.multiTierVoucherDiscount.highlighted.absolute',
    defaultMessage: 'Sleva az {amountPrice}',
  },
  percentageDiscountFromPrice: {
    id: 'pd.multiTierVoucherDiscount.percentageDiscountFromPrice',
    defaultMessage: '<b>{percentage}%</b> při nákupu nad {price}',
  },
  percentageDiscountFromPieces: {
    id: 'pd.multiTierVoucherDiscount.percentageDiscountFromPieces',
    defaultMessage: '<b>{percentage}%</b> při nákupu od {pieces} ks',
  },
  absoluteDiscountFromPrice: {
    id: 'pd.multiTierVoucherDiscount.absoluteDiscountFromPrice',
    defaultMessage: '<b>{priceAmount}</b> při nákupu nad {price}',
  },
  absoluteDiscountFromPieces: {
    id: 'pd.multiTierVoucherDiscount.absoluteDiscountFromPieces',
    defaultMessage: '<b>{priceAmount}</b> při nákupu od {pieces} ks',
  },
});
