import * as React from 'react';

import { IStyledComponent } from 'styled-components';

import ErrorBoundary from '@components/ErrorBoundary';
import { HorizontalChevron } from '@sharedComponents/Icons/HorizontalChevron';

import { Title, ChevronIconWrapper, TitleButton } from './styled';

export interface ISubtitleProps
  extends Omit<React.HTMLAttributes<Element>, 'title'> {
  isExpanded?: boolean;
  title?: string | React.ReactNode;
  handleExpand?: (e: React.SyntheticEvent | React.KeyboardEvent) => void;
  hideChevron?: boolean;
}

export const TabTitle: React.FC<ISubtitleProps> = ({
  isExpanded,
  title,
  handleExpand,
  role,
  hideChevron,
  ...rest
}) => {
  // eslint-disable-next-line
  const TitleComponent: IStyledComponent<'web', any> =
    role === 'button' ? TitleButton : Title;
  return (
    <ErrorBoundary>
      <TitleComponent
        {...rest}
        {...(role !== 'button' && { 'data-testid': 'tab-content-title' })}
        onClick={handleExpand}
      >
        {title}

        {!hideChevron && (
          <ChevronIconWrapper>
            <HorizontalChevron
              color="icon.primary"
              width="16px"
              height="16px"
              isOpened={isExpanded}
            />
          </ChevronIconWrapper>
        )}
      </TitleComponent>
    </ErrorBoundary>
  );
};
