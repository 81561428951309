import { ReviewOrderBy } from '@notino/shared/definitions/types';

import { IOrderBy } from '@containers/ProductDetailContainer/model';

export interface IReviewItemsContext {
  actions: IActions;
  state: IReviewItemsState;
}

export interface IReviewItemsState {
  orderBy: ReviewOrderBy;
  orderDesc: boolean;
  page: number;
  nextPage: number;
  hideTranslated: boolean;
  allowHideTranslatedCheckbox: boolean;
}

export interface IActions {
  changeOrder: (order: IOrderBy) => void;
  incNextPage: () => void;
  toggleHideTranslatedReviews: () => void;
}

export enum ActionTypes {
  CHANGE_ORDER = 'CHANGE_ORDER',
  INC_NEXT_PAGE = 'INC_NEXT_PAGE',
  TOGGLE_HIDE_TRANSLATED_REVIEWS = 'TOGGLE_HIDE_TRANSLATED_REVIEWS',
}

export type Action =
  | { type: ActionTypes.CHANGE_ORDER; value: IOrderBy }
  | { type: ActionTypes.INC_NEXT_PAGE }
  | { type: ActionTypes.TOGGLE_HIDE_TRANSLATED_REVIEWS };
