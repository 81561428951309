import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@apollo/client';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import { GetShowUnitPricesQuery } from '@notino/shared/definitions/types';

import PriceLabel from '@components/PriceLabel';
import { ILocale } from '@containers/App/model';
import { IPrice } from '@containers/ProductListing/model';
import getShowUnitPricesQuery from '@queries/showUnitPrices.graphql';
import { UnitPriceDecimalPlaces } from '@utils/constants';

import { messages } from './messages';
import { CurrencyWrapper, PriceWrapper } from './styled';

export interface IPriceRatioProps {
  stockAvailability: string;
  unitAmount: number;
  locale: ILocale;
  unit: string;
  unitPrice?: IPrice;
  settingsQuery?: GetShowUnitPricesQuery;
  prepend?: JSX.Element;
}

export const showPriceRatio = (
  queryResult: GetShowUnitPricesQuery,
  stockAvailability: string,
  unitPrice: IPrice,
  unitAmount: number
): boolean =>
  queryResult.Settings &&
  queryResult.Settings.showUnitPrices &&
  stockAvailability !== IStock.outOfStock &&
  unitPrice &&
  !!unitAmount;

export const PriceRatio: React.FC<IPriceRatioProps> = ({
  unitAmount,
  unit,
  stockAvailability,
  unitPrice,
  settingsQuery: settingsQueryResultProps,
  prepend,
}) => {
  const { data: settingsQueryResult } = useQuery<GetShowUnitPricesQuery>(
    getShowUnitPricesQuery,
    {
      ssr: true,
      skip: Boolean(settingsQueryResultProps),
    }
  );

  const queryResult = settingsQueryResultProps || settingsQueryResult;

  if (!showPriceRatio(queryResult, stockAvailability, unitPrice, unitAmount)) {
    return null;
  }

  return (
    <>
      {prepend}
      <span>
        <PriceLabel
          price={{
            value: unitPrice.value,
            decimalPlaces: UnitPriceDecimalPlaces,
            currency: unitPrice.currency,
          }}
          wrapperComponent={PriceWrapper}
          currencyComponent={CurrencyWrapper}
        />{' '}
        / {`${unitAmount}\u00A0${unit}`}
        <FormattedMessage {...messages.taxIncludedDE} />
      </span>
    </>
  );
};
