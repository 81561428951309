import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import {
  IconRegularCoupon,
  breakpoints,
  theme,
} from '@notino/react-styleguide';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 2px 8px;
  transform: translateX(-19px);
  background-color: ${theme.color.background.highlight};
  color: ${theme.color.text.inverse};
  ${theme.typography.labelSmall}

  @media (min-width: ${breakpoints.md}) {
    transform: translateX(-31px);
  }
  @media (min-width: ${breakpoints.lg}) {
    transform: translateX(-16px);
  }
`;

export const VoucherLabel = () => {
  return (
    <Wrapper data-testid="voucher-label">
      <IconRegularCoupon color="icon.inverse" width="20px" height="20px" />
      <FormattedMessage
        id="pd.gallery.voucherLabel.text"
        defaultMessage="Sleva s kódem"
      />
    </Wrapper>
  );
};
