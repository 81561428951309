import * as Sentry from '@sentry/browser';

import { EPictograms } from './model';

export const getPictogramIcon = (pictogram: EPictograms) => {
  try {
    return require(`./icons/${pictogram}.svg`);
  } catch (error) {
    Sentry.captureMessage(`Wrong pictogram path, pictogram: ${pictogram}`);
  }
};
