import styled from 'styled-components';

import { keyframes } from '@notino/react-styleguide';

const rotation8Steps = keyframes`
  0%, 100% { transform: rotate(0deg); }
  12.5% { transform: rotate(45deg); }
  25% { transform: rotate(90deg); }
  37.5% { transform: rotate(135deg); }
  50% { transform: rotate(180deg); }
  62.5% { transform: rotate(225deg); }
  75% { transform: rotate(270deg); }
  87.5% { transform: rotate(315deg); }
`;

interface IReviewsFetchingProps {
  minHeight?: string;
}

export const ReviewsFetching = styled.div<IReviewsFetchingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3.125rem 0;
  min-height: ${({ minHeight = 'auto' }) => minHeight};
  svg {
    animation: ${rotation8Steps} 1s infinite step-start;
  }
`;
