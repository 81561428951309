import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PortalTooltip } from '@components/PortalTooltip';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { getPictogramIcon } from './constants';
import {
  pictogramMoreInfoPathname,
  pictogramMoreInfoLinkText,
  pictogramTitles,
  pictogramTooltip,
} from './messages';
import { EPictograms } from './model';
import {
  MoreInfoLink,
  PictogramImage,
  PictogramItemWrapper,
  TooltipContent,
} from './styled';
import {
  trackOnClickMoreInfoTooltip,
  trackPictograms,
} from './trackPictograms';

interface IPictogramProps {
  pictogram: EPictograms;
}

export const Pictogram = ({ pictogram }: IPictogramProps) => {
  const { formatMessage } = useIntl();
  const { getTimeFromInit } = useTrackingContext();
  const { onHide, onShow } = useTrackTooltipShowDelayed({ pictogram });

  if (!pictogramTooltip[pictogram] || !pictogramTitles[pictogram]) {
    return null;
  }

  const onLinkClick = () => {
    trackOnClickMoreInfoTooltip(getTimeFromInit(), pictogram);
  };

  const moreInfoPathname = formatMessage(pictogramMoreInfoPathname);

  return (
    <PictogramItemWrapper data-testid="pictogram-item">
      <PortalTooltip
        interactive={true}
        dataTestId="pictogram-tooltip"
        content={
          <PortalTooltip.Content shouldWrapText={false}>
            <TooltipContent>
              <FormattedMessage {...pictogramTooltip[pictogram]} />
              {moreInfoPathname !== 'null' && (
                <MoreInfoLink
                  href={moreInfoPathname}
                  rel="nofollow"
                  target="__blank"
                  onClick={onLinkClick}
                >
                  {formatMessage(pictogramMoreInfoLinkText)}
                </MoreInfoLink>
              )}
            </TooltipContent>
          </PortalTooltip.Content>
        }
        onShow={onShow}
        onHide={onHide}
      >
        <PictogramImage
          src={getPictogramIcon(pictogram)}
          data-testid="pictogram-icon"
          alt={formatMessage(pictogramTooltip[pictogram])}
        />
      </PortalTooltip>
      <FormattedMessage {...pictogramTitles[pictogram]} />
    </PictogramItemWrapper>
  );
};

const useTrackTooltipShowDelayed = ({ pictogram }: IPictogramProps) => {
  const { getTimeFromInit } = useTrackingContext();
  const timout = React.useRef<NodeJS.Timeout>();

  const onShow = () => {
    clearTimeout(timout.current); // tap on mobile triggers this twice for some reason, so I clear the first tap
    timout.current = setTimeout(
      () => trackPictograms(getTimeFromInit(), pictogram),
      250
    );
  };

  const onHide = () => {
    clearTimeout(timout.current);
  };

  return {
    onShow,
    onHide,
  };
};
