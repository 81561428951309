import * as React from 'react';

import { CdnImage } from '@components/CdnImage';

import { GiftImgWrapper } from './styled';

interface IGiftImageProps {
  imageUrl: string;
  formattedImageMessage: string;
}

export const GiftImage: React.FC<IGiftImageProps> = ({
  imageUrl,
  formattedImageMessage,
}) => (
  <GiftImgWrapper>
    <CdnImage src={imageUrl} alt={formattedImageMessage} sourceKey="list_2k" />
  </GiftImgWrapper>
);
