import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { IProduct } from '@containers/ProductListing/model';

import { dispatchProductsShownEvent } from '../hooks/useDispatchProductsShowEvent';
import { ISectionContainerProps, SectionContainer } from '../SectionContainer';

import { ProductListContent } from './ProductItemsContent';
import { useProductsByIdsBatched } from './useProductsByIdsBatched';

export interface IProductsSectionProps
  extends Omit<ISectionContainerProps, 'children'> {
  productIds: Array<IProduct['id']>;
  productIdsLoading?: boolean;
  trackProductClick?: boolean;
  trackProductImpression?: boolean;
  attributionToken?: string;
}

export const ProductsSection: React.FC<IProductsSectionProps> = React.memo(
  ({
    productIds,
    listLabel = '',
    containerId = '',
    titleId = '',
    productIdsLoading = false,
    titleMessage = null,
    withDivider = false,
    trackProductClick = false,
    trackProductImpression = false,
    attributionToken,
  }) => {
    const [ref, inView] = useInView({ triggerOnce: true });

    const { loading, data } = useProductsByIdsBatched(productIds, {
      skip: !inView || productIdsLoading,
      onCompleted: (response) => {
        dispatchProductsShownEvent(
          response?.vpProductByIds.map((p) => ({ ...p, webId: p.id })),
          {
            section: listLabel,
          }
        );
      },
    });

    const noRecommendedProducts = !loading && !data?.vpProductByIds?.length;
    if (noRecommendedProducts && inView && !productIdsLoading) {
      return null;
    }

    return (
      <SectionContainer
        containerId={containerId}
        key={containerId}
        withDivider={withDivider}
        titleMessage={titleMessage}
        titleId={titleId}
        ref={ref}
      >
        <ProductListContent
          loading={loading || productIdsLoading}
          inView={inView}
          data={data}
          trackProductClick={trackProductClick}
          trackProductImpression={trackProductImpression}
          listLabel={listLabel}
          attributionToken={attributionToken}
        />
      </SectionContainer>
    );
  }
);
