import { QueryHookOptions, useQuery } from '@apollo/client';

import {
  LoggedInUserReviewQuery,
  LoggedInUserReviewQueryVariables,
} from '@notino/shared/definitions/types';

import loggedInUserReviewQuery from './queries/loggedInUserReview.graphql';

export const useLoggedInUserReviewFetch = (
  masterProductCode: string,
  options: QueryHookOptions<
    LoggedInUserReviewQuery,
    LoggedInUserReviewQueryVariables
  >
) => {
  return useQuery<LoggedInUserReviewQuery, LoggedInUserReviewQueryVariables>(
    loggedInUserReviewQuery,
    {
      variables: {
        masterProductCode,
      },
      ...options,
    }
  );
};
