import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ButtonModel,
  ModalContext,
  Text,
  useBreakpointValue,
} from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { ClickAndCollectContent } from './Content';
import { messages as modalMessages } from './Content/messages';
import {
  trackModalOrDrawerClose,
  trackModalOrDrawerOpen,
} from './gtm/tracking';
import { messages } from './messages';
import { PickUpButton, PickUpButtonWrapper } from './styled';

type IClickAndCollectProps = {
  selectedVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  onModalClosing: () => void;
  disabled?: boolean;
};

export const ClickAndCollect: React.FC<IClickAndCollectProps> = ({
  selectedVariant,
  product,
  onModalClosing,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();
  const { getTimeFromInit } = useTrackingContext();
  const isDesktop = useBreakpointValue({ md: true });
  const { toggleModal, hideModal } = ModalContext.useModalContext();
  const { toggleDrawer } = useDrawer();
  const header = formatMessage(modalMessages.chooseStore);

  const onOpenClickAndCollectModalOrDrawer = React.useCallback(() => {
    trackModalOrDrawerOpen({ timing: getTimeFromInit() });

    if (!isDesktop) {
      toggleDrawer(
        <ClickAndCollectContent
          selectedVariant={selectedVariant}
          product={product}
        />,
        {
          header,
          onDismiss: () =>
            trackModalOrDrawerClose({ timing: getTimeFromInit() }),
        }
      );
      return;
    }

    const handleCloseTracking = () => {
      hideModal();
      trackModalOrDrawerClose({ timing: getTimeFromInit() });
      onModalClosing();
    };

    toggleModal(
      <ClickAndCollectContent
        selectedVariant={selectedVariant}
        product={product}
      />,
      {
        header: (
          <Text data-testid="clickAndCollectTitle" typography="titleLarge">
            {header}
          </Text>
        ),
        center: true,
        withFocusTrap: true,
        onClose: handleCloseTracking,
        noBorders: true,
        onlyTopBorder: true,
        modalDataTestId: 'clickAndCollectModal',
      }
    );
  }, [
    getTimeFromInit,
    isDesktop,
    toggleModal,
    selectedVariant,
    product,
    header,
    toggleDrawer,
    hideModal,
    onModalClosing,
  ]);

  return (
    <PickUpButtonWrapper>
      <PickUpButton
        data-testid="click-and-collect"
        fullWidth={true}
        buttonStyle={ButtonModel.Styles.secondary}
        onClick={onOpenClickAndCollectModalOrDrawer}
        disabled={disabled}
      >
        <FormattedMessage {...messages.pickupNow} />
      </PickUpButton>
    </PickUpButtonWrapper>
  );
};
