import { createStore } from 'zustand';

import { IModalProps, ModalContext } from '@notino/react-styleguide';

import { ToggleDrawerFn } from '@components/BottomSheet/DrawerProvider';
import { RequestState } from '@containers/App/model';
import { isDesktop } from '@utils/helpers';

import { IAddToCartResult, IModal } from '../context/model';
import { ProductWithCount } from '../model';
import { runTrackingEventCartUpdate } from '../utils';

type HandleProductAddedToCartParams = {
  products: ProductWithCount[];
  result: IAddToCartResult;
  modal: IModal;
};

type AddToCartStore = {
  sendState: RequestState;
  submitAttempts: number;
  mouseOverBuyButton: boolean;
  actions: {
    setMouseOverBuyButton: () => void;
    handleProductAddedToCart: (params: HandleProductAddedToCartParams) => void;
    handleIncreaseSubmitAttempts: () => void;
    handleFailedAddToCart: () => void;
  };
};

type CreateAddToCartStoreParams = {
  toggleModal: ReturnType<typeof ModalContext.useModalContext>['toggleModal'];
  toggleDrawer: ToggleDrawerFn;
  getModalAfterAddToCart?: (
    props: IModal & IAddToCartResult & { products: ProductWithCount[] }
  ) => [JSX.Element, IModalProps];
};

export const createAddToCartStore = ({
  toggleModal,
  getModalAfterAddToCart,
  toggleDrawer,
}: CreateAddToCartStoreParams) =>
  createStore<AddToCartStore>((set, get) => ({
    sendState: RequestState.DEFAULT,
    submitAttempts: 0,
    mouseOverBuyButton: false,
    actions: {
      setMouseOverBuyButton: () => set({ mouseOverBuyButton: true }),
      handleFailedAddToCart: () => set({ sendState: RequestState.FAILED }),

      handleIncreaseSubmitAttempts: () =>
        set((state) => ({
          sendState:
            state.submitAttempts === 0
              ? RequestState.IN_PROGRESS
              : RequestState.RETRYING,
          submitAttempts: state.submitAttempts + 1,
        })),

      handleProductAddedToCart: ({ result, modal, products }) => {
        if (!result?.success && !result?.notification) {
          get().actions.handleFailedAddToCart();
          return;
        }

        set({ submitAttempts: 0, sendState: RequestState.DONE });

        products.forEach(({ id, count }) =>
          runTrackingEventCartUpdate({
            productId: id,
            quantity: count,
            action: 'add',
          })
        );

        if (!modal) {
          return;
        }

        const modalProps = getModalAfterAddToCart({
          products,
          ...modal,
          ...result,
        });

        if (modalProps && !isDesktop() && modal.type !== 'upselling') {
          const [content, options] = modalProps;

          toggleModal(); // e.g. hide upselling modal when addToCart clicked from it
          toggleDrawer(content, {
            header: options.header,
          });
          return;
        }

        if (modalProps) {
          toggleModal(...modalProps);
        }
      },
    },
  }));
