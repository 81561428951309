import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ObservableQueryFields } from '@apollo/client';

import { ButtonModel, Checkbox } from '@notino/react-styleguide';
import {
  GetReviewsQuery,
  GetReviewsQueryVariables,
} from '@notino/shared/definitions/types';

import { useShouldShowReviewPage } from '@containers/ProductDetailContainer/ProductDetail/hooks/useShouldShowReviewPage';
import { DEFAULT_REVIEW_COUNT } from '@containers/ReviewsPage/constants';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useProductView } from '@context/product-view';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';

import messages from '../../../../../messages';
import { PAGE_SIZE } from '../../constants';
import { useReviewItemsContext } from '../context';
import {
  ShowNextWrapper,
  StyledButton,
  StyledCol,
  ReviewsList,
  ShowAllReviewsLink,
} from '../styled';

import { SingleReviewData } from './SingleReviewData';
import { CheckboxWrapper } from './styled';

interface IFetchDoneProps {
  textReviewsCount: number;
  items: GetReviewsQuery['reviews'];
  productCode: string;
  isAllReviewsPage?: boolean;
  loadReviews: ObservableQueryFields<
    GetReviewsQuery,
    GetReviewsQueryVariables
  >['fetchMore'];
}

export const FetchDoneBlock: React.FC<IFetchDoneProps> = ({
  textReviewsCount,
  items,
  productCode,
  loadReviews,
  isAllReviewsPage,
}) => {
  const { pd_has_all_reviews_page } = useFeatureFlags();
  const { getTimeFromInit } = useTrackingContext();
  const [isLoading, setLoading] = React.useState(false);
  const {
    state: {
      orderBy,
      orderDesc,
      nextPage,
      hideTranslated,
      allowHideTranslatedCheckbox,
    },
    actions: { incNextPage, toggleHideTranslatedReviews },
  } = useReviewItemsContext();

  const [loadMoreError, setLoadMoreError] = React.useState<boolean>(false);
  const { product } = useProductView();
  const { formatMessage } = useIntl();

  const handleLoadMore = React.useCallback(async () => {
    try {
      dispatchTrackingEvent({
        event: 'element_click',
        element: {
          action: 'show_more_reviews',
          type: isAllReviewsPage ? 'review' : 'product_detail',
          timing: getTimeFromInit(),
          interaction: 'click',
          name: 'reviews',
          promo_labels: undefined,
          mode: undefined,
        },
        _clear: true,
      });

      setLoading(true);

      await loadReviews({
        variables: {
          code: productCode,
          orderBy,
          orderDesc,
          page: isAllReviewsPage
            ? nextPage + (DEFAULT_REVIEW_COUNT / PAGE_SIZE - 1)
            : nextPage,
          pageSize: PAGE_SIZE,
          hideTranslated,
        },
      });
      incNextPage();
    } catch (e) {
      setLoadMoreError(true);
    } finally {
      setLoading(false);
    }
  }, [
    isAllReviewsPage,
    getTimeFromInit,
    loadReviews,
    productCode,
    orderBy,
    orderDesc,
    nextPage,
    hideTranslated,
    incNextPage,
  ]);

  const displayShowMoreButton = textReviewsCount > items.length;
  const { rating: ratingSummary } = product.reviewOverview;

  const shouldShowAllReviewsLink =
    useShouldShowReviewPage(textReviewsCount, ratingSummary) &&
    !isAllReviewsPage;

  const allReviewsPageLink = pd_has_all_reviews_page
    ? formatMessage(messages.redirectReviewsPageLink)
    : null;

  const handleTrackShowAllReviews = () => {
    dispatchTrackingEvent({
      event: 'element_click',
      element: {
        action: 'all',
        type: 'product_detail',
        timing: getTimeFromInit(),
        name: 'all_reviews',
      },
      _clear: true,
    });
  };

  return (
    <>
      <StyledCol data-cypress="reviews-listing" xs={12} md={10}>
        {((items.some((item) => item.translatedFrom) &&
          items.some((item) => !item.translatedFrom)) ||
          allowHideTranslatedCheckbox) && (
          <CheckboxWrapper>
            <Checkbox
              checked={hideTranslated}
              onChange={toggleHideTranslatedReviews}
            >
              <FormattedMessage {...messages.onlyOriginalReviews} />
            </Checkbox>
          </CheckboxWrapper>
        )}

        <ReviewsList>
          {items?.map((item, index) => (
            <SingleReviewData item={item} key={item.id} index={index} />
          ))}
        </ReviewsList>
      </StyledCol>

      {(shouldShowAllReviewsLink || displayShowMoreButton) && (
        <StyledCol xs={12}>
          <ShowNextWrapper shouldShowAllReviewsLink={shouldShowAllReviewsLink}>
            {displayShowMoreButton && (
              <StyledButton
                data-cypress="reviews-loadMore"
                data-testid="reviews-loadMore"
                loading={isLoading}
                error={Boolean(loadMoreError)}
                buttonStyle={ButtonModel.Styles.secondary}
                onClick={handleLoadMore}
              >
                <FormattedMessage
                  {...(loadMoreError
                    ? messages.sendReviewRetry
                    : messages.showNext)}
                />
              </StyledButton>
            )}

            {shouldShowAllReviewsLink && (
              <ShowAllReviewsLink
                onClick={handleTrackShowAllReviews}
                href={`${allReviewsPageLink}${product.url}`}
              >
                <FormattedMessage {...messages.reviewsShowAll} />
              </ShowAllReviewsLink>
            )}
          </ShowNextWrapper>
        </StyledCol>
      )}
    </>
  );
};
