import * as React from 'react';
import { useIntl } from 'react-intl';

import { partition } from '@utils/array';

import { IConditionalVoucherDiscount } from '../../ConditionalVoucherDiscount';
import { ConditionsTooltip } from '../../ConditionalVoucherDiscount/ConditionalInstructionsText/ConditionsTooltip';
import { Link } from '../../ConditionalVoucherDiscount/ConditionalInstructionsText/styled';

import { messages } from './messages';

type Props = {
  discount: IConditionalVoucherDiscount;
};

export const ConditionalInstructionsText = ({
  discount: { targets },
}: Props) => {
  const { formatMessage } = useIntl();

  const [brands, categories] = partition(targets, 'type', [
    'Brand',
    'Category',
  ]);

  let formattedMessage;
  if (brands.length > 0 && categories.length > 0) {
    formattedMessage = formatMessage(messages.brandsNCategoriesN, {
      these: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (brands.length > 1) {
    formattedMessage = formatMessage(messages.brandsNCategories0, {
      brands: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (categories.length > 1) {
    formattedMessage = formatMessage(messages.brands0CategoriesN, {
      categories: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (brands.length === 1) {
    formattedMessage = formatMessage(messages.brands1Categories0, {
      brand: (
        <Link href={brands[0].url}>
          <u>{brands[0].name}</u>
        </Link>
      ),
    });
  } else if (categories.length === 1) {
    formattedMessage = formatMessage(messages.brands0Categories1, {
      category: (
        <Link href={categories[0].url}>
          <u>{categories[0].name}</u>
        </Link>
      ),
    });
  } else {
    formattedMessage = formatMessage(messages.selectedProducts);
  }

  return formattedMessage;
};
