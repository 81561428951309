import * as React from 'react';
import { useLocation } from 'react-router';

import { useApolloClient } from '@apollo/client';

import { Spinner } from '@notino/react-styleguide';
import { variantIdFromUrl } from '@notino/shared/client-utils';
import { GetLoadingProductQuery } from '@notino/shared/definitions/types';

import getLoadingProductQuery from '@containers/ProductDetailContainer/queries/loadingProduct.graphql';

import { CenterWrapper } from '../styled';

import { SpaLoadingSkeleton } from './SpaLoadingSkeleton';

interface ISkeletonProps {
  productId: string;
}

export const Loading: React.FC<ISkeletonProps> = ({ productId }) => {
  const { cache } = useApolloClient();
  const { pathname } = useLocation();

  const variantId = variantIdFromUrl(pathname);
  let loadingProduct;
  if (variantId) {
    // if variant is in cache in its basic form, use this basic form to show skeleton loading
    loadingProduct = cache.readQuery<GetLoadingProductQuery>({
      query: getLoadingProductQuery,
      variables: {
        id: String(variantId),
      },
    });
  } else {
    // if product is in cache in its basic form, use this basic form to show skeleton loading
    loadingProduct = cache.readQuery<GetLoadingProductQuery>({
      query: getLoadingProductQuery,
      variables: {
        id: String(productId),
      },
    });
  }

  if (loadingProduct?.vpProductById) {
    return (
      <SpaLoadingSkeleton
        product={loadingProduct.vpProductById}
        imageDomainPath={loadingProduct.Settings.imageDomainPath}
      />
    );
  }

  return (
    <CenterWrapper>
      <Spinner color="icon.primary" size={32} />
    </CenterWrapper>
  );
};
