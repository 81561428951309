import styled, { css } from 'styled-components';

import { breakpoints, IconSolidInfo, theme } from '@notino/react-styleguide';
export const TagDiscountFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;

  @media (min-width: ${breakpoints.md}) {
    margin-top: auto;
  }

  min-height: 2.5rem;
`;

export const DiscountWrapper = styled.div`
  text-align: right;
  line-height: 1.25rem;
`;

export const OriginalPriceWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  color: ${theme.color.text.tertiary};
  ${theme.typography.labelRegular400}
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LineThrough = styled.span<{ active: boolean }>`
  ${({ active }) => active && 'text-decoration: line-through'};
  white-space: nowrap;
`;

export const Discount = styled.span<{
  isSmall: boolean;
}>`
  color: ${theme.color.text.highlight};
  ${({ isSmall }) =>
    css`
      ${isSmall ? theme.typography.labelRegular : theme.typography.labelLarge}
    `}
`;

export const PriceWrapper = styled.span`
  font-size: 0.8rem;
`;

export const CurrencyWrapper = styled(PriceWrapper)`
  &::before {
    content: ' ';
  }
`;

export const StyledInfoIcon = styled(IconSolidInfo)`
  margin-bottom: 4px;
`;

export const Slash = styled.span`
  padding: 0 0.25rem;
`;
