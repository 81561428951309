import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

const TabWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

export default TabWrapper;
