import { DependencyList, useEffect, useRef, useState } from 'react';

import { Fade, Arrow } from './styled';

const getShowLeftArrow = (
  wrapper: HTMLDivElement,
  showArrowCalulationOffset: number
) => {
  const isScrollable = wrapper.scrollWidth !== wrapper.clientWidth;
  const isScrolledToStart = wrapper.scrollLeft < showArrowCalulationOffset;
  return isScrollable && !isScrolledToStart;
};

const getShowRightArrow = (
  wrapper: HTMLDivElement,
  showArrowCalulationOffset: number
) => {
  const isScrollable = wrapper.scrollWidth !== wrapper.clientWidth;
  const isScrolledToEnd =
    wrapper.scrollWidth - wrapper.scrollLeft - wrapper.clientWidth <
    showArrowCalulationOffset;
  return isScrollable && !isScrolledToEnd;
};

function calculateNewLeft(
  direction: 'left' | 'right',
  el: HTMLDivElement,
  scrollAmount: number,
  threshold: number
) {
  let newScrollLeft: number;
  if (direction === 'left') {
    newScrollLeft = el.scrollLeft - scrollAmount;

    const isReallyCloseToLeft = newScrollLeft < threshold;
    if (isReallyCloseToLeft) {
      // if really close to left, scroll to the left edge
      newScrollLeft = 0;
    }
  } else {
    newScrollLeft = el.scrollLeft + scrollAmount;

    const isReallyCloseToRight =
      newScrollLeft + el.clientWidth >= el.scrollWidth - threshold;
    if (isReallyCloseToRight) {
      // if really close to right, scroll to the right edge
      newScrollLeft = el.scrollWidth;
    }
  }

  return newScrollLeft;
}

export const useArrows = (
  deps: DependencyList = [],
  { scrollAmount = 300, showArrowCalulationOffset = 1 } = {
    scrollAmount: 300,
    showArrowCalulationOffset: 1,
  }
) => {
  const containerRef = useRef<HTMLDivElement>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) {
      return;
    }

    const calculateArrowsHandler = () => {
      setShowLeftArrow(getShowLeftArrow(el, showArrowCalulationOffset));
      setShowRightArrow(getShowRightArrow(el, showArrowCalulationOffset));
    };

    const mutationObserver = new MutationObserver(calculateArrowsHandler);

    calculateArrowsHandler();

    window.addEventListener('resize', calculateArrowsHandler);
    el.addEventListener('scroll', calculateArrowsHandler);
    mutationObserver.observe(el, { childList: true });

    return () => {
      window.removeEventListener('resize', calculateArrowsHandler);
      el.removeEventListener('scroll', calculateArrowsHandler);
      mutationObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const scrollTo = (direction: 'left' | 'right') => {
    const el = containerRef.current;
    const threshold = scrollAmount / 6;

    const newScrollLeft = calculateNewLeft(
      direction,
      el,
      scrollAmount,
      threshold
    );

    el.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
  };

  return { scrollTo, showLeftArrow, showRightArrow, containerRef };
};

export { Fade, Arrow };
