import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Divider } from '@components/Divider';

import { Clear } from '../styled';

import { Container, MarginWrapper, Title } from './styled';

export interface ISectionContainerProps {
  titleMessage?: MessageDescriptor;
  titleId?: string;
  containerId?: string;
  listLabel?: string;
  withDivider?: boolean;
  children: React.ReactNode;
}

export const SectionContainer = React.forwardRef<
  HTMLDivElement,
  ISectionContainerProps
>(({ containerId, withDivider, titleId, titleMessage, children }, ref) => {
  return (
    <>
      <Container
        id={containerId}
        key={containerId}
        withDivider={withDivider}
        data-testid="pd-blog-articles-header"
      >
        <MarginWrapper ref={ref}>
          {withDivider && <Divider />}

          {Boolean(titleMessage) && (
            <Title data-testid={titleId} id={titleId}>
              <FormattedMessage {...titleMessage} />
            </Title>
          )}

          {children}
        </MarginWrapper>
      </Container>

      <Clear />
    </>
  );
});
