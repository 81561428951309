import * as React from 'react';
import { useLocation } from 'react-router';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

export const useOpenBVModal = (
  containerRef: React.MutableRefObject<HTMLDivElement>
) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { bazaarvoice_integration_pd } = useFeatureFlags();

  React.useEffect(() => {
    const currentRef = containerRef.current;

    const onOpenModal = () => {
      const mailQuery = searchParams.get('mail');

      if (mailQuery === '1' && bazaarvoice_integration_pd) {
        const firstReviewButton = currentRef.querySelector('#first-to-write');
        const moreToWriteButton = currentRef.querySelector('.bv-write-review');

        if (firstReviewButton) {
          (firstReviewButton as HTMLButtonElement).click();
          return;
        }

        if (moreToWriteButton) {
          (moreToWriteButton as HTMLButtonElement).click();
          return;
        }
      }
    };

    if (currentRef) {
      const observer = new MutationObserver(onOpenModal);
      observer.observe(currentRef, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
