import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

export const TierDiscountHighlight = styled.span<{ hasContrastBg: boolean }>`
  font-weight: 500;
  color: ${({ hasContrastBg }) =>
    hasContrastBg ? theme.color.text.inverse : theme.color.text.primary};
`;

export const TierRow = styled.div<{ hasContrastBg: boolean }>`
  ${theme.typography.labelSmall400};
  color: ${({ hasContrastBg }) =>
    hasContrastBg ? theme.color.text.inverse : theme.color.text.secondary};
  margin-bottom: 0.25rem;
`;
