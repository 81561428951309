import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  inStock: {
    id: 'product.item.in.stock',
    defaultMessage: 'skladem',
  },
  descriptionText: {
    id: 'product.item.black.friday.description.text',
    defaultMessage: 's kódem',
  },
  discountAmount: {
    id: 'product.item.discount.highlighted',
    defaultMessage: '-{amount} %',
  },
});
