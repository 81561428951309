import { snakeCase } from 'lodash';

import { ProductTileModel } from '@notino/react-styleguide';

import { dispatchTrackingEvent } from '@context/tracking/utils';

const NON_TRACKABLE_TAGS = [
  ProductTileModel.ProductLabelAttributes.Damaged,
  ProductTileModel.ProductLabelAttributes.Returned,
];

export const trackTagClick = (
  attr: ProductTileModel.ProductLabelAttributes,
  timing: number
) => {
  if (NON_TRACKABLE_TAGS.includes(attr)) {
    return;
  }

  dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      action: 'click_on_element',
      name: attr,
      type: 'tag_discount_label',
      promo_labels: snakeCase(attr),
      interaction: 'click',
      timing,
    },
  });
};
