import { breakpoints, styled } from '@notino/react-styleguide';

export const Container = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 2rem;
  }
`;

export const SectionTitle = styled.strong`
  padding-top: 1.3rem;
`;

export const Icon = styled.img`
  width: 3rem;
  height: 3rem;
`;
