import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { useUser } from '@queries/useUser';

import { getAttributeToken } from './useGetAttributeToken';

export const useTrackPageView = (
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
) => {
  const { google_retail_api_user_events } = useFeatureFlags();

  const { user } = useUser();

  React.useEffect(() => {
    const attributionToken = getAttributeToken();

    dispatchTrackingEvent({
      event: 'page_view',
      page: {
        path: window.location.pathname,
        title: variant.metaData.title,
        type: 'product',
        unified_name: `product-${variant.productCode}`,
        subpage: undefined,
      },
      _clear: true,
    });

    if (google_retail_api_user_events) {
      dispatchTrackingEvent({
        event: 'cloud_retail',
        cloud_retail: {
          eventType: 'detail-page-view',
          eventTime: new Date().toISOString(),
          productDetails: [
            {
              product: {
                id: variant.productCode,
              },
            },
          ],
          visitorId: user.sid,
          ...(attributionToken && { attributionToken }),
        },
      });
    }
  }, [
    variant.productCode,
    variant.metaData.title,
    google_retail_api_user_events,
    user,
  ]);
};
