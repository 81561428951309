import { css } from 'styled-components';

import { TooltipModel, theme } from '@notino/react-styleguide';

import { TOOLTIP_OFFSET_PX } from './getTooltipPositionStyles';

import { BackgroundColor } from '.';

const topAndBottomCommon = css`
  left: 50%;
  transform: translateX(-50%);
`;
const leftAndRightCommon = css`
  top: 50%;
  transform: translateY(-50%);
`;

const arrowHeightPx = 10;
const arrowHeight = `${arrowHeightPx}px`;
const arrowOffset = TOOLTIP_OFFSET_PX - arrowHeightPx;

const topCommon = css`
  &::before {
    bottom: calc(100% + ${arrowOffset + 1}px);
  }
  &::after {
    bottom: calc(100% + ${arrowOffset}px);
    border-top: ${arrowHeight} solid ${theme.color.border.divider};
  }
`;
const bottomCommon = css`
  &::before {
    top: calc(100% + ${arrowOffset + 1}px);
  }
  &::after {
    top: calc(100% + ${arrowOffset}px);
    border-bottom: ${arrowHeight} solid ${theme.color.border.divider};
  }
`;
const leftCommon = css`
  &::before {
    right: calc(100% + ${arrowOffset + 1}px);
  }
  &::after {
    right: calc(100% + ${arrowOffset}px);
    border-left: ${arrowHeight} solid ${theme.color.border.divider};
  }
`;
const rightCommon = css`
  &::before {
    left: calc(100% + ${arrowOffset + 1}px);
  }
  &::after {
    left: calc(100% + ${arrowOffset}px);
    border-right: ${arrowHeight} solid ${theme.color.border.divider};
  }
`;

export const getArrowPositionStyles = ({
  position,
  backgroundColor,
}: {
  position: TooltipModel.Position;
  backgroundColor?: BackgroundColor;
}) => {
  switch (position) {
    case TooltipModel.Position.topCenter:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}
          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-top: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${topCommon}
      `;
    case TooltipModel.Position.topLeft:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}
          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-top: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${topCommon}
      `;
    case TooltipModel.Position.topRight:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}

          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-top: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${topCommon}
      `;
    case TooltipModel.Position.bottomCenter:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}
          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${bottomCommon}
      `;
    case TooltipModel.Position.bottomLeft:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}
          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${bottomCommon}
      `;
    case TooltipModel.Position.bottomRight:
      return css`
        &:after,
        &:before {
          ${topAndBottomCommon}
          border-left: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${bottomCommon}
      `;
    case TooltipModel.Position.leftCenter:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-left: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${leftCommon}
      `;
    case TooltipModel.Position.leftTop:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-left: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${leftCommon}
      `;
    case TooltipModel.Position.leftBottom:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-left: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${leftCommon}
      `;
    case TooltipModel.Position.rightCenter:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${rightCommon}
      `;
    case TooltipModel.Position.rightTop:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${rightCommon}
      `;
    case TooltipModel.Position.rightBottom:
      return css`
        &:after,
        &:before {
          ${leftAndRightCommon}
          border-top: ${arrowHeight} solid transparent;
          border-bottom: ${arrowHeight} solid transparent;
          border-right: ${arrowHeight} solid
            ${theme.color.background[backgroundColor] || 'white'};
        }
        ${rightCommon}
      `;

    default:
      throw Error('Invalid position' + position);
  }
};
