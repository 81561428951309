import { SemanticColorKey } from '@notino/react-styleguide';

interface IBannerThemeValue {
  fontColor: SemanticColorKey;
  backgroundColor: SemanticColorKey;
}

const isDateToday = (dateString: string): boolean => {
  const date = new Date(dateString);
  const today = new Date();

  return (
    date.getUTCFullYear() === today.getUTCFullYear() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCDate() === today.getUTCDate()
  );
};

export const getBannerTheme = (
  isLive: boolean,
  scheduledDate: string
): IBannerThemeValue => {
  if (isLive)
    return {
      fontColor: 'common.magenta50',
      backgroundColor: 'background.primary',
    };
  const isToday = isDateToday(scheduledDate);

  if (isToday)
    return {
      fontColor: 'text.inverse',
      backgroundColor: 'background.highlight',
    };
  return {
    fontColor: 'text.inverse',
    backgroundColor: 'background.inverse',
  };
};
