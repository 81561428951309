import { useEffect } from 'react';

export interface IBambuserProps {
  getItems: () => IItem[];
}

export interface IItem {
  showId: string;
  deeplink: string;
  node: HTMLElement;
}

export const useInitBambuserLiveShopping = ({ getItems }: IBambuserProps) => {
  useEffect(() => {
    if (!window.initBambuserLiveShopping) {
      window.initBambuserLiveShopping = function (item) {
        window.initBambuserLiveShopping.queue.push(item);
      };
    }

    if (!document.getElementById('bambuser-embed')) {
      window.initBambuserLiveShopping.queue = [];
      const scriptNode = document.createElement('script');
      scriptNode.id = 'bambuser-embed';
      scriptNode.src = 'https://lcx-embed.bambuser.com/notino/embed.js';
      document.body.appendChild(scriptNode);
    }

    if (!document.getElementById('bambuser-metrics')) {
      const scriptNodeTracking = document.createElement('script');
      scriptNodeTracking.id = 'bambuser-metrics';
      scriptNodeTracking.src =
        'https://cdn.liveshopping.bambuser.com/metrics/bambuser.min.js';
      document.body.appendChild(scriptNodeTracking);
    }

    const items = getItems();

    items.forEach((item) => {
      window.initBambuserLiveShopping({
        showId: item.showId,
        deeplink: item.deeplink,
        node: item.node,
        type: 'overlay',
      });
    });
  }, [getItems]);
};
