import * as React from 'react';

import {
  IconRegularChevronLeft,
  IconRegularChevronRight,
  IBasicStyledProps,
  SemanticColorKey,
} from '@notino/react-styleguide';

import { ArrowWrapper } from './styled';

interface IArrowProps extends IBasicStyledProps {
  direction: string;
  className?: string;
  color?: SemanticColorKey;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Arrow: React.FC<IArrowProps> = ({
  direction,
  onClick,
  className,
  color: customColor,
  ...basicStyledProps
}) => {
  const color = customColor || (onClick ? 'icon.secondary' : 'icon.disabled');
  const isLeft = direction === 'left';
  return (
    <ArrowWrapper
      className={className}
      onClick={onClick}
      isLeft={isLeft}
      {...basicStyledProps}
    >
      {isLeft ? (
        <IconRegularChevronLeft color={color} width="24px" height="24px" />
      ) : (
        <IconRegularChevronRight color={color} width="24px" height="24px" />
      )}
    </ArrowWrapper>
  );
};
