import * as React from 'react';

import { VoucherVariantFeatureFlag } from '@notino/shared/definitions/types';

import { ICurrency } from '@containers/App/model';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { Line } from '../SelectedVariant/styled';

import {
  ConditionalVoucherDiscount,
  IConditionalVoucherDiscount,
} from './ConditionalVoucherDiscount';
import { VoucherDiscountContext } from './context';
import { MultiTierVoucherDiscount } from './MultiTierVoucherDiscount';
import { LineWrapper } from './styled';
import { isConditionalDiscount } from './utils';
import { IVoucherDiscount, VoucherDiscount } from './VoucherDiscount';

interface IVoucherDiscountProps {
  voucherDiscount: IVoucherDiscount | IConditionalVoucherDiscount;
  currency: ICurrency;
  isUnderSelectBox?: boolean;
  hideLine?: boolean;
  isArrowHidden?: boolean;
}

export const VoucherDiscountMaster = ({
  voucherDiscount,
  hideLine,
  ...rest
}: IVoucherDiscountProps) => {
  const { voucher_variant } = useFeatureFlags();

  const getComponent = () => {
    if (isConditionalDiscount(voucherDiscount)) {
      return voucherDiscount.discountConditions.length > 1 ? (
        <MultiTierVoucherDiscount voucherDiscount={voucherDiscount} />
      ) : (
        <ConditionalVoucherDiscount voucherDiscount={voucherDiscount} />
      );
    }
    return <VoucherDiscount voucherDiscount={voucherDiscount} />;
  };

  const hasContrastBg =
    voucher_variant === VoucherVariantFeatureFlag.ContrastBackgroundWithLabel ||
    voucher_variant === VoucherVariantFeatureFlag.ContrastBackground;

  return (
    <VoucherDiscountContext.Provider value={{ ...rest, hasContrastBg }}>
      {getComponent()}

      {!hideLine && (
        <LineWrapper>
          <Line />
        </LineWrapper>
      )}
    </VoucherDiscountContext.Provider>
  );
};
