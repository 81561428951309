import { ListItem } from 'schema-dts';

import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';

import { IBreadcrumb } from './model';

const prepareListItem = (item: IBreadcrumb, index: number): ListItem => ({
  '@type': 'ListItem',
  position: index + 1,
  item: {
    '@id': item.link,
    name: item.name,
  },
});

export const prepareItemListElement = (items: IBreadcrumb[]) =>
  items.map(prepareListItem);

type BreadcrumbClickTrackingEvent = TrackingAttributes<{
  index: 0 | 1 | 2 | 3 | 4;
  name: string;
  link: string;
  text: string[];
}>;

export const trackBreadcrumbClick = ({
  name,
  link,
  index,
  variant,
  timing,
  text,
}: BreadcrumbClickTrackingEvent) => {
  dispatchTrackingEvent({
    event: 'breadcrumbs_click',
    breadcrumbs: {
      timing,
      type: `level_${index}`,
      name,
      path_query: link,
      interaction: 'click',
      variant,
      action: 'breadcrumbs_used',
      text,
    },
    _clear: true,
  });
};
