export const skaCountryConfig = {
  'notino.it': {
    partnerId: 'tC4ZmDdLCc',
    locale: 'it',
  },
  'notino.pl': {
    partnerId: 'Vl04MGlEhq',
    locale: 'pl',
  },
};
