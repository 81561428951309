import * as React from 'react';

import { Container, Description, MarginWrapper } from './styled';

export interface IAboutBrandProps {
  description?: string;
}

export const AboutBrand: React.FC<IAboutBrandProps> = React.memo(
  ({ description }) => {
    if (!description) {
      return null;
    }
    return (
      <Container id="pdAboutBrand">
        <MarginWrapper>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </MarginWrapper>
      </Container>
    );
  }
);
