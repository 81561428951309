/**
 * Import all Route components
 */
import ErrorPage from '@components/ErrorPage/loadable';
import NotFound from '@components/NotFound';
import AdventCalendar from '@containers/AdventCalendar/loadable';
import Home from '@containers/Home/loadable';
import ProductDetail from '@containers/ProductDetailContainer/loadable';
import ReviewsPage from '@containers/ReviewsPage/loadable';
import Sampling from '@containers/Sampling/loadable';
import SamplingThanks from '@containers/SamplingThanks/loadable';
import Tryandbuy from '@containers/Tryandbuy/loadable';
import TryandbuyError from '@containers/TryandbuyError/loadable';
import TryandbuyThanks from '@containers/TryandbuyThanks/loadable';
import WishList from '@containers/WishList/loadable';

/**
 * create dictionary of all loadable components
 */
const loadables = {
  Home,
  ProductDetail,
  ErrorPage,
  Tryandbuy,
  TryandbuyThanks,
  TryandbuyError,
  Sampling,
  SamplingThanks,
  NotFound,
  WishList,
  AdventCalendar,
  ReviewsPage,
};

export default loadables;
