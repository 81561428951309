import * as React from 'react';

import useMedia from 'use-media';

import { theme } from '@notino/react-styleguide';
import { GetProductsByIdBatchedQuery } from '@notino/shared/definitions/types';

import { ProductsSectionContent } from './ProductSectionContent';
import { StyledListPlaceholder } from './styled';

interface IProductListContentParams {
  loading: boolean;
  listLabel: string;
  inView: boolean;
  data: GetProductsByIdBatchedQuery;
  trackProductClick: boolean;
  trackProductImpression: boolean;
  attributionToken?: string;
}

export const ProductListContent = ({
  loading,
  listLabel,
  inView,
  data,
  trackProductClick,
  trackProductImpression,
  attributionToken,
}: IProductListContentParams) => {
  const isBigScreen = useMedia(`(min-width: ${theme.breakpoints.xxl}rem)`);
  const itemsPerSlide = isBigScreen ? 5 : 4;

  if (inView && !loading) {
    return (
      <ProductsSectionContent
        data={data}
        listLabel={listLabel}
        trackProductClick={trackProductClick}
        trackProductImpression={trackProductImpression}
        itemsPerSlide={itemsPerSlide}
        attributionToken={attributionToken}
      />
    );
  }
  return (
    <StyledListPlaceholder
      id={listLabel}
      placeholderCount={itemsPerSlide}
      rows={4}
      containerProps={{
        ['data-testid']: 'product-items-placeholder',
      }}
    />
  );
};
