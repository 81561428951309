import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  IconRegularPercent,
  ProductLabelModel,
  IconRegularDelivery,
  IconSolidStar,
  ProductTileModel,
} from '@notino/react-styleguide';

import messages from '@containers/ProductDetailContainer/messages';
import {
  AttributeTypes,
  AttributeType,
} from '@containers/ProductListing/model';

export interface ILabelAttribute {
  icon: JSX.Element;
  style: ProductLabelModel.Styles;
  tooltipMessage?: MessageDescriptor;
}

export enum ClientOnlyAttributeTypes {
  Damaged = 'Damaged',
  Returned = 'Returned',
}

export type ILabelAttributes = {
  [key in ProductTileModel.ProductLabelAttributes]: ILabelAttribute;
};

/** Label attributes definition */
export const labelAttributes: Omit<
  ILabelAttributes,
  ProductTileModel.ProductLabelAttributes.Gift
> = {
  [AttributeTypes.Action]: {
    style: ProductLabelModel.Styles.highlighted,
    icon: <IconRegularPercent />,
  },
  [AttributeTypes.FreeDelivery]: {
    style: ProductLabelModel.Styles.default,
    icon: <IconRegularDelivery />,
  },
  [AttributeTypes.New]: {
    style: ProductLabelModel.Styles.neutral,
    icon: <IconSolidStar />,
  },
  [AttributeTypes.Clearance]: {
    style: ProductLabelModel.Styles.highlighted,
    icon: <IconRegularPercent />,
  },
  [ClientOnlyAttributeTypes.Damaged]: {
    style: ProductLabelModel.Styles.neutral,
    icon: <IconRegularPercent />,
    tooltipMessage: messages.productDamaged,
  },
  [ClientOnlyAttributeTypes.Returned]: {
    style: ProductLabelModel.Styles.neutral,
    icon: <IconRegularPercent />,
    tooltipMessage: messages.productReturned,
  },
};

export interface ITagsProps {
  attributes?: AttributeType;
  stockAvailability: string;
  small?: boolean;
  multiple?: boolean;
  type?: ProductLabelModel.Types;
  icon?: string;
  onClick?: (attribute: ProductTileModel.ProductLabelAttributes) => void;
  nowrapLg?: boolean;
}
