import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { NotificationModalOrDrawerContentWrapper } from './styled';

interface INotificationModalOrDrawerContentProps {
  statusMessages: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messagesValues?: any;
}

export const NotificationModalOrDrawerContent: React.FC<
  INotificationModalOrDrawerContentProps
> = ({ statusMessages, messagesValues }) => {
  if (!statusMessages) {
    return null;
  }

  return (
    <NotificationModalOrDrawerContentWrapper>
      {statusMessages.map((messageKey) => (
        <div key={messageKey}>
          <FormattedMessage
            {...messages[messageKey]}
            values={{
              ...messagesValues,
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      ))}
    </NotificationModalOrDrawerContentWrapper>
  );
};
