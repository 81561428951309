import { CatalogVariantFragmentFragment } from 'packages/shared/definitions/types';

import { IVariant } from '@containers/ProductListing/model';

export interface IWatchdogFields {
  watchdogEmail: string;
}

export interface IWatchdogProps {
  variant:
    | Pick<IVariant, 'showWatchdog' | 'productCode' | 'url' | 'catalogId'>
    | Pick<
        CatalogVariantFragmentFragment,
        'availability' | 'productCode' | 'url' | 'catalogId'
      >;
  id: string;
  hasRegisteredWatchdog?: boolean;
  children:
    | JSX.Element
    | ((renderProps: { watchdogActive: boolean }) => JSX.Element);
}

export interface IWatchdogState {
  errors: string[];
  status: WatchdogComm;
  submitAttempts: number;
}

export enum WatchdogComm {
  SENDING = 'sending',
  IDLE = 'idle',
  ERROR = 'error',
}
