import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import {
  LivestreamCard,
  ReactFCC,
  useBreakpointValue,
} from '@notino/react-styleguide';

import messages from '@containers/ProductDetailContainer/messages';

import { ILivestream } from './model';
import {
  Description,
  DescriptionWrapper,
  InfoWrapper,
  LivestreamBannerWrapper,
  StyledButton,
  Title,
} from './styled';
import { getBannerTheme } from './utils/getBannerTheme';

interface ILivestreamBannerProps extends ILivestream {
  buttonId: string;
  livestreamCardId: string;
  fullwidth?: boolean;
  onCardClick?: () => void;
  onButtonClick?: () => void;
}

export const LivestreamBanner: ReactFCC<ILivestreamBannerProps> = ({
  fullwidth,
  description,
  isLive,
  previewImageUrl,
  scheduledStart,
  videoLength,
  publishedDate,
  scheduledDate,
  title,
  buttonId,
  livestreamCardId,
  onCardClick,
  onButtonClick,
}) => {
  const { backgroundColor, fontColor } = getBannerTheme(isLive, scheduledDate);
  const isOversizedDesktop = useBreakpointValue(
    { xxl: true },
    { ssrValue: false }
  );

  return (
    <LivestreamBannerWrapper>
      <LivestreamCard
        onClick={onCardClick}
        showId={livestreamCardId}
        title={title}
        description={description}
        previewImageUrl={previewImageUrl}
        isLive={isLive}
        showDescription={false}
        scheduledStart={scheduledStart}
        videoLength={videoLength}
        scheduledStartIndicatorBackgroundColor={backgroundColor}
        scheduledStartIndicatorColor={fontColor}
        publishedDate={
          <FormattedDate
            value={publishedDate}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        }
        customThemeConfig={
          isOversizedDesktop
            ? {
                container: {
                  width: '19.625rem',
                  height: '34.4375rem',
                  lineClamp: 9,
                },
              }
            : {
                container: {
                  width: '15rem',
                  height: '26.25rem',
                  lineClamp: 9,
                },
              }
        }
      />
      <InfoWrapper fullwidth={fullwidth}>
        <Title>{title}</Title>
        <DescriptionWrapper>
          <Description>{description}</Description>
        </DescriptionWrapper>
        <StyledButton id={buttonId} onClick={onButtonClick}>
          <FormattedMessage {...messages.livestreamsPlayButton} />
        </StyledButton>
      </InfoWrapper>
    </LivestreamBannerWrapper>
  );
};
