import styled, { css } from 'styled-components';

import {
  Row,
  Col,
  ActionButton,
  breakpoints,
  theme,
} from '@notino/react-styleguide';

export const SortBox = styled.div`
  font-size: 0.925rem;
  border-bottom: 1px solid ${theme.color.border.disabled};
  margin-top: 1.25rem;
`;

export const ShowNextWrapper = styled.div<{
  shouldShowAllReviewsLink: boolean;
}>`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    ${({ shouldShowAllReviewsLink }) =>
      shouldShowAllReviewsLink &&
      css`
        flex-direction: row;
        justify-content: space-between;
      `}
  }
`;

export const ShowAllReviewsLink = styled('a')`
  display: block;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1.3rem;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0rem;
  }
`;

export const StyledButton = styled(ActionButton)`
  padding: 0 0.5625rem !important;
  font-weight: 500 !important;
`;

export const StyledRow = styled(Row)`
  display: flex !important;
  margin: 0;
`;

export const StyledCol = styled(Col)`
  padding: 0;
`;

export const ReviewsList = styled.ol`
  list-style: none;
  margin-left: 0 !important; // important to override layout styles

  overflow-x: hidden;
  @media (min-width: ${breakpoints.md}) {
    overflow-x: unset;
  }
`;
