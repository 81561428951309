import { QueryHookOptions, useQuery } from '@apollo/client';

import {
  GetReviewSummaryQuery,
  GetReviewSummaryQueryVariables,
} from '@notino/shared/definitions/types';

import getReviewSummaryQuery from './queries/reviewSummary.graphql';

export const useReviewsSummaryFetch = (
  catalogMasterId: string,
  options: QueryHookOptions<
    GetReviewSummaryQuery,
    GetReviewSummaryQueryVariables
  >
) => {
  return useQuery<GetReviewSummaryQuery, GetReviewSummaryQueryVariables>(
    getReviewSummaryQuery,
    {
      variables: {
        catalogMasterId,
      },
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  );
};
