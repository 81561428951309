import { AddProductsToCartMutation } from '@notino/shared/definitions/types';

import {
  AddToCartProductErrors,
  ProductWithCount,
} from '@sharedComponents/AddToCartButton/model';

import { generateNotificationForProduct, parseStatuses } from '../../../utils';

import { getSettings } from './getSettings';

interface IAddToCartResult {
  response: AddProductsToCartMutation['AddProductsToCart'];
  showAddToCartModal: boolean;
  disableRedirect?: boolean;
  products: ProductWithCount[];
}

export const addToCartResult = ({
  response,
  showAddToCartModal,
  disableRedirect,
  products,
}: IAddToCartResult) => {
  const cartSettings = getSettings(showAddToCartModal, disableRedirect);
  const fatalErrors = getFatalErrors(response.productAddResponse);

  const productById = (id: string) =>
    response.productAddResponse.find((p) => String(p.productId) === id);

  if (fatalErrors.length === 0) {
    const statusMessages = products
      .map((product) => {
        const { productIncrease } = productById(product.id);

        return parseStatuses(
          response.serviceAddResponse,
          productIncrease,
          product.count
        );
      })
      .flat();

    return {
      success: true,
      cartSettings,
      statusMessages,
    };
  }

  const notificationMessages = createNotification(products, productById);

  return {
    success: false,
    errorCode: fatalErrors[0],
    cartSettings,
    notification: notificationMessages[0],
  };
};

function createNotification(
  products: IAddToCartResult['products'],
  productById: (
    id: string
  ) => AddProductsToCartMutation['AddProductsToCart']['productAddResponse'][number]
) {
  return products
    .map((product) => {
      const productFatalErrors = productById(product.id).messages.filter(
        onlyFatalErrors
      );

      if (productFatalErrors.length === 0) {
        return null;
      }

      return generateNotificationForProduct(productFatalErrors, product);
    })
    .filter(Boolean);
}

const nonFatalAddToCartProductErrors = [
  AddToCartProductErrors.SC_INSURANCE_ADD_ERROR,
  AddToCartProductErrors.SC_ECO_PACKAGING_ADD_ERROR,
];

const onlyFatalErrors = (m: AddToCartProductErrors) =>
  !nonFatalAddToCartProductErrors.includes(m);

const getFatalErrors = (
  products: AddProductsToCartMutation['AddProductsToCart']['productAddResponse']
) =>
  products
    .map((product) => product.messages)
    .flat()
    .filter(onlyFatalErrors) as AddToCartProductErrors[];
