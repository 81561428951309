import * as React from 'react';
import { useLocation } from 'react-router';

import { variantUrlFromUrl } from '@notino/shared/client-utils';
import { AvailabilityState } from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability } from '@notino/web-tracking';

import { useTrackingDisplayType } from '@containers/ProductDetailContainer/utils';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { IModifaceEffectVariants } from '../ModiFaceModal/model';
import { useSkinAnalyzerVisibility } from '../ProductBaseInfo/SkinAnalyser/hooks/useAnalyzerVisibility';
import { useProductDetailContext } from '../ProductDetailContext';
import { trackItemInExponea, trackPageReload } from '../tracking';

import { usePriceLogic } from './usePriceLogic';

interface UseTrackItemAndReload {
  hasWebcam: boolean;
  modiface: { hair: IModifaceEffectVariants; makeup: IModifaceEffectVariants };
  detectWebcamAndTrackProductDetailView: (
    trackerFunc: (HasWebcam: boolean) => void
  ) => void;
}

export const useTrackItemAndReload = ({
  hasWebcam,
  modiface,
  detectWebcamAndTrackProductDetailView,
}: UseTrackItemAndReload) => {
  const mounted = React.useRef<boolean>(false);
  const { pathname } = useLocation();
  const variantUrl = variantUrlFromUrl(pathname);
  const featureFlags = useFeatureFlags();
  const { shouldShowSkinAnalyzer } = useSkinAnalyzerVisibility();

  const {
    additionalServices,
    engravingAvailable,
    tryItFirstAvailable,
    currentVariant: variant,
    product,
  } = useProductDetailContext();

  const { rrpShown, minimalPriceShown } = usePriceLogic(variant);

  const trackingDisplayType = useTrackingDisplayType(product);

  // Did update variantUrl
  React.useEffect(() => {
    const trackPageView =
      variant.availability.state !== AvailabilityState.CanBeBought ||
      additionalServices?.data?.CartServicesIncluded ||
      additionalServices?.error;

    const eventPayload = {
      variant,
      product,
      modifaceVariants: modiface.makeup,
      engravingAvailable:
        engravingAvailable ?? AdditionalServicesAvailability.notAvailable,
      tryItFirstAvailable:
        tryItFirstAvailable ?? AdditionalServicesAvailability.notAvailable,
      skinAnalyzer: shouldShowSkinAnalyzer,
    };

    if (mounted.current) {
      trackItemInExponea(product, variant);
      trackPageReload({
        rrpShown,
        minimalPriceShown,
        trackingDisplayType,
        featureFlags,

        ...eventPayload,
      });
    } else if (!mounted.current && trackPageView) {
      trackItemInExponea(product, variant);

      // Detect webcam if there is at least one modiface variant
      // if hasWebcam, no need to detect it again
      if (
        Object.keys(modiface.makeup).length > 0 ||
        Object.keys(modiface.hair).length > 0 ||
        hasWebcam
      ) {
        detectWebcamAndTrackProductDetailView(() =>
          trackPageReload({
            rrpShown,
            minimalPriceShown,
            trackingDisplayType,
            featureFlags,
            ...eventPayload,
          })
        );
      } else {
        trackPageReload({
          rrpShown,
          minimalPriceShown,
          trackingDisplayType,
          featureFlags,
          ...eventPayload,
        });
      }
      mounted.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantUrl, additionalServices.loading]);
};
