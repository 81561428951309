import * as React from 'react';

import { IBasicStyledProps } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';

import { ChristmasIcon } from './icons/ChristmasIcon';

interface ISpecialSalesProps extends IBasicStyledProps {
  attributes: { [key: string]: string };
  stockAvailabilityCode?: string;
}

export const SpecialSalesIcon: React.FC<ISpecialSalesProps> = ({
  attributes,
  stockAvailabilityCode,
  ...rest
}) => {
  if (
    !stockAvailabilityCode ||
    stockAvailabilityCode === IStock.outOfStock ||
    !attributes
  ) {
    return null;
  }

  if (attributes.IsSpecialSalesOffer) {
    return <ChristmasIcon {...rest} />;
  }

  return null;
};
