import * as React from 'react';
import { useLocation } from 'react-router';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { selectVariant } from '../../utils';

export const useCurrentVariant = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants']
) => {
  const { pathname } = useLocation();

  return React.useMemo(
    () => selectVariant(variants, pathname),
    [variants, pathname]
  );
};
