import { useLivestreams } from '@components/LivestreamBanner/hooks/useLivestreams';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useIsDesktop } from '@utils/helpers';

import { TabIds, Tabs } from '../model';
import { useProductDetailContext } from '../ProductDetailContext';
import { useRichContentData } from '../ProductTabContent/RichContentArea/queries/useRichContentData';

import { useIsRichContentFirstTab } from './useIsRichContentFirstTab';

export const useTabsConfig = () => {
  const { livestream_on_pd_enabled } = useFeatureFlags();
  const isDesktop = useIsDesktop({
    defaultState: true,
  });
  const isRichContentFirstTab = useIsRichContentFirstTab();

  const { product, currentVariant, customVersionKey } =
    useProductDetailContext();

  const { data: livestreamData, error: livestreamError } = useLivestreams({
    enabled: livestream_on_pd_enabled && !isDesktop,
    productCode: product.masterProductCode,
  });

  const shouldRenderDescriptionTab =
    Boolean(product.description) ||
    Boolean(
      currentVariant.characteristics &&
        currentVariant.characteristics.length > 0
    );

  const shouldRenderLivestreamsTab = Boolean(
    livestream_on_pd_enabled &&
      !isDesktop &&
      !livestreamError &&
      livestreamData?.getLivestreams?.shows &&
      livestreamData?.getLivestreams?.shows.length !== 0
  );

  const { data: richContent } = useRichContentData({
    versionKey: customVersionKey,
  });

  const shouldRenderRichContentTabByDefault =
    !!richContent?.RichContentByCatalogMasterId?.isAvailable &&
    isRichContentFirstTab;

  const defaultTab = getDefaultTab({
    shouldRenderDescriptionTab,
    shouldRenderRichContentTabByDefault,
  });

  return {
    shouldRenderDescriptionTab,
    shouldRenderLivestreamsTab,
    shouldRenderRichContentTabByDefault,
    defaultTab,
    defaultTabId: tabToTabIdMap[defaultTab],
    isDefaultTabOpenOnMobile: useIsDefaultTabOpenOnMobile({
      defaultTab,
      shouldRenderLivestreamsTab,
    }),
  };
};

const getDefaultTab = ({
  shouldRenderDescriptionTab,
  shouldRenderRichContentTabByDefault,
}) => {
  if (shouldRenderRichContentTabByDefault) {
    return Tabs.RichContent;
  }

  if (shouldRenderDescriptionTab) {
    return Tabs.Description;
  }

  return Tabs.AboutBrand;
};

const tabToTabIdMap: Record<Tabs, TabIds> = {
  aboutBrand: TabIds.AboutBrand,
  description: TabIds.Description,
  livestreams: TabIds.Livestreams,
  relatedArticles: TabIds.RelatedArticles,
  richContent: TabIds.RichContent,
};

const useIsDefaultTabOpenOnMobile = ({
  defaultTab,
  shouldRenderLivestreamsTab,
}: {
  defaultTab: Tabs;
  shouldRenderLivestreamsTab: boolean;
}) => {
  const { currentVariant } = useProductDetailContext();

  if (defaultTab === Tabs.Description) {
    return Boolean(currentVariant.attributes?.Pictograms?.length > 0);
  }
  if (defaultTab === Tabs.Livestreams) {
    return shouldRenderLivestreamsTab;
  }
  return false;
};
