import styled, { css } from 'styled-components';

import { ProductLabel, breakpoints, theme } from '@notino/react-styleguide';

export const StyledLabel = styled(ProductLabel)`
  @media (max-width: ${breakpoints.sm}) {
    font-size: 0.75rem !important;
  }

  ${({ labelType }) =>
    labelType === 'corner'
      ? css`
          top: -2px;
          right: -2px;
        `
      : ''}

  text-transform: none;
  ${theme.typography.labelSmall}
`;

// This wraps around all visualized tags so they stay together
export const StyledTagWrapper = styled.div<{ nowrapLg: boolean }>`
  @media (min-width: ${breakpoints.lg}) {
    ${(props) => (props.nowrapLg ? 'white-space: nowrap;' : '')}
  }
`;
