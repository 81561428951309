import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20rem;
  color: ${theme.color.text.secondary};
`;

export const SystemOverloadedMessage = styled.div`
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
`;

export const PleaseTryAgainMessage = styled.div`
  font-size: 0.75rem;
`;
