import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconSolidInfo, IconRegularCopy } from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';

import { useVoucherDiscountContext } from '../../context';
import { messages } from '../../messages';
import { useHasStressOnVoucherDiscount } from '../../useHasStressOnVoucherDiscount';
import {
  TextWrapper,
  CodeWrapper,
  DiscountAmountWrapper,
  TermsWrapper,
  InstructionTextWrapper,
  InfoIconWrapper,
  DiscountInfoDescription,
} from '../styled';

import { Divider, StyledInteractiveArea, TooltipContent } from './styled';

export interface IProductDiscountProps {
  highlightedDiscountAmount: string | JSX.Element;
  instructionsText: JSX.Element;
  infoTooltipText: JSX.Element;
  discountCode: string;
  highlightedDiscountAmountColor?: string;
  hasDivider?: boolean;
  middleSection?: React.ReactElement;
}

export const ProductDiscount: React.FC<IProductDiscountProps> = ({
  highlightedDiscountAmount,
  discountCode,
  instructionsText,
  infoTooltipText,
  highlightedDiscountAmountColor,
  hasDivider,
  middleSection,
}) => {
  const [showIsCopied, setShowIsCopied] = React.useState(false);
  const tooltipTimeout = React.useRef<NodeJS.Timeout>(null);
  const { hasContrastBg } = useVoucherDiscountContext();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(discountCode);
    setShowIsCopied(true);
    clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = setTimeout(() => setShowIsCopied(false), 2000);
  };

  const isStressed = useHasStressOnVoucherDiscount();

  return (
    <DiscountInfoDescription>
      <TextWrapper hasContrastBg={hasContrastBg} isStressed={isStressed}>
        <DiscountAmountWrapper
          isStressed={isStressed}
          hasContrastBg={hasContrastBg}
          color={highlightedDiscountAmountColor}
        >
          {highlightedDiscountAmount}
        </DiscountAmountWrapper>

        <span>
          <FormattedMessage
            {...messages.withCode}
            values={{
              code: (
                <CodeWrapper hasContrastBg={hasContrastBg}>
                  {discountCode}
                </CodeWrapper>
              ),
            }}
          />

          <PortalTooltip
            isOpen={showIsCopied || undefined}
            content={
              <PortalTooltip.Content shouldWrapText={false}>
                {showIsCopied ? (
                  <FormattedMessage {...messages.codeCopied} />
                ) : (
                  <FormattedMessage {...messages.copyCode} />
                )}
              </PortalTooltip.Content>
            }
          >
            <StyledInteractiveArea onClick={handleCopy}>
              <IconRegularCopy
                height="20px"
                color={hasContrastBg ? 'icon.inverse' : 'icon.tertiary'}
              />
            </StyledInteractiveArea>
          </PortalTooltip>
        </span>
      </TextWrapper>

      {hasDivider && <Divider />}

      {middleSection}

      <TermsWrapper>
        <InstructionTextWrapper hasContrastBg={hasContrastBg}>
          {instructionsText}

          {infoTooltipText && (
            <PortalTooltip
              content={
                <PortalTooltip.Content>
                  <TooltipContent>{infoTooltipText}</TooltipContent>
                </PortalTooltip.Content>
              }
            >
              <InfoIconWrapper>
                <IconSolidInfo
                  width="13px"
                  height="13px"
                  color={hasContrastBg ? 'icon.inverse' : 'icon.tertiary'}
                />
              </InfoIconWrapper>
            </PortalTooltip>
          )}
        </InstructionTextWrapper>
      </TermsWrapper>
    </DiscountInfoDescription>
  );
};
