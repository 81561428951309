import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import { useProductDetailContext } from '../ProductDetailContext';

export const useIsRichContentFirstTab = () => {
  const {
    product: {
      brand: { id },
    },
  } = useProductDetailContext();

  const { pd_display_rich_content_as_first_tab_brand_ids } = useFeatureFlags();
  return pd_display_rich_content_as_first_tab_brand_ids?.includes(id);
};
