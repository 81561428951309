import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const LivestreamBannerWrapper = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  text-align: left;

  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
    background: #f1f1f1;
    padding: 1.5rem;
  }
`;

interface IInfoWrapperProps {
  fullwidth?: boolean;
}

export const InfoWrapper = styled.div<IInfoWrapperProps>`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    overflow-wrap: break-word;

    ${({ fullwidth }) =>
      fullwidth
        ? css`
            margin: 0 4rem;
            width: calc(100% - 8rem);
          `
        : css`
            margin: 0 2.5rem;
            width: calc(100% - 5rem);
          `};
  }
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 0.5rem;
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.75rem;
`;

export const DescriptionWrapper = styled.div`
  max-height: 14.25rem;
  width: 100%;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${breakpoints.xxl}) {
    -webkit-line-clamp: 13;
    max-height: 23.25rem;
  }
`;

export const StyledButton = styled.div`
  margin-right: auto;
  padding: 1rem 2rem;
  border-radius: 2px;
  cursor: pointer;
  ${theme.typography.labelRegular}
  background: ${theme.color.background.inverse};
  color: ${theme.color.text.inverse};

  &:hover {
    background: #404040;
  }

  transition: background-color 300ms ease 0s, border-color 300ms ease 0s;
`;
