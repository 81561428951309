import styled, { css } from 'styled-components';

import {
  breakpoints,
  IBasicStyledProps,
  theme,
} from '@notino/react-styleguide';
interface IActiveTab extends IBasicStyledProps {
  isActive?: boolean;
}

export const Container = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 80rem;
  @media (min-width: 105rem) {
    max-width: 100rem;
  }
`;

export const ContentSwitcherContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    margin-top: 4.0625rem;
  }
`;

export const ContentSwitcherWrapper = styled.div<IBasicStyledProps>`
  text-align: left;
  width: 100%;
  background: ${(props) => props.theme.color.background.primary};
`;

export const TabList = styled.div`
  position: relative;
  font-size: 87.5%;
`;

const HoveredTab = css`
  border-bottom: 1px solid ${theme.color.border.selected};
  padding-bottom: 13px;
`;

export const LinkWrapper = styled.button<IActiveTab>`
  color: ${theme.color.text.primary};
  cursor: pointer;
  position: relative;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  border-radius: 0;
  font-family: Roboto, Helvetica Neue, Arial, Helvetica, sans-serif;
  border: 0;
  border-bottom: 3px solid
    ${(props: IActiveTab) =>
      props.isActive
        ? props.theme.color.border.selected
        : props.theme.color.common.transparent};
  background: transparent;

  display: inline-block;
  list-style-type: none;
  padding: 0.625rem 0 0.6875rem;
  margin-right: 1.875rem;

  &:hover {
    ${(props) => (props.isActive ? null : HoveredTab)}
  }
  z-index: 1;

  ${({ isActive }) =>
    css`
      color: ${isActive
        ? theme.color.text.primary
        : theme.color.text.secondary};
      font-weight: ${isActive ? 500 : 400};
    `}
`;

export const ReviewSummaryCount = styled.span`
  font-size: 0.75rem;
  color: ${theme.color.text.tertiary};
`;
