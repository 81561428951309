import * as React from 'react';

import { HttpStatus } from '@components/HttpContext';

interface ILoadFailedProps {
  reason?: string;
}

export const PageNotFound: React.FC<ILoadFailedProps> = ({ reason }) => {
  return (
    <HttpStatus status={404} reason={reason}>
      {null}
    </HttpStatus>
  );
};

export const PageGone: React.FC<ILoadFailedProps> = ({ reason }) => {
  return (
    <HttpStatus status={410} reason={reason}>
      {null}
    </HttpStatus>
  );
};

export const PageLoadFailed: React.FC<ILoadFailedProps> = ({ reason }) => {
  return (
    <HttpStatus status={500} reason={reason}>
      {null}
    </HttpStatus>
  );
};

export default PageNotFound;
