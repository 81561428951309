import * as React from 'react';

import { Ratings } from '@notino/react-styleguide';

import {
  BazaarvoiceRatingPlaceholder,
  BazaarvoiceRatingsDiv,
  Wrapper,
} from './styled';

interface BazaarvoiceRatingsProps {
  productCode: string;
}

export const BazaarvoiceRatings = ({
  productCode,
}: BazaarvoiceRatingsProps) => {
  const ratingsDivRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const currentRef = ratingsDivRef.current;

    if (currentRef) {
      const observer = new MutationObserver(updateReviewsTabCount);
      observer.observe(currentRef, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, []);

  return (
    <Wrapper>
      <BazaarvoiceRatingPlaceholder>
        <Ratings
          ratingId="loading-skeleton-id"
          rating={0}
          size={16}
          numReviews={0}
          foreground="text.highlight"
          microdata={false}
        />
      </BazaarvoiceRatingPlaceholder>

      <BazaarvoiceRatingsDiv
        ref={ratingsDivRef}
        id="bv-reviews-test"
        data-bv-show="rating_summary"
        data-bv-product-id={productCode}
      />
    </Wrapper>
  );
};

const updateReviewsTabCount = () => {
  const countEl = document.getElementById('tabReviews_reviewCount');
  const mobileCountEl = document.getElementById('pdReviewsScroll_reviewCount');
  const bvReviewCountText = document.querySelector(
    '.bv_numReviews_text'
  )?.textContent;

  if (!bvReviewCountText) {
    return;
  }

  const bvReviewCount = bvReviewCountText.replace('(', '').replace(')', '');
  if (bvReviewCount === '0') {
    return;
  }

  if (countEl) countEl.textContent = bvReviewCount;
  if (mobileCountEl) mobileCountEl.textContent = bvReviewCount;
};
