import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@apollo/client';

import { Heading } from '@notino/react-styleguide';
import {
  GetUpsellingDataQuery,
  GetUpsellingDataQueryVariables,
} from '@notino/shared/definitions/types';

import { useTrackDynamicList } from '@helpers/googleTagManager';

import messages from '../../../messages';
import { GTM_LIST_LABEL } from '../constants';

import { IUpsellingListProps } from './model';
import { ProductTile } from './ProductTile';
import getUpsellingDataQuery from './queries/upsellingData.graphql';
import { RelatedList } from './styled';

const MAX_PRODUCTS_COUNT = 4;

export const UpsellingList: React.FC<IUpsellingListProps> = ({
  databreakersProductIds,
  attributionToken,
}) => {
  const { loading, data: productData } = useQuery<
    GetUpsellingDataQuery,
    GetUpsellingDataQueryVariables
  >(getUpsellingDataQuery, {
    variables: {
      ids: databreakersProductIds,
    },
  });

  const trackVisibleItemsGTM = useTrackDynamicList({
    listName: GTM_LIST_LABEL,
    interaction: 'automatic',
  });

  if (!productData?.vpProductByIds || loading) {
    return null;
  }

  // Filter all products, that are not available and select first 4
  const vpProductByIds = productData.vpProductByIds
    .filter((product) => product && product.canBuy)
    .slice(0, MAX_PRODUCTS_COUNT);

  return (
    <>
      <Heading.H4 data-cypress="customersAlsoBought">
        <FormattedMessage {...messages.customersAlsoBuy} />
      </Heading.H4>
      <RelatedList data-key="upsellingBox">
        {vpProductByIds.map((productById, i) => (
          <ProductTile
            key={productById.productCode}
            trackVisibleItemsGTM={trackVisibleItemsGTM}
            position={i + 1}
            productById={productById}
            attributionToken={attributionToken}
          />
        ))}
      </RelatedList>
    </>
  );
};
