import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const Sticky = styled.div``;

export const ChildrenTopSensor = styled.div``;

export const ChildrenBottomSensor = styled.div``;

export const BottomSensor = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const TopSensor = styled.div`
  position: absolute;
  top: 0px;
`;
