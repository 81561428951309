import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackModalOrDrawerClose = ({
  timing,
}: Omit<TrackingAttributes, 'variant'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      name: 'click_and_collect',
      interaction: 'click',
      action: 'close_click_and_collect',
      timing,
      mode: 'off',
      type: 'product_detail',
      promo_labels: undefined,
    },
    _clear: true,
  });
};

export const trackModalOrDrawerOpen = ({
  timing,
}: Omit<TrackingAttributes, 'variant'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      name: 'click_and_collect',
      interaction: 'click',
      action: 'open_click_and_collect',
      timing,
      mode: 'on',
      type: 'product_detail',
      promo_labels: undefined,
    },
    _clear: true,
  });
};
