import * as React from 'react';

import { IconSolidStar } from '@notino/react-styleguide';

import ProgressBar from '@components/ProgressBar';
import { IReviewSummary } from '@containers/ProductDetailContainer/model';
import { arrayFromNumber } from '@helpers/utils';

import { RatingRow, StyledCol } from '../styled';

import {
  ProgressWrapper,
  RatingsValue,
  StarsBox,
  StarsValue,
  StarReviews,
} from './styled';

interface IStarSummaryProps {
  reviewSummary: IReviewSummary;
}

export const StarsSummary: React.FC<IStarSummaryProps> = ({
  reviewSummary,
}) => {
  return (
    <StyledCol xs={12} md={5}>
      <StarReviews data-cypress="reviews-starsSummary">
        {Object.keys(reviewSummary.votes)
          .sort()
          .reverse()
          .map((stars, index) => (
            <RatingRow key={stars} isFirst={index === 0}>
              <ProgressWrapper>
                <ProgressBar
                  name={stars}
                  max={reviewSummary.totalVotes}
                  value={reviewSummary.votes[stars]}
                />
              </ProgressWrapper>
              <RatingsValue>
                <StarsValue>{reviewSummary.votes[stars]}</StarsValue>
                <StarsBox>
                  {arrayFromNumber(parseInt(stars, 10)).map((i) => (
                    <IconSolidStar
                      key={`starsReviews${stars}-${i}`}
                      width="14px"
                      height="14px"
                      color="icon.highlight"
                    />
                  ))}
                </StarsBox>
              </RatingsValue>
            </RatingRow>
          ))}
      </StarReviews>
    </StyledCol>
  );
};
