import * as React from 'react';
import { useIntl } from 'react-intl';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { roundToMax2Decimals } from '@utils/number';

import { FadeWrapper } from './FadeWrapper';
import { messages } from './messages';
import {
  TableSubtitle,
  TableTitle,
  TableAnnotation,
  VitaminsTableWrapper,
} from './styled';

type Props = {
  vitaminsAndMinerals: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['documentation']['composition']['vitaminsAndMinerals'];
};

export const VitaminsTable = ({ vitaminsAndMinerals }: Props) => {
  const { formatMessage } = useIntl();
  const [headerRow, ...otherRows] = vitaminsAndMinerals;

  return (
    <div>
      <TableTitle>{formatMessage(messages.vitaminsAndMinerals)}</TableTitle>

      <TableSubtitle>
        {formatMessage(messages.number)} {headerRow.measureUnit}{' '}
        {formatMessage(messages.inDailyDose)} {headerRow.amount}
      </TableSubtitle>

      <FadeWrapper>
        <VitaminsTableWrapper data-testid="vitamins-table">
          <table>
            <tr>
              <td />
              <td>{formatMessage(messages.referenceValue)}</td>
              <td>{formatMessage(messages.onePortion)}</td>
              <td>{formatMessage(messages.referenceValueOnePercent)}</td>
              <td>{formatMessage(messages.dailyAmount)}</td>
              <td>{formatMessage(messages.referenceValuePercent)}</td>
            </tr>

            {otherRows.map((row) => (
              <tr key={row.nutritionValue}>
                <td>{row.nutritionValue}</td>
                <td>
                  {roundToMax2Decimals(row.referenceValue)} {row.measureUnit}
                </td>
                <td>
                  {roundToMax2Decimals(row.onePortionAmount)} {row.measureUnit}
                </td>
                <td>{roundToMax2Decimals(row.referenceValueOnePercent)} %</td>
                <td>
                  {roundToMax2Decimals(row.amount)} {row.measureUnit}
                </td>
                <td>{roundToMax2Decimals(row.referenceValuePercent)} %</td>
              </tr>
            ))}
          </table>
        </VitaminsTableWrapper>
      </FadeWrapper>

      <TableAnnotation>
        {formatMessage(messages.referenceValueAnnotation)}
      </TableAnnotation>
    </div>
  );
};
