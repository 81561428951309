import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { IEngravingTryItFirstContext } from './model';
import { reducer, initialState } from './reducer';
import { useActions } from './useActions';

const initialContext = {
  state: initialState,
  actions: {},
};

export const EngravingTryItFirstContext =
  React.createContext<IEngravingTryItFirstContext>(
    initialContext as IEngravingTryItFirstContext
  );

export const EngravingTryItFirstProvider: ReactFCC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = useActions(dispatch);
  const value = React.useMemo(() => ({ state, actions }), [state, actions]);

  return (
    <EngravingTryItFirstContext.Provider value={value}>
      {children}
    </EngravingTryItFirstContext.Provider>
  );
};

export const useEngravingTryItFirstContext = () =>
  React.useContext(EngravingTryItFirstContext);
