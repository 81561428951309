import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { ICurrencyProps } from '@components/PriceLabel/components/Currency';

import { IVariantNameProps } from '../../model';

export const VariantListItem = styled.li<{ minWidth?: string }>`
  list-style-type: none;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  min-width: ${(props) => props.minWidth || '28vw'};

  &:before {
    display: none;
  }

  @media (min-width: ${breakpoints.sm}) {
    min-width: auto;
  }
`;

export const VariantLink = styled(Link)`
  text-decoration: none;
`;

export const VariantCommon = styled.div`
  position: relative;
  margin: 0 0.25rem 0.25rem 0;
  font-weight: normal;
  line-height: 1.375rem;
  cursor: pointer;
`;

export const Name = styled.div<IVariantNameProps>`
  ${({ isOnStock }) =>
    css`
      ${theme.typography.labelRegular}
      color: ${isOnStock ? theme.color.text.primary : theme.color.text.tertiary}
    `}
`;

export const PriceLabelCurrency = styled.span.attrs<{ 'data-testid'?: string }>(
  {
    'data-testid': 'currency-variant',
  }
)<ICurrencyProps>`
  padding-left: ${(props) =>
    props.isSpaced && !props.isPrefix ? '0.3rem' : 0};
  padding-right: ${(props) =>
    props.isSpaced && props.isPrefix ? '0.3rem' : 0};
`;

export const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
  margin-bottom: 0.75rem;

  @media (min-width: ${breakpoints.md}) {
    height: initial;
  }
`;

export const VariantList = styled.ul<{
  itemsCount: number;
}>`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${({ itemsCount }) =>
    css`
      display: flex;
      gap: 0.5rem;
      margin: 0rem;

      @media (min-width: ${breakpoints.lg}) {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: repeat(${itemsCount >= 3 ? 3 : 2}, 1fr);
        overflow-x: auto;
        margin: 0;
        overflow-x: hidden;
      }
    `}
`;
