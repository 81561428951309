import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  engravingMaxOrderQtyHeader: {
    id: 'engraving.max.order.quantity.header',
    defaultMessage: 'Lze objednat pouze {quantity} {unit}',
  },
  engravingMaxOrderQtyDesc: {
    id: 'engraving.max.order.quantity.description',
    defaultMessage:
      'Aby se dostalo na co nejvíce zákazníku, z výrobních důvodů zatím neumožňujeme gravírování více kusů na jednu objednávku.',
  },
  addToCartQuantity: {
    id: 'pd.addToCart.quantity',
    defaultMessage: 'Množství',
  },
});
