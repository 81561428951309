// Turn off SSR for wishlist, advent-calendar, reviews-forms
export const COMPONENTS_SSR = false;

export const CDN_DOMAIN = 'https://cdn.notinoimg.com';

export const CART_URL = '/cart/';

export const EXCLUDE_FROM_LAST_VISITED = 73619;

export const UnitPriceDecimalPlaces = 2;

export const getWindowOrigin = (
  fallback: string = 'https://www.notino.cz'
): string =>
  typeof window !== 'undefined' && window.location
    ? window.location.origin
    : fallback;

export const ATTRIBUTION_TOKEN_QUERY_PARAM = 'at';
