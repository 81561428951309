import {
  ApolloError,
  useApolloClient,
  useQuery,
  ApolloClient,
} from '@apollo/client';

import { masterUrl } from '@notino/shared/client-utils';
import {
  GetProductPathNameQuery,
  GetProductWebViewQuery,
  GetProductWebViewQueryVariables,
} from '@notino/shared/definitions/types';

import getProductPathNameQuery from '@components/WebView/productPathName.graphql';
import getProductWebViewQuery from '@components/WebView/productWebView.graphql';
import * as Sentry from '@sentry/browser';

interface IResolveWebViewResult {
  resolveWebViewLoading: boolean;
  resolveWebViewData: GetProductWebViewQuery;
  resolveWebViewError: ApolloError;
}

interface IResolveWebViewParams {
  pathname: string;
  originUrl?: string;
}

/**
 * We set ProductSsrData.pathname to null, so when we navigate to previous or further page in browser
 * we don't use pathname from SSR, but we rather use browser url
 * @param client
 */
const clearSSRProductPath = (client: ApolloClient<object>) => {
  client.writeQuery({
    query: getProductPathNameQuery,
    data: {
      ProductSsrData: {
        pathname: null,
      },
    },
  });
};

export const useResolveWebView = ({
  pathname,
  originUrl,
}: IResolveWebViewParams): IResolveWebViewResult => {
  const client = useApolloClient();

  const { ProductSsrData } = client.readQuery<
    GetProductPathNameQuery,
    Record<string, unknown>
  >({
    query: getProductPathNameQuery,
  });

  const ssrProductPath = ProductSsrData && ProductSsrData.pathname;

  const {
    loading: resolveWebViewLoading,
    data: resolveWebViewData,
    error: resolveWebViewError,
  } = useQuery<GetProductWebViewQuery, GetProductWebViewQueryVariables>(
    getProductWebViewQuery,
    {
      variables: {
        url: ssrProductPath
          ? ssrProductPath
          : originUrl
          ? decodeURI(originUrl)
          : masterUrl(pathname),
      },
      onError: (e) => {
        Sentry.captureException(
          new Error(`Could not resolve web view
          variables: ${ssrProductPath} - ${originUrl} - ${pathname}.
          Error: ${JSON.stringify(e)}`)
        );
      },
      onCompleted: () => clearSSRProductPath(client),
      errorPolicy: 'all',
    }
  );

  return {
    resolveWebViewLoading,
    resolveWebViewData,
    resolveWebViewError,
  };
};
