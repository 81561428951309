import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  text: {
    id: 'pd.discoveryBoxSelfChoice.text',
    defaultMessage:
      '<b>Získejte vzorek</b> toho ultra narachleho produktosu a objevte další 4 narachle produktosy!',
  },
  modalTitle: {
    id: 'pd.discoveryBoxSelfChoice.modal.title',
    defaultMessage: 'Objevte 5 různých vzorecku',
  },
  modalText: {
    id: 'pd.discoveryBoxSelfChoice.modal.text',
    defaultMessage:
      'Chcete vyzkoušet vzorek tohoto produktosu a zároveň objevit další produktose? Vyberte si 5 vzorků dle svých preferencí.',
  },
  modalCta: {
    id: 'pd.discoveryBoxSelfChoice.modal.cta',
    defaultMessage: 'Vybrat si vzorky',
  },
});
