import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ReactFCC } from '@notino/react-styleguide';

import { useTabsConfig } from '@containers/ProductDetailContainer/ProductDetail/hooks/useTabsConfig';
import { TabIds } from '@containers/ProductDetailContainer/ProductDetail/model';
import { useTabsWithExpandableText } from '@containers/ProductDetailContainer/ProductDetail/ProductTabContent/ProductTabContentProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { useIsMountedRef } from '@hooks/useIsMounted';
import { assignRefs } from '@utils/assignRefs';
import { useIsDesktop } from '@utils/helpers';

import { useTabSwitcher } from './context/TabSwitcherContext';
import { ITabContentProps } from './model';
import { ChildrenWrapper, TabDivider, TitleWrapper } from './styled';
import { TabTitle } from './TabTitle';
import { trackTab } from './trackingTab';

export const TabContent: ReactFCC<ITabContentProps> = ({
  children,
  tabId,
  title,
  trackingLabel,
  lazyLoad,
  tabDividerMobile = true,
  tabIdentifier,
  titleHiddenOnDesktop = false,
  tabOrder,
  alwaysOpen,
  dataTestId,
}) => {
  const { activeTabId, registerTabContent, toggleActiveTab } = useTabSwitcher();
  const { getTimeFromInit } = useTrackingContext();
  const [childrenInViewRef, inView] = useInView({ triggerOnce: true });

  const { toggleTabOpening, tabsWithExpandableTextState } =
    useTabsWithExpandableText();

  const isDesktop = useIsDesktop();

  const isActive = activeTabId === tabId;

  const childrenComponent =
    typeof children === 'function'
      ? children({ tabId, activeTabId })
      : children;

  const childrenRef = useResetScroll(tabId);

  React.useEffect(() => {
    registerTabContent({ id: tabId, tabOrder });
  }, [registerTabContent, tabOrder, tabId]);

  const handleOpen = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement> | React.KeyboardEvent) => {
      e.stopPropagation();

      const tabWithExpandableText = tabsWithExpandableTextState?.[tabId];

      const isTabOpenForTheFirstTime =
        tabWithExpandableText?.isTabOpenForTheFirstTime;

      const shouldShowFullText = tabWithExpandableText?.shouldShowFullText;

      if (isTabOpenForTheFirstTime) {
        toggleTabOpening(tabId);
      }

      if (trackingLabel) {
        const openOrClose = isActive ? 'close' : 'open';

        const mode =
          isActive && isTabOpenForTheFirstTime && !shouldShowFullText
            ? 'close partial'
            : openOrClose;

        trackTab({
          timing: getTimeFromInit(),
          trackingLabel,
          mode,
        });
      }

      toggleActiveTab(tabId);
    },
    [
      tabId,
      isActive,
      trackingLabel,
      tabsWithExpandableTextState,
      toggleTabOpening,
      getTimeFromInit,
      toggleActiveTab,
    ]
  );

  const { isDefaultTabOpenOnMobile } = useTabsConfig();
  const isMounted = useIsMountedRef();

  const isExpandedOnMobile =
    alwaysOpen || (isMounted ? isActive : isActive && isDefaultTabOpenOnMobile);

  return (
    <>
      {title && (
        <>
          <TabDivider
            tabDividerMobile={tabDividerMobile}
            tabDividerDesktop={isActive}
            hideOnDesktop={titleHiddenOnDesktop}
          />
          <TitleWrapper
            isActive={isActive}
            hideOnDesktop={titleHiddenOnDesktop}
          >
            <TabTitle
              isExpanded={isActive}
              hideChevron={alwaysOpen}
              title={title}
              handleExpand={isDesktop || alwaysOpen ? undefined : handleOpen}
              role={isDesktop ? 'heading' : 'button'}
              aria-controls={tabIdentifier}
              aria-expanded={isActive}
              tabIndex={isDesktop ? -1 : 0}
            />
          </TitleWrapper>
        </>
      )}
      <div
        role="tabpanel"
        tabIndex={isActive ? 0 : -1}
        aria-hidden={!isActive}
        data-testid={dataTestId}
        id={tabIdentifier}
      >
        <ChildrenWrapper
          ref={assignRefs(childrenInViewRef, childrenRef)}
          isExpanded={isExpandedOnMobile}
          isActive={isActive}
        >
          {lazyLoad ? inView && childrenComponent : childrenComponent}
        </ChildrenWrapper>
        <TabDivider tabDividerMobile={true} />
      </div>
    </>
  );
};

const useResetScroll = (tabId: TabIds) => {
  const childrenRef = React.useRef<HTMLDivElement>();
  const { tabContentsRef, subscribeToAfterTabClosedByOpeningOtherTab } =
    useTabSwitcher();

  // fix for restoring scroll on iPhone when opening one tab on mobile closes other tab
  React.useEffect(() => {
    const restoreScroll = () => {
      const childrenEl = childrenRef.current;
      if (!childrenEl) {
        return;
      }
      window.scrollTo({
        top: window.scrollY - childrenEl.scrollHeight,
        behavior: 'instant' as ScrollBehavior,
      });
    };

    return subscribeToAfterTabClosedByOpeningOtherTab(
      ({ closedTabId, openTabId }) => {
        const closedTabOrder = tabContentsRef.current.find(
          (x) => x.id === closedTabId
        ).tabOrder;
        const openedTabOrder = tabContentsRef.current.find(
          (x) => x.id === openTabId
        ).tabOrder;

        const isClosedTabAboveOpenedTab = closedTabOrder < openedTabOrder;
        if (closedTabId === tabId && isClosedTabAboveOpenedTab) {
          restoreScroll();
        }
      }
    );
  }, [
    tabId,
    tabContentsRef,
    childrenRef,
    subscribeToAfterTabClosedByOpeningOtherTab,
  ]);

  return childrenRef;
};
