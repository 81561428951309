import styled from 'styled-components';

import { IBasicStyledProps, theme } from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';
import { CurrencyStyled } from '@components/PriceLabel/components/styled';
import { SpecialSalesIcon } from '@sharedComponents/Icons/SpecialSalesIcon';

export const ProductTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 25%;
  padding: 0 1rem;
  min-height: 6.25rem;
  min-width: 11rem;
  max-width: 25%;
`;

export const ProductTileAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 7.75rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.3rem;
`;

export const Image = styled(CdnImage)`
  max-height: 6.25rem;
  width: auto;
  max-width: 100%;
`;

export const ProductName = styled.div`
  font-weight: bold;
  font-size: 0.875rem;

  ${ProductTileAnchor}:hover & {
    text-decoration: underline;
  }
`;

export const BrandTitle = styled.div`
  font-weight: 300;
  font-size: 0.875rem;

  ${ProductTileAnchor}:hover & {
    text-decoration: underline;
  }
`;

export const Annotation = styled.p`
  font-size: 0.75rem;
  color: ${theme.color.text.tertiary};
  font-weight: 400;
`;

export const Price = styled.div<IBasicStyledProps>`
  position: relative;
  font-size: 1.125rem;
  font-weight: bold;
  padding-top: 0.5rem;
  color: ${(props) => props.theme.color.text.secondary};
  line-height: normal;
  z-index: 10;
  margin-top: auto;
  text-align: center;
`;

export const PriceWrapper = styled.span`
  position: relative;
  font-size: 1.125rem;
  z-index: 10;
`;

export const DualPriceWrapper = styled(PriceWrapper)`
  font-size: 1rem;
`;

export const CurrencyWrapper = styled(CurrencyStyled)`
  position: relative;
  font-weight: inherit;
  font-size: 1.125rem;
  z-index: 10;
`;

export const DualCurrencyWrapper = styled(CurrencyWrapper)`
  font-size: 1rem;
`;

export const DescriptionSectionWrapper = styled.div`
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;

  & > button,
  form button {
    text-transform: none !important;
    font-weight: normal !important;
    width: auto !important;
    height: auto !important;
    min-width: 0 !important;
  }
`;

export const StyledSpecialSalesIcon = styled(SpecialSalesIcon)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TagsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  > div {
    margin: 0;
    font-size: 0.6125rem;
  }
`;
