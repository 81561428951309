import * as React from 'react';
import { JsonLd } from 'react-schemaorg';

import { Product } from 'schema-dts';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useProductView } from '@context/product-view';

import { useSsrProductUrl } from '../hooks/useSsrProductUrl';

import { getImagesSrc, getVariantOffer, getAggregateRating } from './utils';

interface IProductJsonLdProps {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  selectedVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  imageDomainPath: string;
}

export const ProductJsonLd = React.memo(
  ({ product, selectedVariant, imageDomainPath }: IProductJsonLdProps) => {
    const { url } = useSsrProductUrl(product);
    const { bazaarvoice_pd_components } = useFeatureFlags();

    const { data } = useProductView();

    const countryCode = data.shopSettings.countryCode || '';

    return (
      <JsonLd<Product>
        item={{
          '@context': 'https://schema.org',
          '@type': 'Product',
          '@id': url,
          sku: product.masterProductCode,
          gtin13: selectedVariant.eanCode,
          category: product.annotation,
          name: `${product.brand?.name} ${product.collection}`,
          description: product.description,
          brand: {
            '@type': 'Brand',
            name: product.brand.name,
          },
          image: getImagesSrc(selectedVariant, imageDomainPath),
          offers: product.variants
            .filter((variant) => variant.price?.value > 0)
            .map((variantItem) =>
              getVariantOffer(
                variantItem,
                product,
                imageDomainPath,
                countryCode
              )
            ),
          ...(product.reviewOverview &&
            product.reviewOverview.count > 0 &&
            !bazaarvoice_pd_components && {
              aggregateRating: getAggregateRating(product.reviewOverview),
            }),
        }}
      />
    );
  }
);
