import { css } from 'styled-components';

import { TooltipModel } from '@notino/react-styleguide';

export const TOOLTIP_OFFSET_PX = 16;

export const getTooltipPositionStyles = ({
  position,
  interactive,
  distanceFromArrowToWindowEdge,
}: {
  position: TooltipModel.Position;
  distanceFromArrowToWindowEdge: number;
  interactive: boolean;
}) => {
  const offset = `${TOOLTIP_OFFSET_PX}px`;
  const offsetProp = interactive ? 'padding' : 'margin';
  const sideOffset = Math.min(distanceFromArrowToWindowEdge, 30);

  switch (position) {
    case TooltipModel.Position.topAutomatic:
    case TooltipModel.Position.topCenter:
      return css`
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        ${offsetProp}-bottom: ${offset};
      `;
    case TooltipModel.Position.topLeft:
      return css`
        bottom: 100%;
        left: 50%;
        transform: translateX(-${sideOffset}px);
        ${offsetProp}-bottom: ${offset};
      `;
    case TooltipModel.Position.topRight:
      return css`
        bottom: 100%;
        right: 50%;
        transform: translateX(${sideOffset}px);
        ${offsetProp}-bottom: ${offset};
      `;
    case TooltipModel.Position.bottomAutomatic:
    case TooltipModel.Position.bottomCenter:
      return css`
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        ${offsetProp}-top: ${offset};
      `;
    case TooltipModel.Position.bottomLeft:
      return css`
        top: 100%;
        left: 50%;
        transform: translateX(-${sideOffset}px);
        ${offsetProp}-top: ${offset};
      `;
    case TooltipModel.Position.bottomRight:
      return css`
        top: 100%;
        right: 50%;
        transform: translateX(${sideOffset}px);
        ${offsetProp}-top: ${offset};
      `;
    case TooltipModel.Position.leftCenter:
      return css`
        top: 50%;
        transform: translateY(-50%);
        right: 100%;
        ${offsetProp}-right: ${offset};
      `;
    case TooltipModel.Position.leftTop:
      return css`
        top: 50%;
        transform: translateY(-20px);
        right: 100%;
        ${offsetProp}-right: ${offset};
      `;
    case TooltipModel.Position.leftBottom:
      return css`
        bottom: 50%;
        transform: translateY(20px);
        right: 100%;
        ${offsetProp}-right: ${offset};
      `;
    case TooltipModel.Position.rightCenter:
      return css`
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        ${offsetProp}-left: ${offset};
      `;
    case TooltipModel.Position.rightTop:
      return css`
        top: 50%;
        transform: translateY(-20px);
        left: 100%;
        ${offsetProp}-left: ${offset};
      `;
    case TooltipModel.Position.rightBottom:
      return css`
        bottom: 50%;
        transform: translateY(20px);
        left: 100%;
        ${offsetProp}-left: ${offset};
      `;
  }
};
