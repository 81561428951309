import * as React from 'react';
import { Route, useLocation, Routes } from 'react-router';

import { PathTemplate } from '@notino/shared/definitions/types';

import { TunnelForSpecialPageComponent } from '@components/Tunnel';
import ProductDetail from '@containers/ProductDetailContainer';
import { useResolveWebView } from '@queries/useResolveWebView';

import loadables from '../../containers/App/loadables';
import { PageLoadFailed } from '../NotFound';
import SpecialPageComponent from '../SpecialPageComponent/loadable';

import { createComponents } from './utils';

const WebView: React.FC<{
  mountId?: string;
  originUrl?: string;
  productDetailSsrEnabled?: boolean;
  productPath?: string;
}> = ({ mountId, originUrl, productDetailSsrEnabled }) => {
  const { pathname, search } = useLocation();
  const { resolveWebViewData, resolveWebViewError, resolveWebViewLoading } =
    useResolveWebView({ originUrl, pathname });

  if (resolveWebViewLoading) {
    return null;
  }
  if (resolveWebViewError) {
    return <PageLoadFailed reason="unable resolve url" />;
  }

  switch (resolveWebViewData.resolveUrl.type) {
    case PathTemplate.ProductDetailSimple:
      return (
        <Routes>
          <Route
            path="*"
            element={
              <ProductDetail
                catalogId={Number(resolveWebViewData.resolveUrl.catalogId)}
                productId={Number(resolveWebViewData.resolveUrl.productId)}
                ssrEnabled={productDetailSsrEnabled}
              />
            }
          />
        </Routes>
      );

    case PathTemplate.ReviewsPage:
      return (
        <Routes>
          <Route
            path="*"
            element={
              <loadables.ReviewsPage
                ssrEnabled={true}
                productId={Number(resolveWebViewData.resolveUrl.productId)}
                catalogId={Number(resolveWebViewData.resolveUrl.catalogId)}
              />
            }
          />
        </Routes>
      );

    case PathTemplate.Tryandbuy:
      return (
        <Routes>
          <Route path="/try-and-buy" element={<loadables.Tryandbuy />} />
          <Route
            path="/try-and-buy/error/:errorType"
            element={<loadables.TryandbuyError />}
          />
          <Route
            path="/try-and-buy/thanks"
            element={<loadables.TryandbuyThanks />}
          />
        </Routes>
      );

    case PathTemplate.Wishlist:
      return (
        <Routes>
          <Route path="*" element={<loadables.WishList />} />
        </Routes>
      );

    case PathTemplate.Error:
      return (
        <Routes>
          <Route path="/error/:errorType" element={<loadables.ErrorPage />} />
        </Routes>
      );

    case PathTemplate.SpecialPageComponent:
      return (
        <TunnelForSpecialPageComponent
          components={createComponents(originUrl ? originUrl : search)}
          mountId={mountId}
          render={({ props }) => <SpecialPageComponent component={props} />}
        />
      );

    case PathTemplate.HomePage:
      return (
        <Routes>
          <Route path="/" element={<loadables.Home />} />
        </Routes>
      );

    default:
      return <div>Not found</div>;
  }
};

export default WebView;
