import { ReviewSourceTypes } from '@containers/ProductDetailContainer/model';

export const REVIEWS_MAIL_QUERY_PARAM = 'mail';

export const shouldOpenReviewModal = (): ReviewSourceTypes => {
  if (typeof location !== 'undefined' && location.search) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(REVIEWS_MAIL_QUERY_PARAM) === '1'
      ? ReviewSourceTypes.EMAIL
      : searchParams.get('reviewLogin') === 'true'
      ? ReviewSourceTypes.DIRECT
      : null;
  }
  return null;
};
