import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useLivestreams } from '@components/LivestreamBanner/hooks/useLivestreams';
import { ICharacteristic } from '@containers/ProductListing/model';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { isEmpty } from '@helpers/utils';
import { ExpandableText } from '@sharedComponents/ExpandableText';
import { trackTab } from '@sharedComponents/TabSwitcher/trackingTab';
import { useIsDesktop } from '@utils/helpers';

import messages from '../../../messages';
import { TabIds } from '../../model';
import { SkinAnalyser } from '../../ProductBaseInfo/SkinAnalyser';
import { useSkinAnalyzerVisibility } from '../../ProductBaseInfo/SkinAnalyser/hooks/useAnalyzerVisibility';
import { useProductDetailContext } from '../../ProductDetailContext';
import { Pictograms } from '../Pictograms';
import { EPictograms } from '../Pictograms/model';
import { useTabsWithExpandableText } from '../ProductTabContentProvider';
import { TRACKING_ATTRIBUTES } from '../tracking';

import { Characteristic } from './Characteristic';
import { Instructions } from './Instructions';
import { VideoInstructions } from './Instructions/VideoInstructions';
import LivestreamBannersCarousel from './LivestreamBannersCarousel';
import {
  Container,
  MarginWrapper,
  Description,
  CharacteristicsTable,
  SectionTitle,
  HairSensitivityTestLink,
  CharacteristicsTableWrapper,
  LivestreamsContainer,
  ExpandButtonText,
  SkinAnalyserWrapper,
} from './styled';

export interface IAboutProductProps {
  isForSensitiveHair?: boolean;
  description: string;
  pictograms?: EPictograms[];
  characteristics: ICharacteristic[];
  composition: ICharacteristic[];
  isPerfume?: boolean;
  documentation?: Omit<
    GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['documentation'],
    '__typename'
  >;
}

export const AboutProduct: React.FC<IAboutProductProps> = React.memo(
  ({
    description,
    characteristics,
    composition,
    isPerfume,
    documentation,
    pictograms,
    isForSensitiveHair,
  }) => {
    const { formatMessage } = useIntl();
    const { getTimeFromInit } = useTrackingContext();

    const { product } = useProductDetailContext();
    const { livestream_on_pd_enabled } = useFeatureFlags();
    const [livestreamsRef, livestreamsInView] = useInView({
      triggerOnce: true,
      rootMargin: '100px',
    });

    const isDesktop = useIsDesktop();

    const { data, error } = useLivestreams({
      enabled: livestream_on_pd_enabled && isDesktop && livestreamsInView,
      productCode: product.masterProductCode,
    });

    const shouldShowLivestreams = Boolean(
      livestream_on_pd_enabled &&
        isDesktop &&
        !error &&
        data?.getLivestreams?.shows &&
        data?.getLivestreams?.shows.length !== 0
    );

    const { tabsWithExpandableTextState, toggleFullText } =
      useTabsWithExpandableText();

    const descriptionWithExpandableText =
      tabsWithExpandableTextState?.[TabIds.Description];

    const hasPictograms = Boolean(pictograms?.length > 0);

    const shouldShowFullText =
      !hasPictograms ||
      descriptionWithExpandableText?.shouldShowFullText ||
      !descriptionWithExpandableText?.isTabOpenForTheFirstTime;

    const productCharacteristics = isPerfume
      ? composition.concat(characteristics)
      : characteristics;

    const hasDescription = !isEmpty(description);
    const hasCharacteristics = productCharacteristics.length > 0;

    const hasDetailedInstructions =
      documentation && (documentation.instructions || documentation.document);

    const hasDescriptionContent = Boolean(
      hasDescription ||
        hasDetailedInstructions ||
        documentation?.preparationUsage
    );

    const { shouldShowSkinAnalyzer, isVariant2 } = useSkinAnalyzerVisibility();

    if (!hasCharacteristics && !hasDescriptionContent) {
      return null;
    }

    const onToggleFullText = () => {
      toggleFullText(TabIds.Description);
      trackTab({
        mode: 'open partial',
        timing: getTimeFromInit(),
        trackingLabel: TRACKING_ATTRIBUTES.description,
      });
    };

    return (
      <>
        <Container id="pdDescription">
          <MarginWrapper
            fullWidth={!hasCharacteristics}
            hidden={!hasDescriptionContent}
            id="pd-description-wrapper"
            data-testid="pd-description-wrapper"
          >
            {hasPictograms && <Pictograms pictograms={pictograms} />}

            {hasCharacteristics && (
              <CharacteristicsTableWrapper>
                <CharacteristicsTable>
                  <tbody>
                    {productCharacteristics.map((item) => (
                      <Characteristic
                        key={item.name}
                        item={item}
                        fullWidth={true}
                      />
                    ))}
                  </tbody>
                </CharacteristicsTable>
              </CharacteristicsTableWrapper>
            )}

            {shouldShowSkinAnalyzer && isVariant2 && (
              <SkinAnalyserWrapper>
                <SkinAnalyser key={product.catalogMasterId} />
              </SkinAnalyserWrapper>
            )}
            <ExpandableText
              onClick={onToggleFullText}
              useOnlyOn="mobile"
              isExpanded={shouldShowFullText}
              buttonText={
                <ExpandButtonText>
                  <FormattedMessage {...messages.showMoreText} />
                </ExpandButtonText>
              }
            >
              {hasDescription && (
                <Description
                  id="pd-description-text"
                  data-testid="pd-description-text"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}

              {hasDetailedInstructions && (
                <>
                  <SectionTitle>
                    <FormattedMessage {...messages.instructions} />
                  </SectionTitle>
                  <Instructions
                    instructionDocument={documentation.document}
                    instructions={documentation.instructions}
                  />
                </>
              )}

              {documentation?.video && (
                <>
                  <SectionTitle>
                    <FormattedMessage {...messages.videoInstructions} />
                  </SectionTitle>
                  <VideoInstructions video={documentation.video} />
                </>
              )}

              {documentation?.preparationUsage && (
                <>
                  <SectionTitle>
                    <FormattedMessage {...messages.preparationUsage} />
                  </SectionTitle>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: documentation.preparationUsage,
                    }}
                  />
                </>
              )}

              {isForSensitiveHair && (
                <HairSensitivityTestLink
                  href={formatMessage(messages.sensitiveHairWarningUrl)}
                  rel="nofollow"
                  target="_blank"
                >
                  <FormattedMessage {...messages.sensitiveHairWarningText} />
                </HairSensitivityTestLink>
              )}
            </ExpandableText>
          </MarginWrapper>
        </Container>

        <LivestreamsContainer ref={livestreamsRef}>
          {shouldShowLivestreams && (
            <>
              <SectionTitle>
                <FormattedMessage {...messages.fixedHeaderLivestreams} />
              </SectionTitle>
              <LivestreamBannersCarousel
                livestreams={data?.getLivestreams?.shows}
              />
            </>
          )}
        </LivestreamsContainer>
      </>
    );
  }
);
