import styled, { css } from 'styled-components';

import { breakpoints, QuantitySelector } from '@notino/react-styleguide';

const getGridTemplateAreas = (isClickAndCollect: boolean) => {
  if (isClickAndCollect) {
    return css`
      grid-template-areas:
        'addToCart'
        'clickAndCollect';
    `;
  }
  return css`
    grid-template-areas: 'addToCart';
  `;
};

export const BuyButtons = styled.div<IWrapperProps>`
  display: grid;
  align-items: center;

  ${({ isClickAndCollect }) =>
    css`
      width: 100%;
      grid-gap: 0.5rem;
      padding: 1rem 0 1.5rem;
      ${getGridTemplateAreas(isClickAndCollect)}
    `}
`;

export const ButtonWrapper = styled.div`
  & > button,
  form button {
    padding: 0 0.5625rem !important;
    height: 2.75rem;
    min-width: 7.5rem;
    text-transform: none !important;
    font-weight: normal !important;
  }
  flex: 1;
  min-width: 7.5rem;

  & > button,
  form button {
    height: 3.25rem;
  }
`;

export const Label = styled.label`
  display: none;
`;

export const DropdownButtonSeparator = styled.div`
  padding-right: 0.5rem;
`;

export const QuantityWrapper = styled.div`
  width: 5.25rem;
  font-size: 0.875rem;
  & > div > div {
    min-height: 2.75rem;
  }
`;

interface IWrapperProps {
  isClickAndCollect?: boolean;
  canBuy?: boolean;
}

export const AddToCartWrapper = styled.div<IWrapperProps>`
  grid-area: addToCart;

  display: flex;
  align-items: center;
`;

export const ClickAndCollectWrapper = styled.div<IWrapperProps>`
  grid-area: clickAndCollect;

  ${({ isClickAndCollect }) =>
    isClickAndCollect &&
    css`
      margin-top: -0.5rem;
    `}

  @media (min-width: ${breakpoints.sm}) {
    margin-top: 0;
  }
`;

export const StyledQuantitySelector = styled(QuantitySelector)`
  [role='combobox'] {
    height: 3.25rem;
  }
`;
