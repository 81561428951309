import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  specialStorage: {
    id: 'pd.other.specialStorage',
    defaultMessage: 'Zvláštní podmínky uchovávaní:',
  },
  importantNotice: {
    id: 'pd.other.importantNotice',
    defaultMessage: 'Důležitá upozornění:',
  },
  manufacturerImporter: {
    id: 'pd.other.manufacturerImporter',
    defaultMessage: 'Výrobce/Dovozce:',
  },
});
