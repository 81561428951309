import * as React from 'react';
import { useIntl } from 'react-intl';

import {
  IconSolidInfo,
  TooltipModel,
  ReactFCC,
  Tooltip,
} from '@notino/react-styleguide';

import globalMessages from '@messages';

import { watchdogMessages } from '../../watchdogMessages';

import { WatchdogInputWrapper, IconWrapper, TooltipContent } from './styled';

export const WatchdogEmailInputWrapper: ReactFCC = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <WatchdogInputWrapper>
      {children}
      <IconWrapper>
        <Tooltip
          wrapText={true}
          position={TooltipModel.Position.leftTop}
          alignDesktop={true}
          interactive={true}
          content={
            <TooltipContent>
              {formatMessage(watchdogMessages.watchdogGDPRinfo, {
                link: (
                  <a
                    key="globalGdprLink"
                    href={formatMessage(globalMessages.globalGDPRLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(globalMessages.globalGDPRLinkText)}
                  </a>
                ),
              })}
            </TooltipContent>
          }
        >
          <IconSolidInfo color="icon.tertiary" width="20px" height="20px" />
        </Tooltip>
      </IconWrapper>
    </WatchdogInputWrapper>
  );
};
