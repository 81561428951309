import useMedia from 'use-media';

import { theme } from '@notino/react-styleguide';

export function isDesktop() {
  return window.matchMedia(`(min-width: ${theme.breakpoints.md}rem)`).matches;
}

export const useIsDesktop = ({
  defaultState,
}: {
  defaultState?: boolean;
} = {}) => useMedia(`(min-width: ${theme.breakpoints.lg}rem)`, defaultState);
