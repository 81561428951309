import * as React from 'react';

import { CurrencyStyled } from './styled';

export interface ICurrencyProps {
  isSpaced: boolean;
  isPrefix: boolean;
  content: string;
}

const Currency = (props: ICurrencyProps) => (
  <CurrencyStyled data-testid="pd-currency" {...props} />
);

export default Currency;
