import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const Container = styled.div`
  padding: 1rem 0 2rem;
  @media (min-width: ${breakpoints.lg}) {
    padding: 1rem 0;
  }
`;

export const SelectWrapper = styled.div``;

export const Select = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const DeliveryList = styled.div`
  flex: 10;
  padding-right: 0.5rem;
`;

export const ChevronIconWrapper = styled.button`
  cursor: pointer;
  width: 1rem;
  border: none;
  background-color: ${theme.color.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 6.5rem;
`;

export const FlexRow = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  color: ${theme.color.text.secondary};
  display: flex;
  flex-wrap: wrap;
`;

export const FlexRowWithMargin = styled(FlexRow)`
  &:last-child {
    margin-bottom: 0;
  }
  margin-bottom: 0.75rem;
`;

export const DeliveryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  align-items: center;
`;

export const TopDeliveryInfo = styled(DeliveryInfo)`
  flex-basis: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  margin-bottom: 1.25rem;
`;

export const DeliveryInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export interface IDeliveryTextColorProps {
  isInactive: boolean;
}

const deliveryTextColor = css<IDeliveryTextColorProps>`
  color: ${(props) =>
    props.isInactive ? theme.color.text.disabled : theme.color.text.primary};
`;

export const Name = styled.div<IDeliveryTextColorProps>`
  padding-right: 0.3125rem;
  min-width: 12.5rem;
  flex-basis: 100%;
  line-height: 1.2rem;
  ${deliveryTextColor};

  ${theme.typography.labelRegular}

  @media (min-width: ${breakpoints.sm}) {
    flex-basis: auto;
    line-height: 1.3rem;
  }

  @media (min-width: ${breakpoints.md}) {
    flex-basis: 100%;
    line-height: 1.2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-basis: auto;
    line-height: 1.3rem;
  }
`;

export const DeliveryDate = styled.div<IDeliveryTextColorProps>`
  line-height: 1.2rem;
  ${deliveryTextColor}

  ${theme.typography.labelRegular400}

  @media (min-width: ${breakpoints.sm}) {
    line-height: 1.3rem;
  }
`;

export const TimeText = styled.span`
  color: ${theme.color.text.positive};
`;

export const StyledHr = styled.hr`
  height: 0.0625rem;
  border: none;
  background-color: ${theme.color.background.disabled};
  margin: 1.25rem 0 0.75rem 0;
`;

export const DeliveryDescription = styled.div`
  margin: 0.625rem 0 0.75rem 0;

  color: ${theme.color.text.secondary};

  ${theme.typography.bodySmall}
`;
