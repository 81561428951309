import * as React from 'react';

import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

const Placeholder = styled.div`
  height: '80px';
  // this height will be adjusted by ResizeObserver based on Header height TODO: probably not needed now
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

interface IProps {
  headerContainerEl: HTMLDivElement;
}

const createObserver = (placeholderEl: HTMLDivElement) =>
  new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.borderBoxSize) {
        // Firefox implements `borderBoxSize` as a single content rect, rather than an array
        const borderBoxSize: ResizeObserverSize = Array.isArray(
          entry.borderBoxSize
        )
          ? entry.borderBoxSize[0]
          : entry.borderBoxSize;

        placeholderEl.style.height = `${borderBoxSize.blockSize}px`;
      }
    }
  });

const MobileHeaderPlaceholder = ({ headerContainerEl }: IProps) => {
  const placeholderRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!placeholderRef.current || !headerContainerEl) {
      return;
    }
    const observer = createObserver(placeholderRef.current);
    observer.observe(headerContainerEl);
    return () => observer.disconnect();
  }, [headerContainerEl]);

  return <Placeholder ref={placeholderRef} />;
};

export default MobileHeaderPlaceholder;
