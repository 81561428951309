/* tslint:disable:ordered-imports */
import 'isomorphic-fetch';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { hydrateRoot } from 'react-dom/client';

import { StyleSheetManager } from '@notino/react-styleguide';
import { GetRequestHeadersQuery } from '@notino/shared/definitions/types';
import { getApolloCacheSettings } from '@notino/shared/graphql/apollo-client-options';
import { shouldForwardProp } from '@notino/shared/shouldForwardProp';

import App from '@containers/App';
import { FeatureFlagsProvider } from '@context/featureFlags/FeatureFlagsProvider';
import { loadDevtools } from '@devtools/dev-tools-load';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import { initSentry } from '@utils/sentry/init';

import getDefaultData from './queries/getDefaultData.graphql';
import getRequestHeadersQuery from './queries/requestHeaders.graphql';
import { initDataLayer, loadApolloState } from './state-load';
import { LocalRequestHeaders } from './utils/admin-panel';

initDataLayer();

const apolloState = loadApolloState();
const cache = new InMemoryCache(getApolloCacheSettings()).restore(apolloState);

// Get default headers from hydrated apollo
const requestHeaders = cache.readQuery<GetRequestHeadersQuery>({
  query: getRequestHeadersQuery,
});

// set address for public assets of this app, probably CDN
// eslint-disable-next-line no-undef
// @ts-ignore
__webpack_public_path__ = requestHeaders.RequestHeaders.assetsPath;

initSentry({
  dsn: requestHeaders.RequestHeaders.sentryDsn,
  environment: requestHeaders.RequestHeaders.sentryEnvironment,
  tracingRate: requestHeaders.RequestHeaders.sentryTracingRate,
  shopId: requestHeaders.RequestHeaders.shopId,
});

LocalRequestHeaders.localHeaders = {
  ...requestHeaders.RequestHeaders,
} as typeof LocalRequestHeaders.localHeaders;

const isGatewayProxy =
  requestHeaders.RequestHeaders.featureFlags.gateway_router_proxy_enabled;

const auth = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...LocalRequestHeaders.getHeaders(isGatewayProxy),
  },
}));

const httpLink = createHttpLink({
  uri: requestHeaders.RequestHeaders.gatewayUrl,
  includeExtensions: true,
});

const persistedQueriesLink = createPersistedQueryLink({
  // @ts-ignore
  generateHash: ({ documentId }) => documentId,
  useGETForHashedQueries: false,
});

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link: ApolloLink.from([persistedQueriesLink, auth, httpLink]),
  cache,
  ssrMode: false,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },

  resolvers: {
    Query: {
      RequestHeaders: () => {
        return LocalRequestHeaders.localHeaders;
      },
    },
  },
});

client.onResetStore(() => {
  return Promise.resolve(
    cache.writeQuery({
      query: getDefaultData,
      data: {
        RequestHeaders: {
          __typename: 'ClientHeaders',
          ...LocalRequestHeaders.localHeaders,
        },
        // Reseting the pathname because of adminanel
        ProductSsrData: {
          __typename: 'ClientProductSsr',
          id: '0',
          productId: '0',
          type: 'ProductDetail',
          pathname: '',
        },
      },
    })
  );
});

const render = () => {
  const { mounts, originUrl, featureFlags } = requestHeaders.RequestHeaders;

  const scheduleHydration =
    typeof global.setImmediate !== 'undefined' &&
    featureFlags.pd_set_immediate_enabled
      ? setImmediate
      : (fn) => fn();

  mounts.forEach((mount) =>
    scheduleHydration(() =>
      hydrateRoot(
        document.getElementById(mount),
        <ApolloProvider client={client}>
          <BrowserRouter>
            <FeatureFlagsProvider featureFlags={featureFlags}>
              <HelmetProvider>
                <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                  <App mountId={mount} originUrl={originUrl} />
                </StyleSheetManager>
              </HelmetProvider>
            </FeatureFlagsProvider>
          </BrowserRouter>
        </ApolloProvider>
      )
    )
  );
};

loadableReady(render).catch(Sentry.captureException);

if (process.env.NODE_ENV === 'development') {
  loadDevtools(client, requestHeaders.RequestHeaders.originUrl);
}
