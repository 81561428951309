import * as React from 'react';

import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { useGetImageAttributes } from '@components/CdnImage/useImageAttributes';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useUser } from '@queries/useUser';
import { useTabSwitcher } from '@sharedComponents/TabSwitcher/context/TabSwitcherContext';

import { TabIds } from '../../model';

export const useInjectBazaarvoice = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId']
) => {
  const { bazaarvoice_integration_pd, bazaarvoice_cookie_consent_mapping } =
    useFeatureFlags();
  const { user } = useUser();
  const { toggleActiveTab } = useTabSwitcher();
  const getImageAttributes = useGetImageAttributes();

  React.useEffect(() => {
    if (!bazaarvoice_integration_pd) {
      return;
    }

    const { kind, category } = product.primaryCategories;
    const eans = product.variants.map((v) => v.eanCode).filter(Boolean);

    window.bvDCC = {
      catalogData: {
        locale: bazaarvoice_integration_pd.locale,
        catalogProducts: product.variants.map((variant) => ({
          productId: variant.productCode,
          productName: variant.name,
          productImageURL: getImageAttributes(
            variant.media.mainImage.src,
            'original',
            variant.media.mainImage.alt
          ).src,
          productPageURL: `${window.location.origin}${variant.url}`,
          eans,
          categoryPath: [
            kind && { Name: `${kind.name} DCC`, id: kind.id.toString() },
            category && {
              Name: `${category.name} DCC`,
              id: category.id.toString(),
            },
          ].filter(Boolean),
          brandId: product.brand?.id ?? '',
          brandName: product.brand?.name ?? '',
          family: product.masterProductCode,
        })),
      },
    };

    window.bvCallback = function (BV) {
      for (
        let i = 0, len = window.bvDCC.catalogData.catalogProducts.length;
        i < len;
        i++
      ) {
        BV.pixel.trackEvent('CatalogUpdate', {
          type: 'Product',
          locale: window.bvDCC.catalogData.locale,
          catalogProducts: [window.bvDCC.catalogData.catalogProducts[i]],
        });
      }

      BV.reviews.on('show', () => {
        toggleActiveTab(TabIds.Reviews, true);

        setTimeout(() => {
          document.getElementById('BVReviewsScroll').scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        });
      });

      const hasPerformanceCookiesConsent = bazaarvoice_cookie_consent_mapping
        ? user.consent.includes('performance')
        : true;
      const hasAnalyticsCookieConsent = bazaarvoice_cookie_consent_mapping
        ? user.consent.includes('analytics')
        : true;

      // NOTE: https://knowledge.bazaarvoice.com/wp-content/knowledge/en_US/cookie-consent.html#optional-cookies
      BV.cookieConsent.setConsent({
        bv_segment: hasPerformanceCookiesConsent,
        bv_metrics: hasPerformanceCookiesConsent,
        BVBRANDID: hasPerformanceCookiesConsent,
        BVBRANDSID: hasPerformanceCookiesConsent,
        BVID: hasAnalyticsCookieConsent,
        BVSD: hasAnalyticsCookieConsent,
      });
    };
  }, [
    bazaarvoice_integration_pd,
    bazaarvoice_cookie_consent_mapping,
    getImageAttributes,
    product.brand?.id,
    product.brand?.name,
    product.variants,
    product.masterProductCode,
    product.primaryCategories,
    toggleActiveTab,
    user,
  ]);
};
