import * as React from 'react';

import { DrawerContext, Grid, ModalContext } from '@notino/react-styleguide';
import {
  Roles,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import { ContentWrapper } from '@components';
import { DrawerProvider } from '@components/BottomSheet/DrawerProvider';
import ErrorBoundary from '@components/ErrorBoundary';
import { RenderInView } from '@components/RenderInView';
import { AdminPanel } from '@containers/AdminPanel/loadable';
import { BrandLogo } from '@containers/ProductDetailContainer/ProductDetail/ProductImageGallery/BrandLogo';
import useFeatureSwitches from '@hooks/useFeatureSwitches';
import { TabSwitcherProvider } from '@sharedComponents/TabSwitcher/context/TabSwitcherContext';

import { BlogArticles } from './BlogArticles';
import { Breadcrumb } from './Breadcrumb';
import { Header } from './Header';
import { HeadMeta } from './HeadMeta';
import { useDispatchProductsShowEvent } from './hooks/useDispatchProductsShowEvent';
import { useInjectBazaarvoice } from './hooks/useDynamicCatalogCollection';
import { useAddToLastVisited } from './hooks/useLastVisited/useAddToLastVisited';
import { useModifaceFns } from './hooks/useModiface';
import { useRegisterNotinoApi } from './hooks/useRegisterNotinoApi';
import { useTrackItemAndReload } from './hooks/useTrackItemAndReload';
import { useTrackPageView } from './hooks/useTrackPageView';
import { useTrackProductUrlChange } from './hooks/useTrackProductUrlChange';
import { LastVisitedProducts } from './LastVisitedProducts';
import { TabIds } from './model';
import { ProductBaseInfo } from './ProductBaseInfo';
import { DeliveriesDisplayMode } from './ProductBaseInfo/DeliveryTime';
import MobileHeaderPlaceholder from './ProductBaseInfo/MobileHeaderPlaceholder';
import {
  ProductDetailContextProvider,
  useProductDetailContext,
} from './ProductDetailContext';
import { ProductImageGallery } from './ProductImageGallery';
import { ProductJsonLd } from './ProductJsonLd';
import { ProductPictogram } from './ProductPictogram';
import { ProductTabContent } from './ProductTabContent';
import MobileTabContentPlaceholder, {
  useMoveTabsToMobilePlaceholder,
} from './ProductTabContent/MobileTabContentPlaceholder';
import { TabsWithExpandableTextProvider } from './ProductTabContent/ProductTabContentProvider';
import { RoutineOrRelatedProducts } from './RoutineOrRelatedProducts';
import { StickySidebar } from './StickySidebar';
import {
  GalleryWrapper,
  MainInfoWrapper,
  TopSection,
  ProductTabContentGrid,
  PDViewPort,
  ShowOnMobile,
  ShowOnDesktop,
  AbsoluteHeaderWrapper,
  AbsoluteTabsWrapper,
} from './styled';
import { getVariantDescription, isDiorBrand } from './utils';
import { VariantName } from './VariantName';

interface IPDProps {
  PDView: GetCatalogProductViewQuery;
  rcKey: string;
  customRcKey: string;
  customVersionKey: string;
}

const tabsWithExpandableText = [TabIds.Description];

const ProductDetailComponent = ({
  PDView: {
    productDetailByCatalogMasterId: product,
    currentCurrency,
    currentLocale,
    Settings: { imageDomainPath, deliveriesDisplayMode },
    user,
  },
  customVersionKey,
}: IPDProps) => {
  const { reviewsOnServerIds } = useFeatureSwitches();

  const headerRef = React.useRef<HTMLDivElement>();
  const galleryWrapperRef = React.useRef<HTMLDivElement>();

  const { absoluteTabsWrapperRef, tabsPlaceholderRef, topSectionRef } =
    useMoveTabsToMobilePlaceholder();

  const {
    engravingAvailable,
    tryItFirstAvailable,
    currentVariant: variant,
    modiface,
  } = useProductDetailContext();

  const {
    detectWebcamAndTrackProductDetailView,
    handleOpenModifaceModal,
    hasWebcam,
  } = useModifaceFns(product, variant, modiface);

  useInjectBazaarvoice(product);
  useDispatchProductsShowEvent(product.variants, {
    section: 'product_detail',
  });
  useAddToLastVisited(product);

  useTrackPageView(variant);
  useTrackItemAndReload({
    hasWebcam,
    modiface,
    detectWebcamAndTrackProductDetailView,
  });

  useRegisterNotinoApi(variant.productCode);

  useTrackProductUrlChange(product, variant);

  const hasModiface =
    Boolean(modiface.makeup[variant.webId]) ||
    Boolean(modiface.hair[variant.webId]);

  React.useEffect(() => {
    if (window.location.hash === '#vto' && hasModiface) {
      handleOpenModifaceModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDior = isDiorBrand(product.brand?.id);

  const tabsComponent = (
    <AbsoluteTabsWrapper ref={absoluteTabsWrapperRef}>
      <ProductTabContentGrid fluid={true}>
        <TabsWithExpandableTextProvider
          tabsWithExpandableText={tabsWithExpandableText}
        >
          <ProductTabContent
            reviewsOnServerIds={reviewsOnServerIds}
            product={product}
            variant={variant}
            customVersionKey={customVersionKey}
          />
        </TabsWithExpandableTextProvider>
      </ProductTabContentGrid>
    </AbsoluteTabsWrapper>
  );

  return (
    <>
      <ErrorBoundary>
        <HeadMeta product={product} variant={variant} />
      </ErrorBoundary>
      <ErrorBoundary>
        <PDViewPort
          // This attribute needs to be here due to exponea recommendations
          data-category={product.category}
          // This  attribute needs to be here due to deloitte
          data-product-code={variant.productCode}
        >
          <ProductJsonLd
            product={product}
            selectedVariant={variant}
            imageDomainPath={imageDomainPath}
          />

          {user.role === Roles.Admin && (
            <ErrorBoundary>
              <AdminPanel
                productId={product.webMasterId}
                productUrl={product.url}
              />
            </ErrorBoundary>
          )}

          <ContentWrapper isWider={true}>
            <Grid fluid={true}>
              <BrandLogo
                brandId={product.brand?.id}
                brandUrl={product.brand?.url}
              />

              <TopSection ref={topSectionRef}>
                <GalleryWrapper ref={galleryWrapperRef}>
                  <ShowOnMobile>
                    <Breadcrumb items={product.breadcrumb} />
                  </ShowOnMobile>

                  <MobileHeaderPlaceholder
                    headerContainerEl={headerRef.current}
                  />

                  <ProductImageGallery
                    key={variant.webId} // NOTE: reset gallery to first image when variant changes
                    media={variant.media}
                    domain={imageDomainPath}
                    hasModiface={hasModiface}
                    openModifaceModal={handleOpenModifaceModal}
                    isBrandLogoVisible={isDior}
                    pictogram={
                      <ProductPictogram attributes={variant.attributes} />
                    }
                  />

                  {tabsComponent}
                </GalleryWrapper>

                <MainInfoWrapper>
                  <StickySidebar galleryWrapperRef={galleryWrapperRef}>
                    <ShowOnDesktop>
                      <Breadcrumb
                        items={product.breadcrumb}
                        renderJsonLd={false}
                      />
                    </ShowOnDesktop>

                    <AbsoluteHeaderWrapper ref={headerRef}>
                      <Header
                        reviewsId="productBaseInfo"
                        productId={product.webMasterId}
                        catalogId={product.catalogMasterId}
                        productCode={product.masterProductCode}
                        collection={product.collection}
                        subName={product.subName}
                        brandInfo={product.brand}
                        desc={getVariantDescription(variant, product)}
                      />
                    </AbsoluteHeaderWrapper>

                    <ProductBaseInfo
                      brand={product.brand.name}
                      selectedVariant={variant}
                      product={product}
                      variants={product.variants}
                      currency={currentCurrency}
                      locale={currentLocale}
                      imageDomainPath={imageDomainPath}
                      deliveriesDisplayMode={
                        deliveriesDisplayMode as DeliveriesDisplayMode
                      }
                      shadefinderAvailable={
                        product.features.isShadeFinderAvailable
                      }
                      variantName={<VariantName variant={variant} />}
                      engravingAvailable={engravingAvailable}
                      tryItFirstAvailable={tryItFirstAvailable}
                      modifaceVariants={modiface.makeup}
                    />
                  </StickySidebar>
                </MainInfoWrapper>
              </TopSection>
            </Grid>

            <MobileTabContentPlaceholder
              ref={tabsPlaceholderRef}
              tabsAbsoluteContainerEl={absoluteTabsWrapperRef.current}
            />

            <Grid fluid={true}>
              <ErrorBoundary>
                <RenderInView>
                  <RoutineOrRelatedProducts
                    productId={product.webMasterId}
                    productCode={product.masterProductCode}
                    relatedProducts={product.related.map((id) => String(id))}
                  />
                </RenderInView>
              </ErrorBoundary>

              {!isDior && (
                <ErrorBoundary>
                  <RenderInView>
                    <LastVisitedProducts
                      productId={product.webMasterId}
                      variants={product.variants}
                    />
                  </RenderInView>
                </ErrorBoundary>
              )}

              <ErrorBoundary>
                <RenderInView>
                  <BlogArticles
                    categorization={product.categorization}
                    brandId={product.brand?.id}
                    masterProductCode={product.masterProductCode}
                    allProductCodes={product.variants.map((v) => v.productCode)}
                  />
                </RenderInView>
              </ErrorBoundary>
            </Grid>
          </ContentWrapper>
        </PDViewPort>
      </ErrorBoundary>
    </>
  );
};

export const ProductDetail = (props: IPDProps) => (
  <ProductDetailContextProvider
    {...props}
    product={props.PDView.productDetailByCatalogMasterId}
  >
    <TabSwitcherProvider>
      <ModalContext.Provider>
        <DrawerContext.Provider>
          <DrawerProvider>
            <ProductDetailComponent {...props} />
          </DrawerProvider>
        </DrawerContext.Provider>
      </ModalContext.Provider>
    </TabSwitcherProvider>
  </ProductDetailContextProvider>
);
