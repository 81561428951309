import * as React from 'react';

import { Video } from '@notino/shared/definitions/types';

import { Image360 } from '@containers/ProductDetailContainer/ProductDetail/ProductImageGallery/Image360';
import { ScrollThumbs } from '@containers/ProductDetailContainer/ProductDetail/ProductImageGallery/Thumbs/ScrollThumbs';
import { IImageProperties } from '@helpers/utils';

import { getVideoIframe } from '../../containers/ProductDetailContainer/utils';

import {
  Wrapper,
  PlayerWrapper,
  ThumbsWrapper,
  VideoSizeContainer,
  Image,
} from './styled';

interface IModalGalleryProps {
  onClose: () => void;
  imageIndex: number;
  shouldShow360Photo: boolean;
  images: IImageProperties[];
  thumbImages: IImageProperties[];
  videos?: Video[];
}

export const ModalGallery: React.FC<IModalGalleryProps> = ({
  imageIndex = 0,
  thumbImages,
  videos,
  onClose,
  images,
  shouldShow360Photo,
}) => {
  const [mediaIndex, setMediaIndex] = React.useState(imageIndex);
  const mediaCount = thumbImages.length;

  React.useEffect(() => {
    setMediaIndex(imageIndex);
  }, [imageIndex]);

  React.useEffect(() => {
    const goToNext = () => {
      setMediaIndex((prevMediaIndex) =>
        Math.min(mediaCount > 1 ? mediaCount - 1 : 0, prevMediaIndex + 1)
      );
    };

    const goToPrev = () => {
      setMediaIndex((prevMediaIndex) => Math.max(0, prevMediaIndex - 1));
    };

    const handleKeyPress = (event) => {
      if (event.keyCode === 37) {
        goToPrev();
      } else if (event.keyCode === 39) {
        goToNext();
      } else if (event.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [mediaCount, onClose]);

  const getVideo = React.useCallback(
    (index: number): string => {
      const videoIndex = index - images.length;
      return videoIndex < 0 ? '' : getVideoIframe(videos[videoIndex], true);
    },
    [images, videos]
  );

  const image = images[mediaIndex];
  const video = getVideo(mediaIndex);

  const imageToShow =
    shouldShow360Photo && mediaIndex === 1 ? (
      <Image360 />
    ) : (
      <Image
        data-testid="modal-main-image"
        src={image?.src}
        srcSet={image?.srcSet}
      />
    );

  return (
    <>
      <Wrapper data-testid="modal-gallery">
        {video ? (
          <VideoSizeContainer>
            <PlayerWrapper
              dangerouslySetInnerHTML={{
                __html: video,
              }}
            />
          </VideoSizeContainer>
        ) : (
          imageToShow
        )}
      </Wrapper>

      {thumbImages.length > 1 && (
        <ThumbsWrapper>
          <ScrollThumbs
            delayInteraction={true}
            imagesAttrs={thumbImages}
            imageIndex={mediaIndex}
            handleImageChange={setMediaIndex}
            dataTestId="modal-gallery-slider"
          />
        </ThumbsWrapper>
      )}
    </>
  );
};
