import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

export const TooltipContent = styled.div`
  text-align: center;
  min-width: 16rem;

  a {
    font-weight: 400;
    color: ${theme.color.text.primary};
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
`;
