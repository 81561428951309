import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import {
  CatalogProductDetailViewFragmentFragment,
  CatalogVariantFragmentFragment,
} from '@notino/shared/definitions/types';

import { useImageAttributes } from '@components/CdnImage/useImageAttributes';

import { useSsrProductUrl } from '../hooks/useSsrProductUrl';

import { replaceMetaInformation } from './utils';

interface HeaderMetaParams {
  product: CatalogProductDetailViewFragmentFragment;
  variant: CatalogVariantFragmentFragment;
}

export const HeadMeta = ({ product, variant }: HeaderMetaParams) => {
  const { url, xDefault } = useSsrProductUrl(product);
  const { pathname } = useLocation();
  const { src: imageSrc } = useImageAttributes(
    variant.media.mainImage.src,
    'metaTag'
  );

  React.useEffect(() => {
    replaceMetaInformation({
      keywords: variant.metaData.keywords,
      title: variant.metaData.title,
      description: variant.metaData.description,
    });
  }, [
    variant.metaData.title,
    variant.metaData.description,
    variant.metaData.keywords,
  ]);

  const variantSite = /(\/p-\d+((\/?)|(\??.+)))$/;

  const showHrefLangs = !variantSite.test(pathname);

  return (
    <Helmet>
      <meta property="og:type" content="product" />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={variant.metaData.description} />
      <meta property="og:title" content={variant.metaData.title} />
      <meta property="og:image" content={imageSrc} />
      <meta property="og:site_name" content={new URL(url).hostname} />
      <link rel="canonical" href={url} />

      {showHrefLangs
        ? product.alternateSites.map(({ link, culture }) => (
            <link
              key={culture}
              rel="alternate"
              hrefLang={culture}
              href={link}
            />
          ))
        : null}
      {showHrefLangs ? (
        <link rel="alternate" hrefLang="x-default" href={xDefault.link} />
      ) : null}
    </Helmet>
  );
};
