import * as React from 'react';

import { dispatchNotinoEvent } from '@utils/notino-events';

interface Options {
  section: string;
}

const EMPTY: Options = {
  section: undefined,
};

export const dispatchProductsShownEvent = (
  products:
    | Array<{
        webId: number | string;
        attributes?: { Pharmacy?: boolean };
      } | null>
    | null
    | undefined,
  options = EMPTY
) => {
  if (products && Array.isArray(products)) {
    dispatchNotinoEvent({
      name: 'products:shown',
      payload: {
        section: options.section,
        products: products.filter(Boolean).map((product) => ({
          id: String(product.webId),
          isPharmacy: Boolean(product.attributes?.Pharmacy),
        })),
      },
    });
  }
};

export const useDispatchProductsShowEvent = (
  products: Array<{
    webId: number | string;
    attributes?: { Pharmacy: boolean };
  }>,
  options = EMPTY
) => {
  const { section } = options;
  React.useEffect(() => {
    dispatchProductsShownEvent(products, { section });
  }, [products, section]);
};
