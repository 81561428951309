import { TrackingAttributes } from '@context/tracking/types';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackProductImageClick = ({
  timing,
}: Pick<TrackingAttributes, 'timing'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      type: 'image',
      interaction: 'open',
      name: 'pd_image',
      timing,
      action: undefined,
      mode: undefined,
      promo_labels: undefined,
    },
    _clear: true,
  });
};

export const trackProductImageTap = ({
  timing,
}: Pick<TrackingAttributes, 'timing'>) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      type: 'image',
      interaction: 'mobile_tap',
      name: 'pd_image',
      timing,
      action: undefined,
      mode: undefined,
      promo_labels: undefined,
    },
    _clear: true,
  });
};

export const trackSwitchingImages = ({
  timing,
  interaction,
}: Pick<TrackingAttributes, 'timing'> & {
  interaction: 'swipe' | 'button';
}) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      type: 'image',
      interaction,
      name: 'pd_image',
      timing,
      action: undefined,
      mode: undefined,
      promo_labels: undefined,
    },
    _clear: true,
  });
};
