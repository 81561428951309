import * as React from 'react';
import { useIntl } from 'react-intl';

import { useFormatPrice } from '@components/PriceLabel/useFormattedPrice';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { partition } from '@utils/array';

import { IConditionalVoucherDiscount } from '..';

import { ConditionsTooltip } from './ConditionsTooltip';
import { messages } from './messages';
import { Link } from './styled';

type Props = {
  discount: IConditionalVoucherDiscount;
};

export const ConditionalInstructionsText = ({
  discount: { targets, conditionType, discountConditions },
}: Props) => {
  const { formatMessage } = useIntl();
  const formatPrice = useFormatPrice();
  const {
    isDualPriceForCroatia,
    getLeftSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
    getRightSideFormattedPrice,
  } = useIsDualPriceForCroatia();

  const [brands, categories] = partition(targets, 'type', [
    'Brand',
    'Category',
  ]);

  const conditionMin = discountConditions[0].conditionMin;

  const priceOrPieces =
    conditionType === 'Piece'
      ? { pieces: conditionMin }
      : {
          price: isDualPriceForCroatia
            ? `${getLeftSideFormattedPrice(
                conditionMin
              )} ${leftSideCurrency} / ${getRightSideFormattedPrice(
                conditionMin
              )} ${rightSideCurrency}`
            : formatPrice({ value: conditionMin }).formattedPriceWithCurrency,
        };

  let formattedMessage;
  if (brands.length > 0 && categories.length > 0) {
    const message =
      conditionType === 'Price'
        ? messages.brandsNCategoriesNFromPrice
        : messages.brandsNCategoriesNFromPieces;

    formattedMessage = formatMessage(message, {
      ...priceOrPieces,
      these: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (brands.length > 1) {
    const message =
      conditionType === 'Price'
        ? messages.brandsNCategories0FromPrice
        : messages.brandsNCategories0FromPieces;

    formattedMessage = formatMessage(message, {
      ...priceOrPieces,
      brands: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (categories.length > 1) {
    const message =
      conditionType === 'Price'
        ? messages.brands0CategoriesNFromPrice
        : messages.brands0CategoriesNFromPieces;

    formattedMessage = formatMessage(message, {
      ...priceOrPieces,
      categories: (parts) => (
        <ConditionsTooltip targets={targets}>{parts}</ConditionsTooltip>
      ),
    });
  } else if (brands.length === 1) {
    const message =
      conditionType === 'Price'
        ? messages.brands1Categories0FromPrice
        : messages.brands1Categories0FromPieces;

    formattedMessage = formatMessage(message, {
      ...priceOrPieces,
      brand: (
        <Link href={brands[0].url}>
          <u>{brands[0].name}</u>
        </Link>
      ),
    });
  } else if (categories.length === 1) {
    const message =
      conditionType === 'Price'
        ? messages.brands0Categories1FromPrice
        : messages.brands0Categories1FromPieces;

    formattedMessage = formatMessage(message, {
      ...priceOrPieces,
      category: (
        <Link href={categories[0].url}>
          <u>{categories[0].name}</u>
        </Link>
      ),
    });
  } else {
    const message =
      conditionType === 'Price'
        ? messages.fromPriceSelectedProducts
        : messages.fromPiecesSelectedProducts;

    formattedMessage = formatMessage(message, priceOrPieces);
  }

  return formattedMessage;
};
