import styled from 'styled-components';

import { InteractiveArea, theme } from '@notino/react-styleguide';

export const ProductDiscountWrapper = styled.div`
  margin: -0.5rem 0 1rem 0;
`;

export const StyledInteractiveArea = styled(InteractiveArea)`
  vertical-align: middle;
  position: relative;
  margin-left: 3px;
  top: -4px;
  height: 1rem;
  z-index: 99;
  cursor: pointer;
  .interactive-area {
    transform: translate(-50%, -18px);
    height: 1.75rem;
    z-index: 9999;
  }
`;

export const TooltipContent = styled.div`
  min-width: 15rem;
`;

export const Divider = styled.div`
  border-bottom: dashed ${theme.color.common.gray80} 1px;
  margin: 0.3rem 0 0.45rem;
`;
