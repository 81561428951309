import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SectionTitle = styled.p`
  padding-top: 1.3rem;
  ${theme.typography.titleMedium}
  margin: 0 0 0.75rem;
`;

export const Text = styled.div`
  line-height: 1.5rem;
  margin: 0 0 0.3rem;
  font-size: 0.9rem;

  strong {
    font-size: 0.8rem;
  }

  color: ${theme.color.text.secondary};
  padding-right: 2rem;
  strong {
    font-size: inherit;
  }
`;

export const TableWrapper = styled.div`
  display: flex;

  table {
    border-spacing: 0;
    border: solid 1px ${theme.color.border.divider};
    overflow: hidden;
    border-radius: 0.25rem;
    font-family: 'Roboto';
    flex: 1;
    max-width: 100%;
  }

  tr {
    ${theme.typography.labelRegular400}

    &:first-of-type {
      ${theme.typography.labelRegular}
    }

    &:nth-child(odd) {
      background-color: ${theme.color.background.disabled};
    }
  }

  td {
    padding: 0.75rem 0.5rem;
    min-width: 8.125rem;
    text-align: right;

    &:first-child {
      color: ${theme.color.text.secondary};
      text-align: left;
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }

    @media (min-width: ${breakpoints.md}) {
      min-width: 10rem;
    }
  }
`;

export const VitaminsTableWrapper = styled(TableWrapper)``;

export const TableTitle = styled.div`
  ${theme.typography.titleMedium}
  margin-bottom: 0.75rem;
`;

export const TableSubtitle = styled.div`
  ${theme.typography.bodyLarge}
  color: ${theme.color.text.secondary};
  margin-bottom: 1.5rem;
`;

export const TableAnnotation = styled.span`
  ${theme.typography.bodySmall}
  display:block;
  color: #6b6b6b;
  margin: 0.75rem 0 0.5rem;
`;

export const TopMargin = styled.div`
  margin: 2rem 0 0;
`;
