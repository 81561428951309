import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { useQuery } from '@apollo/client';

import {
  GetRecommendedProductsQueryVariables,
  GetRecommendedProductsQuery,
} from '@notino/shared/definitions/types';

import { IProduct } from '@containers/ProductListing/model';

import messages from '../../messages';
import getRecommendedProductsQuery from '../../queries/databreakersRecommendedProducts.graphql';
import { useProductDetailContext } from '../ProductDetailContext';
import { ProductsSection } from '../ProductsSection';

import { LIST_LABEL } from './constants';

export interface IRelatedProductsProps {
  items: Array<IProduct['id']>;
  currentProductCode: string;
  shouldRenderProductsFromDatabreakers?: boolean;
}

export const RelatedProducts: React.FC<IRelatedProductsProps> = React.memo(
  ({
    items,
    currentProductCode,
    shouldRenderProductsFromDatabreakers = false,
  }) => {
    const [ref, inView] = useInView({ triggerOnce: true });
    const {
      product: { brand },
    } = useProductDetailContext();

    const { data, loading } = useQuery<
      GetRecommendedProductsQuery,
      GetRecommendedProductsQueryVariables
    >(getRecommendedProductsQuery, {
      variables: {
        productCode: currentProductCode,
        brandId: brand?.id ? Number(brand.id) : null,
      },
      skip: !shouldRenderProductsFromDatabreakers || !inView,
      ssr: false,
    });

    const relatedProductsIds = data?.databreakersRecommendedProducts
      ?.recommendedProducts?.length
      ? data.databreakersRecommendedProducts.recommendedProducts.map(
          (p) => p.productId
        )
      : items;

    return (
      <div ref={ref} data-testid="related-products-wrapper">
        {inView && !window.recoProducts?.tracking && (
          <ProductsSection
            productIds={relatedProductsIds ?? []}
            productIdsLoading={loading}
            titleMessage={messages.related}
            titleId="pdRelatedProducts"
            containerId="pd-related-products-header"
            listLabel={LIST_LABEL}
            withDivider={false}
            trackProductClick={true}
            trackProductImpression={true}
            attributionToken={
              data?.databreakersRecommendedProducts?.recommendationAttributes
                ?.attributionToken
            }
          />
        )}
      </div>
    );
  }
);
