import { dispatchTrackingEvent } from '@context/tracking/utils';

import { DrawerCloseType } from './useCloseType';

interface TrackDrawerCloseArgs {
  closeType: DrawerCloseType;
  timing: number;
  name: string;
  type: string;
  action: string;
}

export const trackDrawerClose = ({
  action,
  closeType,
  name,
  timing,
  type,
}: TrackDrawerCloseArgs) => {
  const interactionMap: Record<DrawerCloseType, string> = {
    automatic: 'automatic',
    backdrop: 'tap_out',
    button: 'X',
    swipe: 'swipe',
  };

  dispatchTrackingEvent({
    event: 'element_click',
    _clear: true,
    element: {
      timing,
      interaction: interactionMap[closeType] || closeType,
      name,
      type,
      action,
    },
  });
};
