import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IRowProps } from './styled';

export const NavLink: React.FC<IRowProps & LinkProps> = ({
  // these three props can not be propagated to the link
  isSelected: _isSelected,
  notInStock: _notInStock,
  children,
  ...rest
}) => <Link {...rest}>{children}</Link>;
