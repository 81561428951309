import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

interface IAttributesReviewsProps {
  visible: boolean;
}

export const AttributesReviews = styled.div<IAttributesReviewsProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  @media (min-width: ${breakpoints.md}) {
    display: block;
    margin-top: 3.125rem;
  }
`;

export const ParamProgressWrapper = styled.div`
  width: 50%;
  padding-right: 0.625rem;
`;

export const ParamValue = styled.div`
  ${theme.typography.labelSmall400};
  width: 50%;
`;
