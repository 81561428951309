import styled from 'styled-components';

import { breakpoints, Link } from '@notino/react-styleguide';

export const PictogramsWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: 0.25rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 2.5rem;
  }
`;

export const PictogramImage = styled.img`
  width: 3rem;
`;

export const PictogramItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MoreInfoLink = styled(Link)`
  display: block;
  margin-top: 0.25rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  min-width: 10rem;
`;
