import * as React from 'react';

import { Background, LogoWrapper } from './styled';
import { getLogoForBrand } from './supported-brands';

interface IBrandInfoProps {
  brandId: string;
  brandUrl: string;
}

export const BrandLogo: React.FC<IBrandInfoProps> = ({ brandId, brandUrl }) => {
  const logo = getLogoForBrand(brandId);

  if (!logo) {
    return null;
  }

  return (
    <LogoWrapper>
      <Background href={brandUrl}>{logo}</Background>
    </LogoWrapper>
  );
};
