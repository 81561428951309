import * as React from 'react';

import { IActions } from '../context/EngravingTryItFirst/model';

const engravingAllowedCharacters =
  /^[\w\sА-ЯҐЄІЇа-яґєіїΑ-Ωα-ωίϊΐόάέύϋΰήώa-zA-Zá-žÁ-Ž?.,!'+|%’–´&#@\-țșȚȘ]+$/i;

interface UseEngravingContentArgs {
  onContentClose: () => void;
  engravingInitials: string;
  changeEngravingInitials: IActions['changeEngravingInitials'];
}

export const useEngravingContent = ({
  onContentClose,
  engravingInitials,
  changeEngravingInitials,
}: UseEngravingContentArgs) => {
  const [initials, setInitials] = React.useState(engravingInitials);
  const [invalid, setInvalid] = React.useState(false);

  const handleInitialsChange = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const value = target.value.trimStart();
      setInitials(value);

      if (value && !engravingAllowedCharacters.test(value)) {
        setInvalid(true);
      } else {
        setInvalid((old) => old && !value);
      }
    },
    [setInitials, setInvalid]
  );

  const handleSave: React.FormEventHandler = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const trimmedInitials = initials.toUpperCase().trim();
      const newIsValid = trimmedInitials.length > 0;

      if (newIsValid && !invalid) {
        changeEngravingInitials(trimmedInitials);
        onContentClose();
      } else {
        changeEngravingInitials('');
        setInvalid(true);
      }
    },
    [initials, invalid, changeEngravingInitials, onContentClose]
  );

  return { handleInitialsChange, handleSave, initials, invalid };
};
