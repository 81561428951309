import {
  CatalogProductDetailViewFragmentFragment,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability } from '@notino/web-tracking';
import { VariantSelector } from '@notino/web-tracking/dist/types/package/ga4/events/constants';

import {
  runTrackingEventViewItem,
  runExponeaFunctions,
} from '@containers/App/utils';
import { FeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';

import {
  RRP_PRICE_PROMO_LABEL,
  MIN_PRICE_PROMO_LABEL,
} from './hooks/usePriceLogic/tracking';
import { IModifaceEffectVariants } from './ModiFaceModal/model';
import { getRenderableDiscoBoxSampleId } from './ProductBaseInfo/DiscoveryBoxSelfChoice';

interface ITrackPageReloadParams {
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  modifaceVariants: IModifaceEffectVariants;
  engravingAvailable: AdditionalServicesAvailability;
  tryItFirstAvailable: AdditionalServicesAvailability;
  rrpShown: boolean;
  minimalPriceShown: boolean;
  skinAnalyzer: boolean;
  trackingDisplayType?: VariantSelector;
  featureFlags: FeatureFlags;
}
export const trackPageReload = ({
  variant,
  product,
  modifaceVariants = {},
  engravingAvailable,
  tryItFirstAvailable,
  rrpShown,
  minimalPriceShown,
  trackingDisplayType,
  skinAnalyzer,
  featureFlags,
}: ITrackPageReloadParams) => {
  // We need to use setTimeout for perf. optim. of hydration
  // This event is often called with 'page_view' event
  // They are often called in separate `useEffect` hooks
  // To make sure this event is logged after 'page_view' event
  // We need to use setTimeout, just to track this event a little later

  setTimeout(() => {
    dispatchTrackingEvent({
      event: 'view_item',
      variant_selector: trackingDisplayType,
      products: [
        ProductEventWither()
          .withProduct(product)
          .withVariant(variant)
          .withAdditionalPromoLabels([
            rrpShown && RRP_PRICE_PROMO_LABEL,
            minimalPriceShown && MIN_PRICE_PROMO_LABEL,
          ])
          .withServices({
            engravingAvailable,
            tryItFirstAvailable,
            modifaceVariants,
            skinAnalyzer,
            discoveryBoxAvailable: Boolean(
              getRenderableDiscoBoxSampleId(product, variant, featureFlags)
            ),
          })
          .build(),
      ],
      _clear: true,
    });
  });
};

export const trackItemInExponea = (
  product: CatalogProductDetailViewFragmentFragment,
  variant: CatalogProductDetailViewFragmentFragment['variants'][number]
) => {
  runTrackingEventViewItem({
    id: variant.webId,
    name: variant.name,
    code: variant.productCode,
    masterProductCode: product.masterProductCode,
    brandName: product.brand?.name ?? '',
    gender: variant.gender,
    marketType: product.brand.market,
    price: variant.price.value,
    kind: product.kind,
    categories: product.category,
    subcategories: product.subCategory,
    types: product.type,
    stockQuantity: variant.stockAvailability.code,
    attributes: variant.attributes,
    isIndividual: product.variants.length === 1,
    size: variant.parameters?.amount,
    sizeUnit: product.variants[0]?.parameters?.unit,
  });
  runExponeaFunctions();
};
