import styled, { css } from 'styled-components';

import { theme } from '@notino/react-styleguide';

import { IRectangleProps } from './RectanglePlaceholder';

const baseCSS = css`
  margin: 0;
  background-color: ${theme.color.background.disabled};
`;

type StyledRectangleProps = IRectangleProps;

export const StyledRectangle = styled.div<StyledRectangleProps>`
  ${baseCSS};
  width: ${(props) => {
    return props.widthCss || props.size || '100%';
  }};
  height: ${(props) => {
    return props.heightCss || props.size || '100%';
  }};
  border-radius: 5px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 11.875rem;
  margin-bottom: 0.625rem;
`;
