import * as Sentry from '@sentry/browser';
export function dispatchNotinoEvent(
  args: Parameters<typeof window.NotinoAPI.Events.publish>[0]
) {
  if (window?.NotinoAPI?.Events) {
    window.NotinoAPI.Events.publish(args);
  } else {
    Sentry.captureMessage('window.NotinoAPI.Events is not defined');
  }
}
