import styled, { css } from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import { IExpandableTextProps } from './model';

const useOnlyOnCss = ({
  useOnlyOn,
}: Pick<IExpandableTextProps, 'useOnlyOn'>) => {
  if (useOnlyOn === 'mobile') {
    return css`
      @media (min-width: ${breakpoints.lg}) {
        display: none;
      }
    `;
  }
  if (useOnlyOn === 'desktop') {
    return css`
      display: none;
      @media (min-width: ${breakpoints.lg}) {
        display: block;
      }
    `;
  }
  return css``;
};

export const ExpandableTextWrapper = styled.div<
  Pick<IExpandableTextProps, 'isExpanded' | 'useOnlyOn' | 'height'>
>`
  height: ${({ isExpanded, height }) =>
    isExpanded ? 'auto' : height || '5.5rem'};
  overflow: ${({ isExpanded }) => (isExpanded ? 'auto' : 'hidden')};

  position: relative;

  ${({ useOnlyOn }) => {
    if (useOnlyOn === 'mobile') {
      return css`
        @media (min-width: ${breakpoints.lg}) {
          height: auto;
          overflow: auto;
        }
      `;
    }
    if (useOnlyOn === 'desktop') {
      return css`
        @media (max-width: ${breakpoints.lg}) {
          height: auto;
          overflow: auto;
        }
      `;
    }
  }}

  @media (min-width: ${breakpoints.lg}) {
    padding-right: 2rem;
  }
`;

export const Gradient = styled.div<Pick<IExpandableTextProps, 'useOnlyOn'>>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  ${useOnlyOnCss}
`;

export const DefaultExpandableTextButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.5rem 0;
  margin-top: 0.25rem;
`;

export const ExpandableTextButtonWrapper = styled.div<
  Pick<IExpandableTextProps, 'useOnlyOn'>
>`
  ${useOnlyOnCss}
`;
