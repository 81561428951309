import * as React from 'react';

import { Ga4Events } from '@notino/web-tracking/dist/types/package/ga4/events';

import { TrackingAttributes } from '@context/tracking/types';
import { isDesktop } from '@utils/helpers';

export interface TrackingContextValue {
  getTimeFromInit: (initTime?: number) => TrackingAttributes['timing'];
  getVariant: () => TrackingAttributes['variant'];
}

const Context = React.createContext<TrackingContextValue>(null!);

export const useTrackingContext = () => {
  const value = React.useContext(Context);
  if (!value)
    throw Error('useTrackingContext must be withing TrackingContextProvider.');

  return value;
};

export const TrackingContextProvider = ({
  children,
}: React.PropsWithChildren<object>) => {
  const initTime = React.useRef(new Date().getTime());

  useSubscribeToInitTimeReset(() => {
    initTime.current = new Date().getTime();
  });

  const value = React.useMemo(
    () => ({
      getTimeFromInit: (time = initTime.current) => new Date().getTime() - time,
      getVariant: () => {
        return isDesktop() ? 'desktop' : 'mobile';
      },
    }),
    []
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useSubscribeToInitTimeReset = (callback: () => void) => {
  const memoizedCallback = React.useRef(callback);
  React.useEffect(() => {
    memoizedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const resetTimeOnPageChange = <T extends Ga4Events>({
      detail,
    }: CustomEvent<T>) => {
      if (detail?.event === 'page_view') {
        memoizedCallback.current();
      }
    };

    document.addEventListener('ga4-tracking-event', resetTimeOnPageChange);
    return () => {
      document.removeEventListener('ga4-tracking-event', resetTimeOnPageChange);
    };
  }, []);
};

TrackingContextProvider.useSubscribeToInitTimeReset =
  useSubscribeToInitTimeReset;
