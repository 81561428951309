import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  reviewsSummaryFailed: {
    id: 'pd.reviews.fail.summary',
    defaultMessage: 'Nepodařilo se načíst souhrn recenzí',
  },
  reviewsFailed: {
    id: 'pd.reviews.fail.reviews',
    defaultMessage: 'Nepodařilo se načíst recenze',
  },
  reviewMotivatedTitle: {
    id: 'pd.reviews.motivated.title',
    defaultMessage: 'Dárek',
  },
  reviewUnknownTitle: {
    id: 'pd.reviews.unknown.title',
    defaultMessage: 'Neověřená recenze',
  },
  reviewVerifiedTitle: {
    id: 'pd.reviews.verified.title',
    defaultMessage: 'Ověřená recenze',
  },
  reviewMotivatedDescription: {
    id: 'pd.reviews.motivated.description',
    defaultMessage:
      'Recenzovaný produkt od nás dostal autor recenze zdarma jako dárek.',
  },
  reviewUnknownDescription: {
    id: 'pd.reviews.unknown.description',
    defaultMessage:
      'Recenzovaný produkt si u nás autor recenze nekoupil nebo jeho nákup nemůžeme ověřit.',
  },
  reviewVerifiedDescription: {
    id: 'pd.reviews.verified.description',
    defaultMessage:
      'Recenzovaný produkt si u nás autor recenze opravdu koupil.',
  },
  reviewMotivatedMoreLink: {
    id: 'pd.reviews.motivated.more.link',
    defaultMessage: 'null',
  },
  reviewUnknownMoreLink: {
    id: 'pd.reviews.unknown.more.link',
    defaultMessage: 'null',
  },
  reviewVerifiedMoreLink: {
    id: 'pd.reviews.verified.more.link',
    defaultMessage: 'null',
  },
  reviewMoreText: {
    id: 'pd.reviews.verified.more.text',
    defaultMessage: 'Více informací',
  },
  tryAgain: {
    id: 'reviews.send.review.retry',
    defaultMessage: 'Zkusit znovu',
  },
  collectingReviewsText: {
    id: 'pd.reviews.collect.reviews.text',
    defaultMessage: 'Jak sbíráme recenze',
  },
  collectingReviewsLink: {
    id: 'pd.reviews.collect.reviews.link',
    defaultMessage: '/produktove-recenze',
  },
});
