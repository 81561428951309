import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const Wrapper = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 2rem;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.75rem 0;
`;

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
`;

export const CollectReviewsWrapper = styled.div<{
  bazaarvoice_integration_pd: boolean;
}>`
  margin-bottom: 1rem;

  ${({ bazaarvoice_integration_pd }) =>
    bazaarvoice_integration_pd &&
    css`
      margin-top: 1rem;
    `}

  display: flex;
  gap: 0.06rem;
  align-items: center;
`;

export const CollectReviewsLink = styled('a')`
  color: ${theme.color.text.secondary};
  font-size: 0.75rem;

  &:hover {
    text-decoration: none;
    color: ${theme.color.common.black};
  }
`;
