import { isRenderingOnServer } from '@utils/isRenderingOnServer';

if (
  !isRenderingOnServer() &&
  navigator.mediaDevices &&
  navigator.mediaDevices.enumerateDevices
) {
  // Firefox 38+ seems having support of enumerateDevices
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigator.enumerateDevices = (callback: (param: any) => void) => {
    const enumerateDevices = navigator.mediaDevices.enumerateDevices();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (enumerateDevices && enumerateDevices.then) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(callback)
        .catch(() => {
          callback([]);
        });
    } else {
      callback([]);
    }
  };
}

export const hasDeviceWebcam = (callback: (hasWebcam: boolean) => void) => {
  if (
    (typeof MediaStreamTrack === 'undefined' ||
      !('getSources' in MediaStreamTrack)) &&
    (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)
  ) {
    callback(false);
    return;
  }

  if (
    !navigator.enumerateDevices &&
    window.MediaStreamTrack &&
    window.MediaStreamTrack.getSources
  ) {
    navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
      window.MediaStreamTrack
    );
  }

  if (!navigator.enumerateDevices && navigator.enumerateDevices) {
    navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
  }

  if (!navigator.enumerateDevices) {
    callback(false);
    return;
  }

  navigator.enumerateDevices((devices) => {
    const hasWebCam = devices.some(
      (device: { kind: string }) =>
        device.kind === 'videoinput' || device.kind === 'video'
    );
    callback(hasWebCam);
  });
};
