import * as React from 'react';

import { useQuery } from '@apollo/client';

import {
  ModalContext,
  Text,
  useBreakpointValue,
} from '@notino/react-styleguide';
import { AddToCartSettingsQuery } from '@notino/shared/definitions/types';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import ErrorBoundary from '@components/ErrorBoundary';

import {
  AddToCartButtonProvider,
  useAddToCartButtonContext,
  useIsMouseOverBuyButton,
} from './context';
import { IAddToCartButtonProps } from './model';
import getAddToCartSettings from './queries/addToCartSettings.graphql';
import { BuyButton } from './sharedComponents/BuyButton';
import { SpecialAddToCartModalOrDrawer } from './SpecialAddToCartModal';
import { useMessagesForSpecialModalOrDrawer } from './SpecialAddToCartModal/useMessagesForSpecialModal';
import { ButtonWrapper } from './styled';

export const AddToCartCoreComponent: React.FC<IAddToCartButtonProps> =
  React.memo((props) => {
    const { data } = useQuery<AddToCartSettingsQuery>(getAddToCartSettings);
    const isDesktop = useBreakpointValue({ md: true });
    const { toggleDrawer } = useDrawer();
    const { toggleModal } = ModalContext.useModalContext();

    const {
      props: {
        product,
        buttonWrapper: WrapperComponent = ButtonWrapper,
        buttonWrapperProps,
      },
    } = useAddToCartButtonContext();

    const mouseOverBuyButton = useIsMouseOverBuyButton();

    const settings = data?.Settings;
    const { isSpecial, description, header } =
      useMessagesForSpecialModalOrDrawer(product);

    const handleShowSpecialModal = () => {
      if (!isDesktop) {
        toggleDrawer(
          <AddToCartButtonProvider {...props}>
            <SpecialAddToCartModalOrDrawer
              description={description}
              productId={product.id}
              productCode={product.productCode}
              settings={settings}
            />
          </AddToCartButtonProvider>,
          {
            header,
          }
        );
        return;
      }

      toggleModal(
        <AddToCartButtonProvider {...props}>
          <SpecialAddToCartModalOrDrawer
            description={description}
            productId={product.id}
            productCode={product.productCode}
            settings={settings}
          />
        </AddToCartButtonProvider>,
        {
          header: <Text typography="titleLarge">{header}</Text>,
          center: true,
        }
      );
    };

    return (
      <ErrorBoundary>
        <WrapperComponent {...buttonWrapperProps}>
          <BuyButton
            productId={product.id}
            productCode={product.productCode}
            isSpecialProduct={isSpecial}
            Settings={settings}
            buttonType={mouseOverBuyButton ? 'button' : 'submit'}
            showSpecialModal={handleShowSpecialModal}
          />
        </WrapperComponent>
      </ErrorBoundary>
    );
  });
