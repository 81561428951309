import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';

import { NavLink } from './NavLink';

export interface IRowProps {
  isSelected: boolean;
  notInStock?: boolean;
}

export const Name = styled.div`
  flex-grow: 2;
  padding: 0.3125rem 0.625rem;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.sm}) {
    flex-basis: auto;
  }
  @media (min-width: ${breakpoints.md}) {
    flex-basis: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex-basis: auto;
  }

  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
  font-size: 0.875rem !important;
`;

export const Img = styled(CdnImage)`
  overflow: hidden;
  object-fit: contain;
  width: 2.5rem;
  height: 2.5rem;
`;

export const Discount = styled.span`
  color: ${theme.color.text.highlight};
  ${theme.typography.labelSmall}
`;

export const DiscountWrapper = styled.span`
  text-align: right;
  font-size: 0.9rem;
  line-height: 1.5rem;
  white-space: nowrap;
`;

export const LineThrough = styled.span<{
  active: boolean;
  removeLeftPadding?: boolean;
}>`
  color: ${theme.color.text.secondary};
  ${({ active }) => active && 'text-decoration: line-through'};
  ${({ removeLeftPadding }) =>
    css`
      ${theme.typography.labelSmall}
      color:${theme.color.text.tertiary};
      padding-left: ${removeLeftPadding ? '0' : '0.25rem'};
    `}
`;

export const Row = styled(NavLink)<IRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: ${theme.color.text.primary};
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  border-bottom: 1px solid ${theme.color.border.disabled};
  flex-grow: 1;
  opacity: ${(props) => (props.notInStock ? 0.7 : 1)};

  @media (min-width: ${breakpoints.md}) {
    &:last-child {
      border-bottom: 0;
    }
  }

  &:hover {
    ${Name} {
      text-decoration: underline;
    }
  }

  padding: 0.75rem 1rem 0.75rem 0.75rem;
  min-height: 4rem;
`;

export const ImgWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${theme.color.background.primary};
  margin-right: 0.5rem;
`;

interface IPriceProps {
  isSelected: boolean;
}

export const Price = styled.b<IPriceProps>`
  line-height: 1.5rem;
  font-size: 1.2rem;
  white-space: nowrap;
  font-weight: ${({ isSelected }) => (isSelected ? 'auto' : 'normal')};

  @media (min-width: ${breakpoints.sm}) {
    width: 100%;
    text-align: right;
  }

  @media (min-width: ${breakpoints.md}) {
    width: auto;
    text-align: left;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
    text-align: right;
  }

  ${({ isSelected }) =>
    css`
      ${theme.typography.labelRegular}
      font-weight: ${isSelected ? 700 : 500};
      > span {
        font-size: 0.875rem !important;
      }
    `}
`;

export const PriceBesideBelow = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const PriceWrapper = styled.span`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  width: auto;
`;

export const PriceSymbol = styled.span`
  margin-left: 0.3rem;
  font-size: 1rem;
`;

export const DualPriceWrapper = styled.div`
  display: flex;
`;

export const Slash = styled.span`
  padding: 0 0.25rem;
  line-height: 1.25rem;
`;

export const RrpSlash = styled.span`
  padding: 0.2rem;
  ${theme.typography.labelSmall}
  color:${theme.color.text.tertiary};
`;
