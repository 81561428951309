import styled, { css } from 'styled-components';

import { breakpoints, Link, theme } from '@notino/react-styleguide';

import { CommonContainer, CommonMarginWrapper } from '../../styled';

export const Container = styled(CommonContainer)`
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.lg}) {
    flex-flow: row;
  }
  p,
  ul {
    color: ${theme.color.text.secondary};
    strong {
      color: ${theme.color.text.primary};
    }
  }
`;

interface IFullWidth {
  fullWidth: boolean;
}

export const MarginWrapper = styled(CommonMarginWrapper)<IFullWidth>`
  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (min-width: ${breakpoints.md}) {
    flex-basis: ${(props: IFullWidth) =>
      props.fullWidth ? '100%' : 'calc(66% - 2rem)'};
    width: ${(props: IFullWidth) =>
      props.fullWidth ? '100%' : 'calc(66% - 2rem)'};
  }
`;

export const Description = styled.div``;

export const SectionTitle = styled.strong`
  margin-top: 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
`;

interface INameProps {
  isTooltip?: boolean;
}

const NameStyles = css<INameProps>`
  font-weight: bold;
  text-decoration: none;
  color: ${(props) => props.theme.color.text.primary};
  border-bottom: ${(props) => (props.isTooltip ? '1px dashed' : 'none')};
  border-bottom-color: ${theme.color.border.selected};
  display: inline-block;
  width: fit-content;
`;

export const CharacteristicsTable = styled.table`
  width: 100%;

  border-spacing: 0;
  border: solid 1px ${theme.color.border.divider};
  border-radius: 0.25rem;
  font-family: 'Roboto';

  tr {
    font-size: 0.9rem;

    &:first-of-type {
      ${theme.typography.labelRegular}
    }

    &:nth-child(odd) {
      background-color: ${theme.color.background.disabled};
    }
  }

  td {
    padding: 0.75rem 0.5rem;
    width: 50%;
    text-align: left;

    &:first-child {
      color: ${theme.color.text.secondary};
      text-align: left;
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }
`;

export const TooltipContentWrapper = styled.div`
  font-weight: 400 !important;
  min-width: 16rem;
`;

export const CharacteristicName = styled.td<INameProps>`
  ${NameStyles}

  div:first-child {
    cursor: ${(props: INameProps) => (props.isTooltip ? 'help' : 'default')};
  }

  border: none;
  display: table-cell;
  ${theme.typography.labelRegular400};
  padding: 0;
`;

export const CharacteristicValue = styled.td`
  padding: 0;
  ${theme.typography.labelRegular400}
`;

export const CharacteristicRow = styled.tr<IFullWidth>``;

export const CharacteristicsTableWrapper = styled.div`
  margin-bottom: 3rem;
  width: 100%;
`;

export const HairSensitivityTestLink = styled(Link)`
  display: block;
  margin-top: 1.3rem;

  font-size: 0.9rem;
  line-height: 1.5;
  text-decoration: underline;
`;

export const LivestreamsContainer = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 1.5rem;
    padding-right: 2rem;
  }
`;

export const ExpandButtonText = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  color: ${theme.color.text.primary};
  margin-bottom: -1rem;
  padding-top: 1.5rem;
`;

export const SkinAnalyserWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;
