export interface IEngravingTryItFirstContext {
  actions: IActions;
  state: IEngravingTryItFirstState;
}

export interface IEngravingTryItFirstState {
  withEngraving: boolean;
  withTryItFirst: boolean;
  engravingInitials: string;
}

export interface IActions {
  changeEngravingInitials: (value: string) => void;
  toggleTryItFirst: (value: boolean) => void;
  clear: () => void;
}

export enum ActionTypes {
  CHANGE_ENGRAVING_INITIALS = 'CHANGE_ENGRAVING_INITIALS',
  TOGGLE_TRY_IT_FIRST = 'TOGGLE_TRY_IT_FIRST',
  CLEAR = 'CLEAR',
}

export type Action =
  | { type: ActionTypes.CHANGE_ENGRAVING_INITIALS; value: string }
  | { type: ActionTypes.TOGGLE_TRY_IT_FIRST; value: boolean }
  | { type: ActionTypes.CLEAR };
