import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMutation } from '@apollo/client';

import { ButtonModel } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';
import {
  VariantDisplayType,
  AddToCartTryFirstMutationVariables,
  AddToCartTryFirstMutation,
  CatalogVariantFragmentFragment,
  GetCatalogProductViewQuery,
  AvailabilityState,
} from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability } from '@notino/web-tracking';

import ErrorBoundary from '@components/ErrorBoundary';
import { WishlistProductDetailIcon } from '@components/Universals/WishlistModifier/WishlistProductDetailIcon';
import { ICurrency, ILocale } from '@containers/App/model';
import { Klarna } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/Klarna';
import { useAutoOpenWatchdogModal } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/useAutoOpenWatchdogModal';
import { withProviders } from '@context/contextConfig';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import messages from '@messages';
import { Watchdog } from '@sharedComponents/Watchdog';
import { trackWatchdogClick } from '@sharedComponents/Watchdog/tracking';
import { useWatchdogActive } from '@sharedComponents/Watchdog/useWatchdogActive';
import { watchdogMessages } from '@sharedComponents/Watchdog/watchdogMessages';

import { IVariantsProps } from '../../model';
import { useDisplayType } from '../../utils';
import { IModifaceEffectVariants } from '../ModiFaceModal/model';

import { AddToCart } from './AddToCart';
import AvailableSets from './AvailableSets';
import { BellowTheLine } from './BellowTheLine';
import ConditionalFreeDelivery from './ConditionalFreeDelivery/loadable';
import {
  EngravingTryItFirstProvider,
  useEngravingTryItFirstContext,
} from './context/EngravingTryItFirst';
import { DeliveryTime, DeliveriesDisplayMode } from './DeliveryTime';
import DiscoveryBoxSelfChoice from './DiscoveryBoxSelfChoice';
import { Engraving } from './Engraving';
import { FreeGiftPackaging } from './FreeGiftPackaging';
import { Gift } from './Gift';
import { useTryItFirstCampaigns } from './hooks/useTryItFirstCampaigns';
import addToCartTryItFirstMutation from './queries/addToCartTryFirst.graphql';
import { SelectedVariant } from './SelectedVariant';
import Shadefinder from './Shadefinder';
import { SkinAnalyser } from './SkinAnalyser';
import { useSkinAnalyzerVisibility } from './SkinAnalyser/hooks/useAnalyzerVisibility';
import {
  Container,
  ButtonsWrapper,
  StyledWatchdogButton,
  WatchdogWrapper,
  SkinAnalyzerWrapper,
} from './styled';
import { trackTryItFirstAddToCart } from './tracking';
import { TryItFirst } from './TryItFirst';
import { useShouldRenderTagDiscount } from './utils';
import { isInfantFormula } from './utils/isInfantFormula';
import { Variants } from './Variants';
import { VariantsInSelectbox } from './VariantsInSelectBox';
import { VoucherDiscountMaster } from './VoucherDiscount';
import { useResolvedVoucherDiscount } from './VoucherDiscount/useResolvedVoucherDiscount';

export interface IProductBaseInfoProps {
  selectedVariant: CatalogVariantFragmentFragment;
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  variants: CatalogVariantFragmentFragment[];
  currency: ICurrency;
  locale: ILocale;
  brand: string;
  imageDomainPath: string;
  variantName: React.ReactNode;
  deliveriesDisplayMode?: DeliveriesDisplayMode;
  modifaceVariants: IModifaceEffectVariants;
  engravingAvailable: AdditionalServicesAvailability;
  tryItFirstAvailable: AdditionalServicesAvailability;
  shadefinderAvailable: boolean;
}

const displayTypesCommonRender = [
  VariantDisplayType.Tiles,
  VariantDisplayType.Thumbnail,
  VariantDisplayType.ColorPicker,
  VariantDisplayType.ShadesDrawer,
];

const ProductBaseInfoComponent: React.FC<IProductBaseInfoProps> = ({
  selectedVariant,
  currency,
  product,
  variants,
  locale,
  brand,
  variantName,
  deliveriesDisplayMode,
  modifaceVariants,
  engravingAvailable,
  tryItFirstAvailable,
  shadefinderAvailable,
}) => {
  const { formatMessage } = useIntl();
  const { klarna_nushop_id } = useFeatureFlags();

  const [tryItFirstAddToCart] = useMutation<
    AddToCartTryFirstMutation,
    AddToCartTryFirstMutationVariables
  >(addToCartTryItFirstMutation, {
    refetchQueries: ['getServicesIncluded'],
    onCompleted: () => {
      trackTryItFirstAddToCart(selectedVariant.productCode);
    },
  });

  const {
    state: { withTryItFirst },
    actions: { clear },
  } = useEngravingTryItFirstContext();

  const { watchdogActive } = useWatchdogActive({
    variant: selectedVariant,
    cacheOnly: false,
  });

  useAutoOpenWatchdogModal(selectedVariant);

  React.useEffect(() => {
    clear();
  }, [selectedVariant.webId, clear]);

  const tryItFirstCampaigns = useTryItFirstCampaigns(product.campaigns);

  const handleAddTryItFirst = React.useCallback(async () => {
    const tryItFirstCampaignForSelectedProduct = tryItFirstCampaigns.find(
      (campaign) => campaign.productId === selectedVariant.webId
    );

    if (withTryItFirst && tryItFirstCampaignForSelectedProduct) {
      await tryItFirstAddToCart({
        variables: {
          productCode: selectedVariant.productCode,
          productId: selectedVariant.webId,
          tryItFirstId: tryItFirstCampaignForSelectedProduct.campaignId,
        },
      });
    }

    clear();
  }, [
    withTryItFirst,
    tryItFirstCampaigns,
    selectedVariant,
    tryItFirstAddToCart,
    clear,
  ]);

  const variantsLen = variants.length;

  const displayType = useDisplayType(product);

  const renderTagDiscount = useShouldRenderTagDiscount(selectedVariant);

  const inStock = selectedVariant.stockAvailability.code !== IStock.outOfStock;
  const { deliveries } = product;

  const commonVariantsProps: IVariantsProps = {
    selectedId: selectedVariant.webId,
    selectedVariant,
    variants,
    locale,
    currency,
  };

  const shouldRenderTryItFirst = React.useMemo(
    () =>
      tryItFirstCampaigns.find(
        (campaign) => campaign.productId === selectedVariant.webId
      ),
    [tryItFirstCampaigns, selectedVariant]
  );

  const voucherDiscount = useResolvedVoucherDiscount();

  const conditionalFreeDelivery =
    selectedVariant.attributes.ConditionalFreeDelivery;

  const { shouldShowSkinAnalyzer, isVariant1, isVariant2 } =
    useSkinAnalyzerVisibility();

  return (
    <Container>
      <ErrorBoundary>
        {displayType === VariantDisplayType.Select && variantsLen > 1 && (
          <>
            <VariantsInSelectbox
              renderTagDiscount={
                renderTagDiscount && !isInfantFormula(selectedVariant)
              }
              {...commonVariantsProps}
            />
            {voucherDiscount && (
              <VoucherDiscountMaster
                isUnderSelectBox={true}
                currency={currency}
                voucherDiscount={voucherDiscount}
                hideLine={conditionalFreeDelivery}
              />
            )}
            {conditionalFreeDelivery && (
              <ConditionalFreeDelivery
                conditionalFreeDelivery={conditionalFreeDelivery}
                leftPadding={!!voucherDiscount}
              />
            )}
          </>
        )}

        {(displayTypesCommonRender.includes(displayType) ||
          (displayType === VariantDisplayType.Select && variantsLen === 1)) && (
          <>
            <SelectedVariant
              variant={selectedVariant}
              displayType={displayType}
              renderTagDiscount={
                renderTagDiscount && !isInfantFormula(selectedVariant)
              }
              variantName={variantName}
              hideLine={
                displayType === VariantDisplayType.ShadesDrawer ||
                !!voucherDiscount
              }
              hideVariantName={displayType === VariantDisplayType.ShadesDrawer}
            />
            {voucherDiscount && (
              <VoucherDiscountMaster
                currency={currency}
                voucherDiscount={voucherDiscount}
                hideLine={conditionalFreeDelivery}
              />
            )}

            {conditionalFreeDelivery && (
              <ConditionalFreeDelivery
                conditionalFreeDelivery={conditionalFreeDelivery}
                leftPadding={!!voucherDiscount}
              />
            )}

            <ErrorBoundary>
              {shadefinderAvailable && (
                <Shadefinder masterId={product.webMasterId} />
              )}
            </ErrorBoundary>

            <Variants
              selectedVariant={selectedVariant}
              variantsLen={variantsLen}
              displayType={displayType}
              {...commonVariantsProps}
            />
          </>
        )}

        <ErrorBoundary>
          {product.features?.availableSets?.sets?.length > 0 && (
            <AvailableSets />
          )}
        </ErrorBoundary>

        <ErrorBoundary>
          <DiscoveryBoxSelfChoice />
        </ErrorBoundary>

        <ErrorBoundary>
          <BellowTheLine variant={selectedVariant} locale={locale} />
        </ErrorBoundary>

        <ButtonsWrapper
          canBuy={
            selectedVariant.availability.state === AvailabilityState.CanBeBought
          }
        >
          <AddToCart
            product={product}
            currency={currency}
            variant={selectedVariant}
            orderUnit={formatMessage(messages.orderUnit)}
            productCode={product.masterProductCode}
            onProductAdded={handleAddTryItFirst}
            modifaceVariants={modifaceVariants}
            engravingAvailable={engravingAvailable}
            tryItFirstAvailable={tryItFirstAvailable}
          />
          <WatchdogWrapper>
            <Watchdog variant={selectedVariant} id="pdWatchdog">
              <StyledWatchdogButton
                buttonStyle={ButtonModel.Styles.primary}
                buttonSize={ButtonModel.Sizes.small}
                onClick={() => trackWatchdogClick(product, selectedVariant)}
                data-testid="pd-watchdog"
              >
                <FormattedMessage
                  {...(watchdogActive
                    ? watchdogMessages.watchdogActive
                    : watchdogMessages.watchAvailability)}
                />
              </StyledWatchdogButton>
            </Watchdog>
          </WatchdogWrapper>
        </ButtonsWrapper>

        <WishlistProductDetailIcon
          wishlisted={selectedVariant.wishlisted}
          productId={selectedVariant.webId}
        />

        {shouldShowSkinAnalyzer && isVariant1 && (
          <SkinAnalyser key={product.catalogMasterId} />
        )}

        {klarna_nushop_id ? (
          <Klarna
            locale={locale.tag}
            price={selectedVariant.price.value}
            priceDecimalPlaces={locale.priceDecimalPlaces}
          />
        ) : null}

        {selectedVariant.features.engraving?.allowed && (
          <Engraving
            code={selectedVariant.orderCode}
            currency={currency}
            maxChars={selectedVariant.features.engraving.characterCount}
            price={selectedVariant.features.engraving.config.price}
            image={selectedVariant.features.engraving.image}
            imageAlt={selectedVariant.media?.mainImage?.alt}
            engravingAvailable={engravingAvailable}
          />
        )}

        {shouldRenderTryItFirst && (
          <TryItFirst availability={tryItFirstAvailable} />
        )}

        <ErrorBoundary>
          <FreeGiftPackaging
            brand={brand}
            currency={currency}
            campaign={selectedVariant.campaignConfig}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <Gift />
        </ErrorBoundary>

        {inStock && (
          <DeliveryTime
            deliveries={deliveries}
            displayMode={deliveriesDisplayMode}
          />
        )}

        {shouldShowSkinAnalyzer && isVariant2 && (
          <SkinAnalyzerWrapper>
            <SkinAnalyser key={product.catalogMasterId} />
          </SkinAnalyzerWrapper>
        )}
      </ErrorBoundary>
    </Container>
  );
};

export const ProductBaseInfo = withProviders<IProductBaseInfoProps>(
  EngravingTryItFirstProvider
)(ProductBaseInfoComponent);
