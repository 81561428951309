import * as React from 'react';

import sortBy from 'lodash/sortBy';

import { useTabsConfig } from '@containers/ProductDetailContainer/ProductDetail/hooks/useTabsConfig';
import { TabIds } from '@containers/ProductDetailContainer/ProductDetail/model';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useUpdatedRef } from '@hooks/useUpdatedRef';
import { useIsDesktop } from '@utils/helpers';

export const TabSwitcherContext =
  React.createContext<ITabSwitcherProviderState>(null!);

interface ITab {
  id: TabIds;
  ref: React.RefObject<HTMLButtonElement>;
  tabOrder: number;
}

interface ITabContent {
  id: TabIds;
  tabOrder: number;
}

type AfterTabClosedByOpeningOtherTabSubscribeFn = (data: {
  closedTabId: TabIds;
  openTabId: TabIds;
}) => void;

export interface ITabSwitcherProviderState {
  activeTabId?: TabIds;
  tabContentsRef: React.MutableRefObject<ITabContent[]>;
  register: (
    tabId: number,
    ref: React.RefObject<HTMLButtonElement>,
    tabOrder: number
  ) => void;
  selectNext: () => void;
  selectPrevious: () => void;
  toggleActiveTab: (newActiveTabIndex: TabIds, isActive?: boolean) => void;
  registerTabContent: (tabCotent: ITabContent) => void;
  subscribeToAfterTabClosedByOpeningOtherTab: (
    subscriber: AfterTabClosedByOpeningOtherTabSubscribeFn
  ) => () => void;
}

export const TabSwitcherProvider = ({ children }: React.PropsWithChildren) => {
  const tabContentsRef = React.useRef<ITabContent[]>([]);
  const tabsRef = React.useRef<ITab[]>([]);

  const { defaultTabId, isDefaultTabOpenOnMobile } = useTabsConfig();

  const [desktopActiveTabId, setDesktopActiveTabId] =
    React.useState<TabIds>(defaultTabId);
  const [mobileActiveTabId, setMobileActiveTabId] = React.useState<TabIds>(
    isDefaultTabOpenOnMobile ? defaultTabId : undefined
  );

  const afterTabClosedByOpeningOtherTabSubscribers = React.useRef<
    AfterTabClosedByOpeningOtherTabSubscribeFn[]
  >([]);

  const isDesktop = useIsDesktop({
    defaultState: true,
  });
  const activeTabId = isDesktop ? desktopActiveTabId : mobileActiveTabId;
  const activeTabIdRef = useUpdatedRef(activeTabId);

  const setActiveTab = React.useCallback(
    (_tabId: TabIds) => {
      if (isDesktop) {
        _tabId && setDesktopActiveTabId(_tabId);
        return;
      }

      if (
        !!_tabId &&
        !!activeTabIdRef.current &&
        activeTabIdRef.current !== _tabId
      ) {
        afterTabClosedByOpeningOtherTabSubscribers.current.forEach((sub) =>
          sub({ closedTabId: activeTabIdRef.current, openTabId: _tabId })
        );
      }
      setMobileActiveTabId(_tabId);
    },
    [isDesktop, activeTabIdRef]
  );

  const toggleActiveTab = React.useCallback(
    (tabId: TabIds, isActive?: boolean) => {
      if (typeof isActive === 'boolean') {
        if (isActive) {
          setActiveTab(tabId);
          return;
        }

        setActiveTab(
          activeTabIdRef.current === tabId ? undefined : activeTabIdRef.current
        );
        return;
      }

      setActiveTab(activeTabIdRef.current === tabId ? undefined : tabId);
    },
    [activeTabIdRef, setActiveTab]
  );

  const { product } = useProductDetailContext();
  React.useEffect(() => {
    const resetTabsOnProductChange = () => {
      setDesktopActiveTabId(defaultTabId);
      setMobileActiveTabId(isDefaultTabOpenOnMobile ? defaultTabId : undefined);
    };
    resetTabsOnProductChange();
  }, [product.catalogMasterId, defaultTabId, isDefaultTabOpenOnMobile]);

  const selectNext = React.useCallback(() => {
    const tabs = tabsRef.current;
    const currTabIndex = tabs.findIndex((tab) => tab.id === desktopActiveTabId);
    let newActiveTab;
    if (currTabIndex + 1 < tabs.length) {
      newActiveTab = tabs[currTabIndex + 1];
    } else {
      newActiveTab = tabs[0];
    }
    newActiveTab.ref.current.focus();
    setDesktopActiveTabId(newActiveTab.id);
  }, [desktopActiveTabId]);

  const selectPrevious = React.useCallback(() => {
    const tabs = tabsRef.current;
    const currTabIndex = tabs.findIndex((tab) => tab.id === desktopActiveTabId);
    let newActiveTab;
    if (currTabIndex > 0) {
      newActiveTab = tabs[currTabIndex - 1];
    } else {
      newActiveTab = tabs[tabs.length - 1];
    }
    newActiveTab.ref.current.focus();
    setDesktopActiveTabId(newActiveTab.id);
  }, [desktopActiveTabId]);

  const register = React.useCallback(
    (tabId: ITab['id'], ref: ITab['ref'], tabOrder: number) => {
      tabsRef.current = sortBy(
        [...tabsRef.current, { id: tabId, ref, tabOrder }],
        'tabOrder' satisfies keyof ITab
      );
    },
    []
  );

  const registerTabContent = React.useCallback((tabContent: ITabContent) => {
    tabContentsRef.current = sortBy(
      [...tabContentsRef.current, tabContent],
      'tabOrder' satisfies keyof ITabContent
    );
  }, []);

  const subscribeToAfterTabClosedByOpeningOtherTab = React.useCallback(
    (subscriber: AfterTabClosedByOpeningOtherTabSubscribeFn) => {
      afterTabClosedByOpeningOtherTabSubscribers.current.push(subscriber);
      return () => {
        afterTabClosedByOpeningOtherTabSubscribers.current =
          afterTabClosedByOpeningOtherTabSubscribers.current.filter(
            (fn) => fn !== subscriber
          );
      };
    },
    []
  );

  const value: ITabSwitcherProviderState = React.useMemo(
    () => ({
      activeTabId,
      tabContentsRef,
      selectNext,
      selectPrevious,
      toggleActiveTab,
      register,
      registerTabContent,
      subscribeToAfterTabClosedByOpeningOtherTab,
    }),
    [
      activeTabId,
      tabContentsRef,
      selectNext,
      selectPrevious,
      toggleActiveTab,
      register,
      registerTabContent,
      subscribeToAfterTabClosedByOpeningOtherTab,
    ]
  );

  return (
    <TabSwitcherContext.Provider value={value}>
      {children}
    </TabSwitcherContext.Provider>
  );
};

export const useTabSwitcher = () => {
  const value = React.useContext(TabSwitcherContext);
  if (!value || Object.keys(value).length === 0) {
    throw Error('useTabSwitcher must be used inside TabSwitcherProvider');
  }

  return value;
};
