import styled from 'styled-components';

import {
  Input,
  Button,
  breakpoints,
  Heading,
  IBasicStyledProps,
  theme,
} from '@notino/react-styleguide';

interface IDisabledText extends IBasicStyledProps {
  disabled: boolean;
}

export const Container = styled.div`
  padding: 1rem 0;

  label {
    ${theme.typography.labelRegular}
  }
`;

export const Description = styled.div`
  padding-left: 2.25rem;
  font-size: 0.875rem;
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }

  margin-top: 0.25rem;
  color: ${theme.color.text.secondary};
  line-height: 1.5rem;
  a {
    color: ${theme.color.text.primary};
  }
`;

export const Edit = styled.button`
  cursor: pointer;
  text-decoration: underline;
  background: transparent;
  border: 0;
  display: block;
`;

export const ModalBody = styled.div`
  text-align: center;
`;

export const ImageText = styled.div`
  color: ${theme.color.text.tertiary};
  font-weight: 300;
  margin-top: 0.5625rem;
  text-align: left;
`;

export const ImageTextDrawer = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: ${theme.color.background.secondary};

  display: flex;
  gap: 0.56rem;

  margin-bottom: 1.25rem;

  ${theme.typography.labelSmall400}
`;
export const ImageMaxText = styled.div`
  color: ${theme.color.text.tertiary};
  font-weight: 300;
  text-align: center;
  margin-bottom: 1.125rem;
  width: 100%;
`;
export const ImageMaxTextDrawer = styled(ImageMaxText)`
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;
  text-align: left;
  ${theme.typography.labelSmall400}
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  img {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  padding: 0 1.875rem;

  @media (min-width: ${breakpoints.md}) {
    margin-left: 0.9375rem;
  }
`;

export const StyledInput = styled(Input)`
  width: 6.75rem;
  text-align: center;
  text-transform: uppercase;
`;

export const DrawerInput = styled(StyledInput)`
  width: 100%;
  text-align: start;
`;

export const H3 = styled(Heading.H3)`
  margin-top: 1.875rem;
  text-align: center !important;
  margin-bottom: 0;
`;

export const H3Drawer = styled(H3)`
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  ${theme.typography.titleLarge}
`;

export const EngravingImage = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1.5625rem;
  max-height: 25rem;

  @media (min-width: ${breakpoints.sm}) {
    padding: 1.25rem;
    min-height: 15.625rem;
    flex-basis: 50%;
    order: 1;
    margin-top: 0;
    justify-content: flex-start;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
    object-fit: scale-down;
    mix-blend-mode: multiply;
  }
`;

export const EngravingImageDrawer = styled(EngravingImage)`
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1.25rem;

  padding-top: 1.5rem;

  display: flex;
  justify-content: center;

  background-color: #f5f5f5;

  @media (min-width: ${breakpoints.sm}) {
    order: initial;
  }

  img {
    width: 300px;
    height: 300px;
  }
`;

export const EngravingFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const EngravingDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

export const EngravingBody = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding: 0 0.9375rem;

  @media (min-width: ${breakpoints.sm}) {
    flex-basis: 50%;
    order: 2;
    margin-top: 0;
    padding: 0;
  }
`;
export const EngravingDrawerBody = styled.div``;

export const BodyWrapper = styled.div`
  padding: 0 0.9375rem;
`;

export const DisabledTextWrapper = styled.div<IDisabledText>`
  color: ${(props) =>
    props.disabled
      ? props.theme.color.text.disabled
      : props.theme.color.text.primary};
`;

export const InputErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 1.6rem;
`;
