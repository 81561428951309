import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, SkeletonElement, Ratings } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';
import { GetLoadingProductQuery } from '@notino/shared/definitions/types';

import { ContentWrapper, TabWrapper } from '@components';
import { BrandLogo } from '@containers/ProductDetailContainer/ProductDetail/ProductImageGallery/BrandLogo';
import { DummyTabSwitcherProvider } from '@sharedComponents/TabSwitcher/context/DummyTabSwitcherContextProvider';
import { TabDivider } from '@sharedComponents/TabSwitcher/styled';

import messages from '../messages';
import { BreadcrumbWrapper } from '../ProductDetail/Breadcrumb/styled';
import { Header } from '../ProductDetail/Header';
import { TabIds } from '../ProductDetail/model';
import {
  CodeBlock,
  CodeName,
  Separator,
  Availability,
  Wrapper,
} from '../ProductDetail/ProductBaseInfo/BellowTheLine/styled';
import { Container } from '../ProductDetail/ProductBaseInfo/styled';
import { ProductImageGallery } from '../ProductDetail/ProductImageGallery';
import { ContentTabLink } from '../ProductDetail/ProductTabContent/ContentSwitcher/ContentTabLink';
import {
  ContentSwitcherWrapper,
  TabList,
  ContentSwitcherContainer,
  Container as SwitcherContainer,
} from '../ProductDetail/ProductTabContent/ContentSwitcher/styled';
import {
  Main,
  TopSection,
  GalleryWrapper,
  ShowOnMobile,
  MainInfoWrapper,
  ShowOnDesktop,
} from '../ProductDetail/styled';

import { PDSkeletonElement, EmptySpace, Row } from './styled';

interface ILoadingSkeletonProps {
  product: GetLoadingProductQuery['vpProductById'];
  imageDomainPath: string;
}

export const SpaLoadingSkeleton = ({
  product,
  imageDomainPath,
}: ILoadingSkeletonProps) => {
  const headerProps = {
    brandInfo: {
      id: '#',
      name: '',
      __typename: 'Brand' as const,
      description: '',
      url: '#',
      market: '',
      manufacturer: '',
    },
    productId: product.id,
    catalogId: product.catalogMasterId.toString(),
    productCode: product.productCode,
    collection: product.name,
    subName: null,
    desc: product.annotation,
    hideReviews: true,
  };

  const ratingsProps = {
    rating: product?.reviewOverview?.rating ?? 0,
    size: 16,
    numReviews: 0,
    foreGround: 'text.highlight',
    microdata: false,
  };

  return (
    <>
      <ContentWrapper data-testid="pd-loading-skeleton" isWider={true}>
        <Main>
          <Grid fluid={true}>
            <BrandLogo
              brandId={product.brand?.id}
              brandUrl={product.brand?.url}
            />

            <TopSection isLoading={true}>
              <GalleryWrapper>
                <ShowOnMobile>
                  <BreadcrumbWrapper>
                    <SkeletonElement height={1} width={15} />
                  </BreadcrumbWrapper>
                  <Header {...headerProps}>
                    <Ratings
                      ratingId="loading-skeleton-id-mobile"
                      {...ratingsProps}
                    />
                  </Header>
                </ShowOnMobile>

                <ProductImageGallery
                  media={{
                    mainImage: {
                      src: product.imageUrl,
                      alt: `${product?.brand?.name} ${product?.name}`,

                      __typename: 'Image',
                    },
                    __typename: 'VariantMedia',
                    shadeImage: null,
                    previewImage: null,
                    others: [],
                    videos: [],
                  }}
                  domain={imageDomainPath}
                />
              </GalleryWrapper>

              <MainInfoWrapper>
                <ShowOnDesktop>
                  <BreadcrumbWrapper>
                    <SkeletonElement height={1} width={15} />
                  </BreadcrumbWrapper>
                  <Header {...headerProps}>
                    <Ratings
                      ratingId="loading-skeleton-id-desktop"
                      {...ratingsProps}
                    />
                  </Header>
                </ShowOnDesktop>
                <Container>
                  <PDSkeletonElement
                    height={2}
                    marginTop={7.5}
                    marginBottom={0.5}
                  />

                  <Row>
                    <PDSkeletonElement
                      width={6.8}
                      height={3.5}
                      marginRight={0.25}
                    />
                    <PDSkeletonElement
                      width={6.8}
                      height={3.5}
                      marginRight={0.25}
                    />
                    <PDSkeletonElement
                      width={6.8}
                      height={3.5}
                      marginRight={0.25}
                    />
                  </Row>
                  <Wrapper>
                    <CodeBlock>
                      <CodeName>
                        <FormattedMessage {...messages.code} />{' '}
                      </CodeName>
                      <PDSkeletonElement
                        width={4}
                        height={1}
                        display="inline-block"
                      />
                    </CodeBlock>

                    <Separator> | </Separator>
                    <Availability
                      highlight={[IStock.outOfStock, IStock.lessThan5].includes(
                        product.stockAvailability.code as IStock
                      )}
                    >
                      <FormattedMessage
                        {...messages[product.stockAvailability.code]}
                        values={{ count: product.stockAvailability.count }}
                      />
                    </Availability>
                  </Wrapper>
                  <PDSkeletonElement width={20} height={3} marginTop={2} />
                  <PDSkeletonElement width={16} height={2} marginTop={1} />
                  <PDSkeletonElement height={4} marginTop={2} />

                  <PDSkeletonElement height={2} marginTop={2} />
                </Container>
              </MainInfoWrapper>
            </TopSection>

            <DummyTabSwitcherProvider>
              <>
                <ContentSwitcherContainer>
                  <ContentSwitcherWrapper>
                    <SwitcherContainer>
                      <Row>
                        <TabList>
                          <ContentTabLink
                            tabId={TabIds.Description}
                            id="tabDescription"
                          >
                            <FormattedMessage
                              {...messages.prodDescription}
                              values={{ name: '' }}
                            />
                          </ContentTabLink>
                        </TabList>
                      </Row>
                    </SwitcherContainer>
                  </ContentSwitcherWrapper>
                </ContentSwitcherContainer>
                <TabWrapper role="tabpanel">
                  <div>
                    <TabDivider
                      tabDividerMobile={true}
                      tabDividerDesktop={true}
                    />
                  </div>
                </TabWrapper>
              </>
            </DummyTabSwitcherProvider>
          </Grid>
        </Main>
      </ContentWrapper>
      <EmptySpace />
    </>
  );
};
