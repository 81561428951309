export const TRACKING_ATTRIBUTES = {
  description: 'Description PD',
  brand: 'Brand PD',
  reviews: 'Reviews PD',
  richContent: 'Rich Content PD',
  composition: 'Composition PD',
  other: 'Other PD',
};

export const TAB_IDENTIFIERS = {
  description: 'description-tab',
  brand: 'brand-tab',
  reviews: 'reviews-tab',
  richContent: 'rich-content-tab',
  composition: 'composition-tab',
  livestreamsTab: 'livestreams-tab',
  other: 'other-tab',
};
