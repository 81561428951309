import { useLocation } from 'react-router';

import { masterUrl } from '@notino/shared/client-utils';
import { CatalogProductDetailViewFragmentFragment } from '@notino/shared/definitions/types';

import { useUpdateEffect } from '@hooks';

import { trackItemInExponea } from '../tracking';

export const useTrackProductUrlChange = (
  product: CatalogProductDetailViewFragmentFragment,
  variant: CatalogProductDetailViewFragmentFragment['variants'][number]
) => {
  const { pathname } = useLocation();
  const productUrl = masterUrl(pathname);

  useUpdateEffect(() => {
    trackItemInExponea(product, variant);
  }, [productUrl]);
};
