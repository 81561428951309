import * as React from 'react';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import {
  CatalogVariantFragmentFragment,
  VariantDisplayType,
} from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import PriceLabel from '@components/PriceLabel';

import { useIsDualPriceForCroatia } from '../../hooks/useIsDualPriceForCroatia';
import { TagDiscount } from '../VariantsInSelectBox/TagDiscount';
import { useHasStressOnVoucherDiscount } from '../VoucherDiscount/useHasStressOnVoucherDiscount';

import {
  Container,
  FlexWrapper,
  Name,
  Line,
  LineWrapper,
  PriceWrapper,
  CurrencyWrapper,
  DualPriceSplitter,
  DualPrice,
  DualPriceWrapper,
  SmallerPriceWrapper,
  SmallerCurrencyWrapper,
} from './styled';

export interface ISelectedVariantProps {
  variant: CatalogVariantFragmentFragment;
  displayType: VariantDisplayType;
  singleVariant?: boolean;
  renderTagDiscount: boolean;
  variantName: React.ReactNode;

  hideLine?: boolean;
  hideVariantName?: boolean;
}

export const SelectedVariant: React.FC<ISelectedVariantProps> = React.memo(
  ({
    variant,
    renderTagDiscount,
    variantName,
    hideLine = false,
    hideVariantName = false,
  }) => {
    const {
      isDualPriceForCroatia,
      getLeftSideFormattedPrice,
      getRightSideFormattedPrice,
      leftSideCurrency,
      rightSideCurrency,
    } = useIsDualPriceForCroatia();
    const inStock = variant.stockAvailability.code !== IStock.outOfStock;

    const isSmall = useHasStressOnVoucherDiscount();

    const getPriceWrapper = () => {
      if (isSmall) {
        return SmallerPriceWrapper;
      }
      return PriceWrapper;
    };

    const getCurrencyWrapper = () => {
      return isSmall ? SmallerCurrencyWrapper : CurrencyWrapper;
    };

    return (
      <ErrorBoundary>
        <Container id="pdSelectedVariant">
          {renderTagDiscount && <TagDiscount variant={variant} />}
          <FlexWrapper aria-live="assertive" tabIndex={0}>
            <Name isSmall={isSmall}>
              {hideVariantName ? null : variantName}
            </Name>

            {inStock && !isDualPriceForCroatia && (
              <PriceLabel
                price={variant.price}
                wrapperComponent={getPriceWrapper()}
                currencyComponent={getCurrencyWrapper()}
                id="pd-price"
                dataTestId="pd-price"
              />
            )}

            {inStock && isDualPriceForCroatia && (
              <DualPriceWrapper id="pd-price" data-testid="pd-dual-price">
                <DualPrice isSmall={isSmall}>
                  {getLeftSideFormattedPrice(variant.price.value)}{' '}
                  <span>{leftSideCurrency}</span>
                </DualPrice>

                <DualPriceSplitter isSmall={isSmall}>/</DualPriceSplitter>

                <DualPrice isSmall={isSmall}>
                  {getRightSideFormattedPrice(variant.price.value)}{' '}
                  <span>{rightSideCurrency}</span>
                </DualPrice>
              </DualPriceWrapper>
            )}
          </FlexWrapper>
          {!hideLine && (
            <LineWrapper>
              <Line />
            </LineWrapper>
          )}
        </Container>
      </ErrorBoundary>
    );
  }
);

SelectedVariant.displayName = 'SelectedVariant';
