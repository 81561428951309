import * as React from 'react';

import { IBasicStyledProps } from '@notino/react-styleguide';

import { Image } from './styled';
import { useImageAttributes } from './useImageAttributes';

export interface ICdnImageProps extends IBasicStyledProps {
  src: string;
  sourceKey: string;
  alt?: string;
}

export const CdnImage: React.FC<ICdnImageProps> = ({
  src,
  sourceKey,
  alt = '',
  ...rest
}) => {
  const imgProps = useImageAttributes(src, sourceKey, alt);

  return <Image {...imgProps} {...rest} />;
};
