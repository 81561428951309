export interface IRoute {
  component: string;
  path?: string;
  exact?: boolean;
  rolesOnly?: Roles[];
}

interface IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  lastName2: string;
  email: string;
}

// watch out here, user needs to be single level object for the purposes of error reporting (see src/app.ts)
export interface IUser {
  role: string;
  id?: string;
  token?: string;
  cartId?: string;
  info?: IUserInfo;
}

export interface ISettings {
  useNewAddToCart: boolean;
  imageDomainPath: string;
  addToCartSettings: {
    buttonAllowed: string;
    buttonAllowedForAll: boolean;
    modalAllowed: string;
    isNewShoppingCartEndpointEnabled: boolean;
  };
  engravingSettings: {
    allowed: string;
  };
  routineSettings: {
    allowed: string;
  };
  routes: IRoute[];
  isWishlistAllowed: boolean;
  isTryItFirstEnabled: boolean;
  deliveriesDisplayMode?: string;
}

export interface ICurrency {
  id: number;
  code: string;
  symbol: string;
}

export interface ILocale {
  id: number;
  tag: string;
  currency: string;
  priceDecimalPlaces: number;
  priceFormat: string; // values: 'vs', 'v s', 'sv', 's v' where 'v' stands for value, 's' for symbol
}

export enum Roles {
  ANONYMOUS = 'Anonymous',
  CUSTOMER = 'Customer',
  SUPERUSER = 'Superuser',
  ADMIN = 'Admin',
}

export enum EShop {
  NOTINO_CZ = 'notino.cz',
  NOTINO_SK = 'notino.sk',
  NOTINO_PL = 'notino.pl',
  NOTINO_DE = 'notino.de',
  NOTINO_AT = 'notino.at',
  NOTINO_HU = 'notino.hu',
  NOTINO_ES = 'notino.es',
  NOTINO_PT = 'notino.pt',
  NOTINO_COM = 'notino.com',
  NOTINO_CO_UK = 'notino.co.uk',
  NOTINO_FR = 'notino.fr',
  NOTINO_IT = 'notino.it',
  NOTINO_NL = 'notino.nl',
  NOTINO_BE = 'notino.be',
  NOTINO_HR = 'notino.hr',
  NOTINO_SE = 'notino.se',
  NOTINO_DK = 'notino.dk',
  NOTINO_FI = 'notino.fi',
  NOTINO_GR = 'notino.gr',
  NOTINO_RO = 'notino.ro',
  NOTINO_SI = 'notino.si',
  NOTINO_UA = 'notino.ua',
  NOTINO_BG = 'notino.bg',
}

export const RoleGroups = {
  KNOWN: [Roles.ADMIN, Roles.CUSTOMER, Roles.SUPERUSER],
};

export type Validator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  allValues?: Record<string, unknown>
) => string | { key: string; values: Record<string, string> };

export interface IValidationDictionary {
  [key: string]: IValidation;
}

export interface IValidation {
  required?: boolean;
  maxLength?: number;
  regex?: {
    pattern: string;
    modifier?: string;
    errMsg?: string;
  };
}

export type IRegexValidatorFactory = (
  pattern: string,
  modifier?: string,
  error?: string
) => Validator;

export enum RequestState {
  DEFAULT = 'default',
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  RETRYING = 'retrying',
  FAILED = 'failed',
}

export type RequestUser = RequestContext & {
  xUserToken?: string;
  jwtToken?: string;
  userName?: string;
};

/**
 * Add shopId model to global types
 */
declare global {
  namespace Express {
    // tslint:disable-next-line:interface-name
    interface Request {
      user?: RequestUser;
    }
  }

  // tslint:disable-next-line:interface-name
  interface RequestContext {
    shopId: EShop;
    role: Roles;
    groupId?: string;
    userId?: string;
    email?: string;
    givenName?: string;
    userName?: string;
    cartId?: string;
    comparisonIds?: string;
    authorization?: string;
    grd: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jwtPayload?: any;
    consent: string[];
  }

  // tslint:disable-next-line:interface-name
  interface GraphQLRequestContext {
    shopId?: EShop;
  }
}
