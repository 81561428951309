import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const SliderWrapper = styled.div`
  height: 4.375rem;
  display: none;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const commonImgStyles = css`
  width: 8.75rem !important;
  height: 8.75rem !important;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0.25rem;
`;

export const Img = styled.img<{ isSelected: boolean }>`
  cursor: pointer;
  display: inline;
  ${commonImgStyles}

  ${({ isSelected }) =>
    !isSelected &&
    css`
      opacity: 0.5;
    `}
`;

export const Div = styled.div`
  min-width: 4.375rem;
  line-height: 1rem;
  position: relative;
  margin-bottom: 0.3125rem;

  cursor: pointer;
  text-align: center;
  mix-blend-mode: multiply;
`;

export const TopMostDiv = styled.div`
  position: relative;
  border-radius: 0.25rem;
  background-color: ${theme.color.background.disabled};
`;

export const Border = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 2px solid ${theme.color.border.selected};
  border-radius: 0.25rem;
`;

export const VideoIcon = styled.div`
  opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
