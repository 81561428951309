import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { Wrapper, Currency } from '@components/PriceLabel/components';
export const Container = styled.div`
  margin-top: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.5rem;
  height: 2rem;
`;

export const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem 0;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0.5rem;
  }
`;

export const Line = styled.hr`
  flex: 1;
  border-top: 1px dashed ${theme.color.border.divider};
  height: 0.0625rem;
  margin: 0;
  border-bottom: none;
`;

export const Name = styled.div<{
  isSmall: boolean;
}>`
  padding: 0 0.5rem 0.05rem 0;
  ${({ isSmall }) =>
    css`
      ${isSmall
        ? theme.typography.labelRegular400
        : theme.typography.labelLarge400}
    `}
`;

export const PriceWrapper = styled(Wrapper)`
  ${theme.typography.titleDisplay}
`;

export const SmallerPriceWrapper = styled(Wrapper)`
  ${theme.typography.labelLarge}
`;

export const CurrencyWrapper = styled(Currency)`
  ${theme.typography.titleLarge}
`;

export const SmallerCurrencyWrapper = styled(Currency)`
  ${theme.typography.labelLarge}
`;

export const DualPriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const DualPrice = styled.div<{ isSmall: boolean }>`
  font-size: 1.55rem;
  font-weight: 700;
  ${({ isSmall }) =>
    isSmall ? theme.typography.labelLarge : theme.typography.titleLarge}
`;

export const DualPriceSplitter = styled.div<{
  isSmall: boolean;
}>`
  padding: 0 0.3rem;
  font-size: 1.55rem;
  font-weight: 700;
  ${({ isSmall }) =>
    isSmall ? theme.typography.labelLarge : theme.typography.titleLarge}
`;
