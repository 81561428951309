import { IBasicStyledProps } from '@notino/react-styleguide';
import {
  CatalogVariantFragmentFragment,
  ReviewOrderBy,
  VariantDisplayType,
} from '@notino/shared/definitions/types';

import { ICurrency, ILocale } from '@containers/App/model';
import {
  IPrice,
  AttributeType,
  IStockInfo,
} from '@containers/ProductListing/model';

import { HandleTransitionClick } from './ProductDetail/ProductBaseInfo/Variants/useVariantTransition';

/** Interfaces */
export interface IOpenedProps {
  isExpanded: boolean;
}

enum WatchdogErrorTypes {
  ERROR = 'Error',
  VALIDATION_ERROR = 'ValidationError',
}

export enum ReviewSourceTypes {
  DIRECT = 'direct',
  EMAIL = 'email',
}

interface IWatchdogResponse {
  success: boolean;
}

export interface IWatchdogError extends IWatchdogResponse {
  type?: WatchdogErrorTypes.ERROR;
  errors?: string[];
}

type WatchdogAttributes = 'email' | 'productId';

export type IWatchdogValidationErrorValues = {
  [index in WatchdogAttributes]: string[];
};

export interface IWatchdogValidationError extends IWatchdogResponse {
  type?: WatchdogErrorTypes.VALIDATION_ERROR;
  errors?: IWatchdogValidationErrorValues;
}

export type WatchdogResponseTypes = IWatchdogResponse &
  IWatchdogError &
  IWatchdogValidationError;

export interface IRating {
  id: number;
  ratingType: string;
  score: number;
}

export interface IReviewSummary {
  averageRating: number;
  totalVotes: number;
  votes: Record<string, number>;
  ratings: IRating[];
}

export interface IOrderBy {
  key: ReviewOrderBy;
  desc: boolean;
}

export interface IVariantProps {
  item: CatalogVariantFragmentFragment;
  isSelected: boolean;
  unit: string;
  locale: ILocale;
  currency: ICurrency;
  displayPrice?: boolean;
  variantsCount?: number;
  onClick?: HandleTransitionClick;
}

export interface IVariantsProps {
  selectedId: string;
  variants: CatalogVariantFragmentFragment[];
  selectedVariant: CatalogVariantFragmentFragment;
  locale: ILocale;
  currency: ICurrency;
}

export interface IStyledVariantProps extends IBasicStyledProps {
  isSelected: boolean;
  isOnStock: boolean;
}

export interface IVariantNameProps extends IBasicStyledProps {
  isOnStock: boolean;
}

export interface IColumns {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface IBrand {
  id: string;
  name: string;
  description: string;
}

export interface IVirtualPackageItem {
  productId: number;
  url: string;
  price: IPrice;
  imageUrl: string;
  name: string;
  brand: IBrand;
  annotation: string;
  stockAvailability: IStockInfo;
  productCode: string;
  orderUnit?: string;
  attributes?: AttributeType;
}

export type VariantDisplayWithoutDefault = Exclude<
  VariantDisplayType,
  VariantDisplayType.Default
>;
