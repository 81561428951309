import * as React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { TooltipModel } from '@notino/react-styleguide';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { PortalTooltip } from '@components/PortalTooltip';

import { GiftImage } from '../GiftImage/GiftImage';
import { GiftImageThumb } from '../GiftImage/GiftImageThumb';
import { GiftImageBlockWrapper } from '../GiftImage/styled';

import { Description, TooltipWrapper, GiftBaseItemWrapper } from './styled';

interface GiftBaseItemProps {
  imageUrl: string;
  descriptionText: string;
  imageMessage: MessageDescriptor;
}

export const GiftBaseItem: React.FC<GiftBaseItemProps> = ({
  descriptionText,
  imageUrl,
  imageMessage,
}) => {
  const { formatMessage } = useIntl();

  const { toggleDrawer } = useDrawer();

  const giftImage = React.useMemo(
    () => (
      <GiftImage
        formattedImageMessage={formatMessage(imageMessage)}
        imageUrl={imageUrl}
      />
    ),
    [imageUrl, formatMessage, imageMessage]
  );

  const omOpenDrawer = () => {
    toggleDrawer(giftImage, { header: formatMessage(imageMessage) });
  };

  return (
    <GiftBaseItemWrapper data-testid="gift-base-item">
      <TooltipWrapper>
        <PortalTooltip
          content={<PortalTooltip.Content>{giftImage}</PortalTooltip.Content>}
          position={TooltipModel.Position.topLeft}
          disabled={!imageUrl}
        >
          <GiftImageThumb
            sourceKey="thumb"
            imageUrl={imageUrl}
            formattedImageMessage={formatMessage(imageMessage)}
          />
        </PortalTooltip>
      </TooltipWrapper>

      <GiftImageBlockWrapper>
        <GiftImageThumb
          sourceKey="list_2k"
          imageUrl={imageUrl}
          formattedImageMessage={formatMessage(imageMessage)}
          onClick={omOpenDrawer}
        />
      </GiftImageBlockWrapper>

      <Description dangerouslySetInnerHTML={{ __html: descriptionText }} />
    </GiftBaseItemWrapper>
  );
};
