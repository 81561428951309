import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalContext } from '@notino/react-styleguide';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import { trackDrawerClose } from '@components/BottomSheet/tracking';
import {
  FeatureFlags,
  useFeatureFlags,
} from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { isDesktop } from '@utils/helpers';

import { useProductDetailContext } from '../../ProductDetailContext';

import { Content } from './Content';
import discoBoxImage from './discoBoxImageSmall.webp';
import { messages } from './messages';
import { Chevron, GetSamplesText, StyledImage, Title, Wrapper } from './styled';

const DiscoveryBoxSelfChoice = () => {
  const { toggleModal } = ModalContext.useModalContext();
  const { toggleDrawer } = useDrawer();
  const { product, currentVariant } = useProductDetailContext();
  const { getTimeFromInit } = useTrackingContext();

  const sampleProductId = getRenderableDiscoBoxSampleId(
    product,
    currentVariant,
    useFeatureFlags()
  );

  if (!sampleProductId) {
    return null;
  }

  const openModalOrDrawer = () => {
    dispatchTrackingEvent({
      event: 'element_click',
      _clear: true,
      element: {
        timing: getTimeFromInit(),
        interaction: 'click',
        name: 'discovery_box_pdp_open',
        type: 'discovery_box_pdp',
        action: 'discovery_box_pdp_open',
      },
    });

    if (isDesktop()) {
      toggleModal(<Content sampleProductId={sampleProductId} />);
      return;
    }

    toggleDrawer(<Content sampleProductId={sampleProductId} />, {
      header: (
        <Title>
          <FormattedMessage {...messages.modalTitle} />
        </Title>
      ),
      onDismiss: (closeType) =>
        trackDrawerClose({
          closeType,
          timing: getTimeFromInit(),
          name: 'discovery_box_pdp_close',
          type: 'discovery_box_pdp',
          action: 'discovery_box_pdp_close',
        }),
    });
  };

  return (
    <Wrapper onClick={openModalOrDrawer} data-testid="discoveryBoxSelfChoice">
      <StyledImage src={discoBoxImage} alt="discovery box" />

      <GetSamplesText>
        <FormattedMessage
          {...messages.text}
          values={{
            b: (chunks) => <b>{chunks}</b>,
          }}
        />
      </GetSamplesText>

      <Chevron />
    </Wrapper>
  );
};

export const getRenderableDiscoBoxSampleId = (
  product: { features: { selfChoiceBox: { sampleProductId: number } } },
  variant: { features: { selfChoiceBox: { sampleProductId: number } } },
  featureFlags: Pick<FeatureFlags, 'discovery_box_self_choice_enabled'>
) => {
  if (!featureFlags.discovery_box_self_choice_enabled) {
    return null;
  }
  return (
    product.features?.selfChoiceBox?.sampleProductId ||
    variant.features?.selfChoiceBox?.sampleProductId
  );
};

export default DiscoveryBoxSelfChoice;
