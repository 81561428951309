import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { isEmpty } from '@helpers/utils';

interface IGetDescriptionIndex {
  description: string;
  composition: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['features']['composition'];
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
}
export const getDescriptionIndex = ({
  description,
  composition,
  variant,
}: IGetDescriptionIndex) => {
  if (
    !isEmpty(description) ||
    composition?.length > 0 ||
    variant.characteristics.length > 0
  ) {
    return 'prodDescriptionTabName';
  }
  return '';
};
