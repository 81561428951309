import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Checkbox,
  ModalModel,
  ModalContext,
  useBreakpointValue,
} from '@notino/react-styleguide';
import { AdditionalServicesAvailability } from '@notino/web-tracking';

import { useDrawer } from '@components/BottomSheet/DrawerProvider';
import ErrorBoundary from '@components/ErrorBoundary';
import PriceLabel from '@components/PriceLabel';

import { ICurrency } from '../../../../App/model';
import { CurrencyWrapper, PriceWrapper } from '../../styled';
import { useEngravingTryItFirstContext } from '../context/EngravingTryItFirst';

import { EngravingDrawer } from './EngravingDrawer';
import { messages } from './engravingMessages';
import { EngravingModal } from './EngravingModal';
import { Container, Description, Edit, DisabledTextWrapper } from './styled';

interface IEngravingProps {
  imageAlt: string;
  code: string;
  currency: ICurrency;
  maxChars: number;
  price: number;
  image?: string;
  engravingAvailable?: AdditionalServicesAvailability;
}

export const Engraving: React.FC<IEngravingProps> = React.memo(
  ({
    price,
    currency,
    maxChars,
    image,
    imageAlt,
    code,
    engravingAvailable,
  }) => {
    const { formatMessage } = useIntl();
    const {
      state: { withTryItFirst, withEngraving, engravingInitials },
      actions: { changeEngravingInitials },
    } = useEngravingTryItFirstContext();
    const isDesktop = useBreakpointValue({ md: true });

    const { toggleDrawer } = useDrawer();
    const { toggleModal } = ModalContext.useModalContext();

    const engravingContentProps = {
      maxChars,
      image,
      imageAlt,
      code,
      changeEngravingInitials,
      engravingInitials,
    };

    const showModalOrDrawer = () => {
      if (!isDesktop) {
        toggleDrawer(
          <EngravingDrawer
            {...engravingContentProps}
            onDrawerClose={toggleDrawer}
          />
        );
        return;
      }
      toggleModal(<EngravingModal {...engravingContentProps} />, {
        withFocusTrap: true,
        size: ModalModel.Sizes.large,
        type: ModalModel.Types.default,
        center: true,
        noBorders: true,
      });
    };

    const handleUnCheck = () => {
      changeEngravingInitials('');
    };

    const handleCheckbox = ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) => {
      const checked = target.checked;
      if (!checked) {
        handleUnCheck();
      } else {
        showModalOrDrawer();
      }
    };

    const isEngravingDisabled =
      engravingAvailable !== AdditionalServicesAvailability.available ||
      withTryItFirst;

    return (
      <ErrorBoundary>
        <Container data-testid="pd-engraving" id="pdEngraving">
          <DisabledTextWrapper disabled={isEngravingDisabled}>
            <Checkbox
              disabled={isEngravingDisabled}
              checked={withEngraving}
              onChange={handleCheckbox}
              tabIndex={0}
              position={0}
            >
              <FormattedMessage
                {...messages.engravingCheckboxLabel}
                values={{
                  price: (
                    <PriceLabel
                      price={{ value: price, currency: currency.code }}
                      wrapperComponent={PriceWrapper}
                      currencyComponent={CurrencyWrapper}
                    />
                  ),
                }}
              />
            </Checkbox>
            <Description>
              <FormattedMessage
                {...messages.engravingCheckboxLabelDesc}
                values={{
                  a: (chunks: React.ReactNode) => (
                    <a
                      href={formatMessage(messages.engravingSpecialPageHref)}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
              {withEngraving && (
                <Edit onClick={showModalOrDrawer}>
                  <FormattedMessage {...messages.engravingEdit} />
                </Edit>
              )}
            </Description>
          </DisabledTextWrapper>
        </Container>
      </ErrorBoundary>
    );
  }
);

Engraving.displayName = 'Engraving';
