import { useEffect } from 'react';

export const REVIEW_DIGEST_LOCAL_STORAGE_KEY = 'nushop-review-digest';
export const REVIEW_DIGEST_TOKEN_QUERY_KEY = 'reviewDigest';
export const REVIEW_DIGEST_VALID_TO_QUERY_KEY = 'validTo';

type ReviewDigest = {
  token: string;
  validTo: string;
  masterIds: string[];
};

export const useHandleReviewDigest = ({ masterId }: { masterId: string }) => {
  useEffect(() => {
    deleteInvalidDigestsFromLS();
    const params = new URLSearchParams(window.location.search);

    if (params.has(REVIEW_DIGEST_TOKEN_QUERY_KEY)) {
      addNewOrUpdateDigestToLS(
        params.get(REVIEW_DIGEST_TOKEN_QUERY_KEY),
        params.get(REVIEW_DIGEST_VALID_TO_QUERY_KEY),
        masterId
      );
    }
  }, [masterId]);
};

export const getReviewDigestsFromBrowser = () =>
  getValidDigestsOnly().map((x) => x.token);

export const digestExistsForProduct = (masterId: string) =>
  getValidDigestsOnly().some((d) => d.masterIds.some((id) => id === masterId));

const getDigestsFromLS = (): ReviewDigest[] => {
  try {
    const data = localStorage.getItem(REVIEW_DIGEST_LOCAL_STORAGE_KEY);
    if (!data) {
      return [];
    }
    const parsed = JSON.parse(data);
    if (!Array.isArray(parsed)) {
      return [];
    }
    return parsed;
  } catch (err) {
    return [];
  }
};

const checkAndFixMasterIdsAttribute = (digest: ReviewDigest): ReviewDigest => {
  if (Array.isArray(digest.masterIds)) {
    return digest;
  }
  return { ...digest, masterIds: [] };
};

const getValidDigestsOnly = () =>
  getDigestsFromLS()
    .filter((digest) => digest.token && isDigestValid(digest.validTo))
    .map(checkAndFixMasterIdsAttribute);

const saveToLS = (digests: ReviewDigest[]) => {
  try {
    localStorage.setItem(
      REVIEW_DIGEST_LOCAL_STORAGE_KEY,
      JSON.stringify(digests)
    );
  } catch (_) {
    // pass
  }
};

const deleteInvalidDigestsFromLS = () => saveToLS(getValidDigestsOnly());

const isDigestValid = (validTo: string) => new Date(validTo) > new Date();

const addNewOrUpdateDigestToLS = (
  token: string,
  validTo: string,
  masterId: string
) => {
  const savedDigests = getValidDigestsOnly();
  if (!isDigestValid(validTo)) {
    return;
  }
  const existingDigest = savedDigests.find((x) => x.token === token);

  if (existingDigest?.masterIds.every((id) => id !== masterId)) {
    const withoutUpdatedDigest = savedDigests.filter((d) => d.token !== token);
    const updated = {
      ...existingDigest,
      masterIds: [...existingDigest.masterIds, masterId],
    };

    saveToLS([...withoutUpdatedDigest, updated]);
    return;
  }

  //if digest is already saved and there is nothing to update
  if (existingDigest) {
    return;
  }

  const digest: ReviewDigest = { token, validTo, masterIds: [masterId] };
  saveToLS([...savedDigests, digest]);
};
