import { useLocation } from 'react-router';

import { PathTemplate } from '@notino/shared/definitions/types';

import { createComponents } from '@components/WebView/utils';
import { useResolveWebView } from '@queries/useResolveWebView';

import { prefixes } from './prefixes';

export const usePrefixes = (originUrl: string): string[] => {
  const location = useLocation();

  const { resolveWebViewData, resolveWebViewError, resolveWebViewLoading } =
    useResolveWebView({ pathname: location.pathname, originUrl });

  if (resolveWebViewLoading) {
    return null;
  }
  if (resolveWebViewError) {
    return [];
  }

  if (
    resolveWebViewData.resolveUrl &&
    resolveWebViewData.resolveUrl.type === PathTemplate.SpecialPageComponent
  ) {
    const types = createComponents(originUrl ? originUrl : location.search).map(
      (component) => component.type
    );

    return types
      .map((type) => prefixes[type])
      .filter((prefix) => Boolean(prefix))
      .flat();
  } else if (resolveWebViewData.resolveUrl) {
    return prefixes[resolveWebViewData.resolveUrl.type];
  }
};
