import { snakeCase } from 'lodash';

import { VariantDisplayTypes } from '@containers/ProductListing/model';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const trackTryItFirstAddToCart = (productCode: string) => {
  dispatchTrackingEvent({
    event: 'general_event',
    general_parameters: {
      id: undefined,
      type: 'try_it_first',
      action: 'TryItFirstProductAdded',
      description_data: productCode,
      value_units: 'product_code',
      interaction: 'add',
    },
    _clear: true,
  });
};

export const trackVariantClick = (
  timing: number,
  type: VariantDisplayTypes,
  promoLabel?: string
) => {
  dispatchTrackingEvent({
    event: 'element_click',
    element: {
      timing,
      action: 'click_on_variant_tile',
      type,
      name: '',
      interaction: 'click',
      promo_labels: snakeCase(promoLabel),
    },
    _clear: true,
  });
};
