import * as React from 'react';

import { ProductLabelModel } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';

import ErrorBoundary from '@components/ErrorBoundary';
import PriceLabel from '@components/PriceLabel';
import { Tags } from '@components/Tags';
import { VariantDisplayTypes } from '@containers/ProductListing/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dataLabelsProp } from '@helpers/utils';

import { IVariantProps } from '../../../../model';
import { trackVariantClick } from '../../tracking';
import { VariantLink, PriceLabelCurrency } from '../../variantsStyled';

import {
  PriceLabelWrapper,
  Variant,
  ImgWrapper,
  Img,
  VariantListItem,
  PricePlaceholder,
} from './styled';

export const VariantThumbnail: React.FC<IVariantProps> = ({
  item,
  isSelected,
  displayPrice,
  variantsCount,
  onClick,
}) => {
  const isOnStock = item.stockAvailability.code !== IStock.outOfStock;
  const { getTimeFromInit } = useTrackingContext();
  const displayInRow = variantsCount === 2;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(item, e);
    trackVariantClick(getTimeFromInit(), VariantDisplayTypes.Thumbnail);
  };

  return (
    <VariantListItem>
      <ErrorBoundary>
        <VariantLink
          onClick={handleClick}
          replace={true}
          to={item.url}
          id={`pd-variant-${item.webId}`}
          data-testid={`pd-variant-${item.webId}`}
          className={isSelected ? 'pd-variant-selected' : ''}
          {...dataLabelsProp(item.attributes)}
        >
          <Variant
            displayInRow={displayInRow}
            isOnStock={isOnStock}
            isSelected={isSelected}
          >
            {item.attributes && (
              <Tags
                small={true}
                type={ProductLabelModel.Types.corner}
                attributes={item.attributes}
                stockAvailability={item.stockAvailability.code}
              />
            )}

            <ImgWrapper isOnStock={isOnStock} isSelected={isSelected}>
              <Img {...item.media.mainImage} sourceKey="thumb" />
            </ImgWrapper>

            {displayPrice && isOnStock ? (
              <PriceLabel
                price={item.price}
                wrapperComponent={(additionalProps) => (
                  <PriceLabelWrapper
                    data-cypress="variant-thumbnail-price"
                    isSelected={isSelected}
                    isOnStock={isOnStock}
                    {...additionalProps}
                  />
                )}
                currencyComponent={PriceLabelCurrency}
              />
            ) : displayInRow ? null : (
              <PricePlaceholder />
            )}
          </Variant>
        </VariantLink>
      </ErrorBoundary>
    </VariantListItem>
  );
};
