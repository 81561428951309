import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { PictogramRegularCheckmarkCircle } from '@notino/react-styleguide';

import { messages } from './messages';
import {
  ReviewSentText,
  ReviewSentThanksText,
  ReviewsSentSuccesModalWrapper,
} from './styled';

export const ReviewsSentSuccessModalBody: React.FC = () => {
  return (
    <ReviewsSentSuccesModalWrapper data-cypress="reviewsModal-modalHeader">
      <PictogramRegularCheckmarkCircle
        color="icon.secondary"
        height="64px"
        width="64px"
      />
      <ReviewSentText aria-live="polite">
        <FormattedMessage {...messages.reviewSent} />
      </ReviewSentText>
      <ReviewSentThanksText>
        <FormattedMessage {...messages.reviewSentThanks} />
      </ReviewSentThanksText>
    </ReviewsSentSuccesModalWrapper>
  );
};
