import * as React from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

interface IFormattedDateProps {
  date: string;
}

interface IFormattedTimeProps {
  from: string;
  to: string;
}

const Span = styled.span`
  color: ${theme.color.text.secondary};
  ${theme.typography.labelRegular400}
`;

export const FormattedDate: React.FC<IFormattedDateProps> = ({ date }) => {
  const { formatDate } = useIntl();

  const dateObj = new Date(date);
  const dayName = formatDate(dateObj, { weekday: 'long' });
  const dayNumber = formatDate(dateObj, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

  return <Span>{`${dayName} ${dayNumber}`}</Span>;
};

export const FormattedTime: React.FC<IFormattedTimeProps> = ({ from, to }) => {
  const { formatTime } = useIntl();

  const fromObj = new Date(from);
  const fromNumber = formatTime(fromObj, {
    hour: '2-digit',
    minute: '2-digit',
  });

  const toObj = new Date(to);
  const toNumber = formatTime(toObj, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return <Span>{`${fromNumber} - ${toNumber}`}</Span>;
};
