import { getImageAttributes } from '@notino/react-web-utils';
import { Video, VideoType } from '@notino/shared/definitions/types';

const srcMap: Record<VideoType, (videoId: string, type: string) => string> = {
  Youtube: (id, type) => `https://i.ytimg.com/vi/${id}/${type}.jpg`,
  Vimeo: () => 'https://placehold.co/320x180/000000/000000/png',
};

export const getVideoAttrs = (video: Video, type: string = 'default') =>
  getImageAttributes(
    {
      src: srcMap[video.type](video.id, type),
      alt: `${video.id}`,
      isVideo: true,
    },
    '',
    'video'
  );
