import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const NotificationModalOrDrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem;

  @media (min-width: ${breakpoints.md}) {
    display: block;
    padding: 0;
  }
`;
