import React, { useCallback } from 'react';

import {
  Dot,
  ISliderConf,
  NativeSlider,
  ReactFCC,
} from '@notino/react-styleguide';

import { LivestreamBanner } from '@components/LivestreamBanner';
import {
  IItem,
  useInitBambuserLiveShopping,
} from '@components/LivestreamBanner/hooks/useInitBambuserLiveShopping';
import {
  EShowComponentLayout,
  ILivestream,
} from '@components/LivestreamBanner/model';
import {
  trackOnClick,
  ELivestreamTrackingInteraction,
} from '@containers/ProductDetailContainer/ProductDetail/livestreamTracking';

import { getShowElementId } from '../../../../../../components/LivestreamBanner/utils/getShowElementId';

import { Arrow } from './Arrow';
import { MainWrapper, BannerCarouselWrapper, SliderWrapper } from './styled';
import { useLiveStreamTracking } from './useLivestreamTracking';

interface ILivestreamBannersCarouselProps {
  livestreams: ILivestream[];
}

const LivestreamBannersCarousel: ReactFCC<ILivestreamBannersCarouselProps> = ({
  livestreams,
}) => {
  const itemsPerSlide = 1;
  const { trackOnSlide, livestreamCarouselRef } =
    useLiveStreamTracking(livestreams);

  const sliderConfig: ISliderConf = {
    slidesToShow: itemsPerSlide,
    slidesToScroll: itemsPerSlide,
    dots: <Dot data-testid="dot" />,
    scrollAnimation: 'ease-in-out',
    prevArrow: livestreams.length > itemsPerSlide && (
      <Arrow direction="left" data-testid="arrow" />
    ),
    nextArrow: livestreams.length > itemsPerSlide && (
      <Arrow direction="right" data-testid="arrow" />
    ),
    afterChange: (settings) => trackOnSlide(settings.slideIndex),
  };

  useInitBambuserLiveShopping({
    getItems: useCallback(
      () =>
        livestreams
          ? livestreams.flatMap<IItem>((livestream) => [
              {
                showId: livestream.showId,
                deeplink: livestream.deeplink,
                node: document.getElementById(
                  getShowElementId(
                    livestream.showId,
                    EShowComponentLayout.DESKTOP
                  )
                ),
              },
              {
                showId: livestream.showId,
                deeplink: livestream.deeplink,
                node: document.getElementById(
                  getShowElementId(
                    livestream.showId,
                    EShowComponentLayout.SINGLE
                  )
                ),
              },
              {
                showId: livestream.showId,
                deeplink: livestream.deeplink,
                node: document.getElementById(
                  getShowElementId(
                    livestream.showId,
                    EShowComponentLayout.DESKTOP,
                    true
                  )
                ),
              },
              {
                showId: livestream.showId,
                deeplink: livestream.deeplink,
                node: document.getElementById(
                  getShowElementId(
                    livestream.showId,
                    EShowComponentLayout.SINGLE,
                    true
                  )
                ),
              },
            ])
          : [],
      [livestreams]
    ),
  });

  if (!livestreams || livestreams.length <= 0) {
    return null;
  }

  return livestreams.length === itemsPerSlide ? (
    <MainWrapper ref={livestreamCarouselRef}>
      {livestreams.map(({ showId, ...livestream }, itemIndex) => (
        <LivestreamBanner
          key={showId}
          showId={showId}
          onCardClick={() =>
            trackOnClick({
              showId,
              itemIndex,
              interaction: ELivestreamTrackingInteraction.IMAGE,
            })
          }
          onButtonClick={() =>
            trackOnClick({
              showId,
              itemIndex,
              interaction: ELivestreamTrackingInteraction.BUTTON,
            })
          }
          livestreamCardId={getShowElementId(
            showId,
            EShowComponentLayout.SINGLE
          )}
          buttonId={getShowElementId(showId, EShowComponentLayout.SINGLE, true)}
          {...livestream}
          fullwidth={true}
        />
      ))}
    </MainWrapper>
  ) : (
    <MainWrapper ref={livestreamCarouselRef}>
      <BannerCarouselWrapper>
        <SliderWrapper>
          <NativeSlider {...sliderConfig}>
            {livestreams.map(({ showId, ...livestream }, itemIndex) => (
              <LivestreamBanner
                key={showId}
                showId={showId}
                onCardClick={() =>
                  trackOnClick({
                    showId,
                    itemIndex,
                    interaction: ELivestreamTrackingInteraction.IMAGE,
                  })
                }
                onButtonClick={() =>
                  trackOnClick({
                    showId,
                    itemIndex,
                    interaction: ELivestreamTrackingInteraction.BUTTON,
                  })
                }
                livestreamCardId={getShowElementId(
                  showId,
                  EShowComponentLayout.DESKTOP
                )}
                buttonId={getShowElementId(
                  showId,
                  EShowComponentLayout.DESKTOP,
                  true
                )}
                {...livestream}
              />
            ))}
          </NativeSlider>
        </SliderWrapper>
      </BannerCarouselWrapper>
    </MainWrapper>
  );
};

export default LivestreamBannersCarousel;
