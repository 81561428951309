import * as React from 'react';

import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import { assignRefs } from '@utils/assignRefs';

const Placeholder = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

interface IProps {
  tabsAbsoluteContainerEl: HTMLDivElement;
}

const createObserver = (placeholderEl: HTMLDivElement) =>
  new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.borderBoxSize) {
        // Firefox implements `borderBoxSize` as a single content rect, rather than an array
        const borderBoxSize: ResizeObserverSize = Array.isArray(
          entry.borderBoxSize
        )
          ? entry.borderBoxSize[0]
          : entry.borderBoxSize;

        placeholderEl.style.height = `${borderBoxSize.blockSize}px`;
      }
    }
  });

const MobileTabContentPlaceholder = React.forwardRef<HTMLDivElement, IProps>(
  ({ tabsAbsoluteContainerEl }, ref) => {
    const placeholderRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
      if (!placeholderRef.current || !tabsAbsoluteContainerEl) {
        return;
      }

      const observer = createObserver(placeholderRef.current);
      observer.observe(tabsAbsoluteContainerEl);
      return () => observer.disconnect();
    }, [tabsAbsoluteContainerEl]);

    return <Placeholder ref={assignRefs(placeholderRef, ref)} />;
  }
);

export const useMoveTabsToMobilePlaceholder = () => {
  const absoluteTabsWrapperRef = React.useRef<HTMLDivElement>();
  const tabsPlaceholderRef = React.useRef<HTMLDivElement>();
  const topSectionRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const absoluteEl = absoluteTabsWrapperRef.current;
    const placeholderEl = tabsPlaceholderRef.current;
    const topSectionEl = topSectionRef.current;

    if (!absoluteEl || !placeholderEl || !topSectionEl) {
      return;
    }

    const observer = new ResizeObserver(() => {
      absoluteEl.style.top = `${
        placeholderEl.offsetTop - topSectionEl.offsetTop
      }px`;
    });
    observer.observe(topSectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { absoluteTabsWrapperRef, tabsPlaceholderRef, topSectionRef };
};

export default MobileTabContentPlaceholder;
