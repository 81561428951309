import styled, { css } from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

import { ImageProps } from './utils';

export const BufferImage = styled.img`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

type MainImgProps = ImageProps;

export const MainImg = styled.img<MainImgProps>`
  display: none;

  ${({ hasShadow, fullHeight }) => css`
    ${css`
      height: auto;

      ${hasShadow &&
      css`
        margin-bottom: -2rem;
      `}

      ${fullHeight
        ? css`
            height: 100%;

            max-height: unset !important;
            max-width: unset !important;

            aspect-ratio: unset;

            @media (min-width: ${breakpoints.xl}) {
              height: calc(100% - 3rem); // 1.5rem padding on top and bottom
              width: unset;
            }
          `
        : css`
            max-height: min(85%, 21.875rem) !important;

            @media (min-width: 1280px) {
              max-height: min(85%, 25rem) !important;
            }
            @media (min-width: 1680px) {
              max-height: min(85%, 28.125rem) !important;
            }
          `}
    `}
  `}

  @media (min-width: ${breakpoints.sm}) {
    display: block;
    max-width: 100%;
    max-height: 100%;

    cursor: zoom-in;
    flex-grow: 0;
    transition: opacity 0.5s;
  }
`;
