import * as React from 'react';

import { Col, IconRegularSpinner } from '@notino/react-styleguide';

import { REVIEW_ITEM_HEIGHT } from '../../constants';

import { ReviewsFetching } from './styled';

interface IFetchingBlockProps {
  reviewsCount?: number;
}

export const FetchingBlock: React.FC<IFetchingBlockProps> = ({
  reviewsCount,
}) => (
  <Col xs={12}>
    <ReviewsFetching minHeight={`${reviewsCount * REVIEW_ITEM_HEIGHT}rem`}>
      <IconRegularSpinner color="icon.secondary" />
    </ReviewsFetching>
  </Col>
);
