import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { Arrow } from '@components/Arrow';

const contentSizeCss = css`
  max-height: 18.75rem !important;
  max-width: calc(100vw - 2rem) !important;
  margin: auto;
`;

export const StyledArrow = styled(Arrow)<{
  visible: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  transition: opacity 350ms;
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}

  ${({ direction }) =>
    direction === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}


  background-color: ${theme.color.common.transparent};
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OuterWrapper = styled.div`
  position: relative;
  left: -0.9375rem;
  width: 100vw;
  background-color: ${theme.color.background.disabled};
  margin-bottom: 1.25rem;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const noScrollbarCss = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */
`;

export const SliderWrapper = styled.div`
  left: -0.9375rem;
  width: 100vw;
  aspect-ratio: 1 / 1;
  @media (min-width: ${breakpoints.md}) {
    height: auto;
  }

  overflow-y: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  display: flex;
  ${noScrollbarCss}
`;

export const SlideWrapper = styled.div<{
  isSquare?: boolean;
  showDivider: boolean;
}>`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  height: 100%;
  position: relative;
  overflow: hidden;

  margin: auto;

  ${({ isSquare, showDivider }) =>
    css`
      background-color: ${theme.color.background.disabled};
      max-width: 100vw;

      ${isSquare
        ? css`
            > div:first-child {
              height: 100%;
            }
            img {
              width: 100%;
              max-width: 400px;

              @media (min-width: ${breakpoints.md}) {
                max-width: 800px;
              }
            }
          `
        : css`
            padding: 0 2.25rem;
            height: 100%;
            img {
              width: auto;
              max-width: 100% !important;
              height: auto;
              max-height: min(21.875rem, 80%) !important;
            }
            > div:first-child {
              // use first-child so we don't target modiface overlay
              max-height: 100%;
            }
          `}

      @media (min-width: ${breakpoints.md}) {
        height: 100%;
      }
      img {
        mix-blend-mode: multiply;
        min-height: auto;
        max-height: ${isSquare ? 'none' : '100%'};
      }

      ${showDivider &&
      css`
        position: relative;
        &::before {
          content: ' ';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          width: 3px;
          background-color: ${theme.color.background.primary};
        }
      `}
    `}
`;

export const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const PlayerWrapper = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100vw;
  display: flex;
  align-items: center;
  height: 100%;
  iframe {
    ${contentSizeCss}
  }
`;
