import * as React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import messages from './messages';
import { TooltipContent } from './styled';

export const useGetOriginalPriceTooltip = (): ((
  type: string
) => JSX.Element) => {
  const { formatMessage } = useIntl();
  const getAnchorTag = useGetPriceLink();

  return (type: string) => {
    if (type === 'Recommended') {
      return (
        <TooltipContent key="recommended">
          {formatMessage(messages.recommendedPrice, {
            link: getAnchorTag(messages.recommendedPriceLink),
          })}
        </TooltipContent>
      );
    }
    if (type === 'Converted') {
      return (
        <TooltipContent key="converted">
          {formatMessage(messages.convertedPrice, {
            link: getAnchorTag(messages.convertedPriceLink),
          })}
        </TooltipContent>
      );
    }
    if (type === 'HistoricalMax') {
      return (
        <TooltipContent key="historical_max">
          {formatMessage(messages.historicalPrice, {
            link: getAnchorTag(messages.historicalPriceLink),
          })}
        </TooltipContent>
      );
    }

    return null;
  };
};

export const useRecentPriceTooltip = () => {
  const { formatMessage } = useIntl();
  const getAnchorTag = useGetPriceLink();

  return (
    <TooltipContent key="recent_price">
      {formatMessage(messages.recentPrice, {
        link: getAnchorTag(messages.recentPriceLink),
      })}
    </TooltipContent>
  );
};

const useGetPriceLink = () => {
  const { formatMessage } = useIntl();

  return (href: MessageDescriptor) => {
    const formattedHref = formatMessage(href);
    if (formattedHref === 'null') {
      return null;
    }

    return (
      <a
        key="price_link_anchor"
        href={formatMessage(href)}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        {formatMessage(messages.priceLinkText)}
      </a>
    );
  };
};

export const getDiscountPercentage = (price: number, prevPrice: number) =>
  Math.floor(100 - (price / prevPrice) * 100);

export const useGetYouSaveText = () => {
  const { formatMessage } = useIntl();

  return (price: number, prevPrice: number) =>
    `${formatMessage(messages.youSave)} ${getDiscountPercentage(
      price,
      prevPrice
    )}%`;
};

export const useGetBetterOfferText = () => {
  const { formatMessage } = useIntl();

  return (price: number, prevPrice: number) =>
    `${formatMessage(messages.betterOffer, {
      percentage: getDiscountPercentage(price, prevPrice),
    })}`;
};
