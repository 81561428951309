import * as React from 'react';
import { IntlContext, useIntl } from 'react-intl';

import { ReactFCC } from '@notino/react-styleguide';

const SPECIAL_COUNTRY_FORMAT = ['lv-LV', 'lt-LT'];

/**
 * Override original IntlProvider formatDate
 * @param props
 * @constructor
 */
export const IntlProviderOverride: ReactFCC = ({ children }) => {
  const originalIntl = useIntl();

  const values: typeof originalIntl = React.useMemo(
    () => ({
      ...originalIntl,
      formatDate: (value, opts) => {
        const { formatDate: originalFormatDate, locale } = originalIntl;
        if (SPECIAL_COUNTRY_FORMAT.includes(locale)) {
          const date = new Date(value);
          if (
            opts.day === 'numeric' &&
            opts.month === 'numeric' &&
            opts.year === 'numeric'
          ) {
            return `${date.getDate()}.${
              date.getMonth() + 1
            }.${date.getFullYear()}`;
          }
        }
        return originalFormatDate(value, opts);
      },
    }),
    [originalIntl]
  );

  return <IntlContext.Provider value={values}>{children}</IntlContext.Provider>;
};
