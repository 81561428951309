import { css } from 'styled-components';

import { breakpoints, styled, theme } from '@notino/react-styleguide';

export const ModiFaceWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;

  background: rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  font-size: 0.75rem;
  color: ${theme.color.text.inverse};
  font-weight: normal;
  padding: 0.25rem 10%;
`;

export const NewVtoButton = styled.div<{ visible: boolean }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;

  @media (min-width: ${breakpoints.md}) {
    bottom: 2rem;
  }

  background: rgba(0, 0, 0, 0.7);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  cursor: pointer;
  user-select: none;
  padding: 10px 20px;
  border-radius: 10rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${theme.color.text.inverse};
  ${theme.typography.labelRegular}

  transition: all 300ms;

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `}
`;
