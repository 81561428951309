import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';
export const WatchdogInputWrapper = styled.div`
  position: relative;
  text-align: left;

  input {
    margin-top: 0.5rem;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;

  svg {
    transition: color 0.4s ease;
    color: ${theme.color.icon.tertiary};
  }

  &:hover {
    svg {
      transition: color 0.4s ease;
      color: ${theme.color.icon.primary};
    }
  }
`;

export const TooltipContent = styled.div`
  min-width: 18rem;
  font-size: 0.8rem;
  text-align: left;
  padding: 4px;

  @media (min-width: ${breakpoints.md}) {
    min-width: 23rem;
  }

  a {
    color: ${theme.color.text.primary};

    &:hover {
      text-decoration: none;
    }
  }
`;
