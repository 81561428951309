import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

/*
 * P = wrapped Component's own Props (pass through)
 * C = Context Props
 *
 *
 * note, withProviders does not expose the true Providers,
 * instead, they come wrapped within their own stateful components
 * meaning they don't accept "value" via external props
 */
export const withProviders =
  <P,>(...providers: React.ComponentType[]) =>
  (Component: React.ComponentType<P>) =>
  (props: P) => {
    function mountProviders(providersArray: React.ComponentType[]) {
      if (!providersArray.length) {
        return <Component {...props} />;
      }

      const WrapperProvider = providersArray.shift() as ReactFCC;
      return (
        <WrapperProvider>{mountProviders(providersArray)}</WrapperProvider>
      );
    }

    return mountProviders([...providers]);
  };

export const withConsumers =
  <P, C = Record<string, unknown>>(...consumers: Array<React.Consumer<C>>) =>
  (Component: React.ComponentType<P & C>) =>
  (props: P) => {
    function mountConsumers(
      consumersArray: Array<React.Consumer<C>>,
      consumerProps = {}
    ) {
      if (!consumersArray.length) {
        return <Component {...props} {...(consumerProps as C)} />;
      }

      const WrapperConsumer = consumersArray.shift();
      return (
        <WrapperConsumer>
          {(consumerValues: C) =>
            mountConsumers(consumersArray, {
              ...consumerProps,
              ...consumerValues,
            })
          }
        </WrapperConsumer>
      );
    }

    return mountConsumers([...consumers]);
  };
