import * as React from 'react';

export const useRegisterNotinoApi = (productCode: string) => {
  React.useEffect(() => {
    if (!window.NotinoAPI) {
      window.NotinoAPI = {} as INotinoAPI;
    }
    window.NotinoAPI.getProductCode = () => productCode;
  }, [productCode]);
};
