import { useIntl } from 'react-intl';

import { useApolloClient } from '@apollo/client';

import { GetRequestHeadersQuery } from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';

import getRequestHeadersQuery from '../../../../queries/requestHeaders.graphql';

type Return =
  | {
      isDualPriceForCroatia: true;
      getRightSidePrice: (value: number) => number;
      getRightSideFormattedPrice: (value: number) => string;
      getLeftSidePrice: (value: number) => number;
      getLeftSideFormattedPrice: (value: number) => string;
      rightSideCurrency: string;
      rightSideCurrencyCode: string;
      leftSideCurrency: string;
    }
  | {
      isDualPriceForCroatia: false;
      getRightSidePrice: null;
      getRightSideFormattedPrice: null;
      getLeftSidePrice: null;
      getLeftSideFormattedPrice: null;
      rightSideCurrency: null;
      rightSideCurrencyCode: null;
      leftSideCurrency: null;
    };

const HRK_EUR_EXCHANGE_SEPTEMBER_2022 = 7.5345;
const EUR_HRK_EXCHANGE_SEPTEMBER_2022 = 1 / HRK_EUR_EXCHANGE_SEPTEMBER_2022;

export const useIsDualPriceForCroatia = (): Return => {
  const { dual_currency_price } = useFeatureFlags();
  const { cache } = useApolloClient();
  const { formatNumber } = useIntl();

  if (!dual_currency_price) {
    return {
      isDualPriceForCroatia: false,
      getRightSidePrice: null,
      getRightSideFormattedPrice: null,
      getLeftSidePrice: null,
      getLeftSideFormattedPrice: null,
      rightSideCurrency: null,
      leftSideCurrency: null,
      rightSideCurrencyCode: null,
    };
  }

  const {
    RequestHeaders: {
      hrkDefaultCurrency: { code, exchangeRates },
    },
  } = cache.readQuery<GetRequestHeadersQuery>({
    query: getRequestHeadersQuery,
  });

  const isEurBaseCurrency = code === 'EUR';
  const toEurRate = exchangeRates?.EUR || HRK_EUR_EXCHANGE_SEPTEMBER_2022;
  const toHrkRate = exchangeRates?.HRK || EUR_HRK_EXCHANGE_SEPTEMBER_2022;
  const exchangeRate = isEurBaseCurrency ? toHrkRate : toEurRate;
  const convert = (value: number) => value / exchangeRate;
  const format = (value: number) =>
    formatNumber(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const getRightSidePrice = (value: number) =>
    isEurBaseCurrency ? convert(value) : value;
  const getLeftSidePrice = (value: number) =>
    isEurBaseCurrency ? value : convert(value);

  return {
    isDualPriceForCroatia: true,
    getRightSidePrice,
    getLeftSidePrice,
    getRightSideFormattedPrice: (value) => format(getRightSidePrice(value)),
    getLeftSideFormattedPrice: (value) => format(getLeftSidePrice(value)),
    rightSideCurrency: 'kn',
    leftSideCurrency: '€',
    rightSideCurrencyCode: 'HRK',
  };
};
