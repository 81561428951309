import { noop } from '@utils/noop';

/**
 * If event is defined, then tracking function is invoked
 * Otherwise logs of unsent exponea events are sent to Sentry
 * @param windowObj name of window object due to migration from old exponea event to bloomreach events
 * @param eventName name of exponea event
 * @param func tracking function
 */
export const withTryToInvokeEvent =
  <Key extends keyof Window>(windowObj: Key) =>
  <T extends unknown[]>(
    eventName: keyof Window[Key],
    func: (...args: T) => void
  ) =>
  (...args: T): void => {
    if (
      !window?.[windowObj] ||
      typeof window?.[windowObj]?.[eventName] !== 'function'
    ) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        console.warn(`window.${windowObj}.${eventName as string} is not defined.`, args);
      }

      return noop();
    }
    return func(...args);
  };

export const withTryToInvokeTrackingEvent =
  withTryToInvokeEvent('trackingEvents');
