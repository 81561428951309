import { SkeletonElement, styled, breakpoints } from '@notino/react-styleguide';

type PDSkeletonProps = {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  display?: string;
};
export const PDSkeletonElement = styled(SkeletonElement)<PDSkeletonProps>`
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}rem` : 0)};
  margin-right: ${(props) =>
    props.marginRight ? `${props.marginRight}rem` : 0};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}rem` : 0};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}rem` : 0)};

  display: ${(props) => props.display || 'block'};
`;

export const EmptySpace = styled.div`
  @media (min-width: ${breakpoints.md}) {
    height: 40vh;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
