import { ReviewOrderBy } from '@notino/shared/definitions/types';

import { Action, ActionTypes, IReviewItemsState } from './model';

export const initialState: IReviewItemsState = {
  orderBy: ReviewOrderBy.DateTime,
  orderDesc: true,
  page: 1,
  nextPage: 2,
  hideTranslated: false,
  allowHideTranslatedCheckbox: false,
};

export const reducer = (
  state: IReviewItemsState,
  action: Action
): IReviewItemsState => {
  switch (action.type) {
    case ActionTypes.CHANGE_ORDER:
      if (
        state.orderBy === action.value.key &&
        state.orderDesc === action.value.desc
      ) {
        return state;
      }
      return {
        ...state,
        orderBy: action.value.key,
        orderDesc: action.value.desc,
        page: 1,
        nextPage: 2,
      };
    case ActionTypes.INC_NEXT_PAGE: {
      return {
        ...state,
        nextPage: state.nextPage + 1,
      };
    }
    case ActionTypes.TOGGLE_HIDE_TRANSLATED_REVIEWS: {
      return {
        ...state,
        hideTranslated: !state.hideTranslated,
        allowHideTranslatedCheckbox:
          state.allowHideTranslatedCheckbox || !state.hideTranslated,
      };
    }
    default:
      return state;
  }
};
