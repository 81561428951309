import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { IReviewItemsContext } from './model';
import { reducer, initialState } from './reducer';
import { useActions } from './useActions';

const initialContext = {
  state: initialState,
  actions: {},
};

const ReviewItemsContext = React.createContext<IReviewItemsContext>(
  initialContext as IReviewItemsContext
);

export const ReviewItemsProvider: ReactFCC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = useActions(dispatch);
  const value = React.useMemo(() => ({ state, actions }), [state, actions]);

  return (
    <ReviewItemsContext.Provider value={value}>
      {children}
    </ReviewItemsContext.Provider>
  );
};

export const useReviewItemsContext = () => React.useContext(ReviewItemsContext);
