import styled from 'styled-components';

import { breakpoints, Ratings, Button } from '@notino/react-styleguide';

interface IHeaderProps {
  baseline?: boolean;
}

export const Header = styled.div<IHeaderProps>`
  height: 3.125rem;
  line-height: 3.125rem;
  display: flex;
  align-items: ${(props) => (props.baseline ? 'baseline' : 'center')};
  font-size: 0.875rem;

  strong {
    font-size: 1.125rem;
    padding-right: 0.3125rem;
  }
`;

export const NoReviewText = styled.div`
  padding-bottom: 1.25rem;
`;

export const OverallValue = styled.span`
  display: inline-block;
  box-sizing: content-box;
  padding-right: 0.625rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 1.25rem;
  font-weight: 500;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

export const StyledRatings = styled(Ratings)`
  display: inline-block !important;

  line-height: initial !important;

  svg {
    vertical-align: initial !important;
  }
`;
