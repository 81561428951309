import styled from 'styled-components';

import { breakpoints, Button, theme } from '@notino/react-styleguide';

export const PickUpButtonWrapper = styled.div`
  margin-top: 0.5rem;
  flex: 0 1 100%;
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 0;
    flex: auto;
  }
`;

export const PickUpButton = styled(Button)`
  width: 100%;
  height: 3.25rem;
  ${theme.typography.labelRegular}
`;
