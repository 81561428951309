import { useMutation } from '@apollo/client';

import {
  AddProductsToCartMutation,
  AddProductsToCartMutationVariables,
} from '@notino/shared/definitions/types';

import addToCartMutationGql from '../queries/addToCart.graphql';

export const useAddToCartMutation = () => {
  const [addToCartMutation] = useMutation<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >(addToCartMutationGql, {
    refetchQueries: ['getServicesIncluded'],
  });

  return {
    addToCartMutation,
  };
};
