import {
  variantUrlFromUrl,
  pickBestVariant,
} from '@notino/shared/client-utils';
import {
  VariantDisplayType,
  GetCatalogProductViewQuery,
  Video,
} from '@notino/shared/definitions/types';
import { VariantSelector } from '@notino/web-tracking/dist/types/package/ga4/events/constants';

import { VariantDisplayWithoutDefault } from './model';

export const isNotDamaged = (variant: {
  attributes: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['attributes'];
}) => (variant.attributes ? !variant.attributes.Damage : true);

export const selectVariant = (
  variants: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'],
  currentUrl: string
):
  | GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]
  | undefined => {
  const productUrlPath = variantUrlFromUrl(currentUrl);

  if (productUrlPath) {
    return variants.find((variant) => variant.url.includes(productUrlPath));
  }

  return pickBestVariant(variants);
};

export const getDisplayType = (
  variantDisplayType: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variantDisplayType']
): VariantDisplayWithoutDefault => {
  if (variantDisplayType && variantDisplayType !== VariantDisplayType.Default) {
    return variantDisplayType;
  }
  return VariantDisplayType.Tiles;
};

export const useDisplayType = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId']
): VariantDisplayWithoutDefault => {
  return getDisplayType(product.variantDisplayType);
};

export const useTrackingDisplayType = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId']
): VariantSelector => {
  const displayType = useDisplayType(product);

  if (
    product.variants.length <= 1 &&
    displayType !== VariantDisplayType.ColorPicker
  ) {
    return 'None';
  }

  return displayType;
};

const youtubeIframe = (videoId: string, autoplay = false) => `
  <iframe
    width="560"
    height="315"
    src="https://www.youtube-nocookie.com/embed/${videoId}?rel=0&amp;showinfo=0&amp;iv_load_policy=3&amp;autoplay=${
  autoplay ? 1 : 0
}"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen
  ></iframe>`;

const vimeoIframe = (videoId: string, autoplay = false) => `
  <iframe
    width="560"
    height="315"
    src="https://player.vimeo.com/video/${videoId}?dnt=1&autoplay=${
  autoplay ? 1 : 0
}"
    frameborder="0"
    allow="autoplay; encrypted-media"
    allowfullscreen
  ></iframe>`;

export const getVideoIframe = (video: Video, autoplay = false): string => {
  if (!video) {
    return '';
  }

  switch (video.type) {
    case 'Youtube':
      return youtubeIframe(video.id, autoplay);
    case 'Vimeo':
      return vimeoIframe(video.id, autoplay);
    default:
      return '';
  }
};
