import styled from 'styled-components';

import { IBasicStyledProps, theme } from '@notino/react-styleguide';
interface IDisabledText extends IBasicStyledProps {
  disabled: boolean;
}

export const Container = styled.div`
  padding: 1rem 0;
  display: block;

  label {
    ${theme.typography.labelRegular}
  }

  svg {
    vertical-align: middle;
    padding-left: 0.4rem;
  }
`;

export const TooltipWrapper = styled.div`
  text-align: left !important;
  font-size: 0.9rem;
  padding: 0.2rem;
`;

export const Description = styled.div`
  padding-left: 2.25rem;
  a {
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
  }

  margin-top: 0.25rem;
  color: ${theme.color.text.secondary};
  ${theme.typography.bodyRegular}
`;

export const DisabledTextWrapper = styled.div<IDisabledText>`
  color: ${(props) =>
    props.disabled
      ? props.theme.color.text.disabled
      : props.theme.color.text.primary};
`;
