import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const ButtonWrapper = styled.div``;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
  text-align: center;
  color: ${theme.color.text.secondary};
  ${theme.typography.bodyLarge}
  font-size: 14px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
`;

export const WatchdogWrapper = styled.div`
  text-align: center;
  padding: 1.25rem 1.25rem 0;
  @media (min-width: ${breakpoints.md}) {
    padding: 1.5rem;
  }
`;

export const ErrorText = styled.span`
  margin-left: 1.2rem;
  line-height: 2.8125rem;
  color: ${theme.color.text.negative};
  cursor: help;
`;

export const Buttons = styled.div`
  margin-top: 2.25rem;
  max-height: 2.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 1.5rem;
  }

  button {
    width: 100%;
    @media (min-width: ${breakpoints.md}) {
      width: auto;
      min-width: 250px;
    }
  }
`;

export const LoginButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  a,
  button {
    display: block;
    width: 100%;
    text-decoration: none;
    @media (min-width: ${breakpoints.md}) {
      width: auto;
      min-width: 250px;
    }
  }

  button:nth-child(2) {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    padding: 0.75rem;

    ${theme.typography.labelRegular}

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const ToastLink = styled.a`
  text-decoration: underline;
  color: currentColor;
`;
