import * as React from 'react';

import { TooltipModel } from '@notino/react-styleguide';
import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import { PortalTooltip } from '@components/PortalTooltip';
import PriceLabel from '@components/PriceLabel';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { usePriceLogic } from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic';

import { useHasStressOnVoucherDiscount } from '../../VoucherDiscount/useHasStressOnVoucherDiscount';

import {
  DiscountWrapper,
  OriginalPriceWrapper,
  StyledInfoIcon,
  LineThrough,
  Discount,
  PriceWrapper,
  CurrencyWrapper,
  Slash,
  FlexEnd,
} from './styled';

interface IProps {
  variant: CatalogVariantFragmentFragment;
}

export const OriginalPrice = ({ variant }: IProps) => {
  const {
    isDualPriceForCroatia,
    leftSideCurrency,
    rightSideCurrency,
    getLeftSideFormattedPrice,
    getRightSidePrice,
  } = useIsDualPriceForCroatia();
  const { discountText, topPrice } = usePriceLogic(variant);
  const isSmall = useHasStressOnVoucherDiscount();

  if (!topPrice && !discountText) {
    return null;
  }

  return (
    <DiscountWrapper data-testid="originalPriceDiscountWrapper">
      {topPrice && (
        <OriginalPriceWrapper data-testid="originalPriceWrapper">
          {isDualPriceForCroatia && (
            <>
              <LineThrough
                active={topPrice.isCrossed}
                data-testid="originalDualPriceLineThroughWrapper"
              >
                <PriceWrapper>
                  {getLeftSideFormattedPrice(topPrice.value.value)}{' '}
                  {leftSideCurrency}
                </PriceWrapper>
              </LineThrough>
              <Slash>/</Slash>
            </>
          )}

          <FlexEnd>
            <LineThrough
              active={topPrice.isCrossed}
              data-testid="originalPriceLineThroughWrapper"
            >
              <PriceLabel
                price={
                  isDualPriceForCroatia
                    ? {
                        value: getRightSidePrice(topPrice.value.value),
                      }
                    : topPrice.value
                }
                currencySymbolOverride={
                  isDualPriceForCroatia ? rightSideCurrency : undefined
                }
                wrapperComponent={PriceWrapper}
                currencyComponent={CurrencyWrapper}
              />
            </LineThrough>

            <PortalTooltip
              position={TooltipModel.Position.topRight}
              interactive={true}
              content={
                <PortalTooltip.Content>
                  {topPrice.tooltip}
                </PortalTooltip.Content>
              }
            >
              <StyledInfoIcon color="icon.disabled" height="0.9rem" />
            </PortalTooltip>
          </FlexEnd>
        </OriginalPriceWrapper>
      )}

      <Discount isSmall={isSmall}>{discountText}</Discount>
    </DiscountWrapper>
  );
};
