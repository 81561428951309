import { useEffect, useRef } from 'react';

import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';

export const useGiftsTracking = () => {
  const moreGiftsTracked = useRef(false);
  const { currentVariant } = useProductDetailContext();
  const { getTimeFromInit } = useTrackingContext();

  const trackShowMore = (interaction: 'click' | 'swipe') => {
    dispatchTrackingEvent({
      event: 'element_click',
      _clear: true,
      element: {
        timing: getTimeFromInit(),
        interaction,
        name: 'more_gifts',
        type: 'product_detail',
        action: 'more_gifts_view',
      },
    });
  };

  useEffect(() => {
    moreGiftsTracked.current = false;
  }, [currentVariant.webId]);

  const trackToggleGifts = (isExpanded: boolean) => {
    if (moreGiftsTracked.current || !isExpanded) {
      return;
    }
    trackShowMore('click');
    moreGiftsTracked.current = true;
  };

  const trackScrollGifts = () => {
    if (moreGiftsTracked.current) {
      return;
    }
    trackShowMore('swipe');
    moreGiftsTracked.current = true;
  };

  return {
    trackToggleGifts,
    trackScrollGifts,
  };
};
