import * as React from 'react';

import {
  IconRegularChevronDown,
  IconRegularChevronUp,
  SemanticColorKey,
} from '@notino/react-styleguide';

const CHEVRON_SIZE = '12px';
interface HorizontalChevronProps {
  isOpened: boolean;

  color?: SemanticColorKey;

  width?: string;

  height?: string;
}

export const HorizontalChevron: React.FC<HorizontalChevronProps> = ({
  isOpened,
  color,
  width,
  height,
}) =>
  isOpened ? (
    <IconRegularChevronUp
      color={color || 'icon.disabled'}
      width={width || CHEVRON_SIZE}
      height={height || CHEVRON_SIZE}
    />
  ) : (
    <IconRegularChevronDown
      color={color || 'icon.disabled'}
      width={width || CHEVRON_SIZE}
      height={height || CHEVRON_SIZE}
    />
  );
