import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../../../messages';
import { isTomorrow } from '../utils/dateUtils';

import { FormattedDate, FormattedTime } from './FormattedDate';
import { TimeText as StyledTimeText } from './styled';

interface ITimeTextProps {
  date: string;
  from?: string;
  to?: string;
}

export const TimeText: React.FC<ITimeTextProps> = ({ date, from, to }) => {
  const dateToCompare = new Date(date);
  const nowDate = new Date().getDate();

  const isTomorrowDate = isTomorrow(dateToCompare);
  const isTodayDate = dateToCompare.getDate() === nowDate;

  const getFormattedDate = () => {
    if (from && to && (isTodayDate || isTomorrowDate)) {
      return <FormattedTime from={from} to={to} />;
    }
    return <FormattedDate date={date} />;
  };

  if (isTomorrowDate) {
    return (
      <>
        <StyledTimeText>
          <FormattedMessage {...messages.deliverTomorrow} />
        </StyledTimeText>{' '}
        {getFormattedDate()}
      </>
    );
  } else if (isTodayDate) {
    return (
      <>
        <StyledTimeText>
          <FormattedMessage {...messages.deliverToday} />{' '}
        </StyledTimeText>
        {from && to && getFormattedDate()}
      </>
    );
  }

  return getFormattedDate();
};
