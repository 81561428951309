import * as React from 'react';

import {
  Col,
  ReviewsSummaryWrapper,
  ReviewsWrapper,
  SkeletonElement,
} from './styled';

export const ReviewSummarySkeleton: React.FC = () => {
  return (
    <ReviewsSummaryWrapper>
      <Col width={20}>
        <SkeletonElement width={8} height={1.5} />
        <SkeletonElement width={5} height={2} marginY={0.5} />
      </Col>

      <ReviewsWrapper width={50} mobileWidth={100}>
        <Col width={80}>
          <SkeletonElement height={0.5} marginY={0.5} />
        </Col>
        <Col width={80}>
          <SkeletonElement height={0.5} marginY={0.5} />
        </Col>
        <Col width={80}>
          <SkeletonElement height={0.5} marginY={0.5} />
        </Col>
        <Col width={80}>
          <SkeletonElement height={0.5} marginY={0.5} />
        </Col>
        <Col width={80}>
          <SkeletonElement height={0.5} marginY={0.5} />
        </Col>
      </ReviewsWrapper>
      <ReviewsWrapper width={30} hideOnMobile={true}>
        <SkeletonElement height={0.5} marginY={0.5} />
        <SkeletonElement height={0.5} marginY={0.5} />
        <SkeletonElement height={0.5} marginY={0.5} />
      </ReviewsWrapper>
    </ReviewsSummaryWrapper>
  );
};
