import styled from 'styled-components';

import {
  PictogramRegularBagBrand,
  breakpoints,
  Button,
  theme,
} from '@notino/react-styleguide';

export const StyledBag = styled(PictogramRegularBagBrand)`
  height: 2.5rem;
  width: 2.5rem;
  margin: 2.25rem 0 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  @media (min-width: ${breakpoints.md}) {
    padding: 0rem;
  }
`;

export const Title = styled.div`
  ${theme.typography.titleLarge}
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
  ${theme.typography.labelRegular400};
  text-align: center;
  color: #474747;
  margin-bottom: 1.5rem;
  max-width: 400px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 2.625rem;

  @media (min-width: ${breakpoints.sm}) {
    width: 13.125rem;
  }
`;
