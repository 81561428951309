import * as React from 'react';

import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

type Props = { count: number; index: number };

const Track = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Indicator = styled.div<{ width: string; left: string }>`
  height: 0.2rem;
  background-color: ${theme.color.common.gray50};
  position: relative;
  border-radius: 1rem;
  width: ${({ width }) => width};
  left: ${({ left }) => left};
  transition: left 250ms;
`;

export const ProgressBar = ({ count, index }: Props) => {
  if (count < 2) {
    return null;
  }

  const width = 100 / count;
  const left = width * index;

  return (
    <Track data-testid="progress-bar">
      <Indicator width={`${width}%`} left={`${left}%`} />
    </Track>
  );
};
