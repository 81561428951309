import { IntlFormatters } from 'react-intl';

import {
  DeliveryType,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import { IDelivery } from '@containers/ProductListing/model';

import messages from '../../../../messages';

export interface IDeliveryGroup {
  deliveries: IDelivery[];
  getTopDeliveryName: (
    delivery: IDelivery,
    intl: IntlFormatters['formatMessage']
  ) => string;
}

interface IDeliveryByType {
  personal: IDelivery[];
  transport: IDelivery[];
  airTransport: IDelivery[];
}

export const groupPossibleDeliveries = (
  deliveries: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['deliveries']
): IDeliveryGroup[] => {
  const deliveriesByType = [...deliveries]
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    .reduce(
      (acc, delivery) => {
        if (delivery.delivery === DeliveryType.Personal) {
          acc.personal = [...acc.personal, delivery];
        } else if (delivery.delivery === DeliveryType.AirTransport) {
          acc.airTransport = [...acc.airTransport, delivery];
        } else {
          acc.transport = [...acc.transport, delivery];
        }

        return acc;
      },
      { personal: [], transport: [], airTransport: [] }
    );

  return getPossibleDeliveryGroups(deliveriesByType);
};

const getPossibleDeliveryGroups = (
  deliveriesByType: IDeliveryByType
): IDeliveryGroup[] => {
  const possibleDeliveryGroups = [];

  if (deliveriesByType.transport.length > 0) {
    possibleDeliveryGroups.push({
      deliveries: deliveriesByType.transport,
      getTopDeliveryName: (delivery) => delivery.name,
    });
  }

  if (deliveriesByType.personal.length > 0) {
    possibleDeliveryGroups.push({
      deliveries: deliveriesByType.personal,
      getTopDeliveryName: (_, formatMessage) =>
        formatMessage(messages.deliveryPersonal),
    });
  }

  if (deliveriesByType.airTransport.length > 0) {
    possibleDeliveryGroups.push({
      deliveries: deliveriesByType.airTransport,
      getTopDeliveryName: (delivery) => delivery.name,
    });
  }

  return possibleDeliveryGroups;
};
