import * as React from 'react';

const TAP_MAX_TIME_MS = 300;
const TAP_MAX_OFFSET = 20;

export const useOnTap = <TElement extends HTMLElement>(
  callback: () => void
) => {
  const ref = React.useRef<TElement>(null);
  const startTime = React.useRef(0);
  const startPosition = React.useRef({ x: 0, y: 0 });

  const callbackRef = React.useRef(callback);
  React.useEffect(() => {
    callbackRef.current = callback;
  });

  React.useEffect(() => {
    const el = ref.current;

    const handleStart = (e: TouchEvent) => {
      const touch = e.changedTouches.item(0);
      startPosition.current = { x: touch.clientX, y: touch.clientY };
      startTime.current = new Date().getTime();
    };

    const handleEnd = (e: TouchEvent) => {
      const now = new Date().getTime();
      const touch = e.changedTouches.item(0);
      const isQuick = now - startTime.current < TAP_MAX_TIME_MS;
      const isClose =
        Math.abs(touch.clientX - startPosition.current.x) < TAP_MAX_OFFSET &&
        Math.abs(touch.clientY - startPosition.current.y) < TAP_MAX_OFFSET;

      if (isQuick && isClose) {
        callbackRef.current();
      }
    };

    el?.addEventListener('touchstart', handleStart);
    el?.addEventListener('touchend', handleEnd);

    return () => {
      el?.addEventListener('touchstart', handleStart);
      el?.removeEventListener('touchend', handleEnd);
    };
  }, []);

  return ref;
};
