import { ReactFCC } from '@notino/react-styleguide';

interface ILoadingWrapperProps {
  isLoading: boolean;
  isError: boolean;
  skeleton: JSX.Element;
  error: JSX.Element;
}

export const LoadingWrapper: ReactFCC<ILoadingWrapperProps> = ({
  isLoading,
  isError,
  error,
  skeleton,
  children,
}) => {
  if (isLoading) {
    return skeleton;
  }
  if (isError) {
    return error;
  }

  return children as JSX.Element;
};
