import styled from 'styled-components';

import {
  ActionButtonWithConfirmation,
  breakpoints,
  theme,
} from '@notino/react-styleguide';

export const StoreWrapper = styled.div`
  padding: 1.25rem 0;
  &:not(:last-child) {
    border-bottom: solid 1px ${theme.color.border.disabled};
  }
  &:first-child {
    padding-top: 0;
  }
`;
export const ContentWrapper = styled.div<{
  shouldShowGiftInformationBox: boolean;
}>`
  overflow-y: auto;
  padding: 1.5rem;
  margin-bottom: ${({ shouldShowGiftInformationBox }) =>
    shouldShowGiftInformationBox ? '8rem' : '4rem'};

  @media (min-width: ${breakpoints.md}) {
    max-height: 50vh;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const CenterWrapper = styled.div`
  padding-top: 2.8125rem;
  display: flex;
  justify-content: center;
`;

export const ActionButtonWithConfirmationWrapper = styled.div`
  padding: 1rem;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: white;

  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: ${breakpoints.md}) {
    position: relative;
    padding: 1.5rem;
  }
`;

export const StyledActionButtonWithConfirmation = styled(
  ActionButtonWithConfirmation
)`
  @media (min-width: ${breakpoints.sm}) {
    margin: auto;
    display: block;
  }
`;

export const GiftInformationBox = styled.div`
  padding: 0.75rem;
  background-color: ${theme.color.background.secondary};

  display: flex;
  gap: 0.5rem;

  margin-bottom: 1rem;

  svg {
    flex-shrink: 0;
  }
`;
