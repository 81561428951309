import * as React from 'react';

import { ApolloCache, useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';

import {
  AddToWishlistMutation,
  AddToWishlistMutationVariables,
  GetItemDataByIdQuery,
  RemoveFromWishlistMutation,
  RemoveFromWishlistMutationVariables,
} from '@notino/shared/definitions/types';

import getItemDataByIdQuery from '@components/Universals/ProductItem/queries/itemDataById.graphql';
import { getSkinAnalyzerVisibility } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/SkinAnalyser/hooks/useAnalyzerVisibility';
import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import addToWishlistMutation from '../queries/addToWishlist.graphql';
import removeFromWishlistMutation from '../queries/removeFromWishlist.graphql';
import { trackExponeaWishlistUpdate } from '../tracking/exponea';
import {
  handleTrackWishlistOperation,
  WishlistActionLocation,
} from '../tracking/gtm';
import { updateWishlistedCache } from '../updateWishlistedCache';

import { useIsWishlistEnabled } from './useIsWishlistEnabled';

export const publishUpdateWishlistCountEvent = () => {
  if (window.wishlistService) {
    window.wishlistService.count();
  } else if (document) {
    // publish a custom event only when the wihlistService is unavailable because
    // when it is available the event is published inside the wishlistService count() method
    const event = new CustomEvent('header:wishlist:overview:update');
    document.dispatchEvent(event);
  }
};

interface IUseWishlistActions {
  productId: string;
  wishlisted: boolean;
  onCompleted?: (productId: string, wishlisted: boolean) => void;
}

export const useWishlistActionsFactory = ({
  onCompleted,
}: Pick<IUseWishlistActions, 'onCompleted'>) => {
  const { getTimeFromInit } = useTrackingContext();
  const pdContext = useProductDetailContext(false);
  const flags = useFeatureFlags();
  const { shouldShowSkinAnalyzer } = getSkinAnalyzerVisibility({
    product: pdContext?.product,
    pd_skin_analyzer_variant: flags.pd_skin_analyzer_variant,
  });

  const isWishlistAllowed = useIsWishlistEnabled();

  const [add, { loading: addLoading }] = useMutation<
    AddToWishlistMutation,
    AddToWishlistMutationVariables
  >(addToWishlistMutation);

  const [remove, { loading: removeLoading }] = useMutation<
    RemoveFromWishlistMutation,
    RemoveFromWishlistMutationVariables
  >(removeFromWishlistMutation);

  const loading = addLoading || removeLoading;

  const handleWishlistClick = React.useCallback(
    async ({
      productId,
      wishlisted,
      actionLocation,
      trackingPromoLabels = [],
      listName,
      listPosition,
    }: {
      productId: string;
      wishlisted: boolean;
      actionLocation: WishlistActionLocation;
      trackingPromoLabels?: string[];
      listName?: string;
      listPosition?: number;
    }) => {
      const addedToWishlist = !wishlisted;
      const operation = addedToWishlist ? add : remove;
      const { data } = await operation({
        variables: { productId },
        update: (cache: ApolloCache<object>) => {
          updateWishlistedCache({
            cache,
            wishlisted: addedToWishlist,
            productId,
          });

          const vpProduct = cache.readQuery<GetItemDataByIdQuery>({
            query: getItemDataByIdQuery,
            variables: { id: productId.toString() },
          })?.vpProductById;

          const isCurrentProductDetail =
            pdContext?.currentVariant.webId === productId;

          if (vpProduct || isCurrentProductDetail) {
            handleTrackWishlistOperation({
              addedToWishlist,
              shouldShowSkinAnalyzer,
              vpProduct,
              timing: getTimeFromInit(),
              flags,
              actionLocation,
              additionalPromoLabels: trackingPromoLabels,
              product: isCurrentProductDetail ? pdContext.product : null,
              variant: isCurrentProductDetail ? pdContext.currentVariant : null,
              additionalGtm: {
                list_name: listName,
                list_position: listPosition,
              },
              engravingAvailable: pdContext?.engravingAvailable,
              tryItFirstAvailable: pdContext?.tryItFirstAvailable,
              modifaceVariants: isEmpty(pdContext?.modiface.hair)
                ? pdContext?.modiface.makeup
                : pdContext.modiface.hair,
            });
          }
        },
      });

      const wishlistProductIds =
        'WishlistAddProduct' in data
          ? data.WishlistAddProduct.products
          : data.WishlistRemoveProduct.products;

      trackExponeaWishlistUpdate({
        action: addedToWishlist ? 'add' : 'remove',
        productId: Number(productId),
        wishlistProductIds: wishlistProductIds.map(Number),
      });

      publishUpdateWishlistCountEvent();
      onCompleted?.(productId, addedToWishlist);
    },
    [
      add,
      onCompleted,
      remove,
      getTimeFromInit,
      pdContext,
      flags,
      shouldShowSkinAnalyzer,
    ]
  );

  return React.useMemo(
    () => ({
      handleWishlistClick,
      isWishlistAllowed,
      loading,
    }),
    [handleWishlistClick, isWishlistAllowed, loading]
  );
};

export const useWishlistActions = ({
  productId,
  wishlisted,
  onCompleted,
}: IUseWishlistActions) => {
  const { handleWishlistClick, isWishlistAllowed, loading } =
    useWishlistActionsFactory({ onCompleted });

  return React.useMemo(
    () => ({
      handleWishlistClick: (
        actionLocation: WishlistActionLocation,
        trackingPromoLabels: string[] = []
      ) => {
        return handleWishlistClick({
          productId,
          wishlisted,
          actionLocation,
          trackingPromoLabels,
        });
      },
      isWishlistAllowed,
      loading,
    }),
    [handleWishlistClick, isWishlistAllowed, loading, productId, wishlisted]
  );
};
