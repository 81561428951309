import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { masterUrl, removeUrlSearchParams } from '@notino/shared/client-utils';
import {
  PathTemplate,
  GetLocalesDataQuery,
  GetLocalesDataQueryVariables,
  GetCatalogProductViewQuery,
  GetCatalogProductViewQueryVariables,
} from '@notino/shared/definitions/types';

import { prefixes } from '@containers/LanguageProvider/prefixes';
import { unmountSkinAnalyzer } from '@containers/ProductDetailContainer/ProductDetail/ProductBaseInfo/SkinAnalyser/lib/skinAnalyzerScripts';
import getProductViewQuery from '@containers/ProductDetailContainer/queries/catalogProductView.graphql';
import * as Sentry from '@sentry/browser';
import { dispatchNotinoEvent } from '@utils/notino-events';

import getLocalesQuery from '../../containers/LanguageProvider/queries/localesData.graphql';

import UpdateResolveUrlQuery from './queries/updateResolveUrl.graphql';

import { IClientRedirectProps } from './index';

export const usePrefetchProduct = () => {
  const { query, cache } = useApolloClient();

  const getQueryForTextsIfMissing = () => {
    const languageForPd = {
      query: getLocalesQuery,
      variables: {
        prefixes: prefixes[PathTemplate.ProductDetailSimple],
      },
    };
    const textsForPd = cache.readQuery(languageForPd);

    if (textsForPd) {
      return null;
    }
    return query<GetLocalesDataQuery, GetLocalesDataQueryVariables>(
      languageForPd
    );
  };

  return (product: IClientRedirectProps['product']) => {
    const languageQuery = getQueryForTextsIfMissing();

    Promise.all([
      query<GetCatalogProductViewQuery, GetCatalogProductViewQueryVariables>({
        query: getProductViewQuery,
        variables: {
          masterId: String(product.masterId),
          catalogMasterId: String(product.catalogMasterId),
        },
        errorPolicy: 'all',
      }),
      languageQuery,
    ]).catch((e) =>
      Sentry.captureMessage(`Some query for SPA redirect failed ${String(e)}`)
    );
  };
};

export const useModifyCacheAndChangePathname = () => {
  const { cache } = useApolloClient();
  const prefetch = usePrefetchProduct();

  return (product: IClientRedirectProps['product']) => {
    prefetch(product);
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant', // typescript doesn't recognize instant, but it's described in docs - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    });

    const type = 'ProductDetailSimple';
    const url = `/productDetail?productId=${product.masterId}&variantId=${product.id}&catalogId=${product.catalogMasterId}`;

    cache.writeQuery({
      query: UpdateResolveUrlQuery,
      data: {
        resolveUrl: {
          __typename: 'PathView',
          id: btoa(url),
          path: url,
          productId: String(product.masterId),
          catalogId: String(product.catalogMasterId),
          type: type,
        },
      },
      variables: {
        url: removeUrlSearchParams(masterUrl(product.url)),
      },
    });
  };
};

/**
 * Spa Redirect decorator for spa redirect for anchor elements
 * @param product = { url, id }
 * @param isSpaEnabled if false, SPA redirect never happens
 * @param useMasterUrl
 */
export const useWithSpaRedirect = (
  product: IClientRedirectProps['product'],
  isSpaEnabled: boolean = true,
  useMasterUrl: boolean = false
): ((
  fc: React.MouseEventHandler<HTMLAnchorElement>
) => React.MouseEventHandler<HTMLAnchorElement>) => {
  const withProductSpaRedirect = useProductSpaRedirect(
    isSpaEnabled,
    useMasterUrl
  );

  return withProductSpaRedirect(product);
};

export const useProductSpaRedirect = (
  isSpaEnabled: boolean = true,
  useMasterUrl: boolean = false
): ((
  product: IClientRedirectProps['product']
) => (
  fc: React.MouseEventHandler<HTMLAnchorElement>
) => React.MouseEventHandler<HTMLAnchorElement>) => {
  const modifyCacheAndChangePathname = useModifyCacheAndChangePathname();
  const navigate = useNavigate();

  return (product: IClientRedirectProps['product']) =>
    (func: React.MouseEventHandler<HTMLAnchorElement>) =>
    (e) => {
      func(e);

      const shouldOpenInNewTab = e.ctrlKey || e.metaKey;
      if (isSpaEnabled && !shouldOpenInNewTab) {
        e.preventDefault();
        modifyCacheAndChangePathname(product);
        unmountSkinAnalyzer();
        navigate(useMasterUrl ? masterUrl(product.url) : product.url);
        dispatchNotinoEvent({
          name: 'spa:redirect',
        });
      }
    };
};
