import { withTryToInvokeTrackingEvent } from '@utils/exponea-logging';

export const runExponeaFunctions = () => {
  if (typeof window.runExponeaFunctions === 'function') {
    window.runExponeaFunctions();
  } else {
    window.addEventListener('load', runExponeaAndRemoveListener);
  }
};

const runExponeaAndRemoveListener = () => {
  if (typeof window.runExponeaFunctions === 'function') {
    window.runExponeaFunctions();
  }
  window.removeEventListener('load', runExponeaAndRemoveListener);
};

export const runTrackingEventViewItem = withTryToInvokeTrackingEvent(
  'ViewItem',
  (payload: Parameters<typeof window.trackingEvents.ViewItem>[0]) => {
    void window.trackingEvents.ViewItem(payload);
  }
);
