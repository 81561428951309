import * as React from 'react';

import { IconRegularClose } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';
import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { PortalTooltip } from '@components/PortalTooltip';
import { VariantDisplayTypes } from '@containers/ProductListing/model';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { hexToDec, dataLabelsProp } from '@helpers/utils';

import { trackVariantClick } from '../../tracking';
import { VariantLink, VariantListItem } from '../../variantsStyled';
import { HandleTransitionClick } from '../useVariantTransition';

import { StyledColorCircle, Variant } from './styled';

export interface IVariantItemProps {
  item: CatalogVariantFragmentFragment;
  isSelected: boolean;
  onClick?: HandleTransitionClick;
}

export const getContrastingIconColor = (colors: string[]) => {
  if (colors.length === 0) {
    return 'icon.primary';
  }

  const avgColor =
    colors
      .map(
        (c) =>
          0.2126 * hexToDec(c, 0) +
          0.7152 * hexToDec(c, 2) +
          0.0722 * hexToDec(c, 4)
      )
      .reduce((a, b) => a + b) / colors.length;

  return avgColor > 0.179 ? 'icon.inverse' : 'icon.primary';
};

export const ColorPicker: React.FC<IVariantItemProps> = ({
  item,
  isSelected,
  onClick,
}) => {
  const { getTimeFromInit } = useTrackingContext();
  const isOnStock = item.stockAvailability.code !== IStock.outOfStock;

  const withoutColor = !item.colors || item.colors.length === 0;
  const colors = withoutColor ? ['icon.primary'] : item.colors;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(item, e);
    trackVariantClick(getTimeFromInit(), VariantDisplayTypes.ColorPicker);
  };

  const ColorTile = (
    <StyledColorCircle
      width={2.5}
      height={2.5}
      colors={colors}
      bordered={withoutColor && !isSelected}
    >
      {!isOnStock && (
        <IconRegularClose
          color={getContrastingIconColor(colors)}
          width="1.25rem"
          height="1.25rem"
        />
      )}
    </StyledColorCircle>
  );

  return (
    <VariantListItem
      minWidth="auto"
      data-testid={
        isSelected ? 'color-picker-item-selected' : 'color-picker-item'
      }
    >
      <ErrorBoundary>
        <VariantLink
          onClick={handleClick}
          replace={true}
          to={item.url}
          id={`pd-variant-${item.webId}`}
          data-testid={`pd-variant-${item.webId}`}
          className={isSelected ? 'pd-variant-selected' : ''}
          {...dataLabelsProp(item.attributes)}
        >
          <Variant touch={false} isSelected={isSelected} isOnStock={isOnStock}>
            <PortalTooltip
              disabled={!item.additionalInfo}
              content={
                <PortalTooltip.Content shouldWrapText={false}>
                  {item.additionalInfo}
                </PortalTooltip.Content>
              }
            >
              {ColorTile}
            </PortalTooltip>
          </Variant>
          <Variant touch={true} isSelected={isSelected} isOnStock={isOnStock}>
            {ColorTile}
          </Variant>
        </VariantLink>
      </ErrorBoundary>
    </VariantListItem>
  );
};
