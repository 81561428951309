import * as React from 'react';
import { FormattedNumber } from 'react-intl';

import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import { ILocale } from '@containers/App/model';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { usePriceLogic } from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic';
import { getDiscountPercentage } from '@containers/ProductDetailContainer/ProductDetail/hooks/usePriceLogic/utils';

import { Discount, DiscountWrapper, LineThrough, RrpSlash } from './styled';

interface IVariantDiscountProps {
  variant: CatalogVariantFragmentFragment;
  locale: ILocale;
}

export const VariantDiscount: React.FC<IVariantDiscountProps> = ({
  variant,
  locale,
}) => {
  const { topPrice } = usePriceLogic(variant);
  const {
    isDualPriceForCroatia,
    rightSideCurrencyCode,
    leftSideCurrency,
    getRightSidePrice,
    getLeftSideFormattedPrice,
  } = useIsDualPriceForCroatia();

  if (!topPrice) {
    return null;
  }

  return (
    <DiscountWrapper>
      <Discount>
        {-getDiscountPercentage(variant.price.value, topPrice.value.value)}%
      </Discount>

      {isDualPriceForCroatia && (
        <>
          <LineThrough
            active={topPrice.isCrossed}
            data-testid="originalDualPriceLineThroughWrapper"
          >
            {getLeftSideFormattedPrice(topPrice.value.value)} {leftSideCurrency}
          </LineThrough>
          <RrpSlash>/</RrpSlash>
        </>
      )}

      <LineThrough
        removeLeftPadding={isDualPriceForCroatia}
        active={topPrice.isCrossed}
        data-testid="originalPriceLineThroughWrapper"
      >
        <FormattedNumber
          value={
            isDualPriceForCroatia
              ? getRightSidePrice(topPrice.value.value)
              : topPrice.value.value
          }
          currency={
            isDualPriceForCroatia
              ? rightSideCurrencyCode
              : topPrice.value.currency
          }
          currencyDisplay="symbol"
          style="currency"
          minimumFractionDigits={locale.priceDecimalPlaces}
        />
      </LineThrough>
    </DiscountWrapper>
  );
};
