import * as React from 'react';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import { CatalogVariantFragmentFragment } from '@notino/shared/definitions/types';

import { Tags } from '@components/Tags';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { OriginalPrice } from './OriginalPrice';
import { TagDiscountFlexRow } from './styled';
import { trackTagClick } from './tracking';

interface ITagDiscountProps {
  variant: CatalogVariantFragmentFragment;
}

export const TagDiscount = ({ variant }: ITagDiscountProps) => {
  const { getTimeFromInit } = useTrackingContext();

  return (
    <TagDiscountFlexRow>
      <Tags
        onClick={(attr) => trackTagClick(attr, getTimeFromInit())}
        multiple={true}
        attributes={variant.attributes}
        stockAvailability={variant.stockAvailability.code}
        nowrapLg={true}
      />

      {variant.stockAvailability.code !== IStock.outOfStock && (
        <OriginalPrice variant={variant} />
      )}
    </TagDiscountFlexRow>
  );
};
