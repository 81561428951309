import { useApolloClient } from '@apollo/client';

import { AppFeatureSwitchesFragment } from '@notino/shared/definitions/types';

import featureSwitchesFragment from './featureSwitchesFragment.graphql';

interface IFeatureSwitches {
  reviewsOnServerIds: string[];
  addToCartResendDelay: number;
  addToCartWarningCount: number;
}

type Converters = {
  [Key in keyof IFeatureSwitches]: (value: string) => IFeatureSwitches[Key];
};

export const DEFAULT_FEATURE_SWITCHES: IFeatureSwitches = {
  reviewsOnServerIds: [],
  addToCartResendDelay: 0,
  addToCartWarningCount: 0,
};

const csvToStringArrayConverter = (value: string) =>
  value ? value.split(',') : [];

const numberConverter = (value: string) => {
  const parsed = +value;
  return Number.isNaN(parsed) ? 0 : parsed;
};

const converters: Converters = {
  reviewsOnServerIds: csvToStringArrayConverter,
  addToCartResendDelay: numberConverter,
  addToCartWarningCount: numberConverter,
};

const useFeatureSwitches = (): IFeatureSwitches => {
  const client = useApolloClient();

  const featureSwitches = client.readFragment<AppFeatureSwitchesFragment>({
    id: 'FeatureSwitches:0',
    fragment: featureSwitchesFragment,
  });

  const mapped = Object.entries(featureSwitches || {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: converters[key] ? converters[key](value) : value,
    }),
    {}
  );

  return { ...DEFAULT_FEATURE_SWITCHES, ...mapped };
};

export default useFeatureSwitches;
