import { useIntl, IntlFormatters } from 'react-intl';

import { GetPriceConfigQuery } from '@notino/shared/definitions/types';

import { usePriceConfig } from '@components/PriceLabel/usePriceConfig';
import { IPrice } from '@containers/ProductListing/model';

interface IFormattedPrice extends GetPriceConfigQuery {
  formattedPrice: string;
  currencySymbol: string;
  formattedPriceWithCurrency: string;
}

const formatPrice = (
  formatNumber: IntlFormatters['formatNumber'],
  priceConfig: GetPriceConfigQuery,
  price: IPrice
) => {
  const { currentCurrency, currentLocale } = priceConfig;
  const decimalPlaces = price.decimalPlaces || currentLocale.priceDecimalPlaces;

  const formattedPrice = formatNumber(price.value, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).toString();

  const currencySymbol =
    currentCurrency.symbol || currentLocale.currency || price.currency;

  const isPrefix = currentLocale.priceFormat?.startsWith('s');
  const isSpaced = currentLocale.priceFormat?.charAt(1) === ' ';

  const formattedPriceWithCurrency = `${isPrefix ? currencySymbol : ''}${
    isPrefix && isSpaced ? ' ' : ''
  }${formattedPrice}${!isPrefix && isSpaced ? ' ' : ''}${
    !isPrefix ? currencySymbol : ''
  }`;

  return {
    formattedPrice,
    currencySymbol,
    currentCurrency,
    currentLocale,
    formattedPriceWithCurrency,
  };
};

export const useFormatPrice = () => {
  const { formatNumber } = useIntl();
  const { loading, error, data } = usePriceConfig();

  return (price: IPrice) => {
    if (loading || error || !data || !price) {
      return null;
    }
    return formatPrice(formatNumber, data, price);
  };
};

export const useFormattedPrice = (price: IPrice): IFormattedPrice => {
  const formatPriceFn = useFormatPrice();

  return formatPriceFn(price);
};
