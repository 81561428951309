import * as React from 'react';
import {
  MessageDescriptor,
  WrappedComponentProps,
  FormattedMessage,
} from 'react-intl';

import { TooltipModel, ReactFCC } from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';
import { withConditionalWrapper } from '@helpers/withConditionalWrapper';

interface IFormattedTooltipProps {
  tooltipMessage: MessageDescriptor;
}

const FormattedTooltipComponent: ReactFCC<
  IFormattedTooltipProps & WrappedComponentProps
> = ({ tooltipMessage, children }) => (
  <PortalTooltip
    position={TooltipModel.Position.topRight}
    content={
      <PortalTooltip.Content>
        <FormattedMessage {...tooltipMessage} />
      </PortalTooltip.Content>
    }
  >
    {children}
  </PortalTooltip>
);

export const FormattedTooltip = withConditionalWrapper<IFormattedTooltipProps>(
  FormattedTooltipComponent
);
