import * as React from 'react';

import { ModalContext, ModalModel } from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import { ModalGallery } from '@components/ModalGallery';
import { PlayerWrapper } from '@components/ModalGallery/styled';
import { getVideoIframe } from '@containers/ProductDetailContainer/utils';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { useProductDetailContext } from '../ProductDetailContext';

import { Image360 } from './Image360';
import { ImageSlider } from './ImageSlider';
import { MainImage } from './MainImg';
import { ModiFaceOverlay } from './ModiFaceOverlay';
import {
  Container,
  MainImgWrapper,
  ImageWrapper,
  VideoWrapper,
  StyledCol,
  PictogramWrapper,
} from './styled';
import { ScrollThumbs } from './Thumbs/ScrollThumbs';
import { trackProductImageClick } from './tracking';
import { getImagesAttributes, getVideoAttrs } from './utils';

export const EAN_FOR_360_PHOTO = '4210201434658';

export interface IProductImageGalleryProps {
  media: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['media'];
  domain: string;
  hasModiface?: boolean;
  openModifaceModal?: () => void;
  isBrandLogoVisible?: boolean;
  pictogram?: JSX.Element | null;
}

export const ProductImageGallery: React.FC<IProductImageGalleryProps> = ({
  domain,
  media,
  openModifaceModal,
  hasModiface = false,
  isBrandLogoVisible = false,
  pictogram,
}) => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const { toggleModal, hideModal } = ModalContext.useModalContext();

  const { getTimeFromInit } = useTrackingContext();

  const { pd_360_photos, new_vto_button } = useFeatureFlags();
  const pdContext = useProductDetailContext(false);

  const shouldShow360Photo =
    pdContext?.currentVariant.eanCode === EAN_FOR_360_PHOTO && pd_360_photos;

  const { imagesAttrsOrder, imagesAttrsZoom, imagesAttrsOriginal } =
    React.useMemo(() => {
      const imagesArray = [
        media.mainImage,
        shouldShow360Photo && media.mainImage, //copy the main image until we dont have a specific image for this
        media.previewImage,
        ...media.others,
      ].filter((item) => item);

      const [mainImage, ...otherImages] = imagesArray;
      return {
        imagesAttrsOrder: [
          ...getImagesAttributes(imagesArray, domain, 'order'),
          ...media.videos.map((video) => getVideoAttrs(video, 'mqdefault')),
        ],
        imagesAttrsZoom: getImagesAttributes(imagesArray, domain, 'zoomImage'),
        imagesAttrsOriginal: [
          // let first image be in a little worse quality, so it loads faster for better LCP and UX
          ...getImagesAttributes([mainImage], domain, 'detailMain'),
          ...getImagesAttributes(otherImages, domain, 'detailGallery'),
        ],
      };
    }, [
      media.mainImage,
      media.previewImage,
      media.others,
      media.videos,
      shouldShow360Photo,
      domain,
    ]);

  const handleMainImageClick = React.useCallback(
    (imgIndex?: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      trackProductImageClick({
        timing: getTimeFromInit(),
      });

      if (imgIndex) {
        setImageIndex(imgIndex);
      }

      toggleModal(
        <ModalGallery
          onClose={hideModal}
          imageIndex={imgIndex}
          images={imagesAttrsZoom}
          thumbImages={imagesAttrsOrder}
          videos={media.videos}
          shouldShow360Photo={shouldShow360Photo}
        />,
        {
          center: true,
          size: ModalModel.Sizes.s1200,
          onlyTopBorder: true,
          noBorders: true,
        }
      );
    },
    [
      getTimeFromInit,
      toggleModal,
      hideModal,
      imagesAttrsZoom,
      imagesAttrsOrder,
      media.videos,
      shouldShow360Photo,
    ]
  );

  const videoIndex = imageIndex - imagesAttrsOriginal.length;
  const isVideo: boolean = videoIndex >= 0;

  const shouldShowModiFaceOverlay =
    hasModiface && !isVideo && (imageIndex === 0 || new_vto_button);

  return (
    <Container id="pdImageGallery" brandLogo={isBrandLogoVisible}>
      {pictogram ? <PictogramWrapper>{pictogram}</PictogramWrapper> : null}

      <ImageSlider
        imagesAttrs={imagesAttrsOriginal}
        videos={media.videos}
        hasModiface={hasModiface}
        openModifaceModal={openModifaceModal}
        shouldShow360Photo={shouldShow360Photo}
      />

      <StyledCol>
        <MainImgWrapper
          data-testid="main-image-wrapper"
          onClick={!isVideo ? handleMainImageClick(imageIndex) : undefined}
        >
          <ImageWrapper>
            {!isVideo &&
              (imageIndex === 1 && shouldShow360Photo ? (
                <Image360 />
              ) : (
                <MainImage
                  imageAttrs={imagesAttrsOriginal[imageIndex]}
                  onClick={handleMainImageClick(imageIndex)}
                  index={imageIndex}
                />
              ))}

            {isVideo && (
              <VideoWrapper>
                <PlayerWrapper
                  dangerouslySetInnerHTML={{
                    __html: getVideoIframe(media.videos[videoIndex]),
                  }}
                />
              </VideoWrapper>
            )}

            {shouldShowModiFaceOverlay && (
              <ModiFaceOverlay
                onClick={(e) => {
                  e.stopPropagation();
                  openModifaceModal();
                }}
              />
            )}
          </ImageWrapper>
        </MainImgWrapper>

        {imagesAttrsOrder.length > 1 && (
          <ScrollThumbs
            imagesAttrs={imagesAttrsOrder}
            imageIndex={imageIndex}
            handleImageChange={setImageIndex}
            handleImageClick={handleMainImageClick}
            dataTestId="pd-gallery-slider"
          />
        )}
      </StyledCol>
    </Container>
  );
};
