import styled from 'styled-components';

import {
  IBasicStyledProps,
  breakpoints,
  theme,
} from '@notino/react-styleguide';
interface IActiveTab extends IBasicStyledProps {
  isActive?: boolean;
}

interface IShowDivider extends IBasicStyledProps {
  tabDividerMobile?: boolean;
  tabDividerDesktop?: boolean;
}

interface IChildrenExpanded extends IBasicStyledProps {
  isExpanded?: boolean;
}

interface HiddenOnDesktop {
  hideOnDesktop?: boolean;
}

export const LinkWrapper = styled.div`
  cursor: pointer;
`;

export const ChildrenWrapper = styled.div<IChildrenExpanded & IActiveTab>`
  padding-bottom: 2.5rem;

  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 0;
  }

  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  min-height: auto;
  @media (min-width: ${breakpoints.lg}) {
    padding: 0;
    display: block;
    -webkit-transition: ${(props) =>
      props.isActive ? 'opacity 1s ease' : 'none'};
    transition: ${(props) => (props.isActive ? 'opacity 1s ease' : 'none')};
    z-index: ${(props) => (props.isActive ? '2' : '-1')};
    visibility: ${(props) => (props.isActive ? 'auto' : 'hidden')};
    opacity: ${(props) => (props.isActive ? '1' : '0')};
    position: ${(props) => (props.isActive ? 'relative' : 'fixed')};
  }
`;

export const TabDivider = styled.div<IShowDivider & HiddenOnDesktop>`
  display: ${(props: IShowDivider) =>
    props.tabDividerMobile ? 'block' : 'none'};
  position: relative;
  clear: both;
  height: 0.0625rem;
  background-color: ${theme.color.background.tertiary};
  @media (min-width: ${breakpoints.lg}) {
    display: ${(props: IShowDivider) =>
      props.tabDividerDesktop ? 'block' : 'none'};
    margin: ${(props) => (props.hideOnDesktop ? '0' : '0 0 3rem 0')};
  }
`;

export const TitleWrapper = styled.div<IActiveTab & HiddenOnDesktop>`
  height: 4.25rem;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: ${(props: IActiveTab) => (props.isActive ? 'block' : 'none')};
    ${(props) => (props.hideOnDesktop ? 'display: none !important;' : '')};
  }
`;
