//TODO: Remove exponea implementation of upselling recommendation, when it is clear there will be no further usage

import * as React from 'react';

import { useQuery } from '@apollo/client';

import {
  UpsellingProductsQuery,
  UpsellingProductsQueryVariables,
} from '@notino/shared/definitions/types';

import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import databreakersUpsellingQuery from './queries/databreakersUpsellingQuery.graphql';
import { RelatedWrapper } from './styled';
import { UpsellingList } from './UpsellingList';
import { notifyCypressUpsellingDisabled, trackUpsellingOpen } from './utils';

interface IUpsellingProductsProps {
  productCodes: string[];
}

export const UpsellingProducts: React.FC<IUpsellingProductsProps> = ({
  productCodes,
}) => {
  const { getTimeFromInit } = useTrackingContext();
  const {
    reco_product_detail_add_to_cart,
    pd_upselling_product_ids_for_testing,
  } = useFeatureFlags();
  const {
    product: { brand },
  } = useProductDetailContext();

  // Databreakers recommendations
  const { data: databreakersResponse } = useQuery<
    UpsellingProductsQuery,
    UpsellingProductsQueryVariables
  >(databreakersUpsellingQuery, {
    skip: !reco_product_detail_add_to_cart,
    variables: { productCodes, brandId: brand?.id ? Number(brand.id) : null },
  });

  React.useEffect(() => {
    trackUpsellingOpen({ timing: getTimeFromInit() });
  }, [getTimeFromInit]);

  const databreakersIds =
    databreakersResponse?.getUpsellingProductRecommendationsForMultiple?.recommendedProducts?.map(
      (x) => x.productId
    );

  const testingIds = pd_upselling_product_ids_for_testing?.length
    ? pd_upselling_product_ids_for_testing
    : null;

  const productIds = testingIds ?? databreakersIds ?? [];

  if (productIds.length === 0) {
    notifyCypressUpsellingDisabled(reco_product_detail_add_to_cart);
    return null;
  }

  return (
    <RelatedWrapper data-testid="upselling-wrapper">
      <UpsellingList
        databreakersProductIds={productIds}
        attributionToken={
          databreakersResponse?.getUpsellingProductRecommendationsForMultiple
            ?.recommendationAttributes?.attributionToken
        }
      />
    </RelatedWrapper>
  );
};
