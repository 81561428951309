// This file was copied from react-spring examples

import * as React from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import { IBounds } from '../sharedComponents/SpringCollapser/model';

export function useMeasure(): [{ ref: React.Ref<HTMLDivElement> }, IBounds] {
  const ref = React.useRef();
  const [bounds, setBounds] = React.useState<IBounds>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    const ro = new ResizeObserver(([entry]) => setBounds(entry.contentRect));

    if (ref.current) {
      ro.observe(ref.current);
    }
    return () => ro.disconnect();
  }, []);

  return [{ ref }, bounds];
}
