import styled from 'styled-components';

import { ReactFCC } from '@notino/react-styleguide';

export const Wrapper = styled.span`
  font-size: inherit;
  line-height: inherit;
`;

export const CurrencyWrapper = styled(Wrapper)<{
  isSpaced: boolean;
  isPrefix: boolean;
}>`
  &:before {
    content: ${(props) => (props.isSpaced && !props.isPrefix ? '" "' : '')};
  }
  &:after {
    content: ${(props) => (props.isSpaced && props.isPrefix ? '" "' : '')};
  }
` as ReactFCC;

export const LineWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const Slash = styled.span`
  padding: 0 0.25rem;
`;
