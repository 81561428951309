import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { registerKeyboardListeners } from '@helpers/accessibility/registerKeyboardListeners';
import { useTabSwitcher } from '@sharedComponents/TabSwitcher/context/TabSwitcherContext';

import { TabIds } from '../../model';

import { LinkWrapper } from './styled';

interface IContentTabLinkProps {
  tabId: TabIds;
  id: string;
  trackingLabel?: string;
  ariaControls?: string;
  tabOrder?: number;
  dataTestId?: string;
}

export const ContentTabLink: ReactFCC<IContentTabLinkProps> = ({
  tabId,
  trackingLabel,
  id,
  children,
  ariaControls,
  tabOrder,
  dataTestId,
}) => {
  const ref = React.useRef<HTMLButtonElement>();
  const { getTimeFromInit } = useTrackingContext();

  const { register, selectNext, selectPrevious, activeTabId, toggleActiveTab } =
    useTabSwitcher();

  const isActive = activeTabId === tabId;

  React.useEffect(() => {
    register(tabId, ref, tabOrder);
  }, [register, tabId, tabOrder]);

  const handleClick = () => {
    toggleActiveTab(tabId, true);

    if (trackingLabel) {
      dispatchTrackingEvent({
        event: 'subpage_view',
        subpage: {
          name: trackingLabel,
          type: 'tab',
          interaction: 'click',
          timing: getTimeFromInit(),
          action: 'click_on_tab',
        },
        _clear: true,
      });
    }
  };

  return (
    <LinkWrapper
      id={id}
      ref={ref}
      role="tab"
      data-testid={dataTestId}
      aria-controls={ariaControls}
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      isActive={isActive}
      onClick={handleClick}
      {...registerKeyboardListeners({
        ArrowRight: selectNext,
        ArrowLeft: selectPrevious,
      })}
    >
      {children}
    </LinkWrapper>
  );
};
