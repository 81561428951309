import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  maxProductsErrorHeader: {
    id: 'universals.addToCart.maxProductsError.header',
    defaultMessage: 'Košík je plný',
  },
  maxProductsErrorAdvice: {
    id: 'universals.addToCart.maxProductsError.advice',
    defaultMessage:
      'Dokončete rozdělanou objednávku a pokračujte v nákupu s novým košíkem.',
  },
  ok: {
    id: 'universals.addToCart.maxProductsError.button.text',
    defaultMessage: 'Ok',
  },
});
