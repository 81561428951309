/*
 * ProductBaseInfo messages
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutBrand: {
    id: 'brand.about',
    defaultMessage: 'O značce {brandName}',
  },
  prodDescription: {
    id: 'pd.about',
    defaultMessage: 'Popis {name}',
  },
  prodDescriptionTabName: {
    id: 'pd.about.tab.name',
    defaultMessage: 'Popis {name}',
  },
  compositionTab: {
    id: 'pd.composition',
    defaultMessage: 'Složení',
  },
  compositionPerfume: {
    id: 'pd.composition.perfume',
    defaultMessage: 'Složení',
  },
  compositionCosmetics: {
    id: 'pd.composition.cosmetics',
    defaultMessage: 'Vlastnosti',
  },
  relArtHeader: {
    id: 'pd.related.articles',
    defaultMessage: 'Napsali jsme o {name}',
  },
  other: {
    id: 'pd.other',
    defaultMessage: 'Ostatní',
  },
  code: {
    id: 'pd.code',
    defaultMessage: 'Kód:',
  },
  relReadMore: {
    id: 'pd.related.articles.more',
    defaultMessage: 'Číst dále',
  },
  lessThan5: {
    id: 'pd.availability.lessThan5',
    defaultMessage: '{count} ks skladem',
  },
  mainPage: {
    id: 'pd.main.page',
    defaultMessage: 'Hlavní stránka',
  },
  moreThan5: {
    id: 'pd.availability.moreThan5',
    defaultMessage: 'Více než 5 ks skladem',
  },
  moreThan20: {
    id: 'pd.availability.moreThan20',
    defaultMessage: 'Skladem',
  },
  outOfStock: {
    id: 'pd.availability.outOfStock',
    defaultMessage: 'Toto zboží není momentálně k dispozici',
  },
  notAvailable: {
    id: 'pd.not.available',
    defaultMessage: 'není k dispozici',
  },
  deliveryDescription: {
    id: 'pd.delivery.description',
    defaultMessage:
      'Uvedené doby jsou informativní, vycházejí z předpokládaných dodacích lhůt přepravních firem.',
  },
  addToCart: {
    id: 'pd.add.to.cart',
    defaultMessage: 'Do košíku',
  },
  addAllToCart: {
    id: 'pd.add.all.to.cart',
    defaultMessage: 'Vše do košíku',
  },
  addedToCart: {
    id: 'pd.added.to.cart',
    defaultMessage: 'Přidáno do košíku',
  },
  continueWithShopping: {
    id: 'pd.continue.with.shopping',
    defaultMessage: 'Pokračovat v nákupu',
  },
  goToCart: {
    id: 'pd.go.to.cart',
    defaultMessage: 'Přejít do košíku',
  },
  priceMissingForFreeDelivery: {
    id: 'pd.amount.missing.for.free.delivery',
    defaultMessage: '{price} do dopravy zdarma',
  },
  customersAlsoBuy: {
    id: 'pd.customers.also.buy',
    defaultMessage: 'Zákazníci také koupili',
  },

  watchAvailability: {
    id: 'pd.watch.availability',
    defaultMessage: 'Hlídat dostupnost',
  },
  deliveryWhereEver: {
    id: 'pd.delivery.whereever',
    defaultMessage: 'Kdekoliv v ČR',
  },
  deliveryPersonal: {
    id: 'pd.delivery.personal',
    defaultMessage: 'Osobní odběr',
  },
  deliveryDisallowed: {
    id: 'pd.delivery.disallowed',
    defaultMessage: 'Not allowed',
  },
  deliverToday: {
    id: 'pd.delivery.today',
    defaultMessage: 'již dnes',
  },
  deliverTomorrow: {
    id: 'pd.delivery.tomorrow',
    defaultMessage: 'již zítra',
  },
  watchdogText: {
    id: 'pd.watchdog.text',
    defaultMessage: `
      Rádi Vás budeme informovat e-mailem, až zboží naskladníme.
      Samozřejmě je jen na Vás, jestli nabídku využijete.
    `,
  },
  watchdogSentHeader: {
    id: 'pd.watchdog.sent.header',
    defaultMessage: 'Váš e-mail byl uložen.',
  },
  watchdogSentText: {
    id: 'pd.watchdog.sent.text',
    defaultMessage:
      'Budeme Vás informovat o změnách v dostupnosti tohoto produktu.',
  },
  watchdogHeader: {
    id: 'pd.watchdog.header',
    defaultMessage: 'Hlídání dostupnosti zboží',
  },
  watchdogEmailLabel: {
    id: 'pd.watchdog.email.label',
    defaultMessage: 'Vepište svůj e-mail',
  },
  watchdogSendButton: {
    id: 'pd.watchdog.send.button',
    defaultMessage: 'Odeslat',
  },
  watchdogTryAgainButton: {
    id: 'pd.watchdog.tryAgain.button',
    defaultMessage: 'Zkusit znovu',
  },
  watchdogSubmitError: {
    id: 'pd.watchdog.submit.error',
    defaultMessage: 'Chyba při odeslání formuláře.',
  },
  watchdogGDPRinfo: {
    id: 'pd.watchdog.gdpr.info',
    defaultMessage:
      'Odesláním souhlasíte se zpracováním emailu za účelem hlídání dostupnosti tohoto zboží společností Notino s.r.o., se sídlem v Brně, Londýnské náměstí 881/6, PSČ 639 00, IČO 27609057 do odeslání emailu, maximálně 1 roku. Údaje mohou být předány a zpracovány sesterskými společnostmi. Máte právo svůj souhlas kdykoliv odvolat, požadovat od správce přístup k osobním údajům, jejich opravu, výmaz, přenositelnost, popřípadě omezení zpracování. Více informací v {link}.​',
  },
  giftForYou: {
    id: 'pd.giftForYou',
    defaultMessage: 'Dárek pro vás',
  },
  giftsForYou: {
    id: 'pd.giftsForYou',
    defaultMessage: 'Dárky pro vás',
  },
  showMoreGifts: {
    id: 'pd.showMoreGifts',
    defaultMessage: 'Zobrazit více dárků',
  },
  showLessGifts: {
    id: 'pd.showLessGifts',
    defaultMessage: 'Zobrazit méně',
  },
  giftTooltipPickUpInformation: {
    id: 'pd.giftTooltipPickUpInformation',
    defaultMessage:
      'Nevztahuje se na nákup prostřednictvím služby Vyzvednout ihned',
  },
  discount: {
    id: 'pd.discount',
    defaultMessage: 'Sleva',
  },
  rrp: {
    id: 'pd.rrp',
    defaultMessage: 'Doporučená maloobchodní cena',
  },
  productVolume: {
    id: 'pd.productVolume',
    defaultMessage: 'Obsah výrobku',
  },
  productDamaged: {
    id: 'pd.productDamaged',
    defaultMessage:
      'Nový nerozbalený produkt se 100% obsahem, který má pouze poškozený obal.',
  },
  productReturned: {
    id: 'pd.productReturned',
    defaultMessage:
      'Vrácený produkt, který může být lehce použitý nebo bez původního obalu.',
  },
  withoutCellophane: {
    id: 'pd.withoutCellophane',
    defaultMessage: 'Tento produkt je již od výrobce balen bez celofánu.',
  },
  perfumeSample: {
    id: 'pd.perfumeSample',
    defaultMessage: 'odstřik',
  },
  differentPackaging: {
    id: 'pd.differentPackaging',
    defaultMessage:
      'Produkt může mít odlišné balení než to, které je na obrázku.',
  },
  isForProfessionals: {
    id: 'pd.isForProfessionals',
    defaultMessage: 'Pouze k profesionálnímu použití',
  },
  related: {
    id: 'relatedProducts.related',
    defaultMessage: 'Mohlo by vás zajímat',
  },
  getInspired: {
    id: 'pd.getInspired.title',
    defaultMessage: 'Inspirujte se',
  },
  lastVisited: {
    id: 'pd.last.visited',
    defaultMessage: 'Naposledy jste prohlíželi',
  },
  sendReviewRetry: {
    id: 'reviews.send.review.retry',
    defaultMessage: 'Zkusit znovu',
  },
  reviewHeader: {
    id: 'reviews.header',
    defaultMessage: 'Hodnocení {name}',
  },
  writeReview: {
    id: 'reviews.write.review',
    defaultMessage: 'Napsat hodnocení',
  },
  editReview: {
    id: 'reviews.edit.review',
    defaultMessage: 'Upravit hodnocení',
  },
  noReview: {
    id: 'reviews.no.review',
    defaultMessage: 'Tento produkt zatím nikdo nehodnotil, buďte první!',
  },
  reviews: {
    id: 'reviews.reviews',
    defaultMessage: 'hodnocení',
  },
  showNext: {
    id: 'reviews.show.next',
    defaultMessage: 'Zobrazit další',
  },
  showParametersReviews: {
    id: 'reviews.show.parameters.reviews',
    defaultMessage: 'Zobrazit hodnocení podle vlastností',
  },
  hideParametersReviews: {
    id: 'reviews.hide.parameters.reviews',
    defaultMessage: 'Skrýt hodnocení podle vlastností',
  },
  fetchFailed: {
    id: 'reviews.fetch.failed',
    defaultMessage: 'nepodařilo se načíst',
  },
  retry: {
    id: 'reviews.retry',
    defaultMessage: 'Zkusit znovu',
  },
  nameLabel: {
    id: 'reviews.name.label',
    defaultMessage: 'Jméno',
  },
  emailLabel: {
    id: 'reviews.email.label',
    defaultMessage: 'Email',
  },
  reviewTextLabel: {
    id: 'reviews.review.text.label',
    defaultMessage: 'Vaše recenze',
  },
  reviewTextDefault: {
    id: 'reviews.review.text.default',
    defaultMessage:
      'Nepište zde prosím dotazy ani informace, které se produktu netýkají.',
  },
  reviewWasItUsefull: {
    id: 'reviews.was.it.usefull',
    defaultMessage: 'Bylo hodnocení užitečné?',
  },
  reviewUsefullYes: {
    id: 'reviews.usefull.yes',
    defaultMessage: 'Ano',
  },
  reviewUsefullNo: {
    id: 'reviews.usefull.no',
    defaultMessage: 'Ne',
  },
  rating: {
    id: 'rating.input.name',
    defaultMessage: 'Celkové hodnocení',
  },
  ratingErrorRequired: {
    id: 'rating.input.name.error.required',
    defaultMessage: 'Prosím vyberte vaše hodnocení',
  },
  ratingTypeDesignFlakon: {
    id: 'rating.type.designFlakon',
    defaultMessage: 'vzhled flakonu',
  },
  ratingTypePersistenceScent: {
    id: 'rating.type.persistenceScent',
    defaultMessage: 'výdrž vůně',
  },
  ratingTypeIntensity: {
    id: 'rating.type.intensity',
    defaultMessage: 'intenzita',
  },
  ratingTypeOverallImpresion: {
    id: 'rating.type.overallImpresion',
    defaultMessage: 'celkový dojem',
  },
  ratingTypeSimplicityApplication: {
    id: 'rating.type.simplicityApplication',
    defaultMessage: 'jednoduchost aplikace',
  },
  ratingTypeCosmeticQuality: {
    id: 'rating.type.cosmeticQuality',
    defaultMessage: 'kosmetická kvalita',
  },
  ratingTypeTotalHappiness: {
    id: 'rating.type.totalHappiness',
    defaultMessage: 'spokojenost s výsledkem',
  },
  orderReviewsByDateTimeDesc: {
    id: 'order.reviews.by.date.desc',
    defaultMessage: 'Nejnovější',
  },
  orderReviewsByDateTimeAsc: {
    id: 'order.reviews.by.date.asc',
    defaultMessage: 'Nejstarší',
  },
  orderReviewsByRatingDesc: {
    id: 'order.reviews.by.rating.desc',
    defaultMessage: 'Nejlépe hodnocené',
  },
  orderReviewsByRatingAsc: {
    id: 'order.reviews.by.rating.asc',
    defaultMessage: 'Nejhůře hodnocené',
  },
  orderReviewsByFavoriteDesc: {
    id: 'order.reviews.by.favorite.desc',
    defaultMessage: 'Oblíbené',
  },
  orderReviewsByFavoriteAsc: {
    id: 'order.reviews.by.favorite.asc',
    defaultMessage: 'Neoblíbené',
  },
  reviewsCannotVote: {
    id: 'reviews.cannot.vote',
    defaultMessage: 'Pro hlasování se prosím',
  },
  reviewsLogIn: {
    id: 'reviews.log.in',
    defaultMessage: 'přihlaste',
  },
  reviewsVoteError: {
    id: 'reviews.vote.error',
    defaultMessage: 'Hlasování skončilo chybou.',
  },
  reviewsShowAll: {
    id: 'reviews.show.all',
    defaultMessage: 'Všechny recenze',
  },
  showSpecials: {
    id: 'pd.showSpecials',
    defaultMessage: 'Zobrazit produkty se speciální slevou',
  },
  hideSpecials: {
    id: 'pd.hideSpecials',
    defaultMessage: 'Skrýt produkty se speciální slevou',
  },
  vpTitle: {
    id: 'vp.title',
    defaultMessage: 'Hodí se k sobě',
  },
  rcHeader: {
    id: 'pd.rc.header',
    defaultMessage: 'Více o {name}',
  },
  illustrativeImage: {
    id: 'engraving.illustrative.image',
    defaultMessage: 'Obrázek je pouze ilustrační',
  },
  fixedHeaderArticles: {
    id: 'pd.fixedHeader.articles',
    defaultMessage: 'Napsali jsme',
  },
  fixedHeaderRichContent: {
    id: 'pd.fixedHeader.richContent',
    defaultMessage: 'Více',
  },
  fixedHeaderLivestreams: {
    id: 'pd.fixedHeader.livestreams',
    defaultMessage: 'Prohlédněte si ve videu',
  },
  livestreamsPlayButton: {
    id: 'pd.livestreams.play',
    defaultMessage: 'Spustit',
  },
  wishlistLabel: {
    id: 'pd.wishlist.label',
    defaultMessage: 'Přidat do oblíbených',
  },
  freeGiftPackagingTitle: {
    id: 'pd.freeGiftPackaging',
    defaultMessage: 'Dárkové balení zdarma',
  },
  freeGiftPackagingBrandInfo: {
    id: 'pd.freeGiftPackagingBrandInfo',
    defaultMessage:
      'K nákupu produktů značky {brand} nad {price} {currency} nyní získáte dárkové balení zdarma.\n' +
      'Stačí v košíku vybrat požadovanou barvu krabičky. Nabídka platí do vydání denních zásob.',
  },
  freeGiftPackagingProductInfo: {
    id: 'pd.freeGiftPackagingProductInfo',
    defaultMessage:
      'Zabalte si tento produkt do dárkové balení. Nabídka platí do vydání denních zásob.',
  },
  tryItOnYourself: {
    id: 'modiface.try.it.on.yourself',
    defaultMessage: 'Zkusit na sobě',
  },
  tryItOnYourselfNew: {
    id: 'modiface.tryItOnYourself',
    defaultMessage: 'Zkusit na sobě',
  },
  selectAppearance: {
    id: 'modiface.select.appearance',
    defaultMessage: 'Vybrat',
  },
  importantNotices: {
    id: 'pd.legal.importantNotices',
    defaultMessage: 'Důležitá upozornění:',
  },
  preparationUsage: {
    id: 'pd.legal.preparationUsage',
    defaultMessage: 'Způsob přípravy/užití (dávkování):',
  },
  fullComposition: {
    id: 'pd.legal.fullComposition',
    defaultMessage: 'Kompletní složení:',
  },
  foodComposition: {
    id: 'pd.legal.foodComposition',
    defaultMessage: 'Složení:',
  },
  showAllText: {
    id: 'pd.showAllText',
    defaultMessage: 'Celý text',
  },
  instructions: {
    id: 'pd.legal.instructions',
    defaultMessage: 'Podrobný návod:',
  },
  videoInstructions: {
    id: 'pd.legal.videoInstructions',
    defaultMessage: 'Videa:',
  },
  onlyOriginalReviews: {
    id: 'pd.reviews.show.original.reviews',
    defaultMessage: 'Pouze české recenze',
  },
  reviewSource: {
    id: 'pd.reviews.source',
    defaultMessage: 'Přeloženo z {shopId}',
  },
  automaticlyTranslatedReview: {
    id: 'pd.reviews.automaticly.translated.review',
    defaultMessage: 'Recenze byla automaticky přeložena z {shopId}',
  },
  sensitiveHairWarningText: {
    id: 'pd.hair.warning.text',
    defaultMessage:
      'Zjistěte, jak provést test citlivosti pokožky před použitím tohoto produktu.',
  },
  sensitiveHairWarningUrl: {
    id: 'pd.hair.warning.url',
    defaultMessage:
      'https://www.notino.cz/barveni-vlasu-testovani-citlivosti-pokozky/',
  },
  minimumRecentPrice: {
    id: 'pd.bellowLine.minimalRecentPrice',
    defaultMessage: 'Minimální cena',
  },
  showMoreText: {
    id: 'pd.description.expandableText.showMoreText',
    defaultMessage: 'Zobrazit více',
  },
  redirectReviewsPageLink: {
    id: 'pd.reviews.all.reviews.page.link',
    defaultMessage: '/reviews',
  },
  sharedComment: {
    id: 'pd.reviews.sharedComment',
    defaultMessage: 'Recenze od {userName} z {originalShopId}',
  },
  anonymizedSharedComment: {
    id: 'pd.reviews.anonymizedSharedComment',
    defaultMessage: 'Kopie recenze od zákazníka z {originalShopId}',
  },
  anonymizedComment: {
    id: 'pd.reviews.anonymizedComment',
    defaultMessage: 'Zákazník z {originalShopId}',
  },
  immediateCollection: {
    id: 'pd.availability.immediateCollection',
    defaultMessage:
      'Toto zboží je momentálně pouze dostupné k vyzvednutí ihned na některé z našich prodejen.',
  },
});
