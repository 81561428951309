import styled from 'styled-components';

import { breakpoints, Heading, theme } from '@notino/react-styleguide';

export const StyledHeader = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const H1 = styled(Heading.H1)`
  font-size: 1.125rem !important;
  line-height: 2rem !important;
  text-align: left;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.5rem !important;
  }

  margin: 0 !important;
`;

export const BrandExtraLine = styled.a`
  margin-right: 0.3rem;

  &:hover {
    text-decoration: underline;
  }

  margin-right: 0.25rem;
  color: ${theme.color.text.secondary};
  font-size: 1rem;
  color: ${theme.color.text.primary};
  font-size: 1.125rem;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

export const ProductNameWrapper = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Span = styled.span`
  font-weight: bold;

  display: block;
  font-size: 1rem;
  margin-top: 0rem;
  line-height: 1.5rem;
`;

export const Description = styled.span`
  ${theme.typography.bodyRegular};
  color: ${theme.color.text.secondary};
  margin-bottom: 0.5rem;
  display: block;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0.25rem;
  }
`;

export const SubName = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 300;

  @media (min-width: ${breakpoints.sm}) {
    line-height: 2rem;
    font-size: 1.1rem;
  }
`;

export const ReviewsAnchor = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const BazaarvoiceRatingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
`;

export const BazaarvoiceRatingsDiv = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;

  background: ${theme.color.background.primary};

  font-size: 0.75rem !important;
`;

export const Wrapper = styled.div`
  height: 1.125rem;
  position: relative;
`;

export const RatingWrapper = styled.div`
  svg {
    vertical-align: initial !important;
  }
`;
