import * as React from 'react';

import ErrorBoundary from '@components/ErrorBoundary';
import { IPrice } from '@containers/ProductListing/model';

import { Wrapper, Currency, Span, LineHeight } from './components';
import { useFormattedPrice } from './useFormattedPrice';

interface IPriceLabel {
  price: IPrice;
  lineHeight?: LineHeight;
  currencyComponent?: React.ComponentType<Record<string, unknown>>;
  wrapperComponent?: React.ComponentType<Record<string, unknown>>;
  id?: string;
  currencySymbolOverride?: string;
  dataTestId?: string;
}

const PriceLabel: React.FC<IPriceLabel> = ({
  price,
  lineHeight,
  currencyComponent: CurrencyComponent = Currency,
  wrapperComponent: WrapperComponent = Wrapper,
  id,
  currencySymbolOverride,
  dataTestId,
}) => {
  const formattedPriceObj = useFormattedPrice(price);

  if (!formattedPriceObj) {
    return null;
  }

  const {
    formattedPrice,
    currentLocale: { priceFormat },
    currencySymbol,
    currentCurrency,
  } = formattedPriceObj;

  // format is specified for ex like 'v s' where 'v' is value, 's' is symbol
  const isPrefix = priceFormat.startsWith('s');
  const isSpaced = priceFormat.charAt(1) === ' ';

  const symbol = (
    <CurrencyComponent
      content={currentCurrency.code}
      isSpaced={isSpaced}
      isPrefix={isPrefix}
    >
      {currencySymbolOverride || currencySymbol}
    </CurrencyComponent>
  );

  return (
    <ErrorBoundary>
      <WrapperComponent lineHeight={lineHeight} id={id}>
        {isPrefix && symbol}
        <Span data-testid={dataTestId} content={formattedPrice}>
          {formattedPrice}
        </Span>
        {!isPrefix && symbol}
      </WrapperComponent>
    </ErrorBoundary>
  );
};

export default PriceLabel;
