import styled, { css } from 'styled-components';

import { breakpoints, Heading } from '@notino/react-styleguide';

const commonCss = css`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex-basis: 100%;
  line-height: 1.18;
  margin: 0;
  font-weight: 500 !important;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.75rem;
    display: block;
    cursor: text;
    margin: 3rem 0 1.25rem;
  }
`;

export const Title = styled(Heading.H2)`
  ${commonCss}
`;

export const TitleButton = styled.button`
  background: transparent;
  width: 100%;
  border: 0;
  color: inherit;
  ${commonCss}
`;

export const ChevronIconWrapper = styled.span`
  cursor: pointer;
  text-align: center;
  width: 1rem;
  line-height: 1;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;
