import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import PriceLabel from '@components/PriceLabel';

import { IConditionalVoucherDiscount } from '../ConditionalVoucherDiscount';
import { useVoucherDiscountContext } from '../context';
import { messages as commonMessages } from '../messages';
import { CurrencyWrapper, Wrapper } from '../styled';
import { VoucherDiscountInfo } from '../VoucherDiscountInfo';

import { ConditionalInstructionsText } from './ConditionalInstructionsText';
import { messages } from './messages';
import { Tiers } from './Tiers';

export interface IVoucherDiscountProps {
  voucherDiscount: IConditionalVoucherDiscount;
}

export const MultiTierVoucherDiscount = ({
  voucherDiscount,
}: IVoucherDiscountProps) => {
  const { currency } = useVoucherDiscountContext();

  const { code, productsOnSaleIncluded, discountType, discountConditions } =
    voucherDiscount;

  const maxPercentageDiscount = Math.max(
    ...discountConditions.map((condition) => condition.percentageDiscount)
  );
  const maxAbsoluteDiscount = Math.max(
    ...discountConditions.map((condition) => condition.discountAmount)
  );

  const discountTypeComponentMap: Record<typeof discountType, JSX.Element> = {
    Percentage: (
      <FormattedMessage
        {...messages.discountAmountPercentage}
        values={{ amount: maxPercentageDiscount }}
      />
    ),
    Absolute: (
      <FormattedMessage
        {...messages.discountAmountAbsolute}
        values={{
          amountPrice: (
            <PriceLabel
              price={{
                value: maxAbsoluteDiscount,
                currency: currency.code,
              }}
              wrapperComponent={Wrapper}
              currencyComponent={CurrencyWrapper}
            />
          ),
        }}
      />
    ),
  };

  return (
    <VoucherDiscountInfo
      percentage={0}
      hasDivider={true}
      discountCode={code}
      showPercentageIcon={false}
      highlightedDiscountAmount={discountTypeComponentMap[discountType]}
      middleSection={<Tiers discount={voucherDiscount} />}
      instructionsText={
        <ConditionalInstructionsText discount={voucherDiscount} />
      }
      infoTooltipText={
        productsOnSaleIncluded ? (
          <FormattedMessage
            {...commonMessages.conditionalTooltipSaleIncludedText}
          />
        ) : (
          <FormattedMessage {...commonMessages.conditionalTooltipText} />
        )
      }
    />
  );
};
