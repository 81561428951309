import styled, { css } from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import {
  Wrapper as PriceLabelWrapper,
  CurrencyStyled as PriceLabelCurrencyWrapper,
} from '@components/PriceLabel/components/styled';

export const SectionWrapper = styled.section`
  margin: 1.25rem 0;
  padding: 0 0 0 0;
  margin-bottom: 0.75rem;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1.5rem;
`;

export const Select = styled.div<{ isExpanded: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  ${({ isExpanded }) =>
    css`
      height: 3.5rem;
      padding: 0 1rem;
      border: 1px solid
        ${isExpanded ? theme.color.border.selected : theme.color.border.divider};
    `}
`;

export const SelectedVariantName = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
  padding-bottom: 0.5rem;
  order: 2;

  @media (min-width: ${breakpoints.sm}) {
    padding-top: 0.5rem;
    width: 100%;
    order: 1;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: auto;
  }
`;

export const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: normal;

  @media (min-width: ${breakpoints.sm}) {
    margin-right: 0.625rem;
  }

  ${theme.typography.labelRegular400}
`;

export const ChevronIconWrapper = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  color: ${theme.color.text.tertiary};
  width: 1rem;
  padding: 0 0.1rem;
  margin-left: 1rem;
  background-color: ${theme.color.background.primary};
`;

export const CloseWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding: 0.5rem;
  height: 3.25rem;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  height: 2rem;
  display: inline-block;
  padding: 0.5rem;
`;

export const PriceAndName = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.sm}) {
    flex-wrap: nowrap;
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: baseline;
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: wrap;
  width: 100%;
  order: 1;
  padding-top: 0.5rem;

  @media (min-width: ${breakpoints.sm}) {
    width: auto;
    flex-flow: column;
    justify-content: center;
    order: 2;
    padding-top: 0;
  }
`;

export const PriceRatioWrapper = styled.div`
  margin-top: -0.5rem;
`;

export const PriceWrapper = styled(PriceLabelWrapper)`
  ${theme.typography.titleMedium}
`;

export const SmallerPriceWrapper = styled(PriceLabelWrapper)`
  ${theme.typography.labelLarge}
`;

export const CurrencyWrapper = styled(PriceLabelCurrencyWrapper)`
  ${theme.typography.titleMedium}
`;

export const SmallerCurrencyWrapper = styled(PriceLabelCurrencyWrapper)`
  ${theme.typography.labelLarge}
`;

export const DualPriceWrapper = styled.div`
  display: flex;
`;

export const DualPrice = styled.div<{ isSmall: boolean }>`
  font-size: 1.1rem;
  font-weight: 700;
  white-space: nowrap;
  ${({ isSmall }) =>
    isSmall ? theme.typography.labelLarge : theme.typography.titleLarge}
`;

export const DualPriceSplitter = styled.div<{
  isSmall: boolean;
}>`
  padding: 0 0.2rem;
  font-size: 1.1rem;
  font-weight: 700;
  ${({ isSmall }) =>
    isSmall ? theme.typography.labelLarge : theme.typography.titleLarge}
`;
