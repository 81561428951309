import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { TabIds } from '../model';

interface ITabsWithExpandableTextContext {
  tabsWithExpandableTextState: TTabsWithExpandableTextState;
  toggleTabOpening: (tabId: number) => void;
  toggleFullText: (tabId: number) => void;
}

interface ITabsWithExpandableTextProviderProps {
  tabsWithExpandableText?: TabIds[];
}

type TTabsWithExpandableTextState = Record<
  number,
  { isTabOpenForTheFirstTime: boolean; shouldShowFullText: boolean }
>;

const TabsWithExpandableTextContext =
  React.createContext<null | ITabsWithExpandableTextContext>(null);

export const TabsWithExpandableTextProvider: ReactFCC<
  ITabsWithExpandableTextProviderProps
> = ({ children, tabsWithExpandableText }) => {
  const initialState = React.useMemo(() => {
    return tabsWithExpandableText?.reduce<TTabsWithExpandableTextState>(
      (acc, cur) => {
        acc[cur] = {
          isTabOpenForTheFirstTime: true,
          shouldShowFullText: false,
        };

        return acc;
      },
      {} as TTabsWithExpandableTextState
    );
  }, []);

  const [tabsWithExpandableTextState, setTabsWithExpandableTextState] =
    React.useState<TTabsWithExpandableTextState>(initialState);

  const toggleTabOpening = React.useCallback(
    (tabId: number) => {
      if (tabsWithExpandableTextState && tabsWithExpandableTextState[tabId]) {
        setTabsWithExpandableTextState({
          ...tabsWithExpandableTextState,
          [tabId]: {
            ...tabsWithExpandableTextState[tabId],
            isTabOpenForTheFirstTime: false,
          },
        });
      }
    },
    [tabsWithExpandableTextState]
  );

  const toggleFullText = React.useCallback((tabId: number) => {
    if (tabsWithExpandableTextState && tabsWithExpandableTextState[tabId]) {
      setTabsWithExpandableTextState({
        ...tabsWithExpandableTextState,
        [tabId]: {
          ...tabsWithExpandableTextState[tabId],
          shouldShowFullText: true,
        },
      });
    }
  }, []);

  const value: ITabsWithExpandableTextContext = {
    toggleTabOpening,
    toggleFullText,
    tabsWithExpandableTextState,
  };

  return (
    <TabsWithExpandableTextContext.Provider value={value}>
      {children}
    </TabsWithExpandableTextContext.Provider>
  );
};

export const useTabsWithExpandableText = () => {
  return React.useContext(TabsWithExpandableTextContext);
};
