import { NormalizedCacheObject } from '@apollo/client';

function loadApolloState(): NormalizedCacheObject {
  return JSON.parse(document.getElementById('__APOLLO_STATE__').textContent);
}

function initDataLayer() {
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }
}

export { loadApolloState, initDataLayer };
