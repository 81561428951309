import * as React from 'react';

import { IProduct } from '@containers/ProductListing/model';

import { loadProductsFromCookieOldestFirst } from '../hooks/useLastVisited/utils';

const NUMBER_OF_VISIBLE_LAST_VISITED_PRODUCTS = 5;

export const useLastVisitedItems = (productId: IProduct['id']) => {
  return React.useMemo<Array<IProduct['id']>>(() => {
    return loadProductsFromCookieOldestFirst()
      .filter((lastVisitedProductId) => lastVisitedProductId !== productId)
      .slice(-NUMBER_OF_VISIBLE_LAST_VISITED_PRODUCTS)
      .reverse();
  }, [productId]);
};
