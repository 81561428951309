import * as React from 'react';
import { MessageDescriptor, FormattedMessage } from 'react-intl';

import { TooltipModel, ReactFCC } from '@notino/react-styleguide';

import { PortalTooltip } from '@components/PortalTooltip';
import { RequestState } from '@containers/App/model';

import messages from '../../../../../messages';

import {
  Vote as StyledVote,
  VoteWrapper,
  TooltipWrapper,
  TooltipBox,
  ErrorMsg,
} from './styled';

interface IVoteProps {
  message: MessageDescriptor;
  onClick: () => void;
  state: RequestState;
  voted?: boolean;
  describedBy?: string;
}

export const Vote: ReactFCC<IVoteProps> = ({
  children,
  message,
  onClick,
  state,
  voted,
  describedBy,
}) => (
  <VoteWrapper
    onClick={onClick}
    aria-describedby={describedBy}
    state={state}
    voted={voted}
  >
    {state === RequestState.FAILED && (
      <TooltipWrapper>
        <PortalTooltip
          position={TooltipModel.Position.bottomCenter}
          isOpen={true}
          content={
            <PortalTooltip.Content shouldWrapText={false}>
              <ErrorMsg>
                <FormattedMessage {...messages.reviewsVoteError} />
              </ErrorMsg>
            </PortalTooltip.Content>
          }
        >
          <TooltipBox />
        </PortalTooltip>
      </TooltipWrapper>
    )}
    <StyledVote>
      <FormattedMessage {...message} />
    </StyledVote>
    {children}
  </VoteWrapper>
);
