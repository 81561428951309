import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IStock } from '@notino/shared/definitions/custom-definitions';
import {
  AvailabilityState,
  CatalogVariantFragmentFragment,
  Roles,
} from '@notino/shared/definitions/types';

import { PortalTooltip } from '@components/PortalTooltip';
import { useFormatPrice } from '@components/PriceLabel/useFormattedPrice';
import { ILocale } from '@containers/App/model';
import {
  IDamage,
  MaximumVolumeInPercent,
} from '@containers/ProductListing/model';
import { useUser } from '@queries/useUser';
import { ExpandableText } from '@sharedComponents/ExpandableText';

import messages from '../../../messages';
import { usePriceLogic } from '../../hooks/usePriceLogic';
import { PriceRatio } from '../VariantsInSelectBox/PriceRatio';

import {
  Wrapper,
  CodeBlock,
  CodeName,
  Separator,
  Availability,
  AdditionalDescriptionStyled,
  AdditionalText,
  StyledInfoIcon,
} from './styled';

const Damage: React.FC<{ damage: IDamage }> = ({ damage }) => {
  const message =
    damage.volumeInPercent === MaximumVolumeInPercent
      ? messages.productDamaged
      : messages.productReturned;

  return (
    <AdditionalDescriptionStyled highlight={true}>
      <FormattedMessage {...message} />
    </AdditionalDescriptionStyled>
  );
};

interface IBelowTheLineProps {
  variant: CatalogVariantFragmentFragment;
  locale: ILocale;
}

export const BellowTheLine: React.FC<IBelowTheLineProps> = React.memo(
  ({ variant, locale }) => {
    const {
      attributes,
      orderCode,
      productCode,
      webId,
      catalogId,
      annotation,
      additionalText,
      availability,
      stockAvailability,
    } = variant;

    const { user } = useUser();

    const { bottomRecentPrice } = usePriceLogic(variant);
    const formatPrice = useFormatPrice();
    const { formatMessage } = useIntl();

    const isImmediateCollection =
      availability.availablePickUpStores > 0 &&
      availability.state !== AvailabilityState.CanBeBought;

    const isMaster = attributes && attributes.Master;

    return (
      <Wrapper data-testid="product-specifications">
        <Availability
          highlight={[IStock.outOfStock, IStock.lessThan5].includes(
            stockAvailability.code as IStock
          )}
        >
          {isImmediateCollection ? (
            <FormattedMessage {...messages.immediateCollection} />
          ) : (
            <FormattedMessage
              {...messages[stockAvailability.code]}
              values={{
                count: stockAvailability.count,
              }}
            />
          )}
        </Availability>

        {variant.unitPrice && (
          <PriceRatio
            prepend={<Separator> | </Separator>}
            stockAvailability={variant.stockAvailability.code}
            unitAmount={variant.unitPrice.perAmount}
            locale={locale}
            unit={variant.unitPrice.unit}
            unitPrice={variant.unitPrice}
          />
        )}

        {bottomRecentPrice && (
          <>
            <Separator> | </Separator>
            <FormattedMessage {...messages.minimumRecentPrice} />{' '}
            {formatPrice(bottomRecentPrice.value)?.formattedPriceWithCurrency}
            <PortalTooltip
              interactive={true}
              content={
                <PortalTooltip.Content>
                  {bottomRecentPrice.tooltip}
                </PortalTooltip.Content>
              }
            >
              <StyledInfoIcon color="icon.disabled" height="1rem" />
            </PortalTooltip>
          </>
        )}

        <Separator> | </Separator>

        <CodeBlock>
          <CodeName>
            <FormattedMessage {...messages.code} />{' '}
          </CodeName>

          {user.role === Roles.Admin ? (
            <span>
              <span role="button">{orderCode}</span> <b>{productCode}</b>{' '}
              {webId} {catalogId}
              {isMaster && <b> M</b>}
            </span>
          ) : (
            orderCode
          )}
        </CodeBlock>

        {user.role === Roles.Admin && attributes.EAN && (
          <AdditionalDescriptionStyled>
            EAN: {attributes.EAN}
          </AdditionalDescriptionStyled>
        )}

        {attributes.IsForProfessionals && (
          <AdditionalDescriptionStyled highlight={true}>
            <FormattedMessage {...messages.isForProfessionals} />
          </AdditionalDescriptionStyled>
        )}

        {attributes.Damage && <Damage damage={attributes.Damage} />}

        {attributes.WithoutCellophane && (
          <AdditionalDescriptionStyled>
            <FormattedMessage {...messages.withoutCellophane} />
          </AdditionalDescriptionStyled>
        )}

        {attributes.DifferentPackaging && (
          <AdditionalDescriptionStyled>
            <FormattedMessage {...messages.differentPackaging} />
          </AdditionalDescriptionStyled>
        )}

        {annotation && annotation.length > 0 && (
          <ExpandableText
            disabled={annotation.length <= 250}
            buttonText={formatMessage(messages.showMoreText)}
            height="75px"
          >
            <AdditionalDescriptionStyled>
              <span>{annotation}</span>
            </AdditionalDescriptionStyled>
          </ExpandableText>
        )}

        {additionalText && (
          <AdditionalText
            dangerouslySetInnerHTML={{ __html: additionalText }}
          />
        )}
      </Wrapper>
    );
  }
);
