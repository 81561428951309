import * as React from 'react';
import { useIntl } from 'react-intl';

import {
  CatalogVariantFragmentFragment,
  EGiftPackagingAllowedType,
} from '@notino/shared/definitions/types';

import { ICampaign } from '@containers/ProductListing/model';

import { ICurrency } from '../../../../App/model';
import messages from '../../../messages';
import { GiftBase } from '../ProductGifts/GiftBase/GiftBase';

interface IFreeGiftPackagingProps {
  campaign: CatalogVariantFragmentFragment['campaignConfig'];
  currency: ICurrency;
  brand: string;
}

export const FreeGiftPackaging: React.FC<IFreeGiftPackagingProps> = React.memo(
  ({ campaign: { allowed, price, groupName }, currency, brand }) => {
    const { formatMessage } = useIntl();

    if (
      allowed === EGiftPackagingAllowedType.None ||
      groupName !== 'GiftPackaging'
    ) {
      return null;
    }

    const descriptionText =
      allowed === EGiftPackagingAllowedType.Product
        ? formatMessage(messages.freeGiftPackagingProductInfo, {
            currency: currency.symbol,
            brand,
            price,
          })
        : formatMessage(messages.freeGiftPackagingBrandInfo, {
            currency: currency.symbol,
            brand,
            price,
          });

    const campaign: ICampaign = {
      imageUrl: 'darkove-krabice/2800018346842_01_small-o.jpg',
      detailPageText: descriptionText,
      giftImageUrl: 'darkove-krabice/2800018346842_01_small-o.jpg',
      displayOnDetailPage: true,
      text: descriptionText,
      name: '',
      campaignId: 'campaignId',
      productId: '',
    };

    return (
      <GiftBase
        containerId="pdFreeGiftPackaging"
        titleMessage={messages.freeGiftPackagingTitle}
        imageMessage={messages.freeGiftPackagingProductInfo}
        campaigns={[campaign]}
      />
    );
  }
);
