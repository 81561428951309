import styled from 'styled-components';

import { breakpoints, Modal, theme } from '@notino/react-styleguide';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 60vh;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
`;

export const ThumbsWrapper = styled.div`
  width: 100%;
  padding: 1rem 2.5rem 1.5625rem;
`;

export const VideoSizeContainer = styled.div`
  flex-basis: 100vh;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @media (min-width: ${breakpoints.md}) {
      background: ${theme.color.background.disabled};
    }
  }
`;

export const GalleryModal = styled(Modal)`
  position: absolute;
  height: 100%;
`;
