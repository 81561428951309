import styled from 'styled-components';

import { theme } from '@notino/react-styleguide';

export const Wrapper = styled.div`
  padding: 0 0 1rem 0;
  ${theme.typography.labelRegular400}
`;

export const StyledAddToWishlist = styled.a`
  display: flex;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
  align-items: center;

  &:hover {
    text-decoration: underline !important;
    svg {
      color: ${theme.color.icon.highlight} !important;
    }
  }
`;
