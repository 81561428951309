import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@apollo/client';

import {
  GetProductByIdsQuery,
  GetProductByIdsQueryVariables,
} from '@notino/shared/definitions/types';

import PriceLabel from '@components/PriceLabel';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';

import messages from '../messages';

import { IModalSubHeader } from './model';
import getProductByIdQuery from './queries/productByIds.graphql';
import {
  SubHeaderWrapper,
  SubHeader,
  InlineBlock,
  PriceWrapper,
  CurrencyWrapper,
  Notes,
} from './styled';

export const ModalSubHeader: React.FC<IModalSubHeader> = ({
  statusMessages,
  products,
}) => {
  const { loading, error, data } = useQuery<
    GetProductByIdsQuery,
    GetProductByIdsQueryVariables
  >(getProductByIdQuery, {
    variables: {
      ids: products.map((product) => String(product.id)),
    },
  });

  const {
    getLeftSidePrice,
    getRightSidePrice,
    leftSideCurrency,
    rightSideCurrency,
    isDualPriceForCroatia,
  } = useIsDualPriceForCroatia();

  if (loading || error || !data || !data.vpProductByIds) {
    return null;
  }

  const { vpProductByIds } = data;

  return (
    <SubHeaderWrapper>
      <SubHeader data-testid="addToCartModal-productInfo">
        {vpProductByIds.map((product, idx) => {
          const count = products[idx].count;
          const priceSum = {
            ...product.price,
            value: product.price.value * count,
          };

          return (
            <div key={product.id}>
              {product.brand && product.brand.name}{' '}
              <strong>{product.name}</strong> {product.annotation}{' '}
              <InlineBlock data-testid="addToCartModal-priceInfo">
                | {count} {product.orderUnit} |{' '}
                {isDualPriceForCroatia ? (
                  <>
                    <PriceLabel
                      price={{ value: getLeftSidePrice(priceSum.value) }}
                      wrapperComponent={PriceWrapper}
                      currencyComponent={CurrencyWrapper}
                      currencySymbolOverride={leftSideCurrency}
                    />
                    <span> / </span>
                    <PriceLabel
                      price={{ value: getRightSidePrice(priceSum.value) }}
                      wrapperComponent={PriceWrapper}
                      currencyComponent={CurrencyWrapper}
                      currencySymbolOverride={rightSideCurrency}
                    />
                  </>
                ) : (
                  <PriceLabel
                    price={priceSum}
                    wrapperComponent={PriceWrapper}
                    currencyComponent={CurrencyWrapper}
                    dataTestId="addToCartModal-price"
                  />
                )}
              </InlineBlock>
            </div>
          );
        })}
      </SubHeader>

      {statusMessages && (
        <Notes>
          {statusMessages.map((messageKey) => (
            <div key={messageKey} data-cypress="addToCartModal-statusMessage">
              <FormattedMessage {...messages[messageKey]} />
            </div>
          ))}
        </Notes>
      )}
    </SubHeaderWrapper>
  );
};
