import * as React from 'react';
import { useLocation } from 'react-router';

import { Redirect } from '@components/HttpContext';
import {
  IProductViewProviderProps,
  ProductViewProvider,
  useProductView,
} from '@context/product-view';

import { ForbiddenProductScreen } from './ForbiddenProductScreen';
import { getRcVersionKeys, IRCVersionKeys } from './getRcVersionKeys';
import { ProductDetail } from './ProductDetail';

const addTrailingSlash = (url: string) => {
  return url.endsWith('/') ? url : `${url}/`;
};

const ProductDetailContainer: React.FC = () => {
  const location = useLocation();
  const { product, variant, data } = useProductView();

  if (!variant) {
    return <Redirect to={`${product.url}${location.search}`} />;
  }

  const pathName = addTrailingSlash(location.pathname);

  if (product.url !== pathName && variant.url !== pathName) {
    return <Redirect to={`${variant.url}${location.search}`} />;
  }

  if (product.isForbiddenToDisplay) {
    return <ForbiddenProductScreen product={product} />;
  }

  const keys: IRCVersionKeys = getRcVersionKeys(location);
  return (
    <ProductDetail
      PDView={data}
      rcKey={keys.rcKey}
      customRcKey={keys.customRcKey}
      customVersionKey={keys.customVersionKey}
    />
  );
};

const WrappedProductDetailContainer: React.FC<IProductViewProviderProps> = (
  props
) => {
  return (
    <ProductViewProvider {...props}>
      <ProductDetailContainer />
    </ProductViewProvider>
  );
};

export default WrappedProductDetailContainer;
