import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const StyledDivider = styled.div<{
  shouldShowOnMobile?: boolean;
}>`
  display: ${({ shouldShowOnMobile }) =>
    shouldShowOnMobile ? 'block' : 'none'};
  height: 0.0625rem;
  background-color: ${theme.color.background.disabled};

  @media (min-width: ${breakpoints.lg}) {
    display: block;
    position: relative;
    clear: both;
    margin: 3rem 0 0;
  }
`;
