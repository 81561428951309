import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const ProgressWrapper = styled.div`
  flex: 1 1;
  padding-right: 0.625rem;
  display: inline-block;
`;

export const RatingsValue = styled.div`
  min-width: 7.5rem;
  display: flex;
`;

export const StarsBox = styled.div`
  display: inline-block;
`;

export const StarsValue = styled.div`
  display: inline-block;
  width: 2.1875rem;
  padding-right: 0.625rem;
  ${theme.typography.labelSmall400};
`;

export const StarReviews = styled.div`
  margin-top: 1.5rem;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 3.125rem;
  }
`;
