import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import PriceLabel from '@components/PriceLabel';

import { useIsDualPriceForCroatia } from '../../hooks/useIsDualPriceForCroatia';

import { useVoucherDiscountContext } from './context';
import { messages } from './messages';
import { Wrapper, CurrencyWrapper, Slash } from './styled';
import { VoucherDiscountInfo } from './VoucherDiscountInfo';

export interface IVoucherDiscount {
  displayType: 'DiscountedPrice' | 'Percentage';
  percentage: number;
  discountedPrice: number;
  code: string;
  productsOnSaleIncluded: boolean;
}

export interface IVoucherDiscountProps {
  voucherDiscount: IVoucherDiscount;
}

export const VoucherDiscount = ({ voucherDiscount }: IVoucherDiscountProps) => {
  const {
    isDualPriceForCroatia,
    getLeftSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
    getRightSidePrice,
  } = useIsDualPriceForCroatia();
  const { currency } = useVoucherDiscountContext();

  const {
    code,
    percentage,
    discountedPrice,
    displayType,
    productsOnSaleIncluded,
  } = voucherDiscount;

  const discountAmountComponentMap: Record<typeof displayType, JSX.Element> = {
    Percentage: (
      <FormattedMessage
        {...messages.discountAmountPercentage}
        values={{
          amount: percentage,
        }}
      />
    ),
    DiscountedPrice: (
      <>
        {isDualPriceForCroatia && (
          <Wrapper>
            {getLeftSideFormattedPrice(discountedPrice)}{' '}
            <CurrencyWrapper>{leftSideCurrency}</CurrencyWrapper>
            <Slash>/</Slash>
          </Wrapper>
        )}
        <PriceLabel
          price={{
            value: isDualPriceForCroatia
              ? getRightSidePrice(discountedPrice)
              : discountedPrice,
            currency: currency.code,
          }}
          wrapperComponent={Wrapper}
          currencyComponent={CurrencyWrapper}
          currencySymbolOverride={
            isDualPriceForCroatia ? rightSideCurrency : undefined
          }
        />
      </>
    ),
  };

  return (
    <VoucherDiscountInfo
      showPercentageIcon={true}
      percentage={percentage}
      discountCode={code}
      infoTooltipText={
        productsOnSaleIncluded ? undefined : (
          <FormattedMessage {...messages.conditionText} />
        )
      }
      instructionsText={<FormattedMessage {...messages.codeInCart} />}
      highlightedDiscountAmount={discountAmountComponentMap[displayType]}
    />
  );
};
