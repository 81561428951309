import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const MainWrapper = styled.div`
  width: 100%;
`;

export const CardsWrapper = styled.div`
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 1rem;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: none;
    margin-bottom: unset;
  }
`;
