import * as React from 'react';
import { useIntl } from 'react-intl';

import {
  GetCatalogProductViewQuery,
  VoucherVariantFeatureFlag,
} from '@notino/shared/definitions/types';

import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useApolloClientHeaders } from '@hooks/useApolloClientHeaders';

import { useResolvedVoucherDiscount } from '../ProductBaseInfo/VoucherDiscount/useResolvedVoucherDiscount';
import { messages } from '../ProductBaseInfo/VoucherDiscount/VoucherDiscountInfo/messages';
import { pictogramMoreInfoPathname } from '../ProductTabContent/Pictograms/messages';

import {
  DermoLink,
  RecomendedByDermatologistsIcon,
  StyledPowerOfBeautyIcon,
  StyledSummerBlackFridayIcon,
} from './styled';
import { VoucherLabel } from './VoucherLabel';

interface IProductPictogramProps {
  attributes: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number]['attributes'];
}

export const ProductPictogram: React.FC<IProductPictogramProps> = ({
  attributes,
}) => {
  const { formatMessage } = useIntl();
  const { voucher_variant } = useFeatureFlags();
  const dermoMoreInfoPathname = formatMessage(pictogramMoreInfoPathname);
  const isFranceShop = useApolloClientHeaders().shopId === 'notino.fr';
  const hasVoucher = !!useResolvedVoucherDiscount({ attributes });

  const shouldShowSummerBlackFriday =
    attributes.VoucherDiscount?.tags.includes('summerblackfriday');

  const showVoucherLabel =
    hasVoucher &&
    voucher_variant === VoucherVariantFeatureFlag.ContrastBackgroundWithLabel;

  return (
    <>
      {showVoucherLabel && <VoucherLabel />}

      {shouldShowSummerBlackFriday && (
        <StyledSummerBlackFridayIcon
          highlightedWordIndex={isFranceShop ? 2 : 0}
          text={formatMessage(messages.sbfLabel)}
          data-testid="summerBlackFridayIcon-pictogram"
        />
      )}

      {attributes?.LorealLabel && (
        <StyledPowerOfBeautyIcon data-testid="powerOfBeauty-pictogram" />
      )}

      {attributes?.Dermo && (
        <DermoLink target="_blank" href={dermoMoreInfoPathname}>
          <RecomendedByDermatologistsIcon data-testid="dermo-pictogram" />
        </DermoLink>
      )}
    </>
  );
};
