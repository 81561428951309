import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { snakeCase } from 'lodash';

import { ModalModel, TextAlign, ModalContext } from '@notino/react-styleguide';
import {
  AvailabilityState,
  CartServiceType,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';
import { AdditionalServicesAvailability, CartType } from '@notino/web-tracking';
import { Ga4Events } from '@notino/web-tracking/dist/types/package/ga4/events';

import ErrorBoundary from '@components/ErrorBoundary';
import { useTrackingDisplayType } from '@containers/ProductDetailContainer/utils';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { useTrackingContext } from '@context/tracking/TrackingContext';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';
import { useAddToCardCloudTracking } from '@hooks/useAddToCardCloudTracking';
import { AddToCartButton } from '@sharedComponents/AddToCartButton/AddToCartButton';

import { ICurrency } from '../../../../App/model';
import { usePriceLogic } from '../../hooks/usePriceLogic';
import {
  MIN_PRICE_PROMO_LABEL,
  RRP_PRICE_PROMO_LABEL,
} from '../../hooks/usePriceLogic/tracking';
import { IModifaceEffectVariants } from '../../ModiFaceModal/model';
import { trackPageReload } from '../../tracking';
import { ClickAndCollect } from '../ClickAndCollect';
import { useEngravingTryItFirstContext } from '../context/EngravingTryItFirst';
import { getRenderableDiscoBoxSampleId } from '../DiscoveryBoxSelfChoice';
import { useSkinAnalyzerVisibility } from '../SkinAnalyser/hooks/useAnalyzerVisibility';

import { messages } from './addToCartMessages';
import {
  ButtonWrapper,
  BuyButtons,
  Label,
  DropdownButtonSeparator,
  QuantityWrapper,
  AddToCartWrapper,
  ClickAndCollectWrapper,
  StyledQuantitySelector,
} from './styled';
import { trackEngravingAddToCart } from './tracking';
import { prepareOptions } from './utils/prepareOptions';

interface IAddToCartProps {
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'];
  variant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number];
  orderUnit: string;
  productCode: string;
  currency: ICurrency;
  onProductAdded: () => void;
  modifaceVariants: IModifaceEffectVariants;
  engravingAvailable: AdditionalServicesAvailability;
  tryItFirstAvailable: AdditionalServicesAvailability;
}

const QUANTITY_SELECTOR_KEYBOARD_CONFIG = {
  keyNavigation: true,
};

export const AddToCart: React.FC<IAddToCartProps> = React.memo(
  ({
    variant,
    product,
    orderUnit,
    onProductAdded,
    modifaceVariants,
    engravingAvailable,
    tryItFirstAvailable,
  }) => {
    const {
      state: { engravingInitials, withEngraving, withTryItFirst },
    } = useEngravingTryItFirstContext();
    const { hideModal, toggleModal } = ModalContext.useModalContext();
    const { getTimeFromInit } = useTrackingContext();
    const { formatMessage } = useIntl();
    const [quantity, setQuantity] = React.useState(1);
    const { rrpShown, minimalPriceShown } = usePriceLogic(variant);
    const featureFlags = useFeatureFlags();

    const { shouldShowSkinAnalyzer } = useSkinAnalyzerVisibility();

    const addToCardCloudTracking = useAddToCardCloudTracking();

    const trackingDisplayType = useTrackingDisplayType(product);

    const onModalClose = React.useCallback(() => {
      setQuantity(1);
      hideModal();
    }, [hideModal]);

    const changeAmount = React.useCallback(
      (newQuantity: number): void => {
        if (withEngraving && newQuantity > 1) {
          const modalOptions = {
            header: (
              <div data-cypress="engraving-maxOrderQuantity">
                {formatMessage(messages.engravingMaxOrderQtyHeader, {
                  quantity: 1,
                  unit: orderUnit,
                })}
              </div>
            ),
            type: ModalModel.Types.default,
            onClose: onModalClose,
          };

          const modalContent = (
            <FormattedMessage {...messages.engravingMaxOrderQtyDesc} />
          );

          toggleModal(modalContent, modalOptions);
        }
        setQuantity(newQuantity);
      },
      [formatMessage, onModalClose, orderUnit, toggleModal, withEngraving]
    );

    const handleGtmPushProductDetail = React.useCallback(() => {
      trackPageReload({
        rrpShown,
        minimalPriceShown,
        variant,
        product,
        modifaceVariants,
        engravingAvailable,
        tryItFirstAvailable,
        trackingDisplayType,
        featureFlags,
        skinAnalyzer: shouldShowSkinAnalyzer,
      });
    }, [
      variant,
      product,
      modifaceVariants,
      engravingAvailable,
      tryItFirstAvailable,
      rrpShown,
      minimalPriceShown,
      trackingDisplayType,
      featureFlags,
      shouldShowSkinAnalyzer,
    ]);

    const handleQuantitySelectorClick = () => {
      dispatchTrackingEvent({
        event: 'element_click',
        element: {
          timing: getTimeFromInit(),
          interaction: 'click',
          mode: undefined,
          name: 'quantity_selector',
          type: 'product_detail',
          action: 'click_on_element',
          promo_labels: undefined,
        },
        _clear: true,
      });
    };

    const services = React.useMemo(
      () =>
        withEngraving && [
          {
            type: CartServiceType.Engraving,
            value: engravingInitials,
            count: quantity,
            productId: variant.webId,
          },
        ],
      [withEngraving, engravingInitials, quantity, variant.webId]
    );

    const trackingProduct: Extract<
      Ga4Events,
      { event: 'add_to_cart' }
    >['product'] = React.useMemo(
      () =>
        ProductEventWither()
          .withProduct(product)
          .withVariant(variant)
          .withServices({
            modifaceVariants,
            tryItFirstAvailable,
            engravingAvailable,
            skinAnalyzer: shouldShowSkinAnalyzer,
            discoveryBoxAvailable: Boolean(
              getRenderableDiscoBoxSampleId(product, variant, featureFlags)
            ),
          })
          .withAdditionalData({ quantity, cart_type: snakeCase(CartType.cart) })
          .withAdditionalPromoLabels([
            rrpShown && RRP_PRICE_PROMO_LABEL,
            minimalPriceShown && MIN_PRICE_PROMO_LABEL,
          ])
          .build(),
      [
        engravingAvailable,
        tryItFirstAvailable,
        product,
        minimalPriceShown,
        rrpShown,
        modifaceVariants,
        quantity,
        variant,
        featureFlags,
        shouldShowSkinAnalyzer,
      ]
    );

    const handleProductAdded = React.useCallback(() => {
      onProductAdded();

      const additionalPromoLabels = [
        rrpShown && RRP_PRICE_PROMO_LABEL,
        minimalPriceShown && MIN_PRICE_PROMO_LABEL,
      ];
      dispatchTrackingEvent({
        event: 'add_to_cart',
        product: trackingProduct,
        _clear: true,
      });

      addToCardCloudTracking({
        quantity,
        productCode: variant.productCode,
      });

      if (withEngraving) {
        trackEngravingAddToCart(
          product,
          variant,
          quantity,
          additionalPromoLabels
        );
      }
    }, [
      onProductAdded,
      rrpShown,
      minimalPriceShown,
      trackingProduct,
      addToCardCloudTracking,
      withEngraving,
      product,
      variant,
      quantity,
    ]);

    const trackAddFailed = React.useCallback(
      (message: string) => {
        dispatchTrackingEvent({
          event: 'add_to_cart_failed',
          add: {
            products: [trackingProduct],
          },
          error: {
            status: message || '',
          },
          _clear: true,
        });
      },
      [trackingProduct]
    );

    const canBuy = variant.availability.state === AvailabilityState.CanBeBought;
    const isClickAndCollect = variant.availability.availablePickUpStores > 0;

    return (
      <ErrorBoundary>
        <BuyButtons
          id="pdAddToCart"
          data-testid="pdAddToCart"
          isClickAndCollect={isClickAndCollect}
          canBuy={canBuy}
        >
          {canBuy && (
            <AddToCartWrapper isClickAndCollect={isClickAndCollect}>
              <Label>
                <FormattedMessage {...messages.addToCartQuantity} />
              </Label>
              <QuantityWrapper onClick={handleQuantitySelectorClick}>
                <StyledQuantitySelector
                  options={prepareOptions(
                    variant.availability.maxOrderQuantity
                  )}
                  disableSelectWhenSingleOption={true}
                  handleOptionSelect={changeAmount}
                  currentOption={quantity}
                  textAlign={TextAlign.center}
                  keyNavigationConfig={QUANTITY_SELECTOR_KEYBOARD_CONFIG}
                  selectDataTestId="selected-value-quantity"
                />
              </QuantityWrapper>
              <DropdownButtonSeparator />
              <AddToCartButton
                product={{
                  ...variant,
                  id: variant.webId,
                  masterId: product.webMasterId,
                }}
                count={quantity}
                services={services}
                buttonWrapper={ButtonWrapper}
                showAddToCartModal={product.config.showAddToCartModal}
                buttonElementId="pd-buy-button"
                onProductAdded={handleProductAdded}
                onProductAddFailed={trackAddFailed}
                onClosingModal={handleGtmPushProductDetail}
                withLegacyAddToCart={true}
              />
            </AddToCartWrapper>
          )}
          {isClickAndCollect && (
            <ClickAndCollectWrapper isClickAndCollect={isClickAndCollect}>
              <ClickAndCollect
                selectedVariant={variant}
                product={product}
                onModalClosing={handleGtmPushProductDetail}
                disabled={withEngraving || withTryItFirst || quantity > 1}
              />
            </ClickAndCollectWrapper>
          )}
        </BuyButtons>
      </ErrorBoundary>
    );
  }
);

AddToCart.displayName = 'AddToCart';
