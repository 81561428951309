import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ButtonModel,
  ModalContext,
  InputError,
} from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';

import { IActions } from '../context/EngravingTryItFirst/model';

import { messages } from './engravingMessages';
import {
  StyledInput,
  StyledButton,
  ModalBody,
  ImageText,
  EngravingFlex,
  EngravingImage,
  EngravingBody,
  H3,
  BodyWrapper,
  ImageMaxText,
  InputErrorWrapper,
} from './styled';
import { useEngravingContent } from './useEngravingContent';
import { resolveEngravingImageAttribute } from './utils';

export interface IEngravingModalProps {
  imageAlt: string;
  code: string;
  maxChars: number;
  image?: string;
  engravingInitials: string;
  changeEngravingInitials: IActions['changeEngravingInitials'];
}

export const EngravingModal: React.FC<IEngravingModalProps> = ({
  imageAlt,
  image,
  maxChars,
  code,
  engravingInitials,
  changeEngravingInitials,
}) => {
  const { hideModal } = ModalContext.useModalContext();

  const { handleInitialsChange, handleSave, initials, invalid } =
    useEngravingContent({
      onContentClose: hideModal,
      engravingInitials,
      changeEngravingInitials,
    });

  return (
    <ModalBody>
      <EngravingFlex data-cypress="engraving-modal">
        <EngravingBody>
          <BodyWrapper>
            <H3>
              <FormattedMessage {...messages.engravingYourText} />
            </H3>
            <ImageMaxText>
              <FormattedMessage
                {...messages.engravingMaxCharactersv2}
                values={{ maxChars }}
              />
            </ImageMaxText>
            <form onSubmit={handleSave}>
              <StyledInput
                type="text"
                placeholder="LOVE"
                maxLength={maxChars}
                onChange={handleInitialsChange}
                value={initials}
                invalid={invalid}
              />
              <StyledButton
                type="submit"
                buttonStyle={ButtonModel.Styles.primary}
              >
                <FormattedMessage {...messages.engravingSave} />
              </StyledButton>
              <InputErrorWrapper>
                {invalid && (
                  <InputError>
                    <FormattedMessage
                      {...messages.engravingInvalidCharacters}
                    />
                  </InputError>
                )}
              </InputErrorWrapper>
            </form>
            <ImageText>
              <FormattedMessage {...messages.engravingPreview} />
            </ImageText>
          </BodyWrapper>
        </EngravingBody>
        <EngravingImage>
          {image ? (
            <CdnImage src={image} alt={imageAlt} sourceKey="engraving" />
          ) : (
            <img {...resolveEngravingImageAttribute(code)} alt={imageAlt} />
          )}
        </EngravingImage>
      </EngravingFlex>
    </ModalBody>
  );
};
