import { GetItemDataByIdQuery as IgetItemDataById } from '@notino/shared/definitions/types';

import getItemDataByIdQuery from '../ProductItem/queries/itemDataById.graphql';

import { ICallbackParameters } from './model';

export const updateWishlistedCache = ({
  cache,
  wishlisted,
  productId,
}: ICallbackParameters & { productId: string }) => {
  cache.updateQuery<IgetItemDataById>(
    {
      query: getItemDataByIdQuery,
      variables: { id: productId.toString() },
    },
    (prev) => ({
      vpProductById: {
        ...prev?.vpProductById,
        wishlisted,
      },
    })
  );
};
