import * as React from 'react';

import { ModalContext } from '@notino/react-styleguide';

import useFeatureSwitches from '@hooks/useFeatureSwitches';
import { ModalSubHeader } from '@sharedComponents/AddToCartButton/AddToCartModal/ModalSubHeader';
import {
  useAddToCartActions,
  useAddToCartButtonContext,
  useAddToCartSubmitAttempts,
} from '@sharedComponents/AddToCartButton/context';
import { IModal } from '@sharedComponents/AddToCartButton/context/model';
import { ProductWithCount } from '@sharedComponents/AddToCartButton/model';
import { IBuyButtonProps } from '@sharedComponents/AddToCartButton/sharedComponents/BuyButton';
import { useSystemOverloadedModal } from '@sharedComponents/AddToCartButton/SystemOverloadedModal';
import { wait } from '@utils/wait';

import { updateHeaderCartOverview } from '../utils';
import { addToCart } from '../utils/addToCart';
import { addToCartResult } from '../utils/addToCartResult';

type UseHandleAddToCartPropsType = Pick<IBuyButtonProps, 'isOnSpecialModal'>;

type IHandleAddToCart = {
  products?: ProductWithCount[];
};

export const useHandleAddToCart = ({
  isOnSpecialModal,
}: UseHandleAddToCartPropsType = {}) => {
  const {
    apollo: { addToCartMutation },
    props: {
      showAddToCartModal,
      disableRedirect,
      product,
      onProductAdded,
      onProductAddFailed,
      count,
      services,
      onClosingModal,
    },
  } = useAddToCartButtonContext();
  const submitAttempts = useAddToCartSubmitAttempts();

  const {
    handleProductAddedToCart,
    handleIncreaseSubmitAttempts,
    handleFailedAddToCart,
  } = useAddToCartActions();

  const { addToCartWarningCount, addToCartResendDelay } = useFeatureSwitches();

  const { hideModal } = ModalContext.useModalContext();
  const showSystemOverloadedModal = useSystemOverloadedModal();

  const handleAddToCart = React.useCallback(
    async ({ products: _products }: IHandleAddToCart) => {
      handleIncreaseSubmitAttempts();

      if (submitAttempts > 0 && addToCartResendDelay > 0) {
        await wait(addToCartResendDelay);
      }
      const products = _products || [{ ...product, count }];
      const addToCartResponse = await addToCart({
        addToCartMutation,
        products,
        services,
      });

      if (isOnSpecialModal) {
        hideModal();
      }

      if (addToCartResponse.success) {
        const { response } = addToCartResponse;
        const result = addToCartResult({
          response,
          showAddToCartModal,
          disableRedirect,
          products,
        });

        if (result.success) {
          await Promise.resolve(onProductAdded(products));
        } else {
          await Promise.resolve(onProductAddFailed(result.errorCode, products));
        }

        updateHeaderCartOverview({
          cartId: response.productAddResponse[0].cart.cartId,
          cartSettings: result.cartSettings,
        });

        let modal: IModal = null;
        if (result.notification) {
          modal = {
            type: result.notification.modalType,
          };
        } else if (showAddToCartModal) {
          modal = {
            type: 'upselling',
            onClosingModal,
            subHeader: (
              <ModalSubHeader
                statusMessages={result.statusMessages}
                products={products}
              />
            ),
            onClose: () => {
              onClosingModal();
              hideModal();
            },
          };
        }

        handleProductAddedToCart({
          result,
          products,
          modal,
        });
      } else {
        handleFailedAddToCart();
        await Promise.resolve(
          onProductAddFailed(addToCartResponse.error, products)
        );
        if (
          submitAttempts >= addToCartWarningCount &&
          addToCartWarningCount > 0
        ) {
          showSystemOverloadedModal();
        }
      }
    },
    [
      handleIncreaseSubmitAttempts,
      submitAttempts,
      addToCartResendDelay,
      product,
      count,
      addToCartMutation,
      services,
      isOnSpecialModal,
      hideModal,
      showAddToCartModal,
      disableRedirect,
      handleProductAddedToCart,

      onProductAdded,
      onProductAddFailed,
      onClosingModal,
      handleFailedAddToCart,
      addToCartWarningCount,
      showSystemOverloadedModal,
    ]
  );

  const handleAddToCartEvent = React.useCallback(
    async (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      await handleAddToCart({ products: undefined });
    },
    [handleAddToCart]
  );

  return {
    handleAddToCartEvent,
    handleAddToCart,
  } as const;
};
