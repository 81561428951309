import { PathTemplate } from '@notino/shared/definitions/types';

import { ComponentType } from '@components/WebView/utils';

// needed for wishlist -> pd spa redirect
const pdSkeletonPrefixes = ['pd.about', 'pd.code', 'pd.availability'];

export const prefixes = {
  [PathTemplate.ProductDetailSimple]: [
    'pd',
    'attribute',
    'universals',
    'product',
    'brand',
    'reviews',
    'rating',
    'order',
    'relatedProducts',
    'global',
    'engraving',
    'try.it.first',
    'watchdog',
    'modiface',
  ],
  [PathTemplate.Error]: ['pd'],
  [PathTemplate.Tryandbuy]: ['tnb'],
  [PathTemplate.Wishlist]: [
    'wishlist',
    'universals',
    'attribute',
    'reviews',
    'product',
    'pd.summer.black.friday',
    'pd.black.friday',
    'watchdog',
    'global',
    ...pdSkeletonPrefixes,
  ],
  [PathTemplate.ReviewsPage]: [
    'pd',
    'reviews',
    'rating',
    'order',
    'global',
    'universals',
    'attribute',
    'product.item.discount',
  ],
  // We use component type for development purposes
  [ComponentType.AdventCalendar]: ['adventcalendar'],
  [ComponentType.GiftWrapper]: ['gifts'],
  [ComponentType.TryAndBuy]: ['tryandbuy', 'tnb', 'global'],
};
