import { defineMessages } from 'react-intl';

export default defineMessages({
  youSave: {
    id: 'pd.youSave',
    defaultMessage: 'Ušetříte',
  },
  betterOffer: {
    id: 'pd.betterOffer',
    defaultMessage: 'Výhodneji o {percentage}%',
  },
  recommendedPrice: {
    id: 'pd.price.recommended',
    defaultMessage: 'Tuto cenu doporučuje dodavatel produktu. {link}',
  },
  recommendedPriceLink: {
    id: 'pd.price.recommended.link',
    defaultMessage: 'null',
  },
  convertedPrice: {
    id: 'pd.price.converted',
    defaultMessage:
      'Cena doporučená dodavatelem a přepočtená z cizí měny. {link}',
  },
  convertedPriceLink: {
    id: 'pd.price.converted.link',
    defaultMessage: 'null',
  },
  historicalPrice: {
    id: 'pd.price.historical',
    defaultMessage:
      'Maximální cena, za kterou se produkt nabízel v posledních dvou letech. {link}',
  },
  historicalPriceLink: {
    id: 'pd.price.historical.link',
    defaultMessage: 'null',
  },
  recentPrice: {
    id: 'pd.price.recent',
    defaultMessage:
      'Nejnižší cena, za kterou se produkt nabízel v posledním měsíci. {link}',
  },
  recentPriceLink: {
    id: 'pd.price.recent.link',
    defaultMessage: 'null',
  },
  priceLinkText: {
    id: 'pd.price.link.text',
    defaultMessage: 'Více informací',
  },
});
