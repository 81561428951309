import * as React from 'react';

import {
  PictogramRegularPercentageDiscount,
  theme,
} from '@notino/react-styleguide';

import { useProductDetailContext } from '@containers/ProductDetailContainer/ProductDetail/ProductDetailContext';

import { useVoucherDiscountContext } from '../context';

import { IProductDiscountProps, ProductDiscount } from './ProductDiscount';
import {
  VoucherDiscountInfoWrapper,
  VoucherDiscountIconWrapper,
} from './styled';

export interface IVoucherDiscountInfoProps extends IProductDiscountProps {
  percentage: number;
  showPercentageIcon: boolean;
}

export const VoucherDiscountInfo: React.FC<IVoucherDiscountInfoProps> = ({
  showPercentageIcon,
  percentage,
  ...props
}) => {
  const { isUnderSelectBox, isArrowHidden, hasContrastBg } =
    useVoucherDiscountContext();
  const { currentVariant } = useProductDetailContext();

  const hasBlackFridayDiscount =
    currentVariant.attributes?.VoucherDiscount?.tags?.includes(
      'summerblackfriday'
    ) ||
    currentVariant.attributes?.VoucherDiscount?.tags?.includes('blackfriday');

  const iconBgColor =
    hasBlackFridayDiscount || hasContrastBg
      ? theme.color.background.inverse
      : theme.color.background.highlight;

  return (
    <VoucherDiscountInfoWrapper
      showArrow={!isArrowHidden}
      isUnderSelectBox={isUnderSelectBox}
      hasContrastBg={hasContrastBg}
    >
      <ProductDiscount {...props} />

      {showPercentageIcon && (
        <VoucherDiscountIconWrapper data-testid="voucher-discount-icon">
          <PictogramRegularPercentageDiscount
            percentage={percentage}
            bgColor={iconBgColor}
          />
        </VoucherDiscountIconWrapper>
      )}
    </VoucherDiscountInfoWrapper>
  );
};
