import * as React from 'react';

import { ProductLabelModel } from '@notino/react-styleguide';
import { IStock } from '@notino/shared/definitions/custom-definitions';

import { useTagAttributes } from '@components/Universals/ProductItem/useTagAttributes';

import { FormattedTooltip } from './FormattedTooltip';
import { ITagsProps } from './model';
import { StyledLabel, StyledTagWrapper } from './styled';

export const Tags: React.FC<ITagsProps> = ({
  type = ProductLabelModel.Types.default,
  onClick,
  nowrapLg = false,
  ...props
}) => {
  const tagAttributes = useTagAttributes(props.attributes, {
    multiple: props?.multiple,
  });
  if (props.stockAvailability === IStock.outOfStock || !tagAttributes) {
    return null;
  }

  return (
    <StyledTagWrapper nowrapLg={nowrapLg}>
      {tagAttributes.map(
        ({ style, icon, tooltipMessage, label, attribute }) => (
          <FormattedTooltip
            key={label}
            wrapperEnabled={
              !props.small &&
              type === ProductLabelModel.Types.default &&
              !!tooltipMessage
            }
            tooltipMessage={tooltipMessage}
          >
            <StyledLabel
              onClick={() => onClick?.(attribute)}
              key={label}
              labelStyle={style}
              icon={icon}
              labelType={type}
              className={`pd-variant-tag-${label}`}
            >
              {label}
            </StyledLabel>
          </FormattedTooltip>
        )
      )}
    </StyledTagWrapper>
  );
};
