import styled from 'styled-components';

import { Row, Col } from '@notino/react-styleguide';

interface IRatingRow {
  isFirst: boolean;
}

export const RatingRow = styled.div<IRatingRow>`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  height: 1.875rem;
  margin-top: ${(props) => (props.isFirst ? '-0.6875rem' : '0')};
`;

export const RatingsValue = styled.div`
  min-width: 7.5rem;
  display: flex;
`;

export const ShowLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.925rem;
  margin: 1.25rem 0;
`;

export const StyledRow = styled(Row)`
  display: flex !important;
  margin: 0;
`;

export const StyledCol = styled(Col)`
  padding: 0;
`;
