export const subtractArrays = <T>(arr1: T[], arr2: T[]) =>
  arr1.filter((item) => !arr2.includes(item));

/**
 * Partitions an array by a given key
 * @param arr
 * @param partitionBy
 * @param groups
 *
 * @example
 * const arr = [
 *  { id: 1, name: 'a' },
 *  { id: 2, name: 'b' },
 *  { id: 3, name: 'c' },
 *  { id: 4, name: 'a' },
 * ];
 * partition(arr, 'name', ['a', 'b']);
 * // [
 * //   [ { id: 1, name: 'a' }, { id: 4, name: 'a' } ],
 * //   [ { id: 2, name: 'b' } ],
 * // ]
 */
export function partition<T, Key extends keyof T>(
  arr: T[],
  partitionBy: Key,
  groups: Array<T[Key]>
): Array<T[]> {
  const retArray: Array<T[]> = [];

  for (const group of groups) {
    const groupArray: T[] = [];
    for (const item of arr) {
      if (item[partitionBy] === group) {
        groupArray.push(item);
      }
    }
    retArray.push(groupArray);
  }

  return retArray;
}
