import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  code: {
    id: 'pd.discount.code',
    defaultMessage: 'notino',
  },
  codeInCart: {
    id: 'pd.discount.code.in.cart',
    defaultMessage: 'Kód zadejte v košíku',
  },
  conditionText: {
    id: 'pd.discount.condition.text',
    defaultMessage: 'Nevztahuje se na zboží v akci a ve výprodeji',
  },
  conditionalTooltipText: {
    id: 'pd.discount.conditionalTooltip.text',
    defaultMessage:
      'Kód zadejte prosím v košíku. Voucher se nevztahuje na zboží v akci a ve výprodeji.',
  },
  conditionalTooltipSaleIncludedText: {
    id: 'pd.discount.conditionalTooltip.saleIncluded.text',
    defaultMessage: 'Kód zadejte prosím v košíku.',
  },
  withCode: {
    id: 'pd.discount.description.withCode',
    defaultMessage: 's kódem {code}',
  },
  discountAmountPercentage: {
    id: 'product.item.discount.highlighted',
    defaultMessage: '-{amount} %',
  },
  discountAmountAbsolute: {
    id: 'product.item.discount.highlighted.absolute',
    defaultMessage: '{amountPrice} sleva',
  },
  copyCode: {
    id: 'pd.discount.copyToClipboard.tooltip.copyCode',
    defaultMessage: 'Zkopírovat kód ',
  },
  codeCopied: {
    id: 'pd.discount.copyToClipboard.tooltip.codeCopied',
    defaultMessage: 'Kód zkopírovan!',
  },
});
