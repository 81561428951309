import styled from 'styled-components';

import { CurrencyStyled } from '@components/PriceLabel/components/styled';

export const PriceWrapper = styled.span`
  font-size: inherit;
`;

export const CurrencyWrapper = styled(CurrencyStyled)`
  font-size: inherit;
`;
