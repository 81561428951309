import styled from 'styled-components';

import { breakpoints, Col, theme } from '@notino/react-styleguide';

export const StyledCol = styled(Col)`
  padding: 0;
`;

export const ThumbsWrapper = styled.div`
  max-width: 20rem;
  margin: 0 auto;
`;

export const MainImgWrapper = styled.div`
  position: relative;
  display: none;

  width: 21.875rem;
  max-width: 100%;
  height: 21.875rem;

  margin: 0 auto;
  text-align: center;

  height: 70vh;
  max-height: 46.875rem;
  width: 100%;
  cursor: zoom-in;
  background-color: ${theme.color.background.disabled};
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

export const PictogramWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 0.25rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  z-index: 1;

  @media (min-width: ${breakpoints.md}) {
    top: 1.25rem;
    left: 1rem;
  }
`;

export const Container = styled.div<{
  brandLogo: boolean;
}>`
  width: 100%;
  position: relative;
  text-align: center;
  margin-top: 1.25rem;
  height: auto;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  mix-blend-mode: multiply;
  > img {
    max-height: 25rem;
    @media (min-width: ${breakpoints.lg}) {
      max-height: min(85%, 28.125rem);
      max-width: min(85%, 28.125rem);
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    max-width: 100%;
  }

  &:hover {
    cursor: pointer;
    svg {
      opacity: 0.95;
    }
  }

  svg {
    opacity: 0.8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
