/*
 * Global messages
 */
import { defineMessages } from 'react-intl';
export default defineMessages({
  globalGDPRLinkText: {
    id: 'global.gdpr.link.text',
    defaultMessage: 'zásadách zpracování osobních údajů',
  },
  globalGDPRLink: {
    id: 'global.gdpr.link',
    defaultMessage: '/ochrana-osobnich-udaju/',
  },
  globalTACLink: {
    id: 'global.tac.link',
    defaultMessage: '/obchodni-podminky/',
  },
  orderUnit: {
    id: 'global.orderUnit',
    defaultMessage: 'ks',
  },
});
