import { useEffect } from 'react';

import { useOpenWatchdogModal } from '@sharedComponents/Watchdog/useOpenWatchdogModal';

export const useAutoOpenWatchdogModal = (
  variant: Parameters<typeof useOpenWatchdogModal>[0]['variant']
) => {
  const showWatchdogModal = useOpenWatchdogModal({ variant });

  useEffect(() => {
    if (window.location.hash === '#watchdog') {
      showWatchdogModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
