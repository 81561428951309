import React, { useCallback } from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import {
  IItem,
  useInitBambuserLiveShopping,
} from '@components/LivestreamBanner/hooks/useInitBambuserLiveShopping';
import {
  EShowComponentLayout,
  ILivestream,
} from '@components/LivestreamBanner/model';
import { getShowElementId } from '@components/LivestreamBanner/utils/getShowElementId';

import { LivestreamCard } from './components/LivestreamCard';
import { MainWrapper, CardsWrapper } from './styled';

interface ILivestreamBannersProps {
  livestreams: ILivestream[];
}

const LivestreamBanners: ReactFCC<ILivestreamBannersProps> = ({
  livestreams,
}) => {
  useInitBambuserLiveShopping({
    getItems: useCallback(
      () =>
        livestreams
          ? livestreams.flatMap<IItem>((livestream) => [
              {
                showId: livestream.showId,
                deeplink: livestream.deeplink,
                node: document.getElementById(
                  getShowElementId(
                    livestream.showId,
                    EShowComponentLayout.MOBILE
                  )
                ),
              },
            ])
          : [],
      [livestreams]
    ),
  });

  return livestreams && livestreams.length > 0 ? (
    <MainWrapper>
      <CardsWrapper>
        {livestreams.map((livestream, index) => {
          return (
            <LivestreamCard
              key={livestream.showId}
              {...livestream}
              index={index}
            />
          );
        })}
      </CardsWrapper>
    </MainWrapper>
  ) : null;
};

export default LivestreamBanners;
