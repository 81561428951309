import { useResolvedVoucherDiscount } from './useResolvedVoucherDiscount';
import { isConditionalDiscount } from './utils';

/**
 * Determines if we want to display price in the voucher bigger than main product price
 */
export const useHasStressOnVoucherDiscount = () => {
  const voucherDiscount = useResolvedVoucherDiscount();

  if (!voucherDiscount) {
    return false;
  }

  const isMultiTierDiscount =
    isConditionalDiscount(voucherDiscount) &&
    voucherDiscount.discountConditions.length > 1;

  if (isMultiTierDiscount) {
    return true;
  }

  const isAbsoluteConditionalDiscount =
    isConditionalDiscount(voucherDiscount) &&
    voucherDiscount.discountType === 'Absolute';

  return !isAbsoluteConditionalDiscount;
};
