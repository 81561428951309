export enum Tabs {
  Description = 'description',
  // eslint-disable-next-line no-shadow
  RelatedArticles = 'relatedArticles',
  // eslint-disable-next-line no-shadow
  AboutBrand = 'aboutBrand',
  Livestreams = 'livestreams',
  RichContent = 'richContent',
}

export enum TabIds {
  Description = 1,
  Composition = 2,
  RelatedArticles = 3,
  AboutBrand = 4,
  RichContent = 5,
  Reviews = 6,
  Other = 7,
  Livestreams = 8,
}

export enum BrandsId {
  DIOR = '150',
  Roche = '222',
  Estee = '44',
}
