import { Action, ActionTypes, IEngravingTryItFirstState } from './model';

export const initialState: IEngravingTryItFirstState = {
  withEngraving: false,
  withTryItFirst: false,
  engravingInitials: '',
};

export const reducer = (
  state: IEngravingTryItFirstState,
  action: Action
): IEngravingTryItFirstState => {
  switch (action.type) {
    case ActionTypes.CHANGE_ENGRAVING_INITIALS:
      return {
        ...state,
        withEngraving: action.value.length > 0,
        engravingInitials: action.value,
        withTryItFirst: false,
      };
    case ActionTypes.TOGGLE_TRY_IT_FIRST:
      return {
        ...state,
        withTryItFirst: action.value,
        withEngraving: false,
      };
    case ActionTypes.CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
