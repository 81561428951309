import styled, { css } from 'styled-components';

import { breakpoints, Button } from '@notino/react-styleguide';

export const Container = styled.div`
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 2rem;
  }
`;

export const ButtonsWrapper = styled.div<{ canBuy?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ canBuy }) =>
    canBuy &&
    css`
      @media (min-width: ${breakpoints.lg}) {
        flex-direction: row;
      }
    `}
`;

export const LeftIconWrapper = styled.div`
  align-self: flex-start;
  padding-right: 1rem;
  width: 2.25rem;
  line-height: 0;
`;

export const StyledWatchdogButton = styled(Button)`
  text-transform: none !important;
  font-weight: normal !important;
`;

export const WatchdogWrapper = styled.div`
  text-align: center;
  align-self: flex-start;
  margin: 1rem 0;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;

export const DiscountWrapper = styled.div`
  margin-top: 0.75rem;
`;

export const SkinAnalyzerWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;
