import * as React from 'react';
import { BottomSheet as SpringBottomSheet } from 'react-spring-bottom-sheet';

import { InteractiveArea } from '@notino/react-styleguide';

import {
  CloseButton,
  AbsoluteInteractiveArea,
  DrawerTitle,
  Header,
  StyledBottomSheet,
} from './styled';
import {
  DRAWER_CLOSE_BUTTON_AREA_CLASS,
  DrawerCloseType,
  useCloseType,
} from './useCloseType';

type Props = Omit<
  React.ComponentProps<typeof SpringBottomSheet>,
  'onDismiss'
> & {
  onDismiss?: (closeType: DrawerCloseType) => void;
  ignoreBodySwipe?: boolean;
};

const BottomSheet = (props: Props) => {
  // hack for not being able to configure animation duration
  // should be fixed when this PR is merged https://github.com/stipsan/react-spring-bottom-sheet/pull/260
  const [shouldRender, setShouldRender] = React.useState(true);

  const { getCloseType } = useCloseType(props.open);

  const handleDismiss = () => {
    if (!props.onDismiss) {
      return;
    }
    props.onDismiss(getCloseType());
    setTimeout(() => {
      setShouldRender(false);
      setTimeout(() => setShouldRender(true), 1);
    }, 500);
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <StyledBottomSheet
      {...props}
      {...(props.ignoreBodySwipe && { 'data-body-scroll-lock-ignore': 'true' })}
      initialFocusRef={false}
      onDismiss={handleDismiss}
      header={
        props.header && (
          <Header>
            {/* dummy element to keep header centered */}
            <CloseButton style={{ visibility: 'hidden' }} />

            {typeof props.header === 'string' ? (
              <DrawerTitle>{props.header}</DrawerTitle>
            ) : (
              props.header
            )}

            <InteractiveArea
              className={DRAWER_CLOSE_BUTTON_AREA_CLASS}
              size="44px"
              onClick={handleDismiss}
            >
              <CloseButton />
            </InteractiveArea>
          </Header>
        )
      }
    >
      {!props.header && (
        <AbsoluteInteractiveArea
          className={DRAWER_CLOSE_BUTTON_AREA_CLASS}
          size="44px"
          onClick={handleDismiss}
        >
          <CloseButton />
        </AbsoluteInteractiveArea>
      )}
      {props.children}
    </StyledBottomSheet>
  );
};

export default BottomSheet;
