import styled from 'styled-components';

import { breakpoints } from '@notino/react-styleguide';

export const Wrapper = styled.div`
  margin-top: 1.25rem;
  position: relative;
  overflow: hidden;
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 1rem;
    display: block;
  }
`;

export const ImageWrapper = styled.div`
  flex-basis: 6rem;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  gap: 1rem;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
