export const SENTRY_IGNORE_ERROR_RULES: string[] = [
  'top.GLOBALS',
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'atomicFindClose',
  'fb_xd_fragment',
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  'conduitPage',
];

export const SENTRY_IGNORE_URL_RULES: RegExp[] = [
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /extensions\//i,
  /^chrome:\/\//i,
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /notinopbx\.daktela\.com\//i,
  /\/external\/web\/web\.js/i,
  /\/member\/publisherBookmarklet\.js/i,
  /\/files\/modiface\//i,
  /\/v2\/braintree\.js/i,
  /\/js\/exponea\.min\.js/i,
  /gtag\/js/i,
];
