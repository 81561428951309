import styled, { css } from 'styled-components';

import { keyframes, breakpoints, theme } from '@notino/react-styleguide';

import { IOpenedProps } from '../../../../model';

const openingMD = keyframes`
  from {
    max-height: 0;
    overflow: hidden;
  }
  to {
    max-height: 21.875rem;
    overflow: auto;
  }
`;

const opening = keyframes`
  from {
    transform: translateY(100%);
    overflow: hidden;
  }
  to {
    transform: translateY(0);
    overflow: auto;
  }
`;

const closingMD = keyframes`
  from {
    max-height: 21.875rem;
  }
  to {
    max-height: 0;
  }
`;

const closing = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const OpenedVariants = styled.div<IOpenedProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: ${theme.color.background.primary};
  z-index: 10002; /* daktela is 10001 */
  overflow: auto;
  animation-duration: 0.6s;
  animation-name: ${(props) => (props.isExpanded ? opening : closing)};
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  -webkit-overflow-scrolling: touch;

  ${({ isExpanded }) =>
    css`
      @media (min-width: ${breakpoints.lg}) {
        position: absolute;
        top: auto;
        left: auto;
        height: auto;
        max-height: 21.875rem;
        border: 1px solid ${theme.color.border.divider};
        border-top: 0;
        animation-name: ${isExpanded ? openingMD : closingMD};
      }
    `}
`;
