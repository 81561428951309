import * as React from 'react';

import { ModalModel, ModalContext } from '@notino/react-styleguide';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { useTrackingContext } from '@context/tracking/TrackingContext';

import { ModifaceModalRef } from '../ModiFaceModal';
import ModiFaceModal from '../ModiFaceModal/loadable';
import { IModifaceEffectVariants } from '../ModiFaceModal/model';
import { trackModifaceOpen, trackModifaceClosed } from '../ModiFaceModal/utils';
import { useHasWebcam } from '../useHasWebcam';

interface UseModifaceFns {
  handleOpenModifaceModal: () => void;
  detectWebcamAndTrackProductDetailView: (
    trackerFunc: (HasWebcam: boolean) => void
  ) => void;
  hasWebcam: boolean | undefined;
}

export const useModifaceFns = (
  product: GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  currentVariant: GetCatalogProductViewQuery['productDetailByCatalogMasterId']['variants'][number],
  modiface: {
    hair: IModifaceEffectVariants;
    makeup: IModifaceEffectVariants;
  }
): UseModifaceFns => {
  const { toggleModal, hideModal } = ModalContext.useModalContext();
  const { getTimeFromInit } = useTrackingContext();
  const modifaceModalRef = React.useRef<ModifaceModalRef>(null);

  const [hasWebcam, detectWebcamAndTrackProductDetailView] = useHasWebcam();

  const handleOpenModifaceModal = React.useCallback(() => {
    trackModifaceOpen({
      timing: getTimeFromInit(),
      product,
    });
    const timeOfOpen = new Date().getTime();
    const isHair = Object.keys(modiface.hair).length > 0;

    toggleModal(
      <ErrorBoundary>
        <ModiFaceModal
          modifaceModalRef={modifaceModalRef}
          gtmProductId={currentVariant.productCode}
          onModalClose={hideModal}
          selectedVariant={currentVariant}
          variants={product.variants}
          type={isHair ? 'hair' : 'makeup'}
          modifaceVariants={isHair ? modiface.hair : modiface.makeup}
          timeOfOpen={timeOfOpen}
        />
      </ErrorBoundary>,
      {
        noBorders: true,
        center: true,
        size: ModalModel.Sizes.s1200,
        fullscreenOnMobile: true,
        onClose: () => {
          hideModal();
          trackModifaceClosed({
            timing: getTimeFromInit(timeOfOpen),
            product,
            selectedVariant: currentVariant,
            viewedVariants: modifaceModalRef.current?.getViewedVariants(),
          });
        },
      }
    );
  }, [
    getTimeFromInit,
    product,
    toggleModal,
    currentVariant,
    hideModal,
    modiface.hair,
    modiface.makeup,
  ]);

  return React.useMemo(
    () => ({
      handleOpenModifaceModal,
      detectWebcamAndTrackProductDetailView,
      hasWebcam,
    }),
    [detectWebcamAndTrackProductDetailView, handleOpenModifaceModal, hasWebcam]
  );
};
