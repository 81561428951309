import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

import { CdnImage } from '@components/CdnImage';

export const GiftImageBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.color.background.tertiary};
  padding: 1.5rem 0;

  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  text-align: center;
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 7.75rem !important;
  height: 7.75rem;

  @media (min-width: ${breakpoints.md}) {
    background-color: transparent;
    width: auto;
    height: auto;
    pointer-events: none;
    width: 4.5rem !important;
    height: 4.5rem;
  }

  img {
    mix-blend-mode: multiply;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const GiftImg = styled(CdnImage)`
  overflow: hidden;
`;

export const PlaceholderIconWrapper = styled('div')`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    svg {
      width: auto;
      height: auto;
    }
  }
`;

export const GiftImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    overflow: hidden;
    padding: 1.25rem;
    @media (min-width: ${breakpoints.md}) {
      padding: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    width: 18.75rem;
    height: 18.75rem;
    max-width: 100%;
    padding: 0.75rem;

    img {
      max-width: 18.75rem;
      max-height: 18.75rem;
    }
  }
`;
