(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("not_supported"), require("not_supported"), require("not_supported"), require("not_supported"), require("not_supported"));
	else if(typeof define === 'function' && define.amd)
		define("nushop", ["react", "react-router-dom", "react-dom", "react-intl", "react-router"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("not_supported"), require("not_supported"), require("not_supported"), require("not_supported"), require("not_supported")) : factory(root["sharedLibraries_nushop_react"], root["sharedLibraries_nushop_react-router-dom"], root["sharedLibraries_nushop_react-dom"], root["sharedLibraries_nushop_react-intl"], root["sharedLibraries_nushop_react-router"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__34837__, __WEBPACK_EXTERNAL_MODULE__42153__, __WEBPACK_EXTERNAL_MODULE__29512__, __WEBPACK_EXTERNAL_MODULE__80694__, __WEBPACK_EXTERNAL_MODULE__51775__) => {
return 