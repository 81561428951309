import { flowRight as compose } from 'lodash';

import { ProductTileModel } from '@notino/react-styleguide';

import {
  AttributeTypes,
  MaximumVolumeInPercent,
  AttributeType,
} from '@containers/ProductListing/model';

import {
  ILabelAttributes,
  labelAttributes,
  ClientOnlyAttributeTypes,
  ILabelAttribute,
} from './model';

interface LabelPair {
  label: Label;
  attributes: ILabelAttribute;
}

type Label = AttributeTypes | ClientOnlyAttributeTypes;

/**
 * Returns an object of variant attributes, where keys are attributes (label) and values are its properties.
 * It respects the order according to labelAttributes keys
 */
export const getLabelAttrs = (attributes: Label[]): Partial<ILabelAttributes> =>
  Object.keys(labelAttributes)
    .map((label) => attributes.find((variantLabel) => variantLabel === label))
    .filter((element) => element !== undefined)
    .reduce((acc, label) => {
      acc[label] = labelAttributes[label];
      return acc;
    }, {});

export const getPairs = (attrs: Partial<ILabelAttributes>): LabelPair[] =>
  Object.keys(attrs).reduce(
    (acc, label) => [...acc, { label, attributes: labelAttributes[label] }],
    []
  );

export const resolveDamageAttribute = (attributeValue: {
  volumeInPercent: number;
}): ClientOnlyAttributeTypes.Damaged | ClientOnlyAttributeTypes.Returned => {
  return attributeValue.volumeInPercent === MaximumVolumeInPercent
    ? ClientOnlyAttributeTypes.Damaged
    : ClientOnlyAttributeTypes.Returned;
};

export const mapClientOnlyAttributes = (
  label: AttributeTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributeValue: any
): Label => {
  if (label === AttributeTypes.Damage) {
    return resolveDamageAttribute(attributeValue);
  }

  return label;
};

export const orderTags = (tags: ProductTileModel.ProductLabelAttributes[]) => {
  const tagsOrder = [
    ProductTileModel.ProductLabelAttributes.Action,
    ProductTileModel.ProductLabelAttributes.Clearance,
    ProductTileModel.ProductLabelAttributes.FreeDelivery,
  ];

  return [...tags].sort((a, b) => {
    const indexA = tagsOrder.indexOf(a);
    const indexB = tagsOrder.indexOf(b);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    }
    return 0;
  });
};

export const transformAttributesToTags = (
  attributes: Record<string, unknown> = {}
): ProductTileModel.ProductLabelAttributes[] => {
  const tags = Object.keys(attributes)
    .filter((key) => Boolean(attributes[key]))
    .map((key: AttributeTypes) => {
      const label = mapClientOnlyAttributes(key, attributes[key]);

      return ProductTileModel.ProductLabelAttributes[label];
    })
    .filter(Boolean);

  return orderTags(tags);
};

const addClientOnlyAttributes = (attributes: AttributeType): Label[] =>
  Object.keys(attributes).reduce(
    (acc, label) => [
      ...acc,
      mapClientOnlyAttributes(label as AttributeTypes, attributes[label]),
    ],
    []
  );

export const getRenderableAttributes = compose(
  getLabelAttrs,
  addClientOnlyAttributes
) as (attributes?: AttributeType) => Partial<ILabelAttributes>;
