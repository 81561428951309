import * as React from 'react';

import { ReactFCC } from '@notino/react-styleguide';

import { IExpandableTextProps } from './model';
import {
  Gradient,
  ExpandableTextWrapper,
  DefaultExpandableTextButton,
  ExpandableTextButtonWrapper,
} from './styled';

export const ExpandableText: ReactFCC<IExpandableTextProps> = ({
  isExpanded: isExpandedProp,
  buttonText,
  onClick,
  children,
  useOnlyOn,
  height,
  disabled,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const isControlledComponent = isExpandedProp !== undefined;

  const resolvedIsExpanded = isControlledComponent
    ? isExpandedProp
    : isExpanded;

  const handleClick = () => {
    if (isControlledComponent) {
      onClick?.();
      return;
    }
    setIsExpanded(true);
  };

  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <>
      <ExpandableTextWrapper
        isExpanded={resolvedIsExpanded}
        useOnlyOn={useOnlyOn}
        height={height}
      >
        {children}

        {!resolvedIsExpanded && (
          <Gradient
            useOnlyOn={useOnlyOn}
            data-testid="expandable-text-gradient"
          />
        )}
      </ExpandableTextWrapper>

      {!resolvedIsExpanded &&
        getButtonComponent({ useOnlyOn, buttonText, handleClick })}
    </>
  );
};

const getButtonComponent = ({
  useOnlyOn,
  handleClick,
  buttonText,
}: Pick<IExpandableTextProps, 'useOnlyOn' | 'buttonText'> & {
  handleClick: () => void;
}) => {
  const btnCommonProps = {
    useOnlyOn,
    onClick: handleClick,
    ['data-testid']: 'expandable-text-button',
  };

  return typeof buttonText === 'string' ? (
    <ExpandableTextButtonWrapper {...btnCommonProps}>
      <DefaultExpandableTextButton>{buttonText}</DefaultExpandableTextButton>
    </ExpandableTextButtonWrapper>
  ) : (
    <ExpandableTextButtonWrapper {...btnCommonProps}>
      {buttonText}
    </ExpandableTextButtonWrapper>
  );
};
