import styled, { css } from 'styled-components';

import { breakpoints, theme, Grid } from '@notino/react-styleguide';

import { ViewPort } from '@components';

export const Main = styled.div``;

export const Clear = styled.div`
  clear: both;
`;

export const ProductTabContentGrid = styled(Grid)`
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 2rem;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 4rem;
  }
`;

export const TopSection = styled.div<{
  isLoading?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 1.25rem;
  }
`;

export const GalleryWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 100%;
    padding-right: 2rem;
    padding-top: 1.25rem;
    width: 65%;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 70%;
    padding-right: 3rem;
    z-index: 0;
  }
`;

export const MainInfoWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 35%;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 30%;
  }
`;

export const PDViewPort = styled(ViewPort)``;

export const TitleCSS = css`
  text-align: left !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  flex-basis: 100%;

  font-size: 1rem;
  line-height: 1.18;

  font-weight: 500;
  @media (min-width: ${breakpoints.lg}) {
    ${theme.typography.titleLarge};
  }

  @media (min-width: ${breakpoints.lg}) {
    display: block;
    cursor: text;
  }
`;

export const TitleWrapper = styled.div`
  ${TitleCSS};
`;

export const ProductTitleWrapper = styled.div`
  text-align: left;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  flex-basis: 100%;
  width: 100%;
  margin: 0.625rem 0 !important;

  font-size: 1rem;
  line-height: 1.18;

  display: flex;
  font-weight: 500;
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 0;
    ${theme.typography.titleLarge};
  }

  @media (min-width: ${breakpoints.md}) {
    cursor: text;
  }
`;

interface IDescriptionTitleProps {
  fullWidth: boolean;
}

export const DescriptionTitle = styled.div<
  {
    fullWidth: boolean;
  } & IDescriptionTitleProps
>`
  ${({ fullWidth }) =>
    css`
      width: 100%;
      @media (min-width: ${breakpoints.lg}) {
        width: ${fullWidth ? '100%' : '65%'};
      }

      @media (min-width: ${breakpoints.xl}) {
        width: ${fullWidth ? '100%' : '70%'};
      }
    `}
`;

export const CompositionTitle = styled.div`
  max-width: 66%;
`;

export const TextNormal = styled.span`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: inline;
  }
`;

export const CommonContainer = styled.section`
  display: flex;
`;

export const CommonMarginWrapper = styled.div`
  flex-basis: 100%;
  height: inherit;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

export const PriceWrapper = styled.span``;

export const CurrencyWrapper = styled(PriceWrapper)`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export const ShowOnDesktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

export const ShowOnMobile = styled.div`
  display: block;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export const AbsoluteHeaderWrapper = styled.div`
  position: absolute;
  top: 60px;
  @media (min-width: ${breakpoints.lg}) {
    all: unset;
  }
`;

export const AbsoluteTabsWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 1400px; // this will be dynamically adjusted by useMoveTabsToMobilePlaceholder
  @media (min-width: ${breakpoints.lg}) {
    all: unset;
  }
`;

export const ReviewSummaryCount = styled.span`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: ${theme.color.text.tertiary};

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;
