import { useEffect } from 'react';

import {
  AvailabilityState,
  GetCatalogProductViewQuery,
} from '@notino/shared/definitions/types';

import { EXCLUDE_FROM_LAST_VISITED } from '@utils/constants';
import { requestIdleCallback } from '@utils/requestIdleCallback';

import { appendProductToCookie } from './utils';

type IUseAddToLastVisited = Pick<
  GetCatalogProductViewQuery['productDetailByCatalogMasterId'],
  'variants' | 'webMasterId'
>;

export const useAddToLastVisited = ({
  webMasterId,
  variants,
}: IUseAddToLastVisited) => {
  useEffect(() => {
    requestIdleCallback(() => {
      const canBuy = variants.some(
        (variant) =>
          variant.availability.state === AvailabilityState.CanBeBought
      );

      const canSaveToLastVisited = variants.every(
        (variant) =>
          !variant.navigationValues.includes(EXCLUDE_FROM_LAST_VISITED)
      );
      if (canBuy && canSaveToLastVisited) {
        appendProductToCookie(webMasterId);
      }
    });
  }, [webMasterId, variants]);
};
