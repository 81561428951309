import { noop } from './utils';

export const initSkinAnalyzer = (skinAnalyzerConfig) => {
  const script = document.getElementById('skin-analyzer');

  if (!script) {
    const rv = document.createElement('script');
    rv.src =
      'https://d38knilzwtuys1.cloudfront.net/revieve-plugin-v4/revieve-plugin-loader.js';
    rv.charset = 'utf-8';
    rv.type = 'text/javascript';
    rv.async = 'true';
    rv.id = 'skin-analyzer';
    rv.onload = rv.onreadystatechange = function () {
      const rs = this.readyState;
      if (rs && rs !== 'complete' && rs !== 'loaded') return;
      // eslint-disable-next-line no-undef
      Revieve.Init(skinAnalyzerConfig, noop);
    };
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(rv, s);
  } else {
    // eslint-disable-next-line no-undef
    Revieve.Init(skinAnalyzerConfig, noop);
  }
};

// eslint-disable-next-line no-undef
export const openSkinAnalyzer = () => Revieve.API.show();

export const unmountSkinAnalyzer = () => {
  // eslint-disable-next-line no-undef
  window.Revieve?.API?.unmount();
};
