import styled from 'styled-components';

import { breakpoints, theme } from '@notino/react-styleguide';

export const BreadcrumbWrapper = styled.div`
  max-width: 64.6875rem;
  font-size: 0.75rem;
  padding: 1rem 0 0 0;
  display: block;

  ${theme.typography.labelRegular400}
  font-size: 0.75rem;
  padding: 1rem 0 1.5rem;

  @media (min-width: ${breakpoints.lg}) {
    padding: 1.25rem 0 1.5rem;
  }
`;
