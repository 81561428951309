import { useApolloClient } from '@apollo/client';

import { GetRequestHeadersQuery } from '@notino/shared/definitions/types';

import getRequestHeadersQuery from '../queries/requestHeaders.graphql';

export const useApolloClientHeaders = () => {
  const { cache } = useApolloClient();

  const { RequestHeaders } = cache.readQuery<GetRequestHeadersQuery>({
    query: getRequestHeadersQuery,
  });

  return RequestHeaders;
};
