import styled, { css } from 'styled-components';

import { TooltipModel, theme } from '@notino/react-styleguide';

import { getArrowPositionStyles } from './getArrowPositionStyles';
import { getTooltipPositionStyles } from './getTooltipPositionStyles';

import { BackgroundColor } from '.';

export const Wrapper = styled.div`
  display: inline-block;
`;

export const Content = styled.div.attrs({ role: 'tooltip' })<{
  shouldWrapText?: boolean;
}>`
  padding: 0.5rem;
  font-size: 0.8rem;
  white-space: ${({ shouldWrapText = true }) =>
    shouldWrapText ? 'wrap' : 'nowrap'};
  border: 1px solid ${theme.color.border.divider};

  line-height: 1.3rem;
`;

export const Placeholder = styled.div`
  position: absolute;
  z-index: 999999999;
`;

export const RelativeWrapper = styled.div<{
  position: TooltipModel.Position;
  visible: boolean;
  backgroundColor?: BackgroundColor;
}>`
  position: relative;
  width: 100%;
  height: 100%;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }
  &::before {
    z-index: 1;
  }
  ${getArrowPositionStyles}
`;

export const TooltipWrapper = styled.div<{
  position: TooltipModel.Position;
  visible: boolean;
  interactive: boolean;
  distanceFromArrowToWindowEdge: number;
  backgroundColor?: BackgroundColor;
}>`
  position: absolute;

  ${({ backgroundColor }) =>
    css`
      background-color: ${theme.color.background[backgroundColor || 'primary']};
      background-clip: content-box;
    `}

  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  ${({ visible }) =>
    css`
      opacity: ${visible ? 1 : 0};
    `}

  ${getTooltipPositionStyles}
`;
