import * as React from 'react';

interface TWrapperEnabled {
  wrapperEnabled?: boolean;
  children?: React.ReactNode;
}

type ConditionalWrapper<P> = React.FC<TWrapperEnabled & P>;

export function withConditionalWrapper<T = Record<string, unknown>>(
  Wrapper: React.FC<TWrapperEnabled | T>
): ConditionalWrapper<T> {
  return ({ wrapperEnabled, children, ...wrapperProps }: TWrapperEnabled) =>
    wrapperEnabled ? (
      <Wrapper {...wrapperProps}>{children}</Wrapper>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    );
}
