import { EMessage } from '../model';

export const triggerWebcamPermission = (
  onSuccess: () => void,
  onFail: (message: EMessage) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigator = window.navigator as any;

  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  if (navigator.getUserMedia) {
    navigator.getUserMedia(
      { video: { width: 640, height: 450 } },
      onSuccess,
      (stream: MediaStream) => {
        onFail(EMessage.webcamError);
        stopLiveVideoWhenIsRunning(stream);
      }
    );
  } else if (navigator.mediaDevices) {
    // Safari fix
    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream: MediaStream) => {
        stream.getVideoTracks()[0].stop();
        onSuccess();
      },
      (stream: MediaStream) => {
        onFail(EMessage.webcamError);
        stopLiveVideoWhenIsRunning(stream);
      }
    );
  } else {
    onFail(EMessage.genericError);
  }
};

const stopLiveVideoWhenIsRunning = (stream: MediaStream) => {
  if (!stream?.getTracks || stream.getTracks().length === 0) {
    return;
  }

  stream.getTracks().forEach((track) => {
    if (track.readyState === 'live' && track.kind === 'video') {
      track.stop();
    }
  });
};
