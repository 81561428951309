import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductTile, ProductTileModel } from '@notino/react-styleguide';
import { masterUrl } from '@notino/shared/client-utils';
import { IStock } from '@notino/shared/definitions/custom-definitions';

import { VoucherCode } from '@components/Universals/ProductItem/styled';
import { useSettings } from '@containers/ProductDetailContainer/hooks/useSettings';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';
import { isDiorBrand } from '@containers/ProductDetailContainer/ProductDetail/utils';
import { dispatchTrackingEvent } from '@context/tracking/utils';
import { ProductEventWither } from '@helpers/googleTagManager';
import { ATTRIBUTION_TOKEN_QUERY_PARAM } from '@utils/constants';
import { noop } from '@utils/noop';
import { appendQueryParam } from '@utils/url';

import { useGetImageAttributes } from '../../CdnImage/useImageAttributes';
import { useProductSpaRedirect } from '../../ClientRedirect/useHandleClientRedirect';
import { useFormatPrice } from '../../PriceLabel/useFormattedPrice';
import { usePriceConfig } from '../../PriceLabel/usePriceConfig';
import { useWishlistActionsFactory } from '../WishlistModifier/useWishlistActions';

import { BuyButton } from './components';
import { messages } from './messages';
import { IProductItemProps } from './model';
import { useGetTagAttributes } from './useTagAttributes';
import { useGetProductIcons } from './utils';

// this character ensures that there is same spacing as if text was present
// found here https://jkorpela.fi/chars/spaces.html
const SPECIAL_IDEOGRAPHIC_SPACE = '　';

type UseGetProductItemProps = Pick<
  IProductItemProps,
  | 'useMasterUrl'
  | 'setProductWishlisted'
  | 'GTMEventLabel'
  | 'inSectionOfType'
  | 'sendGTM'
  | 'noPriceIfUnavailable'
  | 'noTags'
  | 'showWishlistButton'
  | 'onProductAdded'
  | 'onProductAddFailed'
  | 'onClosingCartModal'
  | 'showBuyButton'
  | 'showAddToCartModal'
  | 'buttonElementClassName'
  | 'canSpaRedirect'
  | 'wishlistActionLocation'
  | 'hasRegisteredWatchdog'
>;

type GetProductProps = Pick<
  IProductItemProps,
  | 'product'
  | 'wishlisted'
  | 'order'
  | 'appendComponent'
  | 'description'
  | 'position'
  | 'attributionToken'
>;

export const useGetProductItemProps = ({
  useMasterUrl = false,
  setProductWishlisted,
  GTMEventLabel,
  inSectionOfType = '',
  sendGTM = false,
  noPriceIfUnavailable,
  noTags,
  showWishlistButton,
  onProductAdded = noop,
  onProductAddFailed = noop,
  onClosingCartModal = noop,
  showBuyButton = false,
  showAddToCartModal = true,
  buttonElementClassName,
  canSpaRedirect = true,
  wishlistActionLocation,
  hasRegisteredWatchdog,
}: UseGetProductItemProps) => {
  const { data: { currentLocale, currentCurrency } = {} } = usePriceConfig();
  const { Settings: { showUnitPrices } = {} } = useSettings();

  const formatPrice = useFormatPrice();
  const {
    isDualPriceForCroatia,
    getLeftSideFormattedPrice,
    getRightSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
  } = useIsDualPriceForCroatia();
  const getTagAttributes = useGetTagAttributes();
  const getProductIcons = useGetProductIcons();
  const getImageAttributes = useGetImageAttributes();

  const withProductSpaRedirect = useProductSpaRedirect(
    canSpaRedirect,
    useMasterUrl
  );

  const { handleWishlistClick, isWishlistAllowed } = useWishlistActionsFactory({
    onCompleted: setProductWishlisted,
  });

  return ({
    product,
    wishlisted = false,
    order,
    appendComponent,
    description,
    position = 0,
    attributionToken,
  }: GetProductProps): ProductTileModel.IProductTileContentProps => {
    if (!currentLocale || !currentCurrency || !product) {
      return null;
    }

    const formattedPriceObj = formatPrice(product.price);
    const formattedUnitPriceObj = formatPrice(product.unitPrice);
    const tagAttributes = getTagAttributes(product.attributes);

    const productIcons = getProductIcons(product.attributes);

    const isDior = isDiorBrand(product.brand?.id);

    const imageAttributes = getImageAttributes(
      product.imageUrl,
      'list',
      `${product.brand?.name} ${product.name}`
    );

    const handleTracking = () => {
      if (sendGTM && currentCurrency) {
        dispatchTrackingEvent({
          event: 'select_item',
          product: [
            ProductEventWither()
              .withVpProduct(product)
              .withAdditionalData({
                list_position: position,
                list_name: GTMEventLabel,
              })
              .build(),
          ],
          _clear: true,
        });
      }
    };

    const getHref = () => {
      const resolvedUrl = useMasterUrl ? masterUrl(product.url) : product.url;
      return attributionToken
        ? appendQueryParam(
            resolvedUrl,
            ATTRIBUTION_TOKEN_QUERY_PARAM,
            attributionToken
          )
        : resolvedUrl;
    };

    const withSpaRedirect = withProductSpaRedirect({
      ...product,
      url: getHref(),
    });
    const handleClick = withSpaRedirect(handleTracking);

    const productInStock = product.stockAvailability.code !== IStock.outOfStock;
    const showPrice = !noPriceIfUnavailable || product.canBuy;

    const voucherDiscount = product.attributes.VoucherDiscount;

    const shouldShowTags = productInStock && tagAttributes && !isDior;

    function getVoucherDiscountDecoration():
      | 'summerBlackFriday'
      | 'blackFriday'
      | undefined {
      if (voucherDiscount?.tags.includes('summerblackfriday')) {
        return 'summerBlackFriday';
      }
      if (voucherDiscount?.tags?.includes('blackfriday')) {
        return 'blackFriday';
      }
      return;
    }

    return {
      state: ProductTileModel.State.product,
      tileProps: {
        'data-testid': 'product-item',
        'data-cypress': 'productItem-container',
      },
      product: {
        id: product.id,
        description: description,
        name: product.name,
        subName: product.subName,
        brandName: product.brand?.name ?? SPECIAL_IDEOGRAPHIC_SPACE,
        variantName: product.annotation,
      },

      unitPrice: showPrice &&
        showUnitPrices &&
        product.unitPrice && {
          price: {
            price: String(
              formattedUnitPriceObj?.formattedPrice || product.unitPrice?.value
            ),
            currencySymbol: formattedUnitPriceObj?.currencySymbol,
            currencyFormat: formattedUnitPriceObj?.currentLocale?.priceFormat,
          },
          unit: product.unitPrice.unit,
          unitAmount: String(product.unitPrice.perAmount),
        },
      ratings: Boolean(product.reviewOverview?.rating) && {
        inSectionOfType,
        score: product.reviewOverview.rating,
      },
      productIcons,
      productLabels: shouldShowTags && {
        attributes: tagAttributes,
        visible: !noTags,
      },
      aboveBuyButton: (
        <>
          {Boolean(product.attributes.BlackFridayOfferDiscount) && (
            <ProductTile.Discount
              discountAmount={
                <FormattedMessage
                  {...messages.discountAmount}
                  values={{
                    amount:
                      product.attributes.BlackFridayOfferDiscount
                        .percentageDiscount,
                  }}
                />
              }
              discountDescription={
                <FormattedMessage {...messages.descriptionText} />
              }
            />
          )}

          {Boolean(voucherDiscount) && (
            <ProductTile.Discount
              decoration={getVoucherDiscountDecoration()}
              discountAmount={
                <FormattedMessage
                  {...messages.discountAmount}
                  values={{
                    amount: voucherDiscount.percentage,
                  }}
                />
              }
              discountDescription={
                <span>
                  <FormattedMessage {...messages.descriptionText} />
                  &nbsp;
                  <VoucherCode>{voucherDiscount.code}</VoucherCode>
                </span>
              }
            />
          )}
        </>
      ),
      appendComponent: (
        <>
          {appendComponent}
          {showBuyButton && (
            <BuyButton
              onProductAddFailed={onProductAddFailed}
              onProductAdded={onProductAdded}
              buttonElementClassName={buttonElementClassName}
              product={product}
              showAddToCartModal={showAddToCartModal}
              disableRedirect={true}
              onClosingCartModal={onClosingCartModal}
              hasRegisteredWatchdog={hasRegisteredWatchdog}
            />
          )}
        </>
      ),
      href: getHref(),
      imageProps: imageAttributes,
      onClick: handleClick,
      order,
      wishlist: {
        wishlisted: wishlisted || product.wishlisted,
        onClick: () =>
          handleWishlistClick({
            productId: product.id,
            wishlisted: wishlisted || product?.wishlisted,
            actionLocation: wishlistActionLocation,
            listName: GTMEventLabel,
            listPosition: position,
          }),
        notActive: !isWishlistAllowed && showWishlistButton,
      },
      price:
        showPrice &&
        (isDualPriceForCroatia
          ? {
              price: getRightSideFormattedPrice(product.price.value),
              currencySymbol: rightSideCurrency,
              currencyFormat: 'v s',
            }
          : {
              price: String(
                formattedPriceObj?.formattedPrice || product?.price.value
              ),
              currencySymbol:
                formattedPriceObj?.currencySymbol || currentCurrency.symbol,
              currencyFormat:
                formattedPriceObj?.currentLocale?.priceFormat ||
                currentLocale.priceFormat,
            }),
      dualPrice: showPrice &&
        isDualPriceForCroatia && {
          price: getLeftSideFormattedPrice(product.price.value),
          currencySymbol: leftSideCurrency,
          currencyFormat: 'v s',
        },
    };
  };
};

export const useProductItemProps = ({
  product,
  wishlisted,
  description,
  position,
  appendComponent,
  order,
  ...rest
}: IProductItemProps) => {
  const getProductItemProps = useGetProductItemProps(rest);

  return getProductItemProps({
    product,
    wishlisted,
    description,
    position,
    appendComponent,
    order,
  });
};
