import { useQuery } from '@apollo/client';

import { GetFullUserQuery, Roles } from '@notino/shared/definitions/types';

import getUserQuery from './getFullUser.graphql';

type UseUserParams = { ssr?: boolean };
type UseUserReturn = {
  user: GetFullUserQuery['user'];
  isLoggedIn: boolean;
};

export const useUser = ({ ssr }: UseUserParams = {}): UseUserReturn => {
  const { data } = useQuery<GetFullUserQuery>(getUserQuery, {
    ssr,
  });

  const nonNullUser = data?.user ?? {
    role: Roles.Anonymous,
    comparisonIds: null,
    cartId: null,
    id: null,
    consent: [],
    sid: '',
    info: {
      email: '',
    },
  };

  return {
    user: nonNullUser,
    isLoggedIn: nonNullUser.role !== Roles.Anonymous,
  };
};
