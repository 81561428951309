import * as React from 'react';

import { Video } from '@notino/shared/definitions/types';

import { getVideoIframe } from '@containers/ProductDetailContainer/utils';
import { useFeatureFlags } from '@context/featureFlags/FeatureFlagsProvider';
import { IImageProperties } from '@helpers/utils';
import { useArrows } from '@hooks/useArrows';
import { useIsMountedRef } from '@hooks/useIsMounted';
import { isRenderingOnServer } from '@utils/isRenderingOnServer';

import { ModiFaceOverlay } from '../ModiFaceOverlay';

import { ProgressBar } from './ProgressBar';
import { Slide } from './Slide';
import {
  OuterWrapper,
  PlayerWrapper,
  SliderWrapper,
  StyledArrow,
} from './styled';
import { useTrackSwiping } from './useTrackSwiping';

export interface IImageSliderProps {
  imagesAttrs: IImageProperties[];
  videos?: Video[];
  hasModiface?: boolean;
  openModifaceModal?: () => void;
  shouldShow360Photo?: boolean;
}

export const ImageSlider = ({
  imagesAttrs,
  videos = [],
  hasModiface,
  openModifaceModal,
  shouldShow360Photo,
}: IImageSliderProps) => {
  const { trackSwiping } = useTrackSwiping();
  const isMounted = useIsMountedRef();
  const { new_vto_button } = useFeatureFlags();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const { scrollTo, containerRef, showLeftArrow, showRightArrow } = useArrows(
    [],
    {
      scrollAmount: isRenderingOnServer() ? 0 : window.innerWidth,
      showArrowCalulationOffset: 150,
    }
  );

  const items: Array<{ id: string; renderItem: JSX.Element }> = React.useMemo(
    () => [
      ...imagesAttrs
        .map((imgProps, index) => {
          const showModifaceOverlay =
            hasModiface && index === 0 && !new_vto_button;
          const isLastSlide =
            videos.length === 0 && index === imagesAttrs.length - 1;

          // if modiface overlay on mobile, clone main image so customer can swipe to image and view it without overlay
          if (showModifaceOverlay) {
            return [
              {
                id: `${imgProps.src}-modiface`,
                renderItem: (
                  <Slide isFirstSlide={true} imgProps={imgProps}>
                    <ModiFaceOverlay onClick={openModifaceModal} />
                  </Slide>
                ),
              },
              {
                id: imgProps.src,
                renderItem: (
                  <Slide imgProps={imgProps} isLastSlide={isLastSlide} />
                ),
              },
            ];
          }

          return {
            id: imgProps.src,
            renderItem: (
              <Slide
                imgProps={imgProps}
                isFirstSlide={index === 0}
                isLastSlide={isLastSlide}
                shouldShow360Photo={shouldShow360Photo && index === 1}
              />
            ),
          };
        })
        .flat(),

      ...videos.map((video) => ({
        id: video.id,
        renderItem: (
          <PlayerWrapper
            dangerouslySetInnerHTML={{
              __html: getVideoIframe(video),
            }}
          />
        ),
      })),
    ],
    [
      new_vto_button,
      hasModiface,
      imagesAttrs,
      openModifaceModal,
      shouldShow360Photo,
      videos,
    ]
  );

  const calculateCurrentIndex = (el: HTMLDivElement) =>
    setCurrentIndex(
      Math.floor((el.scrollLeft + window.innerWidth / 2) / window.innerWidth)
    );

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    trackSwiping('swipe');
    calculateCurrentIndex(e.currentTarget);
  };

  React.useEffect(() => {
    calculateCurrentIndex(containerRef.current);
  }, [containerRef]);

  const isVideoSlide = currentIndex >= imagesAttrs.length;

  return (
    <OuterWrapper>
      <StyledArrow
        direction="left"
        visible={showLeftArrow}
        onClick={() => {
          scrollTo('left');
          trackSwiping('button');
        }}
      />

      <SliderWrapper ref={containerRef} onScroll={handleScroll}>
        {items.map(({ id, renderItem }) => (
          <div key={id}>{renderItem}</div>
        ))}
      </SliderWrapper>

      {new_vto_button && hasModiface && (
        <ModiFaceOverlay
          onClick={openModifaceModal}
          visible={!isVideoSlide && isMounted}
        />
      )}

      {isMounted && <ProgressBar count={items.length} index={currentIndex} />}

      <StyledArrow
        direction="right"
        data-testid="chevron-right"
        visible={showRightArrow}
        onClick={() => {
          scrollTo('right');
          trackSwiping('button');
        }}
      />
    </OuterWrapper>
  );
};
