import * as React from 'react';
import { useNavigate } from 'react-router';

import { CatalogVariantFragmentFragment } from 'packages/shared/definitions/types';

import { IVariantProps } from '@containers/ProductDetailContainer/model';

export type HandleTransitionClick = (
  item: CatalogVariantFragmentFragment,
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void;

export const useVariantTransition = (selectedId: string) => {
  const navigate = useNavigate();
  const [_, startTransition] = React.useTransition();

  const [internalSelectedId, setInternalSelectedId] =
    React.useState(selectedId);

  // just in case some other component does the navigation
  React.useEffect(() => {
    setInternalSelectedId(selectedId);
  }, [selectedId]);

  const handleClick: IVariantProps['onClick'] = (item, e) => {
    e.preventDefault();
    setInternalSelectedId(item.webId);
    startTransition(() => navigate(item.url, { replace: true }));
  };

  return {
    handleClick,
    selectedId: internalSelectedId,
  };
};
