import {
  breakpoints,
  SkeletonElement as NotinoSkeletonElement,
  styled,
} from '@notino/react-styleguide';

export const SkeletonElement = styled(NotinoSkeletonElement)<{
  marginY?: number;
  marginX?: number;
}>`
  margin: ${({ marginY = 0 }) => marginY}rem ${({ marginX = 0 }) => marginX}rem;
`;

export const Col = styled.div<{
  mobileWidth?: number;
  width: number;
  hideOnMobile?: boolean;
}>`
  width: ${({ mobileWidth, width }) => mobileWidth ?? width}%;

  @media (min-width: ${breakpoints.md}) {
    width: ${({ width }) => width}%;
  }
`;
export const ReviewsWrapper = styled(Col)`
  padding: 0.75rem 0;
  display: ${({ hideOnMobile = false }) => (hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewSkeleton = styled.div`
  width: 80%;
`;

export const ReviewsSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;
