import { QueryHookOptions, useQuery } from '@apollo/client';

import {
  GetReviewsQuery,
  GetReviewsQueryVariables,
} from '@notino/shared/definitions/types';

import { useReviewItemsContext } from '@containers/ProductDetailContainer/ProductDetail/ProductTabContent/Reviews/ReviewItems/context';

import getReviewsQuery from './queries/reviews.graphql';

export const useReviewsFetch = (
  pageSize: number,
  productCode: string,
  options: QueryHookOptions<GetReviewsQuery, GetReviewsQueryVariables>
) => {
  const {
    state: { orderBy, orderDesc, page, hideTranslated },
  } = useReviewItemsContext();

  return useQuery<GetReviewsQuery, GetReviewsQueryVariables>(getReviewsQuery, {
    variables: {
      code: productCode,
      orderBy,
      orderDesc,
      page,
      pageSize,
      hideTranslated,
    },
    partialRefetch: true,
    ...options,
  });
};
