import * as React from 'react';

import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  GetProductsByIdBatchedQuery,
  GetProductsByIdBatchedQueryVariables,
} from '@notino/shared/definitions/types';

import getProductsByIdBatchedQuery from '@components/Universals/BatchVPProductQuery/queries/productsByIdBatched.graphql';

type Options = {
  filterNullProducts?: boolean;
} & Partial<
  Omit<
    QueryHookOptions<
      GetProductsByIdBatchedQuery,
      GetProductsByIdBatchedQueryVariables
    >,
    'variables'
  >
>;

export const useProductsByIdsBatched = (
  ids: (string | number)[],
  {
    filterNullProducts = true,
    skip = false,
    ssr = false,
    errorPolicy = 'all',
    onCompleted,
    ...restOptions
  }: Options = {}
) => {
  const { data, ...restResponse } = useQuery<
    GetProductsByIdBatchedQuery,
    GetProductsByIdBatchedQueryVariables
  >(getProductsByIdBatchedQuery, {
    // we have to check if ids.length === 0 to avoid call to api with empty ids
    skip: ids.length === 0 || skip,
    errorPolicy,
    ssr,
    variables: {
      ids: ids.map(String),
    },
    onCompleted: filterNullProducts
      ? (response) => {
          onCompleted?.({
            ...response,
            vpProductByIds: response.vpProductByIds?.filter(Boolean),
          });
        }
      : onCompleted,
    ...restOptions,
  });

  const products = React.useMemo(() => {
    if (!data) {
      return data;
    }
    return {
      ...data,
      vpProductByIds: filterNullProducts
        ? data.vpProductByIds?.filter(Boolean)
        : data.vpProductByIds,
    };
  }, [filterNullProducts, data]);

  return {
    data: products,
    ...restResponse,
  };
};
