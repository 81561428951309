import { styled, breakpoints } from '@notino/react-styleguide';

// we add padding-bottom because of top modal padding
export const RelatedList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.875rem;
  overflow-y: hidden;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    height: auto;
    overflow-x: auto;
    padding-bottom: 2.75rem;
  }
`;
