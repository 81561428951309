import styled, { css } from 'styled-components';

import { IBasicStyledProps, keyframes } from '@notino/react-styleguide';

const adding = keyframes`
	0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

interface IStyledPulsatingHeart extends IBasicStyledProps {
  animate: boolean;
}

const complexMixin = css`
  animation: ${adding} 350ms ease forwards;
`;

export const StyledPulsatingHeart = styled.div<IStyledPulsatingHeart>`
  display: flex;
  align-items: center;
  padding-right: 1.13rem;
  padding-left: 0.125rem;

  svg {
    ${(props) => (props.animate ? complexMixin : '')};
  }
`;
