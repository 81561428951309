import * as React from 'react';

import NodeCache from 'node-cache';

import { ThemeProvider, ModalContext, Toaster } from '@notino/react-styleguide';
import { type Logger } from '@notino/shared/logging';

import { DrawerProvider } from '@components/BottomSheet/DrawerProvider';
import { LanguageProvider } from '@containers/LanguageProvider';
import { TrackingContextProvider } from '@context/tracking/TrackingContext';
import { GlobalStyle } from '@global-styles';
import theme from '@themes/default';

import WebView from '../../components/WebView';

import { runExponeaFunctions } from './utils';

interface IAppProps {
  mountId?: string;
  originUrl?: string;
  productDetailSsrEnabled?: boolean;
  cache?: NodeCache;
  rendererLogger?: Logger;
}

const App = ({
  mountId,
  originUrl,
  productDetailSsrEnabled,
  cache,
  rendererLogger,
}: IAppProps) => {
  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 9) {
        document.getElementById(mountId).classList.add('accesibility');
      }
    };

    const handleMouseClick = () => {
      document.getElementById(mountId).classList.remove('accesibility');
    };
    if (mountId) {
      document.getElementById(mountId).classList.remove('no-js');
      document.getElementById(mountId).classList.add('js');

      window.addEventListener('keydown', handleKeyPress);
      window.addEventListener('mousedown', handleMouseClick);
      runExponeaFunctions();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('mousedown', handleMouseClick);
    };
  }, [mountId]);

  /**
   * note: when there is a change made here, the same change must be made in Tunnel component
   */
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <TrackingContextProvider>
          <LanguageProvider
            originUrl={originUrl}
            cache={cache}
            rendererLogger={rendererLogger}
          >
            <ModalContext.Provider>
              <DrawerProvider>
                <WebView
                  mountId={mountId}
                  originUrl={originUrl}
                  productDetailSsrEnabled={productDetailSsrEnabled}
                />
              </DrawerProvider>
              <div id="nushop-portal-root" />
              <Toaster />
            </ModalContext.Provider>
          </LanguageProvider>
        </TrackingContextProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
