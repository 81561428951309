import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../../messages';
import { IStore } from '../../model';

import { StoreStockAvailability } from './StoreStockAvailability';
import { StoreDescription, StoreTitle, TextOverloaded } from './styled';

interface IStoreProps {
  data: IStore;
}

export const Store: React.FC<IStoreProps> = ({ data }) => {
  return (
    <>
      <StoreTitle isOverloaded={data.isOverloaded}>{data.name}</StoreTitle>
      <StoreDescription
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      />
      {data.isOverloaded ? (
        <TextOverloaded>
          <FormattedMessage {...messages.overloaded} />
        </TextOverloaded>
      ) : (
        <StoreStockAvailability {...data} />
      )}
    </>
  );
};
