import React from 'react';

import { EPictograms } from './model';
import { Pictogram } from './Pictogram';
import { PictogramsWrapper } from './styled';

interface IPictogramsProps {
  pictograms: EPictograms[];
}

export const Pictograms = ({ pictograms }: IPictogramsProps) => {
  return (
    <PictogramsWrapper>
      {pictograms.map((pictogram) => (
        <Pictogram pictogram={pictogram} key={pictogram} />
      ))}
    </PictogramsWrapper>
  );
};
