import * as React from 'react';

import { useTrackingContext } from '@context/tracking/TrackingContext';

import { useProductDetailContext } from '../../ProductDetailContext';
import { trackSwitchingImages } from '../tracking';

export const useTrackSwiping = () => {
  const context = useProductDetailContext(false);
  const variantId = context?.currentVariant.webId || '';
  const hasAlreadyTracked = React.useRef(false);
  const { getTimeFromInit } = useTrackingContext();

  React.useEffect(() => {
    hasAlreadyTracked.current = false;
  }, [variantId]);

  const trackSwiping = (interaction: 'swipe' | 'button') => {
    if (hasAlreadyTracked.current) {
      return;
    }
    hasAlreadyTracked.current = true;
    trackSwitchingImages({ timing: getTimeFromInit(), interaction });
  };

  return { trackSwiping };
};
