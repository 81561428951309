export enum EPictograms {
  VEGAN = 'IsVegan',
  BIO = 'IsBio',
  NATURAL = 'IsNatural',
  SUSTAINABLE = 'IsSustainable',
  TRAVEL_PACKING = 'IsTravelPacking',
  SPF = 'WithSpf',
  WITHOUT_PERFUME = 'WithoutPerfume',
  WITHOUT_SILICONE = 'WithoutSilicone',
  WITHOUT_ALCOHOL = 'WithoutAlcohol',
  WITHOUT_ALUMINIUM = 'WithoutAluminium',
  WITHOUT_SULFATE = 'WithoutSulfate',
}
