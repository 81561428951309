import * as React from 'react';
import { useIntl } from 'react-intl';

import { ApolloError, ObservableQueryFields } from '@apollo/client';

import { Select } from '@notino/react-styleguide';
import {
  ReviewOrderBy,
  GetReviewsQuery,
  GetReviewsQueryVariables,
} from '@notino/shared/definitions/types';

import ErrorBoundary from '@components/ErrorBoundary';
import { IOrderBy } from '@containers/ProductDetailContainer/model';

import messages from '../../../../messages';
import { PAGE_SIZE } from '../constants';

import { useReviewItemsContext } from './context';
import { FetchBlockFailed } from './FetchBlockFailed';
import { FetchDoneBlock } from './FetchDoneBlock';
import { FetchingBlock } from './FetchingBlock';
import { SortBox, StyledCol, StyledRow } from './styled';

interface IReviewsItemsProps {
  textReviewsCount: number;
  isAllReviewsPage?: boolean;
  refetch: () => void;
  fetchMore: ObservableQueryFields<
    GetReviewsQuery,
    GetReviewsQueryVariables
  >['fetchMore'];
  reviews: GetReviewsQuery['reviews'];
  productCode: string;
  reviewsLoading: boolean;
  reviewsError: ApolloError;
}

const orderByOptions: IOrderBy[] = [
  { key: ReviewOrderBy.DateTime, desc: true },
  { key: ReviewOrderBy.DateTime, desc: false },
  { key: ReviewOrderBy.Rating, desc: true },
  { key: ReviewOrderBy.Rating, desc: false },
  { key: ReviewOrderBy.Favorite, desc: true },
  { key: ReviewOrderBy.Favorite, desc: false },
];

const selectKeyboardConfig = {
  keyNavigation: true,
};

export const ReviewsItems: React.FC<IReviewsItemsProps> = ({
  refetch,
  fetchMore,
  reviews,
  reviewsLoading,
  reviewsError,
  productCode,
  textReviewsCount,
  isAllReviewsPage,
}) => {
  const { formatMessage } = useIntl();
  const {
    state: { orderBy: currentOrderBy, orderDesc: currentOrderDesc },
    actions: { changeOrder },
  } = useReviewItemsContext();
  const lastReview = React.useRef(0);

  if (!reviewsLoading && reviews.length > 0) {
    lastReview.current = reviews.length;
  }

  const onSortSelect = React.useCallback(
    (option) => {
      const orderBy = orderByOptions[option.id];
      changeOrder(orderBy);
    },
    [changeOrder]
  );

  const options = React.useMemo(
    () =>
      orderByOptions.map((orderBy, index) => ({
        id: index,
        label: formatMessage(
          messages[
            `orderReviewsBy${orderBy.key}${orderBy.desc ? 'Desc' : 'Asc'}`
          ]
        ),
        key: orderBy.key,
        desc: orderBy.desc,
      })),
    [formatMessage]
  );

  return (
    <StyledRow>
      {(reviews.length > 0 || lastReview.current > 0) && (
        <>
          <StyledCol xs={12}>
            <SortBox>
              <Select
                data-cypress="reviews-sorting"
                currentOption={options.find(
                  (option) =>
                    option.key === currentOrderBy &&
                    option.desc === currentOrderDesc
                )}
                keyNavigationConfig={selectKeyboardConfig}
                options={options}
                borderless={true}
                handleOptionSelect={onSortSelect}
              />
            </SortBox>
          </StyledCol>

          {reviews.length > 0 && (
            <ErrorBoundary>
              <FetchDoneBlock
                textReviewsCount={textReviewsCount}
                productCode={productCode}
                items={reviews}
                loadReviews={fetchMore}
                isAllReviewsPage={isAllReviewsPage}
              />
            </ErrorBoundary>
          )}
        </>
      )}

      {reviews.length === 0 && reviewsLoading && (
        <FetchingBlock reviewsCount={Math.min(textReviewsCount, PAGE_SIZE)} />
      )}

      {reviews.length === 0 && reviewsError && (
        <FetchBlockFailed loadReviews={refetch} />
      )}
    </StyledRow>
  );
};
