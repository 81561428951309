import { css } from 'styled-components';

import { styled, theme } from '@notino/react-styleguide';

export const StoreTitle = styled.p<{ isOverloaded: boolean }>`
  ${theme.typography.labelRegular400}
  margin-bottom: 0.25rem;

  ${({ isOverloaded }) =>
    isOverloaded &&
    css`
      color: '#aaa';
    `}
`;

export const StoreDescription = styled.span`
  font-weight: 300;
  ${theme.typography.labelRegular400}
  color:${theme.color.text.tertiary};
  padding-bottom: 0.25rem;
`;

export const StoreStockAvailabilityWrapper = styled.div`
  color: ${theme.color.text.positive};
  ${theme.typography.labelRegular400}
`;

export const TextOverloaded = styled.p`
  color: rgb(204, 37, 37);
  font-weight: 500;
`;
