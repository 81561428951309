import styled, { css } from 'styled-components';

import {
  breakpoints,
  IBasicStyledProps,
  IconSolidInfo,
  theme,
} from '@notino/react-styleguide';

export const Wrapper = styled.div`
  line-height: 1.3125rem;
  font-size: 0.875rem;
  margin: 0.75rem 0 1rem;
`;

export const CodeBlock = styled.span`
  color: ${theme.color.text.secondary};
`;

export const CodeName = styled.span`
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: inline;
  }
`;

export const Separator = styled.span`
  color: ${theme.color.text.disabled};
`;

export interface IAvailabilityProps extends IBasicStyledProps {
  highlight: boolean;
}

export const Availability = styled.span<IAvailabilityProps>`
  color: ${(props: IAvailabilityProps) =>
    props.highlight
      ? props.theme.color.text.negative
      : props.theme.color.text.positive};

  ${({ highlight }) =>
    css`
      font-weight: ${highlight ? 500 : 'normal'};
    `}
`;

export interface IAdditionalDescriptionStyledProps extends IBasicStyledProps {
  damage?: boolean;
  highlight?: boolean;
}
// prettier-ignore
export const AdditionalDescriptionStyled = styled.p<IAdditionalDescriptionStyledProps>`
  color: ${(props: IAdditionalDescriptionStyledProps) =>
    props.damage ? props.theme.color.text.negative : props.theme.color.text.primary};
  font-weight: ${(props: IAdditionalDescriptionStyledProps) =>
    props.highlight ? 'bold' : 'normal'};
  margin: 1rem 0 0 0;
`;

export const AdditionalText = styled.div`
  margin-top: 1rem;
`;

export const StyledInfoIcon = styled(IconSolidInfo)`
  margin-bottom: -2px;
`;

export const TooltipContent = styled.div`
  max-width: max-content;
  width: 18rem;
  padding: 0.5rem;
`;
