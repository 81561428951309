import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { theme } from '@notino/react-styleguide';

import PriceLabel from '@components/PriceLabel';
import { useIsDualPriceForCroatia } from '@containers/ProductDetailContainer/ProductDetail/hooks/useIsDualPriceForCroatia';

import { useVoucherDiscountContext } from '../context';
import { messages } from '../messages';
import { Wrapper, CurrencyWrapper, Slash } from '../styled';
import { VoucherDiscountInfo } from '../VoucherDiscountInfo';

import { ConditionalInstructionsText } from './ConditionalInstructionsText';

export interface IConditionalVoucherDiscount {
  code: string;
  conditionType: 'Price' | 'Piece';
  discountType: 'Absolute' | 'Percentage';
  productsOnSaleIncluded: boolean;
  discountConditions: Array<{
    percentageDiscount: number;
    discountedPrice: number;
    discountAmount: number;
    conditionMin: number;
  }>;
  targets: Array<{
    name: string;
    type: 'Brand' | 'Category';
    url: string;
  }>;
}

export interface IVoucherDiscountProps {
  voucherDiscount: IConditionalVoucherDiscount;
}

export const ConditionalVoucherDiscount = ({
  voucherDiscount,
}: IVoucherDiscountProps) => {
  const {
    isDualPriceForCroatia,
    getLeftSideFormattedPrice,
    leftSideCurrency,
    rightSideCurrency,
    getRightSidePrice,
  } = useIsDualPriceForCroatia();
  const { currency } = useVoucherDiscountContext();

  const {
    code,
    productsOnSaleIncluded,
    discountType,
    discountConditions: [
      { discountedPrice, discountAmount, percentageDiscount },
    ],
  } = voucherDiscount;

  const getPriceComponent = (price: number) => (
    <>
      {isDualPriceForCroatia && (
        <Wrapper>
          {getLeftSideFormattedPrice(price)}{' '}
          <CurrencyWrapper>{leftSideCurrency}</CurrencyWrapper>
          <Slash>/</Slash>
        </Wrapper>
      )}
      <PriceLabel
        price={{
          value: isDualPriceForCroatia ? getRightSidePrice(price) : price,
          currency: currency.code,
        }}
        wrapperComponent={Wrapper}
        currencyComponent={CurrencyWrapper}
        currencySymbolOverride={
          isDualPriceForCroatia ? rightSideCurrency : undefined
        }
      />
    </>
  );

  const discountTypeComponentMap: Record<typeof discountType, JSX.Element> = {
    Percentage: getPriceComponent(discountedPrice),
    Absolute: (
      <FormattedMessage
        {...messages.discountAmountAbsolute}
        values={{ amountPrice: getPriceComponent(discountAmount) }}
      />
    ),
  };

  return (
    <VoucherDiscountInfo
      discountCode={code}
      percentage={percentageDiscount}
      showPercentageIcon={discountType === 'Percentage'}
      highlightedDiscountAmount={discountTypeComponentMap[discountType]}
      highlightedDiscountAmountColor={
        discountType === 'Absolute' ? theme.color.text.primary : undefined
      }
      infoTooltipText={
        productsOnSaleIncluded ? (
          <FormattedMessage {...messages.conditionalTooltipSaleIncludedText} />
        ) : (
          <FormattedMessage {...messages.conditionalTooltipText} />
        )
      }
      instructionsText={
        <ConditionalInstructionsText discount={voucherDiscount} />
      }
    />
  );
};
