export const createCarousel = (
  id: string,
  data: { ids: string[] }
): Carousel => ({
  id,
  type: ComponentType.Carousel,
  data,
});

export const createUniversalPage = (
  type: ComponentType,
  id: string,
  data: { ids: string[] }
): UniversalPage => ({
  id,
  type,
  data,
});

export enum ComponentType {
  Carousel = 'carousel',
  Wishlist = 'wishlist',
  AdventCalendar = 'adventCalendar',
  GiftWrapper = 'giftwrapper',
  TryAndBuy = 'tryAndBuy',
  ShadeFinder = 'shadeFinder',
}

interface UniversalPage {
  type: ComponentType;
  id: string;
  data: { ids: string[] };
}

export type Components = Carousel | UniversalPage;

export interface Carousel {
  type: ComponentType.Carousel;
  id: string;
  data: { ids: string[] };
}

export const createComponents = (url: string): Components[] => {
  const qs = decodeURIComponent(url).replace('/render', '');
  const reqComponents = new URLSearchParams(qs).get('components');

  const data: Array<{
    type: ComponentType;
    id: string;
    data: { ids: string[] };
  }> = JSON.parse(reqComponents);

  return data
    .filter((component) =>
      Object.values(ComponentType).includes(component.type)
    )
    .map((component) => {
      switch (component.type) {
        case ComponentType.Carousel: {
          return createCarousel(component.id, component.data);
        }
        // as all unsupported types are filtered, we can default to create universal page
        default: {
          return createUniversalPage(
            component.type,
            component.id,
            component.data
          );
        }
      }
    });
};
