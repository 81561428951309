import * as React from 'react';

import { TabIds } from '@containers/ProductDetailContainer/ProductDetail/model';
import { noop } from '@utils/noop';

import { TabSwitcherContext } from './TabSwitcherContext';

export const DummyTabSwitcherProvider = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <TabSwitcherContext.Provider
      value={{
        activeTabId: TabIds.Description,
        selectNext: noop,
        selectPrevious: noop,
        toggleActiveTab: noop,
        register: noop,
        registerTabContent: noop,
        subscribeToAfterTabClosedByOpeningOtherTab: () => noop,
        tabContentsRef: { current: [] },
      }}
    >
      {children}
    </TabSwitcherContext.Provider>
  );
};
