import * as React from 'react';
import { useIntl } from 'react-intl';

import { ProductLabelModel, TooltipModel } from '@notino/react-styleguide';
import { AuthorType } from '@notino/shared/definitions/types';

import { PortalTooltip } from '@components/PortalTooltip';
import { registerKeyboardListeners } from '@helpers/accessibility/registerKeyboardListeners';

import { messages, messages as reviewMessages } from '../../../messages';

import {
  MoreInfoLink,
  TooltipContent,
  ReviewMotivationProductLabel,
} from './styled';

interface IReviewMotivationProps {
  authorType: AuthorType;
}

export const ReviewMotivation: React.FC<IReviewMotivationProps> = ({
  authorType,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const { formatMessage } = useIntl();

  const title = formatMessage(
    reviewMessages[`review${String(authorType)}Title`]
  );

  if (title === 'null') {
    return null;
  }

  const tooltip = formatMessage(
    reviewMessages[`review${String(authorType)}Description`]
  );
  const moreInfoLink = formatMessage(
    reviewMessages[`review${String(authorType)}MoreLink`]
  );

  const authorTag = (
    <ReviewMotivationProductLabel
      labelStyle={ProductLabelModel.Styles.neutral}
      isVerified={authorType === AuthorType.Verified}
    >
      <span
        tabIndex={0}
        onBlur={() => setOpen(false)}
        {...registerKeyboardListeners({
          Enter: () => setOpen((prev) => !prev),
        })}
      >
        {title}
      </span>
    </ReviewMotivationProductLabel>
  );

  if (tooltip === 'null') {
    return authorTag;
  }

  return (
    <PortalTooltip
      content={
        <PortalTooltip.Content>
          <TooltipContent>
            {tooltip}

            {moreInfoLink !== 'null' && (
              <MoreInfoLink href={moreInfoLink} rel="nofollow" target="__blank">
                {formatMessage(messages.reviewMoreText)}
              </MoreInfoLink>
            )}
          </TooltipContent>
        </PortalTooltip.Content>
      }
      // true || undefined => cuz when undefined, tooltip is uncontrolled
      isOpen={isOpen || undefined}
      interactive={true}
      position={TooltipModel.Position.topCenter}
    >
      {authorTag}
    </PortalTooltip>
  );
};
