import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ButtonModel,
  ActionButton,
  Text,
  Button,
  PictogramRegularEnvelope,
} from '@notino/react-styleguide';

import SimpleInput from '@components/GenericInput/SimpleInput';
import { isInvalidEmail } from '@helpers/emailUtils';
import { useUser } from '@queries/useUser';
import {
  IWatchdogFields,
  WatchdogComm,
} from '@sharedComponents/Watchdog/model';
import { LocalRequestHeaders } from '@utils/admin-panel';

import {
  Paragraph,
  Buttons,
  ErrorText,
  WatchdogWrapper,
  LoginButtons,
} from '../styled';
import { watchdogMessages } from '../watchdogMessages';

import { WatchdogEmailInputWrapper } from './WatchdogInput';

interface IWatchdogProps {
  errorMessage: string;
  handleSubmit: (values: IWatchdogFields) => void;
  submitAttempts: number;
  status: WatchdogComm;
  variantUrl: string;
}

export const WatchdogForm: React.FC<IWatchdogProps> = ({
  errorMessage,
  submitAttempts,
  handleSubmit: onSubmit,
  status,
  variantUrl,
}) => {
  const { formatMessage } = useIntl();
  const [email, setEmail] = React.useState(
    LocalRequestHeaders.localHeaders.userEmail || ''
  );
  const [touched, setTouched] = React.useState(false);
  const error = React.useMemo(() => isInvalidEmail(email), [email]);

  const { isLoggedIn } = useUser();
  const [continueWithoutLogin, setContinueWithoutLogin] = React.useState(false);
  const showLoginSuggest = !isLoggedIn && !continueWithoutLogin;

  const loginUrl = `${
    window.location.origin
  }/login?returnUrl=${encodeURIComponent(`${variantUrl}#watchdog`)}`;

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;
    setEmail(value);
  };

  const handleSetTouched = () => {
    setTouched(true);
  };

  const handleFormSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    onSubmit({
      watchdogEmail: email,
    });
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      aria-label={formatMessage(watchdogMessages.watchdogHeader)}
    >
      <WatchdogWrapper>
        <div>
          <PictogramRegularEnvelope width="72px" height="72px" />
        </div>

        <Text typography="titleLarge" marginBottom="1.5rem" marginTop="1rem">
          <FormattedMessage {...watchdogMessages.watchdogHeader} />
        </Text>

        <Paragraph>
          <FormattedMessage {...watchdogMessages.watchdogText} />
        </Paragraph>

        {showLoginSuggest && (
          <LoginButtons>
            <a href={loginUrl}>
              <Button type="button" buttonStyle={ButtonModel.Styles.primary}>
                <FormattedMessage {...watchdogMessages.logIn} />
              </Button>
            </a>
            <button onClick={() => setContinueWithoutLogin(true)}>
              <FormattedMessage {...watchdogMessages.skipLogin} />
            </button>
          </LoginButtons>
        )}

        {!showLoginSuggest && (
          <>
            <WatchdogEmailInputWrapper>
              <SimpleInput
                input={{
                  name: 'watchdogEmail',
                  value: email,
                  onChange: handleEmailChange,
                  onBlur: handleSetTouched,
                  inputMode: 'email',
                }}
                meta={{
                  error,
                  touched,
                }}
              />
            </WatchdogEmailInputWrapper>
            <Buttons>
              <ActionButton
                type="submit"
                buttonStyle={ButtonModel.Styles.primary}
                loading={status === WatchdogComm.SENDING}
                error={!!errorMessage || status === WatchdogComm.ERROR}
                disabled={!!error}
              >
                <FormattedMessage
                  {...watchdogMessages[
                    !errorMessage && submitAttempts <= 1
                      ? 'watchdogSendButton'
                      : 'watchdogTryAgainButton'
                  ]}
                />
              </ActionButton>
              {errorMessage && (
                <ErrorText title={errorMessage}>{errorMessage}</ErrorText>
              )}
            </Buttons>
          </>
        )}
      </WatchdogWrapper>
    </form>
  );
};
