import { IStock } from '@notino/shared/definitions/custom-definitions';
import { GetCatalogProductViewQuery } from '@notino/shared/definitions/types';

export interface IProductConfig {
  showAddToCartButton?: boolean;
  showAddToCartModal?: boolean;
}

export interface IRating {
  score: number;
  votes: number;
}

export interface IParameters {
  amount?: number;
  package?: string;
}

export interface IPrice {
  value: number;
  currency?: string;
  decimalPlaces?: number;
}

export enum AttributeTypes {
  New = 'New',
  Gift = 'Gift',
  FreeDelivery = 'FreeDelivery',
  Clearance = 'Clearance',
  Action = 'Action',
  Sample = 'Sample',
  Unknown = 'Unknown',
  Master = 'Master',
  WithoutCellophane = 'WithoutCellophane',
  IsForProfessionals = 'IsForProfessionals',
  DifferentPackaging = 'DifferentPackaging',
  Damage = 'Damage',
  EAN = 'EAN',
  Pharmacy = 'Pharmacy',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AttributeType = { [index in AttributeTypes]?: any };

export enum VariantDisplayTypes {
  Default = 'Default',
  Tiles = 'Tiles',
  Select = 'Select',
  ColorPicker = 'ColorPicker',
  Thumbnail = 'Thumbnail',
}

export enum DeliveryDisallowedReasons {
  AirTransportDisallowed = 'AirTransportDisallowed',
}

// Images
export interface IImage {
  src: string;
  alt: string;
  isVideo?: boolean;
}

export type Images = IImage[];

export type IDelivery =
  GetCatalogProductViewQuery['productDetailByCatalogMasterId']['deliveries'][number];

export interface IVariant {
  id: string;
  name: string;
  annotation: string;
  orderCode: string;
  productCode: string;
  type?: string;
  parameters?: IParameters;
  price: IPrice;
  canBuy: boolean;
  nextDeliveryDate?: Date;
  stockAvailability: IStockInfo;
  attributes?: AttributeType;
  url: string;
  images: Images;
  videos?: string[];
  maxOrderQty: number;
  showWatchdog: boolean;
  originalPrice?: IPrice;
  additionalInfo: string;
  additionalText: string;
  variantName: string;
  color?: string;
  colors?: string[];
  unitPrice?: IPrice;
  engraving: IEngraving;
  wishlisted: boolean;
  catalogId: string;
}

export interface IStockInfo {
  code: IStock;
  count?: number;
}

export interface ICampaign {
  name: string;
  imageUrl: string;
  text: string;
  detailPageText: string;
  displayOnDetailPage: boolean;
  giftImageUrl: string;
  productId: string;
  campaignId: string;
}

export interface IArticle {
  title: string;
  url: string;
  description: string;
  image: string;
  date: Date;
  author: string;
}

export interface IEngraving {
  allowed: boolean;
  characterCount: number;
  config: IEngravingConfig;
}

export interface IEngravingConfig {
  servicesType?: string;
  productId?: string;
  orderedCount?: number;
  isAvailable: boolean;
  limit?: number;
  isActive?: boolean;
  price?: number;
}

export interface IProduct {
  id: string;
  brand: string;
  brandId: number;
  collection: string;
  annotation: string;
  code: string;
  config: IProductConfig;
  description: string;
  unit: string;
  orderUnit: string;
  rating: IRating;
  variants: IVariant[];
  campaigns?: ICampaign[];
  related: IProduct[];
  deliveries: IDelivery[];
  hasDifferentColors?: boolean;
  isPerfume: boolean;
  variantDisplayType?: VariantDisplayTypes;
  composition: ICharacteristic[];
  relatedArticles: IArticle[];
  url: string;
  kind: string;
}

/** Model definitions */
export interface IProductDictionary {
  [key: string]: IProduct;
}

export interface IListingStore {
  masters: R.Dictionary<IProduct>;
  variants: R.Dictionary<IVariant>;
}

export interface IDamage {
  volumeInPercent: number;
  descriptions: string[];
}

export const MaximumVolumeInPercent: number = 100;

export interface ICharacteristic {
  name: string;
  help: string;
  values: string[];
}
